import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-dropdown-component',
  templateUrl: './dropdown-component.component.html',
  styleUrls: ['./dropdown-component.component.scss'],
})
export class DropdownComponentComponent implements OnInit {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input()
  data: any[];

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  top: number;

  @Input()
  bottom: number;

  @Input()
  left: number;

  @Input()
  right: number;

  @Input()
  gap: number;

  get gapValue() {
    return `${this.gap}px`;
  }

  get leftValue() {
    if (this.left !== undefined) {
      return `${this.left}px`;
    }
    return 'auto';
  }

  get rightValue() {
    if (this.right  !== undefined) {
      return `${this.right}px`;
    }
    return 'auto';
  }
  
  get topValue() {
    if (this.top !== undefined) {
      return `${this.top}px`;
    }
    return 'auto';
  }
  
  get bottomValue() {
    if (this.bottom  !== undefined) {
      return `${this.bottom}px`;
    }
    return 'auto';
  }

  @Output()
  clicked = new EventEmitter<any>();

  @Output()
  itemHovered = new EventEmitter<any>();

  @Output()
  hoverOut = new EventEmitter<any>();

  hoveredIn = null;

  constructor(public readonly cs: CanvasService) {}

  ngOnInit(): void {
    // console.log('ng-on-init', this.data);
  }

  hoverInHandler(data: any) {
    this.hoveredIn = data;
    setTimeout(() => {
      if (this.hoveredIn === data) {
        this.itemHovered?.emit(data);
      }
    }, 5);
  }

  hoverOutHandler(_data: any) {
    this.hoveredIn = undefined;
    setTimeout(() => {
      if (this.hoveredIn === undefined) {
        this.hoverOut?.emit();
      }
    }, 5);
  }
}
