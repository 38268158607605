import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { projectReducer } from './project.reducer';
import { ProjectFeature } from './project.actions';
import { EffectsModule } from '@ngrx/effects';
import { ProjectEffects } from './project.effect';
import { ProjectService } from './project.service';

@NgModule({
  imports: [
    EffectsModule.forFeature([ProjectEffects]),
    StoreModule.forFeature(ProjectFeature, projectReducer),
  ],
  providers: [ProjectService],
})
export class ProjectModule {}
