import { pick } from 'lodash';
import { TextAnimationFrame } from '../components/animation-frame/animation.types';
import { SoundAnimationFrameObject } from './sound-animation-frame';
import { AnimationEnvironment } from '../../../services/animation/animation.types';

export class TextAnimationFrameObject<
  T extends TextAnimationFrame = TextAnimationFrame,
> extends SoundAnimationFrameObject<T> {
  get soundUrl() {
    const lang = this.animationService.currentLanguage;

    const descriptor = this.frame.langs?.[lang] || this.frame;

    if (descriptor.voiceType == 'recorded') {
      return descriptor.recordFileUrl;
    } else {
      // default
      return descriptor.soundFileUrl;
    }
  }

  get _duration() {
    const lang = this.animationService.currentLanguage;
    if (this.frame.langs?.[lang]) {
      return this.frame.langs?.[lang]?.audioDuration || 1;
    }
    return this.frame.duration;
  }

  get _text() {
    if (this.animationService.currentLanguage) {
      return this.frame.langs?.[this.animationService.currentLanguage]?.text;
    }
    return this.frame.text;
  }

  get type() {
    return 'text';
  }

  get text() {
    return this.frame.text;
  }

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      ...pick(this.frame, ['text', 'subtitle', 'langs']),
    };
  }

  async _animate(env: AnimationEnvironment) {
    this.shape.service.addSubtitle(this._text);
    await super._animate(env);
    this.shape.service.removeSubtitle();
  }

  _animtionId: string;

  async localAnimate(_inverse?: boolean, _timeScale?: number) {
    this.maxIncrement = Math.ceil(this.duration * 60);
    this.currentIncrement = 0;

    return new Promise<void>(resolve => {
      this._animtionId = Math.random().toString() + Math.random().toString();
      this.playSound();
      this.service.animationFrames[this._animtionId] = increment => {
        this.currentIncrement += increment;
        if (this.currentIncrement > this.maxIncrement) {
          resolve();
        }
      };
    });
  }
}
