<div class="number-input-comp row-space-between">
  <div class="flex-row gap-4">
    <div class="attr-key">{{ key }}</div>
    <ng-container *ngIf="array.length > 1">
      <div (click)="selectorOpen = !selectorOpen" class="indefinite-wave">
        ~
      </div>
    </ng-container>
    <input
      *ngIf="array.length == 1"
      [value]="_value | step"
      type="number"
      (keyup)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      (change)="inputChanged($event)"
      [step]="step"
    />
  </div>
  <nw-dropdown-component
    [data]="array"
    *ngIf="selectorOpen"
    [right]="0"
    [top]="12"
    (clicked)="selectorClicked($event)"
  >
    <div>
      <ng-template let-value>
        <div>{{ value }}</div>
      </ng-template>
    </div>
  </nw-dropdown-component>
</div>
