// tslint:disable: no-unused-expression

import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { post } from "selenium-webdriver/http";

export enum LineElementType {
  SemiColon = "semicolon",
  Space = "space",
  Tab = "tab",
  Word = "word"
}

export enum Keys {
  BackSpace = "Backspace",
  Control = "Control",
  Down = "ArrowDown",
  Enter = "Enter",
  Left = "ArrowLeft",
  Right = "ArrowRight",
  Space = "Space",
  Up = "ArrowUp"
}

export interface Line {
  elements: LineElement[];
}

export interface LineElement {
  type: LineElementType;
  // cursor?: number;
  value?: string;
}

@Injectable()
export class MSEditorService {
  lineIndex = 0;
  index = 0;
  lines: Line[] = [];
  letterIndex = 0;
  textIndex = 0;

  constructor() {
    this.lines = [
      {
        elements: [
          {
            type: LineElementType.Tab
          },
          {
            type: LineElementType.Word,
            value: "const"
          },
          {
            type: LineElementType.Space
          },
          {
            type: LineElementType.Word,
            value: "const"
          }
        ]
      }
    ];
  }

  eventHandler(action: KeyboardEvent) {
    this.handleKey(action.code, action.key);
  }

  get currentLine(): Line {
    return this.lines[this.lineIndex];
  }

  get currentLineElements(): LineElement[] {
    return this.currentLine ? this.currentLine.elements : [];
  }

  get lineEnd(): boolean {
    return this.index >= this.currentLineElements.length;
  }

  get lineBegin(): boolean {
    return this.index === 0;
  }

  get currentElement() {
    return this.currentLineElements[this.index];
  }

  get currentElementLength() {
    return this.currentElement.value.length;
  }

  get preceedingElement() {
    if (this.lineBegin) {
      return;
    }
    return this.currentLineElements[this.index - 1];
  }

  get preceedingElementLength() {
    return this.preceedingElement.value.length;
  }

  get preceedingLine() {
    return this.lineIndex > 0 && this.lines[this.lineIndex - 1];
  }

  get preceedingLineElements() {
    return this.preceedingLine && this.preceedingLine.elements;
  }

  get isPreceedingElementAWord() {
    return this.preceedingElement ? this.isWord(this.preceedingElement) : false;
  }

  get isCurrentElementAWord() {
    return this.currentElement ? this.isWord(this.currentElement) : false;
  }

  private removeCurrentLine() {
    this.lines.splice(this.lineIndex, 1);
    this.lineIndex--;
  }

  isCurrentLine(index: number) {
    return this.lineIndex === index;
  }

  private isWord(e: LineElement) {
    return e.type === LineElementType.Word;
  }

  private isLeft(code: string) {
    return code === Keys.Left;
  }

  private isRight(code: string) {
    return code === Keys.Right;
  }

  private newLine() {
    this.lines.push({ elements: [] });
    this.index = 0;
    this.letterIndex = 0;
  }

  handleKey(code: string, value: string) {
    switch (code) {
      case Keys.Left:
        if (this.isPreceedingElementAWord && this.letterIndex === 0) {
          this.letterIndex = this.preceedingElementLength - 1;
          this.index--;
          return;
        }
        if (this.isCurrentElementAWord) {
          this.letterIndex === 0 ? this.index-- : this.letterIndex--;
          return;
        }
        !this.lineBegin && this.index--;
        break;

      case Keys.Right:
        if (this.isCurrentElementAWord) {
          if (this.letterIndex === this.currentElementLength - 1) {
            this.index++;
            this.letterIndex = 0;
          } else {
            this.letterIndex++;
          }
          return;
        }
        !this.lineEnd && this.index++;
        break;
      case Keys.BackSpace:
        if (this.lineBegin) {
          this.preceedingLineElements.push(...this.currentLineElements);
          this.removeCurrentLine();
          this.checkCurrentLine();
        } else if (this.letterIndex) {
          this.currentElement.value = this.remove(
            this.currentElement.value,
            this.letterIndex
          );
          this.letterIndex--;
        } else if (this.isPreceedingElementAWord) {
          this.preceedingElement.value = this.remove(
            this.preceedingElement.value
          );
        } else if (!this.isPreceedingElementAWord && !this.lineBegin) {
          this.removeNotWord(this.index);
        } else {
          this.currentLineElements.splice(this.index - 1, 1);
          this.index--;
        }
        break;
      case Keys.Space:
        if (this.letterIndex) {
          this.currentLineElements.splice(this.index + 1, 0, {
            type: LineElementType.Space
          });
          this.currentLineElements.splice(this.index + 2, 0, {
            type: LineElementType.Word,
            value: this.currentElement.value.substr(this.letterIndex)
          });
          this.currentElement.value = this.currentElement.value.substr(
            0,
            this.letterIndex
          );
          this.letterIndex = 0;
          this.index++;
        } else {
          this.currentLineElements.splice(this.index, 0, {
            type: LineElementType.Space
          });
        }
        this.index++;
        break;
      case Keys.Enter:
        if (this.letterIndex) {
          this.currentElement.value = this.currentElement.value.substr(
            0,
            this.letterIndex
          );
          this.lines.push({
            elements: [
              {
                type: LineElementType.Word,
                value: this.currentElement.value.substring(this.letterIndex)
              },
              ...this.currentLineElements.slice(this.index + 1)
            ]
          });
          this.currentLineElements.splice(
            this.index - 1,
            this.currentLineElements.length - this.index
          );
          this.index = 0;
          this.letterIndex = 0;
        } else {
          this.lineIndex++;
          if (!this.currentLine) {
            this.newLine();
          }
        }
        break;
      case Keys.Control:
        break;
      case Keys.Down:
        if (this.lineIndex < this.lines.length - 1) {
          this.lineIndex++;
          this.letterIndex = 0;
          this.index = 0;
        }
        break;
      case Keys.Up:
        this.lineIndex > 0 && this.lineIndex--;
        break;
      default:
        if (this.currentElement && this.isWord(this.currentElement)) {
          this.currentElement.value = this.insert(
            this.currentElement.value,
            value,
            this.letterIndex
          );
          this.letterIndex++;
        } else if (this.isPreceedingElementAWord) {
          this.preceedingElement.value += value;
        } else if (!this.currentElement) {
          this.currentLineElements.push({
            type: LineElementType.Word,
            value
          });
          this.index++;
          this.letterIndex = 0;
        }
        return false;
    }
  }

  checkCurrentLine() {
    for (let i = 0; i < this.currentLineElements.length; i++) {
      const element = this.currentLineElements[i];
      const next = this.currentLineElements[i + 1];
      if (next && this.isWord(next) && this.isWord(element)) {
        this.letterIndex = element.value.length;
        element.value += next.value;
        this.currentLineElements.splice(i + 1, 1);
        this.index = i;
        break;
      }
    }
  }

  insert(base: string, insert: string, index = base.length + 1) {
    return base.replace(new RegExp(`^(.{${index}})(.)`), `$1${insert}$2`);
  }

  remove(base: string, index = base.length) {
    return `${base.slice(0, index - 1)}${base.slice(index)}`;
  }

  removeNotWord(index: number) {
    if (
      this.isWord(this.currentLineElements[index - 2]) &&
      this.isWord(this.currentLineElements[index])
    ) {
      const prevWord = this.currentLineElements[index - 2];
      const postWord = this.currentLineElements[index];
      this.letterIndex = prevWord.value.length;
      prevWord.value += postWord.value;
      this.index -= 2;
      this.currentLineElements.splice(index, 1);
      this.currentLineElements.splice(index - 1, 1);
    }
  }
}
