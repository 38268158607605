import { Component, Input } from '@angular/core';
import { FunctionAnimationFrameObject } from '../../../animation/frame/function/function-animation-frame';

@Component({
  selector: 'nw-function-frame',
  templateUrl: './function-frame.component.html',
  styleUrls: ['./function-frame.component.scss'],
})
export class FunctionFrameComponent {
  @Input()
  frame: FunctionAnimationFrameObject;
}
