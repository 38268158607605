import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { createOrganisation } from '../../organisation/organisation.actions';
import { Organisation } from '../../organisation/organisation.interface';
import {
  organisationList,
  organisationListLoading,
} from '../../organisation/organisation.selector';
import { myUserId } from '../../user/user.selector';

@Component({
  selector: 'nw-organisation-selector',
  templateUrl: './organisation-selector.component.html',
  styleUrls: ['./organisation-selector.component.scss'],
  host: {
    '(document:keyup)': 'keyup($event)',
  },
})
export class OrganisationSelectorComponent implements OnInit {
  newOrganisationPanel = false;
  newOrganisationName = '';

  organisations$: Observable<Organisation[]>;
  loading$: Observable<boolean>;
  myUserId$: Observable<string>;

  constructor(
    private router: Router,
    private readonly store: Store,
  ) {
    this.organisations$ = this.store.select(organisationList);
    this.loading$ = this.store.select(organisationListLoading);
    this.myUserId$ = this.store.select(myUserId);
  }

  ngOnInit() {
    this.fetchOrganisations();
  }

  keyup(event: KeyboardEvent) {
    if (event.key !== 'Escape') {
      return;
    }
    this.resetNewPanel();
  }

  fetchOrganisations() {
    // this.store.dispatch(loadOrganisations());
  }

  setRoute(id: string) {
    this.router.navigate(['organisations', id.toLowerCase()]);
  }

  addOrganisation() {
    this.newOrganisationPanel = true;
  }

  resetNewPanel(event?: Event) {
    if (event && event.target !== event.currentTarget) {
      return;
    }

    this.newOrganisationName = '';
    this.newOrganisationPanel = false;
  }

  submitNewOrganisation() {
    if (!this.newOrganisationName) {
      return;
    }

    const name = this.newOrganisationName;
    this.resetNewPanel();

    this.store.dispatch(createOrganisation({ organisation: { name } }));
  }
}
