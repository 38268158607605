import { on } from '@ngrx/store';
import { ResourceData } from '../../../elements/resource/resource.types';
import {
  addNewState,
  deleteShapeAction,
  deselectAllShapes,
  deselectShape,
  setNewShapeAction,
  removeState,
  resetPatchedShapes as resetUpdates,
  selectAllShapes,
  selectShape,
  setCurrentState,
  setFileAction,
  setFileLoading,
  setPatchLoading,
  setShapeAttributeBaseAction,
  setShapeLabel,
  toggleSelectAction,
  shiftSelectShapes,
  incrementStrokeWidthAction,
  setShapeDescriptor,
  setShapeResourceData,
  addPatchedShape,
  updateDescriptorOfShapeBaseAction,
  setOriginalDescriptor,
  setBBoxOfShape,
  setCurrentDescriptor,
  updateCurrentDescriptorBaseAction,
  setShapeTranslateBase,
  setShapeOriginalTransform,
  setCurrentShapeTranslate,
  setCurrentShapeScale,
  setShapeScaleBase,
  setShowComponentSearch,
  setOriginalSVGAttributes,
  setCurrentSVGAttributesOfShape,
  setShapeToBeSaved,
  setSVGAttributesOfShape,
  setBaseShapeTransform,
  setSVGSubAttribute,
  setCurrentSVGSubAttribute,
  setDescriptorItem,
  cleanFile,
  setDescriptorSubAttribute,
  setCurrentDescriptorSubAttribute,
  setTransformSubAttribute,
  setCurrentScene,
  setFileChanged,
  setIfAttributes,
  addSubScene,
  setSceneName,
  setSubSceneName,
  saveSubScenePosition,
  setCanvasPosition,
  selectSubSceneBase,
  setCurrentScenes,
  setFontSize,
  setNoAnimationMode,
  setFontLoaded,
  setSelectedShapes,
  setCanvasBackgroundColor,
  setChildOf,
  setCurrentMainScene,
  addNewSceneBase,
  setCurrentShapeRotation,
  setShapeRotationBase,
  deleteShapeFromFileAction,
  setComponents,
  setLoadedScenes,
  addLoadedScene,
  addState,
  selectState,
  setAttributeByState,
  saveScenePosition,
  resetBaseState,
  bulkShapeUpdate,
  saveChangedShapes,
  setCanvasOrientation,
  setScenes,
  setFiles,
} from '../editor.actions';
import { createImmerReducer } from 'ngrx-immer/store';
import {
  BBox,
  CircleShapeDescriptor,
  FillConfig,
  FontAlign,
  FontStyle,
  FontWeight,
  GeneralShapeDescriptor,
  LiteralValue,
  Point,
  RectangleShapeDescriptor,
  Rotation,
  SVGAttributes,
  Scene,
  ShapeIRI,
  ShapeTransform,
  StrokeConfig,
  TextShapeDescriptor,
} from '../../../elements/resource/types/shape.type';
import { set as _set, pick as _pick } from 'lodash';

export interface SceneState {
  current?: string[];
  scenes: Scene[];
}

export interface CanvasPosition {
  x: number;
  y: number;
  scale: {
    x: number;
    y: number;
  };
}

export type UpdatesByState = Record<string, Record<string, any>>;
export interface EditorState {
  file?: ResourceData;
  shapes?: Record<string, ResourceData>;
  descriptors?: Record<string, GeneralShapeDescriptor>;
  currentDescriptors?: Record<string, GeneralShapeDescriptor>;
  originalDescriptors?: Record<ShapeIRI, GeneralShapeDescriptor>;

  baseSvgAttributes?: Record<ShapeIRI, SVGAttributes>;
  originalSVGAttributes?: Record<ShapeIRI, SVGAttributes>;
  currentSVGAttributes?: Record<ShapeIRI, SVGAttributes>;

  baseShapeTransforms?: Record<ShapeIRI, ShapeTransform>;
  originalShapeTransforms?: Record<ShapeIRI, ShapeTransform>;
  currentShapeTransforms?: Record<ShapeIRI, ShapeTransform>;

  selectedShapes?: Record<string, boolean>;
  newShapes?: Record<string, boolean>;
  patchedShapes?: Record<string, boolean>;
  deletedShapes?: Record<string, boolean>;
  literals?: Record<string, Record<string, LiteralValue>>;
  showComponentSearch?: boolean;
  loading?: boolean;
  isPatchLoading?: boolean;
  isProjectLoading?: boolean;
  isFileLoading?: boolean;

  // project?: ResourceData;
  currentScene?: string[];
  currentMainScene?: string;
  scenes?: Scene[];
  bBoxes?: Record<string, BBox>;
  colorPalette?: Record<string, string>;
  childOf?: Record<string, string>;
  fileChanged?: boolean;
  canvasPosition?: CanvasPosition;
  noAnimationMode?: boolean;
  fontLoaded?: boolean;
  components?: Array<{
    IRI: string;
    label: string;
  }>;
  loadedScenes?: Record<string, boolean>;
  states?: string[];
  selectedState?: string;
  updatesByState?: Record<ShapeIRI, UpdatesByState>;
  files?: Record<string, ResourceData>;
}

export const initialEditorState: EditorState = {
  showComponentSearch: false,
  loading: false,
  shapes: {},
  descriptors: {},
  currentDescriptors: {},
  originalDescriptors: {},
  patchedShapes: {},
  deletedShapes: {},
  selectedShapes: {},
  newShapes: {},
  isPatchLoading: false,
  bBoxes: {},
  baseShapeTransforms: {},
  originalShapeTransforms: {},
  currentShapeTransforms: {},
  baseSvgAttributes: {},
  originalSVGAttributes: {},
  currentSVGAttributes: {},
  childOf: {},
  noAnimationMode: false,
  fontLoaded: true,
  loadedScenes: null,
  updatesByState: {},
  files: {},
};

const getSVGAttributes = (descriptor: GeneralShapeDescriptor) => {
  const strokeWidth = descriptor.svgAttributes['stroke-width'];

  return {
    ...descriptor.svgAttributes,
    stroke: descriptor.svgAttributes?.stroke
      ? ({
          color: descriptor.svgAttributes.stroke,
          width: isNaN(strokeWidth) ? 1 : strokeWidth,
          dash: descriptor.svgAttributes.dash,
        } as StrokeConfig)
      : undefined,
    fill: descriptor.svgAttributes?.fill
      ? {
          color: descriptor.svgAttributes.fill,
          gradient: descriptor.svgAttributes.gradient,
          gradientDirection: descriptor.svgAttributes.gradientDirection,
        }
      : undefined,
  };
};

const getScale = (descriptor: GeneralShapeDescriptor) => {
  switch (descriptor.type) {
    case 'rectangle-shape':
      const { width, height } = descriptor as RectangleShapeDescriptor;
      return { x: width, y: height };
    case 'circle-shape':
      const { rx, ry } = descriptor as CircleShapeDescriptor;
      return { x: 2 * rx, y: 2 * ry };
    case 'imported-shape':
      return descriptor.position.scale;
  }
  return;
};

const mapDescriptor = (descriptor: GeneralShapeDescriptor) => {
  if ((descriptor as TextShapeDescriptor)?.textConfig) {
    // this is already the new structure
    return descriptor;
  }

  return {
    ...descriptor,
    textConfig: (descriptor as TextShapeDescriptor)?.text
      ? _pick(descriptor as TextShapeDescriptor, [
          'text',
          'fontSize',
          'align',
          'fontStyle',
          'fontWeight',
          'wordWrap',
          'wordWrapWidth',
          'letterSpacing',
          'fontFamily',
        ])
      : undefined,
  };
};

export const editorReducer = createImmerReducer(
  initialEditorState,
  on(cleanFile, state => {
    return state;
  }),
  on(setCanvasBackgroundColor, (state, { color }) => {
    _set(state, ['file', 'literals', 'descriptor', 'backgroundColor'], color);
    state.fileChanged = true;
    return state;
  }),
  on(setCanvasOrientation, (state, { value }) => {
    _set(state, ['file', 'literals', 'descriptor', 'orientation'], value);
    state.fileChanged = true;
    return state;
  }),
  on(setFileAction, (state, { file, merge }) => {
    const ID = file.IRI.split('#')[1];
    state.files[ID] = file;
    if (!merge) {
      state.scenes = [];
      state.currentScene = null;
      state.descriptors = {};
      state.currentDescriptors = {};
      state.originalDescriptors = {};
      state.patchedShapes = {};
      state.deletedShapes = {};
      state.selectedShapes = {};
      state.newShapes = {};
      state.updatesByState = {};
      state.isPatchLoading = false;
      state.bBoxes = {};
      state.baseShapeTransforms = {};
      state.originalShapeTransforms = {};
      state.currentShapeTransforms = {};
      state.baseSvgAttributes = {};
      state.originalSVGAttributes = {};
      state.currentSVGAttributes = {};
      state.childOf = {};
      state.literals = {};

      const { _scenes, states } = file.literals
        .descriptor as GeneralShapeDescriptor;

      state.states = states;

      if (_scenes?.length) {
        state.scenes = file.literals.descriptor._scenes;
        // state.currentScene = [state.scenes[0].name];
      } else {
        state.scenes = [];
      }
    }

    const setStateByResourceData = (
      resourceData: ResourceData,
      parentIRI: string,
      prefix = '',
      // childShapes: ResourceData[] = [],
    ) => {
      if (merge && !!state.shapes[resourceData.IRI]) {
        return;
      }

      let IRI = resourceData.IRI;
      if (prefix) {
        IRI = `${prefix}_${IRI}`;
      }

      if (resourceData.literals.descriptor.childOf) {
        state.childOf[IRI] = resourceData.literals.descriptor.childOf;
      } else {
        state.childOf[IRI] = parentIRI;
      }

      state.shapes[IRI] = resourceData;
      state.literals[IRI] = resourceData.literals;
      const descriptor = resourceData.literals.descriptor;

      state.descriptors[IRI] = mapDescriptor(descriptor);
      state.currentDescriptors[IRI] = mapDescriptor(descriptor);
      state.originalDescriptors[IRI] = mapDescriptor(descriptor);
      state.updatesByState[IRI] = descriptor.updatesByState;

      // console.log('descriptor', descriptor.type, descriptor); //

      const position = descriptor.position || { x: 0, y: 0 };
      const transform = {
        translate: _pick(descriptor.position, 'x', 'y'),
        scale: getScale(descriptor),
        rotation: position.rotation || { angle: 0 },
      } as ShapeTransform;

      state.baseShapeTransforms[IRI] = transform;
      state.originalShapeTransforms[IRI] = transform;
      state.currentShapeTransforms[IRI] = transform;

      const svgAttributes = getSVGAttributes(descriptor);

      state.baseSvgAttributes[IRI] = svgAttributes;
      state.originalSVGAttributes[IRI] = svgAttributes;
      state.currentSVGAttributes[IRI] = svgAttributes;

      // const shapes =
      //   (resourceData.relationships?.shape as ResourceData[]) || [];
      // for (const data of shapes) {
      //   const { descriptor } = data.literals;
      //   if (descriptor.type == 'imported-shape') {
      //     prefix = [prefix, data.IRI].filter(v => !!v).join('_');
      //   }
      //   setStateByResourceData(data, resourceData.IRI, prefix);
      // }
    };

    for (const shape of (file.relationships?.shape as ResourceData[]) || []) {
      setStateByResourceData(shape, file.IRI);
    }
    state.file = file;
    return state;
  }),
  on(bulkShapeUpdate, (state, { data: bulkData }) => {
    Object.entries(bulkData || {}).map(([shapeIRI, updates]) => {
      Object.entries(updates).map(([animationKey, animationValue]) => {
        switch (animationKey) {
          case 'translate':
          case 'scale':
            state.currentShapeTransforms[shapeIRI][animationKey] =
              animationValue as Point;
            break;
          case 'rotation':
            state.currentShapeTransforms[shapeIRI].rotation =
              animationValue as Rotation;
            break;
          case 'fill':
            state.currentSVGAttributes[shapeIRI].fill =
              animationValue as FillConfig;
            break;
          case 'stroke':
            state.currentSVGAttributes[shapeIRI].stroke =
              animationValue as StrokeConfig;
            break;
        }
      });
    });
    return state;
  }),
  on(resetBaseState, state => {
    state.currentDescriptors = state.descriptors;
    state.currentShapeTransforms = state.baseShapeTransforms;
    state.currentSVGAttributes = state.baseSvgAttributes;
    return state;
  }),
  on(setFiles, (state, { files }) => {
    console.log('set-files', files);
    state.files = files;
    return state;
  }),

  on(setIfAttributes, (state, { key, value }) => {
    const { scenes, selectedShapes, newShapes } = state;
    // -- // -- //
    Object.keys(selectedShapes).map(IRI => {
      if (key == '_all_') {
        state.descriptors[IRI].if = value ? undefined : {};
        console.log('set _all_');
      } else {
        if (!state.descriptors[IRI].if && !value) {
          // setting all other scenes to true after the all
          console.log('setting-all - but');
          state.descriptors[IRI].if = scenes.reduce((object, { name }) => {
            object[name] = name !== key;
            return object;
          }, {});
        } else {
          state.descriptors[IRI].if = {
            ...(state.descriptors[IRI].if || {}),
            [key]: value,
          };
        }
      }
      if (!newShapes[IRI]) {
        state.patchedShapes[IRI] = true;
      }
    });

    return state;
  }),
  on(setCurrentMainScene, (state, { scene }) => {
    state.currentMainScene = scene;
    return state;
  }),
  on(setFontLoaded, (state, { value }) => {
    state.fontLoaded = value;
    return state;
  }),
  on(setNoAnimationMode, (state, { value }) => {
    state.noAnimationMode = value;
    return state;
  }),
  on(setFileChanged, (state, { value }) => {
    console.log('set-file-changed', value);
    state.fileChanged = value;
    return state;
  }),
  on(saveScenePosition, (state, { scene, position }) => {
    const sceneIndex = state.scenes.findIndex(s => s.name == scene);

    state.scenes[sceneIndex].position = position;
    state.fileChanged = true;
    return state;
  }),
  on(saveSubScenePosition, (state, { scene, subScene, position }) => {
    const sceneIndex = state.scenes.findIndex(s => s.name == scene);
    const subSceneIndex = state.scenes[sceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );

    state.scenes[sceneIndex].subscenes[subSceneIndex].position = position;
    console.log('set-file-changed > saveSubScenePosition');

    state.fileChanged = true;

    return state;
  }),
  on(setComponents, (state, { data }) => {
    state.components = data;
    return state;
  }),
  on(setCurrentScene, (state, { scene }) => {
    state.currentScene = [scene];
    return state;
  }),
  on(setCurrentScenes, (state, { scenes }) => {
    console.log('set-current-scenes', scenes);
    state.currentScene = scenes;
    return state;
  }),
  on(selectSubSceneBase, (state, { name }) => {
    state.currentScene[1] = name;
    return state;
  }),
  on(setSubSceneName, (state, { parentIndex, index, name }) => {
    state.currentScene[1] = name;
    state.scenes[parentIndex].subscenes[index].name = name;
    state.fileChanged = true;
    return state;
  }),
  on(setScenes, (state, { scenes }) => {
    state.scenes = scenes;
    state.fileChanged = true;
    return state;
  }),
  on(addSubScene, (state, { parentIndex, name, index }) => {
    state.scenes[parentIndex].subscenes ||= [];
    state.scenes[parentIndex].subscenes.splice(index, 0, { name });
    state.fileChanged = true;
    return state;
  }),
  on(setChildOf, (state, { parent, child }) => {
    state.childOf[child] = parent;
    return state;
  }),
  on(setSceneName, (state, { index, name }) => {
    const oldName = state.scenes[index].name;
    state.scenes[index].name = name;
    state.fileChanged = true;

    Object.entries(state.descriptors).map(([IRI, descriptor]) => {
      if (descriptor.if?.[oldName]) {
        delete state.descriptors[IRI].if[oldName];
        state.descriptors[IRI].if[name] = true;
        if (!state.newShapes[IRI]) {
          state.patchedShapes[IRI] = true;
        }
      }
    });

    return state;
  }),
  on(setCurrentDescriptor, (state, { IRI, descriptor }) => {
    state.currentDescriptors[IRI] = descriptor;
    return state;
  }),
  on(setShapeDescriptor, (state, { IRI, descriptor }) => {
    state.descriptors[IRI] = descriptor;
    state.currentDescriptors[IRI] = descriptor;
    return state;
  }),
  on(setOriginalDescriptor, (state, { IRI, descriptor }) => {
    state.originalDescriptors[IRI] = descriptor;
    state.currentDescriptors[IRI] = descriptor;
    state.descriptors[IRI] = descriptor;
    return state;
  }),
  on(setDescriptorItem, (state, { IRI, IRIs, key, value, innerKey }) => {
    const shapeIRIs = IRI ? [IRI] : IRIs;
    shapeIRIs.map(shapeIRI => {
      _set(
        state.descriptors,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
      _set(
        state.currentDescriptors,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
    });
    return state;
  }),
  on(
    setAttributeByState,
    (state, { IRIs, state: fileState, key, innerKey, value, keys }) => {
      IRIs.map(IRI => {
        if (!state.newShapes[IRI]) {
          state.patchedShapes[IRI] = true;
        }
        _set(
          state.updatesByState,
          keys
            ? [IRI, fileState, ...keys]
            : [IRI, fileState, key, innerKey].filter(v => !!v),
          value,
        );
      });
      return state;
    },
  ),
  on(setLoadedScenes, (state, { value }) => {
    state.loadedScenes = value;
    return state;
  }),
  on(addLoadedScene, (state, { key }) => {
    state.loadedScenes ||= {};
    state.loadedScenes[key] = true;
    return state;
  }),
  on(setOriginalSVGAttributes, (state, { IRI: shapeIRI, svgAttributes }) => {
    state.originalSVGAttributes[shapeIRI] = svgAttributes;
    state.currentSVGAttributes[shapeIRI] = svgAttributes;
    state.baseSvgAttributes[shapeIRI] = svgAttributes;
    return state;
  }),
  on(setCurrentSVGAttributesOfShape, (state, { shapeIRI, svgAttributes }) => {
    state.currentSVGAttributes[shapeIRI] = {
      ...state.currentSVGAttributes[shapeIRI],
      ...svgAttributes,
    };
    return state;
  }),
  on(setSVGAttributesOfShape, (state, { shapeIRI, svgAttributes }) => {
    state.baseSvgAttributes[shapeIRI] = {
      ...state.baseSvgAttributes[shapeIRI],
      ...svgAttributes,
    };
    return state;
  }),
  on(setSVGSubAttribute, (state, { IRI, IRIs, key, innerKey, value }) => {
    const shapeIRIs = IRI ? [IRI] : IRIs || [];
    shapeIRIs.map(shapeIRI => {
      _set(
        state.currentSVGAttributes,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
      _set(
        state.baseSvgAttributes,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
    });
    return state;
  }),
  on(
    setCurrentSVGSubAttribute,
    (state, { IRI: shapeIRI, key, innerKey, value }) => {
      _set(state.currentSVGAttributes, [shapeIRI, key, innerKey], value);
      return state;
    },
  ),
  on(setTransformSubAttribute, (state, { IRI, IRIs, key, innerKey, value }) => {
    const shapeIRIs = IRI ? [IRI] : IRIs || [];
    shapeIRIs.map(shapeIRI => {
      _set(
        state.currentShapeTransforms,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
      _set(
        state.baseShapeTransforms,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
    });
    return state;
  }),
  on(setDescriptorSubAttribute, (state, { shapeIRI, key, innerKey, value }) => {
    _set(
      state.currentDescriptors,
      [shapeIRI, key, innerKey].filter(v => !!v),
      value,
    );
    _set(state.descriptors, [shapeIRI, key, innerKey], value);
    return state;
  }),
  on(
    setCurrentDescriptorSubAttribute,
    (state, { shapeIRI, key, innerKey, value }) => {
      _set(
        state.currentDescriptors,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
      return state;
    },
  ),
  on(setShapeResourceData, (state, { data }) => {
    state.shapes[data.IRI] = data;

    // if (data.relationships?.parent) {
    //   state.childOf[data.IRI] = data.relationships.parent as string;
    // }

    return state;
  }),
  on(setSelectedShapes, (state, { shapes }) => {
    state.selectedShapes = shapes;
    return state;
  }),
  on(selectShape, (state, { IRI, shift }) => {
    if (shift) {
      state.selectedShapes[IRI] = true;
    } else {
      state.selectedShapes = {
        [IRI]: true,
      };
    }
    return state;
  }),
  on(addState, (state, { name }) => {
    state.states ||= [];
    state.states.push(name);
    state.fileChanged = true;
    return state;
  }),
  on(selectState, (state, { name }) => {
    state.selectedState = name;
    return state;
  }),
  on(deselectShape, (state, { IRI }) => {
    delete state.selectedShapes[IRI];
    return state;
  }),
  on(selectAllShapes, state => ({
    ...state,
    selectedShapes: Object.keys(state.shapes).reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {}),
  })),
  on(deselectAllShapes, state => {
    state.selectedShapes = {};
    return state;
  }),
  on(setShapeToBeSaved, (state, { IRI: shapeIRI }) => {
    if (!state.newShapes[shapeIRI]) {
      // if shape is newly added, than it is to be saved anyway
      state.patchedShapes[shapeIRI] = true;
    }
    return state;
  }),
  on(addPatchedShape, (state, { IRI }) => {
    if (!state.newShapes[IRI]) {
      // if shape is newly added, than it is to be saved anyway
      state.patchedShapes[IRI] = true;
    }
    return state;
  }),
  on(saveChangedShapes, (state, { IRI, IRIs }) => {
    const shapeIRIs = IRI ? [IRI] : IRIs || [];
    shapeIRIs.map(_IRI => {
      if (!state.newShapes[_IRI]) {
        // if shape is newly added, than it is to be saved anyway
        state.patchedShapes[_IRI] = true;
      }
    });
    return state;
  }),
  on(resetUpdates, state => ({
    ...state,
    newShapes: {},
    patchedShapes: {},
    deletedShapes: {},
  })),
  on(setShapeAttributeBaseAction, (state, { IRIs, object }) => {
    IRIs.map(IRI => {
      Object.entries(object).map(([k, v]) => {
        _set(state.descriptors[IRI], k, v);
        _set(state.currentDescriptors[IRI], k, v);
      });
      if (!state.newShapes[IRI]) {
        state.patchedShapes[IRI] = true;
      }
    });
    return state;
  }),
  on(updateDescriptorOfShapeBaseAction, (state, { IRI, descriptor }) => {
    // TDOOx - implement original
    state.descriptors[IRI] = {
      ...state.descriptors[IRI],
      ...descriptor,
    };
    state.currentDescriptors[IRI] = {
      ...state.currentDescriptors[IRI],
      ...descriptor,
    };
    return state;
  }),
  on(updateCurrentDescriptorBaseAction, (state, { IRI, descriptor }) => {
    // TDOOx - implement original
    state.currentDescriptors[IRI] = {
      ...state.descriptors[IRI],
      ...descriptor,
    };
    return state;
  }),
  on(incrementStrokeWidthAction, (state, { increment }) => {
    const { selectedShapes } = state;
    Object.keys(selectedShapes).map(IRI => {
      if (state.descriptors[IRI].svgAttributes.stroke) {
        state.descriptors[IRI].svgAttributes['stroke-width'] ||= 0;
        state.descriptors[IRI].svgAttributes['stroke-width'] += increment;
        state.descriptors[IRI].svgAttributes['stroke-width'] = Math.max(
          state.descriptors[IRI].svgAttributes['stroke-width'],
          0,
        );
      }
    });
    return state;
  }),
  on(deleteShapeAction, (state, { IRI }) => {
    delete state.selectedShapes[IRI];
    delete state.patchedShapes[IRI];

    delete state.descriptors[IRI];
    delete state.shapes[IRI];

    if (state.newShapes?.[IRI]) {
      delete state.newShapes[IRI];
    } else {
      state.deletedShapes ||= {};
      state.deletedShapes[IRI] = true;
    }

    return state;
  }),
  on(deleteShapeFromFileAction, (state, { IRI }) => {
    delete state.selectedShapes[IRI];
    delete state.descriptors[IRI];
    delete state.shapes[IRI];
    return state;
  }),
  on(setPatchLoading, (state, { value }) => {
    state.isPatchLoading = value;
    return state;
  }),
  on(setFileLoading, (state, { value }) => {
    state.isFileLoading = value;
    return state;
  }),
  // TODO - implement
  on(addNewState, (state, { name, index }) => {
    return state;
  }),
  on(addNewSceneBase, (state, { name }) => {
    state.scenes.push({ name });
    return state;
  }),
  // TODO - implement
  on(removeState, (state, { name }) => {
    return state;
  }),
  // TODO - implement
  on(setCurrentState, (state, { name }) => {
    return state;
  }),
  on(setNewShapeAction, (state, { data }) => {
    state.newShapes[data.IRI] = true;
    return state;
  }),
  on(setShapeLabel, (state, { IRI, label }) => {
    state.shapes[IRI].literals.label = label;
    return state;
  }),
  on(toggleSelectAction, (state, { IRI }) => {
    state.selectedShapes[IRI] = !state.selectedShapes[IRI];
    return state;
  }),
  on(shiftSelectShapes, (state, { IRI }) => {
    // console.log('yooo');
    // const index = this.shape.index;
    // // -- // -- // -- //

    // const shapeArray = this.cs.previewShape.shapesByIndex;
    // const upward = shapeArray.filter(
    //   shape => shape.selected && shape.index < index
    // );

    // if (upward.length) {
    //   const last = upward[upward.length - 1];
    //   shapeArray
    //     .filter(
    //       shape => last.index < shape.index && shape.index < this.shape.index
    //     )
    //     .map(shape => shape.select());
    // }

    // shapeArray.reverse();
    // const downward = shapeArray.filter(
    //   shape => shape.selected && shape.index > index
    // );

    // if (downward.length) {
    //   const first = downward[0];
    //   shapeArray
    //     .filter(
    //       shape => this.shape.index < shape.index && shape.index < first.index
    //     )
    //     .map(shape => shape.select());
    // }
    return state;
  }),
  on(setBBoxOfShape, (state, { IRI, bBox }) => {
    // console.log('----- set-bBox -----', bBox);
    state.bBoxes[IRI] = bBox;
    return state;
  }),
  on(setShapeTranslateBase, (state, { IRI, translate }) => {
    _set(state.baseShapeTransforms, [IRI, 'translate'], translate);
    _set(state.currentShapeTransforms, [IRI, 'translate'], translate);
    return state;
  }),
  on(setCurrentShapeTranslate, (state, { IRI, translate }) => {
    _set(state.currentShapeTransforms, [IRI, 'translate'], translate);
    return state;
  }),
  on(setShapeScaleBase, (state, { IRI, scale }) => {
    _set(state.baseShapeTransforms, [IRI, 'scale'], scale);
    _set(state.currentShapeTransforms, [IRI, 'scale'], scale);
    return state;
  }),
  on(setShapeRotationBase, (state, { IRI, rotation }) => {
    _set(state.baseShapeTransforms, [IRI, 'rotation'], rotation);
    _set(state.currentShapeTransforms, [IRI, 'rotation'], rotation);
    return state;
  }),
  on(setBaseShapeTransform, (state, { IRI, transform }) => {
    // console.log('set-shape-transform', IRI, transform);
    _set(state.baseShapeTransforms, IRI, transform);
    _set(state.currentShapeTransforms, IRI, transform);
    return state;
  }),
  on(setCurrentShapeScale, (state, { IRI, scale }) => {
    _set(state.currentShapeTransforms, [IRI, 'scale'], scale);
    return state;
  }),
  on(setCurrentShapeRotation, (state, { IRI, rotation }) => {
    _set(state.currentShapeTransforms, [IRI, 'rotation'], rotation);
    return state;
  }),
  // this is called only once at the shape initialisation
  on(setShapeOriginalTransform, (state, { IRI, transform }) => {
    _set(state.originalShapeTransforms, [IRI], transform);
    _set(state.currentShapeTransforms, [IRI], transform);
    _set(state.baseShapeTransforms, [IRI], transform);
    return state;
  }),
  on(setShowComponentSearch, (state, { value }) => {
    state.showComponentSearch = value;
    return state;
  }),
  on(setCanvasPosition, (state, { position }) => {
    state.canvasPosition = position;
    return state;
  }),

  on(setFontSize, (state, { fontSize }) => {
    // -- //
    const { selectedShapes, descriptors } = state;

    // const textShapes =
    Object.keys(selectedShapes)
      .filter(IRI => descriptors[IRI].type == 'text-shape')
      .map(IRI => {
        (state.descriptors[IRI] as TextShapeDescriptor).fontSize = fontSize;

        if (!state.newShapes[IRI]) {
          state.patchedShapes[IRI] = true;
        }
      });

    return state;
  }),
);
