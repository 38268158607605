import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AttributePanelState } from '../../../services/animation/animation.types';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';

@Component({
  selector: 'nw-translate-attribute',
  templateUrl: './translate-attribute.component.html',
  styleUrls: ['./translate-attribute.component.scss'],
})
export class TranslateAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  @Input()
  showDelimiter = false;

  constructor(readonly store: Store) {
    super(store);
    this.key = 'translate';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('translate'));
    this.attributeState$.subscribe(attrState => {
      // -- // -- //
    });
  }
}
