import { BooleanService } from './boolean';
import { get } from 'lodash';

export interface Search {
  [key: string]: any;
}

export class ArrayService {
  public static get(val: string | string[]): string[] {
    return Array.isArray(val) ? val : [val];
  }

  public static push(object, key, value) {
    if (!object) {
      object = {};
    }
    if (Array.isArray(object[key])) {
      object[key].push(value);
    } else {
      object[key] = [value];
    }
  }

  public static remove(array: any[], element, thrw = false) {
    const index = array.findIndex(e => e === element || e.IRI == element.IRI);
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      if (thrw) {
        throw new Error(`Unable to find element '${element}' `);
      }
    }
  }

  public static increment(array: any[], index: number): number {
    if (array.length - 1 > index) {
      index++;
    }
    return index;
  }

  public static decrement(index: number): number {
    if (index > 0) {
      index--;
    }
    return index;
  }

  public static selectSingle(array: any[], search: Search): any {
    const result = array.filter(element => {
      const results = [] as boolean[];
      for (const key of Object.keys(search)) {
        results.push(this.match(key, element, search[key]));
      }
      const res = BooleanService.and(...results);
      return res;
    });

    switch (result.length) {
      case 0:
        return 0;
      case 1:
        return result[0];
      default:
        throw new Error('Select has multiple output');
    }
  }

  private static match(key: string, object: any, value: any): boolean {
    return get(object, key) === value;
  }

  static leftJoin(left: any[], right: any[], lKey?: string, rKey?: string) {
    const rightKeys = right.map(r => (rKey ? r[rKey] : r));
    return left.filter(l => !rightKeys.includes(lKey ? l[lKey] : l));
  }

  static rightJoin(left: any[], right: any[], lKey?: string, rKey?: string) {
    return this.leftJoin(right, left, lKey, rKey);
  }

  static innerJoin(left: any[], right: any[], lKey?: string, rKey?: string) {
    const rightKeys = right.map(r => (rKey ? r[rKey] : r));
    return left.filter(l => rightKeys.includes(lKey ? l[lKey] : l));
  }
}
