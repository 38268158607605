import { createSelector } from '@ngrx/store';
import { DataBag } from '../../../store/selectors';
import { EditorFeature } from '../editor.actions';
import { EditorState, SceneState } from '../reducer/editor.reducer';
import { get as _get, pick } from 'lodash';
import {
  AnimationItem,
  AnimationKeys,
  AnimationValue,
  CircleShapeDescriptor,
  IfPanelState,
  PathShapeDescriptor,
  SVGAttributes,
  Scene,
  TextShapeDescriptor,
  HandShapeNewDescriptor,
  FillAnimation,
  ShapeTransform,
  ShapePosition,
  ImportedShapeDescriptor,
  GeneralShapeDescriptor,
  PrimitiveValue,
} from '../../../elements/resource/types/shape.type';
import { selectAnimationFeature } from '../../animation/store/animation.reducer';
import {
  AttributePanelState,
  ShapeAttributeState,
} from '../../../services/animation/animation.types';
import { ResourceData } from '../../../elements/resource/resource.types';

const asc = (v1, v2) => (v1 > v2 ? 1 : -1);

export const selectMyScenes = (IRI: string) =>
  createSelector(selectEditorFeature, ({ files }) =>
    Object.values(files).filter(
      file => (file.relationships?.childOf as ResourceData)?.IRI == IRI,
    ),
  );

export const selectEditorFeature = (state: DataBag) =>
  state[EditorFeature] as EditorState;

export const selectFile = createSelector(
  selectEditorFeature,
  state => state?.file,
);

export const selectFiles = createSelector(
  selectEditorFeature,
  state => state?.files,
);

export const selectShapes = createSelector(
  selectEditorFeature,
  state => state.shapes,
);

export const selectComponents = createSelector(
  selectEditorFeature,
  state => state.components,
);

export const multiplicationByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => ({
    x: state.descriptors[IRI]?.multiplicationX,
    y: state.descriptors[IRI]?.multiplicationY,
  }));

export const dropShadowByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.descriptors[IRI]?.dropShadow,
  );

export const originalShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.originalShapeTransforms,
);
export const currentShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.currentShapeTransforms,
);
export const baseShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.baseShapeTransforms,
);

export const originalDescriptors = createSelector(
  selectEditorFeature,
  state => state.originalDescriptors,
);
export const currentDescriptors = createSelector(
  selectEditorFeature,
  state => state.currentDescriptors,
);

export const currentSVGAttributes = createSelector(
  selectEditorFeature,
  state => state.currentSVGAttributes,
);
export const baseSvgAttributes = createSelector(
  selectEditorFeature,
  state => state.baseSvgAttributes,
);

export const canvasBackgroundColor = createSelector(
  selectEditorFeature,
  state => state.file?.literals?.descriptor?.backgroundColor || '#ffffff',
);

export const canvasOrientation = createSelector(
  selectEditorFeature,
  state => state.file?.literals?.descriptor?.orientation || 'landscape',
);

export const baseSVGAttributesByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.baseSvgAttributes[IRI]);

export const baseShapeTransformByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.baseShapeTransforms[IRI]);

export const baseDescriptorByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.descriptors[IRI]);

export const currentSVGAttributesByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.currentSVGAttributes[IRI]);

export const selectDescriptors = createSelector(
  selectEditorFeature,
  state => state.descriptors,
);

export const colorPalette = createSelector(
  selectEditorFeature,
  state => state.colorPalette,
);

export const amISelected = (IRI: string) =>
  createSelector(selectEditorFeature, state => !state.selectedShapes[IRI]);

export const newShapes = createSelector(
  selectEditorFeature,
  state => state.newShapes,
);

export const bBoxes = createSelector(
  selectEditorFeature,
  state => state.bBoxes,
);

export const selectPatchedShapes = createSelector(
  selectEditorFeature,
  state => state.patchedShapes,
);

export const selectUpdatesByState = createSelector(
  selectEditorFeature,
  state => state.updatesByState,
);

export const deletedShapes = createSelector(
  selectEditorFeature,
  state => state.deletedShapes,
);

export const isPatchLoading = createSelector(
  selectEditorFeature,
  state => state.isPatchLoading,
);

export const selectSelectedShapes = createSelector(
  selectEditorFeature,
  state => state.selectedShapes,
);

export const sceneState = createSelector(
  selectEditorFeature,
  ({ currentScene, scenes }): SceneState => {
    return {
      current: currentScene || ['main'],
      scenes: scenes?.length ? scenes : [{ name: 'main' }],
    };
  },
);

export const selectCurrentScenes = createSelector(
  selectEditorFeature,
  ({ currentScene }) => currentScene,
);

export const selectScenes = createSelector(
  selectEditorFeature,
  ({ scenes }): Scene[] => {
    return scenes;
  },
);

export const selectStates = createSelector(
  selectEditorFeature,
  ({ states }) => states || [],
);

export const selectedSelectedState = createSelector(
  selectEditorFeature,
  ({ selectedState }) => selectedState,
);

export const isSaveActive = createSelector(
  selectEditorFeature,
  selectAnimationFeature,
  (state, animationState) => {
    return (
      !!Object.keys({
        ...state.patchedShapes,
        ...state.newShapes,
        ...state.deletedShapes,
      }).length ||
      state.fileChanged ||
      animationState.animationChanged
    );
  },
);

export const showAttributePanel = createSelector(
  selectEditorFeature,
  state => !!Object.keys(state.selectedShapes).length,
);

export const selectShapeByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.shapes[IRI]);

export const isShapeSelected = (IRI: string) =>
  createSelector(selectEditorFeature, state => !!state.selectedShapes[IRI]);

export const selectedShapes = createSelector(selectEditorFeature, state =>
  Object.keys(state.selectedShapes),
);

export const _selectedShapes = createSelector(
  selectEditorFeature,
  state => state.selectedShapes,
);

export const showComponentSearch = createSelector(
  selectEditorFeature,
  state => state.showComponentSearch,
);

export const selectShapePositionByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.shapes[IRI]?.literals.descriptor.position,
  );

export const selectShapeDescriptorByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.descriptors[IRI] || {});

export const selectCurrentShapeDescriptorByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.currentDescriptors[IRI] || {},
  );

export const selectCurrentShapeTransform = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.currentShapeTransforms[IRI] || {},
  );

export const selectCurrentShapeTranslate = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    const { updatesByState, selectedState } = state;

    let selectedPosition: Partial<ShapePosition> = {};

    if (selectedState && updatesByState[IRI]?.[selectedState]?.position) {
      // console.log(
      //   '---- selectCurrentShapeTranslate -----', IRI,
      //   updatesByState[IRI]?.[selectedState]?.position,
      // );
      selectedPosition = updatesByState[IRI]?.[selectedState]?.position;
      // return updatesByState[IRI]?.[selectedState]?.position;
    }
    return {
      x: selectedPosition.x || state.currentShapeTransforms[IRI]?.translate.x,
      y: selectedPosition.y || state.currentShapeTransforms[IRI]?.translate.y,
    };
  });

export const selectCurrentShapeScale = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    const { updatesByState, selectedState } = state;
    if (
      selectedState &&
      (updatesByState[IRI]?.[selectedState]?.position as ShapeTransform)?.scale
    ) {
      return (updatesByState[IRI]?.[selectedState]?.position as ShapeTransform)
        ?.scale;
    }

    return state.currentShapeTransforms[IRI]?.scale;
    // const { type } = state.descriptors[IRI] || {};
    // switch (type) {
    //   case 'circle-shape':
    //     return {
    //       x: 2 * (descriptor as CircleShapeDescriptor).rx,
    //       y: 2 * (descriptor as CircleShapeDescriptor).ry,
    //     };
    //     default:
    //     return state.currentShapeTransforms[IRI]?.scale;
    // }
  });

export const selectCurrentShapeRotation = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.currentShapeTransforms[IRI]?.rotation,
  );

export const selectShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.baseShapeTransforms,
);

const getDifferentAttribues = (svgAttributes: SVGAttributes[], key: string) => {
  const obj = {};

  try {
    svgAttributes
      .map(descriptor => _get(descriptor, key))
      .filter(val => !!val && val !== 'undefined' && val !== 'null')
      .map(val => {
        if (typeof val == 'object') {
          obj[JSON.stringify(val)] = true;
        } else {
          obj[val] = true;
        }
      });
  } catch (error) {
    console.log('error.getDifferentAttribues', error.message);
    return [];
  }

  return Object.keys(obj).map(key => {
    try {
      return JSON.parse(key);
    } catch (e) {
      return key;
    }
  });
};

export const showColorPanel = (attributeKey: string) =>
  createSelector(selectEditorFeature, state => {
    const svgAttributes = Object.keys(state.selectedShapes).map(
      IRI => state.currentSVGAttributes[IRI],
    );

    const attributes = getDifferentAttribues(
      svgAttributes,
      attributeKey,
    ).filter(e => !!e && e !== 'undefined' && e !== 'null');

    if (attributes.length == 1) {
      return true;
    }
    if (attributes.length > 1) {
      return true;
    }
    return false;
  });

export const attributeStateSelector = (key: string) =>
  createSelector(selectEditorFeature, state => {
    const svgAttributes = Object.keys(state.selectedShapes).map(
      IRI => state.currentSVGAttributes[IRI],
    );

    if (!svgAttributes.length) {
      return 'undefined';
    }

    const attributes = getDifferentAttribues(svgAttributes, key).filter(
      e => !!e && e !== 'undefined' && e !== 'null',
    );

    if (attributes.length == 1) {
      return 'defined';
    }
    if (attributes.length > 1) {
      return 'indefinite';
    }
    return 'undefined';
  });

export const getObjectValue = <T>(attributeKey: string) =>
  createSelector(selectEditorFeature, state => {
    const shapes = Object.keys(state.selectedShapes).map(
      IRI => state.shapes[IRI],
    );
    return shapes[0]?.literals?.descriptor?.svgAttributes?.[attributeKey] as T;
  });

export const differentSVGAttributes = <T>(attributeKey: string) =>
  createSelector(selectEditorFeature, state => {
    const object = Object.keys(state.selectedShapes)
      .map(IRI => state.currentSVGAttributes[IRI])
      .filter(descriptor => !!descriptor)
      .map(svgAttributes => svgAttributes?.[attributeKey])
      // .filter(val => !!val && val !== 'undefined' && val !== 'null')
      .reduce((object, value) => {
        if (typeof value == 'object') {
          object[JSON.stringify(value)] = true;
        } else {
          object[value || 'null'] = true;
        }
        return object;
      }, {});

    const arr = Object.keys(object).map(key => {
      try {
        return JSON.parse(key);
      } catch (e) {
        return key;
      }
    }) as T[];

    if (arr.length == 0 && attributeKey == 'stroke-width') {
      return [1] as T[];
    }
    return arr;
  });

export const getDifferentAttributes = <T>(attributeKey: string) =>
  createSelector(selectEditorFeature, state => {
    const object = Object.keys(state.selectedShapes)
      .map(IRI => state.currentSVGAttributes[IRI])
      .map(svgAttributes => _get(svgAttributes, attributeKey))
      .filter(val => !!val && val !== 'undefined' && val !== 'null')
      .reduce((object, value) => {
        if (typeof value == 'object') {
          object[JSON.stringify(value)] = true;
        } else {
          object[value] = true;
        }
        return object;
      }, {});

    const arr = Object.keys(object).map(key => {
      try {
        return JSON.parse(key);
      } catch (e) {
        return key;
      }
    }) as T[];

    if (arr.length == 0 && attributeKey == 'stroke-width') {
      return [1] as T[];
    }
    return arr;
  });

// export const getProjectColors = createSelector(selectEditorFeature, state => {
//   return Object.entries(
//     state.project?.literals?.descriptor?.colorPalette || {},
//   ).map(([label, color]) => ({ label, color }));
// });

export const getFile = createSelector(selectEditorFeature, state => state.file);

// export const files = createSelector(selectEditorFeature, state =>
//   Object.values(state.files).sort((f1, f2) =>
//     f1.literals.label > f2.literals.label ? 1 : -1,
//   ),
// );

export const isProjectLoading = createSelector(
  selectEditorFeature,
  state => state.isPatchLoading,
);

export const isFileLoading = createSelector(
  selectEditorFeature,
  state => !!state.isFileLoading,
);

// export const currentProjectID = createSelector(selectEditorFeature, state => {
//   return state.project?.IRI?.split('#')?.[1];
// });

// export const currentProject = createSelector(selectEditorFeature, state => {
//   return state.project;
// });

export const isShapeSelect = (IRI: string) =>
  createSelector(selectEditorFeature, state => !!state.selectedShapes[IRI]);

export const shapeList = createSelector(selectEditorFeature, state => {
  const { shapes, deletedShapes } = state;
  return Object.values(shapes)
    .filter(({ IRI }) => !deletedShapes[IRI])
    .sort(
      (s1, s2) => s1.literals.descriptor.index - s2.literals.descriptor.index,
    );
});

export const rootShapeList = createSelector(selectEditorFeature, state => {
  const { shapes, deletedShapes, file, childOf, descriptors } = state;
  return Object.values(shapes)
    .filter(({ IRI, relationships }) => {
      // by the group shape children there's no relationship
      return !deletedShapes[IRI] && childOf[IRI] == file.IRI;
      // return !deletedShapes[IRI] && !descriptors[IRI]?.childOf;
    })
    .sort((s1, s2) => descriptors[s1.IRI]?.index - descriptors[s2.IRI]?.index);
});

export const shapeListByScene = (scene: string) =>
  createSelector(
    selectEditorFeature,
    ({ shapes, file, descriptors, childOf }) => {
      const sceneMap =
        file.literals.descriptor._scenes?.reduce((object, { name }) => {
          object[name] = true;
          return object;
        }, {}) || {};

      const _shapes = Object.values(shapes)
        .filter(({ IRI }) => {
          // by the group shape children there's no relationship
          return !deletedShapes[IRI] && childOf[IRI] == file.IRI;
          // return !deletedShapes[IRI] && !descriptors[IRI]?.childOf;
        })
        // .filter(shape => {
        //   const ifDefinition = Object.keys(
        //     descriptors[shape.IRI]?.if || {},
        //   ).reduce((object, key) => {
        //     if (sceneMap[key] || key === 'main') {
        //       object[key] = descriptors[shape.IRI]?.if[key];
        //     }
        //     return object;
        //   }, {}) as Record<string, boolean>;

        //   if (!ifDefinition && scene == 'main') {
        //     return true;
        //   }

        //   return (
        //     !ifDefinition ||
        //     Object.keys(ifDefinition || {}).length == 0 ||
        //     ifDefinition[scene]
        //   );
        // })
        .sort(
          (s1, s2) => descriptors[s1.IRI]?.index - descriptors[s2.IRI]?.index,
        );
      return _shapes;
    },
  );

export const shapeAttribute = <T>(IRI: string, attributeKey: string) =>
  createSelector(
    selectEditorFeature,
    state => _get(state.descriptors, [IRI, attributeKey]) as T,
  );

export const showByIf = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    // const { sceneState: editorState } = state;

    return true;
    // const { current } = editorState || {};
    // if (!current) {
    //   return true;
    // }

    // const ifDefinition = state.descriptors[IRI]?.if;

    // if (!ifDefinition) {
    //   return true;
    // }

    // return ifDefinition[current];
  });

export const ifPanelState = createSelector(
  selectEditorFeature,
  ({ scenes, descriptors, selectedShapes }): IfPanelState => {
    if (!scenes || scenes.length == 1) {
      return {
        type: 'hide',
      };
    }

    // console.log('scenes', scenes);

    const allValues: Record<number, boolean> = {};
    const sceneValues = {};
    scenes.map(({ name: scene }) => (sceneValues[scene] = {}));

    Object.keys(selectedShapes).map(IRI => {
      const ifValue = descriptors[IRI]?.if;

      if (!ifValue) {
        allValues[1] = true;
        scenes.map(({ name: scene }) => {
          sceneValues[scene][1] = true;
        });
      } else if (Array.isArray(ifValue)) {
        // TODO - avoid that in the editor.effect
        // scenes.map(scene => {
        //   if (ifValue.includes(scene)) {
        //     sceneValues[scene][1] = true;
        //   } else {
        //     sceneValues[scene][0] = true;
        //   }
        // });
        // allValues[0] = true;
      } else if (ifValue) {
        scenes.map(({ name: scene }) => {
          if (ifValue[scene]) {
            sceneValues[scene][1] = true;
          } else {
            sceneValues[scene][0] = true;
          }
        });
      }
      // console.log('if-value', ifValue);
    });

    // console.log('scene-values', sceneValues);

    return {
      type: 'show',
      all: Object.keys(allValues).map(val => !!val),
      scenes: scenes.map(({ name: key }) => ({
        key,
        values: Object.keys(sceneValues[key]).map(val => !!+val),
      })),
    };
  },
);

export const file = createSelector(selectEditorFeature, state => state.file);

export const getAttributeValueOfShape = (
  key: AnimationKeys,
  currentAnimationId: string,
  baseData: {
    transform?: ShapeTransform;
    svgAttributes?: SVGAttributes;
    descriptor?: GeneralShapeDescriptor;
  },
  animations: Record<string, Partial<Record<AnimationKeys, AnimationItem>>>,
): ShapeAttributeState => {
  if (animations?.[currentAnimationId]?.[key]) {
    const item = animations[currentAnimationId]?.[key];
    if (item.fcn) {
      return {
        type: 'function',
        value: item.fcn,
      };
    }
    return {
      type: 'animation',
      value: item.value,
    };
  }

  const { transform, svgAttributes, descriptor } = baseData;
  switch (key) {
    case 'translate':
    case 'scale':
    case 'rotate':
      return {
        type: 'base',
        value: transform[key],
      };

    case 'fill':
      return {
        type: svgAttributes.fill ? 'base' : 'undefined',
        value: svgAttributes.fill,
      };
    case 'stroke':
      return {
        type: svgAttributes.stroke ? 'base' : 'undefined',
        value: svgAttributes.stroke,
      };
    default:
      return {
        type: descriptor?.[key] ? 'base' : 'undefined',
        value: descriptor[key],
      };
  }
};

export const mergeObjects = (objects: AnimationValue[]) => {
  const returnObject: Record<string, PrimitiveValue[]> = {};

  objects.map(object => {
    Object.entries(object || {}).map(([key, value]) => {
      returnObject[key] ||= [];
      if (value !== undefined && !returnObject[key].includes(value)) {
        returnObject[key].push(value);
      }
    });
  });

  Object.entries(returnObject).map(([key, value]) => {
    returnObject[key] = value.sort((v1, v2) => (v1 > v2 ? 1 : -1));
  });

  return returnObject;
};

export const _attributeState = (attributeKey: AnimationKeys) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (
      {
        selectedShapes,
        baseShapeTransforms,
        baseSvgAttributes,
        descriptors,
        noAnimationMode,
        selectedState,
        updatesByState,
      },
      { currentAnimationId, animationsByShape, functions: animationFunctions },
    ): AttributePanelState => {
      const values = Object.keys(selectedShapes).map(shapeIRI =>
        getAttributeValueOfShape(
          attributeKey,
          noAnimationMode ? '' : currentAnimationId,
          {
            transform: baseShapeTransforms[shapeIRI],
            svgAttributes: baseSvgAttributes[shapeIRI],
            descriptor: descriptors[shapeIRI],
          },
          animationsByShape[shapeIRI],
        ),
      );
      const isThereBase = !!values.find(({ type }) => type == 'base');
      const isThereAnimation = !!values.find(({ type }) => type == 'animation');
      const isThereFunction = !!values.find(({ type }) => type == 'function');
      const isThereUndefined = !!values.find(({ type }) => type == 'undefined');

      const optionCount = [
        isThereBase,
        isThereAnimation,
        isThereFunction,
        isThereUndefined,
      ].filter(val => val).length;

      if (optionCount > 1) {
        return { type: 'mixed' };
      }

      const functions = Object.keys(animationFunctions[attributeKey] || {});

      if (isThereFunction) {
        const selectedFunctions = values.reduce((prev, { value }) => {
          if (!prev.includes(value as string)) {
            prev.push(value as string);
          }
          return prev;
        }, [] as string[]);

        return {
          type: 'function',
          functions,
          selectedFunctions,
        };
      }

      if (isThereBase) {
        return {
          type: 'base',
          functions,
          value: mergeObjects(values.map(({ value }) => value)),
        };
      }

      if (isThereAnimation) {
        return {
          type: 'animation',
          functions,
          value: mergeObjects(values.map(({ value }) => value)),
        };
      }

      if (isThereUndefined) {
        return {
          type: 'undefined',
          functions,
          value: null,
        };
      }

      return null;
    },
  );

export const attributeState = (attributeKey: AnimationKeys) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (
      {
        selectedShapes,
        baseShapeTransforms,
        baseSvgAttributes,
        descriptors,
        noAnimationMode,
        selectedState,
        updatesByState,
      },
      { currentAnimationId, animationsByShape, functions: animationFunctions },
    ): AttributePanelState => {
      const selectedShapeIRIs = Object.keys(selectedShapes);
      if (selectedShapeIRIs.length == 0) {
        return {
          type: currentAnimationId ? 'animation' : 'base',
          state: 'undefined',
        };
      }
      let type: 'base' | 'animation';

      function getReturnValuesAndState(
        objects: any[],
      ): ['defined' | 'mixed', Record<string, any[]> | any[]] {
        const returnValue = {};
        const primitiveValues = [];
        let state: 'defined' | 'mixed' = 'defined';
        let primitiveValue = false;

        objects.map(object => {
          if (typeof object == 'object') {
            Object.entries(object || {}).map(([key, val]) => {
              returnValue[key] ||= [];
              if (!returnValue[key].includes(val)) {
                returnValue[key].push(val);
              }
            });
          } else {
            primitiveValue = true;
            if (!primitiveValues.includes(object)) {
              primitiveValues.push(object);
            }
          }
        });

        if (primitiveValue) {
          state = primitiveValues.length > 1 ? 'mixed' : 'defined';
          return [state, primitiveValues];
        }

        const deleteKeys = [];
        Object.keys(returnValue).map(key => {
          switch (returnValue[key].length) {
            case 0:
              deleteKeys.push(key);
              break;
            case 1:
              if (!returnValue[key][0]) {
                deleteKeys.push(key);
              }
              break;
            default:
              returnValue[key] = returnValue[key].sort(asc);
              break;
          }
        });

        deleteKeys.map(key => delete returnValue[key]);

        return [state, returnValue];
      }

      const fcnNames = Object.keys(animationFunctions?.[attributeKey] || {});
      const functions = fcnNames.length ? fcnNames : undefined;

      if (currentAnimationId && !noAnimationMode) {
        type = 'animation';

        if (currentAnimationId.includes('_')) {
          const ids = currentAnimationId.split('_');
          currentAnimationId = ids[ids.length - 1];
        }

        const items: AnimationItem[] = selectedShapeIRIs.map(IRI => {
          if (attributeKey == 'fill') {
            if (!animationsByShape[IRI]?.[currentAnimationId]?.fill) {
              return;
            }

            return {
              key: attributeKey,
              value: {
                color: animationsByShape[IRI]?.[currentAnimationId]?.fill.value,
                gradient: false,
              } as FillAnimation,
            };
          }

          return animationsByShape[IRI]?.[currentAnimationId]?.[attributeKey];
        });

        const isThereDefined = !!items.find(value => !!value && !value.fcn);
        const isThereUndefined = !!items.filter(value => !value).length;
        const isThereFunction = !!items.find(value => !!value?.fcn);

        const cnt = [isThereDefined, isThereUndefined, isThereFunction].filter(
          v => v,
        ).length;

        if (cnt > 1) {
          return { type, state: 'mixed', functions };
        }

        if (isThereUndefined) {
          return { type, state: 'undefined', functions };
        }

        if (isThereFunction) {
          const fcnKeys = items.reduce((object, item) => {
            object[item.fcn] = true;
            return object;
          }, {});

          if (Object.keys(fcnKeys).length > 1) {
            return {
              type,
              state: 'mixed',
              selectedFunctions: Object.keys(fcnKeys).sort(asc),
              isFunction: true,
              functions,
            };
          } else {
            const [fcn] = Object.keys(fcnKeys);

            if (fcn == 'original') {
              if (Object.keys(selectedShapes).length > 1) {
                return {
                  type,
                  state: 'mixed',
                  selectedFunctions: Object.keys(fcnKeys).sort(asc),
                  isFunction: true,
                  functions,
                };
              }
              const [IRI] = Object.keys(selectedShapes);
              let value: AnimationValue;
              switch (attributeKey) {
                case 'scale':
                  value = baseShapeTransforms[IRI].scale;
                  break;
                case 'translate':
                  value = baseShapeTransforms[IRI].translate;
                  break;
                case 'rotation':
                  console.log(
                    'rotation-state',
                    baseShapeTransforms[IRI].rotation,
                  );
                  value = baseShapeTransforms[IRI].rotation || { angle: 0 };
                  break;
                case 'stroke':
                  value = baseSvgAttributes[IRI].stroke;
                  break;
                  // case 'fill':
                  //   value = baseSvgAttributes[IRI].fill;

                  break;
              }
              // -- //
              return {
                type,
                state: 'defined',
                isFunction: true,
                selectedFunctions: [fcn],
                functions,
                value,
              };
            }

            return {
              type,
              state: 'defined',
              isFunction: true,
              selectedFunctions: [fcn],
              functions,
              value: animationFunctions?.[attributeKey]?.[fcn].value as any,
            };
          }
        }

        // there is no function just values //
        const values = items.map(item => item.value);
        const [state, value] = getReturnValuesAndState(values);
        return { type, state, value, functions };
      } else {
        let values: AnimationValue[];
        type = 'base';

        switch (attributeKey) {
          // case 'fill':
          //   values = selectedShapeIRIs.map(IRI => ({
          //     color: baseSvgAttributes[IRI]?.fill,
          //     gradient: !!baseSvgAttributes[IRI]?.gradient,
          //     gradientColor: baseSvgAttributes[IRI]?.gradientColor,
          //     gradientDirection: baseSvgAttributes[IRI]?.gradientDirection,
          //   })) as FillAnimation[];
          //   break;
          case 'stroke':
          case 'stroke-width':
          case 'opacity':
            values = selectedShapeIRIs.map(
              IRI => baseSvgAttributes[IRI]?.[attributeKey],
            );
            break;
          case 'translate':
            values = selectedShapeIRIs.map(IRI => {
              if (
                selectedState &&
                updatesByState[IRI]?.[selectedState]?.position?.x
              ) {
                return pick(updatesByState[IRI]?.[selectedState]?.position, [
                  'x',
                  'y',
                ]);
              }
              return baseShapeTransforms[IRI]?.[attributeKey];
            });
            break;
          case 'scale':
          case 'rotation':
            values = selectedShapeIRIs.map(IRI => {
              if (
                selectedState &&
                updatesByState[IRI]?.[selectedState]?.position?.[attributeKey]
              ) {
                console.log('------ hey > scale ------');
                return updatesByState[IRI]?.[selectedState]?.position?.[
                  attributeKey
                ];
              }
              return baseShapeTransforms[IRI]?.[attributeKey];
            });
            break;
          case 'config':
            values = selectedShapeIRIs
              .filter(IRI => descriptors[IRI]?.type == 'hand-shape')
              .map(IRI => descriptors[IRI]?.[attributeKey]);
            break;

          // case 'env':
          //   // -- //
          //   // values = selectedShapeIRIs
          //   //   .map(IRI => descriptors[IRI])
          //   //   .filter(descriptor => {
          //   //     return (
          //   //       descriptor.type == 'imported-shape' &&
          //   //       (descriptor as ImportedShapeDescriptor).baseShapeDescriptor
          //   //         .inputs?.length
          //   //     );
          //   //   })
          //   //   .map(descriptor => descriptor.env);
          //   values = selectedShapeIRIs
          //     .map(IRI => descriptors[IRI])
          //     .filter(descriptor => {
          //       return (
          //         descriptor.type == 'imported-shape' &&
          //         (descriptor as ImportedShapeDescriptor).baseShapeDescriptor
          //           .inputs?.length
          //       );
          //     })
          //     .map(descriptor => descriptor.env);
          //   break;

          default:
            values = selectedShapeIRIs.map(IRI => {
              return descriptors[IRI]?.[attributeKey];
            });
            break;
        }

        if (!values.length) {
          return { type: 'base', state: 'undefined' };
        }

        if (attributeKey == 'stroke') {
          // console.log('values', values);
        }

        const isThereDefined = !!values.find(value => !!value);
        const isThereUndefined = !!values.filter(value => !value).length;

        if (isThereDefined && isThereUndefined) {
          return { type: 'base', state: 'mixed', functions };
        }

        if (isThereUndefined && !isThereDefined) {
          return { type: 'base', state: 'undefined', functions };
        }

        if (attributeKey == 'handSections') {
          return {
            type,
            state: values.length ? 'defined' : 'undefined',
            value: values[0],
          };
        }

        const [state, value] = getReturnValuesAndState(values);
        return {
          type,
          state,
          value,
          functions,
        };
      }
    },
  );

export const onlyShapeType = (
  type: 'hand-shape' | 'path-shape' | 'text-shape' | 'circle-shape',
) =>
  createSelector(selectEditorFeature, ({ selectedShapes, descriptors }) => {
    return !Object.keys(selectedShapes).find(
      IRI => descriptors[IRI].type !== type,
    );
  });
export const canvasPosition = createSelector(
  selectEditorFeature,
  ({ scenes, currentScene }) => {
    if (!currentScene || currentScene.length < 2) {
      return;
    }
    const mainScene = currentScene[0];
    const subScene = currentScene[1];
    const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
    const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    return scenes[mainSceneIndex].subscenes[subSceneIndex].position;
  },
);

export const selectTextDescriptorByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors }): TextShapeDescriptor =>
      (descriptors[IRI] as TextShapeDescriptor)?.textConfig,
  );

export const textAttributeState = createSelector(
  selectEditorFeature,
  ({ selectedShapes, descriptors }) => {
    const textShapes = Object.keys(selectedShapes)
      .map(IRI => descriptors[IRI])
      .filter(descriptor => descriptor?.type == 'text-shape');

    if (!textShapes.length) {
      return {
        type: 'hide',
      };
    }

    return {
      type: 'show',
      text: (textShapes[0] as TextShapeDescriptor).text,
      fontSize: (textShapes[0] as TextShapeDescriptor).fontSize,
      letterSpacing: (textShapes[0] as TextShapeDescriptor).letterSpacing,
    };
  },
);

export const selectStateOptions = createSelector(selectEditorFeature, state => {
  const { descriptors, selectedShapes } = state;
  const IRI = Object.keys(selectedShapes)[0];
  if (!IRI) {
    return [];
  }
  return (
    (descriptors[IRI] as ImportedShapeDescriptor)?.baseShapeDescriptor
      ?.states || []
  );
});

export const selectCurrentFileID = createSelector(
  selectEditorFeature,
  state => {
    return state.file?.IRI.split('#')[1] || state.file?.IRI.split(':')[1];
  },
);

export const selectNoAnimationMode = createSelector(
  selectEditorFeature,
  state => state.noAnimationMode,
);
export const selectFontLoaded = createSelector(
  selectEditorFeature,
  state => state.fontLoaded,
);

export const loadedScenes = createSelector(
  selectEditorFeature,
  state => state.loadedScenes,
);

export const selectOpacityByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    const opacity = state.currentDescriptors[IRI]?.opacity;
    return isNaN(opacity) ? 1 : opacity;
  });

export const selectFloatEffectByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return state.currentDescriptors[IRI]?.floatEffect;
  });
export const selectPathSectionsByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return (state.currentDescriptors[IRI] as PathShapeDescriptor)?.sections;
  });

export const selectHandShapeConfig = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return (state.currentDescriptors[IRI] as HandShapeNewDescriptor)?.config;
  });

export const selectCurrentBackgroundColor = createSelector(
  selectEditorFeature,
  ({ file }) => {
    if (!file) {
      return;
    }
    const descriptor = file.literals.descriptor;
    const { backgroundColor } = descriptor || {};
    return backgroundColor || '#ffffff';
  },
);

export const selectDescriptorValueByIRI = (IRI: string, key: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors }) => descriptors[IRI]?.[key],
  );

export const selectArcIntervalByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors: currentDescriptors }) =>
      (currentDescriptors[IRI] as CircleShapeDescriptor)?.arcInterval,
  );
