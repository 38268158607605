import * as _ from 'lodash';

export const BETWEEN = /(?<=\()(.*)(?=\))/;
// import * as assert from 'assert';

export interface Match {
  match?: string;
  remaining: string;
}

export class Regex {
  static before(string: string, chars: string) {
    return new RegExp(`.+?(?=${chars})`).exec(string)?.[0];
  }

  static after(start: string, text: string) {
    const regex = new RegExp(`(?<=${start})(.*)`);
    return regex.exec(text)?.[0];
  }

  static between(start: string, end: string, str: string) {
    start = _.escapeRegExp(start);
    end = _.escapeRegExp(end);
    const regex = new RegExp(`(?<=${start})(.*)(?=${end})`);
    return this.matchAndReplace(regex, str).match;
  }

  static get(string: string, regexp: RegExp | string): string {
    return new RegExp(regexp).exec(string)?.[0];
  }

  static _get(
    string: string,
    regexp: RegExp | string,
  ): [string, number] | undefined {
    const result = new RegExp(regexp).exec(string);
    if (!result) {
      return;
    }

    return [result[0], result.index];
  }

  static matchAll(string: string, regexp: RegExp): string[] {
    const output = [];
    let match = {};
    const regex = new RegExp(regexp, 'g');
    const i = 0;
    while ((match = regex.exec(string))) {
      if (!match[0]) {
        break;
      }
      output.push(match[0]);
      // assert(i++ < 10, 'Something is wrong with the regex. To much loop');
    }
    return output;
  }

  static matchNthAndReplace(
    regexp: RegExp,
    string: string,
    n: number,
    replaceWith = '',
  ): string {
    const results = this.matchAll(string, regexp);
    return string.replace(results[n], replaceWith);
  }

  static matchAndReplace(
    regexp: RegExp,
    string: string,
    replaceWith = '',
  ): Match {
    const result = new RegExp(regexp).exec(string);
    if (!result) {
      return { remaining: string };
    }
    return { match: result[0], remaining: string.replace(regexp, replaceWith) };
  }

  static removeWhiteSpaces(str: string) {
    return str.replace(/\s/g, '');
  }
}
