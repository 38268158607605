<div
  class="float-effect-attribute-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Float"
    [attributeState]="attributeState"
    key="floatEffect"
    [initialValue]="{ x: 0, xInterval: 0, y: 0, yInterval: 0 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4 fs-12"
  >
    <div class="flex-row space-between">
      <div>x</div>
      <nw-number-input
        [value]="attributeState.value['x']"
        (changed)="updateSubAttributeValue('x', $event)"
      >
      </nw-number-input>
    </div>

    <div class="flex-row space-between">
      <div>xInterval</div>
      <nw-number-input
        [value]="attributeState.value['xInterval']"
        (changed)="updateSubAttributeValue('xInterval', $event)"
      >
      </nw-number-input>
    </div>

    <div class="flex-row space-between">
      <div>y</div>
      <nw-number-input
        [value]="attributeState.value['y']"
        (changed)="updateSubAttributeValue('y', $event)"
      >
      </nw-number-input>
    </div>

    <div class="flex-row space-between">
      <div>yInterval</div>
      <nw-number-input
        [value]="attributeState.value['yInterval']"
        (changed)="updateSubAttributeValue('yInterval', $event)"
      >
      </nw-number-input>
    </div>
  </div>
</div>
