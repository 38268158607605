import { Injectable } from '@angular/core';
import { AnimationFrame } from '../element-editor/animation/components/animation-frame/animation.types';
import {
  ChainExpression,
  Expression,
  ExpressionType,
  MethodExpression,
  MSFunction,
  MSType,
  MSTypeDef,
  ObjectExpression,
} from './ms.types';
import { MSExpression } from './parser/ms-expression.stat';
import { ScatterConfig } from '../elements/resource/types/shape.type';
import { GeneralShape } from '../element-editor/shape/shapes/general/general-shape';
import { MSEvaluationService } from './evaluation/evaluation.service';

const typeDef = {
  Shape: {
    functions: {
      stroke: {
        params: [
          {
            type: MSType.Object,
            body: {
              color: MSType.Color,
              width: { type: MSType.Number, optional: true },
            },
          },
          MSType.Number,
        ],
        returns: 'Shape',
      },
      fill: {
        params: [
          {
            type: MSType.Object,
            body: {
              color: MSType.Color,
              opacity: { type: MSType.Number, optional: true },
            },
          },
          MSType.Number,
        ],
        returns: 'Shape',
      },
      delay: {
        params: [MSType.Number],
        returns: 'Shape',
      },
      hide: {
        params: [MSType.Number],
        returns: 'Shape',
      },
      show: {
        params: [MSType.Number],
        returns: 'Shape',
      },
    },
  } as MSTypeDef,
};

export type Context = Record<string, string>;

@Injectable()
export class MetascriptService {
  constructor() {}

  validateMethod(input: MethodExpression, type: MSFunction) {
    // --> //
  }

  setAnimationByCode(shape: GeneralShape, code: string) {
    // -- //
  }

  getAnimationByCode(shape: GeneralShape, code: string) {
    const pojo = MSExpression.getPojo(code);
    console.log('pojo', pojo);
    if (pojo.expr) {
      return { error: 'Invalid ms expr' };
    }

    const { type, value, params } = pojo as MethodExpression;
    if (type != ExpressionType.Method) {
      return;
    }

    if (value == 'translate') {
      if (params.length != 1) {
        return { error: 'translate function has one object parameter' };
      }

      const [paramExp] = params;

      if (paramExp.type != ExpressionType.Object) {
        return { error: 'the only parameter must be an object' };
      }

      const object = MSEvaluationService.resolve(
        shape,
        paramExp as ObjectExpression,
      );
      return {
        data: {
          key: 'transform',
          value: object,
        },
      };
    }

    return { error: 'Only translate object is processed right now' };
  }

  evalParams(shape: GeneralShape, params: Expression[]) {
    return params.map(param => MSEvaluationService.resolve(shape, param));
  }

  getAnimation(
    context: Context,
    expr: ChainExpression,
  ): { animation: AnimationFrame; error: any } {
    const { chain } = expr;
    // --> // --> // -- // -- // -- //

    return null;
  }

  getAnimationFrameByMS(expr: string) {
    const pojo = MSExpression.getPojo(expr);
    return this.getAnimationFrame(pojo as ChainExpression);
  }

  getAnimationFrame(expr: ChainExpression): {
    frame?: AnimationFrame;
    error?: any;
  } {
    const chain = expr.chain;

    const field = chain.shift();
    if (field.type !== ExpressionType.Field) {
      return {
        error: {
          pos: field.value?.length || 0,
          message: 'First element must be field',
        },
      };
    }

    const functionTarget = { targetAlias: field.value };

    const frame = this.getFrameFromChain(chain);

    return {
      frame: {
        functionTarget,
        ...frame,
      },
    };
  }

  getFrameFromChain(chain: Expression[]) {
    // --> // --> //
    const frame = this.getFrameFromExpression(
      chain.shift() as MethodExpression,
    );

    if (!chain.length) {
      return frame;
    }

    let current = frame;
    for (const expr of chain) {
      const next = this.getFrameFromExpression(expr as MethodExpression);
      current.next = next;
      current = current.next;
    }
    return frame;
  }

  getFrameFromExpression(expr: MethodExpression): AnimationFrame {
    const [value, duration] = expr.params.map(({ value }) => value);
    switch (expr.value) {
      case 'strokeWidth':
        return {
          baseAction: {
            key: 'svgAttributes.stroke-width',
            value,
          },
          duration,
        };
      case 'fill':
        return {
          baseAction: {
            key: 'svgAttributes.fill',
            value,
          },
          duration,
        };
      case 'blink':
        const frequency = value as number;
        const frames = duration * 2 * frequency;
        const time = 1 / (2 * frequency);

        const chain: MethodExpression[] = Array.from(
          Array(2 * frames - 1).keys(),
        ).map(index =>
          index % 2
            ? {
                type: ExpressionType.Method,
                value: 'delay',
                params: [
                  {
                    type: ExpressionType.ConstantNumber,
                    value: time * 0.9,
                  },
                ],
              }
            : {
                type: ExpressionType.Method,
                value: Math.floor(index / 2) % 2 ? 'appear' : 'disappear',
                params: [
                  {
                    type: ExpressionType.ConstantNumber,
                    value: time * 0.1,
                  },
                ],
              },
        );
        // console.log('chain', JSON.stringify(chain, null, 2)); //
        return this.getFrameFromChain(chain);
      case 'delay':
        return {
          duration: expr.params.map(({ value }) => value)?.[0],
          delay: true,
        };
      case 'appear':
      case 'disappear':
        return {
          duration: expr.params.map(({ value }) => value)?.[0],
          baseAction: {
            key: expr.value,
          },
        };
      default:
        return {
          duration: 0,
          baseAction: {
            key: expr.value,
          },
        };
    }
  }

  getShapeExtension(code: string) {
    // .. //
  }

  processCode(code: string) {
    code.split('\n').map(line => {
      try {
        const expr = MSExpression.getPojo(line);
        if (expr.type == ExpressionType.Method) {
          if (expr.value == 'scatter') {
          }
        }
      } catch (error) {
        // -- //
      }
    });
  }

  compile(code: string) {
    return MSExpression.getPojo(code);
  }

  getScatterParams(expr: ObjectExpression): ScatterConfig {
    const returnObject = {};
    Object.entries(expr.object).map(([key, value]) => {
      if (key == 'cnt') {
        returnObject[key] = value.value;
      } else {
        const { params } = value;
        if (params?.length != 2) {
          return;
        }
        const [start, end] = params.map(({ value }) => value);
        returnObject[key] = [start, end];
      }
    });
    return returnObject as ScatterConfig;
  }

  getScatter(code: string) {
    // -- // -- //

    const expr = MSExpression.getPojo(code);

    // -- // -- // -- //
  }
}
