import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { _attributeState } from '../../store/selector/editor.selector';

@Component({
  selector: 'nw-text-attributes',
  templateUrl: './text-attributes.component.html',
  styleUrls: ['./text-attributes.component.scss'],
})
export class TextAttributesComponent
  extends BaseAttributeComponent
  implements OnInit
{
  text: string;
  fontSize: number;

  constructor(readonly store: Store) {
    super(store);
    this.key = 'textConfig';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('textConfig'));
    this.attributeState$.subscribe(state => {
      // console.log('text-attr.state', state);
      // this.text = state.text;
      // this.fontSize = state.fontSize;
    });
  }

  changeText(event: Event) {
    const text = (event.target as HTMLInputElement).value;
    console.log('set-text', text);
    this.updateSubAttributeValue('text', text);
  }
}
