

export class BooleanService {

  public static and(... inputs: boolean[]) {

    if(!inputs || inputs.length === 0) {
      return false;
    }
    let result = inputs.shift() as boolean;
    for (const input of inputs) {
      result = result && input;
    }
    return result;
  }

  public static or(... inputs: boolean[]) {

    let result = inputs.shift() as boolean;
    for (const input of inputs) {
      result = result || input;
    }
    return result;
  }


}
