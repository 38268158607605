import { StoreService } from '../../store/store.service';
import { CanvasService } from '../../services/canvas/canvas.service';
import { ResourceData } from '../../elements/resource/resource.types';
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
} from '@angular/core';
import {
  ShapeDescriptor,
  ContainerShapeDescriptor,
  Coords,
  CurveSectionDescriptor,
  CircleShapeDescriptor,
  GeneralShapeDescriptor,
  PathShapeDescriptor,
  RectangleShapeDescriptor,
} from '../../elements/resource/types/shape.type';
import { clone as _cloneDeep, omit as _omit, uniq } from 'lodash';
import { safeDump, safeLoad } from 'js-yaml';

import { PathShape } from '../shape/shapes/path-shape/path-shape';
import { AnimationFrame } from '../animation/components/animation-frame/animation.types';
import { RootShape } from '../shape/shapes/general/root/root-shape';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GeneralShape } from '../shape/shapes/general/general-shape';
import { ShapeService } from '../shape/shape.service';
import { Store } from '@ngrx/store';
import { Regex } from '../../services/util/regex';
import { Observable } from 'rxjs';
import {
  isFileLoading,
  isPatchLoading,
  showComponentSearch,
} from '../store/selector/editor.selector';
import { filter, map } from 'rxjs/operators';
import { AnimationService } from '../animation/animation.service';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'iw-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;

  @ViewChild('container') container: ElementRef;

  @Input() recordMode = false;

  isPatchLoading$: Observable<boolean>;
  isFileLoading$: Observable<boolean>;

  codeMirrorOptions = {
    mode: 'javascript',
    lineNumbers: true,
  };

  newTab = null;
  yaml: string;
  time: number;

  currentId: string;
  localStore: Record<string, { resource: ResourceData }> = {};
  command = false;
  forceFetch = false;
  newDescriptor: ShapeDescriptor;

  dragState: { x: number; y: number };
  dragRectangle: { x: number; y: number; width: number; height: number };

  get resourceIRI() {
    return this.cs.currentResourceData.IRI;
  }

  get showCodeEditor() {
    if (!this.cs.previewShape) {
      return false;
    }
    return this.cs.selectedShapes.length == 1;
  }

  showComponentSearch$: Observable<boolean>;

  get previewShape() {
    return this.cs.previewShape;
  }

  set previewShape(shape: RootShape) {
    this.cs.previewShape = shape;
  }

  get translated() {
    if (!this.previewShape?.currentMatrix) {
      return { x: undefined, y: undefined };
    }
    const [x, y] = [
      this.previewShape.currentMatrix.e,
      this.previewShape.currentMatrix.f,
    ];
    const scale = this.previewShape.currentMatrix.a;
    return {
      x: (this.cs.canvasX - x) / scale,
      y: (this.cs.canvasY - y) / scale,
      cx: this.cs.canvasX,
      cy: this.cs.canvasY,
      tx: x,
      ty: y,
      scale: scale,
    };
  }

  get calculated() {
    return this.cs.getAbsoluteCoords(this.cs.canvasX, this.cs.canvasY, true);
  }

  get currentResourceData() {
    return this.cs.currentResourceData;
  }

  get label() {
    return this.currentResourceData.literals.label;
  }

  get componentType() {
    return 'element-editor';
  }

  set descriptor(value: ShapeDescriptor) {
    if (!this.localStore[this.currentId]) {
      return;
    }
    this.localStore[this.currentId].resource.literals.descriptor = value;
  }

  get descriptor() {
    return this.currentResourceData.literals.descriptor;
  }

  get shapes() {
    return this.previewShape?.ref('shapes') || [];
  }

  get selectedShapes() {
    return this.shapes.filter((ps: PathShape) => ps.selected);
  }

  imageUploadState: string;

  @Input()
  fixCanvas = false;
  async onFileDropped(event: any) {
    this.imageUploadState = 'in-progress';
    const { files, coords } = event;
    const file = files[0];
    console.log('onFileDropped', file);
    console.log('file.type', file.type);
    if (file.type.endsWith('svg+xml')) {
      return this.parseSVG(file);
    }

    if (this.cs.previewShape) {
      const { data: base64, error: e1 } = await this.cs.toBase64(file);

      if (e1) {
        throw new Error(
          `Base64 conversion of the uploaded file resulted an error: ${e1.message}`,
        );
      }

      // const { data: s3Id, error: e2 } = await this.cs.saveImageToS3(base64);
      // if (e2) {
      //   throw new Error(`Image could not be saved to S3: ${e1.message}`);
      // }

      this.cs.previewShape.addImageShape(null, coords, base64);

      // const is = new ImageShapeByFile(
      //   this.cs,
      //   {
      //     literals: {
      //       label: 'abc',
      //       descriptor: {
      //         position: {
      //           x: 100,
      //           y: 100,
      //         },
      //         s3Id: null,
      //       },
      //     },
      //   },
      //   file
      // );
      // this.cs.previewShape.containerForChildren.addChild(is.container);
    }
  }

  maskRecord: Record<string, number> = {};

  async parseSVG(file) {
    this.maskRecord = {};

    const text = await file.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, 'text/xml');

    const _paths = xmlDoc.getElementsByTagName('path');
    const [svg] = Array.from(xmlDoc.children);
    // -- //
    const paths = svg.children as HTMLCollectionOf<SVGElement>;
    const descriptors: GeneralShapeDescriptor[] = [];
    let index = 0;
    for (const element of Array.from(paths)) {
      // -- // -- // -- // -- //
      const [newDescriptors, newIndex] = this.getDescriptorByElement(
        element,
        index,
      );

      descriptors.push(...newDescriptors);
      index = newIndex;
    }

    this.shapeService.addShapesByDescriptor(descriptors, this.maskRecord);
  }

  getDescriptorByElement(
    element: SVGElement,
    index: number,
    mask?: string,
  ): [GeneralShapeDescriptor[], number] {
    let fill = element.getAttribute('fill');
    const stroke = element.getAttribute('stroke');
    const strokeWidthParam = element.getAttribute('stroke-width');
    const strokeWidth = isNaN(+strokeWidthParam)
      ? stroke
        ? 1
        : undefined
      : +strokeWidthParam;
    const opacity = +element.getAttribute('opacity');
    if (!mask && element.getAttribute('mask')) {
      mask = Regex.between('url(#', ')', element.getAttribute('mask'));
    }

    const descriptors = [];

    const style = element.getAttribute('style');
    for (const s of style?.split(';') || []) {
      if (s.startsWith('fill')) {
        fill = s.split(': ')[1].trim();
      }
    }

    function componentToHex(c) {
      const hex = (+c).toString(16).padStart(2, '0');
      return hex.length == 1 ? '0' + hex : hex;
    }

    if (fill?.startsWith('rgb(')) {
      fill =
        '#' +
        fill
          .slice(0, fill.length - 2)
          .slice(4)
          .split(',')
          .map(val => componentToHex(val))
          .join('');
    }

    // console.log('fill', fill);

    if (element instanceof SVGMaskElement) {
      const id = element.getAttribute('id');

      this.maskRecord[id] = index;
      // -- // -- // -- // -- // -- //

      for (const maskChild of Array.from(element.children)) {
        const [maskElementDescriptor, newIndex] = this.getDescriptorByElement(
          maskChild as SVGElement,
          index,
        );
        descriptors.push(...maskElementDescriptor);
        index = newIndex;
      }
    } else if (element instanceof SVGPathElement) {
      const path = element as SVGPathElement;
      let d = path.getAttribute('d');

      try {
        const transform = path.getAttribute('transform');
        let px: number,
          py: number,
          cx = 0,
          cy = 0;
        if (transform) {
          [px, py] = transform
            .slice(10)
            .split(',')
            .map(val => +/[\d.]*/.exec(val)[0]);
        }

        const isClosed = d.endsWith('Z');
        if (isClosed) {
          // removing Z from the end
          d = d.slice(0, d.length - 1);
        }

        d = d.replace('\n', '');
        d = d.replace(/,/g, ' ');

        for (const _d of d.split('M')) {
          if (!_d) {
            continue;
          }

          const [first, ...elements] = Regex.matchAll(
            _d,
            /([HVCL]{0,1}[-*\d*\.*\s]*)/,
          ).map(e => e.trim());

          const [mx, my] = first.split(' ').map(v => +v);

          if ((!isNaN(mx) && mx !== 0) || (!isNaN(my) && my !== 0)) {
            px = mx || 0;
            py = my || 0;
            cx = mx || 0;
            cy = my || 0;
          }
          const sections = [];

          for (let e of elements) {
            const letter = e[0];
            e = e.slice(1);

            switch (letter) {
              case 'H':
                const [__x] = e.split(' ').map(v => +v);
                sections.push({
                  type: 'line',
                  x: __x - cx,
                  y: 0,
                });
                cx = __x;
                break;
              case 'V':
                const [__y] = e.split(' ').map(v => +v);
                sections.push({
                  type: 'line',
                  x: 0,
                  y: __y - cy,
                });
                cy = __y;
                break;
              case 'L':
                const [_x, _y] = e.split(' ').map(v => +v);
                sections.push({
                  type: 'line',
                  x: _x - cx,
                  y: _y - cy,
                });
                cx = _x;
                cy = _y;
                break;
              case 'C':
                const [a1x, a1y, a2x, a2y, x, y] = e.split(' ').map(v => +v);

                const [_a1x, _a1y] = [a1x - cx, a1y - cy];
                const [_a2x, _a2y] = [a2x - x, a2y - y];

                const h1 = Math.sqrt(Math.pow(_a1x, 2) + Math.pow(_a1y, 2));
                const h2 = Math.sqrt(Math.pow(_a2x, 2) + Math.pow(_a2y, 2));

                const aRef = this.getAngle(x - cx, -(y - cy)) + Math.PI / 2;

                sections.push({
                  type: 'curve',
                  a1: [_a1x, _a1y],
                  a2: [_a2x, _a2y],
                  // h1,
                  // h2,
                  // b1: aRef - this.getAngle(_a1x, -_a1y),
                  // b2: this.getAngle(_a2x, -_a2y) - aRef,
                  x: x - cx,
                  y: y - cy,
                });
                cx = x;
                cy = y;
                break;
            }
          }

          sections.push({
            type: 'line',
            x: 0,
            y: 0,
          });
          descriptors.push({
            svgAttributes: {
              // stroke: '#ff0000',
              fill: fill ? fill : undefined,
              stroke: stroke ? stroke : undefined,
              'stroke-width': strokeWidth,
              // opacity: isNaN(opacity) ? 1 : opacity,
            },
            index: index++,
            type: 'path-shape',
            position: { x: px, y: py },
            isClosed,
            maskedBy: mask,
            sections: sections.map(
              s =>
                ({
                  id: Math.random().toString(),
                  ...s,
                }) as CurveSectionDescriptor,
            ),
          } as PathShapeDescriptor);
        }
      } catch (error) {
        console.log('------------ error ------------', error.message, path);
      }
    } else if (element instanceof SVGCircleElement) {
      const cx = +element.getAttribute('cx');
      const cy = +element.getAttribute('cy');
      const r = +element.getAttribute('r');
      console.log('circle-r', r);
      descriptors.push({
        svgAttributes: {
          fill: fill ? fill : undefined,
          stroke: stroke ? stroke : undefined,
          'stroke-width': strokeWidth,
          opacity: isNaN(opacity) ? 1 : opacity,
        },
        index: index++,
        type: 'circle-shape',
        position: { x: cx - r, y: cy - r },
        rx: r,
        ry: r,
        r,
        maskedBy: mask,
      } as CircleShapeDescriptor);
    } else if (element instanceof SVGRectElement) {
      const width = +element.getAttribute('width');
      const height = +element.getAttribute('height');

      const x = +element.getAttribute('x');
      const y = +element.getAttribute('y');
      const rx = +element.getAttribute('rx');

      descriptors.push({
        svgAttributes: {
          fill: fill ? fill : undefined,
          stroke: stroke ? stroke : undefined,
          'stroke-width': strokeWidth,
          opacity: isNaN(opacity) ? 1 : opacity,
        },
        index: index++,
        type: 'rectangle-shape',
        position: { x, y },
        width,
        height,
        r: rx,
        maskedBy: mask,
      } as RectangleShapeDescriptor);
    } else if (element.tagName == 'g') {
      // -- // -- //
      for (const maskChild of Array.from(element.children)) {
        const [maskElementDescriptor, newIndex] = this.getDescriptorByElement(
          maskChild as SVGElement,
          index,
          mask,
        );
        descriptors.push(...maskElementDescriptor);
        index = newIndex;
      }
    }

    return [descriptors, index];
  }

  norm(angle: number) {
    if (angle > 2 * Math.PI) {
      return angle - 2 * Math.PI;
    }
    if (angle < 0) {
      return angle + 2 * Math.PI;
    }
    return angle;
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }

    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }

  hover(event) {
    // console.log('hover', event);
    // $event ? (imageUploadState = 'dragging') : (imageUploadState = 'initial') //
  }

  constructor(
    public readonly cs: CanvasService,
    private readonly db: StoreService,
    private readonly ngZone: NgZone,
    private readonly viewportRuler: ViewportRuler,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    public readonly shapeService: ShapeService,
    private readonly animationService: AnimationService,
  ) {
    this.isFileLoading$ = this.store.select(isFileLoading);

    // this.viewportRuler
    //   .change(50)
    //   .subscribe(() => this.ngZone.run(() => this.resizeCanvas()));

    this.cs.generalEventSubscribe('refresh', () => this.reInit());
    // this.cs.keyEventSubscribe(
    //   'Shift+r',
    //   () => {
    //     this.cs.consumeKeyEvent('r');
    //     this.reInit();
    //   },
    //   2,
    // );

    this.cs.keyEventSubscribe('Escape', () => {
      this.shapeService.selectorRectangle?.remove();
      this.dragState = null;
    });

    this.route.params
      .pipe(filter(({ fileID }) => !!fileID))
      .subscribe(async ({ fileID }) => {
        this.cs.currentFileID = fileID;
      });

    this.isPatchLoading$ = this.cs.store.select(isPatchLoading);

    // this.cs.generalEventSubscribe('start-recording', () => this.record());
  }

  ngOnInit() {
    this.showComponentSearch$ = this.store.select(showComponentSearch);
  }

  scaleBufferCnt = 0;
  scaleBuffer = 0;

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    // console.log(
    //   'deltaX',
    //   event.deltaX,
    //   'deltaY',
    //   event.deltaY,
    //   'deltaMode',
    //   event.deltaMode,
    // );
    const { deltaX, deltaY, offsetX, offsetY } = event;
    const { abs } = Math;

    if (deltaX % 1 == 0 && deltaY % 1 == 0) {
      return this.cs.translateCanvas(
        (abs(deltaX) > abs(deltaY) ? -2 * deltaX : 0) * this.cs.canvasScale,
        (abs(deltaY) > abs(deltaX) ? -2 * deltaY : 0) * this.cs._canvasScale,
      );
    }

    // if (this.cs.isShiftPressed) {
    //   console.log('is-shift-pressed', deltaX, deltaY);
    //   return this.cs.translateCanvas(0, -deltaX);
    // }
    // if (this.cs.isSpacePressed) {
    //   return this.cs.translateCanvas(deltaY, 0);
    // }
    this.scaleBufferCnt++;
    this.scaleBuffer += deltaY;
    if (this.scaleBufferCnt < 2) {
      return;
    }

    this.cs.xscale(offsetX, offsetY, -2 * this.scaleBuffer);
    this.scaleBuffer = 0;
    this.scaleBufferCnt = 0;
    return;
    if (
      (abs(deltaX) < 1 && abs(deltaX) != 0) ||
      (abs(deltaY) < 1 && abs(deltaY) != 0)
    ) {
      this.cs.xscale(offsetX, offsetY, -deltaY);
    } else {
      // if (this.cs.isMetaPressed) {
      //   this.cs.xscale(offsetX, offsetY, -deltaY * 10);
      // } else if (this.cs.isSpacePressed) {
      //   return this.cs.translateCanvas(-deltaY / 2, 0);
      // } else {
      //   return this.cs.translateCanvas(0, -deltaY / 2);
      // }
    }

    // console.log('wheel', event.offsetX, event.offsetY, event.deltaY);
  }

  // @HostListener('gesturestart', ['$event'])
  // @HostListener('gesturechange', ['$event'])
  // @HostListener('gestureend', ['$event'])
  // onGesture(event: any) {
  //   // this.zoomChange.emit(event.scale);
  //   event.preventDefault();
  // }
  // }
  initialTouchPoints: any;
  initialDistance: number;
  initialAngle: number;

  private calculateDistance(points) {
    const [p1, p2] = points;
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  private calculateAngle(points) {
    const [p1, p2] = points;
    return Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI);
  }

  initializedShapeByDrag: GeneralShape;

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    if (this.cs.mouseDownAbsorbed) {
      this.cs.mouseDownAbsorbed = false;
      return;
    }

    this.cs.mouseDownFree = true;

    if (this.cs.draggedSVGElement && !this.cs.isPressed('Alt')) {
      return;
    }

    // if (this.cs.isPressed('Alt')) {
    //   this.cs.draggedSVGElement = null;
    //   return;
    // }

    if (this.cs.isPressed(['a', 's', 'd'])) {
      if (this.cs.draggedSVGElement) {
        return;
      }

      const { offsetX, offsetY } = event;
      this._offsetX = offsetX;
      this._offsetY = offsetY;
      const { x, y } = this.cs.getAbsoluteCoords(offsetX, offsetY);
      this.initializedShapeByDrag = this.shapeService.addPathShape(x, y);
      return;
    }

    if (this.cs.shapeAddMode) {
      const { offsetX, offsetY } = event;
      this._offsetX = offsetX;
      this._offsetY = offsetY;
      const { x, y } = this.cs.getAbsoluteCoords(offsetX, offsetY);
      switch (this.cs.shapeAddMode) {
        case 'rectangle':
          this.initializedShapeByDrag = this.shapeService.addRectangleShape(
            x,
            y,
          );
          break;
        case 'circle':
          this.initializedShapeByDrag = this.shapeService.addCircleShape(x, y);
          break;
        case 'path':
          this.initializedShapeByDrag = this.shapeService.addPathShape(x, y);
          break;
        case 'text':
          this.initializedShapeByDrag = this.shapeService.addTextShape(x, y);
          break;
      }

      return;
    }

    // @ts-ignore
    if (event.target.parentElement.getAttribute('id') == '_canvas') {
      // console.log('------- ee:starting-dragstate -----------');
      this.dragState = { x: event.offsetX, y: event.offsetY };
      this.shapeService.startSelectorRectangle(event.offsetX, event.offsetY);
    }
  }

  // @HostListener('mouseup', ['$event'])
  // mouseup(event: MouseEvent) {
  //   // @ts-ignore
  // }

  _offsetX = 0;
  _offsetY = 0;

  currentPos: Coords;

  @HostListener('mousemove', ['$event'])
  onMousemove({ offsetX, offsetY }: MouseEvent) {
    this.currentPos = [offsetX, offsetY];

    if (this.initializedShapeByDrag && !this.cs.isPressed('Alt')) {
      const [width, height] = [
        offsetX - this._offsetX,
        offsetY - this._offsetY,
      ];
      this.initializedShapeByDrag._resize(
        width / this.cs.canvasScale,
        height / this.cs.canvasScale,
      );
      return;
    }
    if (this.dragState) {
      // console.log('--- mouse-move ---', offsetX, offsetY);
      let { x, y } = this.dragState;
      const [width, height] = [offsetX - x, offsetY - y];
      if (width < 0) {
        x = x + width;
      }
      if (height < 0) {
        y = y + height;
      }
      this.shapeService.dragSelectorRectangle(x, y, width, height);
    }

    // This broadcasts back the moving to the SVG elements that are dragging
    this.cs.mouseMoveEvent(offsetX, offsetY);
  }

  @HostListener('click', ['$event'])
  async click(e: MouseEvent) {
    const log = false;
    if (log) {
      console.log('canvas.comp > clicked', {
        clickAbsorbed: this.cs.clickAbsorbed,
        initializedShapeByDrag: this.initializedShapeByDrag,
        draggingPathSection: this.cs.draggingPathSection,
        draggedSVGElement: this.cs.draggedSVGElement,
        dragState: this.dragState,
      });
    }

    // if (this.shapeService.groupRCDragMode) {
    //   this.shapeService.groupRCDragMode = false;
    //   return;
    // }
    this.cs.mouseDownFree = false;

    if (this.cs.clickAbsorbed) {
      this.cs.clickAbsorbed = false;
      this.cs.draggedSVGElement?.endDragHandler();
      this.cs.draggedSVGElement = null;
      // console.log('return > 1');
      return;
    }

    // TODO - maybe it is not the most elegant place for it //
    this.cs.generalEventEmit('hide-right-click-menu');

    if (this.initializedShapeByDrag) {
      this.initializedShapeByDrag.finishInit();
      this.initializedShapeByDrag = null;
      this.cs.shapeAddMode = null;
      this.cs.draggedSVGElement?.endDragHandler();
      // console.log('return > 2');
      return;
    }

    if (this.cs.draggingPathSection && !this.cs.isPressed('Alt')) {
      // As we finish the dragging initial drag session of a path-section another svg element
      // may have been clicked and its drag process would go on. We eliminate it stopDrag
      // #notsupercleancode
      console.log('ee:draggingPathSection-return');
      this.cs.draggedSVGElement?.stopDrag();
      this.cs.draggedSVGElement?.endDragHandler();
      this.cs.draggedSVGElement = null;
      this.cs.draggingPathSection?.clickHandler();

      // This is bit dirty but necessary.
      // If the initial drag session is over, then we will click one other element which
      // causes a next drag session to be created. The next line eliminates that
      // console.log('return > 3');
      return;
    }

    if (this.cs.draggedSVGElement) {
      // this is the case when there's for instance an image //

      this.cs.draggedSVGElement.endDragHandler();
      this.cs.draggedSVGElement = null;

      this.cs._hoveredControlPoint?.draggedOnto(
        this.cs.draggedPointController?.config?.controlPoint,
      );
      this.cs.draggedPointController = null;
      // console.log('return > 4');
      return;
    }

    // if (this.cs.clickAbsorbed) {
    //   this.cs.clickAbsorbed = false;
    //   this.dragState = null;
    //   return;
    // }
    if (this.dragState) {
      this.shapeService.removeSelectorRectangle();
      this.dragState = null;
      return;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.command = true;
    }
  }

  ngAfterViewInit() {
    this.cs.canvasNativeElement = this.canvas.nativeElement;

    this.cs.canvasOffset = {
      x: this.canvas.nativeElement.offsetLeft,
      y: this.canvas.nativeElement.offsetTop,
    };

    this.canvas.nativeElement.appendChild(this.cs.app.view);
    this.cs.canvas = this.canvas;

    setTimeout(() => {
      const { scrollWidth, scrollHeight } = this.container.nativeElement;
      // console.log('------', { scrollWidth, scrollHeight });
      // this.resizeCanvas(); //
    }, 10);
  }

  resizeCanvas() {
    const { scrollWidth, scrollHeight } = this.container.nativeElement;
    // TODO - check if it necessary
    console.log('resize', { scrollWidth, scrollHeight });
    this.cs.resizeCanvas(scrollWidth, scrollHeight);
  }

  getSubElementKeys(d: ShapeDescriptor) {
    const arr = d.elements
      ? Object.values(d.elements)
      : (d as ContainerShapeDescriptor).subElements;
    return uniq(arr)
      .map(({ type }) => type)
      .filter(e => !!e);
  }

  getSubImageKeys(d: ShapeDescriptor) {
    const arr = d.elements
      ? Object.values(d.elements)
      : (d as ContainerShapeDescriptor).subElements;
    return uniq(arr)
      .map(({ image }) => image)
      .filter(e => !!e);
  }

  async yamlChanged(content: string) {
    this.newDescriptor = safeLoad(content) as ShapeDescriptor;
    // await this.initPreviewshape(); //
  }

  setCurrentResource(resource: ResourceData) {
    this.currentId = resource.IRI;
    this.localStore[resource.IRI] = { resource };
  }

  // This routine is called when a file is opened at the side-bar

  resourceLoaded: string;

  async reInit() {}

  envChange({ key, value }: { key: string; value: any }) {
    this.cs.previewShape.descriptor.env ||= {};
    this.cs.previewShape.descriptor.env[key] = value;
    this.cs.previewShape.save();
  }

  currentAnimation: AnimationFrame;

  /*********************************** UNUSED FOR NOW *******************************/

  tabChanged({ id }: { id: string }) {
    this.currentId = id;
    this.yaml = safeDump(this.descriptor);
    // this.initPreviewshape(); //
  }

  initTab() {
    this.newDescriptor = null;
    this.newTab = {
      id: this.currentId,
      name: this.currentResourceData.literals.label,
    };
  }

  initYaml(resource: ResourceData) {
    try {
      this.descriptor = resource.literals?.descriptor;
    } catch {
      this.descriptor = {};
    }
    this.yaml = safeDump(this.descriptor);
  }
}
