import { Injectable } from '@angular/core';
import {
  Coords,
  OrientationLineCandidate,
  eCoords,
} from '../../elements/resource/types/shape.type';
import { CanvasService } from '../canvas/canvas.service';
import { PathElement } from '../../element-editor/shape/shapes/primitive/path-element';
import { GeneralShape } from '../../element-editor/shape/shapes/general/general-shape';

export type Store<T extends string | number | symbol = number> = Record<
  T,
  boolean
>;

export type BaseIndex = number;
export type IRI = string;

export type OrientationStore = Record<
  BaseIndex,
  Record<number, Record<BaseIndex, Record<number, IRI>>>
>;

export interface FoundOrientation {
  internalPoint: Coords;
  externalPoint: Coords;
  offset?: number;
}

@Injectable()
export class OrientationService {
  constructor(private readonly cs: CanvasService) {}

  horizontal: Record<number, Record<string, Store>> = {};
  vertical: Record<number, Record<string, Store>> = {};

  horizontalLookup: Record<string, Store> = {};
  verticalLookup: Record<string, Store> = {};

  horizontalUpStore: OrientationStore = {};
  horizontalDownStore: OrientationStore = {};

  verticalLeftStore: OrientationStore = {};
  verticalRightStore: OrientationStore = {};

  getBaseNumber(val: number) {
    const res = val % 100;
    if (val < 0) {
      return val - (res + 100);
    } else {
      return val - res;
    }
  }

  // TODO - it should be change upon scale

  check(v1: number, v2: number) {
    return Math.abs(v1 - v2) < this.cs.orientationLimit / this.cs.canvasScale;
  }

  minXBase = Infinity;
  minYBase = Infinity;

  maxXBase = -Infinity;
  maxYBase = -Infinity;

  clearHorizontalUp(key: string) {
    const [yBase, y, xBase, x] = key.split('_');
    delete this.horizontalUpStore[yBase]?.[y]?.[xBase]?.[x];
  }

  clearHorizontalDown(key: string) {
    const [yBase, y, xBase, x] = key.split('_');
    delete this.horizontalDownStore[yBase]?.[y]?.[xBase]?.[x];
  }

  clearVerticalLeft(key: string) {
    const [xBase, x, yBase, y] = key.split('_');
    delete this.verticalLeftStore[xBase]?.[x]?.[yBase]?.[y];
  }

  clearVerticalRight(key: string) {
    const [xBase, x, yBase, y] = key.split('_');
    delete this.verticalRightStore[xBase]?.[x]?.[yBase]?.[y];
  }

  registerHorizontalUp(IRI: string, x: number, y: number) {
    const xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);
    this.horizontalUpStore[yBase] ||= {};
    this.horizontalUpStore[yBase][y] ||= {};
    this.horizontalUpStore[yBase][y][xBase] ||= {};
    this.horizontalUpStore[yBase][y][xBase][x] = IRI;

    this.minYBase = this.minYBase > yBase ? yBase : this.minYBase;
    return `${yBase}_${y}_${xBase}_${x}`;
  }

  registerHorizontalDown(IRI: string, x: number, y: number) {
    const xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);
    this.horizontalDownStore[yBase] ||= {};
    this.horizontalDownStore[yBase][y] ||= {};
    this.horizontalDownStore[yBase][y][xBase] ||= {};
    this.horizontalDownStore[yBase][y][xBase][x] = IRI;

    this.maxYBase = this.maxYBase < yBase ? yBase : this.maxYBase;
    return `${yBase}_${y}_${xBase}_${x}`;
  }

  registerVerticalLeft(IRI: string, x: number, y: number) {
    const xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);
    this.verticalLeftStore[xBase] ||= {};
    this.verticalLeftStore[xBase][x] ||= {};
    this.verticalLeftStore[xBase][x][yBase] ||= {};
    this.verticalLeftStore[xBase][x][yBase][y] = IRI;

    this.minXBase = xBase < this.minXBase ? xBase : this.minXBase;
    return `${xBase}_${x}_${yBase}_${y}`;
  }

  registerVerticalRight(IRI: string, x: number, y: number) {
    const xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);
    this.verticalRightStore[xBase] ||= {};
    this.verticalRightStore[xBase][x] ||= {};
    this.verticalRightStore[xBase][x][yBase] ||= {};
    this.verticalRightStore[xBase][x][yBase][y] = IRI;

    this.maxXBase = this.maxXBase < xBase ? xBase : this.maxXBase;
    return `${xBase}_${x}_${yBase}_${y}`;
  }

  checkHorizontalUp(
    IRI: string,
    unMoved: Coords | eCoords,
    diff: Coords,
  ): FoundOrientation | void {
    return;
    const [_x, _y, offset] = unMoved;
    const [dx, dy] = diff;

    const x = _x + dx;
    const y = _y + dy;

    const xBase = this.getBaseNumber(x);
    let yBase = this.getBaseNumber(y);

    let i = 0;
    do {
      if (this.horizontalUpStore[yBase]) {
        let foundY = -Infinity;
        let foundX = Infinity;

        const keys = Object.keys(this.horizontalUpStore[yBase] || {});
        for (let i = 0; i < keys.length; i++) {
          const yValueKey = keys[i];
          const yValue = +yValueKey;
          if (foundY < yValue && yValue < y) {
            const xEntries = Object.entries(
              this.horizontalUpStore[yBase][yValueKey][xBase] || {},
            );
            for (let j = 0; j < xEntries.length; j++) {
              const [xValueKey, shapeIRI] = xEntries[j];
              if (shapeIRI != IRI) {
                const xValue = +xValueKey;
                if (this.check(xValue, x)) {
                  foundY = yValue;
                  foundX = xValue;
                }
              }
            }

            if (foundY != -Infinity) {
              return {
                internalPoint: unMoved.slice(0, 2) as Coords,
                externalPoint: [foundX, foundY],
                offset: offset || 0,
              };
            }
          }
        }
      }
      yBase -= 100;
      if (i++ > 30) {
        console.warn('something went wrong');
        break;
      }
    } while (yBase >= this.minYBase);
  }

  checkHorizontalDown(
    IRI: string,
    unMoved: Coords | eCoords,
    diff: Coords,
  ): FoundOrientation | undefined {
    return;
    const [_x, _y, offset] = unMoved;
    const [dx, dy] = diff;

    const x = _x + dx;
    const y = _y + dy;
    const xBase = this.getBaseNumber(x);
    let yBase = this.getBaseNumber(y);
    let i = 0;
    do {
      if (this.horizontalDownStore[yBase]) {
        let foundY = Infinity;
        let foundX = Infinity;

        const yValues = Object.keys(this.horizontalDownStore[yBase] || {});
        for (let i = 0; i < yValues.length; i++) {
          const yValue = +yValues[i];
          // const yValue = +yValueKey;
          if (foundY > yValue && yValue > y) {
            const xObjects = this.horizontalDownStore[yBase][yValue][xBase];

            const entries = Object.entries(xObjects || {});
            for (let j = 0; j < entries.length; j++) {
              const [xValueKey, shapeIRI] = entries[j];
              if (shapeIRI != IRI) {
                const xValue = +xValueKey;
                if (this.check(xValue, x)) {
                  // console.log('check-h-down.found');
                  foundY = yValue;
                  foundX = xValue;
                }
              }
            }
            if (foundY != Infinity) {
              return {
                internalPoint: unMoved.slice(0, 2) as Coords,
                externalPoint: [foundX, foundY],
                offset: offset || 0,
              };
            }
          }
        }
      }

      yBase += 100;

      if (i++ > 30) {
        console.warn('something went wrong');
        break;
      }
    } while (yBase <= this.maxYBase);
  }

  checkVerticalLeft(
    IRI: string,
    unMoved: Coords | eCoords,
    diff: Coords,
  ): FoundOrientation | undefined {
    return;
    const [_x, _y, offset] = unMoved;
    const [dx, dy] = diff;

    const x = _x + dx;
    const y = _y + dy;
    let xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);

    let i = 0;
    do {
      if (this.verticalLeftStore[xBase]) {
        let foundX = -Infinity;
        let foundY = Infinity;

        const xValues = Object.keys(this.verticalLeftStore[xBase] || {});
        for (let i = 0; i < xValues.length; i++) {
          const xValue = +xValues[i];
          if (foundX < xValue && xValue < x) {
            Object.entries(
              this.verticalLeftStore[xBase][xValue][yBase] || {},
            ).map(([yValueKey, shapeIRI]) => {
              if (shapeIRI != IRI) {
                const yValue = +yValueKey;
                if (this.check(yValue, y)) {
                  foundX = xValue;
                  foundY = yValue;
                }
              }
            });
            if (foundX != -Infinity) {
              return {
                internalPoint: unMoved.slice(0, 2) as Coords,
                externalPoint: [foundX, foundY],
                offset: offset || 0,
              };
            }
          }
        }
      }
      xBase -= 100;
      if (i++ > 30) {
        console.warn('something went wrong');
        break;
      }
    } while (this.minXBase <= xBase);
  }

  checkVerticalRight(
    IRI: string,
    unMoved: Coords | eCoords,
    diff: Coords,
  ): FoundOrientation | undefined {
    return;
    const [_x, _y, offset] = unMoved;
    const [dx, dy] = diff;

    const x = _x + dx;
    const y = _y + dy;

    let xBase = this.getBaseNumber(x);
    const yBase = this.getBaseNumber(y);

    let i = 0;
    do {
      if (this.verticalRightStore[xBase]) {
        let foundX = Infinity;
        let foundY = Infinity;

        const xValues = Object.keys(this.verticalRightStore[xBase] || {});
        for (let i = 0; i < xValues.length; i++) {
          const xValue = +xValues[i];
          if (xValue < foundX && x < xValue) {
            const yEntries = Object.entries(
              this.verticalRightStore[xBase][xValue][yBase] || {},
            );
            for (let j = 0; j < yEntries.length; j++) {
              const [yValueKey, shapeIRI] = yEntries[j];
              if (shapeIRI != IRI) {
                const yValue = +yValueKey;
                if (
                  Math.abs(yValue - y) <
                  this.cs.orientationLimit / this.cs.canvasScale
                ) {
                  // console.log("vertical-right-found");
                  foundX = xValue;
                  foundY = yValue;
                }
              }
            }

            if (foundX != Infinity) {
              return {
                internalPoint: unMoved.slice(0, 2) as Coords,
                externalPoint: [foundX, foundY],
                offset: offset || 0,
              };
            }
          }
        }
      }
      xBase += 100;

      if (i++ > 30) {
        console.warn('something went wrong');
        break;
      }
    } while (xBase <= this.maxXBase);
  }

  clearFile() {
    this.horizontalUpStore = {};
    this.horizontalDownStore = {};

    this.verticalLeftStore = {};
    this.verticalRightStore = {};
  }

  orientationLines: PathElement[] = [];

  drawOrientationLine(p1: Coords, p2: Coords) {
    const [x1, y1] = p1;
    const [x2, y2] = p2;
    const vertical = x1 == x2;
    this.orientationLines.push(
      new PathElement(this.cs.previewShape, this.cs.previewShape.container, {
        position: {
          x: vertical ? (y1 < y2 ? x1 : x2) : x1 < x2 ? x1 : x2,
          y: vertical ? (y1 < y2 ? y1 : y2) : x1 < x2 ? y1 : y2,
        },
        elements: [
          {
            type: 'line',
            x: Math.abs(x2 - x1),
            y: Math.abs(y2 - y1),
          },
        ],
        stroke: '#000',
        closed: false,
      }),
    );
  }

  showHorizontalOrientationLines(
    shape: GeneralShape,
    orientationLines: OrientationLineCandidate[],
    dx: number,
    dy: number,
  ) {
    shape.hideHOrientationLines();
    shape.hOrientationLines = orientationLines.map(
      ({ externalPoint, internalPoint, offset }) => {
        const [ix, iy] = internalPoint;
        const [ex, ey] = externalPoint;
        if (offset) {
          dx = 0;
        }
        return new PathElement(shape, shape.cs.previewShape.circleContainer, {
          position: {
            x: ex,
            y: ey,
          },
          x: ix + dx - ex,
          y: 0,
          ...this.cs.auxLineConfig,
        });
      },
    );
  }

  showVerticalOrientationLines(
    shape: GeneralShape,
    orientationLines: OrientationLineCandidate[],
    dx: number,
    dy: number,
  ) {
    shape.hideVOrientationLines();
    shape.vOrientationLines = orientationLines.map(
      ({ externalPoint, internalPoint, offset }) => {
        const [ix, iy] = internalPoint;
        const [ex, ey] = externalPoint;
        if (offset) {
          dy = 0;
        }
        return new PathElement(shape, shape.cs.previewShape.circleContainer, {
          position: {
            x: ex,
            y: ey,
          },
          x: 0,
          y: iy + dy - ey,
          ...this.cs.auxLineConfig,
        });
      },
    );
  }
}
