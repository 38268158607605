<ng-container *ngIf="frame">
  <nw-floating [left]="frame._start * 32 + 1" [top]="frame.rowIndex * 16 + 2">
    <div class="animation-frame-item" (click)="clickHandler()">
      <div *ngIf="frame.preDelay" class="predelay-line">
        <div
          class="predelay-inner"
          [style.right.px]="frame.absDuration * 32"
          [style.width.px]="frame.preDelay * 32"
          pre-delay
        >
          <div class="predelay-vertical"></div>
        </div>
      </div>
      <ng-container *ngIf="!functionOpen">
        <div
          (mouseover)="mouseOver()"
          (mouseout)="mouseOut()"
          *ngIf="!frame.edit && !!frame.duration"
          class="frame"
          [style.width.px]="
            max(3, frame.absDuration * 32) -
            2 +
            (frame.stateTransition && !frame.isSelected ? 4 : 0)
          "
          [ngClass]="{
            selected: frame.isSelected,
            'selected-among-many': isSelectedAmongMany,
            delay: !(isThereAnimation$ | async),
            sound:
              !!soundFrame.frame.soundFileUrl ||
              frame.frame.type == 'text' ||
              frame.frame.type == 'local-sound',
            function: frame.function,
            'is-function': frame.functionTarget,
            'state-transition': frame.stateTransition,
            'state-transition-transform':
              subSceneTransitionFrame.targetSubScene ||
              canvasTransformFrame.frame.type == 'canvas-transform',
            inverse: frame.inverse,
            line: frame.absDuration < 0.2,
          }"
          (click)="click()"
        >
          <!-- {{ frame.function ? 'f' : '' }} -->
        </div>
        <div
          *ngIf="!frame.edit && !frame.duration"
          class="zero-animation"
          fxLayout="column"
          fxLayoutAlign=" center"
          [class.selected-zero-animation]="frame.isSelected"
        >
          <div
            class="horizontal"
            [class.h-zero-selected]="frame.isSelected"
          ></div>
          <div
            class="vertical"
            [class.v-zero-selected]="frame.isSelected"
          ></div>
          <div
            class="horizontal"
            [class.h-zero-selected]="frame.isSelected"
          ></div>
        </div>
        <ng-container *ngIf="frame.edit">
          <input
            *ngIf="!frame.preDelayMode"
            (keydown)="$event.stopPropagation(); inputKeyDown($event)"
            (keyup)="$event.stopPropagation()"
            [(ngModel)]="frame.duration"
            (ngModelChange)="frame.durationChange($event)"
            #duration
          />
          <input
            *ngIf="frame.preDelayMode"
            (keydown)="$event.stopPropagation(); inputKeyDown($event)"
            (keyup)="$event.stopPropagation()"
            [(ngModel)]="frame.preDelay"
            (ngModelChange)="frame.durationChange($event)"
            #duration
          />
        </ng-container>
      </ng-container>
      <div
        *ngIf="functionOpen"
        [class.function-open]="functionOpen"
        [style.width.px]="functionInstance.functionDuration * 32 + 2"
      >
        <!-- [style.height.px]="functionInstance.height * 28 + 2" -->
        <!-- <div>Function Open</div> -->
        <div style="position: relative; top: 1px; left: 1px">
          <nw-animation-frame
            [parent]="functionFrame"
            [frame]="functionInstance"
          ></nw-animation-frame>
        </div>
      </div>
    </div>
  </nw-floating>
  <!-- <div
    *ngIf="frame.isSelected"
    [style.left.px]="frame._start * 32"
    [style.top.px]="frame.rowIndex * 16"
    class="selector-rectangle"
  ></div> -->
</ng-container>
