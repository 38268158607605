import { Container, Text } from 'pixi.js';
import { SVG, SVGBaseConfig, SVGConfig } from './svg-element';
import { TextShape } from '../text/text-shape';
import { SubtitleElement } from '../../subtitle/subtitle-element';

export interface TextSVGConfig extends SVGBaseConfig {
  text: string;
  fontSize: number;
  fontStyle?: 'normal' | 'italic' | 'oblique';
  wordWrap?: boolean;
  wordWrapWidth?: number;
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter';
  letterSpacing?: number;
}
export class TextElement extends SVG<TextSVGConfig, Text> {
  get text() {
    return this.config.text;
  }

  get fontStyle() {
    return this.config.fontStyle;
  }

  get strokeWidth() {
    return this.config['stroke-width'];
  }

  get fontSize() {
    return this.config.fontSize;
  }

  get wordWrap() {
    return this.config.wordWrap;
  }

  get wordWrapWidth() {
    return this.config.wordWrapWidth;
  }

  get fontWeight() {
    return this.config.fontWeight;
  }

  get letterSpacing() {
    return this.config.letterSpacing || -1;
  }

  // get element() {
  //   return this._element as Text;
  // }

  constructor(
    parent: TextShape | SubtitleElement,
    container: Container,
    config: TextSVGConfig,
  ) {
    super(parent, container, config);
    container.addChild(this.element);
    this.updateAttr();
  }

  initElement(): void {
    this._element = new Text();
  }
  updateAttr() {
    if (!this.element) {
      return;
    }

    this._element.text = this.text;
    this._element.style = {
      fontSize: this.fontSize,
      fontStyle: this.fontStyle,
      // fontWeight: '500',
      // fontWeight: 'bold' || this.fontWeight,
      fontWeight: '700',
      fill: this.fill || this.convertHexToNumber('#808080'),
      stroke: this.convertHexToNumber(this.stroke) || '#000000',
      strokeThickness: this.strokeWidth || 0,
      wordWrap: this.wordWrap,
      wordWrapWidth: this.wordWrapWidth,
      lineJoin: 'round',
      letterSpacing: this.letterSpacing,
      align: 'center',
      // lineHeight: 80,
      fontFamily: 'Lalezar',
      // fontFamily: 'Work Sans',
      // fontFamily: 'Inter',
    };

    if (this.x || this.y) {
      this._element.setTransform(this.x, this.y);
      this._element.position._x = this.x;
      this._element.position._y = this.y;
    }
  }

  getBounds() {
    return this.element.getBounds();
  }
}
