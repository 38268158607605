import { Coords } from '../../elements/resource/types/shape.type';

const { asin, acos, sin, cos, abs, min, sign } = Math;

export class LimiterService {
  static circle(r: number, [x, y]: Coords): Coords {
    if (abs(x) >= abs(y)) {
      const angle = asin(min(abs(y), r) / r);
      return [sign(x) * cos(angle) * r, y];
    } else {
      const angle = acos(min(abs(x), r) / r);
      return [x, sign(y) * sin(angle) * r];
    }
  }
}
