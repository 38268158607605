export const angles = {
  '-1.-1|-1.-1': 0,
  '-1.-1|-1.0': -45,
  '-1.-1|-1.1': -90,
  '-1.-1|0.-1': 45,
  '-1.-1|0.1': -135,
  '-1.-1|1.-1': 90,
  '-1.-1|1.0': 135,
  '-1.-1|1.1': 180,
  '-1.0|-1.-1': 45,
  '-1.0|-1.0': 0,
  '-1.0|-1.1': -45,
  '-1.0|0.-1': 90,
  '-1.0|0.1': -90,
  '-1.0|1.-1': 135,
  '-1.0|1.0': -180,
  '-1.0|1.1': -135,
  '-1.1|-1.-1': 90,
  '-1.1|-1.0': 45,
  '-1.1|-1.1': 0,
  '-1.1|0.-1': 135,
  '-1.1|0.1': -45,
  '-1.1|1.-1': -180,
  '-1.1|1.0': -135,
  '-1.1|1.1': -90,
  '0.-1|-1.-1': -45,
  '0.-1|-1.0': -90,
  '0.-1|-1.1': -135,
  '0.-1|0.-1': 0,
  '0.-1|0.1': 180,
  '0.-1|1.-1': 45,
  '0.-1|1.0': 90,
  '0.-1|1.1': 135,
  '0.1|-1.-1': 135,
  '0.1|-1.0': 90,
  '0.1|-1.1': 45,
  '0.1|0.-1': -180,
  '0.1|0.1': 0,
  '0.1|1.-1': -135,
  '0.1|1.0': -90,
  '0.1|1.1': -45,
  '1.-1|-1.-1': -90,
  '1.-1|-1.0': -135,
  '1.-1|-1.1': 180,
  '1.-1|0.-1': -45,
  '1.-1|0.1': 135,
  '1.-1|1.-1': 0,
  '1.-1|1.0': 45,
  '1.-1|1.1': 90,
  '1.0|-1.-1': -135,
  '1.0|-1.0': 180,
  '1.0|-1.1': 135,
  '1.0|0.-1': -90,
  '1.0|0.1': 90,
  '1.0|1.-1': -45,
  '1.0|1.0': 0,
  '1.0|1.1': 45,
  '1.1|-1.-1': -180,
  '1.1|-1.0': 135,
  '1.1|-1.1': 90,
  '1.1|0.-1': -135,
  '1.1|0.1': 45,
  '1.1|1.-1': -90,
  '1.1|1.0': -45,
  '1.1|1.1': 0,
};
