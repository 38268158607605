<div
  class="translate-animation-component"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Position"
    [noDelete]="true"
    [attributeState]="attributeState$ | async"
    key="translate"
    [initialValue]="{ x: 100, y: 100, relative: true }"
  ></nw-general-attribute-header>

  <div
    class="attribute-value-section"
    [ngClass]="{
      'value-section-disabled': !!(attributeState$ | async).selectedFunctions
        ?.length,
    }"
  >
    <div
      *ngIf="
        attributeState.type == 'base' || attributeState.type == 'animation'
      "
      class="flex-column gap-4"
    >
      <div class="flex-row space-between">
        <nw-number-input
          [key]="'x'"
          [value]="attributeState.value['x']"
          (changed)="updateSubAttributeValue('x', $event)"
          [step]="0.01"
        ></nw-number-input>
        <nw-number-input
          [key]="'y'"
          [value]="attributeState.value['y']"
          (changed)="updateSubAttributeValue('y', $event)"
          [step]="0.01"
        ></nw-number-input>
      </div>
      <nw-boolean-input
        *ngIf="
          attributeState.value['relative']?.length ||
          attributeState.type == 'animation'
        "
        [key]="'relative'"
        [value]="attributeState.value['relative'] || [false]"
        (changed)="updateSubAttributeValue('relative', $event)"
      ></nw-boolean-input>
    </div>
  </div>
</div>
