export enum Easing {
  START = 'start',
  END = 'end',
  OVERFLOW = 'overflow',
  SMOOTH = 'smooth',
  LINEAR = 'linear',
  RANDOM = 'random',
}

export class IncrementController {
  currentIncrement = 0;

  constructor(
    public start: number,
    public end: number,
    private maxIncrement: number,
    private easing = Easing.LINEAR,
  ) {
    // console.log('constructor', { start, end }); //
  }

  getValue(t: number) {
    switch (this.easing) {
      case Easing.LINEAR:
        return t;
      case Easing.OVERFLOW:
        return 3 * Math.pow(t, 2) - 2 * Math.pow(t, 3.5);
      case Easing.SMOOTH:
        return 3 * Math.pow(t, 2) - 2 * Math.pow(t, 3);
    }
  }

  increment(increment: number) {
    this.currentIncrement += increment;
    const t = this.getValue(this.currentIncrement / this.maxIncrement);
    return this.start + t * (this.end - this.start);
  }
}
