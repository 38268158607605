<div class="project-language-edit-component editor-container">
  <div class="flex-column stretch">
    <div class="editor-container-header">
      <div class="header-inner flex-row gap-8">
        <nw-icon type="language" [size]="13"></nw-icon>
        <div>Languages</div>
      </div>
    </div>
    <div class="body flex-column gap-8 start">
      <div class="flex-row gap-12">
        <div class="circle"></div>
        <div class="fs-13">default</div>
      </div>
      <div
        *ngFor="let lang of languages; let i = index"
        class="flex-row gap-12"
      >
        <div class="circle"></div>
        <div class="fs-13">{{ langMap[lang] }}</div>
        <!-- <div>{{ i }}</div> -->
      </div>

      <div class="selector flex-row gap-6">
        <select [(ngModel)]="selectedLang" name="" id="">
          <option *ngFor="let langKey of langKeys" [value]="langKey">
            {{ langMap[langKey] }}
          </option>
        </select>
        <div class="add-container" (click)="addLanguage()">
          <nw-icon type="add" [size]="15"></nw-icon>
        </div>
      </div>
    </div>
    <div class="editor-footer flex-row space-around pointer">
      <div
        class="w-50 t-center fs-15"
        style="text-align: center; border-right: 1.5px solid black"
        (click)="save()"
      >
        Save
      </div>
      <div
        class="w-50 t-center fs-15"
        style="text-align: center"
        (click)="discard()"
      >
        Discard
      </div>
    </div>
  </div>
</div>
