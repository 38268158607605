import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-number-select',
  templateUrl: './number-select.component.html',
  styleUrls: ['./number-select.component.scss'],
})
export class NumberSelectComponent implements OnInit {
  @Input()
  key: string;

  @Input()
  step = 1;

  @Input()
  values: any[];

  @Output()
  valueChange = new EventEmitter<number>();

  get currentValue() {
    const values = this.differentValues;
    return values.length == 1 ? values[0] : undefined;
  }

  get differentValues() {
    const values = {};
    this.values
      ?.filter(e => !!e && e !== 'undefined' && e !== 'null')
      .map(value => (values[value] = true));
    return Object.keys(values).map(val => +val);
  }

  get enableDropdown() {
    return this.differentValues.length > 1;
  }

  // get differentValues() {
  //   return this.values.filter
  // }

  _val: any;

  set currentValue(val: number) {
    if (isNaN(val) || val == null) {
      return;
    }

    if (this._val !== val) {
      this.valueChange.emit(val);
    }
    this._val = val;
  }

  change(event: Event) {
    this.valueChange.emit(+(event.target as HTMLInputElement).value);
  }

  dropdownOpen = false;

  constructor(public readonly cs: CanvasService) {}

  ngOnInit(): void {}
}
