import { Component, OnInit } from '@angular/core';
import { User } from '../authentication/cognito.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Component({
  selector: 'mw-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  loading: boolean;
  user: User;
  code: string;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.loading = false;
    this.user = {} as User;
  }

  public ngOnInit(): void {}

  async signIn(provider?: string) {
    this.loading = true;
    try {
      await this.authenticationService.signIn(
        this.user,
        provider as CognitoHostedUIIdentityProvider
      );

      console.log('logged in');
    } catch (error) {
      this.authenticationService.isAuthenticationFailed(error);
    }
    this.loading = false;
  }

  get isUserRestricted() {
    return this.authenticationService.isUserRestricted;
  }
}
