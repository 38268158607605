import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';

@Component({
  selector: 'nw-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss']
})
export class ListEditorComponent implements OnInit {

  @Input()
  items: string[]

  @Output()
  dataChanged = new EventEmitter<string[]>();

  newItemState = false;

  constructor(private readonly cs: CanvasService) { }

  ngOnInit(): void {
  }

  addNew() {
    this.newItemState = true;
  }

  newItem: string;

  keyup(event: KeyboardEvent) {
    this.cs.consumeKeyEvent(event.key);
  }

  keydown(event: KeyboardEvent) {
    this.cs.consumeKeyEvent(event.key);
  }

  saveItem(index: number, text: string) {
    this.items[index] = text;
  }

  saveNew() {
    if (!this.newItem) {
      return;
    }
    
    this.items.push(this.newItem);

    this.newItem = '';
    this.newItemState = false;
    this.save();
  } 

  removeItem(index: number) {
    this.items.splice(index, 1);
    this.save();
  }


  save() {
    this.dataChanged.emit(this.items);
  }

}
