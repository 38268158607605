import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iw-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.scss'],
})
export class TextEditComponent {
  @Input()
  text = '';

  @Output()
  save = new EventEmitter<string>();

  @Output()
  discard = new EventEmitter();

  saveHandler() {
    this.save.emit(this.text);
  }

  discardHandler() {
    console.log('discard-handler');
    this.discard.emit();
  }
}
