import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  AnimationFeatureKey,
  animationReducer,
} from './store/animation.reducer';
import { AnimationEffects } from './store/animation.effect';
import { AnimationService } from './animation.service';
import { CanvasService } from '../../services/canvas/canvas.service';
import { AnimationFramePanelComponent } from './components/animation-frame/animation-frame.component';
import { AnimationPanelComponent } from './components/animation-panel/animation-panel.component';
import { AnimationSelectorComponent } from './components/animation-selector/animation-selector.component';
import { UtilComponentModule } from '../../components/util/util-component.module';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { AnimationFrameItemComponent } from './components/animation-frame-item/animation-frame-item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AudioService } from './audio.service';

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    UtilComponentModule,
    MatProgressSpinnerModule,
    EffectsModule.forFeature(AnimationEffects),
    StoreModule.forFeature(AnimationFeatureKey, animationReducer),
  ],
  providers: [CanvasService, AudioService, AnimationService],
  declarations: [
    AnimationFramePanelComponent,
    AnimationPanelComponent,
    AnimationSelectorComponent,
    AnimationFrameItemComponent,
  ],
  exports: [
    AnimationFramePanelComponent,
    AnimationPanelComponent,
    AnimationSelectorComponent,
  ],
})
export class AnimationModule {}
