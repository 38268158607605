import { Inject, Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ICredentials, HubPayload } from '@aws-amplify/core';
import type { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import type { AmplifyClass } from '@aws-amplify/core/lib-esm/Amplify';
import type { HubClass } from '@aws-amplify/core/lib-esm/Hub';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  AmplifyClassToken,
  AuthClassToken,
  HubClassToken,
} from './authentication.constans';

export interface User {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable()
export class CognitoService {
  private readonly _cognitoEvents = new Subject<HubPayload>();

  get cognitoEvents(): Observable<HubPayload> {
    return this._cognitoEvents.asObservable();
  }

  constructor(
    @Inject(AmplifyClassToken) private readonly amplify: AmplifyClass,
    @Inject(HubClassToken) private readonly hub: HubClass,
    @Inject(AuthClassToken) private readonly auth: AuthClass
  ) {
    this.amplify.configure({
      Auth: environment.cognito,
    });

    this.hub.listen('auth', event => {
      console.log(event);
      this._cognitoEvents.next(event.payload);
    });
  }

  public signUp(user: User): Promise<any> {
    return this.auth.signUp({
      username: user.email,
      password: user.password,
    });
  }

  public confirmSignUp(user: User): Promise<any> {
    return this.auth.confirmSignUp(user.email, user.code);
  }

  public signIn(user: User): Promise<CognitoUser> {
    return this.auth.signIn(user.email, user.password);
  }

  async socialSignIn(
    provider: CognitoHostedUIIdentityProvider
  ): Promise<ICredentials> {
    return this.auth.federatedSignIn({
      provider,
    });
  }

  signOut(): Promise<void> {
    return this.auth.signOut();
  }

  public async isAuthenticated(): Promise<boolean> {
    try {
      const user = await this.getUser();
      return !!user;
    } catch (_error) {
      return false;
    }
  }

  public getUser(): Promise<any> {
    return this.auth.currentUserInfo();
  }

  public async updateUser(user: User): Promise<string> {
    const cognitoUser = await this.auth.currentUserPoolUser();

    return this.auth.updateUserAttributes(cognitoUser, user);
  }
}
