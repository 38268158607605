import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';

@Component({
  selector: 'nw-rotation-attribute',
  templateUrl: './rotation-attribute.component.html',
  styleUrls: ['./rotation-attribute.component.scss'],
})
export class RotationAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'rotation';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('rotation'));
    this.attributeState$.subscribe(animationState => {
      // console.log('--------- scale --------', animationState); //
    });
  }
}
