<!-- fxLayoutGap="2px" -->
<div class="color-select-component" fxLayout="row" fxLayoutAlign=" center">
  <ng-container *ngIf="isFormula">
    <input
      [value]="formula"
      (change)="inputChange($event)"
      (keyup)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    />
  </ng-container>
  <ng-container *ngIf="!isFormula">
    <div
      class="format-align-cont"
      style="width: 24px; height: 24px"
      fxLayout="row"
      fxLayoutAlign="space-around center"
      (colorPickerChange)="currentColor = $event"
      [colorPicker]="currentColor"
      cpPosition="left"
    >
      <div
        class="color-sample"
        [ngClass]="{ 'mixed-color': colors?.length > 1 }"
        [ngStyle]="{ 'background-color': currentColor }"
      ></div>
    </div>

    <!-- <input [(ngModel)]="currentColor" (keydown)="cs.consumeKeyEvent($event.code)" class="current-color" /> -->
    <ng-container *ngIf="!noOptionSelect">
      <nw-option-select
        *ngIf="(colorPalette$ | async)?.length"
        [data]="colorPalette$ | async"
        (itemSelected)="colorLibSelected($event)"
        (click)="colorlibSelectorClicked()"
        [close]="colorLibClosed"
      >
        <ng-template let-value>
          <div
            fxLayout="row"
            fxLayoutAlign=" center"
            fxLayoutGap="8px"
            class="color-option-container"
          >
            <div
              class="color-sample"
              [ngStyle]="{ 'background-color': value.color }"
            ></div>
            <div class="color-label" style="white-space: nowrap">
              {{ value.label }}
            </div>
          </div>
        </ng-template>
      </nw-option-select>
      <nw-option-select
        *ngIf="colors?.length > 1 && !notNullColors.length"
        [data]="notNullColors"
        (itemSelected)="colorSelected($event)"
        (click)="colorOptionSelectorClicked()"
        [close]="colorOptionsClosed"
      >
        <ng-template let-value>
          <div
            fxLayout="row"
            fxLayoutAlign=" center"
            fxLayoutGap="8px"
            class="color-option-container"
          >
            <div
              class="color-sample"
              [ngStyle]="{ 'background-color': value }"
            ></div>
          </div>
        </ng-template>
      </nw-option-select>
      <nw-icon type="code" (click)="setFormula()" [size]="12"></nw-icon>
    </ng-container>
  </ng-container>

  <!--
  <div
    class="format-align-cont"
    (click)="cs.consumeClick(); colorLibSelectorOpen = !colorLibSelectorOpen"
  >
    <nw-icon type="label" [size]="18" [outlined]="true"></nw-icon>
    <nw-dropdown-component
      *ngIf="colorLibSelectorOpen"
      [height]="20"
      [top]="8"
      [right]="1"
      [gap]="4"
      [data]="colorOptions"
      (clicked)="colorLibSelected($event)"
    >
      <ng-template let-value>
        <div
          fxLayout="row"
          fxLayoutAlign=" center"
          fxLayoutGap="8px"
          class="color-option-container"
        >
          <div
            class="color-sample"
            [ngStyle]="{ 'background-color': value.value }"
          ></div>
          <div>{{ value.key }}</div>
        </div>
      </ng-template>
    </nw-dropdown-component>
  </div> 
  <div
    *ngIf="!noOptionSelect"
    class="format-align-cont"
    (click)="cs.consumeClick(); colorSelectorOpen = !colorSelectorOpen"
  >
    <nw-icon
      type="arrow_drop_down"
      [size]="18"
      [disabled]="!enableColorSelector"
    ></nw-icon>
    <nw-dropdown-component
      *ngIf="colorSelectorOpen"
      [height]="20"
      [top]="8"
      [right]="1"
      [gap]="4"
      [data]="definedColors"
      (clicked)="colorSelected($event)"
    >
      <ng-template let-value>
        <div
          fxLayout="row"
          fxLayoutAlign=" center"
          fxLayoutGap="8px"
          class="color-option-container"
        >
          <div
            class="color-sample"
            [ngStyle]="{ 'background-color': value }"
          ></div>
        </div>
      </ng-template>
    </nw-dropdown-component>
  </div> -->
</div>
