<div class="general-attribute-header-component" *ngIf="attributeState">
  <div class="title-row">
    <div *ngIf="title" class="title">{{ title }}</div>

    <nw-icon *ngIf="icon" [type]="icon" [outlined]="true" [size]="13"></nw-icon>
    <img *ngIf="customIcon" [src]="'assets/icons/' + customIcon" />
    <div class="flex-row gap-4">
      <div class="function-selector" *ngIf="functions?.length && !noFunction">
        <nw-icon
          type="keyboard_arrow_down"
          [size]="13"
          (click)="fcnSelectorOpen = !fcnSelectorOpen"
          style="position: relative; top: -2px; left: 5px"
        ></nw-icon>
        <nw-dropdown-component
          *ngIf="fcnSelectorOpen"
          [data]="functions"
          [right]="0"
          [top]="12"
          (clicked)="functionSelected($event)"
        >
          <ng-template let-value>
            <div style="white-space: nowrap">{{ value }}</div>
          </ng-template>
        </nw-dropdown-component>
      </div>

      <ng-container *ngIf="attributeState.type == 'undefined'">
        <nw-icon type="add" [size]="14" (click)="addInitialValue()"></nw-icon>
      </ng-container>

      <ng-container *ngIf="attributeState.type != 'undefined'">
        <nw-icon
          *ngIf="attributeState.type == 'animation' && !noFunction"
          type="bookmark"
          [size]="14"
          [outlined]="true"
          (click)="saveAttribute()"
        ></nw-icon>
        <nw-icon
          *ngIf="!noDelete"
          type="close"
          (click)="deleteAttribute()"
          [size]="12"
        ></nw-icon>
      </ng-container>
    </div>
  </div>
  <div *ngIf="functionSelectMode" class="function-selector">
    <div
      *ngFor="let fcn of functions"
      class="function-item"
      (click)="selectFunction(fcn)"
    >
      {{ fcn }}
    </div>
  </div>
  <div class="function-row" *ngIf="attributeState.isFunction">
    <ng-container *ngIf="!functionSaveMode">
      <div *ngIf="attributeState.state == 'mixed'">~</div>
      <div *ngIf="attributeState.state == 'defined'">
        {{ attributeState.selectedFunctions[0] }}
      </div>
    </ng-container>
    <ng-container *ngIf="functionSaveMode">
      <div class="flex">
        <input [(ngModel)]="newFunctionName" class="new-fcn-input" />
        <nw-icon type="check" [size]="12" (click)="addFunction()"></nw-icon>
        <nw-icon
          type="close"
          [size]="12"
          (click)="discardNewAnimation()"
        ></nw-icon>
      </div>
    </ng-container>
  </div>
</div>
