import { LineAnimation } from '../../../../../elements/resource/types/shape.type';
import { PathElement } from '../../primitive/path-element';
import { PathShape } from '../path-shape';

export class TrajectoryAnimation {
  auxLineElement: PathElement;
  lineElements: PathElement[];
  startPoints: number[];

  // TODO - totalLength should be a getter, and if the path-shape changes so does the trajectory-animation
  totalLength: number;
  dashLength: number;
  dashSpeed: number;

  mode: 'dashArray' | 'trajectoryFill';
  startEnd: number;

  inverse: boolean;

  trajectoryFill: boolean;

  get allLineElementents() {
    return [this.auxLineElement, ...this.lineElements];
  }

  get container() {
    return this.pathShape.container;
  }

  getSvgAttributes(animation: LineAnimation) {
    return {
      stroke: this.pathShape.getColorValue(animation.line.stroke),
      'stroke-width': animation.line['stroke-width'],
    };
  }

  constructor(
    private pathShape: PathShape,
    animation: LineAnimation,
    duration?: number,
    division?: number,
  ) {
    this.post(animation, duration, division);
  }

  post(animation: LineAnimation, duration: number, division?: number) {
    this.currentIncrement = 0;
    this.totalLength = this.pathShape._lastSection.endLength;
    if (animation.dashArray) {
      this.mode = 'dashArray';
      // TODO - revise
      // this.pathShape.element.hide();
      // const { length, speed } = animation.dashArray;
      // this.dashLength = length;
      // this.dashSpeed = speed;
      // this.startPoints = [0];
      // let current = 0;
      // let fullLength: number;
      // while (1) {
      //   current += 2 * length;
      //   if (current < this.totalLength) {
      //     this.startPoints.push(current);
      //   } else {
      //     fullLength = current;
      //     break;
      //   }
      // }
      // this.auxLineElement = new PathElement(
      //   this.pathShape,
      //   this.container,
      //   this.getSvgAttributes(animation)
      // );
      // this.lineElements = this.startPoints.map(
      //   () =>
      //     new PathElement(
      //       this.pathShape,
      //       this.container,
      //       this.getSvgAttributes(animation)
      //     )
      // );
      // this.lineElements.map((element, i) => {
      //   const [start, end] = [
      //     this.startPoints[i],
      //     this.startPoints[i] + length,
      //   ];
      //   element.patch({
      //     elements: this.pathShape.getElements(start, end),
      //   });
      // });
    } else if (animation.trajectoryFill) {
      this.mode = 'trajectoryFill';
      let { offset, end } = animation.trajectoryFill;
      end ||= this.pathShape.__endLength;
      this.startEnd = offset || 0;
      this.dashSpeed = (end - this.startEnd) / division;

      this.pathShape.refreshElement(0, this.startEnd);
    } else {
      // TODO - implement

      const {
        length,
        hideOriginal,
        offset,
        stroke,
        'stroke-width': strokeWidth,
        inverse,
      } = animation.line;

      // -- // -- // -- // -- // -- // -- //
      hideOriginal
        ? this.pathShape.element.hide()
        : this.pathShape.element.show();

      this.dashSpeed = (this.totalLength - offset) / division;
      this.dashLength = length;
      this.inverse = inverse;
      let start = offset || 0;
      let end = this.dashLength;

      if (inverse) {
        this.dashSpeed *= -1;
        start = this.totalLength + (-offset || 0);
        end = 0;
        this.startPoints = [start];
      }

      this.currentIncrement = 0;
      this.startPoints = [start];

      this.lineElements = [
        new PathElement(this.pathShape, this.container, {
          stroke,
          'stroke-width': strokeWidth || 1,
          elements: this.pathShape.getElements(start, end),
        }),
      ];
    }
  }

  patchSVGAttribute(key: string, value: string | number) {
    this.allLineElementents.map(le => le.patch({ [key]: value }));
  }

  patch(animation: LineAnimation, duration: number) {
    this.delete();
    this.post(animation, duration);
  }

  delete() {
    this.lineElements?.map(e => e.remove());
    this.auxLineElement?.remove();
    this.pathShape.element.show();
  }

  currentIncrement: number;

  incrementAnimation(increment: number) {
    if (this.mode == 'trajectoryFill') {
      this.currentIncrement += increment;
      const end = this.startEnd + this.dashSpeed * this.currentIncrement;
      this.pathShape.refreshElement(0, end);
    } else if (this.mode == 'dashArray') {
      let auxStartPoint = 0;
      this.lineElements.map((element, i) => {
        let [start, end] = [
          this.startPoints[i],
          this.startPoints[i] + this.dashLength,
        ];

        if (this.inverse) {
          [start, end] = [
            this.startPoints[i] - this.dashLength,
            this.startPoints[i],
          ];
        }

        this.startPoints[i] += increment * (this.dashSpeed || 1);

        if (this.totalLength < end) {
          auxStartPoint = -this.dashLength + (end - this.totalLength);
          // auxElement.show(); //
        }

        if (this.startPoints[i] >= this.totalLength) {
          auxStartPoint = 0;
          this.startPoints[i] = this.startPoints[i] - this.totalLength;
        }
        element.patch({
          elements: this.pathShape.getElements(start, end),
        });
      });
    } else {
      this.currentIncrement += increment;

      const [start] = this.startPoints;
      const [element] = this.lineElements;

      element.patch({
        elements: this.pathShape.getElements(
          start + this.currentIncrement * this.dashSpeed,
          start + this.currentIncrement * this.dashSpeed + this.dashLength,
        ),
      });
    }

    // if (auxStartPoint) {
    //   this.auxLineElement.patch({
    //     elements: this.pathShape.getElements(
    //       auxStartPoint,
    //       auxStartPoint + this.dashLength
    //     ),
    //   });
    // } else {
    //   this.auxLineElement.patch({
    //     elements: [],
    //   });
    // }
  }
}
