import { AnimationFrameObject } from '../animation-frame-object';

export class InverseAnimationFrameObject extends AnimationFrameObject {
  // -- //

  get _id() {
    return this.prev.id;
  }

  startAnimations(numberOfBatches: number) {
    this.shapesToAnimate.map(shape =>
      shape.startAnimation(this._id, numberOfBatches, true),
    );
  }

  endAnimations() {
    this.shapesToAnimate.map(shape => shape.endAnimation(this._id, true));
  }
}
