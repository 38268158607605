import { Injectable } from '@angular/core';
import { GeneralShape } from './shapes/general/general-shape';
import { Container, Geometry, Graphics, Mesh, Shader } from 'pixi.js';
import { RectangleController } from '../../elements/util/rectangle-controller/rectangle-controller';
import { CanvasService } from '../../services/canvas/canvas.service';
import {
  IndividualRectangleShape,
  RectangleShape,
} from './shapes/base/rectangle/rectangle-shape';
import { PathShape } from './shapes/path-shape/path-shape';
import {
  CircleShapeDescriptor,
  Coords,
  GeneralShapeDescriptor,
  HandShapeConfig,
  HandShapeNewDescriptor,
  ImportedShapeDescriptor,
  PathShapeDescriptor,
  RectangleShapeDescriptor,
  ShapeConfig,
  ShapeDragParams,
  TextShapeDescriptor,
} from '../../elements/resource/types/shape.type';
import { CircleShape } from './shapes/base/circle-shape';
import {
  Relationships,
  ResourceData,
} from '../../elements/resource/resource.types';
import { cloneDeep, set as _set } from 'lodash';
import * as uuidv1 from 'uuid/v1';
import { Store } from '@ngrx/store';
import {
  getFile,
  selectCurrentScenes,
  selectFontLoaded,
  selectNoAnimationMode,
} from '../store/selector/editor.selector';
import {
  addNewShapeAction,
  loadScene,
  resetBaseState,
  saveNewComponentAction,
  setDescriptorValue,
  setCurrentScene,
  setSelectedShapes,
  setScenes,
} from '../store/editor.actions';
import { RootShape } from './shapes/general/root/root-shape';
import { GroupShape } from './shapes/group/group-shape';
import { ImportedShape } from './shapes/general/imported/imported-shape';
import { ImageShape } from './shapes/base/image-shape';
import { TrajectoryShape } from './shapes/trajectory/trajectory-shape';
import { ShadowShape } from './shapes/path-shape/shadow/shadow-shape';
import { TextShape } from './shapes/text/text-shape';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AnimationService } from '../animation/animation.service';
import { Resource } from '../../elements/resource/resource';
import { OrientationService } from '../../services/orientation/orientation.service';
import { currentAnimationId } from '../animation/store/animation.selector';
import { HttpService } from '../../store/http/http.service';
import { RequestType } from '../../store/store.service';
import {
  addAnimationItemAction,
  setMainAnimationFrame,
} from '../animation/store/animation.actions';
import { getCurrentProject } from '../../projects/project.selector';
import { Project } from '../../projects/project.interface';
import { ActivatedRoute } from '@angular/router';
import { HandShapeNext } from './shapes/path-shape/hand-section/hand-shape-new';
import { SubtitleElement } from './subtitle/subtitle-element';
import { stepLanguage } from '../../projects/project.actions';
import { AnimationFrame } from '../animation/components/animation-frame/animation.types';
import { currentOrganisation } from '../../organisation/organisation.selector';
import { Organisation } from '../../organisation/organisation.interface';

@Injectable()
export class ShapeService {
  selectorRectangle: IndividualRectangleShape;
  // previewShape: RootShape;

  get rootShape() {
    return this.cs.previewShape;
  }

  get previewShape() {
    return this.cs.previewShape;
  }

  set previewShape(shape: RootShape) {
    this.cs.previewShape = shape;
  }

  get selectedShapes() {
    return Object.keys(this.selectedShapesStore)
      .map(IRI => this.resourceStore[IRI] as GeneralShape)
      .filter(shape => !!shape);
  }

  get currentScene() {
    return this.cs.currentScene;
  }

  lastFileData: ResourceData;

  resetFileState() {
    if (!this.lastFileData) {
      return;
    }
    // -- // -- //
    this.openRootShape(this.lastFileData);
  }

  appContainer: Container;
  previewShapeIsLoading = false;
  openRootShape(resourceData: ResourceData) {
    console.log('open-root-shape', resourceData);
    this.resourceStore = {};
    this.selectedShapesStore = {};
    this.cs.resetImage();

    this.lastFileData = resourceData;

    this.previewShape = new RootShape(this, resourceData);

    this.previewShape.afterInit();

    this.animationService.init();
    this.cs.addShapeToCanvas(this.previewShape);
    this.previewShapeIsLoading = false;

    return this.previewShape;
  }

  setCanvas() {
    // -- //
  }

  switchScene(scene: string) {
    if (this.cs.previewShapesByScene[scene]) {
      this.cs.previewShapesByScene[scene].readdChildreContainer();
      this.cs.addShapeToCanvas(this.cs.previewShapesByScene[scene]);
      return;
    }

    this.previewShapeIsLoading = true;
    this.store.dispatch(loadScene({ scene })); //
  }

  currentAnimationId: string;
  noAnimationMode: boolean;
  currentProject: Project;
  _scenes: string[];
  currentOrganisation: Organisation;
  constructor(
    readonly store: Store,
    readonly cs: CanvasService,
    readonly animationService: AnimationService,
    readonly orientationService: OrientationService,
    readonly http: HttpService,
    private route: ActivatedRoute,
  ) {
    this.store.select(currentAnimationId).subscribe(id => {
      this.currentAnimationId = id;
    });

    this.store.select(getCurrentProject).subscribe(project => {
      // console.log('------ currentProject -----', project);
    });

    this.store.select(selectNoAnimationMode).subscribe(val => {
      this.noAnimationMode = val;
    });

    this.store.select(selectCurrentScenes).subscribe(scenes => {
      this._scenes = scenes;
    });

    this.store.select(currentOrganisation).subscribe(org => {
      this.currentOrganisation = org;
    });

    combineLatest([
      this.store.select(getCurrentProject),
      this.store.select(getFile),
      this.store.select(selectFontLoaded),
    ])
      .pipe(
        filter(
          ([project, file, fontLoaded]) => !!project && !!file && fontLoaded,
        ),
      )
      .subscribe(async ([project, file]) => {
        this.store.dispatch(
          setCurrentScene({ scene: this.currentScene || 'main' }),
        );
        this.currentProject = project;

        if (this.previewShape && this.previewShape.IRI == file.IRI) {
          // if (this.cs.previewShapesByScene[this.cs.currentScene]) {
          //   // console.log('------ add-existing -------', this.cs.currentScene);
          //   this.cs.addShapeToCanvas(
          //     this.cs.previewShapesByScene[this.cs.currentScene],
          //   );
          // } else {
          //   this.openRootShape(file);
          // }
        } else {
          // -- //
        }
        this.openRootShape(file);
        this.cs.generalEventEmit('fileLoaded', true);
      });

    this.cs.keyEventSubscribe('l+Tab', () => {
      this.cs.consumeKeyEvent('Tab');
      this.store.dispatch(stepLanguage());
    });

    this.cs.keyEventSubscribe('r+c', () => {
      const [is] = this.selectedShapes;
      console.log('r+c > selectedShapes', this.selectedShapes);
      if (is?.getType() == 'is') {
        (is as ImportedShape).addRotationCenterPC();
      }
    });

    this.cs.keyEventSubscribe('Shift+r', () => {
      this.cs.consumeKeyEvent('r');
      console.log('------ reset-base-state -------');
      this.resetBaseState();
    });
    this.cs.keyEventSubscribe('c+i', () => {
      this.createImportedShapeFromSelection();
    });

    this.cs.keyEventSubscribe('m+n', () => {
      const frame: AnimationFrame = {
        id: '0.529753967900835',
        duration: 0.3,
        next: {
          id: '0.7619940321178003',
          duration: 0.5,
          next: {
            id: '0.4810049711436839',
            duration: 1,
          },
          paralell: {
            id: '0.811566031567388',
            duration: 1,
            next: {
              id: '0.3261001750897863',
              duration: 1,
              function: 'main',
              functionTarget: {
                IRI: 'http://nowords.com#033e11c0-3c7e-11ef-965c-571f7dcce200',
                label: 'roomRaiser:1',
              },
              paralell: {
                id: '0.6993895810502311',
                duration: 1.75,
                next: {
                  id: '0.9908268664104731',
                  duration: 2.2,
                  mainScene: 'main',
                  targetSubScene: 'hotel-appear-1',
                  paralell: {
                    id: '0.06908549018106735',
                    duration: 0.5,
                    next: {
                      id: '0.9060344394605093',
                      duration: 2,
                      function: 'main',
                      functionTarget: {
                        IRI: 'http://nowords.com#13546760-3acd-11ef-a4d1-9dca40324d4b',
                        label: 'scene-12:1',
                      },
                      next: {
                        id: '0.08180463859432385',
                        duration: 1,
                        stateTransition: {
                          from: 'main',
                          to: 'reception',
                        },
                      },
                    },
                    soundAnimationFrame: {
                      id: '0.22883606039012294',
                      duration: 4.424988662131519,
                      type: 'text',
                      delay: true,
                      soundFileUrl: '0.22883606039012294.mp3',
                      text: 'As a hotel manager, you have to succeed in a tough industry.',
                      subtitle: true,
                      langs: {
                        hu: {
                          text: 'Szállodavezetőként egy kemény iparágban kell helytállnod,',
                          voiceType: 'generated',
                          soundFileUrl: '0.22883606039012294.mp3',
                          recordFileUrl: '0.22883606039012294_hu_audio.wav',
                          audioDuration: 3.8399773242630384,
                        },
                      },
                      next: {
                        id: '0.5909212398322665',
                        duration: 4.249977324263039,
                        type: 'text',
                        soundFileUrl: '0.5909212398322665.mp3',
                        text: 'You have to outperform the competition constantly.',
                        subtitle: true,
                        langs: {
                          hu: {
                            text: 'ahol folyamatosan túl kell szárnyalnod a konkurenciát.',
                            voiceType: 'generated',
                            soundFileUrl: '0.5909212398322665.mp3',
                            recordFileUrl: '0.5909212398322665_hu_audio.wav',
                            audioDuration: 3.36,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      } as AnimationFrame;

      console.log('migrate', frame);
      this.store.dispatch(setMainAnimationFrame({ frame }));
      // });

      // this.cs.keyEventSubscribe('m+n', () => {
      const scenes = [
        {
          name: 'main',
          subscenes: [
            {
              name: 'hotel-appear-1',
              position: {
                x: 0,
                y: -933.1199999999995,
                scale: {
                  x: 1,
                  y: 1,
                },
              },
            },
            {
              name: 'movein',
              position: {
                x: -2670.1840522190723,
                y: -7890.460838961382,
                scale: {
                  x: 4.2998169599999985,
                  y: 4.2998169599999985,
                },
              },
            },
          ],
        },
        {
          name: 'reception',
          subscenes: [
            {
              name: 'zoomIn',
              position: {
                x: -511.1276554576753,
                y: -90.99300784033481,
                scale: {
                  x: 1.353933215657497,
                  y: 1.353933215657497,
                },
              },
            },
            {
              name: 'duties',
              position: {
                x: -292.7244444444445,
                y: 1.9998313574713453,
                scale: {
                  x: 1.44,
                  y: 1.44,
                },
              },
            },
            {
              name: 'final-zoom',
              position: {
                x: -1115.7903999999996,
                y: 89.25027935957999,
                scale: {
                  x: 2.9859839999999997,
                  y: 2.9859839999999997,
                },
              },
            },
          ],
        },
        {
          name: 'roomRaiser',
          subscenes: [
            {
              name: 'logo',
            },
            {
              name: 'hotel-data',
              position: {
                x: 306.7714876249808,
                y: -67.69311989638865,
                scale: {
                  x: 0.5787037037037038,
                  y: 0.5787037037037038,
                },
              },
            },
            {
              name: 'other-sources',
              position: {
                x: 238.2020518166438,
                y: -170.904898459,
                scale: {
                  x: 0.6944444444444445,
                  y: 0.6944444444444445,
                },
              },
            },
            {
              name: 'pricing-calc',
              position: {
                x: 240.08888888888885,
                y: 356.62526143391926,
                scale: {
                  x: 0.6944444444444445,
                  y: 0.6944444444444445,
                },
              },
            },
            {
              name: 'processing',
              position: {
                x: -128.2109148866701,
                y: -46.19299938513251,
                scale: {
                  x: 1.2000000000000002,
                  y: 1.2000000000000002,
                },
              },
            },
            {
              name: 'pricing-final',
              position: {
                x: 749.4878686998431,
                y: 162.1100735039742,
                scale: {
                  x: 0.6944444444444446,
                  y: 0.6944444444444446,
                },
              },
            },
            {
              name: 'integration',
              position: {
                x: 749.4878686998431,
                y: 758.8993964206404,
                scale: {
                  x: 0.6944444444444448,
                  y: 0.6944444444444448,
                },
              },
            },
          ],
          position: {
            x: 0,
            y: 1061,
            scale: {
              x: 1,
              y: 1,
            },
          },
        },
        {
          name: 'final',
        },
      ];

      this.store.dispatch(setScenes({ scenes }));
    });

    this.cs.keyEventSubscribe('x+m', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'auxMode',
          value: true,
        }),
      );
    });

    this.cs.keyEventSubscribe('f+p', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'fixPosition',
          value: true,
        }),
      );
    });

    this.cs.keyEventSubscribe('s+i', () => {
      if (!this.previewShape) {
        return;
      }

      this.previewShape.shapes.map((shape, index) => {
        shape.index = index;
        this.store.dispatch(
          setDescriptorValue({
            IRI: shape.IRI,
            key: 'index',
            value: index,
          }),
        );
      });
    });

    this.cs.keyEventSubscribe('Space+c', () => {
      this.selectedShapes
        .filter(shape => shape.getType() == 'path-shape')
        .map((ps: PathShape) => ps.flipClosed());
    });

    // -- // -- //

    this.cs.keyEventSubscribe('Shift+ArrowUp', () => {
      const [shape] = this.selectedShapes;
      if (shape) {
        shape.incrementIndex();
      }
    });
    this.cs.keyEventSubscribe('Shift+ArrowDown', () => {
      const [shape] = this.selectedShapes;
      if (shape) {
        shape.decrementIndex();
      }
    });

    this.cs.keyEventSubscribe('Shift+p', () => {
      if (this.currentAnimationId) {
        this.cs.consumeKeyEvent('a');
        this.store.dispatch(
          addAnimationItemAction({ item: { key: 'appear' } }),
        );
      }
    });

    this.cs.keyEventSubscribe('Shift+o', () => {
      if (this.currentAnimationId) {
        this.cs.consumeKeyEvent('a');
        this.store.dispatch(
          addAnimationItemAction({ item: { key: 'disappear' } }),
        );
      }
    });

    this.cs.keyEventSubscribe(
      'a',
      () => {
        this.cs.consumeKeyEvent('a');

        this.previewShape.__setPreAnimationState();

        return;
        const shapesToHide = this.animationService.setPreAnimationState();
        // -- // -- //
        // console.log('shapes-to-hide', Object.keys(shapesToHide).length); //
        shapesToHide
          .map(IRI => this.getShapeByIRI(IRI))
          .map(shape => {
            // console.log('shape', shape)
            shape?._hide();
            shape.hiddenByAnimation = true;
          });

        this.previewShape.shapes
          .filter(shape => shape.auxMode)
          .map(shape => shape._hide());

        // shapesToHide.map(shapeIRI => {
        //   this.store.dispatch(
        //     setCurrentDescriptorSubAttribute({
        //       shapeIRI,
        //       key: 'opacity',
        //       value: 0,
        //     }),
        //   );
        // });
        // shapesToHide.map(IRI => {
        //   this.getShapeByIRI(IRI)?._hide();
        //   if (!this.getShapeByIRI(IRI)) {
        //     console.log('not-found', IRI);
        //   }
      },
      1,
    );

    this.cs.keyEventSubscribe(
      'Backspace',
      consume => {
        if (!this.previewShape) {
          return;
        }
        if (this.selectedShapes.length) {
          consume();
        }
        this.selectedShapes.map(shape => shape.delete());
        this.hideGroupRC();
      },
      10,
    );

    this.cs.keyEventSubscribe('Shift+g', () => this.groupCommandHandler());
    this.cs.keyEventSubscribe('Space+g', () => {
      const [groupShape] = this.selectedShapes;
      if (groupShape.getType() == 'group-shape') {
        (groupShape as GroupShape).ungroup();
      }
    });

    this.cs.keyEventSubscribe('Space+v', () => {
      this.cs.consumeKeyEvent('v');
      this.verticalAlign();
    });
    this.cs.keyEventSubscribe('Space+h', () => {
      this.horizontalAlign();
      this.cs.consumeKeyEvent('h');
    });

    this.cs.keyEventSubscribe('Shift+c', () => {
      if (!!this.previewShape && this.selectedShapes.length) {
        this.cs.copiedShapes = this.selectedShapes;
        this.cs.notify('Copied');
      }
    });

    this.cs.keyEventSubscribe('Shift+v', () => {
      if (!!this.previewShape && this.cs.copiedShapes?.length) {
        const newShapes = this.cs.copiedShapes.map(shape => {
          shape.deselect();
          return this.previewShape.addCopiedShape(shape);
        });
        this.selectedShapesStore = {};
        console.log('pasted');
        this.cs.notify('Pasted');
        newShapes.map(shape => shape.select({ direct: true }));
        this.showGroupRC();
      }
    });

    this.cs.keyEventSubscribe('a+x', () => {
      if (this.cs.selectedShapes.length) {
        this.cs.consumeKeyEvent('a');
        this.cs.consumeKeyEvent('x');
      }
      this.cs.selectedShapes.map(shape => {
        shape.auxMode = !shape.auxMode;
        this.store.dispatch(
          setDescriptorValue({
            IRI: shape.IRI,
            key: 'auxMode',
            value: shape.auxMode,
          }),
        );
      });
    });
    // this.store.select;

    // setTimeout(() => this.addGradient(), 1_500);
  }

  subtitle: SubtitleElement;

  addGradient() {
    // Set up your Pixi application
    const app = this.cs.app;

    // Define desired width and height for the rectangle
    const rectWidth = 300;
    const rectHeight = 200;

    // Vertex shader (basic passthrough)
    const vertexShader = `
  attribute vec2 aVertexPosition;
  attribute vec2 aTextureCoord;
  varying vec2 vTextureCoord;
  
  uniform mat3 translationMatrix;
  uniform mat3 projectionMatrix;
  
  void main() {
    // Apply translation and projection matrices
    gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    vTextureCoord = aTextureCoord;
  }
`;

    // Fragment shader (creates a gradient)
    const fragmentShader = `
  varying vec2 vTextureCoord;

  void main() {
    // Define the gradient colors
    vec4 endColor = vec4(241.0 / 255.0, 162.0 / 255.0, 0.0, 1.0); // #F1A200
    vec4 startColor = vec4(155.0 / 255.0, 81.0 / 255.0, 224.0 / 255.0, 1.0); // #9B51E0

    // Mix colors based on the normalized coordinate
    float mixValue = (vTextureCoord.x + vTextureCoord.y) / 2.0;
    gl_FragColor = mix(startColor, endColor, mixValue);
  }
`;

    // Create a shader and apply it to a PIXI mesh
    const shader = Shader.from(vertexShader, fragmentShader);

    // Define geometry for a specific width and height
    const geometry = new Geometry()
      .addAttribute(
        'aVertexPosition', // the attribute name
        [
          0,
          0, // top-left
          1,
          0, // top-right
          1,
          1, // bottom-right
          0,
          1,
        ], // bottom-left
        2,
      ) // the size of the attribute (2D coordinates)
      .addAttribute(
        'aTextureCoord', // the attribute name
        [
          0,
          0, // top-left
          1,
          0, // top-right
          1,
          1, // bottom-right
          0,
          1,
        ], // bottom-left
        2,
      ) // the size of the attribute (texture coordinates)
      .addIndex([0, 1, 2, 0, 2, 3]);

    const quad = new Mesh(geometry, shader);
    quad.scale.set(rectWidth, rectHeight);

    // Create a container and add the mesh to it
    const container = new Container();
    container.addChild(quad);

    // Center the rectangle by adjusting the container's pivot
    container.pivot.set(rectWidth / 2, rectHeight / 2);

    // Position the container in the center of the canvas
    container.position.set(app.renderer.width / 2, app.renderer.height / 2);

    // Add the container to the stage
    const rectangle = new Graphics();
    rectangle.lineStyle({ width: 3, color: 0 });
    rectangle.beginFill(0, 1);
    rectangle.drawRoundedRect(0, 0, rectHeight, rectHeight, 10);
    rectangle.endFill();

    quad.mask = rectangle;
    this.cs.previewShape.container.addChild(quad);
    this.cs.previewShape.container.addChild(rectangle);
  }

  addGradient1() {
    // Set up your Pixi application

    // Define desired width and height for the rectangle
    const rectWidth = 300;
    const rectHeight = 200;

    // Vertex shader (basic passthrough)
    const vertexShader = `
  attribute vec2 aVertexPosition;
  attribute vec2 aTextureCoord;
  varying vec2 vTextureCoord;

  void main() {
    // Position is used as-is since we will handle the scaling outside
    gl_Position = vec4(aVertexPosition, 0.0, 1.0);
    vTextureCoord = aTextureCoord;
  }
`;

    // Fragment shader (creates a gradient)
    const fragmentShader = `
  varying vec2 vTextureCoord;

  void main() {
    // Define the gradient colors
    vec4 endColor = vec4(241.0 / 255.0, 162.0 / 255.0, 0.0, 1.0); // #F1A200
    vec4 startColor = vec4(155.0 / 255.0, 81.0 / 255.0, 224.0 / 255.0, 1.0); // #9B51E0

    // Mix colors based on the normalized coordinate
    float mixValue = (vTextureCoord.x + vTextureCoord.y) / 2.0;
    gl_FragColor = mix(startColor, endColor, mixValue);
  }
`;

    // Create a shader and apply it to a PIXI mesh
    const shader = Shader.from(vertexShader, fragmentShader);

    // Define geometry for a specific width and height
    const geometry = new Geometry()
      .addAttribute(
        'aVertexPosition', // the attribute name
        [
          0,
          0, // top-left
          1,
          0, // top-right
          1,
          1, // bottom-right
          0,
          1,
        ], // bottom-left
        2,
      ) // the size of the attribute (2D coordinates)
      .addAttribute(
        'aTextureCoord', // the attribute name
        [
          0,
          0, // top-left
          1,
          0, // top-right
          1,
          1, // bottom-right
          0,
          1,
        ], // bottom-left
        2,
      ) // the size of the attribute (texture coordinates)
      .addIndex([0, 1, 2, 0, 2, 3]);

    const app = this.cs.app;

    const quad = new Mesh(geometry, shader);
    quad.position.set(app.renderer.width / 2, app.renderer.height / 2); // Center the rectangle

    // Apply scaling to get the desired width and height
    quad.scale.set(rectWidth, rectHeight);

    // Center the rectangle by adjusting the pivot
    quad.pivot.set(0.5, 0.5);

    // Add the mesh to the stage
    // app.stage.addChild(quad);

    this.rootShape.container.addChild(quad);
  }

  addSubtitle(text: string) {
    this.subtitle = new SubtitleElement(this, text);
  }

  removeSubtitle() {
    this.subtitle.delete();
  }

  resolveColor(key: string) {
    if (key?.startsWith?.('<')) {
      const formula = key.slice(1);

      if (formula.startsWith('random')) {
        const [c1, c2, c3] = formula
          .slice(7)
          .split(',')
          .map(v => v.trim());
        // console.log('random-color', c1, c2, c3.slice(0, 7));
        // const color = ColorIncrementController.getColor(c1, c2.trim(), Math.random())
        // console.log('color', color);
        const num = Math.random();

        if (num < 0.333) {
          return c1;
        }
        if (num < 0.666) {
          return c2;
        }
        return c3.slice(0, 7);
      }

      return key;
    }

    if (!key?.startsWith?.('$')) {
      return key;
    }
    const colorPalette = {
      ...(this.currentProject?.colorPalette || {}),
      ...(this.currentOrganisation?.colorPalette || {}),
    };
    if (!colorPalette) {
      console.warn('color-palette-could not be found');
    }
    return colorPalette?.[key.slice(2)];
  }

  hoverSelectedStore: Record<string, true> = {};

  get hoverSelectedShapes() {
    return Object.keys(this.hoverSelectedStore).map(IRI =>
      this.getShapeByIRI(IRI),
    );
  }

  hoverSelect(shape: GeneralShape) {
    // if (!this.cs.isShiftPressed) {
    //   this.hoverSelectedShapes.map(shape => shape?.hideDragControllers());
    // }

    this.hoverSelectedStore[shape.IRI] = true;
  }

  hoverDeselect(shape: GeneralShape) {
    delete this.hoverSelectedStore[shape.IRI];
  }

  selectedImportedShape: ImportedShape;

  select(shape: GeneralShape, childShapeSelect = false) {
    this.selectedImportedShape =
      shape.getType() == 'is' &&
      this.selectedShapes.length == 0 &&
      !this.cs.isShiftPressed
        ? (shape as ImportedShape)
        : null;

    if (!this.cs.isShiftPressed && !childShapeSelect) {
      this.deselectAllShapes();
    }
    this.selectedShapesStore[shape.IRI] = true;
    shape._select();

    if (
      this.selectedShapes.length > 1 &&
      !childShapeSelect &&
      !this.selectedShapes.find(shape => shape.getType() == 'hand-shape-next')
    ) {
      this.selectedShapes.map(shape => shape.hideDragControllers());
      this.showGroupRC();
    }

    this.saveSelectedShapes();
  }

  deselect(shape: GeneralShape) {
    this.saveSelectedShapes();
  }
  deselectAllShapes() {
    this.selectedShapes.map(shape => shape._deselect());
    this.selectedShapesStore = {};
    this.hideGroupRC();
  }
  resetBaseState() {
    this.store.dispatch(resetBaseState());
    this.previewShape.resetBaseState();
    this.animationService.resetBaseState();
  }

  switchToNewScene(sceneName: string) {
    // -- //

    if (!this.cs.previewShapesByScene[sceneName]) {
      return;
    }

    const rootShape = this.cs.previewShapesByScene[sceneName];

    console.log('--- Switch to new scene > shape ---', rootShape);

    const targetScene = rootShape.scenes.find(scene => scene.name == sceneName);

    if (!targetScene) {
      console.warn('Target scene could not be found');
      return;
    }

    console.log('--- Switch to new scene > target-scene ---', targetScene);
    if (targetScene.position) {
      // -- // -- //
    }
  }

  groupCommandHandler() {
    const selectedShapes = this.selectedShapes;
    console.log('grouping', 'selected shapes', selectedShapes);

    // console.log(
    //   'grouping',
    //   'selected',
    //   selectedShapes.map(shape => shape.parent)
    // );

    // const groupShapes = selectedShapes.filter(
    //   ({ type }) => type === ResourceType.GroupShape,
    // ) as GroupShape[];

    // if (selectedShapes.length < 2 && groupShapes.length === 0) {
    //   return;
    // }

    // if (groupShapes.length === 0) {
    if (true) {
      // -- // -- // create new group shape // -- // -- //

      console.log('create new group shape', this.selectedShapes);
      const { x: xb, y: yb } = this.getBoundingRectangle(this.selectedShapes);

      const { x, y } = this.cs.getAbsoluteCoords(xb, yb);

      const groupShape = this.addGroupShape(x, y) as GroupShape;
      groupShape.addChildren(
        this.selectedShapes.sort((s1, s2) => (s1.index > s2.index ? 1 : -1)),
      );
      groupShape.initRC(true);
      groupShape.select();

      this.groupRC?.remove();

      return;
    }

    // if (selectedShapes.length === 1 && groupShapes.length === 1) {
    // split group shape
    // console.log('split group shape');
    // const [groupShape] = groupShapes;
    // const root = groupShape.parentShape;
    // console.log(root);
    // groupShape.getChildren().forEach(shape => shape.split(root));
    // groupShape.deselect();
    // this.deleteResource(groupShape);

    return;
    // }

    // Merge general and group shapes children into the first group shape
    // console.log('merge shapes');
    // const [targetGroupShape, ...otherGroupShapes] = groupShapes;
    // const shapes = [
    //   ...selectedShapes.filter(({ type }) => type !== ResourceType.GroupShape),
    // ].concat(otherGroupShapes.flatMap(groupShape => groupShape.getChildren()));

    // shapes.forEach(shape => shape.addMeToGroup(targetGroupShape));
    // otherGroupShapes.forEach(groupShape => {
    //   groupShape.deselect();
    //   this.deleteResource(groupShape);
    // });
    // targetGroupShape.calculate();
    // targetGroupShape.select();
  }

  initShapeByDescriptor(
    descriptor: GeneralShapeDescriptor,
    parent?: GeneralShape,
    config?: ShapeConfig,
  ) {
    return this.initShape({
      IRI: Math.random().toString(),
      literals: { descriptor },
    });
  }

  initShape(
    data: ResourceData<GeneralShapeDescriptor>,
    parent?: GeneralShape,
    config?: ShapeConfig,
  ) {
    if (parent) {
      _set(data, 'relationships.parent', parent.IRI);
    }

    let shape: GeneralShape;
    const type: string = (data as ResourceData).literals.descriptor.type;
    let shapeClass;
    switch (type) {
      case 'image-shape':
        shapeClass = ImageShape;
        break;
      case 'imported-shape':
        shapeClass = ImportedShape;
        break;
      case 'imported-shape-preview':
        shapeClass = ImportedShape;
        break;

      case 'trajectory-shape':
        shapeClass = TrajectoryShape;
        break;

      case 'circle-shape':
        shapeClass = CircleShape;
        break;

      case 'rectangle-shape':
        shapeClass = RectangleShape;
        break;

      case 'shadow-shape':
        shapeClass = ShadowShape;
        break;

      case 'text-shape':
        shapeClass = TextShape;
        break;
      case 'group-shape':
        // console.log('-new-group-shape-');
        shapeClass = GroupShape;
        break;
      case 'hand-shape':
        shapeClass = HandShapeNext;
        break;
      default:
        shapeClass = PathShape;
        break;
    }

    return new shapeClass(this, data, config);
  }

  resourceStore: Record<string, Resource> = {};

  saveResource(resource: Resource) {
    this.resourceStore[resource.IRI] = resource;
  }

  deleteResource(resource: Resource) {
    delete this.resourceStore[resource.IRI];
  }

  getResource(IRI: string): Resource {
    return this.resourceStore[IRI];
  }

  getShapeByIRI(IRI: string): GeneralShape {
    return this.resourceStore[IRI] as GeneralShape;
  }

  dxAvg: number;
  dyAvg: number;

  isColumnSelection() {
    if (this.selectedShapes.length < 3) {
      return false;
    }

    // -- //
    const selectedShapesByY = this.selectedShapes.sort(
      (shape1, shape2) => shape1.y - shape2.y,
    );

    this.dyAvg = 0;

    for (let i = 0; i < selectedShapesByY.length - 2; i++) {
      const [shape1, shape2] = [selectedShapesByY[i], selectedShapesByY[i + 1]];
      const [dx, dy] = [shape2.x - shape1.x, shape2.y - shape1.y];
      if (Math.abs(dy) < 2 * Math.abs(dx)) {
        return false;
      }
      this.dyAvg += dy;
    }

    this.dyAvg /= selectedShapesByY.length - 2;
    this.dyAvg = Math.floor(this.dyAvg);
    console.log('dyAvg', this.dyAvg);
    return true;
  }

  evenAlignColum(gap = this.dyAvg) {
    // -- //
    let y: number;
    this.selectedShapes.map((shape, index) => {
      if (index == 0) {
        y = shape.y;
        return;
      }
      y += gap;
      shape.y = y;
      shape.save();
      shape.redraw();
    });
  }

  isRowSelection() {
    if (this.selectedShapes.length < 3) {
      return false;
    }

    // -- //
    const selectedShapesByX = this.selectedShapes.sort(
      (shape1, shape2) => shape1.x - shape2.x,
    );

    this.dxAvg = 0;

    for (let i = 0; i < selectedShapesByX.length - 2; i++) {
      const [shape1, shape2] = [selectedShapesByX[i], selectedShapesByX[i + 1]];
      const [dx, dy] = [shape2.x - shape1.x, shape2.y - shape1.y];
      // console.log('dx', dx, 'dy', dy); //
      // if (Math.abs(dx) < 2 * Math.abs(dy)) {
      if (Math.abs(dx) < shape1.width) {
        return false;
      }
      this.dxAvg += dx;
    }

    this.dxAvg /= selectedShapesByX.length - 2;
    this.dxAvg = Math.floor(this.dxAvg);
    return true;
  }

  evenAlignRow(gap = this.dxAvg) {
    // -- //
    let x: number;
    this.selectedShapes.map((shape, index) => {
      if (index == 0) {
        x = shape.x;
        return;
      }
      x += gap;
      shape.x = x;
      shape.save();
      shape.redraw();
    });
  }

  verticalAlign(pos: 'up' | 'center' | 'bottom' = 'center') {
    let ySum = 0;
    const shapes = this.cs.selectedShapes;
    const diffs = [];
    shapes.map(shape => {
      const { height } = shape.mainContainer.getBounds();
      const dy = height / 2;
      ySum += shape.y + dy;
      diffs.push(dy);
    });
    const mean = ySum / shapes.length;
    shapes.map((shape, i) => {
      shape.y = mean - diffs[i];
      shape.save();
      shape.redraw();
    });
  }

  horizontalAlign(pos: 'left' | 'center' | 'right' = 'center') {
    let xSum = 0;
    const shapes = this.cs.selectedShapes;
    const diffs = [];
    shapes.map(shape => {
      const { width } = shape.mainContainer.getBounds();
      const dx = width / 2;
      // console.log('height', width);

      if (isNaN(+width)) {
        return;
      }

      xSum += shape.x + dx;
      diffs.push(dx);
    });
    const mean = xSum / shapes.length;
    shapes.map((shape, i) => {
      shape.x = mean - diffs[i];
      shape.save();
      shape.redraw();
    });
  }

  concentricCircles() {
    let xSum = 0;
    let ySum = 0;
    const diffs = [];
    const shapes = this.cs.selectedShapes;
    shapes.map((shape: CircleShape) => {
      shape.makeSymmetric();
      const [ox, oy] = shape.origin;
      xSum += ox;
      ySum += oy;
    });

    const [x, y] = [xSum, ySum].map(v => v / shapes.length);
    shapes.map((shape: CircleShape) => {
      shape.origin = [x, y];
      shape.save();
      shape.redraw();
    });
  }

  setAsDarkShadow() {
    this.cs.selectedShapes.map(shape => {
      shape.patch('svgAttributes', {
        fill: '#000',
        opacity: 0.1,
        stroke: null,
      });
      // console.log('opacity', shape.opacity);
    });
  }

  setAsLightShadow() {
    this.cs.selectedShapes.map(shape => {
      shape.patch('svgAttributes', {
        fill: '#ffffff',
        opacity: 0.3,
        stroke: null,
      });
    });
  }

  dragState: 'started' | 'took-place' | 'idle' = 'idle';

  shapesToBeDragged: GeneralShape[];

  startDrag(shape?: GeneralShape) {
    if (shape && shape?.selected) {
      this.shapesToBeDragged = [shape];
    } else if (shape?.groupShapeParent) {
      this.shapesToBeDragged = this.selectedShapes.filter(
        _shape => _shape?.IRI !== shape.groupShapeParent.IRI,
      );
    } else {
      this.shapesToBeDragged = this.selectedShapes;
    }
    this.shapesToBeDragged.map(shape => shape?.startBaseDrag());
  }

  drag(dx: number, dy: number, trace?: string) {
    // console.log('shape.service > drag', dx, dy);
    // this.orientationService.clearOrientationLines();
    // const {
    //   xMin: xs,
    //   yMin: ys,
    //   xMax: xe,
    //   yMax: ye,
    // } = this.getSelectedBoundingRectangle();
    // -- // -- //
    //   this.orientationService._checkHorizontal('cs', [
    //     {
    //       coords: [xs, ys],
    //       left: false,
    //     },
    //     {
    //       coords: [xs, ye],
    //       left: false,
    //     },
    //     {
    //       coords: [xe, ys],
    //       left: true,
    //     },
    //     {
    //       coords: [xe, ye],
    //       left: true,
    //     },
    //   ]);
    //   this.orientationService._checkVertical('cs', [
    //     {
    //       coords: [xs, ys],
    //       top: false,
    //     },
    //     {
    //       coords: [xe, ys],
    //       top: false,
    //     },
    //     {
    //       coords: [xs, ye],
    //       top: true,
    //     },
    //     {
    //       coords: [xe, ye],
    //       top: true,
    //     },
    //   ]);

    this.shapesToBeDragged.map(shape => shape?.drag(dx, dy));
  }

  endDrag(_params?: ShapeDragParams) {
    this.shapesToBeDragged.map(shape => shape.endDrag());
  }

  addTrajectoryAppearAnimation() {
    // this.cs.selectedShapes
    //   .filter(shape => shape.getType() == 'ps')
    //   .map((ps: PathShape) => {
    //     ps.addAnimation('trajectory-appear', null);
    //     ps.save();
    //   });
  }

  startSelectorRectangle(x: number, y: number) {
    if (!this.cs.previewShape) {
      return;
    }
    if (this.selectorRectangle) {
      this.cs.app.stage.removeChild(this.selectorRectangle.container);
    }

    this.selectorRectangle?.remove();
    this.selectorRectangle = new IndividualRectangleShape(this, {
      position: { x, y },
      width: 0,
      height: 0,
      svgAttributes: {
        stroke: '#000000',
      },
    });
    this.cs.addToCanvas(this.selectorRectangle);
  }

  selectorWidth = 0;
  selectorHeight = 0;
  strict = false;

  dragSelectorRectangle(x: number, y: number, width: number, height: number) {
    if (!this.cs.previewShape) {
      return;
    }
    if (!this.selectorRectangle) {
      return;
    }
    this.selectorWidth = Math.abs(width);
    this.selectorHeight = Math.abs(height);
    this.strict = width < 0;
    this.selectorRectangle.x = x;
    this.selectorRectangle.y = y;
    this.selectorRectangle._redraw({ x, y });
    this.selectorRectangle?._resize(this.selectorWidth, this.selectorHeight);

    // const shapeToSelect = this.cs.previewShape.shapes.filter(shape =>
    //   shape.selection({
    //     xLimits:
    //       this.selectorWidth > 0
    //         ? [x, x + this.selectorWidth]
    //         : [x + this.selectorWidth, x],
    //     yLimits:
    //       this.selectorHeight > 0
    //         ? [y, y + this.selectorHeight]
    //         : [y + this.selectorHeight, y],
    //     strict: this.strict,
    //   }),
    // );

    // const currentSelection = {};
    // shapeToSelect.map(shape => {
    //   currentSelection[shape.IRI] = shape;
    //   shape.rc?.show();
    //   // shape.rc?.hidePointControllers();
    // });

    // Object.keys(this.selectedShapesStore).map(IRI => {
    //   if (!currentSelection[IRI]) {
    //     this.getShapeByIRI(IRI)?.hideDragControllers();
    //   }
    // });

    // this.hoverSelectedShapes.map(shape => shape?.hideDragControllers());
    // this.hoverSelectedStore = {};

    // this.selectedShapesStore = currentSelection;
  }

  removeSelectorRectangle() {
    // if (!this.cs.previewShape) {
    //   return;
    // }

    if (!this.selectorRectangle) {
      return;
    }

    this.hideGroupRC();
    const { x, y } = this.selectorRectangle;

    // TODO - revise that
    if (this.cs.isPressed('Space')) {
      this.cs.generalEventEmit('shape-selection', {
        xLimits:
          this.selectorWidth > 0
            ? [x, x + this.selectorWidth]
            : [x + this.selectorWidth, x],
        yLimits:
          this.selectorHeight > 0
            ? [y, y + this.selectorHeight]
            : [y + this.selectorHeight, y],
      });
    } else {
      if (!this.cs.isShiftPressed) {
        this.selectedShapes.map(shape => shape._deselect());
      }

      const shapeToSelect = this.cs.previewShape.shapes.filter(shape => {
        if (this.cs.isShiftPressed && shape.selected) {
          return true;
        }

        return shape.selection({
          xLimits:
            this.selectorWidth > 0
              ? [x, x + this.selectorWidth]
              : [x + this.selectorWidth, x],
          yLimits:
            this.selectorHeight > 0
              ? [y, y + this.selectorHeight]
              : [y + this.selectorHeight, y],
          strict: this.strict,
        });
      });

      if (!this.cs.isShiftPressed) {
        this.selectedShapesStore = {};
      }

      this.selectedShapesStore = shapeToSelect.reduce((object, shape) => {
        object[shape.IRI] = true;
        return object;
      }, {});

      if (shapeToSelect.length == 0) {
        this.deselectAllShapes();
      } else if (shapeToSelect.length == 1) {
        // -- //
        shapeToSelect[0].select();
      } else if (shapeToSelect.length > 1) {
        this.selectedShapes.map(shape => {
          shape._select();
        });

        this.showGroupRC();
      }

      this.saveSelectedShapes();
    }

    this.selectorWidth = 0;
    this.selectorHeight = 0;
    this.strict = false;
    console.log('this.selector-rectangle > remove');
    this.selectorRectangle?.remove();
    this.selectorRectangle = null;
  }

  saveSelectedShapes() {
    this.store.dispatch(
      setSelectedShapes({ shapes: cloneDeep(this.selectedShapesStore) }),
    );
  }

  checkSelectorRectangle() {
    // -- // -- // -- //
  }

  zoomUpdate() {
    this.selectedShapes.map(shape => shape.zoomUpdate());
    this.hoverSelectedShapes.map(shape => shape?.zoomUpdate());

    if (this.currentGroupRCPostion) {
      const { x, y, width, height } = this.currentAbsoluteGroupRCPostion;

      const [xa, ya] = this.cs.getRelativeCoords(x, y);
      this.groupRCContainer.transform.position.set(xa, ya);
      this.groupRC.patch(
        {
          width: width * this.cs.canvasScale,
          height: height * this.cs.canvasScale,
        },
        true,
      );
    }
  }

  getBoundingRectangle(shapes: GeneralShape[]) {
    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;
    for (const shape of shapes) {
      const { x, y, width, height } = shape.container.getBounds();
      // const { x, y, width, height } = shape.getCurrentBBox();

      if (x < xMin) xMin = x;
      if (y < yMin) yMin = y;
      if (xMax < x + width) xMax = x + width;
      if (yMax < y + height) yMax = y + height;
    }

    this._x = xMin;
    this._y = yMin;

    return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
  }

  groupRC: RectangleController;
  groupRCContainer: Container;

  selectedShapesStore: Record<string, boolean> = {};

  closeAfterEndDrag = false;
  groupRCDragMode = false;

  currentGroupRCPostion: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  currentAbsoluteGroupRCPostion: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  showVerticalEvenSpacing = false;
  verticalSpacingPosition: Coords;

  showHorizontalEvenSpacing = false;
  horizontalSpacingPosition: Coords;

  showGroupRC(shape?: GeneralShape) {
    if (!this.groupRC) {
      // TODO: circle, RT, imported
      this.closeAfterEndDrag = false;

      this.groupRCContainer = new Container();
      this.cs.app.stage.addChild(this.groupRCContainer);
      // this.cs.previewShape.rootContainer.addChild(this.groupRCContainer); //
    }

    const { x, y, width, height } = shape
      ? shape.container.getBounds()
      : this.getBoundingRectangle(this.selectedShapes);

    // console.log('showGroupRC > selected-shapes', this.selectedShapes);

    this.showVerticalEvenSpacing = this.isColumnSelection();
    this.showHorizontalEvenSpacing = this.isRowSelection();

    console.log('isRowSelection', this.showHorizontalEvenSpacing);
    this.verticalSpacingPosition = [x + width + 12, y + height / 2 - 4];
    this.horizontalSpacingPosition = [x + width / 2 - 4, y + height + 12];

    this.groupRCContainer.setTransform(x, y);

    if (shape) {
      this.cs.customShapeToDrag = shape;
    }

    this.currentGroupRCPostion = { x, y, width, height };

    this.currentAbsoluteGroupRCPostion = {
      ...this.cs.getAbsoluteCoords(x, y),
      width: width / this.cs.canvasScale,
      height: height / this.cs.canvasScale,
    };

    const beforeDragPosition = { x, y, width, height };

    if (this.groupRC) {
      this.groupRCContainer.transform.position.set(x, y);
      this.groupRCContainer.visible = true;
      this.groupRC.show();
      return this.groupRC.patch(
        {
          width,
          height,
        },
        true,
      );
    }

    this.groupRC = new RectangleController(
      this.cs.previewShape,
      {
        width,
        height,
        noScale: true,
        offset: [0, 0],
        selectedContainerMode: true,
        dragParams: { noShow: true },
        startDrag: () => {
          this.groupRCDragMode = true;

          // TODO - migrate
          // this.selectedShapes = shape ? [shape] : this.cs.selectedShapes;
          this.selectedShapes.forEach(shape => {
            const dx = shape.x - shape.offsetX - x;
            const dy = shape.y - shape.offsetY - y;
            // console.log('start-transformation', { dx, dy }); //
            shape.startTransformation(dx, dy);
          });
        },
        drag: ({ x: cX, y: cY, width: newWidth, height: newHeight }) => {
          const scaleX = newWidth / beforeDragPosition.width;
          const scaleY = newHeight / beforeDragPosition.height;

          this.currentGroupRCPostion.x = beforeDragPosition.x + cX;
          this.currentGroupRCPostion.y = beforeDragPosition.y + cY;
          this.currentGroupRCPostion.width = newWidth;
          this.currentGroupRCPostion.height = newHeight;

          this.selectedShapes.forEach(shape => {
            shape.transformation(scaleX, scaleY, cX, cY);
            // shape.redraw();
            // shape.refresh();
          });
          this.groupRCContainer.setTransform(
            this.currentGroupRCPostion.x,
            this.currentGroupRCPostion.y,
          );
        },
        endDrag: () => {
          this.groupRC.patch({
            width: this.currentGroupRCPostion.width,
            height: this.currentGroupRCPostion.height,
          });

          // this.groupRC.refresh();
          // console.log('GroupRB > end drag');
          this.selectedShapes.forEach(shape => shape.endTransformation());
          // this.selectedShapes = null;

          // if (shape) {
          //   this.hideGroupRC();
          // } else if (shape) {
          //   this.updateGroupRC(shape);
          // }
        },
        _endDrag: () => {
          this.updateGroupRC(shape);
        },
        _drag: (dx: number, dy: number) => {
          // -- // -- //
          this.moveGroupRc([
            dx / this.cs.canvasScale,
            dy / this.cs.canvasScale,
          ]);
        },
      },
      this.groupRCContainer,
      this.groupRCContainer,
      this.groupRCContainer,
    );

    this.groupRC.zoomUpdate();
  }

  _x: number;
  _y: number;

  updateGroupRC(shape?: GeneralShape) {
    if (!this.groupRC) {
      return;
    }

    console.log('---- update-groupRC ---', { shape });

    this.groupRC.show();

    const { x, y, width, height } = shape
      ? shape.container.getBounds()
      : this.getBoundingRectangle(this.cs.selectedShapes);

    this._x = x;
    this._y = y;

    this.groupRCContainer.setTransform(x, y);

    this.groupRC.patch({
      width,
      height,
    });

    this.groupRC.refresh();
    if (this.groupRC.visible) {
      // this.showGroupRB();
    }

    if (!shape) {
      this.cs.selectedShapesStore.map(shape => {
        shape.hideDragControllers();
      });
    }
  }

  moveGroupRc([dx, dy]: Coords) {
    this.groupRCContainer?.setTransform(this._x + dx, this._y + dy);
  }

  hideGroupRC() {
    this.showHorizontalEvenSpacing = false;
    this.showVerticalEvenSpacing = false;
    this.groupRC?.hide(true);
  }

  getResourceDataByDescriptor(
    label: string,
    descriptor: GeneralShapeDescriptor,
    IRI?: string,
  ) {
    descriptor.index = this.rootShape.getNewShapeIndex();
    descriptor.position ||= this.cs.getAbsoluteCoords(100, 100, true);
    descriptor._animationsByKey = this.cs.currentAnimation?.id
      ? {
          [this.cs.currentAnimation.id]: [{ key: 'appear' }],
        }
      : undefined;

    const resourceData = {
      IRI: IRI || `tmp-${Math.random()}`,
      type: 'http://nowords.com#Shape',
      relationships: {
        parent: this.rootShape.IRI,
      },
      literals: {
        label,
        descriptor,
      },
    };
    return resourceData;
  }

  async addImportedShape({ IRI }: ResourceData) {
    const data = await this.http
      .requestCall({
        type: RequestType.GET,
        path: 'shape-v2/file/' + IRI.split('#')[1],
        // params: {
        //   IRI,
        // },
      })
      .toPromise();

    const { x, y } = this.cs.getAbsoluteCoords(100, 100, true);

    this.addShape({
      label: `${data.literals.label}:1`,
      descriptor: {
        type: 'imported-shape',
        shapeIRI: data.IRI,
        auxMode: this.cs.auxMode,
        index: this.previewShape.getNewShapeIndex(),
        baseShapeDescriptor: data.literals.descriptor,
        position: { x, y, scale: { x: 1, y: 1 } },
      } as ImportedShapeDescriptor,
      relationships: {
        shape: data.relationships?.shape,
      },
    });
  }

  addGroupShape(x: number, y: number) {
    return this.addShape({
      label: 'group-shape',
      descriptor: {
        type: 'group-shape',
        position: { x, y },
        if: { [this.cs.currentState]: true },
      },
    });
  }

  addHandShape(x: number, y: number) {
    return this.addShape({
      label: 'hand-shape',
      descriptor: {
        type: 'hand-shape',
        position: { x, y },
        if: { [this.cs.currentState]: true },
        svgAttributes: {
          'stroke-width': 1,
          stroke: '#000000',
        },
        config: {
          closed: false,
          handSections: [
            {
              r: 60,
            },
            {
              r: 50,
              x: 100,
              y: 220,
            },
            // {
            //   r: 30,
            //   x: 180,
            //   y: 10,
            // },
          ],
        } as HandShapeConfig,
      } as HandShapeNewDescriptor,
    });
  }

  addRectangleShape(x: number, y: number) {
    console.log('add-rectangle-shape', x, y);
    return this.addShape({
      label: 'rectangle-shape',
      descriptor: {
        type: 'rectangle-shape',
        position: { x, y },
        svgAttributes: { stroke: '#000000', 'stroke-width': 1 },
        width: 0,
        height: 0,
      } as RectangleShapeDescriptor,
    });
  }

  addCircleShape(x: number, y: number) {
    return this.addShape({
      label: 'circle-shape',
      descriptor: {
        type: 'circle-shape',
        position: { x, y },
        svgAttributes: { stroke: '#000000', 'stroke-width': 1 },
        rx: 0,
        ry: 0,
      } as CircleShapeDescriptor,
    });
  }

  addPathShape(x: number, y: number) {
    return this.addShape({
      label: 'path-shape',
      descriptor: {
        type: 'path-shape',
        position: { x, y },
        svgAttributes: { stroke: '#000000', 'stroke-width': 1 },
        newCurveAngle: true,
        sections: [
          {
            type: this.cs.isPressed(['s', 'd']) ? 'curve' : 'line',
            id: Math.random().toString(),
            x: 0,
            y: 0,
          },
          {
            type: 'line',
            id: Math.random().toString(),
            x: 0,
            y: 0,
          },
        ],
      } as PathShapeDescriptor,
    });
  }

  addTextShape(x: number, y: number) {
    return this.addShape({
      label: 'text-shape',
      descriptor: {
        type: 'text-shape',
        position: { x, y },
        svgAttributes: { fill: '#000000' },
        // TODO - clean this
        textConfig: {
          text: 'Text',
          fontSize: 28,
        },
        text: 'Text',
        fontSize: 28,
      } as TextShapeDescriptor,
    });
  }

  getNewIRI() {
    return `http://nowords.com#${uuidv1()}`;
  }

  addShape({
    label,
    descriptor,
    config,
    index,
    relationships,
  }: {
    label: string;
    descriptor: GeneralShapeDescriptor;
    config?: ShapeConfig;
    index?: number;
    relationships?: Relationships;
  }) {
    // TOOO - this should be smarter if we are zoomed mode //

    const lastShape =
      this.rootShape.shapesByIndex[this.rootShape.shapeIndexes.length - 1];

    index = lastShape ? lastShape.index + 1 : 0;
    config ||= {
      isRoot: true,
    };
    config.index = this.rootShape.shapes.length;
    if (isNaN(index)) {
      throw new Error(`Invalid index was about to be set: ${index}`);
    }

    descriptor.index = index;

    // same way it should be in the reducer

    const [scene] = this._scenes || [];
    if (scene) {
      descriptor.if = {
        [scene]: true,
      };
    }

    if (this.cs.currentState) {
      descriptor.if = { [this.cs.currentState]: true };
    }

    // TODO - migrate
    if (this.currentAnimationId && !this.noAnimationMode) {
      descriptor._animationsByKey = {
        [this.currentAnimationId]: [{ key: 'appear' }],
      };
    }

    const resourceData = {
      IRI: this.getNewIRI(),
      type: 'http://nowords.com#Shape',
      relationships: {
        parent: this.rootShape.IRI,
        ...(relationships || {}),
      },
      literals: {
        label,
        descriptor,
      },
      // This is not elegant but practical
    };

    // TODOx
    // This is because the path-shape offset is set and it would be readonly
    // This is not efficient
    // console.log('add-shape', resourceData);
    return this.addShapeByResourceData(resourceData, config);
  }

  addShapesByDescriptor(
    descriptors: GeneralShapeDescriptor[],
    masks: Record<string, number>,
  ) {
    let resourceData = descriptors.map((descriptor, index) => ({
      type: 'http://nowords.com#Shape',
      IRI: `http://nowords.com#${uuidv1()}`,
      literals: {
        label: 'shape-' + index?.toString(),
        descriptor,
      },
      relationships: {
        parent: this.previewShape.IRI,
      },
    }));

    resourceData = resourceData.map(data => {
      const descriptor = data.literals.descriptor;
      if (descriptor.maskedBy) {
        descriptor.maskedBy = resourceData[masks[descriptor.maskedBy]].IRI;
      }

      const [scene] = this._scenes || [];
      if (scene) {
        descriptor.if = {
          [scene]: true,
        };
      }

      data.literals.descriptor = descriptor;

      return data;
    });

    const shapes = resourceData.map(resourceData =>
      this.addShapeByResourceData(resourceData),
    );

    console.log('shapes', shapes);

    shapes.forEach(shape => {
      if (shape.maskedBy) {
        const baseShape = (this.getResource(shape.maskedBy) ||
          this.getResource(
            this.previewShape.IRI + '_' + shape.maskedBy,
          )) as GeneralShape;
        baseShape.setMeAsMask(shape as GeneralShape, true);
      }
    });
  }

  addShapeByResourceData(resourceData: ResourceData, config?: ShapeConfig) {
    this.store.dispatch(addNewShapeAction({ data: cloneDeep(resourceData) }));
    const newShape = this.initShape(resourceData, this.previewShape, config);
    this.rootShape._shapes.push(newShape);
    newShape.afterInit();
    return newShape;
  }

  copyShapes() {
    // -- //
  }

  pasteShapes() {
    // -- // -- // -- // -- //
  }

  addCopiedShape() {}

  saveImageToS3() {}

  createImportedShapeFromSelection() {
    if (!this.selectedShapes.length) {
      return;
    }

    const name = prompt('Please give a name to the new component');
    if (!name) {
      return;
    }

    const IRI = this.getNewIRI();
    const { x, y, width, height } = this.getBoundingRectangle(
      this.selectedShapes,
    );

    const offset = {
      ...this.cs.getAbsoluteCoords(x, y),
      width: width / this.cs.canvasScale,
      height: height / this.cs.canvasScale,
    };

    const resourceData: ResourceData = {
      IRI,
      literals: {
        label: name,
        descriptor: {
          offset,
        },
      },
      relationships: {
        parent: 'http://nowords.com#' + this.cs.currentFileID,
        shape: this.selectedShapes.map(shape => shape.IRI),
      },
    };

    this.store.dispatch(saveNewComponentAction({ data: resourceData }));

    const is = this.addShapeByResourceData({
      IRI: this.getNewIRI(),
      literals: {
        label: `${name}-instance`,
        descriptor: {
          index: Math.min(...this.selectedShapes.map(shape => shape.index)),
          type: 'imported-shape',
          position: {
            x: offset.x,
            y: offset.y,
          },
          shapeIRI: IRI,
          baseShapeDescriptor: {
            offset,
          },
        } as ImportedShapeDescriptor,
      },
      relationships: {
        parent: 'http://nowords.com#' + this.cs.currentFileID,
        shape: this.selectedShapes.map(shape => shape.resourceData),
      },
    });

    this.selectedShapes.map(shape => shape.delete(true));
    this.removeSelectorRectangle();

    is.select();
    // -- // -- //
  }
}
