<div class="state-editor-component">
  <div *ngIf="states.length == 0">
    <div class="flex-row">
      <div style="font-style: 12px">Add first state</div>
      <nw-icon type="add" (click)="addFirst()" [size]="16"></nw-icon>
    </div>
  </div>

  <div *ngIf="states.length >= 1">
    <nw-list-editor [items]="states" (dataChanged)="states = $event"></nw-list-editor>
  </div>
  <div class="button-container flex-row">
    <div (click)="save()">Save</div>
    <div (click)="discard()">Close</div>
  </div>
</div>
