import { Scene } from '../../../elements/resource/types/shape.type';
import { GeneralShape } from '../../shape/shapes/general/general-shape';
import { RootShape } from '../../shape/shapes/general/root/root-shape';
import { SceneTransitionFrame } from '../components/animation-frame/animation.types';
import { AnimationFrameObject } from './animation-frame-object';
import { ColorIncrementController } from './increment/controller/color-increment.controller';
import {
  Easing,
  IncrementController,
} from './increment/controller/increment.controller';
import { pick as _pick } from 'lodash';

export class SceneTransitionFrameObject extends AnimationFrameObject<SceneTransitionFrame> {
  // -- // -- // -- //

  get to() {
    return this.frame.stateTransition!.to;
  }

  get from() {
    return this.frame.stateTransition!.from;
  }

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      ..._pick(this.frame, ['hide', 'show', 'transform', 'backgroundColor']),
    };
  }

  select() {
    super.select();
    // if (!this.cs.isShiftPressed) {
    //   this.service.store.dispatch(
    //     setCurrentScenes({
    //       scenes: [this.to],
    //     }),
    //   );
    // }
  }

  backgroundChangeController: ColorIncrementController;

  opacityController1: IncrementController;
  opacityController2: IncrementController;

  fromShapes: GeneralShape[];
  toShapes: GeneralShape[];

  halfTime: number;
  secondStarted = false;

  targetSceneShape: RootShape;

  xIncrement: IncrementController;
  yIncrement: IncrementController;
  async executeAnimation(timeScale?: number): Promise<void> {
    const numberOfBatches = Math.floor(
      this.duration * timeScale * this.cs.batchPerSecond,
    );

    this.halfTime = Math.ceil(numberOfBatches / 2);

    const { hide, show, transform, backgroundColor } = this.frame;
    const all = !hide && !show && !transform && !backgroundColor;

    console.log('execute', { hide, show, transform, backgroundColor });

    this.fromShapes =
      hide || all
        ? this.shape.shapes.filter(shape => {
            if (!Object.keys(shape.ifDefinition || {}).length) {
              return;
            }
            return (
              shape.ifDefinition[this.from] && !shape.ifDefinition[this.to]
            );
          })
        : [];

    const toShape = this.cs.previewShapesByScene[this.to];
    this.toShapes = toShape?.shapes || [];

    this.toShapes.map(shape => {
      shape.container.alpha = 0;
      shape._show();
    });

    if (show || all) {
      // -- //
      if (toShape) {
        // console.log('--- Switch to new scene > shape ---', rootShape); //

        const targetScene = toShape?.scenes.find(
          scene => scene.name == this.to,
        );

        if (!targetScene) {
          console.warn('Target scene could not be found');
          return;
        }

        // console.log('--- Switch to new scene > target-scene ---', targetScene);
        // if (targetScene.position) {
        //   // -- // -- //
        //   const { x, y } = targetScene.position;
        //   this.targetSceneShape.outerContainer.setTransform(x, -y);
        // }

        toShape.outerContainer.setTransform(0, 0);
        toShape._scaleX = 1;
        toShape._scaleY = 1;
        toShape.__setPreAnimationState();
        this.cs.addSceneToCanvas(toShape);
      }
    }

    // this.fromShapes.map(frame => frame._hide());
    // this.toShapes.map(frame => {
    //   if (!frame.hidden) {
    //     frame._show();
    //   }
    // });

    // this.cs.previewShape.containerForChildren.removeChild(
    //   ...this.toShapes.map(shape => shape.mainContainer),
    // );
    // container.alpha = 0;
    // container.visible = false;
    // container.addChild(...this.toShapes.map(shape => shape.mainContainer));

    // this.cs.previewShape.container.addChild(container);

    const toScene = this.shape.scenes.find(({ name }) => name == this.to);

    if (toScene.position && (transform || all)) {
      const { x, y } = toScene.position;
      // -- // -- // -- //

      // console.log('x-increment', { x, y });
      // this.xIncrement = new IncrementController(
      //   0,
      //   x || 0,
      //   this.duration * this.cs.batchPerSecond,
      //   Easing.LINEAR,
      // );
      // this.yIncrement = new IncrementController(
      //   0,
      //   y || 0,
      //   this.duration * this.cs.batchPerSecond,
      //   Easing.LINEAR,
      // );
    }

    // -- // this.toShapes.map(shape => shape._show()); // -- //
    this.currentIncrement = 0;

    // if (this.shape.descriptor.backgroundColorByScene) {
    //   const bgColor = this.shape.descriptor.backgroundColor;
    //   const bgByScene = this.shape.descriptor.backgroundColorByScene;
    //   const [fromColor, toColor] = [
    //     this.from == 'main'
    //       ? bgByScene[this.from] || bgColor
    //       : bgByScene[this.from],
    //     bgByScene[this.to],
    //   ];

    //   if (fromColor && toColor && (backgroundColor || all)) {
    //     this.backgroundChangeController = new ColorIncrementController(
    //       this.shapeService.resolveColor(fromColor),
    //       this.shapeService.resolveColor(toColor),
    //       numberOfBatches,
    //     );
    //   }
    // }

    this.opacityController1 = new IncrementController(
      1,
      0,
      this.halfTime,
      Easing.LINEAR,
    );
    this.opacityController2 = new IncrementController(
      0,
      1,
      this.halfTime,
      Easing.LINEAR,
    );

    await new Promise<void>(resolve => {
      this.service.animationFrames[this.id] = increment => {
        this.currentIncrement += increment;
        let end = false;

        if (this.xIncrement) {
          this.cs.canvasContainer.setTransform(
            this.xIncrement.increment(increment),
            this.yIncrement.increment(increment),
          );

          // this.shapeService.previewShape.applyShapeTranslate({
          //   x: this.xIncrement.increment(increment),
          //   y: this.yIncrement.increment(increment),
          // });
        }

        if (this.backgroundChangeController) {
          this.cs.app.renderer.background.color =
            this.backgroundChangeController.increment(increment);
        }

        if (this.currentIncrement >= numberOfBatches) {
          end = true;
          // Occasional overflow is eliminated //
          increment -= this.currentIncrement - numberOfBatches;
        }

        if (this.currentIncrement < this.halfTime) {
          const opacity = this.opacityController1.increment(increment);
          this.fromShapes.map(shape => shape.updateOpacity(opacity));
        } else {
          const opacity = this.opacityController2.increment(increment);
          this.toShapes.map(shape => {
            shape.container.alpha = opacity;
            // shape.updateOpacity(opacity)
          });
          // container.alpha = opacity;
          if (!this.secondStarted) {
            // if (!this.xIncrement) {
            //   console.warn('--------- !xIncrement - fakeNews ------------');
            this.shapeService.previewShape.applyPosition({
              x: 0,
              y: 0,
              scale: {
                x: this.cs.scaleOffset,
                y: this.cs.scaleOffset,
              },
            });
            // }
            this.secondStarted = true;
            this.fromShapes.map(shape => shape._hide());
            // container.visible = true;
            // this.toShapes.map(shape => shape._show());
          }
        }

        if (end) {
          delete this.service.animationFrames[this.id];
          this.toShapes.map(shape => {
            shape.container.alpha = 1;
          });
          resolve();
        }
      };
    });
    await toShape?.animationFrame.animate();
  }

  getPosition(scenes: Scene[], mainScene: string, subScene: string) {
    const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
    const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    return scenes[mainSceneIndex].subscenes[subSceneIndex].position;
  }
}
