const { sin, PI } = Math;

export enum Easing {
  OVERFLOW = 'overflow',
  SMOOTH = 'smooth',
  LINEAR = 'linear',
}

export class SineIncrementController {
  currentIncrement = 0;

  valueMap: Record<number, number> = {};

  time = performance.now();

  constructor(
    public start: number,
    public end: number,
    private maxIncrement: number,
  ) {
    for (let i = 0; i <= maxIncrement; i++) {
      const t = this.getValue(i / this.maxIncrement);
      // console.log('t', i, t);
      this.valueMap[i] = this.start + t * (this.end - this.start);
    }
  }

  getValue(t: number) {
    return sin(t * 2 * PI);
  }

  increment(increment: number) {
    this.currentIncrement += increment;
    if (this.currentIncrement > this.maxIncrement) {
      // const now = performance.now();
      // console.log('time-till one period', (now - this.time) / 1000);
      // this.time = now;
      this.currentIncrement = this.currentIncrement - this.maxIncrement;
    }
    return this.valueMap[this.currentIncrement];
  }
}
