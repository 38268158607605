import { Injectable } from '@angular/core';
import { HttpService } from '../store/http/http.service';
import { RequestType } from '../store/store.service';
import { map } from 'rxjs/operators';
import { User } from './user.interface';
import { Observable } from 'rxjs';
import { IdToIRI, IRIToId } from '../util/iri';
import { RoleTypes } from '../organisation/role.interface';

@Injectable()
export class UserService {
  createUser(
    user: Partial<User>,
    roleType: RoleTypes,
    organisationId: string,
  ): Observable<User> {
    return this.http
      .requestCall({
        path: 'user/user',
        type: RequestType.POST,
        body: {
          ...user,
          organisation: {
            IRI: IdToIRI(organisationId),
          },
          role: {
            type: roleType,
          },
        },
      })
      .pipe(map(user => this.mapResourceToUser(user)));
  }

  listUsers(): Observable<User[]> {
    return this.http
      .requestCall({ path: 'user-crud/user', type: RequestType.GET })
      .pipe(map(users => users.map(user => this.mapResourceToUser(user))));
  }

  loadUserById(userId: string): Observable<User> {
    return this.http
      .requestCall({
        path: `/user-crud/user/${userId}`,
        type: RequestType.GET,
      })
      .pipe(map(user => this.mapResourceToUser(user)));
  }

  deleteUser(user: User, organisationId: string): Observable<User> {
    return this.http.requestCall({
      path: `user/organisation/${user.id}/${organisationId}`,
      type: RequestType.DELETE,
    });
  }

  mapResourceToUser({ IRI, literals }: any): User {
    return {
      id: IRIToId(IRI),
      firstName: literals.firstName,
      lastName: literals.lastName,
      email: literals.email,
    };
  }

  constructor(private readonly http: HttpService) {}
}
