import { Graphics } from 'pixi.js';
import { SVG } from './svg-element';

export class Ellipse extends SVG<{
  x: number;
  y: number;
  rx: number;
  ry: number;
  startAngle?: number;
  endAngle?: number;
}> {
  get rx() {
    return this.config.rx;
  }

  get ry() {
    return this.config.ry;
  }

  get startAngle() {
    return this.config.startAngle;
  }

  get endAngle() {
    return this.config.endAngle;
  }
  initElement() {
    this._element = new Graphics();
  }

  updateAttr() {
    super.updateAttr();

    if (this.startAngle !== undefined || this.endAngle !== undefined) {
      this.element.arc(
        this.x,
        this.y,
        this.rx,
        this.startAngle || 0,
        this.endAngle || 0,
      );
    } else {
      this.element.drawEllipse(this.rx, this.ry, this.rx, this.ry).endFill();
    }
  }
}
