import { NgModule } from '@angular/core';
import { UtilComponentModule } from '../components/util/util-component.module';
import { ImageProcessingPanelComponent } from './image-processing-panel/image-processing-panel.component';
import { ImageService } from './image.service';
import { ImageControlPanelComponent } from './image-control-panel/image-control-panel.component';
import { RegionService } from './region/region.service';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ImageModeSelectorComponent } from './image-mode-selector/image-mode-selector.component';

@NgModule({
  imports: [UtilComponentModule, MatTooltipModule],
  providers: [ImageService, RegionService],
  declarations: [
    ImageProcessingPanelComponent,
    ImageControlPanelComponent,
    ImageModeSelectorComponent,
  ],
  exports: [
    ImageProcessingPanelComponent,
    ImageControlPanelComponent,
    ImageModeSelectorComponent,
  ],
})
export class ImageModule {}
