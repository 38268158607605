import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';
import { StoreService } from '../../store/store.service';
import { ImageService, SUBREGIONS_STORE_PATH } from '../image.service';
import { ResolveData } from '@angular/router';
import {
  ImageDescriptor,
  ImageShapeDescriptor,
} from '../../elements/resource/types/shape.type';
import { ResourceData } from '../../elements/resource/resource.types';
import { Observable } from 'rxjs';

@Component({
  selector: 'nw-image-processing-panel',
  templateUrl: './image-processing-panel.component.html',
  styleUrls: ['./image-processing-panel.component.scss'],
})
export class ImageProcessingPanelComponent implements OnInit {
  items$: Observable<any[]>;
  isLoading$: Observable<any[]>;
  subregionError$: Observable<any[]>;
  imageIsLoading$: Observable<any[]>;

  constructor(
    private readonly cs: CanvasService,
    private readonly db: StoreService,
    public readonly service: ImageService
  ) {
    this.items$ = this.db.get<any[]>(SUBREGIONS_STORE_PATH);
    this.isLoading$ = this.db.get<any[]>(SUBREGIONS_STORE_PATH + '-loading');
    this.subregionError$ = this.db.get<any[]>(SUBREGIONS_STORE_PATH + '-error');
    this.imageIsLoading$ = this.db.get<any[]>('image-is-loading');
  }

  get show() {
    return this.service.show;
  }

  startProcessing() {
    this.service.startProcessing();
  }

  get descriptor() {
    return (this.cs.currentResourceData as ResourceData<ImageShapeDescriptor>)
      ?.literals.descriptor;
  }

  get startState() {
    return !this.descriptor?.imageProcessStarted;
  }

  get inProgressState() {
    return (
      this.descriptor?.imageProcessStarted &&
      !this.descriptor.imageProcessFinished
    );
  }

  get finishedState() {
    return this.descriptor?.imageProcessFinished;
  }

  async ngOnInit(): Promise<void> {
    // console.log('loading-subimages');
    // this.http
    //   .get('editor/subregion', '_', {
    //     resourceData: {
    //       image: {
    //         IRI: this.cs.currentIRI,
    //       },
    //     },
    //   })
    //   .subscribe(value => {
    //     console.log('subImages', value);
    //   });
    // -- // -- // -- // -- // -- //
  }
}
