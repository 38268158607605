import { createSelector } from '@ngrx/store';
import { DataBag } from '../store/reducer';
import { ProjectFeature } from './project.actions';
import { Project } from './project.interface';
import { ProjectState } from './project.reducer';
import { myUserId } from '../user/user.selector';
import { RoleTypes } from '../organisation/role.interface';
import { ResourceData } from '../elements/resource/resource.types';
import { selectOrganisationFeature } from '../organisation/organisation.selector';

export const selectProjectFeature = (state: DataBag) => state[ProjectFeature];

export const projectList = createSelector(
  selectProjectFeature,
  ({ allProjects }): Project[] => Object.values(allProjects),
);

export const projectListLoading = createSelector(
  selectProjectFeature,
  ({ listLoading }): boolean => listLoading,
);

export const newProjectLoading = createSelector(
  selectProjectFeature,
  ({ newProjectLoading }): boolean => newProjectLoading,
);

export const selectProjectIsLoading = createSelector(
  selectProjectFeature,
  ({ projectIsLoading }): boolean => projectIsLoading,
);

export const isNewFileLoading = createSelector(
  selectProjectFeature,
  ({ newFileLoading }): boolean => newFileLoading,
);

export const selectMyScenes = (IRI: string) =>
  createSelector(selectProjectFeature, ({ files }) =>
    Object.values(files).filter(
      file => (file.relationships?.childOf as ResourceData)?.IRI == IRI,
    ),
  );

export const projectById = (id: string) =>
  createSelector(
    selectProjectFeature,
    ({ allProjects }: ProjectState): Project => {
      console.log(allProjects, id);
      return allProjects[id];
    },
  );

export const projectByOrganisationId = (id: string) =>
  createSelector(
    selectProjectFeature,
    ({ allProjects, newlyCreatedProjects }): Project[] =>
      Object.values(allProjects)
        .filter(project => project.organisationId === id)
        .sort((p1, p2) => (p1.name < p2.name ? -1 : 1))
        .sort(
          (p1, p2) =>
            (newlyCreatedProjects[p2.id] || 0) -
            (newlyCreatedProjects[p1.id] || 0),
        ),
  );

export const isDeletePendingById = (id: string) =>
  createSelector(
    selectProjectFeature,
    ({ deleteIsPending }): boolean => deleteIsPending == id,
  );

export const isDeletePending = createSelector(
  selectProjectFeature,
  ({ deleteIsPending }): string => deleteIsPending,
);

export const myRoleByProjectId = (id: string) =>
  createSelector(projectById(id), myUserId, (project, myUserId): RoleTypes => {
    console.log({
      project,
      myUserId,
    });
    return project?.roles[myUserId].type;
  });

export const getCurrentColorPalette = createSelector(
  selectProjectFeature,
  ({ currentProjectID, allProjects }) =>
    allProjects[currentProjectID]?.colorPalette || {},
);

export const getCurrentProjectField = <K extends keyof Project>(key: K) =>
  createSelector(
    selectProjectFeature,
    ({ currentProjectID, allProjects }) =>
      allProjects[currentProjectID]?.[key] as Project[K],
  );

export const getCurrentLanguage = createSelector(
  selectProjectFeature,
  ({ currentLanguage }) => currentLanguage,
);

export const getCurrentProjectLanguages = createSelector(
  selectProjectFeature,
  ({ currentProjectID, allProjects }) =>
    allProjects[currentProjectID]?.languages || [],
);

export const getCurrentProject = createSelector(
  selectProjectFeature,
  ({ currentProjectID, allProjects }) => allProjects[currentProjectID],
);

export const files = createSelector(
  selectProjectFeature,
  ({ currentProjectID, allProjects }) => {
    console.log(
      '---files-selector---',
      currentProjectID,
      allProjects[currentProjectID],
    );
    return allProjects[currentProjectID]?.files;
  },
);

export const selectFileList = createSelector(
  selectProjectFeature,
  ({ files }) => Object.values(files || {}),
);
export const selectFiles = createSelector(
  selectProjectFeature,
  ({ files }) => files,
);

export const getCurrentProjectID = createSelector(
  selectProjectFeature,
  ({ currentProjectID }) => currentProjectID,
);

export const getProjectColorPalette = createSelector(
  selectProjectFeature,
  ({ currentProjectID, allProjects }) => {
    return Object.entries(allProjects[currentProjectID].colorPalette || {}).map(
      ([label, color]) => ({ label, color }),
    );
  },
);

export const getCombinedColorPalette = createSelector(
  selectProjectFeature,
  selectOrganisationFeature,
  (
    { currentProjectID, allProjects },
    { allOrganisations, currentOrganisationID },
  ) => {
    const object = {
      ...(allOrganisations[currentOrganisationID]?.colorPalette || {}),
      ...(allProjects[currentProjectID]?.colorPalette || {}),
    };

    return Object.entries(object).map(([label, color]) => ({ label, color }));
  },
);
export const getCombinedColorPaletteObject = createSelector(
  selectProjectFeature,
  selectOrganisationFeature,
  (
    { currentProjectID, allProjects },
    { allOrganisations, currentOrganisationID },
  ) => {
    return {
      ...(allOrganisations[currentOrganisationID]?.colorPalette || {}),
      ...(allProjects[currentProjectID]?.colorPalette || {}),
    };
  },
);
