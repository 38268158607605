<div class="dropdown-outer-container">
  <div
    class="dropdown-inner-container"
    [style.top]="topValue"
    [style.left]="leftValue"
    [style.right]="rightValue"
  >
    <div
      class="dropdown-element-container"
      fxLayout="column"
      [fxLayoutGap]="gapValue"
    >
      <ng-container *ngFor="let item of data">
        <div
          class="item-container"
          fxLayout="row"
          fxLayoutAlign="space-around center"
          [style.height.px]="height"
          [style.width.px]="width"
          (click)="cs.consumeClick(); clicked?.emit(item)"
          (mouseenter)="hoverInHandler(item)"
          (mouseleave)="hoverOutHandler(item)"
        >
          <ng-template
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
