import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CanvasService } from './services/canvas/canvas.service';

@UntilDestroy()
@Component({
  selector: 'svg-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  @HostListener('contextmenu')
  preventContextMenu() {
    return false;
  }

  session: CognitoUserSession;

  isAuthenticated: boolean;
  showHeader = false;

  constructor(
    private authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cs: CanvasService,
  ) {
    this.authenticationService.session
      .pipe(untilDestroyed(this))
      .subscribe(session => (this.session = session));

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let route = this.activatedRoute.snapshot;

        while (route.firstChild) {
          route = route.firstChild;
        }

        const { hideHeader } = route?.data ?? { hideHeader: false };

        // this.showHeader = !hideHeader;
      });
  }

  @HostListener('click', ['$event'])
  async click(e: MouseEvent) {
    this.cs.generalEventEmit('globalClick', Math.random());
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: Event) {
    console.log('scroll', event);
  }

  signOut() {
    return this.authenticationService.signOut();
  }
}
