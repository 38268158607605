import { GeneralShapeDescriptor } from '../../../../elements/resource/types/shape.type';
import { setDescriptorValue } from '../../../store/editor.actions';
import { GeneralShape } from '../general/general-shape';

export class ContainerShape<
  T extends GeneralShapeDescriptor = GeneralShapeDescriptor,
> extends GeneralShape<T> {
  afterInit(): void {
    super.afterInit();
    this.setChildIndexes();
  }

  resetBaseState(): void {
    super.resetBaseState();
    this.shapes.map(shape => shape.resetBaseState());
  }

  setPreAnimationState() {
    // TODO - check if it is the right thing
    this.shapes.map(shape => shape.setPreAnimationState());
    return super.setPreAnimationState();
  }

  setChildIndexes() {
    this.shapesByIndex.map((shape, index) => {
      if (index > 0) {
        const prevShape = this.shapes[index - 1];
        shape.prev = prevShape;
        prevShape.next = shape;
      }
      shape.childIndex = index;
    });
  }

  moveUp(shape: GeneralShape) {
    if (this._shapes.length == 1) {
      return;
    }

    const canvasIndex = shape.canvasIndex;

    if (canvasIndex == 0) {
      return;
    }

    this._shapes = [
      ...this._shapes.slice(0, canvasIndex - 1),
      shape,
      this._shapes[canvasIndex - 1],
      ...this._shapes.slice(canvasIndex + 1),
    ].filter(shape => !!shape);
    shape.canvasIndex--;

    this._shapes.map((shape, canvasIndex) => (shape.canvasIndex = canvasIndex));

    const prev = this._shapes[shape.canvasIndex - 1];
    const next = this._shapes[shape.canvasIndex + 1];

    if (next && prev) {
      shape.index = (prev.index + next.index) / 2;
    } else if (prev) {
      shape.index = prev.index + 1;
    } else if (next) {
      shape.index = next.index - 1;
    }

    this.saveShapeIndex(shape);
    this.readdChildreContainer();
  }

  moveDown(shape: GeneralShape) {
    if (this._shapes.length == 1) {
      return;
    }

    const canvasIndex = shape.canvasIndex;

    if (canvasIndex == this._shapes.length - 1) {
      return;
    }

    this._shapes = [
      ...this._shapes.slice(0, canvasIndex),
      this._shapes[canvasIndex + 1],
      shape,
      ...this._shapes.slice(canvasIndex + 2),
    ];
    shape.canvasIndex++;

    this._shapes.map((shape, canvasIndex) => (shape.canvasIndex = canvasIndex));

    const prev = this._shapes[shape.canvasIndex - 1];
    const next = this._shapes[shape.canvasIndex + 1];

    if (next && prev) {
      shape.index = (prev.index + next.index) / 2;
    } else if (prev) {
      shape.index = prev.index + 1;
    } else if (next) {
      shape.index = next.index - 1;
    }

    this.saveShapeIndex(shape);
    this.readdChildreContainer();
  }

  saveShapeIndex(shape: GeneralShape) {
    this.store.dispatch(
      setDescriptorValue({
        IRI: shape.IRI,
        key: 'index',
        value: shape.index,
      }),
    );
  }

  readdChildreContainer() {
    this.mainContainer.removeChildren();
    this.mainContainer.addChild(
      ...this._shapes.map(shape => shape.mainContainer),
    );
  }

  moveUpwards(shape: GeneralShape) {
    // -- // -- // -- // -- //
    const indexes = this.shapeIndexes;
    this.fixUndefinedIndexes(indexes);

    const lastIndex = indexes[indexes.length - 1];
    this.setNewIndex(shape, lastIndex + 1);
  }

  setNewIndex(shape: GeneralShape, index: number) {
    console.log('set-new-index', index);
    try {
      shape.mainContainer.removeFromParent();
      // this.containerForChildren.removeChildAt(shape.childIndex);
    } catch (error) {
      console.warn('child-could not be removed', error.message);
    }

    shape.index = index;
    this.setChildIndexes();

    shape.save();
    console.log('up > add at', shape.childIndex);
    this.containerForChildren.addChildAt(shape.mainContainer, shape.childIndex);
  }

  moveDownwards(shape: GeneralShape) {
    console.log('move-downwards');
    return;
    const indexes = this.shapeIndexes;
    this.fixUndefinedIndexes(indexes);

    if (indexes.includes(null)) {
      for (const shape of this.shapes) {
        if (shape.index == null) {
          shape.index = 0;
          shape.save();
        }
      }
    }

    const firstIndex = indexes[0];
    this.containerForChildren.removeChildAt(shape.childIndex);

    if (firstIndex <= 0) {
      shape.index = firstIndex - 1;
    } else {
      shape.index = firstIndex / 2;
    }

    this.setChildIndexes();

    shape.save();
    this.containerForChildren.addChildAt(shape.mainContainer, 0);

    this._shapes = this._shapes.sort((c1, c2) => c1.index - c2.index);
  }

  fixUndefinedIndexes(indexes: number[]) {
    if (indexes.includes(null)) {
      for (const shape of this.shapes) {
        if (shape.index == null || shape.index == 0) {
          shape.index = 0;
          shape.save();
        }
      }
    }
  }
}
