export interface Type {
  key: string;
  label: string;
  literals?: Record<string, Primitive>;
  relationships?: Relationship[];
}

export type TypeId = string;

export enum Primitive {
  Number = "number",
  String = "string",
  Boolean = "boolean",
}

export interface Relationship {
  key: string;
  typeId: TypeId;
  existing?: boolean;
  cardinality?: {
    max?: number;
    min?: number;
    exact?: number;
  };
}
