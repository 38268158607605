<div class="if-attribute-component" *ngIf="panelState$ | async as panelState">
  <div *ngIf="panelState.type == 'show'" class="container flex-column gap-4">
    <div class="header">
      <nw-icon type="filter_alt" [size]="14" [outlined]="true"></nw-icon>
    </div>

    <div class="all-container">
      <nw-boolean-input
        key="all"
        [value]="panelState.all?.length ? panelState.all : [false]"
        (changed)="sceneValueChange('_all_', $event)"
      ></nw-boolean-input>
    </div>

    <div
      class="input-container flex-column gap-4"
      *ngFor="let scene of panelState.scenes"
    >
      <nw-boolean-input
        [key]="scene.key"
        [value]="scene.values"
        (changed)="sceneValueChange(scene.key, $event)"
      ></nw-boolean-input>
    </div>
  </div>

  <!-- *ngIf="(attributeState$ | async).mode == 'base'" -->
  <!-- <nw-general-attribute-header
    icon="north_east"
    [noDelete]="true"
    [attributeState]="attributeState"
    key="translate"
    [initialValue]="{ x: 100, y: 100, relative: true }"
  ></nw-general-attribute-header>
 -->

  <!-- <div
    [ngSwitch]="attributeState.state"
    class="value-section"
    [ngClass]="{
      'value-section-disabled': !!(attributeState$ | async).selectedFunctions
        ?.length,
    }"
  >
    <div *ngSwitchCase="'defined'" class="attr-container flex-column gap-4">
      <ng-container
        *ngIf="
          !attributeState.functions || attributeState.functions.length == 1
        "
      >
        <nw-number-input
          [key]="'x'"
          [value]="attributeState.value['x']"
          (changed)="updateSubAttributeValue('x', $event)"
        ></nw-number-input>
        <nw-number-input
          [key]="'y'"
          [value]="attributeState.value['y']"
          (changed)="updateSubAttributeValue('y', $event)"
        ></nw-number-input>
        <nw-boolean-input
          *ngIf="
            attributeState.value['relative']?.length ||
            attributeState.type == 'animation'
          "
          [key]="'relative'"
          [value]="attributeState.value['relative'] || [false]"
          (changed)="updateSubAttributeValue('relative', $event)"
        ></nw-boolean-input>
      </ng-container>
    </div>
  </div> -->
</div>
