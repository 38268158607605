<div class="time-display-component">
  <div
    *ngIf="currentTimeTexts as timeTexts"
    class="flex-row"
    style="font-size: 12px"
  >
    <div class="digi-digi" style="width: 18px; text-align: center">
      {{ timeTexts.min }}
    </div>
    <div>:</div>
    <div class="digi-digi" style="width: 18px; text-align: center">
      {{ timeTexts.sec }}
    </div>
    <div>:</div>
    <div class="time-milisec-x no-wrap">
      {{ timeTexts.milisec }}
    </div>
  </div>
</div>
