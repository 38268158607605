<div class="image-is-loading-cont" *ngIf="imageIsLoading$ | async">
  <div class="middle-aux">
    <div
      class="image-is-loading"
      fxLayout="row"
      fxLayoutAlign=" center"
      fxLayoutGap="12px"
    >
      <div>Image is being loaded, please wait...</div>
      <div><nw-loader [size]="20"></nw-loader></div>
    </div>
  </div>
</div>

<div class="image-processing-panel" *ngIf="show">
  <div>Image processing panel</div>

  <div
    *ngIf="startState"
    fxLayout="row"
    fxLayoutAlign=" center"
    fxLayoutGap="6px"
    (click)="startProcessing()"
  >
    <div class="text">Start image processing</div>
    <nw-icon type="play_circle" color="grey"></nw-icon>
  </div>

  <div
    *ngIf="inProgressState"
    fxLayout="row"
    fxLayoutAlign=" center"
    fxLayoutGap="6px"
  >
    <div class="text">Processing is in progress</div>
    <nw-icon type="warning" color="yellow"></nw-icon>
  </div>

  <div
    *ngIf="finishedState"
    fxLayout="row"
    fxLayoutAlign=" center"
    fxLayoutGap="6px"
  >
    <div class="text">Processing is complete</div>
    <nw-icon type="check_circle" color="green"></nw-icon>
  </div>

  <div class="subregions">
    <div
      *ngFor="let item of items$ | async"
      fxLayout="row"
      fxLayoutGap=" 12px"
      fxLayoutAlign=" center"
    >
      <div (click)="service.openSubregion(item)" class="pointer">
        {{ item.literals.intervals }}
      </div>
      <div>
        <nw-icon type="close" (click)="service.deleteSubregion(item)"></nw-icon>
      </div>
    </div>
  </div>

  <!-- <div (click)="startProcessing()">start-processing</div>
  <div (click)="openSubImage()">open-subimage</div>
  <div (click)="addSubImage()">add-subimage</div> -->
  <div>Current interval</div>
  <div>{{ service.currentIntervals }}</div>
  <div (click)="service.addSubImage()">Add interval</div>

  <div *ngIf="isLoading$ | async">
    ------------------ Loading -----------------
  </div>
  <div *ngIf="subregionError$ | async">
    ------------------ Error happened -----------------
  </div>
</div>
