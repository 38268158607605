<ng-container *ngIf="!recordMode">
  <nw-right-click-menu></nw-right-click-menu>
  <nw-spacing-controller
    *ngIf="shapeService.showVerticalEvenSpacing"
  ></nw-spacing-controller>
  <nw-spacing-controller
    *ngIf="shapeService.showHorizontalEvenSpacing"
    [isRow]="true"
  ></nw-spacing-controller>
</ng-container>

<div class="canvas-component">
  <iw-scene-controller
    *ngIf="!recordMode && cs.showAnimationPanel"
  ></iw-scene-controller>
  <div fxLayout="column" class="height-100">
    <!-- 
  <div
    *ngIf="cs.notification"
    class="notification"
    fxLayout="column"
    fxLayoutAlign=" center"
  >
    <div class="cdk-content">{{ cs.notification }}</div>
  </div> 
  -->

    <div
      class="canvas-container"
      [class.fix-canvas]="fixCanvas"
      #container
      appDnd
      (fileDropped)="onFileDropped($event)"
      (hover)="hover($event)"
    >
      <div #canvas id="_canvas" class="w-100 h-100"></div>
    </div>
    <!-- [style.width.px]="1420"
      [style.height.px]="810" -->

    <ng-container *ngIf="!recordMode">
      <nw-component-search
        *ngIf="showComponentSearch$ | async"
      ></nw-component-search>
      <nw-image-processing-panel></nw-image-processing-panel>
    </ng-container>
  </div>

  <ng-container *ngIf="!recordMode">
    <nw-general-selector *ngIf="cs.generalSelectorConfig"></nw-general-selector>
  </ng-container>
</div>
