import { Action } from '@ngrx/store';
import { DatabagActionDescriptor } from './reducer';
import * as uuidv1 from 'uuid';

export enum SVGAppActionTypes {
  GetImages = 'getImages',
  General = 'general',
  Store = 'store',
}

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface BackEndRequest {
  type: RequestType;
  path?: string;
  params?: Record<string, any>;
  body?: any;
  token?: string;

  headers?: Record<string, any>;
}

export interface GeneralActionConfig {
  id?: string;
  request: BackEndRequest;
  descriptor?: Partial<DatabagActionDescriptor>;
}

export interface GeneralAction extends Action {
  id?: string;
  descriptor: Partial<DatabagActionDescriptor>;
  request: BackEndRequest;
}

export const getGeneralAction = (
  request: BackEndRequest,
  descriptor: Partial<DatabagActionDescriptor> | string,
): GeneralAction => ({
  id: uuidv1(),
  type: SVGAppActionTypes.General,
  request,
  descriptor:
    typeof descriptor === 'string'
      ? { path: descriptor, value: '${data}' }
      : descriptor,
});
