<div
  class="state-controller-component flex-row gap-4"
  *ngIf="sceneState$ | async as sceneState"
>
  <div *ngFor="let scene of sceneState.scenes" class="state-container">
    <div
      class="state-item"
      [class.selected]="isSceneSelected(scene)"
      (click)="selectScene(scene)"
    >
      <div *ngIf="!isSceneEdited(scene); else editSceneName" class="label">
        {{ scene.name }}
      </div>
      <ng-template #editSceneName>
        <iw-text-edit
          [text]="scene.name"
          (save)="saveSceneName($event)"
          (discard)="discardEdit()"
        ></iw-text-edit>
      </ng-template>
      <div
        *ngIf="isHoverMode == scene.name && !isSceneEdited(scene)"
        class="flex-row gap-4"
      >
        <nw-icon type="edit" [size]="13" (click)="editScene(scene)"></nw-icon>
        <nw-icon
          type="close"
          [size]="13"
          (click)="removeScene(scene)"
        ></nw-icon>
      </div>
    </div>
    <div class="substate-row">
      <div
        class="state-item"
        *ngFor="let subScene of scene.subscenes"
        [class.selected]="isSubSceneSelected(subScene)"
        (click)="selectSubScene(scene, subScene)"
      >
        <div *ngIf="!isSubSceneEdited(scene, subScene); else subSceneEdit">
          {{ subScene.name }}
        </div>
        <ng-template #subSceneEdit>
          <iw-text-edit
            [text]="subScene.name"
            (save)="saveSubScene(scene, subScene, $event)"
          >
          </iw-text-edit>
        </ng-template>
      </div>
      <ng-container *ngIf="isSceneSelected(scene)">
        <div
          *ngIf="!addNewSubSceneMode"
          class="state-item icon"
          (click)="addNewSubScene(scene)"
        >
          <nw-icon type="add" [size]="12"></nw-icon>
        </div>
        <div *ngIf="addNewSubSceneMode" class="flex-row gap-4">
          <iw-text-edit
            (discard)="addNewSubSceneMode = false"
            (save)="saveNewSubScene(scene, $event)"
          ></iw-text-edit>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!addNewMode" class="state-item icon">
    <nw-icon type="add" [size]="12" (click)="addNewScene()"></nw-icon>
  </div>
  <div *ngIf="addNewMode" class="flex-row gap-4">
    <iw-text-edit (save)="saveNewScene($event)" (discard)="closeNewSceneEdit()">
    </iw-text-edit>
  </div>
</div>
