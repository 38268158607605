import { CurveSectionDescriptor } from '../../../../../../elements/resource/types/shape.type';
import { pick as _pick } from 'lodash';
import { CurveItem } from '../../path-shape.types';
import {
  Easing,
  IncrementController,
} from '../../../../../animation/frame/increment/controller/increment.controller';

export class PathSectionStepper {
  bx: number;
  by: number;

  cx: number;
  cy: number;

  x: number;
  y: number;

  isCurve: boolean;

  xIncrement: IncrementController;
  yIncrement: IncrementController;

  a1xIncrement: IncrementController;
  a1yIncrement: IncrementController;

  a2xIncrement: IncrementController;
  a2yIncrement: IncrementController;

  constructor(
    d1: CurveSectionDescriptor,
    d2: CurveSectionDescriptor,
    division: number,
    ease: Easing,
  ) {
    const { x, y } = d1;
    const { x: xt, y: yt } = d2;

    this.xIncrement = new IncrementController(x, xt, division, ease);
    this.yIncrement = new IncrementController(y, yt, division, ease);

    if (d1.type == 'curve') {
      this.isCurve = true;

      const [a11x, a11y] = d1.a1;
      const [a12x, a12y] = d2.a1;

      this.a1xIncrement = new IncrementController(a11x, a12x, division, ease);
      this.a1yIncrement = new IncrementController(a11y, a12y, division, ease);

      const [a21x, a21y] = d1.a2;
      const [a22x, a22y] = d2.a2;

      this.a2xIncrement = new IncrementController(a21x, a22x, division, ease);
      this.a2yIncrement = new IncrementController(a21y, a22y, division, ease);
    }
  }

  increment(number = 1) {
    // console.log('increment', number);
    this.x = this.xIncrement.increment(number);
    this.y = this.yIncrement.increment(number);

    if (this.isCurve) {
      this.bx = this.a1xIncrement.increment(number);
      this.by = this.a1yIncrement.increment(number);

      this.cx = this.a2xIncrement.increment(number);
      this.cy = this.a2yIncrement.increment(number);
    }

    // console.log('increment', { number, cx: this.cx, cy: this.cy });
  }

  getPathItem(): CurveItem {
    if (this.isCurve) {
      return {
        type: 'curve',
        x: this.x,
        y: this.y,
        bx: this.bx,
        by: this.by,
        cx: this.cx,
        cy: this.cy,
      };
    } else {
      return {
        type: 'line',
        x: this.x,
        y: this.y,
      };
    }
  }
}
