import { Component, Input } from '@angular/core';
import { User, UserWithRole } from '../../user/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  myUserId,
  newUserIsLoading,
  selectUserBeingDeleted,
  selectUserBeingEdited,
  userList,
  userListLoading,
} from '../../user/user.selector';
import {
  createUser,
  deleteUser,
  setNewUserLoading,
  setUserBeingDeleted,
} from '../../user/user.actions';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import {
  currentOrganisationID,
  myRoleByOrganisationId,
  userListByOrganisationId,
} from '../../organisation/organisation.selector';
import { RoleTypes } from '../../organisation/role.interface';

const rolePriority: Record<RoleTypes, number> = {
  owner: 1,
  admin: 2,
  editor: 3,
  viewer: 4,
};

@Component({
  selector: 'nw-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
})
export class UserSelectorComponent {
  @Input() set organisationId(id: string) {
    // this.organisationId$.next(id);
  }

  @Input() editable = false;

  // private readonly organisationId$ = new BehaviorSubject<string>(undefined);
  private readonly organisationId$: Observable<string>;

  readonly myOrganisationRole$: Observable<RoleTypes>;

  myOrganisationRole: RoleTypes;

  readonly users$: Observable<(User | UserWithRole)[]>;
  readonly allUser$: Observable<User[]>;
  readonly loading$: Observable<boolean>;
  readonly newUserIsLoading$: Observable<boolean>;

  newUserPanel = false;
  newUser = this.getEmptyUser();
  selectedUser: User;

  currentEditIconSize = 17;
  currentDeleteIconSize = 17;

  deleteMode: User;
  userBeingEdited: string;
  userBeingDeleted: string;
  userBeingEdited$: Observable<string>;
  userBeingDeleted$: Observable<string>;

  selectUser(user: User) {
    if (this.deleteMode) {
      return;
    }
    this.selectedUser = user;
  }

  getModeByUser(user: User) {
    if (this.deleteMode == user) {
      return 'delete-confirm';
    }

    if (this.userBeingDeleted == user.id) {
      return 'user-being-deleted';
    }

    if (this.selectedUser == user) {
      return 'selected';
    }

    return 'idle';
  }

  deleteUser(user: User) {
    this.deleteMode = null;
    this.store.dispatch(deleteUser({ user }));
    this.store.dispatch(setUserBeingDeleted({ user }));
  }

  isDeleteEnabled(user: User | UserWithRole) {
    // -- //
    if (!['owner', 'admin'].includes(this.myOrganisationRole)) {
      return false;
    }

    if (user.id == this.myId) {
      return false;
    }

    // console.log('is-delete-enabled', {
    //   myOrgRole: this.myOrganisationRole,
    //   userRole: (user as UserWithRole).role.type,
    //   p1: rolePriority[this.myOrganisationRole],
    //   p2: rolePriority[(user as UserWithRole).role.type],
    // });
    return (
      rolePriority[this.myOrganisationRole] <
      rolePriority[(user as UserWithRole).role.type]
    );
  }

  startDeleteMode(user: User) {
    this.deleteMode = user;
  }

  deselectUser() {
    if (this.deleteMode) {
      return;
    }
    this.selectedUser = null;
  }

  myId: string;

  constructor(private readonly store: Store) {
    this.organisationId$ = this.store.select(currentOrganisationID);

    this.users$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      mergeMap(id =>
        this.store.select(id ? userListByOrganisationId(id) : userList),
      ),
    );
    this.loading$ = this.store.select(userListLoading);
    this.allUser$ = this.store.select(userList);

    this.newUserIsLoading$ = this.store.select(newUserIsLoading);

    this.myOrganisationRole$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      mergeMap(id => this.store.select(myRoleByOrganisationId(id))),
    );

    this.store.select(myUserId).subscribe(val => {
      this.myId = val;
    });

    this.myOrganisationRole$.subscribe(val => {
      this.myOrganisationRole = val;
    });

    this.userBeingDeleted$ = this.store.select(selectUserBeingDeleted);
    this.userBeingDeleted$.subscribe(val => (this.userBeingDeleted = val));
    this.userBeingEdited$ = this.store.select(selectUserBeingEdited);
    this.userBeingEdited$.subscribe(val => (this.userBeingEdited = val));
  }

  addUser() {
    this.newUserPanel = true;
    // this.store.dispatch(loadUsers());
  }

  selectedRole: RoleTypes = 'admin';
  submitNewUser() {
    if (!this.isUserDataFilled(this.newUser)) {
      return;
    }

    this.newUserPanel = false;
    this.store.dispatch(setNewUserLoading({ value: true }));
    this.store.dispatch(
      createUser({
        user: { ...this.newUser },
        role: this.selectedRole,
      }),
    );

    this.newUser = this.getEmptyUser();
  }

  getEmptyUser(): Omit<User, 'id' | 'organisationId'> {
    return {
      firstName: '',
      lastName: '',
      email: '',
      // role: 'viewer',
    };
  }

  isUserDataFilled({
    firstName,
    lastName,
    email,
  }: Omit<User, 'id' | 'organisationId'>) {
    return firstName && lastName && email;
  }

  hasRole(user: User | UserWithRole): user is UserWithRole {
    return !!(user as UserWithRole).role;
  }

  includesById<T, K extends keyof T>(array: T[], key: K, value: T[K]): boolean {
    // console.log(array, key, value);
    // console.log(array.some(element => element[key] === value));
    return array.some(element => element[key] === value);
  }
}
