import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nw-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss'],
})
export class BooleanInputComponent {
  @Input()
  key: string;

  @Input()
  reverse = false;

  @Input()
  value: boolean[];

  @Output()
  changed = new EventEmitter<boolean>();

  get isArray() {
    return Array.isArray(this.value);
  }

  get array() {
    return Array.isArray(this.value) ? this.value : [this.value as number];
  }

  selectorOpen = false;

  inputChanged(event: Event) {
    this.changed.emit((event.target as HTMLInputElement).checked);
  }

  selectorClicked(event: boolean) {
    this.changed.emit(event);
  }
}
