import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isSaveActive } from '../../store/selector/editor.selector';
import { ShapeService } from '../../shape/shape.service';

// TODO - create loader for loading/refresh

@Component({
  selector: 'nw-utility-control-panel',
  templateUrl: './utility-control-panel.component.html',
  styleUrls: ['./utility-control-panel.component.scss'],
})
export class UtilityControlPanelComponent implements OnInit {
  isSaveActive$: Observable<boolean>;

  constructor(
    readonly cs: CanvasService,
    private readonly store: Store,
    readonly shapeService: ShapeService,
  ) {
    this.isSaveActive$ = this.store.select(isSaveActive);
  }

  ngOnInit(): void {}
}
