import { VectorService } from '../../services/util/vector';
import { Resource } from '../../elements/resource/resource';
import {
  Expression,
  ExpressionType,
  ArithmeticExpression,
  ResourceExpression,
  Operation,
  EquationExpression,
  FieldExpression,
  ComparisonExpression,
  MSRoutineExpr,
  StepType,
  MSIfElseExpr,
  MSSwitchExpr,
  ObjectExpression,
  ChainExpression,
  PathNodeExpression,
  MethodExpression,
} from '../ms.types';

import { ResourceType } from '../../elements/resource/resource.types';
import { Vector } from '../../elements/base/vector/vector';
import { cloneDeep, omit } from 'lodash';
import { GeneralShape } from '../../element-editor/shape/shapes/general/general-shape';
import { AnimationItem } from '../../elements/resource/types/shape.type';

export class MSEvaluationService {
  static executeRoutine(
    resource: Resource,
    routine: MSRoutineExpr,
    _params?: Record<string, any>,
  ) {
    for (const step of Array.isArray(routine) ? routine : [routine]) {
      if (step.stepType === StepType.IfElse) {
        const { condition, true: _true, false: _false } = step as MSIfElseExpr;
        if (this.resolve(resource, condition)) {
          this.executeRoutine(resource, _true);
        } else {
          _false && this.executeRoutine(resource, _false);
        }
      } else if (step.stepType === StepType.Switch) {
        const { expr, cases } = step as MSSwitchExpr;
        const condition = this.resolve(resource, expr);
        for (const { value, routine } of cases) {
          if (condition === this.resolve(resource, value)) {
            this.executeRoutine(resource, routine);
            break;
          }
        }
      } else {
        this.resolve(resource, step as Expression);
      }
    }
  }

  static resolve(
    resource: any,
    expr: Expression,
    baseResource?: Resource,
  ): any {
    try {
      switch (expr?.type) {
        case ExpressionType.Constant:
        case ExpressionType.ConstantBoolean:
        case ExpressionType.ConstantObject:
        case ExpressionType.ConstantNumber:
        case ExpressionType.ConstantString:
          return expr.minus ? -expr.value : expr.value;
        case ExpressionType.Object:
          return this.resolveObject(resource, expr as ObjectExpression);
        case ExpressionType.Arithmetic:
          return this.getArithmeticValue(
            resource,
            expr as ArithmeticExpression,
          );
        case ExpressionType.PathNode:
          const nodes = (expr as PathNodeExpression).chain
            .map(item => this.resolve(resource, item))
            .join(' ');
          return `${expr.value}${nodes}`;
        case ExpressionType.Expr:
          return expr.params.reduce(
            (actual: any, current) => this.resolve(actual, current),
            resource,
          );
        case ExpressionType.Method:
          return this.resolveMethod(resource, expr, baseResource);
        case ExpressionType.Field:
          return this.resolveFieldExpression(resource, expr as FieldExpression);
        case ExpressionType.Resource:
          const object: any = { type: (expr as ResourceExpression).value };
          switch (object.type) {
            case 'Point':
              object.x = this.resolve(resource, expr.params[0]);
              object.y = this.resolve(resource, expr.params[1]);
              break;
          }
          return object;

        case ExpressionType.Ref:
          const nextResource = resource.ref(expr.value);
          if (expr.next) {
            return this.resolve(nextResource, expr);
          } else {
            return nextResource;
          }
        case ExpressionType.Chain:
          return this.resolveChain(resource, expr as ChainExpression, resource);
        case ExpressionType.Equation:
          this.resolveEquationExpression(resource, expr as EquationExpression);
          return;
        case ExpressionType.Comparison:
          return this.resolveComparison(resource, expr as ComparisonExpression);
        default:
          // Unhappy face!
          return;
      }
    } catch (error) {
      console.warn('Error by evaluating expression');
      console.log('error', error);
      console.log('expr', expr);
      console.log('resource', resource);
    }
  }

  static evaluate(
    target: GeneralShape,
    expr: Expression,
    scope: 'base' | 'animation',
    animationId?: string,
  ) {
    const { type, value } = expr;

    switch (type) {
      case ExpressionType.Method:
        const params = (expr as MethodExpression).params;
        switch (value) {
          case 'all':
            const targets = [
              target as GeneralShape,
              ...(target as GeneralShape).multipliedShapesArray,
            ];

            const _expr = params[0];
            return targets.map(_target =>
              MSEvaluationService.evaluate(_target, _expr, scope, animationId),
            );

          case 'translate':
            target.currentAnimationId = animationId;
            const animation = MSEvaluationService.resolve(target, params[0]);
            target.setAnimationByCode({
              key: 'transform',
              value: animation,
            } as AnimationItem);
            return;
        }

      case ExpressionType.Field:
        if (value == 'this') {
          return target;
        }
        break;
      case ExpressionType.Chain:
        for (const chainElement of (expr as ChainExpression).chain) {
          target = this.evaluate(target, chainElement, scope, animationId);
          // if (Array.isArray(target)) {
          //   return target.map()
          // }
        }
        return target;
    }
  }

  static resolveChain(
    resource: Resource,
    { chain }: ChainExpression,
    baseResource: Resource,
  ) {
    let res = resource;
    chain.map(expr => (res = this.resolve(res, expr, baseResource)));
    return res;
  }

  static resolveObject(
    resource: Resource,
    { object, spread, objects }: ObjectExpression,
  ) {
    if (spread) {
      let o = {};
      objects
        .map(subObject => {
          if (!subObject.type) {
            return this.resolveObjectValue(
              resource,
              subObject as Record<string, Expression>,
            );
          }
          return this.resolve(resource, subObject);
        })
        .map(object => {
          o = { ...o, ...object };
        });
      return o;
    } else {
      return this.resolveObjectValue(resource, object);
    }
  }

  static resolveObjectValue(
    resource: Resource,
    object: Record<string, Expression>,
    target: Record<string, any> = {},
  ) {
    Object.entries(object).map(
      ([key, value]) => (target[key] = this.resolve(resource, value)),
    );
    return target;
  }

  static resolveFieldExpression(
    resource: Resource,
    { minus, value, filter }: FieldExpression,
  ) {
    if (!resource) {
      return;
    }
    if (filter) {
      const array = resource.ref(value) as any[];
      return array.filter(res => this.resolveComparison(res, filter));
    }
    if (minus) {
      return -resource.ref(value);
    }
    return resource.ref(value);
  }

  static resolveComparison(
    res: Resource,
    { left, right, operator }: ComparisonExpression,
  ) {
    const l = this.resolve(res, left);
    const r = this.resolve(res, right);
    switch (operator) {
      case '>':
        return l > r;
      case '<':
        return l < r;
      case '>=':
        return l >= r;
      case '<=':
        return l <= r;
      case '!=':
        return l != r;
      case '==':
        return l == r;
    }
  }

  static pascalToKebab(pascal: string) {
    let result;
    let kebab = `${pascal}`;
    let first = true;
    while ((result = /[A-Z]/.exec(kebab))) {
      const [letter] = result;
      kebab = kebab.replace(
        /[A-Z]/,
        (first ? '' : '-') + (letter as string).toLowerCase(),
      );
      first = false;
    }
    return kebab;
  }

  static resolveEquationExpression(
    resource: GeneralShape,
    { push, target, value }: EquationExpression,
  ) {
    if (push) {
      const componentKey = this.pascalToKebab(value.value);
      const descriptor = omit(
        resource.cs.elements[componentKey],
        'constructor',
      );
      // const res = resource.cs.initResource(
      //   {
      //     ...descriptor,
      //     constructor: this.resolve(resource, value.params[0]),
      //   },
      //   {
      //     key: target.value,
      //     child: true,
      //     type: OperationType.Push,
      //   },
      //   resource
      // );
      // res.init(resource.elementGroup);
    } else {
      resource.setLiteralValue(target.value, this.resolve(resource, value));
    }
  }

  static getArithmeticValue(resource: Resource, expr: Expression) {
    const params = cloneDeep(expr.params);
    let nextParam = params.shift();
    let value = this.resolve(resource, nextParam);
    for (const operation of expr.operations) {
      nextParam = params.shift();
      if (operation === Operation.Dot) {
        value = this.resolve(value, nextParam, resource);
      } else {
        const nextValue = this.resolve(resource, nextParam);
        switch (operation) {
          case Operation.Plus:
            if (nextValue.type === ResourceType.Vector) {
              value = VectorService.vectorize({
                start: {
                  x: value.x,
                  y: value.y,
                },
                end: {
                  x: value.x + nextValue.end.x - nextValue.start.x,
                  y: value.y + nextValue.end.y - nextValue.start.y,
                },
              });
            } else {
              value += nextValue;
            }
            break;
          case Operation.Minus:
            value -= nextValue;
            break;
          case Operation.Multiply:
            value *= nextValue;
            break;
          case Operation.Divide:
            value /= nextValue;
            break;
        }
      }
    }
    return expr.minus ? -value : value;
  }

  static resolveMethod(
    resource: Resource,
    expr: Expression,
    baseResource?: Resource,
  ) {
    switch (expr.value) {
      case 'animate':
        const [animation, duration] = expr.params.map(p =>
          this.resolve(resource, p, baseResource),
        );
        // this.asArray(resource).map(r => r.animate_({ ...animation, duration }));
        return;
      case 't':
        const [x, y, scale] = expr.params.map(p =>
          this.resolve(resource, p, baseResource),
        );
        return { x, y, scale };
      case 'sqrt':
        return Math.sqrt(this.resolve(resource, expr.params[0]));
      case 'Point':
        const object: any = { type: (expr as ResourceExpression).value };
        object.x = this.resolve(resource, expr.params[0]);
        object.y = this.resolve(resource, expr.params[1]);
        return object;
      default:
        return VectorService.vectorize(
          this.resolveVectorFunction(resource, expr, baseResource),
        );
    }
  }

  static resolveVectorFunction(
    resource: Resource,
    expr: Expression,
    baseResource?: Resource,
  ) {
    switch (expr.value) {
      case 'v':
        return VectorService.create(expr, [
          { x: 0, y: 0 },
          {
            x: this.resolve(resource, expr.params[0]) as number,
            y: this.resolve(resource, expr.params[1]) as number,
          },
        ]);
      case 'vector':
        return VectorService.create(
          expr,
          expr.params.map(p => this.resolve(resource, p, resource)),
        );
      case 'scale':
        return VectorService.scale(
          resource as Vector,
          this.resolve(baseResource, expr.params[0]),
        );
      case 'size':
        return VectorService.resize(
          resource as Vector,
          this.resolve(baseResource, expr.params[0]),
        );
      case 'turn':
        const v = VectorService.turn(
          resource as Vector,
          this.resolve(baseResource, expr.params[0], resource),
        );
        return v;
      case 'norm':
        return VectorService.norm(resource as Vector);
      case 'unify':
        return VectorService.unify(resource as Vector);
      case 'unit':
        return VectorService.resize(resource as Vector, 1);
      case 'add':
        return VectorService.add(
          resource as Vector,
          this.resolve(baseResource, expr.params[0]),
        );
      default:
        throw new Error(`'${expr.value}' is not a valid function!`);
    }
  }

  static roundVector(v: any) {
    v.start.x = this.round(v.start.x);
    v.start.y = this.round(v.start.y);
    v.end.x = this.round(v.end.x);
    v.end.y = this.round(v.end.y);
    v.x = this.round(v.x);
    v.y = this.round(v.y);
    return v;
  }

  static round(number: number) {
    return Math.abs(number % 1) < 10e-10 ? Math.round(number) : number;
  }

  static asArray<T = any>(key: T | T[]): T[] {
    return Array.isArray(key) ? key : [key];
  }
}
