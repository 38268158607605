import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { UnAuthenticationGuard } from './authentication/unauthentication.guard';
import { OrganisationSelectorComponent } from './components/organisation-selector/organisation-selector.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { HomeComponent } from './components/home/home.component';
import { OrganisationIndexComponent } from './components/organisation-index/organisation-index.component';
import { ProjectSelectorComponent } from './components/project-selector/project-selector.component';
import { PlayComponent } from './components/play/play.component';
import { RecorderComponent } from './components/recorder/recorder.component';
import { EditorComponent } from './element-editor/component/editor.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'organisations',
        children: [
          {
            path: '',
            component: OrganisationSelectorComponent,
          },
          {
            path: ':organisationID',
            children: [
              {
                path: '',
                component: OrganisationIndexComponent,
              },
              {
                path: 'projects',
                children: [
                  {
                    path: '',
                    component: ProjectSelectorComponent,
                  },
                  {
                    path: ':projectID',
                    children: [
                      {
                        path: '',
                        component: EditorComponent,
                      },
                      {
                        path: 'file',
                        children: [
                          {
                            path: ':fileID',
                            children: [
                              {
                                path: '',
                                component: EditorComponent,
                              },
                              {
                                path: 'play',
                                data: {
                                  hideHeader: true,
                                },
                                component: PlayComponent,
                              },
                              {
                                path: 'record',
                                data: {
                                  hideHeader: true,
                                },
                                component: RecorderComponent,
                              },
                              {
                                path: ':scene',
                                component: EditorComponent,
                              },
                              {
                                path: '**',
                                redirectTo: '',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: '**',
                        redirectTo: '',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'users',
        component: UserSelectorComponent,
      },
    ],
  },
  {
    path: '',
    canActivate: [UnAuthenticationGuard],
    children: [
      {
        path: 'signIn',
        component: SignInComponent,
      },
      {
        path: 'signUp',
        component: SignUpComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
