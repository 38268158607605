<div class="text-animation-component">
  <div class="title" style="margin-top: 4px">Text</div>

  <textarea
    [value]="text"
    (change)="textChanged($event)"
    (keydown)="$event.stopPropagation()"
    (keyup)="$event.stopPropagation()"
  ></textarea>

  <nw-boolean-input
    key="Subtitle"
    [reverse]="true"
    [value]="[frame.subtitle]"
    (changed)="subtitleChanged($event)"
  ></nw-boolean-input>
  <div class="flex-row space-between">
    <nw-boolean-input
      key="Voiceover"
      [reverse]="true"
      [value]="[!!soundFileUrl]"
      (changed)="speechChanged($event)"
    ></nw-boolean-input>
    <div class="voice-type-container">
      <div
        class="voice-type"
        [class.selected-type]="isGenerated"
        (click)="setGenerated()"
      >
        <nw-icon type="refresh" [size]="14"></nw-icon>
      </div>
      <div class="delimiter"></div>
      <div
        class="voice-type"
        [class.selected-type]="isRecorded"
        (click)="setRecorded()"
      >
        <nw-icon type="mic" [size]="14"></nw-icon>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isGenerated">
    <div
      *ngIf="isLoading"
      class="w-100 flex-column gap-10"
      style="margin-top: 6px"
    >
      <div>Voice is being generated...</div>
      <div class="spinner-x"></div>
    </div>

    <div
      class="sample flex-row space-between"
      *ngIf="soundFileUrl && !isLoading"
    >
      <div class="half" style="border-right: 1px solid black; width: 20%">
        <nw-icon type="play_arrow" [size]="12" (click)="playSample()"></nw-icon>
      </div>
      <nw-time-display [time]="1.2"></nw-time-display>
      <div class="half" style="border-left: 1px solid black; width: 20%">
        <nw-icon type="autorenew" [size]="12" (click)="refresh()"></nw-icon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isRecorded">
    <div class="sample flex-row space-between">
      <div class="half" style="border-right: 1px solid black; width: 20%">
        <ng-container *ngIf="recordIsInProgress; else noRecord">
          <div class="stop-record-button blink" (click)="stopRecording()"></div>
        </ng-container>
        <ng-template #noRecord>
          <ng-container *ngIf="recordFileUrl">
            <ng-container *ngIf="newRecording">
              <div class="record-button" (click)="startRecording()"></div>
            </ng-container>
            <ng-container *ngIf="!newRecording">
              <nw-icon
                type="play_arrow"
                [size]="12"
                (click)="playAudio()"
              ></nw-icon>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!recordFileUrl">
            <div class="record-button" (click)="startRecording()"></div>
          </ng-container>

          <ng-template #recordFileIsThere>
            <!-- <ng-container *ngIf="!newRecording">
              <nw-icon
                type="play_arrow"
                [size]="12"
                (click)="playAudio()"
              ></nw-icon>
            </ng-container> -->
            <!-- <ng-template #record>
              <ng-container *ngIf="recordFileUrl">
                <nw-icon
                  *ngIf="!playInProgress"
                  type="play_arrow"
                  [size]="12"
                  (click)="playAudio()"
                ></nw-icon>
                <nw-icon
                  *ngIf="playInProgress"
                  type="stop"
                  [size]="12"
                  (click)="pauseAudio()"
                ></nw-icon>
              </ng-container>
            </ng-template> -->
          </ng-template>
        </ng-template>
      </div>
      <div class="flex-row space-around w-100">
        <nw-time-display
          [time]="recordIsInProgress ? recordTime : frameObject._duration"
        ></nw-time-display>
      </div>
      <div
        *ngIf="recordFileUrl"
        class="half"
        style="border-left: 1px solid black; width: 20%"
      >
        <nw-icon type="autorenew" [size]="12" (click)="refresh()"></nw-icon>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="languages.length"
    class="flex-row space-between"
    style="margin-top: 6px"
  >
    <!-- <div class="fs-13">Language</div>
    <div>{{ currentLanguage$ | async }}</div> -->

    <!-- <select name="" id="">
      <option value="">default</option>
      <option *ngFor="let lang of languages" [value]="lang">
        {{ langMap[lang] }}
      </option>
    </select> -->
  </div>
</div>
