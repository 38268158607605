import { Coords } from '../../../../../elements/resource/types/shape.type';
import { GeneralShape } from '../../general/general-shape';
import { LinearTrajectoryFunction } from './linear-trajectory-function';

export interface Trajectory {
  start: number;
  end: number;
  fcn: LinearTrajectoryFunction;
}

export class AnimationController {
  constructor(protected readonly shape: GeneralShape) {}

  incrementAnimation(increment: number) {}

  getIntervals(division: number, cnt: number) {
    const intervals = [];

    let offset = 0;
    const jump = Math.floor(division / cnt);
    for (let i = 0; i < cnt; i++) {
      intervals.push({
        start: offset,
        end: offset + jump,
      });

      offset += jump;
    }
    intervals[intervals.length - 1].end = division;
    return intervals;
  }

  getLinearTrajectory([xs, ys]: Coords, [xe, ye]: Coords): Trajectory {
    return {
      start: xs,
      end: xe,
      fcn: new LinearTrajectoryFunction([xs, ys], [xe, ye]),
    };
  }
}
