<div
  class="hand-shape-attribute-component"
  *ngIf="_attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    icon="wrist"
    [attributeState]="attributeState"
    key="_config"
    [noDelete]="true"
    [initialValue]="{ color: '#000000', width: 1 }"
  ></nw-general-attribute-header>

  <div
    [ngSwitch]="attributeState.state"
    class="value-section"
    [ngClass]="{
      'value-section-disabled': !!attributeState.selectedFunctions?.length,
    }"
  >
    <div *ngSwitchCase="'defined'" class="attr-container flex-column gap-4">
      <ng-container
        *ngIf="
          !attributeState.functions || attributeState.functions.length == 1
        "
      >
        <nw-boolean-input
          key="closed"
          [value]="attributeState.value.closed || false"
          (changed)="updateSubAttributeValue('closed', $event)"
        ></nw-boolean-input>

        <nw-number-input
          key="curve"
          [value]="attributeState.value.curve"
          (changed)="updateSubAttributeValue('curve', $event)"
        ></nw-number-input>

        <div
          *ngFor="
            let section of attributeState.value.handSections?.[0] || [];
            index as i
          "
        >
          <iw-hand-shape-section
            [section]="section"
            [index]="i"
            [length]="attributeState.value.handSections?.[0].length"
            [closed]="attributeState.value.closed?.[0]"
            (change)="changeSection(i, $event)"
          ></iw-hand-shape-section>
        </div>

        <div class="flex-row gap-4">
          <div class="fs-11">Add section</div>
          <nw-icon type="add" [size]="12" (click)="addNewSection()"></nw-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
