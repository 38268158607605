import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nw-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.scss'],
})
export class FloatingComponent implements OnInit {
  @Input()
  left = 0;

  @Input()
  top = 0;

  @Input()
  bottom = 0;

  @Input()
  right = 0;

  constructor() {}

  ngOnInit(): void {}
}
