import { Component, Input } from '@angular/core';
import { ShapeService } from '../../../shape/shape.service';

@Component({
  selector: 'nw-spacing-controller',
  templateUrl: './spacing-controller.component.html',
  styleUrls: ['./spacing-controller.component.scss'],
})
export class SpacingControllerComponent {
  @Input()
  isRow = false;

  showGapInput = false;
  gap: number;

  get x() {
    return this.isRow
      ? this.shapeService.horizontalSpacingPosition[0]
      : this.shapeService.verticalSpacingPosition[0];
  }

  get y() {
    return this.isRow
      ? this.shapeService.horizontalSpacingPosition[1]
      : this.shapeService.verticalSpacingPosition[1];
  }
  constructor(public readonly shapeService: ShapeService) {}

  clickHandler() {
    this.showGapInput = true;
    if (this.isRow) {
      this.shapeService.evenAlignRow();
      this.gap = this.shapeService.dxAvg;
    } else {
      this.shapeService.evenAlignColum();
      this.gap = this.shapeService.dyAvg;
    }
  }
  gapChange() {
    if (this.isRow) {
      this.shapeService.evenAlignRow(this.gap);
    } else {
      this.shapeService.evenAlignColum(this.gap);
    }
  }
}
