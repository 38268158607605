import { set } from 'lodash';
import { CanvasService } from '../../../../services/canvas/canvas.service';
import { ArcSection } from '../path-shape/path-sections/arc/arc-section';
import { PathSection } from '../path-shape/path-sections/path-section';
import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import {
  LineAnimation,
  LineAnimationInstance,
  TrajectoryShapeDescriptor,
  __Animation,
} from '../../../../elements/resource/types/shape.type';
import { PathShape } from '../path-shape/path-shape';
import { PathElement } from '../primitive/path-element';
import { ShapeService } from '../../shape.service';

export class TrajectoryShape extends PathShape<TrajectoryShapeDescriptor> {
  disabled = false;

  // get svgAttributes() {
  //   return Object.keys(super.svgAttributes || {}).length
  //     ? super.svgAttributes
  //     : { 'stroke-dasharray': 4, fill: 'none', stroke: 'black' };
  // }

  // set svgAttributes(value: Record<string, any>) {
  //   if (Object.keys(value).length) {
  //     super.svgAttributes = value;
  //   } else {
  //     super.svgAttributes = undefined;
  //   }
  // }

  get currentLineAnimation(): LineAnimation {
    // return this.getAnimationById(this.cs.currentAnimation?.id);
    return null;
  }

  get currentLineElement() {
    return this.trajectorAnimationInstances[this.cs.currentAnimation.id]
      ?.lineElement;
  }

  get lineElements() {
    return Object.values(this.trajectorAnimationInstances).map(
      ({ lineElement }) => lineElement,
    );
  }

  get SectionClassToInit(): typeof PathSection {
    if (this.cs.isPressed('a')) {
      return PathSection;
    } else {
      return ArcSection;
    }
  }

  get isOldAnimation() {
    return !!this.getOldAnimation(this.cs.currentAnimation?.id);
  }

  getType() {
    return 'ts';
  }

  constructor(
    service: ShapeService,
    r: ResourceData<TrajectoryShapeDescriptor>,
  ) {
    super(service, r);
    this.type = ResourceType.TrajectoryShape;

    if (this.parent?.getType() === 'is') {
      Object.entries(this.descriptor.lineAnimations || {}).map(
        ([id, value]) => {
          const newId = `${this.parent.IRI}_${id}`;
          this.descriptor.lineAnimations[newId] = value;
        },
      );
    }
  }

  save() {
    Object.keys(this.descriptor.lineAnimations || {})
      .filter(
        key =>
          !this.cs.functionIds?.[key] && key !== this.cs.currentAnimation?.id,
      )
      .map(key => {
        delete this.descriptor.lineAnimations[key];
      });

    super.save();
  }

  updateOpacity(opacity: number) {
    // TODO - imple
  }

  hasAnimation(id: string) {
    return super.hasAnimation(id) || !!this.descriptor.lineAnimations?.[id];
  }

  initLineElement(svgAttributes?: Record<string, any>) {
    console.log('init-line-element');
    return new PathElement(this, this.container, {
      ...svgAttributes,
      elements: this._elements,
    });
  }

  // addLine() {
  //   const { id, duration } = this.cs.currentAnimation;

  //   this.patch('trajectory', {
  //     line: { length: 100 },
  //     svgAttributes: {},
  //   });

  //   this.trajectorAnimationInstances[id] = this.getAnimationInstance(
  //     id,
  //     duration
  //   );
  //   this.save();
  //   this.refresh();
  // }

  init() {
    super.init();
    // TODO - migrate to pixi
    // this.lastSection?.hoverPath?.remove();
  }

  refresh() {
    super.refresh();
    // TODO - migrate to pixi
    // this.lastSection?.hoverPath?.remove();
    Object.values(this.trajectorAnimationInstances || {}).map(
      ({ lineElement }) =>
        lineElement.patch({
          elements: this._elements,
        }),
    );
  }

  getOldAnimation(id: string) {
    return this.descriptor.lineAnimations?.[id];
  }

  getAnimationInstance(id: string, duration: number): LineAnimationInstance {
    const animation = this.animationsById[id];
    return;
    // if (animation.dashArray) {
    //   // Here we need to set the array
    //   // this.pathElement.element.attr({ 'stroke-dasharray': 0 });

    //   let { length, speed } = animation.dashArray;

    //   if (length === -1) {
    //     length = this.lastSection.endLength;

    //     const time = speed;
    //     return {
    //       lineElement: this.initLineElement({
    //         ...animation.svgAttributes,
    //         'stroke-dasharray': length,
    //         'stroke-dashoffset': length,
    //       }),
    //       currentValue: length,
    //       // The offset is decreasing
    //       increment: -length / (time * this.cs.batchPerSecond),
    //       maxValue: 0,
    //     };
    //   } else {
    //     speed ||= 1;
    //     this.lengthInterval = null;
    //     return {
    //       lineElement: this.initLineElement({
    //         ...animation.svgAttributes,
    //         'stroke-dasharray': length,
    //       }),
    //       currentValue: 0,
    //       increment: -(speed * length) / this.cs.batchPerSecond,
    //     };
    //   }
    // } else {
    //   // this.pathElement.element.attr({ 'stroke-dasharray': 4 });
    //   let { offset, length } = animation.line;
    //   offset ||= 0;
    //   const totalLength = this.lastSection.endLength;
    //   this.setInterval(offset || 0, length);
    //   return {
    //     lineElement: this.initLineElement(animation.svgAttributes),
    //     currentValue: offset,
    //     increment:
    //       -(totalLength - offset) / (duration * this.cs.batchPerSecond),
    //   };
    // }
  }

  updateAnimationInstance() {
    const { id, duration } = this.cs.currentAnimation;
    if (!this.trajectorAnimationInstances[id]) {
      return;
    }
    this.trajectorAnimationInstances[id].lineElement.remove();
    this.trajectorAnimationInstances[id] = this.getAnimationInstance(
      id,
      duration,
    );
  }

  // removeLineAnimation() {
  //   const id = this.cs.currentAnimation?.id;
  //   delete this.descriptor.lineAnimations?.[id];
  //   this.trajectorAnimationInstances[id]?.lineElement.hide();
  //   this.save();
  // }

  get lastSection() {
    return this.pathSections?.[this.pathSectionLength - 2];
  }

  setInterval(start: number, length: number) {
    this.lengthInterval = [start, start + length];
  }

  /*************** shape-item.component endpoints ******************/

  // lineAnimationChanged() {
  //   this.updateAnimationInstance();
  //   this.save();
  // }

  changeDashArray() {
    const animation = this.currentLineAnimation;
    // The animation.dashArray value is set to true/false by the checkbox in the shape-item.component
    // <input type="checkbox" [(ngModel)]="animationItem.dashArray" ... />
    if (animation.dashArray) {
      delete animation.line;
      animation.dashArray = {
        length: 100,
        speed: 1,
      };
    } else {
      delete animation.dashArray;
      animation.line = {
        length: 100,
      };
    }
    this.updateAnimationInstance();
    this.save();
  }

  movingLineSelected = false;

  select() {
    super.select();
    if (this.cs.isPressed('Shift') && this.currentLineAnimation) {
      console.log('select-moving-line');
      this.selectMovingLine();
    } else {
      this.deselectMovingLine();
    }
  }

  deselect() {
    super.deselect();
    this.deselectMovingLine();
  }

  selectMovingLine() {
    this.element.hide();
    this.movingLineSelected = true;
  }

  deselectMovingLine() {
    this.element?.show();
    this.movingLineSelected = false;
  }

  patch(key: string, value: any) {
    if (this.movingLineSelected) {
      // const animation = this.getAnimationById(this.cs.currentAnimation.id);
      // animation.svgAttributes ||= {};
      // set(animation, key, value);
      // this.updateAnimationInstance();

      this.save();
    } else {
      super.patch(key, value);
    }
  }
}

// this.cs.keyEventSubscribe('Shift+G', () => {
//   this.refresh();
//   this.startLength = 0;
//   this.endLength = 120;
//   this.refresh();

//   this.disabled = false;

//   const lambda = () =>
//     setTimeout(() => {
//       this.startLength += 10;
//       this.endLength += 10;
//       this.refresh();

//       if (this.disabled) {
//         return;
//       }

//       lambda();
//     }, 40);

//   lambda();
// });

// this.cs.keyEventSubscribe('Shift+B', () => {
//   this.refresh();
//   this.startLength = 40;
//   this.endLength = 140;
//   this.refresh();
// });
// this.cs.keyEventSubscribe('g', () => {
//   this.disabled = true;
//   this.startLength += 4;
//   this.endLength += 4;
//   this.refresh();
// });
// this.cs.keyEventSubscribe('b', () => {
//   this.disabled = true;
//   this.startLength -= 10;
//   this.endLength -= 10;
//   this.refresh();
// });
