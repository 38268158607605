import { Store } from '@ngrx/store';
import { setDescriptorValue } from '../../store/editor.actions';
import {
  AnimationInnerKey,
  AnimationKeys,
  LiteralValue,
} from '../../../elements/resource/types/shape.type';
import { Observable } from 'rxjs';
import { AttributePanelState } from '../../../services/animation/animation.types';
import { map } from 'rxjs/operators';

export class BaseAttributeComponent {
  key: AnimationKeys;

  attributeState$: Observable<AttributePanelState>;

  get isDefined$() {
    return this.attributeState$.pipe(
      map(
        state =>
          state?.type == 'base' ||
          state?.type == 'animation' ||
          state?.type == 'function',
      ),
    );
  }
  get isBase$() {
    return this.attributeState$.pipe(map(state => state.type == 'base'));
  }

  get isAnimation$() {
    return this.attributeState$.pipe(map(state => state.type == 'animation'));
  }

  get isMixed$() {
    return this.attributeState$.pipe(map(state => state.type == 'mixed'));
  }

  get isFunction$() {
    return this.attributeState$.pipe(map(state => state.type == 'function'));
  }

  constructor(protected readonly store: Store) {}

  updateSubAttributeValue(innerKey: AnimationInnerKey, value: LiteralValue) {
    this.store.dispatch(
      setDescriptorValue({
        key: this.key,
        innerKey,
        value,
      }),
    );
  }

  saveAttribute(key: AnimationKeys, value: any) {
    this.store.dispatch(
      setDescriptorValue({
        key,
        value,
      }),
    );
  }
}
