export const extendedMap = <T = any, P = any>(
  callBack: (prev: T, current: T, next: T, index?: number) => P,
  array: T[]
) => {
  let prev: T, next: T;
  return array.map((current, index) => {
    if (index == 0) {
      prev = array[array.length - 1];
    } else {
      prev = array[index - 1];
    }

    if (index == array.length - 1) {
      next = array[0];
    } else {
      next = array[index + 1];
    }

    return callBack(prev, current, next, index);
  });
};
