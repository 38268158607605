import { Point } from '../elements/base/point';
import { Vector } from '../elements/base/vector/vector';
import { VectorLine } from './vector-line';
import { Resource } from '../elements/resource/resource';
import { GeneralShape } from '../element-editor/shape/shapes/general/general-shape';

export interface LineConfig {
  parent: Resource;
  start: string;
  end: string;
}

export class Line {
  parent: Resource;
  n: Vector;
  config: LineConfig;

  svgStart: Point;
  svgEnd: Point;
  iPoint: Point;

  public b = NaN;
  public m = NaN;
  public x = NaN;

  constructor(
    public s: Point,
    public e: Point,
  ) {
    this.iPoint = new Point(0, 0, true);
  }

  public getS(): Point {
    return this.s;
  }

  public getE(): Point {
    return this.e;
  }

  public getSE(): [Point, Point] {
    return [this.getS(), this.getE()];
  }

  public calc() {
    const s = this.getS();
    const e = this.getE();
    const dx = e.x - s.x;
    if (Math.abs(dx) < 10e-5) {
      this.x = e.x;
      return;
    }
    const dy = e.y - s.y;
    this.m = dy / dx;
    const alfa = Math.atan(this.m);
    this.b = s.y - s.x * Math.tan(alfa);
  }

  public getY(x: number): number {
    return this.b + this.m * x;
  }

  public draw(shape: GeneralShape) {
    this.svgStart = this.svgStart || new Point(0, 0, true);
    this.svgEnd = this.svgEnd || new Point(0, 0, true);

    this.svgStart.setCoord(0, this.b);
    this.svgEnd.setCoord(5000, this.getY(5000));
  }

  public intersection(line: Line): Point {
    let x = null;
    let m = null;
    let b = null;
    if (!isFinite(line.m) || !isFinite(this.m)) {
      if (!isFinite(line.m)) {
        x = line.getS().x;
        m = this.m;
        b = this.b;
      }
      if (!isFinite(this.m)) {
        x = this.getS().x;
        m = line.m;
        b = line.b;
      }
    } else {
      x = (this.b - line.b) / (line.m - this.m);
      m = this.m;
      b = this.b;
    }

    const y = m * x + b;
    if (isNaN(x) || isNaN(y)) {
      return line.getS(); // .copy();
    } else {
      this.iPoint.setCoord(x, y);
      return this.iPoint;
    }
  }
}

export class DynamicLine extends Line {
  constructor(
    public parent: Resource,
    public start = 'start',
    public end = 'end',
  ) {
    super(parent.getPoint(start) as Point, parent.getPoint(end) as Point);
  }

  public getS(): Point {
    return this.parent.getPoint(this.start) as Point;
  }

  public getE(): Point {
    return this.parent.getPoint(this.end) as Point;
  }

  public get S(): Point {
    return this.parent.getPoint(this.start) as Point;
  }

  public get E(): Point {
    return this.parent.getPoint(this.end) as Point;
  }

  public intersection(line: Line): Point {
    this.calc();
    line.calc();
    return super.intersection(line);
  }

  public angleCheck(line: VectorLine): boolean {
    let angle1 = new Vector(this.getS(), this.getE()).getAngle();
    let angle2 = null;
    if (!line.final) {
      line.calc();
    }
    angle2 = line.final.getAngle();
    if (angle1 < angle2) {
      angle1 += 2 * Math.PI;
    }
    return angle1 - angle2 < Math.PI;
  }
}

export class VectorDynamicLine extends DynamicLine {}

export class PerpendicalarDynamicLine extends DynamicLine {
  private calculatedS = new Point();
  private calculatedE = new Point();
  private vector: Vector;

  s = new Point();
  e = new Point();

  constructor(
    public parent: Resource,
    public start: string,
    public end: string,
  ) {
    super(parent, start, end);
    this.vector = new Vector(this.s, this.e);
  }

  public calc() {
    this.s.set(this.S);
    this.e.set(this.E);
    this.vector.set(this.e, this.s);
    this.calculatedS.set(this.vector.scale(0.5).end);
    this.calculatedE.set(this.calculatedS.endPoint(this.vector.normal));
    super.calc();
  }

  public getS(): Point {
    return this.calculatedS;
  }

  public getE(): Point {
    return this.calculatedE;
  }
}
