<div fxLayout="row" fxLayoutAlign="start" style="height: 100%">
  <div class="iw-icon-list-outer"></div>

  <nw-control-element (click)="addCircle()">
    <nw-icon
      type="circle"
      [outlined]="true"
      [size]="16"
      [disabled]="circleDisabled"
      matTooltip="Add circle"
    ></nw-icon>
  </nw-control-element>
  <nw-control-element (click)="addRectangle()">
    <nw-icon
      type="rectangle"
      [outlined]="true"
      [size]="16"
      [disabled]="rectangleDisabled"
      matTooltip="Add rectangle"
    ></nw-icon>
  </nw-control-element>

  <nw-control-element (click)="addTextShape()">
    <nw-icon
      type="title"
      [outlined]="true"
      [size]="16"
      [disabled]="textShapeDisabled"
      matTooltip="Add text"
    ></nw-icon>
  </nw-control-element>
  <nw-control-element (click)="addHandShape()">
    <nw-icon
      type="share"
      [outlined]="true"
      [size]="16"
      [disabled]="pathShapeDisabled"
      matTooltip="Add path-shape"
    ></nw-icon>
  </nw-control-element>

  <!-- 
  <nw-control-element (click)="cs.addShadowShape()">
    <nw-icon
      type="nightlight"
      [outlined]="true"
      [size]="16"
      [disabled]="!cs.previewShape"
      matTooltip="Add hand-shape"
    ></nw-icon>
  </nw-control-element> -->
</div>
