<div class="container" [style.width.px]="_w" [style.height.px]="_h">
  <div
    *ngIf="loading"
    class="drop-image"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="14px"
  >
    <mat-spinner diameter="24"></mat-spinner>
    <div>{{ inProgressText }}</div>
  </div>
  <div #previewElement [style.width.px]="100" [style.height.px]="50"></div>
</div>
