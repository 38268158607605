import { Expression } from '../../ms/ms.types';
import { PointData } from './vector-service';
import { ResourceType } from '../../elements/resource/resource.types';
import { Point } from '../../elements/base/point';
import { VectorService as vs } from './vector-service';

export interface Vector {
  // start: Partial<Point>;
  // end: Partial<Point>;
  start: PointData;
  end: PointData;
}

export class VectorService {
  static create(_: Expression, [p1, p2]: Partial<Point>[]) {
    return {
      start: {
        x: p1.x,
        y: p1.y,
      },
      end: {
        x: p2.x,
        y: p2.y,
      },
    };
  }

  static turn({ start, end }: Vector, degree: number) {
    const { x, y } = this.diff({ start, end });
    // Negate because the y axis, points horizontal
    const angle = -(degree * Math.PI) / 180;
    return {
      start,
      end: {
        x: start.x + Math.cos(angle) * x - Math.sin(angle) * y,
        y: start.y + Math.sin(angle) * x + Math.cos(angle) * y,
      },
    };
  }

  static scale({ start, end }: Vector, magnitude: number) {
    return {
      start,
      end: vs.scale1(start as PointData, end as PointData, magnitude),
    };
  }

  static diff({ start, end }: Vector): { x: number; y: number } {
    return {
      x: end.x - start.x,
      y: end.y - start.y,
    };
  }

  static size(x: number, y: number) {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  static vectorize(v: Vector) {
    return {
      type: ResourceType.Vector,
      ...v,
      x: v.end.x,
      y: v.end.y,
    };
  }

  static resize(v: Vector, size: number): Vector {
    const unit = this.unit(v);
    const length = this.getLength(unit);
    return this.scale(unit, size / length);
  }

  static unit(v: Vector) {
    const length = this.getLength(v);
    return {
      start: {
        x: 0,
        y: 0,
      },
      end: {
        x: this.getX(v) / length,
        y: this.getY(v) / length,
      },
    };
  }

  static add(v1: Vector, v2: Vector) {
    v2 = this.unify(v2);
    return {
      start: v1.start,
      end: {
        x: v1.end.x + v2.end.x,
        y: v1.end.y + v2.end.y,
      },
    };
  }

  static unify({ start, end }: Vector) {
    const x = end.x - start.x;
    const y = end.y - start.y;
    return {
      start: {
        x: 0,
        y: 0,
      },
      end: { x, y },
      x,
      y,
    };
  }

  static norm(v: Vector) {
    return {
      start: {
        x: 0,
        y: 0,
      },
      end: {
        x: -(v.end.y - v.start.y),
        y: v.end.x - v.start.x,
      },
    };
  }

  static getLength(v: Vector): number {
    const x = Math.abs(this.getX(v));
    const y = Math.abs(this.getY(v));
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  static getX(v: Vector) {
    return v.end.x - v.start.x;
  }

  static getY(v: Vector) {
    return v.end.y - v.start.y;
  }
}
