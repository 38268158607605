import { Graphics } from 'pixi.js';
import { SVG } from './svg-element';
import { ArcItem, CurveItem, PathItem } from '../path-shape/path-shape.types';

export class PathElement extends SVG<{
  elements?: PathItem[];
}> {
  get elements() {
    return this.config.elements;
  }
  initElement() {
    this._element = new Graphics();
  }

  updateAttr() {
    super.updateAttr();
    let { x: _x, y: _y } = this.position;

    // console.log('updateAttr', _x, _y); //
    if (this.elements) {
      this.elements.map((config, i) => {
        const { type, x, y, X, Y, offset } = config;

        if (i == 0) {
          if (offset) {
            this.offset = offset;
            _x += this.offsetX;
            _y += this.offsetY;
            this.element.moveTo(_x, _y);
          } else {
            this.element.moveTo(0, 0);
          }
        }

        let __x: number, __y: number;
        if (isNaN(x) && isNaN(y)) {
          __x = X;
          __y = Y;
        } else {
          __x = _x + x;
          __y = _y + y;
        }

        switch (type) {
          case 'line':
            this.element.lineTo(__x, __y);
            break;
          case 'arc':
            const { a1, a2, antiClockwise, r, cx, cy, CX, CY } =
              config as ArcItem;

            if (isNaN(cx) && isNaN(cy)) {
              this.element.arc(CX, CY, r, a1, a2, antiClockwise);
            } else {
              this.element.arc(_x + cx, _y + cy, r, a1, a2, antiClockwise);
            }

            break;
          case 'curve':
            const { bx, by, cx: cx1, cy: cy1 } = config as CurveItem;

            this.element.bezierCurveTo(
              _x + bx,
              _y + by,
              __x + cx1,
              __y + cy1,
              __x,
              __y,
            );
            break;
        }

        if (isNaN(x) && isNaN(y)) {
          _x = X;
          _y = Y;
        } else {
          _x += x;
          _y += y;
        }
      });
    } else {
      this.element.lineTo(_x + this.x, _y + this.y);
    }
    // if (this.config.closed) {
    //   this.element.closePath();
    // }
    this.element.endFill();
    // this.updatePosition();
  }
}
