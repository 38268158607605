import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { addAnimationItemAction } from '../../animation/store/animation.actions';
import { _attributeState } from '../../store/selector/editor.selector';
import { GradientDirection } from '../../shape/shapes/primitive/rectangle-element';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { removeAttribute } from '../../store/editor.actions';

@Component({
  selector: 'nw-fill-attribute',
  templateUrl: './fill-attribute.component.html',
  styleUrls: ['./fill-attribute.component.scss'],
})
export class FillAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(protected readonly store: Store) {
    super(store);
    this.key = 'fill';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('fill'));
    this.attributeState$.subscribe(val => {
      // console.log('----- fill attr-state ------', val);
    });
  }

  gradientDirectionChanged(event: Event) {
    this.updateSubAttributeValue(
      'gradientDirection',
      (event.target as HTMLInputElement).value as GradientDirection,
    );
  }

  saveMode = false;
  newFunctionName: string;

  saveAnimation() {
    this.saveMode = true;
  }

  addFunction() {
    this.saveMode = true;
  }

  fcnSelectorOpen = false;
  functionSelected(name: string) {
    // TODO - migrate it to setAttributeAction
    // the idea is that attribute components don't think in terms of animation
    this.store.dispatch(
      addAnimationItemAction({
        item: {
          key: 'fill',
          fcn: name,
        },
      }),
    );
    this.fcnSelectorOpen = false;
  }

  functionEditMode = false;

  editFunction() {
    this.functionEditMode = true;
  }

  saveFunction() {
    // -- // -- //
    this.functionEditMode = false;
  }

  discardNewAnimation() {
    this.saveMode = false;
  }

  deleteAnimation() {
    this.store.dispatch(removeAttribute({ key: 'fill' }));
  }
}
