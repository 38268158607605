import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  onlyShapeType,
  selectedShapes,
  selectScenes,
} from '../store/selector/editor.selector';
import { map } from 'rxjs/operators';
import { currentAnimationId } from '../animation/store/animation.selector';
import { AnimationService } from '../animation/animation.service';
import { ShapeService } from '../shape/shape.service';

@Component({
  selector: 'nw-shape-attribute-panel',
  templateUrl: './shape-attribute-panel.component.html',
  styleUrls: ['./shape-attribute-panel.component.scss'],
})
export class ShapeAttributePanelComponent {
  areThereSelectedShapes$: Observable<boolean>;

  isFrameSelected$: Observable<boolean>;

  get isShapeLoading() {
    return this.shapeService.previewShapeIsLoading;
  }

  areThereMultiplScenes$: Observable<boolean>;
  onlyHandshapes$: Observable<boolean>;
  onlyPathShapes$: Observable<boolean>;
  onlyTextShapes$: Observable<boolean>;
  onlyCircleShapes$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    public readonly animationsService: AnimationService,
    private readonly shapeService: ShapeService,
  ) {
    this.areThereMultiplScenes$ = this.store
      .select(selectScenes)
      .pipe(map(scenes => scenes?.length > 1));

    this.areThereSelectedShapes$ = this.store
      .select(selectedShapes)
      .pipe(map(selectedShapes => !!selectedShapes.length));

    this.isFrameSelected$ = this.store
      .select(currentAnimationId)
      .pipe(map(currentAnimationId => !!currentAnimationId));

    this.onlyHandshapes$ = this.store.select(onlyShapeType('hand-shape'));
    this.onlyTextShapes$ = this.store.select(onlyShapeType('text-shape'));
    this.onlyCircleShapes$ = this.store.select(onlyShapeType('circle-shape'));
  }
}
