import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import {
  AnimationItem,
  ArcInterval,
  CenterScaleAnimation,
  CircleShapeDescriptor,
  Coords,
  DropShadowConfig,
  GeneralShapeDescriptor,
  IncrementState,
  Scale,
  ShapeConfig,
  ShapePosition,
} from '../../../../elements/resource/types/shape.type';
import {
  RectangleController,
  RectangleDragResponse,
} from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { PrimitiveShape } from '../primitive/primitive-shape';
import { GeneralShape } from '../general/general-shape';
import { isEqual, omit } from 'lodash';
import { Ellipse } from '../primitive/ellipse-element';
import { BlurFilter, Container } from 'pixi.js';
import { Circle } from '../primitive/circle-element';
import { ShapeService } from '../../shape.service';
import { selectArcIntervalByIRI } from '../../../store/selector/editor.selector';
import {
  Easing,
  IncrementController,
} from '../../../animation/frame/increment/controller/increment.controller';

export class CircleShape extends PrimitiveShape<
  Ellipse,
  CircleShapeDescriptor
> {
  get isPathShape() {
    return true;
  }

  get origin() {
    return [this.ox, this.oy];
  }

  get centerX() {
    return this.bBox?.x + this.rx + this.dx;
  }

  get rightX() {
    return this.bBox?.x + this.rx * 2 + this.dx;
  }

  get centerY() {
    return this.bBox?.y + this.ry + this.dy;
  }

  get bottomY() {
    return this.bBox?.y + this.ry * 2 + this.dy;
  }

  set origin([x, y]: Coords) {
    this.x = x - this.rx;
    this.y = y - this.ry;
  }

  get ox() {
    return this.x + this.rx;
  }

  get oy() {
    return this.y + this.ry;
  }

  get rx() {
    return this.descriptor.rx || this.descriptor.r;
  }

  get ry() {
    return this.descriptor.ry || this.descriptor.r;
  }

  get arcStart() {
    return this.descriptor.arcStart;
  }

  get arcEnd() {
    return this.descriptor.arcEnd;
  }

  arcInterval: ArcInterval;

  get arcIntervalStart() {
    return this.arcInterval?.start;
  }

  get arcIntervalEnd() {
    return this.arcInterval?.end;
  }

  set rx(val: number) {
    this.descriptor.rx = val;
  }

  set ry(val: number) {
    this.descriptor.ry = val;
  }

  // get strokeWidth() {
  //  return this.descriptor.svgAttributes?.['stroke-width'] || 1;
  // }

  get elementAttributes() {
    return {
      x: this.rx,
      y: this.ry,
      r: this.ry,
      rx: this.rx,
      ry: this.ry,
      startAngle: this.arcIntervalStart,
      endAngle: this.arcIntervalEnd,
    };
  }

  getType(): string {
    return 'circle-shape';
  }

  makeSymmetric() {
    const r = (this.rx + this.ry) / 2;
    this.rx = r;
    this.ry = r;
  }

  constructor(
    service: ShapeService,
    data: ResourceData<CircleShapeDescriptor>,
    config?: ShapeConfig,
  ) {
    super(service, data, config);
    this.arcInterval = this.descriptor.arcInterval;

    this.init();
    this.type = ResourceType.CircleShape;

    this.subscriptions.push(
      this.store
        .select(selectArcIntervalByIRI(this.IRI))
        .subscribe(arcInterval => {
          if (!arcInterval) {
            return;
          }
          if (!isEqual(this.arcInterval, arcInterval)) {
            this.arcInterval = arcInterval;
            this.refreshElement();
          }
        }),
    );

    // this.subscriptions.push(

    // );
    this.selectedKeyEventSubscribe('r+i', () => {
      this.saveDescriptorKey('arcInterval', null);
    }),
      this.redraw();
  }

  registerOrientationPoints() {
    /*
    x -------------- x --------------- x
    |                                  |
    x                                  x
    |                                  |
    x -------------- x --------------- x
    */

    return;
    if (this.getType() == 'root-shape') {
      return;
    }

    this.bBox = {
      x: this._x,
      y: this._y,
      width: this.rx * 2,
      height: this.ry * 2,
    };

    this.clearOrientationPoints();

    const points = [
      this.centerTop,
      this.centerBottom,
      this.leftCenter,
      this.rightCenter,
    ];

    this.horizontalUpKeys = points.map(([x, y]) =>
      this.orientationService.registerHorizontalUp(this.IRI, x, y),
    );

    this.horizontalDownKeys = points.map(([x, y]) =>
      this.orientationService.registerHorizontalDown(this.IRI, x, y),
    );

    this.verticalRightKeys = points.map(([x, y]) =>
      this.orientationService.registerVerticalRight(this.IRI, x, y),
    );

    this.verticalLeftKeys = points.map(([x, y]) =>
      this.orientationService.registerVerticalLeft(this.IRI, x, y),
    );
  }

  // checkHorizontalOrientation(dx: number, dy: number) {
  //   const [height, width] = [this.ry * 2, this.rx * 2];

  //   const resultLeft = this.orientationService.checkHorizontalUp(
  //     this.IRI,
  //     [this._x, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   const resultCenter = this.orientationService.checkHorizontalUp(
  //     this.IRI,
  //     [this._x + width / 2, this._y],
  //     [dx, dy]
  //   );

  //   const resultRight = this.orientationService.checkHorizontalUp(
  //     this.IRI,
  //     [this._x + width, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   const resultLeftD = this.orientationService.checkHorizontalDown(
  //     this.IRI,
  //     [this._x, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   const resultCenterD = this.orientationService.checkHorizontalDown(
  //     this.IRI,
  //     [this._x + width / 2, this._y + height],
  //     [dx, dy]
  //   );

  //   const resultRightD = this.orientationService.checkHorizontalDown(
  //     this.IRI,
  //     [this._x + width, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   return this.checHorizontalkOrientationResults(
  //     [
  //       resultLeft,
  //       resultLeftD,
  //       resultCenter,
  //       resultCenterD,
  //       resultRight,
  //       resultRightD,
  //     ],
  //     dx,
  //     dy
  //   );
  // }

  // checkVerticalOrientation(dx: number, dy: number) {
  //   const [height, width] = [this.ry * 2, this.rx * 2];

  //   const leftTop = this.orientationService.checkVerticalLeft(
  //     this.IRI,
  //     [this._x + width / 2, this._y],
  //     [dx, dy]
  //   );

  //   const leftCenter = this.orientationService.checkVerticalLeft(
  //     this.IRI,
  //     [this._x, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   const leftBottom = this.orientationService.checkVerticalLeft(
  //     this.IRI,
  //     [this._x + width / 2, this._y + height],
  //     [dx, dy]
  //   );

  //   const rightTop = this.orientationService.checkVerticalRight(
  //     this.IRI,
  //     [this._x + width / 2, this._y],
  //     [dx, dy]
  //   );

  //   const rightCenter = this.orientationService.checkVerticalRight(
  //     this.IRI,
  //     [this._x + width, this._y + height / 2],
  //     [dx, dy]
  //   );

  //   const rightBottom = this.orientationService.checkVerticalRight(
  //     this.IRI,
  //     [this._x + width / 2, this._y + height],
  //     [dx, dy]
  //   );

  //   return this.checkVerticalOrientationResults(
  //     [leftTop, rightTop, leftCenter, rightCenter, leftBottom, rightBottom],
  //     dx,
  //     dy
  //   );
  // }

  copy(
    IRI: string,
    position: ShapePosition,
  ): GeneralShape<GeneralShapeDescriptor, {}> {
    return new CircleShape(
      this.service,
      {
        IRI,
        type: 'nw:Shape',
        relationships: {
          ...this.clone(this.relationships),
          parent: this.parent.IRI,
        },
        literals: {
          descriptor: {
            ...omit(this.descriptor, [
              'multiplication',
              '_animationsByKey',
              'code',
            ]),
            position,
          },
        },
      },
      {
        noSave: true,
      },
    );
  }

  maskCopyElement: Circle;

  setMeAsMask(maskTarget: GeneralShape, noSave = false) {
    console.log('set-me-as mask');
    maskTarget.maskedBy = this.IRI;
    if (!this.maskCopyElement) {
      this.maskCopyElement = new Circle(this, this.container, {
        ...this.elementAttributes,
        ...this.resolvedSVGAttributes,
        // noFill: true
        opacity: 0.001,
        'stroke-opacity': 0.001,
      });
    }

    maskTarget.mainContainer.mask = this.maskCopyElement.element;

    Object.values(maskTarget.multipliedShapes || {}).map(shape => {
      shape.container.mask = this.maskCopyElement.element;
    });
  }

  applyRotation(rad: number, cx?: number, cy?: number) {
    this.currentAngle = rad || 0;
    // console.log('set-rotation', rad);
    // if (rad === undefined || cx === undefined || cy === undefined) {
    //   return;
    // }

    // console.log('set-rotation', {
    //   x: this.x,
    //   y: this.y,
    //   childOffsetX: this.parent.childOffsetX,
    //   childOffsetY: this.parent.childOffsetY,
    //   rx: this.rx,
    //   rad,
    // });

    this.container.x = this.x - (this.parent.childOffsetX || 0) + this.rx;
    this.container.y = this.y - (this.parent.childOffsetY || 0) + this.rx;
    this.container.pivot.set(this.rx);
    // this.container.transform.rotation = this.degToRad(deg);
    this.container.transform.rotation = rad;
    return;
  }

  _rx: number;
  _ry: number;

  startTransformation(dx?: number, dy?: number): void {
    this._rx = this.rx;
    this._ry = this.ry;

    super.startTransformation(dx, dy);
  }

  afterInit(): void {
    super.afterInit();
    this.refreshElement();
  }

  transformation(scaleX?: number, scaleY?: number, dx = 0, dy = 0): void {
    this.rx = this._rx * scaleX;
    this.ry = this._ry * scaleY;
    super.transformation(scaleX, scaleY, dx, dy);
  }

  endTransformation(): void {
    super.endTransformation();
    this._rx = this.rx;
    this._ry = this.ry;

    this.rc.patch({
      width: 2 * this.rx,
      height: 2 * this.ry,
    });
    this.rc.hide();

    this.saveScale({
      x: 2 * this.rx,
      y: 2 * this.ry,
    });
  }

  initDropShadowElement(config: DropShadowConfig) {
    this.blurContainer?.destroy();
    if (!config) {
      return;
    }
    const { strength, margin, color } = config;
    this.blurContainer = new Container();

    this.dropShadowElement = new Ellipse(this, this.blurContainer, {
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      rx: this.rx + this.strokeWidth,
      ry: this.ry + this.strokeWidth,
      'stroke-width': margin,
      stroke: this.getColorValue(color),
      startAngle: this.arcIntervalStart,
      endAngle: this.arcIntervalEnd,
    });

    // this.container.addChildAt(this.blurContainer, 1);
    this.container.addChild(this.blurContainer);

    // this.blurContainer.setTransform(-margin , -margin);
    this.blurContainer.setTransform(0, 0);
    const filter = new BlurFilter(strength);
    this.dropShadowElement.element.filters = [filter];
  }

  updateOriginControlPoint() {
    return;
    this.controlPointControllers.__origin.patch({
      p: [this.rx, this.ry],
    });
    this.controlPoints.__origin.coords = [this.rx, this.ry];
  }

  rxIncrement: number;
  ryIncrement: number;

  centerScaleIncrementState: IncrementState<{ rx: number; ry: number }>;

  applyAnimation(id: string, duration: number): void {
    super.applyAnimation(id, duration);
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'rx':
          this.rx = value as number;
          this.refreshElement();
          break;
        case 'ry':
          this.ry = value as number;
          this.refreshElement();
          break;
      }
    });
  }

  arcIntervalStartIncrement: IncrementController;
  arcIntervalEndIncrement: IncrementController;

  prepareKeyValueAnimation(
    animation: AnimationItem,
    division: number,
    duration: number,
  ) {
    super.prepareKeyValueAnimation(animation, division, duration);
    const { key, value } = animation;

    switch (key) {
      case 'arcInterval':
        const { start, end } = value as ArcInterval;
        this.arcIntervalStartIncrement = new IncrementController(
          this.arcIntervalStart,
          start,
          division,
          Easing.SMOOTH,
        );
        this.arcIntervalEndIncrement = new IncrementController(
          this.arcIntervalEnd,
          end,
          division,
          Easing.SMOOTH,
        );

        break;
      case 'rx':
        this.rxIncrement = ((value as number) - this.rx) / division;
        break;
      case 'ry':
        this.ryIncrement = ((value as number) - this.ry) / division;
        break;
      case 'center-scale':
        const { ratio } = value as CenterScaleAnimation;
        this.centerScaleIncrementState = {
          current: 1,
          increment: (ratio - 1) / division,
          data: { rx: this.rx, ry: this.ry },
        };
        break;
      case 'dropShadow-remove':
        this.dropShadowElement.remove();
        break;
    }
  }

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);
    if (this.rxIncrement) {
      this.rx += this.rxIncrement * increment;
    }

    if (this.ryIncrement) {
      this.ry += this.ryIncrement * increment;
    }
    if (this.rxIncrement || this.ryIncrement) {
      this.refreshElement();
    }

    let current: number;
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'center-scale':
          // -- // -- //
          current = this.incrementState(
            this.centerScaleIncrementState,
            increment,
          );
          const { rx, ry } = this.centerScaleIncrementState.data;
          const [currentRx, currentRy] = [rx, ry].map(val => val * current);
          this.element.patch({
            rx: currentRx,
            ry: currentRy,
          });

          // TODO - this won't work with margin in the drop-shadow
          (this.dropShadowElement as Ellipse)?.patch({
            rx: currentRx,
            ry: currentRy,
          });

          break;
        case 'arcInterval':
          this.arcInterval = {
            start: this.arcIntervalStartIncrement.increment(increment),
            end: this.arcIntervalEndIncrement.increment(increment),
          };
          this.refreshElement();
          break;
      }
    });
  }

  endAnimation(id: string): void {
    super.endAnimation(id);
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'rx':
          this.rx = value as number;
          this.rxIncrement = null;
          this.descriptor.rx = value as number;
          break;
        case 'ry':
          this.ry = value as number;
          this.ryIncrement = null;
          this.descriptor.ry = value as number;
          break;
        case 'center-scale':
          const { rx, ry } = this.centerScaleIncrementState.data;
          const [currentRx, currentRy] = [rx, ry].map(
            val => val * this.centerScaleIncrementState.current,
          );

          this.rx = currentRx;
          this.ry = currentRy;
          this.x = this.x - (currentRx - rx) / 2;
          this.y = this.y - (currentRy - ry) / 2;
      }
    });
  }

  // refreshElement() {
  //   super.refreshElement();
  // }

  refresh() {
    super.refresh();
    this.rc?.refresh();
  }

  _resize(width: number, height: number): void {
    this.resize(width, height);
    this.rc.patch({
      width,
      height,
    });
  }

  resize(width: number, height: number): void {
    this.rx = width / 2;
    this.ry = height / 2;
    this.element.patch({ rx: this.rx, ry: this.ry });

    this.refresh();
  }

  initRC() {
    console.log('circle-shape > init-rc');
    super.initRC();
  }

  init() {
    super.init();
    this.element = new Ellipse(this, this.container, {
      startAngle: this.arcIntervalStart,
      endAngle: this.arcIntervalEnd,
      ...this.resolvedSVGAttributes,
      x: this.rx,
      y: this.ry,
      rx: this.rx,
      ry: this.ry,
    })
      // .click(() => this.clicked())
      .mouseover(() => {
        this.select({ onHover: true });
      })
      .mouseout(() => {
        this.deselect({ onHover: true });
      })
      .click(() => {
        this.clicked();
        // console.log('ellipse-clicked');
      });

    this.element
      .drag(
        (dx, dy) => {
          this.service.drag(dx, dy);
        },
        () => this.localStartDrag(),
        () => {
          this.service.endDrag();
        },
      )
      .click(() => {
        // console.log('ps.element > clicked');
        this.clicked();
      });

    if (this.editable) {
      this.rc = new RectangleController(
        this,
        {
          // offset: [this.x, this.y],
          width: 2 * this.rx,
          height: 2 * this.ry,
          // symmetric: true,
          drag: resp => this.rcDrag(resp),
          endDrag: () => this.endRCDrag(),
          mouseover: () => {
            this.select({ onHover: true });
          },
          mouseout: () => {
            this.deselect({ onHover: true });
          },
          clicked: () => {
            this.clicked();
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
        // this.container
      );
      this.rc.hide();
    }
  }

  rcDrag({ x, y, width, height }: RectangleDragResponse) {
    this.x = x;
    this.y = y;
    this.rx = width / 2;
    this.ry = height / 2;

    this._redraw({ x, y });
    this.element.patch({
      rx: this.rx,
      ry: this.ry,
    });
  }

  endRCDrag() {
    this.rc.patch({ width: this.rx * 2, height: this.ry * 2 }, true);
    this.saveScale({
      x: this.rx * 2,
      y: this.ry * 2,
    });
    this.saveTranslate();
  }

  applyScale({ x, y }: Scale) {
    this.element.patch({
      rx: x / 2,
      ry: y / 2,
    });
    this.rc?.patch({
      width: x,
      height: y,
    });
  }
}

export class IndividualCircleShape extends CircleShape {
  constructor(service: ShapeService, descriptor: CircleShapeDescriptor) {
    super(service, {
      IRI: '',
      literals: {
        descriptor,
      },
    });
  }
}
