import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Organisation } from '../../organisation/organisation.interface';
import { organisationById } from '../../organisation/organisation.selector';
import {
  loadOrganisationById,
  setCurrentOrganisationID,
} from '../../organisation/organisation.actions';

@Component({
  selector: 'nw-organisation-index',
  templateUrl: './organisation-index.component.html',
  styleUrls: ['./organisation-index.component.scss'],
})
export class OrganisationIndexComponent {
  readonly organisationId$: Observable<string>;
  readonly organisation$: Observable<Organisation>;

  mode: 'projects' | 'users' | 'settings' = 'projects';

  get isProjectsMode() {
    return this.mode == 'projects';
  }

  get isUsersMode() {
    return this.mode == 'users';
  }

  get isSettingsMode() {
    return this.mode == 'settings';
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
  ) {
    this.organisationId$ = this.route.params.pipe(
      map(({ organisationID }) => organisationID),
      filter(id => !!id),
    );

    this.organisation$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      tap(id => {
        this.store.dispatch(setCurrentOrganisationID({ ID: id }));
        this.store.dispatch(loadOrganisationById({ id }));
      }),
      mergeMap(id => this.store.select(organisationById(id))),
    );
  }
}
