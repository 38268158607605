import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { organisationReducer } from './organisation.reducer';
import { OrganisationFeature } from './organisation.actions';
import { EffectsModule } from '@ngrx/effects';
import { OrganisationEffects } from './organisation.effect';
import { OrganisationService } from './organisation.service';
import { OrganisationColorPaletteEditComponent } from './color-palette/org-color-palette-edit.component';
import { UtilComponentModule } from '../components/util/util-component.module';
import { OrganisationIndexComponent } from '../components/organisation-index/organisation-index.component';
import { OrganisationSelectorComponent } from '../components/organisation-selector/organisation-selector.component';
import { ProjectSelectorComponent } from '../components/project-selector/project-selector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UserSelectorComponent } from '../components/user-selector/user-selector.component';

@NgModule({
  declarations: [
    OrganisationColorPaletteEditComponent,
    OrganisationIndexComponent,
    OrganisationSelectorComponent,
    ProjectSelectorComponent,
    UserSelectorComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    UtilComponentModule,
    MaterialModule,
    EffectsModule.forFeature(OrganisationEffects),
    StoreModule.forFeature(OrganisationFeature, organisationReducer),
  ],
  providers: [OrganisationService],
  exports: [
    OrganisationColorPaletteEditComponent,
    OrganisationIndexComponent,
    OrganisationSelectorComponent,
    ProjectSelectorComponent,
  ],
})
export class OrganisationModule {}
