import { BlinkAnimation } from '../../../../../../elements/resource/types/shape.type';
import { GeneralShape } from '../../../general/general-shape';
import { AnimationController } from '../animation-controller';
import { LinearTrajectoryFunction } from '../linear-trajectory-function';

export class BlinkAnimationController extends AnimationController {
  get cnt() {
    return this.animation.cnt;
  }

  complete: number;
  remaining: number;

  trajectoryFunctions: LinearTrajectoryFunction[] = [];

  constructor(
    shape: GeneralShape,
    private animation: BlinkAnimation,
    division: number,
  ) {
    super(shape);
    this.complete = Math.floor(division / this.cnt);
    this.remaining = division % this.cnt;

    const blinkIntervals = this.getBlinkIntervals(division, this.cnt);
    console.log('blinkIntervals', blinkIntervals);
    for (const interval of blinkIntervals) {
      const { start, center, end } = interval;
      this.trajectoryFunctions.push(
        new LinearTrajectoryFunction([start, 1], [center, 0]),
      );
      this.trajectoryFunctions.push(
        new LinearTrajectoryFunction([center, 0], [end, 1]),
      );
    }
  }

  currentIncrement = 0;

  incrementAnimation(increment: number): void {
    this.currentIncrement += increment;
    const opacity = this.getOpacity(this.currentIncrement);
    // console.log('increment', increment, 'opacity', opacity);
    this.shape.opacity = opacity;
    // console.log('shape-opacity', this.shape.opacity);
    this.shape.updateOpacity(opacity);
  }

  getOpacity(currentIncrement: number) {
    for (const trajectoryFcn of this.trajectoryFunctions) {
      const { xStart, xEnd } = trajectoryFcn;
      if (xStart <= currentIncrement && currentIncrement < xEnd) {
        return trajectoryFcn.getValue(currentIncrement);
      }
    }
    return this.trajectoryFunctions[
      this.trajectoryFunctions.length - 1
    ].getValue(currentIncrement);
  }

  // I am gonna need n trajectories //

  getBlinkIntervals(division: number, cnt: number) {
    const intervals = super.getIntervals(division, cnt);
    return intervals.map(({ start, end }) => {
      return {
        start,
        center: start + Math.floor((end - start) * 0.8),
        end,
      };
    });
  }
}
