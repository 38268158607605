import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';
import { _attributeState } from '../../../../store/selector/editor.selector';

@Component({
  selector: 'iw-float-effect-attribute',
  templateUrl: './float-effect-attribute.component.html',
  styleUrls: ['./float-effect-attribute.component.scss'],
})
export class FloatEffectAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'floatEffect';
    this.attributeState$ = this.store.select(_attributeState('floatEffect'));
    this.attributeState$.subscribe(state => {
      // -- // -- //
    });
  }
  ngOnInit(): void {}
}
