import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent {
  @Input()
  step = 1;

  @Input()
  key: string;

  @Input()
  value: number | number[];

  @Output()
  changed = new EventEmitter<number>();

  get isArray() {
    return Array.isArray(this.value);
  }

  get _value(): number {
    return this.array[0];
  }

  get array() {
    return Array.isArray(this.value) ? this.value : [this.value as number];
  }

  constructor(public readonly cs: CanvasService) {}

  selectorOpen = false;

  inputChanged(event: Event) {
    this.changed.emit(+(event.target as HTMLInputElement).value);
  }

  selectorClicked(event: number) {
    console.log('selector-clicked');
    this.changed.emit(event);
  }
}
