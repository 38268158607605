import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  exhaustMap,
  map,
  catchError,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { UserService } from './user.service';
import {
  loadUserById,
  setUser,
  loadUsers,
  setUsers,
  createUser,
  setNewUserLoading,
  setNewlyCreatedUser,
  deleteUser,
  deleteUserBase,
  setUserBeingDeleted,
} from './user.actions';
import { Action, Store } from '@ngrx/store';
import { currentOrganisationID } from '../organisation/organisation.selector';
import { User } from './user.interface';
import { setUserRoleInOrganisation } from '../organisation/organisation.actions';
import { RoleTypes } from '../organisation/role.interface';

@Injectable()
export class UserEffects {
  readonly loadUsers$: Observable<Action>;
  readonly loadUser$: Observable<Action>;
  readonly createUser$: Observable<Action>;

  readonly deleteUser$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly store: Store,
  ) {
    this.loadUsers$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadUsers),
        exhaustMap(() => this.userService.listUsers()),
        map(users => setUsers({ users })),
      ),
    );

    this.deleteUser$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteUser),
        withLatestFrom(this.store.select(currentOrganisationID)),
        exhaustMap(([{ user }, currentOrganisationId]) =>
          this.userService
            .deleteUser(user, currentOrganisationId)
            .pipe(map(() => [user, currentOrganisationId] as [User, string])),
        ),
        switchMap(([user, organisationId]) => [
          deleteUserBase({ user }),
          // TODO - on the long run it should be removed from the org
          setUserBeingDeleted({ user: null }),
        ]),
      ),
    );

    this.loadUser$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadUserById),
        exhaustMap(({ id }) => this.userService.loadUserById(id)),
        map(user => setUser({ user })),
      ),
    );

    this.createUser$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createUser),
        withLatestFrom(this.store.select(currentOrganisationID)),
        exhaustMap(([{ user, role }, currentOrganisationID]) =>
          this.userService
            .createUser(user, role, currentOrganisationID)
            .pipe(
              map(
                user =>
                  [user, currentOrganisationID, role] as [
                    User,
                    string,
                    RoleTypes,
                  ],
              ),
            ),
        ),
        switchMap(([user, currentOrganisationID, role]) => [
          setUser({ user }),
          setUserRoleInOrganisation({
            organisationId: currentOrganisationID,
            userId: user.id,
            role,
          }),
          setNewUserLoading({ value: false }),
          setNewlyCreatedUser({ id: user.id, time: performance.now() }),
        ]),
        catchError(error => {
          console.error('Error creating user:', error);
          return of(setNewUserLoading({ value: false })); // Optionally return an action or an empty observable
        }),
      ),
    );
  }
}
