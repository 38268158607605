import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AnimationFrameObject } from '../../frame/animation-frame-object';
import { CanvasService } from '../../../../services/canvas/canvas.service';
import { Observable, Subscription } from 'rxjs';
import { FunctionAnimationFrameObject } from '../../frame/function/function-animation-frame';
import { SubSceneTransitionFrameObject } from '../../frame/subscene-transition.frame';
import { isThereAnimationByFrame } from '../../store/animation.selector';
import { SoundAnimationFrameObject } from '../../frame/sound-animation-frame';
import { CanvasTransformFrameObject } from '../../frame/canvas-transform-frame';

@Component({
  selector: 'nw-animation-frame-item',
  templateUrl: './animation-frame-item.component.html',
  styleUrls: ['./animation-frame-item.component.scss'],
})
export class AnimationFrameItemComponent implements OnInit, OnDestroy {
  @ViewChild('duration')
  durationInput: ElementRef;

  @Input()
  frame: AnimationFrameObject;

  get subSceneTransitionFrame() {
    return this.frame as SubSceneTransitionFrameObject;
  }

  get canvasTransformFrame() {
    return this.frame as CanvasTransformFrameObject;
  }

  get soundFrame() {
    return this.frame as SoundAnimationFrameObject;
  }

  isThereAnimation$: Observable<boolean>;

  selectedFunction: AnimationFrameObject;
  subscriptions: Subscription[] = [];

  @Input()
  set focusInput(val: boolean) {
    if (val) {
      this.focusInputElement();
    }
  }

  get functionFrame() {
    return this.frame as FunctionAnimationFrameObject;
  }

  get mainFrame() {
    return this.frame.mainFrame;
  }

  get selectedObject() {
    return this.mainFrame?.store.__selected__;
  }

  get isSelectedAmongMany() {
    return (
      this.mainFrame?._selected[this.frame.id] &&
      Object.keys(this.mainFrame._selected).length > 1
    );
  }

  get functionInstance() {
    return (this.frame as FunctionAnimationFrameObject).functionInstance;
  }

  get functionOpen() {
    return this.frame.open;
  }

  constructor(private readonly cs: CanvasService) {}

  ngOnInit(): void {
    this.isThereAnimation$ = this.cs.store.select(
      isThereAnimationByFrame(this.frame.id),
    );
  }

  mouseOver() {
    this.functionFrame?.targetShape?.select({ onHover: true });
  }

  mouseOut() {
    this.functionFrame?.targetShape?.deselect({ onHover: true });
  }

  ngOnDestroy(): void {
    // this.subscriptions.map(sub => sub.unsubscribe());
  }

  clickHandler() {
    // this.frame.select();
    this.frame.clicked();
  }

  focusInputElement() {
    // durationInput should not be coalesced IMO
    console.log('focus-input-element');
    setTimeout(() => this.durationInput?.nativeElement?.focus(), 0);
  }

  max(...params: number[]) {
    return Math.max(...params);
  }

  inputKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.selectedObject?.edit) {
      console.log('selected.object.duration', this.selectedObject.duration);
      console.log('frame.duration', this.frame.duration);
      this.selectedObject.edit = false;
      this.selectedObject.preDelayMode = false;
      if (this.mainFrame.fcnParent) {
        // const fcnParent = this.mainFrame
        //   .fcnParent as FunctionAnimationFrameObject;
        // fcnParent.saveFunction(this.mainFrame.frameObject);
        // fcnParent.functionInstance.store[this.frame.id].edit = false;
        // fcnParent.functionInstance.store[this.frame.id].preDelayMode = false;
        // fcnParent.functionInstance.store[this.frame.id].select();
        // -- // -- //
        // this.cs.consumeKeyEvent('Enter');
        // return;
        this.mainFrame.fcnParent.save();
      }
      this.mainFrame.calcPositions();
      this.save();
      console.log('------ here-we-are ----');
      return;
    }
    // This should be done with event.stopPropagation || event.preventDefault //
    this.cs.consumeKeyEvent(event.code);
  }

  save() {
    this.frame.save();
  }

  click() {
    // it will be a return when we're in different animation-edit mode
    if (this.cs.isShiftPressed) {
      this.frame.clicked();
      return;
    }

    this.frame.select();
  }
}
