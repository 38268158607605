import versionFile from '../assets/version.json';

export const environment = {
  production: true,
  version: versionFile.version,
  backend: {
    url: 'https://0ocmfqd590.execute-api.us-east-1.amazonaws.com',
  },
  cognito: {
    userPoolId: 'us-east-1_k66729S4A',
    userPoolWebClientId: '6hg8cfuu23qgaao1phjisth3vs',
    oauth: {
      domain: 'inwedio.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://app.inwedio.com',
      redirectSignOut: 'https://app.inwedio.com/signIn',
      clientId:
        '308486427799-ohn1b558kpgejft8ha9r4vf179ncjt2t.apps.googleusercontent.com',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
