import { cloneDeep } from 'lodash';
import { Container } from 'pixi.js';
import {
  Coords,
  ShapeControlPointPosition,
  ShapeDragParams,
} from '../../resource/types/shape.type';
import { GeneralShape } from '../../../element-editor/shape/shapes/general/general-shape';
import { Rectanglelement } from '../../../element-editor/shape/shapes/primitive/rectangle-element';
import { PointController } from '../point-controller/point-controller';
import { BaseVector, Vector } from '../../base/vector/vector';

export type RCPoint =
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom'
  | 'center-bottom'
  | 'center'
  | 'center-top'
  | 'left-center'
  | 'right-center';

export interface RectangleDragResponse {
  x: number;
  y: number;
  width: number;
  height: number;
  dx?: number;
  dy?: number;
  dw?: number;
  dh?: number;
  _dx: number;
  _dy: number;

  point?: RCPoint;
}

export interface RectangleControllerConfig {
  offset?: Coords;
  selectedContainerMode?: boolean;
  width: number;
  height: number;
  symmetric?: boolean;
  centerBased?: boolean;
  showRectangle?: boolean;
  noScale?: boolean;
  startDrag?: (point?: ShapeControlPointPosition) => void;
  drag: (resp: RectangleDragResponse) => void;
  endDrag: (point?: RCPoint) => void;
  clicked?: () => void;
  mouseout?: () => void;
  mouseover?: () => void;
  noRect?: boolean;
  rotation?: number;
  noDrag?: boolean;
  dragParams?: ShapeDragParams;
  _startDrag?: () => void;
  _drag?: (dx?: number, dy?: number) => void;
  _endDrag?: () => void;
  noFill?: boolean;
  skipRC?: Partial<Record<RCPoint, boolean>>
}

export class RectangleController {
  pcLeftTop: PointController;
  pcLeftCenter: PointController;
  pcLeftBottom: PointController;

  pcCenterTop: PointController;
  pcCenter: PointController;
  pcCenterBottom: PointController;

  pcRightTop: PointController;
  pcRightCenter: PointController;
  pcRightBottom: PointController;

  rect: Rectanglelement;

  unMovedLengths: Coords;

  diff: Coords;
  currentLengths: Coords;

  get cs() {
    return this.parent.cs;
  }

  get service() {
    return this.parent.service;
  }

  get os() {
    return this.parent.orientationService;
  }

  get rotation() {
    return this.config.rotation;
  }

  get width() {
    return this.config.width;
  }

  set width(val: number) {
    this.config.width = val;
  }

  get height() {
    return this.config.height;
  }

  set height(val: number) {
    this.config.height = val;
  }

  get position() {
    return [this.x, this.y];
  }

  get x() {
    return this.config.offset?.[0] || 0;
  }

  get y() {
    return this.config.offset?.[1] || 0;
  }

  set x(val: number) {
    this.config.offset[0] = val;
  }

  set y(val: number) {
    this.config.offset[1] = val;
  }

  get pcs() {
    return [
      this.pcLeftTop,
      this.pcLeftCenter,
      this.pcLeftBottom,
      this.pcCenterTop,
      this.pcCenter,
      this.pcCenterBottom,
      this.pcRightTop,
      this.pcRightCenter,
      this.pcRightBottom,
    ].filter(rc => !!rc);
  }

  get symmetric() {
    return this.config.symmetric;
  }

  get centerBased() {
    return this.cs.isShiftPressed;
  }

  unMovedX: number;
  unMovedY: number;

  dx: number;
  dy: number;

  dw: number;
  dh: number;

  offsetX = 0;
  offsetY = 0;

  get noScale() {
    return this.config.noScale;
  }

  get skipRC() {
    return this.config.skipRC || {}
  }

  get canvasScale() {
    return this.noScale ? 1 : this.cs.canvasScale;
  }

  get inprogress() {
    return !!this.pcs.find(pc => pc.inProgress);
  }

  get selectedContainerMode() {
    return this.config.selectedContainerMode;
  }
  _dx: number;
  _dy: number;
  leftTop: Vector;
  leftBottom: Vector;
  rightTop: Vector;
  rightBottom: Vector;
  shapePosition: Vector;

  // The left-top vector's new position wrt. to the original origin through rotation-drag
  newLeftTop: Vector;
  newLeftAngle: number;

  newLeftBottom: Vector;
  newrightTop: Vector;
  newRightBottom: Vector;
  newShapePosition: Vector;
  newOrigin: Coords;
  dLeftTop: Coords;
  constructor(
    public parent: GeneralShape,
    public config: RectangleControllerConfig,
    circleContainer: Container,
    auxCircleContainer: Container,
    rectangleContainer?: Container,
  ) {
    this.rect = new Rectanglelement(
      this,
      rectangleContainer || parent.containerForRc,
      // parent.containerForRc || rectangleContainer,
      {
        position: {
          x: this.x,
          y: this.y,
        },
        width: this.width,
        height: this.height,
        stroke: '#000',
        'stroke-opacity': this.config.noRect ? 0 : 1,
        'stroke-width': 1,
        // noFill: this.config.noFill,
        noFill: true,
      },
    )
      .click(() => {
        this.config.clicked?.();
      })
      .mouseover(() => {
        this.isMouseOver = true;
        this.mouseover();
      })
      .mouseout(() => {
        this.isMouseOver = false;
        this.mouseout();
      });

    if (!this.config.noDrag) {
      this.rect.drag(
        (dx, dy) => {
          this.service.drag(dx, dy);
          this.config._drag?.(dx, dy);
        },
        () =>
          this.service.startDrag(
            this.parent.getType() == 'root-shape' ? null : this.parent,
          ),
        () => {
          this.service.endDrag(this.config.dragParams);
        },
      );
    }

    if (this.rotation) {
      this.leftTop = new BaseVector([-this.width / 2, -this.height / 2]);
      this.leftBottom = new BaseVector([-this.width / 2, this.height / 2]);

      this.rightTop = new BaseVector([this.width / 2, -this.height / 2]);
      this.rightBottom = new BaseVector([this.width / 2, this.height / 2]);

      [this.leftTop, this.leftBottom, this.rightTop, this.rightBottom].map(v =>
        v.rotate(this.rotation),
      );

      this.shapePosition = this.leftTop.copy();
      this.shapePosition.rotate(-this.rotation);
    }

    this.diff = [0, 0];
    this.currentLengths = cloneDeep([this.width, this.height]);

    const start = (point?: ShapeControlPointPosition) => {
      this.dx = 0;
      this.dy = 0;
      this.dw = 0;
      this.dh = 0;
      this.unMovedX = this.parent?.x || 0;
      this.unMovedY = this.parent?.y || 0;
      this.config.startDrag?.(point);
    };


    if (!this.skipRC?.['left-top']) {
      this.pcLeftTop = new PointController(
        this,
        {
          p: [this.x, this.y],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          start: () => start('left-top'),
          drag: ({ dx, dy }) => {
            this._dx = dx;
            this._dy = dy;

            if (this.rotation) {
              const [dw, dh, dxPos, dyPos] = this.pcDrag(dx, dy, 'left-top');

              this.dw = dw;
              this.dh = dh;
              this.dx = dxPos;
              this.dy = dyPos;
              return this.drag('left-top');
            }

            const [w, h] = this.unMovedLengths;
            if (this.symmetric) {
              if (dx > dy) {
                dy = dx;
              } else {
                dx = dy;
              }
            }

            const [newDx, vLines] = this.parent.checkHorizontal(
              [this.parent._x, this.parent._y, 0],
              [this.parent._x, this.parent._y + h, h],
              [dx, dy],
            );

            const [newDy, hLines] = this.parent.checkVertical(
              [this.parent._x, this.parent._y, 0],
              [this.parent._x + w, this.parent._y, w],
              [dx, dy],
            );

            if (vLines) {
              this.os.showVerticalOrientationLines(
                this.parent,
                vLines,
                newDx,
                newDy,
              );
            }

            if (hLines) {
              this.os.showHorizontalOrientationLines(
                this.parent,
                hLines,
                newDx,
                newDy,
              );
            }

            this.dw = -newDx;
            this.dh = -newDy;
            this.dx = newDx;
            this.dy = newDy;

            this.drag('left-top');
          },
          end: () => this.endPointDrag('left-top'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }
    // -- // -- // -- // -- // -- //
    if (!this.skipRC?.['left-center']) {
      this.pcLeftCenter = new PointController(
        this,
        {
          p: [this.x, this.y + this.height / 2],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            point: 'left-center',
          },
          start: () => start('left-center'),
          drag: ({ dx, dy }) => {
            this._dx = dx;
            this._dy = dy;

            this.dw = -dx;
            this.dh = 0;
            this.dx = dx;
            this.dy = 0;
            this.drag('left-center');
          },
          end: () => this.endPointDrag('left-center'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['left-bottom']) {
      this.pcLeftBottom = new PointController(
        this,
        {
          p: [this.x, this.y + this.height],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          start: () => start('left-bottom'),
          drag: ({ dx, dy }) => {
            this._dx = dx;
            this._dy = dy;

            if (this.rotation) {
              const [dw, dh, dxPos, dyPos] = this.pcDrag(dx, dy, 'left-bottom');

              this.dw = dw;
              this.dh = dh;
              this.dx = dxPos;
              this.dy = dyPos;
              return this.drag('left-bottom');
            }

            const [w, h] = this.unMovedLengths;

            this.diff = [dx, 0];
            if (this.symmetric) {
              dy = -dx;
            }

            const [newDx, vLines] = this.parent.checkHorizontal(
              [this.parent._x, this.parent._y, -h],
              [this.parent._x, this.parent._y + h, 0],
              [dx, dy],
            );
            const [newDy, hLines] = this.parent.checkVertical(
              [this.parent._x, this.parent._y + h, 0],
              [this.parent._x + w, this.parent._y + h, w],
              [dx, dy],
            );

            this.os.showVerticalOrientationLines(
              this.parent,
              vLines,
              newDx,
              newDy,
            );

            this.os.showHorizontalOrientationLines(
              this.parent,
              hLines,
              newDx,
              newDy,
            );

            if (this.centerBased) {
              this.dx = newDx;
              this.dy = -newDy;
            }

            this.dx = newDx;
            this.dy = 0;
            this.dw = -newDx;
            this.dh = newDy;

            this.drag('left-bottom');
          },
          end: () => this.endPointDrag('left-bottom'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );  
    }
  
    if (!this.skipRC['center-top']) {
      this.pcCenterTop = new PointController(
        this,
        {
          p: [this.x + this.width / 2, this.y],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            point: 'center-top',
          },
          start: () => {
            start('center-top');
          },
          drag: ({ dy }) => {
            this.dh = -dy;
            this.dy = dy;
            this.pcCenterTop.patch({
              p: [this.x + this.width / 2, this.y],
            });
            this.drag('center-top');
          },
          end: () => this.endPointDrag('center-top'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['center']) {
      this.pcCenter = new PointController(
        this,
        {
          p: [this.x + this.width / 2, this.y + this.height / 2],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            point: 'center',
          },
          start: () => {
            if (this.selectedContainerMode) {
              this.unMovedX = this.service.groupRCContainer.x;
              this.unMovedY = this.service.groupRCContainer.y;
              this.service.startDrag();
            } else {
              this.parent.startBaseDrag();
            }
          },
          drag: ({ dx, dy }) => {
            if (this.selectedContainerMode) {
              this.service.drag(dx, dy);
              this.service.groupRCContainer.setTransform(
                this.unMovedX + dx,
                this.unMovedY + dy,
              );
            } else {
              this.parent.drag(dx, dy);
            }
            this.pcCenter.patch({
              p: [this.x + this.width / 2, this.y + this.height / 2],
            });
          },
          end: () =>
            this.config.selectedContainerMode
              ? this.service.endDrag()
              : this.parent.endDrag(),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['center-bottom']) {
      this.pcCenterBottom = new PointController(
        this,
        {
          p: [this.x + this.width / 2, this.y + this.height],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            point: 'center-bottom',
          },
          start: () => {
            start('center-bottom');
          },
          drag: ({ dy }) => {
            this.dh = dy;
            this.dy = 0;
            this.pcCenterTop.patch({
              p: [this.x + this.width / 2, this.y + this.height],
            });
            this.drag('center-bottom');
          },
          end: () => this.endPointDrag('center-bottom'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['right-top']) {
      this.pcRightTop = new PointController(
        this,
        {
          p: [this.x + this.width, this.y],
          showCircle: true,
          noScale: this.noScale,
          updateCircle: true,
          // rotation: this.rotation,
          start: () => start('right-top'),
          drag: ({ dx, dy }) => {
            this._dx = dx;
            this._dy = dy;

            if (this.rotation) {
              const [dw, dh, dxPos, dyPos] = this.pcDrag(dx, dy, 'right-top');

              this.dw = dw;
              this.dh = dh;
              this.dx = dxPos;
              this.dy = dyPos;
              return this.drag('right-top');
            }
            const [w, h] = this.unMovedLengths;
            this.diff = [0, dy];

            if (this.symmetric) {
              dx = -dy;
            }

            const [newDx, vLines] = this.parent.checkHorizontal(
              [this.parent._x + w, this.parent._y, 0],
              [this.parent._x + w, this.parent._y + h, h],
              [dx, dy],
            );

            const [newDy, hLines] = this.parent.checkVertical(
              [this.parent._x, this.parent._y, w],
              [this.parent._x + w, this.parent._y, 0],
              [dx, dy],
            );

            this.os.showVerticalOrientationLines(
              this.parent,
              vLines,
              newDx,
              newDy,
            );

            this.os.showHorizontalOrientationLines(
              this.parent,
              hLines,
              newDx,
              newDy,
            );

            this.dx = 0;

            if (this.centerBased) {
              this.dx = -newDx;
            }

            this.dy = newDy;
            this.dw = newDx;
            this.dh = -newDy;

            this.drag('right-top');
          },
          end: () => this.endPointDrag('right-top'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['right-center']) {
      this.pcRightCenter = new PointController(
        this,
        {
          p: [this.x + this.width, this.y + this.height / 2],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            point: 'right-center',
          },
          start: () => start('right-center'),
          drag: ({ dx }) => {
            this.dw = dx;
            this.drag('right-center');
          },
          end: () => this.endPointDrag('right-center'),
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    if (!this.skipRC['right-bottom']) {
      this.pcRightBottom = new PointController(
        this,
        {
          p: [this.x + this.width, this.y + this.height],
          showCircle: true,
          // rotation: this.rotation,
          noScale: this.noScale,
          start: () => start('right-bottom'),
          drag: ({ dx, dy }) => {
            // if (this.symmetric) {
            //   if (dx > dy) {
            //     dy = dx;
            //   } else {
            //     dx = dy;
            //   }
            // }
            this._dx = dx;
            this._dy = dy;

            if (this.rotation) {
              const [dw, dh, dxPos, dyPos] = this.pcDrag(dx, dy, 'right-bottom');

              this.dw = dw;
              this.dh = dh;
              this.dx = dxPos;
              this.dy = dyPos;
              return this.drag('right-bottom');
            }

            const [w, h] = this.unMovedLengths;

            const [newDx, vLines] = this.parent.checkHorizontal(
              [this.parent._x + w, this.parent._y, h],
              [this.parent._x + w, this.parent._y + h, 0],
              [dx, dy],
            );

            const [newDy, hLines] = this.parent.checkVertical(
              [this.parent._x, this.parent._y + h, w],
              [this.parent._x + w, this.parent._y + h, 0],
              [dx, dy],
            );

            this.os.showVerticalOrientationLines(
              this.parent,
              vLines,
              newDx,
              newDy,
            );

            this.os.showHorizontalOrientationLines(
              this.parent,
              hLines,
              newDx,
              newDy,
            );

            this.dx = 0;
            this.dy = 0;

            if (this.centerBased) {
              this.dx = -newDx;
              this.dy = -newDy;
            }

            // -- // -- // -- // -- // console.log({ newDx, newDy }); // -- // -- // -- // -- //

            this.dw = newDx;
            this.dh = newDy;

            this.drag('right-bottom');
          },
          end: () => this.endPointDrag('right-bottom'),
          mouseout: () => {
            this.mouseout();
          },
          mouseover: () => {
            this.mouseover();
          },
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    this.saveUnmoved();
    this.zoomUpdate();
  }

  pcDrag(dx: number, dy: number, point: RCPoint) {
    let _dx: number, _dy: number;

    const [dw, dh] = this.getDwDhByRoation(dx, dy, point);

    const [dxOrigin, dyOrigin] = [dx, dy].map(val => val / 2);
    switch (point) {
      case 'left-top':
        this.newLeftTop = new BaseVector([
          this.leftTop.x + dx,
          this.leftTop.y + dy,
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);
        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;

      case 'left-bottom':
        this.newOrigin = [dxOrigin, dyOrigin];

        const [dLeftTopX, dLeftTopY] = [
          dw * Math.cos(this.rotation),
          dw * Math.sin(this.rotation),
        ];

        // The LEFT-TOP and the RIGHT-BOTTOM changes
        this.newLeftTop = new BaseVector([
          this.leftTop.x - dLeftTopX,
          this.leftTop.y - dLeftTopY,
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);

        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;
      case 'right-bottom':
        this.newOrigin = [dxOrigin, dyOrigin];

        this.newLeftTop = new BaseVector([
          this.leftTop.x - dxOrigin,
          this.leftTop.y - dyOrigin,
        ]);

        this.newLeftTop.rotate(-this.rotation);

        _dx = dxOrigin + this.newLeftTop.x - this.shapePosition.x;
        _dy = dyOrigin + this.newLeftTop.y - this.shapePosition.y;
        break;
      case 'right-top':
        // -- // -- //
        this.dLeftTop = [
          -dh * Math.cos(-this.rotation),
          -dh * Math.sin(-this.rotation),
        ];

        // console.log({ dxOrigin, dyOrigin });
        this.newLeftTop = new BaseVector([
          this.leftTop.x + this.dLeftTop[0],
          this.leftTop.y + this.dLeftTop[1],
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);

        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;
      default:
        break;
    }

    return [dw, dh, _dx, _dy];
  }

  getDwDhByRoation(dx: number, dy: number, point: RCPoint) {
    let angle = Math.atan(dy / dx);

    if (dx < 0) {
      if (dy >= 0) {
        angle += Math.PI;
      } else {
        angle -= Math.PI;
      }
    }
    const diffAngle = angle - this.rotation;
    const l = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

    // console.log('---------- getDwDh ------------', { l, diffAngle });
    const [dw, dh] = [Math.cos(diffAngle) * l, Math.sin(diffAngle) * l];

    switch (point) {
      case 'left-top':
        return [-dw, -dh];
      case 'left-bottom':
        return [-dw, dh];
      case 'right-bottom':
        return [dw, dh];
      case 'right-top':
        return [dw, -dh];
      default:
        return [0, 0];
    }
    // -- // -- // -- //
  }

  getDxDyByRotation(dx: number, dy: number, point: RCPoint) {
    // -- // -- // -- // -- // -- // -- //
  }

  hidePointControllers() {
    this.pcs.map(pc => pc.hide(true));
  }

  isMouseOver = false;

  mouseover() {
    if (this.inprogress) {
      return;
    }
    this.config.mouseover?.();
  }

  mouseout() {
    //setTimeout(() => {
    if (this.pcs.find(pc => pc.mouseIsOver)) {
      return;
    }

    if (this.inprogress) {
      return;
    }
    this.config.mouseout?.();
    // }, 5);
  }

  pcMouseOut() {
    // setTimeout(() => {
    // -- // -- // -- // -- //
    if (this.isMouseOver) {
      console.log('rc > isMouseOver yoooo!');
      return;
    }

    this.config.mouseout?.();
    //}, 5);
  }

  patch(config: Partial<RectangleControllerConfig>, refresh = false) {
    Object.keys(config).map(key => (this.config[key] = config[key]));

    // console.log('rc-patch', config); //

    // The width and height is updated in the case an ongoing animation
    if (config.width) {
      this.currentLengths[0] = this.width;
      this.saveUnmoved();
    }
    if (config.height) {
      this.currentLengths[1] = this.height;
      this.saveUnmoved();
    }

    if (refresh) {
      this.refresh();
    }
  }

  updateWH(w: number, h: number) {
    this.config.width = w;
    this.config.height = h;
    this.refresh();
  }

  hide(force = false) {
    // Dirthy hack but works - maybe I can work with the fill: transparent somehow
    this.rect.hide();
    this.pcs.map(pc => pc.hide(force));
  }

  show() {
    this.zoomUpdate();
    this.refresh();
    this.rect.show();
    this.pcs.map(pc => pc.show());
  }

  get visible() {
    return this.rect.visible;
  }

  remove() {
    this.rect.remove();
    this.pcs.map(pc => pc.remove());
  }

  drag(point: RCPoint) {
    const [w, h] = this.unMovedLengths;

    const multiplier = this.centerBased ? 2 : 1;

    this.currentLengths = [w + this.dw * multiplier, h + this.dh * multiplier];

    const [width, height] = this.currentLengths;
    this.refreshRectangle(width, height);

    // We need to update them because by their own they do not affect each other //

    // this.pcLeftTop.patch({ p: [this.x, this.y] });
    // this.pcRightTop.patch({ p: [this.x + width, this.y] });
    // this.pcRightBottom.patch({ p: [this.x + width, this.y + height] });
    // this.pcLeftBottom.patch({ p: [this.x, this.y + height] });

    this.updatePointControllers(width, height);

    return this.config.drag({
      x: this.unMovedX + this.dx,
      y: this.unMovedY + this.dy,
      width,
      height,
      dx: this.dx,
      dy: this.dy,
      dw: this.dw,
      dh: this.dh,
      _dx: this._dx,
      _dy: this._dy,
      point,
    });
  }

  refresh() {
    const [width, height] = [this.width, this.height];

    this.refreshRectangle(width, height);

    this.updatePointControllers(width, height);

    // this.pcRightTop.patch({ p: [width, 0] }); //
    // this.pcRightBottom.patch({ p: [width, height] }); //
    // this.pcLeftBottom.patch({ p: [0, height] }); //

    this.pcs.map(pc => pc.refresh());
  }

  updatePointControllers(width: number, height: number) {
    // this.pcLeftTop.patch({ p: [this.x, this.y], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcRightTop.patch({ p: [this.x + width, this.y], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcRightBottom.patch({ p: [this.x + width, this.y + height], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcLeftBottom.patch({ p: [this.x, this.y + height], pOffset: [-this.offsetX, -this.offsetY] });

    const pOffset: Coords = [-this.offsetX, -this.offsetY];

    // --------- left --------- //
    this.pcLeftTop?.patch({
      p: [this.x, this.y],
      pOffset,
    });

    this.pcLeftCenter?.patch({
      p: [this.x, this.y + height / 2],
      pOffset,
    });

    this.pcLeftBottom?.patch({
      p: [this.x, this.y + height],
      pOffset,
    });

    // --------- center --------- //

    this.pcCenterTop?.patch({
      p: [this.x + width / 2, this.y],
    });
    this.pcCenter?.patch({
      p: [this.x + width / 2, this.y + height / 2],
      pOffset,
    });
    this.pcCenterBottom?.patch({
      p: [this.x + width / 2, this.y + height],
    });

    // --------- right --------- //
    this.pcRightCenter?.patch({
      p: [this.x + width, this.y + height / 2],
      pOffset,
    });

    this.pcRightTop?.patch({
      p: [this.x + width, this.y],
      pOffset,
    });

    this.pcRightBottom?.patch({
      p: [this.x + width, this.y + height],
      pOffset,
    });
  }

  refreshRectangle(width: number, height: number) {
    let x = 0;
    let y = 0;
    if (width < 0) {
      x = width;
    }
    if (height < 0) {
      y = height;
    }

    this.rect.patch({
      // position: { x: this.x + x - this.dw, y: this.y + y - this.dh },
      position: { x: this.x + x, y: this.y + y },
      width: Math.abs(width),
      height: Math.abs(height),
      'stroke-width': 1 / this.canvasScale,
      stroke: '#000',
    });
  }

  saveUnmoved() {
    this.unMovedLengths = cloneDeep(this.currentLengths);
  }

  endPointDrag(point: RCPoint) {
    this._dx = undefined;
    this._dy = undefined;
    this.diff = [0, 0];
    this.saveUnmoved();
    this.config.endDrag(point);
    this.parent.hideOrientationLines();
  }

  zoomUpdate() {
    this.rect?.patch({
      'stroke-width': 1 / this.canvasScale,
    });
    this.pcs.map(pc => pc.zoomUpdate());
  }
}
