import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './user.reducer';
import { UserFeature } from './user.actions';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user.effect';
import { UserService } from './user.service';

@NgModule({
  imports: [
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature(UserFeature, userReducer),
  ],
  providers: [UserService],
})
export class UserModule {}
