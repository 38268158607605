<div class="function-frame-component flex-column gap-4">
  <div class="flex-row space-between">
    <div class="bold">Function</div>
  </div>
  <div class="w-100 flex-row space-between">
    <div>Name</div>
    <div>{{ frame.function }}</div>
  </div>
  <div
    *ngIf="frame.targetShape; else openButton"
    class="w-100 flex-row space-between"
  >
    <div>Target</div>
    <div>{{ frame.targetShape.label }}</div>
  </div>
  <ng-template #openButton>
    <div
      class="pointer"
      style="padding: 2px 6px; border: 1px solid black; border-radius: 2px"
      (click)="frame.open ? frame.closeFrame() : frame.openFrame()"
    >
      {{ frame.open ? 'Close' : 'Open' }}
    </div>
  </ng-template>
  <!-- <div class="flex-row space-between">
    <div>Type</div>
    <div>Function</div>
  </div> -->
</div>
