import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { ShapeService } from '../../shape/shape.service';

@Component({
  selector: 'nw-base-shape-control-panel',
  templateUrl: './base-shape-control-panel.component.html',
  styleUrls: ['./base-shape-control-panel.component.scss'],
})
export class BaseShapeControlPanelComponent implements OnInit {
  constructor(
    readonly cs: CanvasService,
    private shapeService: ShapeService,
  ) {}

  get rectangleDisabled() {
    return !!this.cs.shapeAddMode && this.cs.shapeAddMode != 'rectangle';
  }

  get circleDisabled() {
    return !!this.cs.shapeAddMode && this.cs.shapeAddMode != 'circle';
  }

  get pathShapeDisabled() {
    return !!this.cs.shapeAddMode && this.cs.shapeAddMode != 'path';
  }

  get textShapeDisabled() {
    return !!this.cs.shapeAddMode && this.cs.shapeAddMode != 'text';
  }

  ngOnInit(): void {}

  addRectangle() {
    this.cs.shapeAddMode = 'rectangle';
  }

  addCircle() {
    this.cs.shapeAddMode = 'circle';
  }

  addPathShape() {
    this.cs.shapeAddMode = 'path';
  }

  addTextShape() {
    this.cs.shapeAddMode = 'text';
  }

  // pathShapeAddMode

  addHandShape() {
    this.shapeService.addHandShape(100, 100);
  }

  addShadowShape() {
    // this.cs.previewShape?.addPrimitiveShape('shadow-shape');
  }
}
