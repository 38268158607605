<div
  class="text-attributes-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4"
  >
    <div class="fs-14 pv-8">Text</div>
    <!-- Text -->
    <textarea
      [value]="attributeState.value?.text"
      (keyup)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      (change)="changeText($event)"
      class="mv-2"
    >
    </textarea>

    <div class="w-100 flex-row space-between mv-2">
      <div class="fs-12">font-size</div>
      <nw-number-input
        [value]="attributeState.value.fontSize"
        (changed)="updateSubAttributeValue('fontSize', $event)"
      ></nw-number-input>
    </div>
    <div class="w-100 flex-row space-between mv-2">
      <div class="fs-12">letter-spacing</div>
      <nw-number-input
        [value]="attributeState.value.letterSpacing"
        (changed)="updateSubAttributeValue('letterSpacing', $event)"
      ></nw-number-input>
    </div>
  </div>
</div>
