import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { HomeService } from './home.service';
import { HomeEffects } from './home.effects';

@NgModule({
  imports: [EffectsModule.forFeature([HomeEffects])],
  providers: [HomeService],
})
export class HomeModule {}
