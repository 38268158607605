import { Graphics } from 'pixi.js';
import { SVG } from './svg-element';

export class Line extends SVG {
  initElement() {
    this._element = new Graphics();
  }

  updateAttr() {
    super.updateAttr();
    this.element.lineTo(this.x, this.y).closePath().endFill();
  }
}
