import { TypeDef } from "../elements/resource/types/shape.type";

export interface Expression {
  type?: ExpressionType;
  expr?: string;
  value?: any;
  minus?: boolean;
  returnType?: TypeDef;
  next?: Expression;
  operations?: string[];
  params?: Expression[];
}

export interface ChainExpression extends Expression {
  type: ExpressionType.Chain;
  chain?: Expression[];
}

export interface PathNodeExpression extends Expression {
  type: ExpressionType.PathNode;
  chain?: Expression[];
}

export interface FieldExpression extends Expression {
  type: ExpressionType.Field;
  filter?: ComparisonExpression;
}

export interface MethodExpression extends Expression {
  type: ExpressionType.Method;
  new?: boolean;
}

export interface ObjectExpression extends Expression {
  type: ExpressionType.Object;
  spread?: boolean;
  object?: Record<string, Expression>;
  objects?: (ObjectExpression | Record<string, Expression>)[];
}

export interface EquationExpression extends Expression {
  push?: boolean;
  target: Expression;
  value: Expression;
}

export type Operators = '==' | '!=' | '<' | '>' | '<=' | '>=';

export interface ComparisonExpression {
  type: ExpressionType.Comparison;
  left: Expression;
  right: Expression;
  operator: Operators;
}

export enum ExpressionType {
  Arithmetic = 'arithmetic',
  Chain = 'chain',
  Comparison = 'comparison',
  Constant = 'constant',
  ConstantBoolean = 'constantBoolean',
  ConstantNumber = 'constantNumber',
  ConstantString = 'constantString',
  ConstantObject = 'constantObject',
  Equation = 'equation',
  Expr = 'expr',
  Field = 'field',
  Method = 'method',
  Object = 'object',
  PathNode = 'pathNode',
  Ref = 'ref',
  Resource = 'resource',
}

export interface ArithmeticExpression extends Expression {
  type: ExpressionType.Arithmetic;
  operations: Operation[];
}

export enum Operation {
  Plus = '+',
  Minus = '-',
  Multiply = '*',
  Divide = '/',
  Dot = '.',
}

export interface SVGPathNodeExpression extends Expression {
  letter: string;
  items: Expression[];
}

export interface ResourceExpression extends Expression {
  type: ExpressionType.Resource;
  name: string;
  literals: Record<string, Expression>;
}

export type MSRoutine = (string | { [key: string]: MSRoutine })[];

export type MSStepExpr = (Expression | MSIfElseExpr | MSSwitchExpr) & {
  stepType?: StepType;
};

export type MSRoutineExpr = MSStepExpr[];

export enum StepType {
  IfElse = 'IfElse',
  Switch = 'Switch',
}

export interface MSIfElseExpr {
  condition: Expression;
  true: MSRoutineExpr;
  false: MSRoutineExpr;
}

export interface MSSwitchExpr {
  expr: Expression;
  cases: {
    value: Expression;
    routine: MSRoutineExpr;
  }[];
}

export enum MSType {
  Number = 'NUMBER',
  String = 'STRING',
  Color = 'COLOR',
  Boolean = 'BOOLEAN',
  Object = 'OBJECT',
  Function = 'FUNCTION',
}

// TODO - find a better name
export interface Parameter {
  type: MSType;
  optional?: boolean;
}

export type MSParameter = Parameter | MSType;

export interface ObjectParameter extends Parameter {
  body: Record<string, MSType | MSParameter>;
}

export interface MSFunction {
  name: string;
  params: Array<MSParameter>;
  returns: string | MSType;
}

export interface MSTypeDef {
  functions?: Record<
    string,
    {
      params: Array<MSParameter>;
      returns: string;
    }
  >;
  fields?: Record<string, MSType>;
}
