import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nw-control-element',
  templateUrl: './control-element.component.html',
  styleUrls: ['./control-element.component.scss'],
})
export class ControlElementComponent implements OnInit {
  @Input()
  tooltip: string;

  constructor() {}

  ngOnInit(): void {
    // -- // -- // -- //-- //
  }
}
