<div
  class="fill-attribute-component"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Fill"
    [attributeState]="attributeState$ | async"
    key="fill"
    [initialValue]="{ color: '#444444' }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-2"
  >
    <div class="row-space-between">
      <div class="label">color</div>
      <nw-color-select
        key="color"
        [colors]="attributeState.value.color"
        (changed)="updateSubAttributeValue('color', $event)"
      >
      </nw-color-select>
    </div>
    <nw-boolean-input
      key="gradient"
      [value]="attributeState.value.gradient?.[0]"
      (changed)="updateSubAttributeValue('gradient', $event)"
    ></nw-boolean-input>
    <ng-container *ngIf="attributeState.value.gradient?.[0]">
      <nw-color-select
        [colors]="attributeState.value.gradientColor"
        (changed)="updateSubAttributeValue('gradientColor', $event)"
      >
      </nw-color-select>

      <select
        (change)="gradientDirectionChanged($event)"
        [value]="attributeState.value.gradientDirection?.[0]"
      >
        <option value="diagonal">Diagonal</option>
        <option value="horizontal">Horizontal</option>
        <option value="Vertical">Vertical</option>
      </select>
    </ng-container>
  </div>
</div>
