<div class="animation-panel-component">
  <div *ngIf="frame; else noAnimationYet" class="w-100">
    <div class="flex-row">
      <div class="flex-row space-around w-100 control-container">
        <div class="flex-row">
          <nw-icon type="skip_previous" [size]="18"></nw-icon>
          <nw-icon type="play_arrow" [size]="18"></nw-icon>
          <nw-icon type="skip_next" [size]="18"></nw-icon>
          <div
            *ngIf="currentTimeTexts as timeTexts"
            class="flex-row"
            style="font-size: 13px"
          >
            <div class="time-2-digits">
              {{ timeTexts.min }}
            </div>
            <div>:</div>
            <div class="time-2-digits">
              {{ timeTexts.sec }}
            </div>
            <div>:</div>
            <div class="time-milisec">
              {{ timeTexts.milisec }}
            </div>
          </div>
        </div>
      </div>
      <nw-floating [left]="-22" [top]="-10">
        <nw-icon
          *ngIf="closed"
          type="keyboard_arrow_up"
          [size]="20"
          (click)="closed = false"
        ></nw-icon>
        <nw-icon
          *ngIf="!closed"
          type="keyboard_arrow_down"
          [size]="20"
          (click)="closed = true"
        ></nw-icon>
      </nw-floating>
    </div>
    <div class="animation-panel" *ngIf="!closed">
      <div class="time-pin" *ngIf="currentTimePosition">
        <div
          [style.left.px]="currentTimePosition"
          [style.height.px]="height"
          class="time-pin-inner"
        ></div>
      </div>

      <div class="flex-column gap: 2px; root">
        <div class="frame-container-x">
          <nw-animation-frame [frame]="frame"></nw-animation-frame>
        </div>
        <!-- <div style="width: 88%; height: 1px; background: black"></div> -->
        <div class="frame-container-x" *ngIf="frame.soundRootFrame">
          <nw-animation-frame
            [frame]="frame.soundRootFrame"
          ></nw-animation-frame>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noAnimationYet>
    <div class="no-animation-yet flex-row space-around" fxFlex>
      <div class="text">No animation has been added yet</div>
    </div>
  </ng-template>
</div>
