import { Injectable } from '@angular/core';
import { HttpService } from '../store/http/http.service';
import { RequestType } from '../store/store.service';
import { map } from 'rxjs/operators';
import { Project } from './project.interface';
import { Observable } from 'rxjs';
import { IRIToId, IdToIRI } from '../util/iri';
import { User } from '../user/user.interface';
import { UserService } from '../user/user.service';
import { ResourceData } from '../elements/resource/resource.types';
import { cloneDeep } from 'lodash';

@Injectable()
export class ProjectService {
  saveProject(project: Project) {
    return this.http.requestCall({
      type: RequestType.PATCH,
      path: 'project/descriptor/' + project.id,
      body: {
        colorPalette: project.colorPalette,
        languages: project.languages,
      },
    });
  }

  createProject(name: string, orgID: string) {
    return this.http
      .requestCall({
        type: RequestType.POST,
        path: 'project/' + orgID,
        body: {
          literals: {
            label: name,
          },
          relationships: {
            roles: [],
          },
        },
      })
      .pipe(map(project => this.mapResourceToProject(project)));
  }

  deleteProject(projectID: string) {
    return this.http
      .requestCall({
        type: RequestType.DELETE,
        path: 'project/' + projectID,
      })
      .pipe(map(() => projectID));
  }

  listProjects(): Observable<Project[]> {
    return this.http
      .requestCall({ path: 'organisation-crud/project', type: RequestType.GET })
      .pipe(
        map(projects =>
          projects.map(project => this.mapResourceToProject(project)),
        ),
      );
  }

  patchFile(file: ResourceData): Observable<ResourceData> {
    return this.http.requestCall({
      path: 'editor/file',
      type: RequestType.POST,
      body: file,
    });
  }

  loadProjectById(projectId: string): Observable<{
    project: Project;
    users: User[];
    files: Record<string, ResourceData>;
  }> {
    return this.http
      .requestCall({
        path: `project/${projectId}`,
        type: RequestType.GET,
      })
      .pipe(
        map(project => ({
          project: this.mapResourceToProject(project),
          users: project.relationships?.roles.map(role =>
            this.userService.mapResourceToUser(role.relationships.user),
          ),
          files: project.relationships.files.reduce((object, file) => {
            object[file.IRI] = file;
            return object;
          }, {}),
        })),
      );
  }

  loadProjectWithFiles(projectId: string): Observable<ResourceData> {
    console.log('load-project-with-files', projectId);
    return this.http.requestCall({
      path: `project/files/${projectId}`,
      type: RequestType.GET,
    });
  }

  addFileToProject(projectID: string, label: string): Observable<ResourceData> {
    return this.http.requestCall({
      path: `project/files`,
      type: RequestType.POST,
      body: {
        projectID,
        label,
      },
    });
  }

  mapResourceToProject({ IRI, literals, relationships }: any): Project {
    const organisationIRI = relationships?.organisation?.IRI;
    return {
      id: IRIToId(IRI),
      name: literals.label,
      loaded: true,
      colorPalette: literals.descriptor?.colorPalette,
      languages: literals.descriptor?.languages,
      organisationId: organisationIRI && IRIToId(organisationIRI),
      roles:
        relationships.roles?.reduce(
          (roles, role) => ({
            ...roles,
            [IRIToId(role.relationships.user.IRI)]: {
              id: IRIToId(role.IRI),
              type: role.literals.type,
            },
          }),
          {},
        ) || {},
      files:
        cloneDeep(relationships)
          .files?.sort(f1 => (f1.literals.label == 'main' ? -1 : 1))
          .filter(file => !file.relationships.childOf) || [],
    };
  }

  mapProjectToResource({ id, name, organisationId, roles }: Project) {
    return {
      IRI: IdToIRI(id),
      literals: {
        label: name,
      },
      relationships: {
        organisation: {
          IRI: IdToIRI(organisationId),
        },
        roles: Object.entries(roles || []).map(([userId, role]) => ({
          IRI: IdToIRI(role.id),
          literals: {
            type: role.type,
          },
          relationships: {
            user: {
              IRI: IdToIRI(userId),
            },
          },
        })),
      },
    };
  }

  constructor(
    private readonly http: HttpService,
    private readonly userService: UserService,
  ) {}
}
