<div class="yaml-component">
  <div class="code-container">
    <!-- <ngx-codemirror
      #codemirror
      [options]="codeMirrorOptions"
      [(ngModel)]="yaml"
      (ngModelChange)="contentHasChanged()"
    >
    </ngx-codemirror> -->
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="footer">
    <div (click)="close()">Close</div>
    <div fxLayout="row" fxLayoutAlign=" center">
      <nw-icon *ngIf="valid" type="check" color="green" [size]="14"></nw-icon>
      <nw-icon *ngIf="!valid" type="close" color="red" [size]="14"></nw-icon>
    </div>
  </div>
</div>
