const { abs } = Math;

export class RightTriangle {
  static getC(params: {
    a?: number;
    alfa?: number;
    b?: number;
    beta?: number;
  }) {
    const { a, alfa, b, beta } = params;

    if (a && alfa !== undefined) {
      return abs(a / Math.sin(abs(alfa)));
    }

    if (b && beta) {
      return abs(b / Math.cos(beta));
    }

    throw new Error(`Invalid params`);
  }

  static getB(params: {
    a?: number;
    alfa?: number;
    c?: number;
    gamma?: number;
  }) {
    const { a, alfa, c, gamma } = params;

    if (a && alfa) {
      return abs(a / Math.tan(abs(alfa)));
    }
  }
}
