import { last } from 'lodash';
import { abs } from 'mathjs';
import { Vector } from '../../elements/base/vector/vector';
import {
  Coords,
  CurveSectionDescriptor,
  PathSectionDescriptor,
} from '../../elements/resource/types/shape.type';
import { Color } from './pixel';
import { Region } from './region';

const lookUp = {
  '-1.-1|-1.-1': -135.0,
  '-1.-1|-1.0': -146.30993247402023,
  '-1.-1|-1.1': -161.56505117707798,
  '-1.-1|0.-1': -123.69006752597979,
  '-1.-1|0.1': -153.43494882292202,
  '-1.-1|1.-1': -108.43494882292201,
  '-1.-1|1.0': -116.56505117707799,
  '-1.-1|1.1': -135.0,
  '-1.0|-1.-1': -161.56505117707798,
  '-1.0|-1.0': 180.0,
  '-1.0|-1.1': 161.56505117707798,
  '-1.0|0.-1': -153.43494882292202,
  '-1.0|0.1': 153.43494882292202,
  '-1.0|1.-1': -135.0,
  '-1.0|1.0': 180.0,
  '-1.0|1.1': 135.0,
  '-1.1|-1.-1': 161.56505117707798,
  '-1.1|-1.0': 146.30993247402023,
  '-1.1|-1.1': 135.0,
  '-1.1|0.-1': 153.43494882292202,
  '-1.1|0.1': 123.69006752597979,
  '-1.1|1.-1': 135.0,
  '-1.1|1.0': 116.56505117707799,
  '-1.1|1.1': 108.43494882292201,
  '0.-1|-1.-1': -108.43494882292201,
  '0.-1|-1.0': -116.56505117707799,
  '0.-1|-1.1': -135.0,
  '0.-1|0.-1': -90.0,
  '0.-1|0.1': -90.0,
  '0.-1|1.-1': -71.56505117707799,
  '0.-1|1.0': -63.43494882292202,
  '0.-1|1.1': -45.0,
  '0.1|-1.-1': 135.0,
  '0.1|-1.0': 116.56505117707799,
  '0.1|-1.1': 108.43494882292201,
  '0.1|0.-1': 90.0,
  '0.1|0.1': 90.0,
  '0.1|1.-1': 45.0,
  '0.1|1.0': 63.43494882292202,
  '0.1|1.1': 71.56505117707799,
  '1.-1|-1.-1': -71.56505117707799,
  '1.-1|-1.0': -63.43494882292202,
  '1.-1|-1.1': -45.0,
  '1.-1|0.-1': -56.309932474020215,
  '1.-1|0.1': -26.56505117707799,
  '1.-1|1.-1': -45.0,
  '1.-1|1.0': -33.690067525979785,
  '1.-1|1.1': -18.43494882292201,
  '1.0|-1.-1': -45.0,
  '1.0|-1.0': 0.0,
  '1.0|-1.1': 45.0,
  '1.0|0.-1': -26.56505117707799,
  '1.0|0.1': 26.56505117707799,
  '1.0|1.-1': -18.43494882292201,
  '1.0|1.0': 0.0,
  '1.0|1.1': 18.43494882292201,
  '1.1|-1.-1': 45.0,
  '1.1|-1.0': 63.43494882292202,
  '1.1|-1.1': 71.56505117707799,
  '1.1|0.-1': 26.56505117707799,
  '1.1|0.1': 56.309932474020215,
  '1.1|1.-1': 18.43494882292201,
  '1.1|1.0': 33.690067525979785,
  '1.1|1.1': 45.0,
};

const angleLookup = {
  '-1.-1|-1.-1': 0.0,
  '-1.-1|-1.0': -0.7853981633974483,
  '-1.-1|-1.1': -1.5707963267948966,
  '-1.-1|0.-1': 0.7853981633974483,
  '-1.-1|0.1': -2.356194490192345,
  '-1.-1|1.-1': 1.5707963267948966,
  '-1.-1|1.0': 2.356194490192345,
  '-1.-1|1.1': 3.141592653589793,
  '-1.0|-1.-1': 0.7853981633974483,
  '-1.0|-1.0': 0.0,
  '-1.0|-1.1': -0.7853981633974483,
  '-1.0|0.-1': 1.5707963267948966,
  '-1.0|0.1': -1.5707963267948966,
  '-1.0|1.-1': 2.356194490192345,
  '-1.0|1.0': -3.141592653589793,
  '-1.0|1.1': -2.356194490192345,
  '-1.1|-1.-1': 1.5707963267948966,
  '-1.1|-1.0': 0.7853981633974483,
  '-1.1|-1.1': 0.0,
  '-1.1|0.-1': 2.356194490192345,
  '-1.1|0.1': -0.7853981633974483,
  '-1.1|1.-1': -3.141592653589793,
  '-1.1|1.0': -2.356194490192345,
  '-1.1|1.1': -1.5707963267948966,
  '0.-1|-1.-1': -0.7853981633974483,
  '0.-1|-1.0': -1.5707963267948966,
  '0.-1|-1.1': -2.356194490192345,
  '0.-1|0.-1': 0.0,
  '0.-1|0.1': 3.141592653589793,
  '0.-1|1.-1': 0.7853981633974483,
  '0.-1|1.0': 1.5707963267948966,
  '0.-1|1.1': 2.356194490192345,
  '0.1|-1.-1': 2.356194490192345,
  '0.1|-1.0': 1.5707963267948966,
  '0.1|-1.1': 0.7853981633974483,
  '0.1|0.-1': -3.141592653589793,
  '0.1|0.1': 0.0,
  '0.1|1.-1': -2.356194490192345,
  '0.1|1.0': -1.5707963267948966,
  '0.1|1.1': -0.7853981633974483,
  '1.-1|-1.-1': -1.5707963267948966,
  '1.-1|-1.0': -2.356194490192345,
  '1.-1|-1.1': 3.141592653589793,
  '1.-1|0.-1': -0.7853981633974483,
  '1.-1|0.1': 2.356194490192345,
  '1.-1|1.-1': 0.0,
  '1.-1|1.0': 0.7853981633974483,
  '1.-1|1.1': 1.5707963267948966,
  '1.0|-1.-1': -2.356194490192345,
  '1.0|-1.0': 3.141592653589793,
  '1.0|-1.1': 2.356194490192345,
  '1.0|0.-1': -1.5707963267948966,
  '1.0|0.1': 1.5707963267948966,
  '1.0|1.-1': -0.7853981633974483,
  '1.0|1.0': 0.0,
  '1.0|1.1': 0.7853981633974483,
  '1.1|-1.-1': -3.141592653589793,
  '1.1|-1.0': 2.356194490192345,
  '1.1|-1.1': 1.5707963267948966,
  '1.1|0.-1': -2.356194490192345,
  '1.1|0.1': 0.7853981633974483,
  '1.1|1.-1': -1.5707963267948966,
  '1.1|1.0': -0.7853981633974483,
  '1.1|1.1': 0.0,
};

interface Section {
  point: BoundaryPoint;
  angle: number;
  length: number;
}

interface LineSection {
  sections: Section[];
  sign?: 'pos' | 'neg';
  type: 'bending' | 'curve' | 'line';
}

export class BoundaryPoint {
  get coords() {
    return [this.x, this.y];
  }

  get key() {
    return `${this.x}.${this.y}`;
  }

  get id() {
    return this.key + '.' + this.prev.key;
  }

  get boundaryPoints() {
    return this.region.bPoints;
  }

  get _prev() {
    const index = this.getPrevIndex(this.boundaryPoints, this.index);
    return this.boundaryPoints[index];
  }

  get _next() {
    const index = this.getNextIndex(this.boundaryPoints, this.index);
    return this.boundaryPoints[index];
  }

  get _prevPrev() {
    return this._prev._prev;
  }

  get _nextNext() {
    return this._next._next;
  }

  get prevPrev() {
    return this.prev.prev;
  }

  get nextNext() {
    return this.next.next;
  }

  get _angle() {
    const [prev1dx, prev1dy] = [this.prev.x - this.x, this.prev.y - this.y];
    const [next1dx, next1dy] = [this.next.x - this.x, this.next.y - this.y];
    return angleLookup[`${prev1dx}.${prev1dy}|${next1dx}.${next1dy}`];
  }

  prev: BoundaryPoint;
  next: BoundaryPoint;

  x: number;
  y: number;

  tangent: number;
  integral = false;
  angle = false;
  isEdge = false;

  constructor(
    private region: Region,
    public index: number,
    private point: Coords
  ) {
    this.x = point[0];
    this.y = point[1];
  }

  // _angle: number;

  // setAngle() {
  //   const [prev1dx, prev1dy] = [this._prev.x - this.x, this._prev.y - this.y];
  //   const [next1dx, next1dy] = [this._next.x - this.x, this._next.y - this.y];
  //   this._angle = angleLookup[`${prev1dx}.${prev1dy}|${next1dx}.${next1dy}`];
  //   this.region.parentShape.pixelStore[this.key].angle = this._angle;
  // }

  // _setAngle() {
  //   const [prev1dx, prev1dy] = [this.prev.x - this.x, this.prev.y - this.y];
  //   const [next1dx, next1dy] = [this.next.x - this.x, this.next.y - this.y];
  //   this._angle = angleLookup[`${prev1dx}.${prev1dy}|${next1dx}.${next1dy}`];
  //   this.region.parentShape.pixelStore[this.key].angle = this._angle;
  // }

  setRelationships() {
    this.prev = this._prev;
    this.next = this._next;
  }

  appendAfterMe(bPoint) {
    this.next.prev = bPoint;
    bPoint.next = this.next;
    bPoint.prev = this;
    this.next = bPoint;
  }
  isCorner = false;

  getCornerPixel(key1: string, key2: string) {
    const [x1, y1] = key1.split('.').map(v => +v);
    const [x2, y2] = key2.split('.').map(v => +v);

    const _key1 = `${x2}.${y1}`;
    const _key2 = `${x1}.${y2}`;
    if (this.region.pixelStore[_key1] && !this.region.pixelStore[_key2]) {
      return _key1;
    }
    if (!this.region.pixelStore[_key1] && this.region.pixelStore[_key2]) {
      return _key2;
    }
  }

  setTangent() {
    if (
      this._angle == -2.356194490192345 ||
      this._angle == -1.5707963267948966
    ) {
      if (
        (abs(this._prev._angle) == 3.14159653589793 ||
          abs(this._nextNext._angle) == 3.141592653589793) &&
        (this._next._angle == -2.356194490192345 ||
          this._next._angle == -1.5707963267948966)
      ) {
        if (this.x != this._next.x && this.y != this._next.y) {
          const key = this.getCornerPixel(this.key, this.next.key);

          if (key) {
            const newBoundary = new BoundaryPoint(
              this.region,
              this.index,
              key.split('.').map(v => +v) as Coords
            );

            this.appendAfterMe(newBoundary);
            newBoundary.setIsEdge();

            this.region.bPoints.push();
          }
          // this.isCorner = true;
          // this.region.setIsEdge(this.key);
          // this.region.setIsEdge(this._next.key);
        }
      }
    }
  }

  setIsEdge() {
    const angle = this._angle;
    this.region.pixelStore[this.key].angle = angle;
    if (abs(angle) == 1.5707963267948966 || abs(angle) == 0.7853981633974483) {
      this.isEdge = true;
      this.region.setIsEdge(this.key);
    }
  }

  getSectionsTillNextEdge() {
    // -- //
    const log = this.key == '301.89';

    let current: BoundaryPoint = this;
    let currentSection: Section = {
      length: 0,
      angle: 0,
      point: undefined,
    };
    const sections: Section[] = [];
    while (true) {
      current = current.next;
      if (current.isEdge || current.id == this.id) {
        break;
      }
      const angle = current._angle;
      if (abs(angle) != 3.141592653589793) {
        currentSection.angle = angle;
        currentSection.point = current;

        sections.push(currentSection);
        currentSection = {
          length: 0,
          angle: 0,
          point: undefined,
        };
      } else {
        currentSection.length += 1;
      }
    }
    return sections;
  }

  getPointDiffsTillNextEdge() {
    let current: BoundaryPoint = this;
    let next = this.next;

    const diffs = [];
    while (!next.isEdge) {
      // -- // -- //
      const [x, y] = current.point;
      const [xn, yn] = next.point;

      diffs.push([xn - x, yn - y]);

      current = next;
      next = current.next;
    }

    return diffs;
  }

  diffs: Coords[];

  discover(): any {
    // -- // -- //
    const sections = this.getPointDiffsTillNextEdge();

    // console.log('diffs', JSON.stringify(sections));
    return sections;

    const lineSections: LineSection[] = [];

    let prevSection: Section;

    let actualSections: Section[] = [];

    const red = [255, 0, 0] as Color;
    const blue = [0, 0, 255] as Color;

    let currentSection: Section;
    while (sections.length) {
      currentSection = sections.shift();

      // -- // -- //

      if (prevSection?.angle * currentSection.angle > 0) {
        // this is for the last section // -- //
        actualSections.pop();

        for (const actualSection of actualSections) {
          this.region.setPixelColor(actualSection.point.key, blue);
        }

        lineSections.push({
          sections: actualSections,
          type: 'line',
        });

        actualSections = [];

        let bendSections = [prevSection, currentSection];

        while (true) {
          prevSection = currentSection;
          currentSection = sections.shift();
          if (!currentSection) {
            break;
          }
          if (prevSection.angle * currentSection.angle < 0) {
            break;
          }
          bendSections.push(currentSection);
        }
        for (const bendSection of bendSections) {
          this.region.setPixelColor(bendSection.point.key, red);
        }

        lineSections.push({
          sections: bendSections,
          sign: prevSection.angle > 0 ? 'pos' : 'neg',
          type: 'bending',
        });
        prevSection = undefined;
      } else {
        actualSections.push(currentSection);
        prevSection = currentSection;
      }
    }

    if (lineSections.length == 0) {
      lineSections.push({
        sections: actualSections,
        type: 'line',
      });
      for (const actualSection of actualSections) {
        this.region.setPixelColor(actualSection.point.key, blue);
      }
    }

    const nextIsEdge = this.getNextIsEdge();
    const [x, y] = [nextIsEdge.x, nextIsEdge.y];

    const bending = lineSections.find(
      lineSection => lineSection.type == 'bending'
    );

    console.log('------------------', lineSections);

    if (bending) {
      return {
        id: Math.random().toString(),
        x: (x - this.x) * 6,
        y: (y - this.y) * 6,
        type: 'curve',
        bx: 10,
        by: 10,
        cx: 10,
        cy: 10,
      } as CurveSectionDescriptor;
    } else {
      return {
        id: Math.random().toString(),
        x: (x - this.x) * 6,
        y: (y - this.y) * 6,
        type: 'line',
      };
    }
  }

  getNextIsEdge() {
    let current = this.next;
    while (true) {
      if (current.isEdge) {
        return current;
      }
      current = current.next;
    }
  }

  copy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  __setTangent() {
    const [prev2dx, prev2dy] = [
      this.prevPrev.x - this.prev.x,
      this.prevPrev.y - this.prev.y,
    ];

    const [prev1dx, prev1dy] = [this.prev.x - this.x, this.prev.y - this.y];
    // const [prev1dx, prev1dy] = [this.x - this.prev.x, this.y - this.prev.y];

    const a1 = lookUp[`${prev1dx}.${prev1dy}|${prev2dx}.${prev2dy}`];

    let a1Inv = 0;

    if (a1 == 0) {
      a1Inv = 180;
    }
    if (a1 > 0) {
      a1Inv = a1 - 180;
    }
    if (a1 < 0) {
      a1Inv = a1 + 180;
    }

    const [next1dx, next1dy] = [this.next.x - this.x, this.next.y - this.y];

    const angle = angleLookup[`${prev1dx}.${prev1dy}|${next1dx}.${next1dy}`];

    const [next2dx, next2dy] = [
      this.nextNext.x - this.next.x,
      this.nextNext.y - this.next.y,
    ];

    const a2 = lookUp[`${next1dx}.${next1dy}|${next2dx}.${next2dy}`];

    this.tangent = this.getAngleDiff(a2, a1Inv);
    if (this.tangent > 50) {
      this.isEdge = true;
      this.region.setIsEdge(this.key);
    }
  }

  getAngleDiff(a1: number, a2: number) {
    let diff = a2 - a1;
    if (Math.abs(diff) > 180) {
      if (a2 < 0) {
        a2 += 360;
      }
      if (a1 < 0) {
        a1 += 360;
      }
      diff = a2 - a1;
    }
    return diff;
  }

  getPrevIndex(array: any[], index: number) {
    if (index == 0) {
      return array.length - 1;
    }
    return index - 1;
  }

  getNextIndex(array: any[], index: number) {
    if (index < array.length - 1) {
      return index + 1;
    }
    return 0;
  }

  getNeuralNetInput() {
    const angles = [];

    let current = this.next;

    const [p1, p2] = [this.point, current.next.point];

    // -- // -- //
    angles.push(this.getAngleFromPoints(p1, p2));
    while (true) {
      if (current.isEdge) {
        break;
      }

      current = current.next;

      const [prev2, prev1] = [current.prevPrev, current.prev].map(
        bp => bp.point
      );

      const [next1, next2] = [current.next, current.nextNext].map(
        bp => bp.point
      );

      // -- // -- //

      const prev = this.getPointAvg(prev2, prev1);
      const next = this.getPointAvg(next2, next1);
      // -- // -- // -- // -- //

      angles.push(this.getAngleFromPoints(p1, p2));
    }
  }

  getPointAvg(p1: Coords, p2: Coords) {
    const [x1, y1] = p1;
    const [x2, y2] = p2;
    return [x2 + x1, y2 + y1].map(val => val / 2);
  }

  getAngleFromPoints(p1: Coords, p2: Coords) {
    const [x1, y1] = p1;
    const [x2, y2] = p2;
    return Vector.getAngle(y2 - y1, x2 - x1);
  }
}
