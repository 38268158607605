import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilComponentModule } from '../../../components/util/util-component.module';
import { FileSettingsComponent } from './file-settings/file-settings.component';

@NgModule({
  imports: [FormsModule, CommonModule, UtilComponentModule],
  declarations: [FileSettingsComponent],
  exports: [FileSettingsComponent],
})
export class FileAttributeModule {
  // -- // -- //
}
