<div #host class="component-search-component">
  <input
    #search
    class="search-box"
    [(ngModel)]="text"
    (click)="click()"
    (keyup)="$event.stopPropagation(); change($event)"
    (keydown)="$event.stopPropagation()"
  />

  <div class="result-container" fxLayout="column" fxLayoutGap="4px">
    <div
      *ngFor="let result of results"
      class="result-item"
      (click)="$event.stopPropagation(); clickItem(result)"
    >
      {{ result.literals.label }}
    </div>
    <div
      *ngIf="hint"
      class="hint"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="hint-text">
        {{ hint }}
      </div>
      <nw-icon
        type="info"
        [size]="16"
        [outlined]="true"
        [opacity]="60"
      ></nw-icon>
    </div>
  </div>
</div>
