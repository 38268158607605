import { HttpService } from './../../store/http/http.service';
import { StoreService } from './../../store/store.service';
import { CanvasService } from './../../services/canvas/canvas.service';
import { ResourceData } from './../../elements/resource/resource.types';
import {
  KeyEvent,
  KeyHandlerService,
} from './../../services/keyhandler/keyhandler.service';
import { Component, OnInit, Input } from '@angular/core';
import { RootComponent } from '../root/root.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { ImageDescriptor } from '../../elements/resource/types/shape.type';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  files,
  isNewFileLoading,
  selectFileList,
  selectProjectIsLoading,
} from '../../projects/project.selector';
import {
  sceneState,
  selectComponents,
} from '../../element-editor/store/selector/editor.selector';
import { addFileToProject } from '../../projects/project.actions';
import { ShapeService } from '../../element-editor/shape/shape.service';

export interface SidePanelConfig {
  endpoint: string;
  params?: Record<string, any>;
}

const baseConfig = {
  endpoint: 'editor/project',
};

@Component({
  selector: 'nw-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent extends RootComponent implements OnInit {
  @Input()
  item: any;

  newItemState: 'file' | 'image' | 'folder' | '' = '';
  newItemName = '';
  newFileField = false;
  opened = false;

  items$: Observable<ResourceData[]>;
  activeItem: any;

  listView = false;

  isNewFileLoading$: Observable<boolean>;
  isProjectLoading$: Observable<boolean>;
  scenes$: Observable<string[]>;

  selectedProject: ResourceData;

  loading = false;

  get projectIRI() {
    return this.cs.projectIRI;
  }

  components$: Observable<Array<{ IRI: string; label: string }>>;

  constructor(
    public cs: CanvasService,
    private route: ActivatedRoute,
    db: StoreService,
    http: HttpService,
    khs: KeyHandlerService,
    readonly dialog: MatDialog,
    readonly shapeService: ShapeService,
    readonly store: Store,
  ) {
    super(db, http, khs);

    this.items$ = this.cs.store.select(selectFileList);

    this.items$.subscribe(items => {
      // console.log('items', items);
    });
    this.isProjectLoading$ = this.cs.store.select(selectProjectIsLoading);

    this.scenes$ = this.cs.store
      .select(sceneState)
      .pipe(map(state => state.scenes?.map(({ name }) => name) || ['main']));

    this.route.queryParamMap.subscribe(async (param: ParamMap) => {
      this.cs.urlQueryParams.state = param.get('state');
    });

    this.isNewFileLoading$ = this.store.select(isNewFileLoading);

    this.cs.keyEventSubscribe('q', () => this.flipListView());

    this.components$ = this.cs.store.select(selectComponents);
  }

  addComponent(IRI: string) {
    this.shapeService.addImportedShape({ IRI });
  }

  discardNew() {
    this.newItemState = '';
    this.newItemName = '';
  }

  keyUp(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save();
    }
  }

  addFolder(event: Event) {
    event.stopPropagation();
    this.newItemState = 'folder';
  }

  addFile(event: Event) {
    event.stopPropagation();
    this.newItemState = 'file';
  }

  get relationships() {
    return {
      project: {
        IRI: this.projectIRI,
      },
    };
  }

  addImage(event: Event) {
    event.stopPropagation();
    this.dialog
      .open(FileUploaderComponent, {
        data: { relationships: this.relationships },
      })
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          // This case no new image has been uploaded
          return;
        }
        this.cs.setPicture(data.literals as ImageDescriptor);

        // this.loadData(() => {
        //   this.db.set('sidePanel.selected', data.IRI);
        // });
      });
  }

  _addImage(event: Event) {
    event.stopPropagation();
    this.newItemState = 'image';
  }

  get isShapeSelected() {
    return !!this.shapeService.previewShape;
  }

  async ngOnInit() {}

  get selected$() {
    return this.db.get<string>('sidePanel.selected');
  }

  globalClick() {
    this.save();
  }

  keyEvent(event: KeyEvent) {
    if (event.code === 'Enter') {
      this.save();
    }
  }

  async save() {
    this.store.dispatch(
      addFileToProject({
        label: this.newItemName,
      }),
    );
    this.newItemName = '';
    this.newItemState = '';
  }

  selectListView() {
    if (!this.isShapeSelected) {
      return;
    }
    this.listView = true;
  }

  flipListView() {
    if (this.listView) {
      this.listView = false;
    } else {
      this.selectListView();
    }
  }
}
