import { Container } from '@pixi/display';
import { Graphics, LINE_CAP, LINE_JOIN } from '@pixi/graphics';
import { Pixel } from '../../../../image-module/image-processing/pixel';
import { RawImageShape } from '../../../../image-module/image-processing/raw-image-shape';
import {
  ElementType,
  PathSection,
} from '../path-shape/path-sections/path-section';
import { Coords } from '../../../../elements/resource/types/shape.type';
import { PointController } from '../../../../elements/util/point-controller/point-controller';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { GeneralShape } from '../general/general-shape';
import { HandSection } from '../path-shape/hand/hand-section';
import { Subscription } from 'rxjs';
import { TextShape } from '../text/text-shape';
import { Mesh, Shader, Text } from 'pixi.js';
import { GradientDirection } from './rectangle-element';

export interface SVGBaseConfig {
  position?: { x: number; y: number };
  x?: number;
  y?: number;
  fill?: string;
  opacity?: number;
  'fill-opacity'?: number;
  stroke?: string;
  'stroke-width'?: number;
  'stroke-opacity'?: number;
  closed?: boolean;
  noFill?: boolean;
  gradient?: boolean;
  gradientDirection?: GradientDirection;
  gradientColor?: string;

  hole?: boolean;
}
export type SVGConfig<T = {}> = T & Partial<SVGBaseConfig>;

// TODO - rename to CanvasElement/BaseElement
export class SVG<
  T = SVGConfig,
  ElementType extends Graphics | Text = Graphics,
> {
  _element: ElementType;
  quad: Mesh<Shader>;

  offset: Coords;

  get element() {
    return this._element as ElementType;
  }

  protected dragConfig?: {
    start?: () => void;
    drag?: (dx: number, dy: number) => void;
    end?: (dx?: number, dy?: number) => void;
    mouseOver?: () => void;
    mouseOut?: () => void;
  };

  get offsetX() {
    return this.offset?.[0] || 0;
  }

  get offsetY() {
    return this.offset?.[1] || 0;
  }

  get x() {
    return this.config.x;
  }

  get y() {
    return this.config.y;
  }

  get px() {
    return this.config.position?.x || 0;
  }

  get py() {
    return this.config.position?.y || 0;
  }

  get fill() {
    return this.config.fill;
  }

  get fillOpacity() {
    return this.config['fill-opacity'];
  }

  get opacity() {
    return this.config.opacity;
  }

  get stroke() {
    return this.config.stroke;
  }

  get strokeWidth() {
    return this.defaultTo(this.config['stroke-width'], 1);
  }

  get strokeOpacity() {
    return this.defaultTo(this.config['stroke-opacity'], 1);
  }

  defaultTo(val: number, def: number) {
    return val !== undefined ? val : def;
  }

  get cs() {
    return this.parent.cs;
  }

  get position() {
    return this.config.position || { x: 0, y: 0 };
  }

  id: string;

  constructor(
    public parent:
      | PathSection
      | HandSection
      | TextShape
      | GeneralShape
      | PointController
      | RawImageShape
      | Pixel
      | RectangleController,
    container: Container,
    protected config: SVGConfig<T>,
    index?: number,
  ) {
    // if (!config.position) {
    //   config.position = { x: 0, y: 0};
    // }

    // config.x ||= 0;
    this.id = Math.random().toString();
    // config.y ||= 0;
    this.initElement();
    this.updateAttr();

    if (index !== undefined) {
      container.addChildAt(this.element, index);
    } else {
      container.addChild(this.element);
    }
  }

  initElement() {}
  mouseMoveSubscription: Subscription;

  dragBase: Coords;
  clickHandler: (e?: SVG) => void;

  drag(
    drag: (dx: number, dy: number) => void,
    start?: () => void,
    end?: (dx?: number, dy?: number) => void,
  ) {
    this.dragConfig = { start, drag, end };
    this.element.interactive = true;
    // TODO - check what that is

    this.dragInitialized = true;
    this.initPointerdown();

    return this;
  }

  pointerDownInitialized = false;

  initPointerdown() {
    if (this.pointerDownInitialized) {
      return;
    }

    this.pointerDownInitialized = true;

    this.element.cursor = 'pointer';
    this.element.on('pointerdown', e => {
      if (this.cs.draggedSVGElement) {
        console.log('svg > pointerdown > return');
        return;
      }

      if (this.cs.isAlPressed) {
        return;
      }

      this.cs.draggedSVGElement = this as SVG;
      this.startDragHandler(e.data.global.x, e.data.global.y);
    });
  }

  rightClick(handler: (e?) => void) {
    this.element.interactive = true;
    this.element.cursor = 'pointer';
    this.element.on('rightdown', e => {
      e.preventDefault();
      handler(e);
    });
    return this;
  }

  click(handler: (e?: SVG) => void) {
    this.element.interactive = true;
    this.element.cursor = 'pointer';

    this.clickHandler = handler;
    this.initPointerdown();

    // this.element.on('pointerup', () => {
    //   this.cs.consumeClick();
    //   this.clickHandler(handler)
    // });

    return this;
  }

  setMask(element: Graphics | Container) {
    this.element.mask = element;
  }

  mouseover(handler: (e?: SVG) => void) {
    this.element.interactive = true;
    this.element.cursor = 'pointer';
    this.element.on('mouseover', () => {
      handler(this as SVG);
    });
    return this;
  }

  mouseout(handler: (e?: SVG) => void) {
    this.element.interactive = true;
    this.element.cursor = 'pointer';
    this.element.on('mouseout', () => handler(this as SVG));
    return this;
  }

  dragInitialized = false;
  _unMoved: Coords;

  noDragTookPlace = false;

  startDragHandler(x: number, y: number) {
    this.noDragTookPlace = true;
    this.mouseMoveSubscription = this.cs.mouseMove.subscribe(({ x, y }) =>
      this.dragHandler(x, y),
    );
    this.dragBase = [x, y];
    this._x = x;
    this._y = y;
    this.dragConfig?.start();
  }

  _x: number;
  _y: number;

  get dxDy() {
    return [this._x - this.dragBase[0], this._y - this.dragBase[1]];
  }

  stopDrag() {
    this.mouseMoveSubscription.unsubscribe();
    this.dragBase = null;
  }

  dragHandler(x: number, y: number) {
    this.noDragTookPlace = false;
    this._y = y;
    this._x = x;
    const [x0, y0] = this.dragBase;
    this.dragConfig?.drag(
      (x - x0) / this.cs.canvasScale,
      (y - y0) / this.cs.canvasScale,
    );
  }

  endDragHandler() {
    this.mouseMoveSubscription?.unsubscribe();
    if (this.noDragTookPlace || !this.dragConfig) {
      this.dragConfig?.end?.(0, 0);
      this.clickHandler?.();
      return;
    }

    if (!this.dragBase) {
      console.warn('svgElement > dragBase could not be found!');
      return;
    }
    const [x0, y0] = this.dragBase;
    this.dragConfig.end?.(
      (this._x - x0) / this.cs.canvasScale,
      (this._y - y0) / this.cs.canvasScale,
    );
  }

  patch(config: Partial<SVGConfig<T>>, show = false) {
    if (show) {
      this.show();
    }
    this.config = {
      ...this.config,
      ...config,
    };
    if (!this.element) {
      console.warn('No element has been found for SVG');
      return;
    }

    this.updateAttr();
  }

  clear() {
    try {
      (this.element as Graphics)?.clear();
    } catch (e) {
      // console.warn(`Unable to clear element`);
      return;
    }
  }

  hidden = false;

  show(strokeWidth?: number) {
    this.hidden = false;
    this.element.visible = true;
    if (strokeWidth) {
      this.config['stroke-width'] = strokeWidth;
      this.updateAttr();
    }
  }

  hide() {
    // console.log('svg.hide', this);
    this.hidden = true;
    this.element.visible = false;
  }

  get visible() {
    return this.element.visible;
  }

  remove() {
    if (!this.element) {
      return;
    }

    if (!this.element.destroyed) {
      this.element.destroy();
    }
  }

  disableActions() {
    this.element.interactive = false;
    this.element.cursor = null;
  }

  enableActions() {
    this.element.interactive = true;
    this.element.cursor = 'pointer';
  }

  get isStrokeDefined() {
    // return this.stroke !== undefined || !!this.strokeWidth;
    return this.stroke !== undefined && this.stroke !== null;
  }

  get isFillDefined() {
    return this.fill !== undefined && this.fill !== null;
  }

  convertHexToNumber(value: string | number): number {
    if (!value) {
      return 0;
    }

    if (!isNaN(value as number)) {
      return value as number;
    }

    if (value == 'white') {
      return 16777215;
    }

    if (value == 'black') {
      return 0;
    }

    if (typeof value == 'object') {
      return 0;
    }
    return Number(`0x${(value as string).substring(1)}`);
  }

  updateAttr() {
    // if (this instanceof PathElement) {
    //   console.log('isFillDefined', this.isFillDefined, this);
    // }
    if (!this.element) {
      return;
    }

    if (this.element instanceof Text) {
      return;
    }

    this.clear();

    if (this.isFillDefined) {
      // --> // --> // --> //
      const fill = this.convertHexToNumber(this.fill);
      this.element?.beginFill(
        fill < 16777216 ? fill : 0,
        this.opacity === undefined && this.fillOpacity === undefined
          ? 1
          : this.opacity || this.fillOpacity,
      );
    } else if (!this.config.noFill) {
      // This is create for the click and hover events

      if (this.element.destroyed) {
        console.warn('element is destroyed');
        return;
      }
      // so that we can select it
      this.element.beginFill('#000', 0.001);
    }

    if (this.isStrokeDefined) {
      this.element.lineStyle({
        width: this.strokeWidth,
        color: this.convertHexToNumber(this.stroke),
        alpha: this.strokeOpacity,
        cap: LINE_CAP.ROUND,
        join: LINE_JOIN.ROUND,
      });
    }

    /*
    const ctx = this.cs.canvas.getContext('2d');

    const quality = 256;

    // use canvas2d API to create gradient
    const grd = ctx.createLinearGradient(0, 0, quality, 0);

    grd.addColorStop(0, 'rgba(255, 255, 255, 0.0)');
    grd.addColorStop(0.3, 'cyan');
    grd.addColorStop(0.7, 'red');
    grd.addColorStop(1, 'green');
    */
    this.updatePosition();
  }

  updatePosition() {
    if (this.config.position || this.offsetX || this.offsetY) {
      const { x, y } = this.config.position || { x: 0, y: 0 };
      (this.element as Graphics).moveTo(x + this.offsetX, y + this.offsetY);
    }
  }

  // TODO - revise those two function names
  disableHover() {
    // this.element.accessiblePointerEvents
    // this.element.interactive = false;
  }

  enableHover() {
    this.element.interactive = true;
  }
}
