import { Component, Input } from '@angular/core';

@Component({
  selector: 'nw-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input()
  size = 32;

  @Input()
  style: string;
}
