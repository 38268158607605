import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CanvasService } from '../../../../../services/canvas/canvas.service';
import { ShapeService } from '../../../../shape/shape.service';
import {
  _attributeState,
  attributeState,
} from '../../../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';

@Component({
  selector: 'iw-trajectory-animation',
  templateUrl: './trajectory-animation.component.html',
  styleUrls: ['./trajectory-animation.component.scss'],
})
export class TrajectoryAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(
    readonly store: Store,
    private cs: CanvasService,
    private readonly shapeService: ShapeService,
  ) {
    super(store);
    this.key = 'trajectory-transform';
  }

  pathShapeSelectMode = false;
  selectSub: Subscription;
  startPathShapeSelectMode() {
    this.pathShapeSelectMode = true;
    this.cs.pathShapeSelectMode = true;
    this.selectSub = this.cs.generalEventSubscribe('path-shape-select', IRI => {
      if (IRI) {
        this.updateSubAttributeValue('trajectoryShapeIRI', IRI);
        this.selectSub.unsubscribe();
      }
    });
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(
      _attributeState('trajectory-transform'),
    );
    this.attributeState$.subscribe(attrState => {
      // console.log('attrState', attrState); //
    });
  }

  getLabel(IRI: string) {
    return this.shapeService.getShapeByIRI(IRI)?.label || 'not-found';
  }

  mouseOver(IRI: string) {
    this.shapeService.getShapeByIRI(IRI)?.select({ onHover: true });
  }

  mouseOut(IRI: string) {
    this.shapeService.getShapeByIRI(IRI)?.deselect({ onHover: true });
  }

  modeChange(event: any) {
    // console.log('-------- mode-change -------', event);
    this.updateSubAttributeValue('mode', event.target.value);
  }
}
