import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { HandShapeSectionDescriptorNew } from '../../../shape/shapes/path-shape/hand-section/hand-shape-new';

@Component({
  selector: 'iw-hand-shape-section',
  templateUrl: './hand-shape-section.component.html',
  styleUrls: ['./hand-shape-section.component.scss'],
})
export class HandShapeSectionComponent implements OnInit {
  @Input()
  section: HandShapeSectionDescriptorNew;

  @Input()
  index: number;

  @Input()
  closed: boolean;

  @Input()
  length: number;

  @Output()
  change = new EventEmitter<HandShapeSectionDescriptorNew>();

  get amIFirst() {
    return this.index == 0;
  }

  get amIlast() {
    return this.index == this.length - 1;
  }

  constructor(readonly store: Store) {}

  ngOnInit(): void {
    // -- //
  }

  setR(r: number) {
    this.change.emit({
      ...this.section,
      r,
    });
  }

  setCurve(curve: number) {
    this.change.emit({
      ...this.section,
      curve,
    });
  }

  setFlat(flat: boolean) {
    this.change.emit({
      ...this.section,
      flat,
    });
  }
  addStroke() {
    this.change.emit({
      ...this.section,
      stroke: {
        color: '#ffffff',
        width: 2,
        top: { start: 0, end: 1 },
        side: { start: 0, end: 1 },
        bottom: { start: 0, end: 1 },
      },
    });
  }
  removeStroke() {
    this.change.emit({
      ...this.section,
      stroke: null,
    });
  }

  strokeColorChange(color: string) {
    this.change.emit({
      ...this.section,
      stroke: {
        ...this.section.stroke,
        color,
      },
    });
  }

  strokeWidthChange(width: number) {
    this.change.emit({
      ...this.section,
      stroke: {
        ...this.section.stroke,
        width,
      },
    });
  }

  changeStrokeSection(key: 'top' | 'side' | 'bottom', event: Event) {
    const value = (event.target as HTMLInputElement).checked;
    this.change.emit({
      ...this.section,
      stroke: {
        ...this.section.stroke,
        [key]: value
          ? {
              start: 0,
              end: 1,
            }
          : undefined,
      },
    });
  }

  changeSectionInterval(
    sectionKey: 'top' | 'side' | 'bottom',
    key: 'start' | 'end',
    event: Event,
  ) {
    const value = +(event.target as HTMLInputElement).value;
    console.log('value', { sectionKey, key, value });
    this.change.emit({
      ...this.section,
      stroke: {
        ...this.section.stroke,
        [sectionKey]: {
          ...this.section.stroke[sectionKey],
          [key]: value,
        },
      },
    });
  }
}
