import { createSelector } from '@ngrx/store';
import {
  getRootAnimationId,
  selectAnimationFeature,
} from './animation.reducer';
import { selectEditorFeature } from '../../store/selector/editor.selector';
import { AttributePanelState } from '../../../services/animation/animation.types';
import {
  AnimationKeys,
  PathAnimation,
} from '../../../elements/resource/types/shape.type';

export const pathAnimationValueByIRI = (IRI: string) =>
  createSelector(
    selectAnimationFeature,
    ({ currentAnimationId, animationsByShape }) => {
      return animationsByShape[IRI]?.[currentAnimationId]?.['path']
        ?.value as PathAnimation;
    },
  );

export const animationControlPanelState = createSelector(
  selectAnimationFeature,
  ({ controlPanelState }) => controlPanelState,
);

export const selectMyAnimations = (IRI: string) =>
  createSelector(
    selectAnimationFeature,
    ({ animationsByShape: animations, currentAnimationId }) => {
      if (!currentAnimationId) {
        return [];
      }
      const animationId = getRootAnimationId(currentAnimationId);
      return Object.entries(animations[IRI]?.[animationId] || {})
        .sort(([key1], [key2]) => (key1 > key2 ? 1 : -1))
        .map(([, value]) => value);
    },
  );

export const selectAnimationsByShapeByIRI = (shapeIRI: string) =>
  createSelector(
    selectAnimationFeature,
    ({ animationsByShape }) => animationsByShape[shapeIRI],
  );

export const selectAnimationFunctionsByKey = (key: AnimationKeys) =>
  createSelector(selectAnimationFeature, ({ functions }) => functions[key]);

export const currentAnimationId = createSelector(
  selectAnimationFeature,
  state => state.currentAnimationId,
);

export const animationsByFrame = createSelector(
  selectAnimationFeature,
  state => state.animationsByFrame,
);

export const animationFunctions = createSelector(
  selectAnimationFeature,
  state => state.functions,
);

export const animationsByShape = createSelector(
  selectAnimationFeature,
  state => state.animationsByShape,
);

export const animations = createSelector(
  selectAnimationFeature,
  state => state.animationsByShape,
);

export const mainFrame = createSelector(
  selectAnimationFeature,
  state => state.mainFrame,
);
export const framesByScene = createSelector(
  selectAnimationFeature,
  state => state.framesByScene,
);

export const getAnimationsByFrame = (animationId: string) =>
  createSelector(
    selectAnimationFeature,
    ({ animationsByFrame }) => animationsByFrame[animationId],
  );

export const getAnimationState = (animationKey: string) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (
      { selectedShapes },
      { animationsByShape: animations, currentAnimationId, functions },
    ): AttributePanelState => {
      const shapeIRIs = Object.keys(selectedShapes);

      const animationValues = shapeIRIs
        .map(
          shapeIRI =>
            animations[shapeIRI]?.[currentAnimationId]?.[animationKey],
        )
        .filter(val => !!val);

      if (!animationValues.length) {
        return { state: 'undefined' };
      }

      const isThereFunction = animationValues.find(({ fcn }) => !!fcn);
      const isThereNotFunction = animationValues.find(({ fcn }) => !fcn);

      if (isThereFunction && isThereNotFunction) {
        return { state: 'mixed' };
      }

      // if (isThereFunction) {
      //   if (isThereNotFunction) {
      //   } else {

      const functionNames = animationValues.reduce(
        (object, { fcn }) => {
          if (fcn) {
            object[fcn] = true;
          }
          return object;
        },
        {} as Record<string, boolean>,
      );

      const baseObject: Record<string, any[]> = {};
      animationValues.map(({ fcn, value }) => {
        if (fcn) {
          value = functions[animationKey][fcn].value;
        }

        Object.entries(value).map(([key, value]) => {
          baseObject[key] ||= [];
          baseObject[key].push(value);
        });
      });

      function unique(array: Array<any>) {
        if (!array.length) {
          return [];
        }

        const type = typeof array[0];

        return Object.keys(
          array.reduce((object, current) => {
            object[current] = true;
            return object;
          }, {}),
        ).map(val => {
          switch (type) {
            case 'number':
              return +val;
            case 'boolean':
              return val == 'true';
            default:
              return val;
          }
        });
      }

      Object.entries(baseObject).map(([key, values]) => {
        baseObject[key] = unique(values).sort((v1, v2) => (v1 > v2 ? 1 : -1));
      });

      return {
        state: 'defined',
        functions: isThereFunction
          ? Object.keys(functionNames).sort((n1, n2) => (n1 > n2 ? 1 : -1))
          : undefined,
        value: baseObject,
      } as AttributePanelState;
    },
  );

export const getAnimationValues = (animationKey: string, innerKey?: string) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (state, { currentAnimationId }) => {
      const { selectedShapes, shapes } = state;
      const _shapes = Object.keys(selectedShapes).map(IRI => shapes[IRI]);

      return _shapes
        .map(shape => {
          return shape.literals.descriptor._animationsByKey?.[
            currentAnimationId
          ]?.find(({ key }) => key == animationKey)?.value;
        })
        .map(value => {
          if (!innerKey) {
            return value;
          }
          return value?.[innerKey];
        })
        .filter(val => !!val && val !== 'undefined' && val !== 'null');
    },
  );

export const isThereAnimationByFrame = (frameID: string) =>
  createSelector(selectAnimationFeature, state => {
    return Object.keys(state.animationsByFrame[frameID] || {}).length > 0;
  });
