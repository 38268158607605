import { NgModule } from '@angular/core';
import { UtilComponentModule } from '../../components/util/util-component.module';
import { ImageModule } from '../../image-module/image.module';
import { MaterialModule } from '../../material.module';
import { CanvasService } from '../../services/canvas/canvas.service';
import { ControlPanelComponent } from './control-panel-component/control-panel.component';
import { UtilityControlPanelComponent } from './utility-control-panel/utility-control-panel.component';
import { BaseShapeControlPanelComponent } from './base-shape-control-panel/base-shape-control-panel.component';
import { ShapeFeatureControlPanelComponent } from './shape-feature-control-panel/shape-feature-control-panel.component';
import { StateControllerComponent } from './state-controller/state-controller.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ControlPanelComponent,
    UtilityControlPanelComponent,
    BaseShapeControlPanelComponent,
    ShapeFeatureControlPanelComponent,
    StateControllerComponent,
  ],
  imports: [ImageModule, UtilComponentModule, MaterialModule, FormsModule],
  providers: [CanvasService],
  exports: [
    ControlPanelComponent,
    UtilityControlPanelComponent,
    BaseShapeControlPanelComponent,
    ShapeFeatureControlPanelComponent,
  ],
})
export class ControlerPanelModule {}
