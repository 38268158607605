import { Component, Input } from '@angular/core';
import { SubSceneTransitionFrameObject } from '../../../animation/frame/subscene-transition.frame';
import { CanvasTransformFrameObject } from '../../../animation/frame/canvas-transform-frame';
import { CanvasService } from '../../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-canvas-transform',
  templateUrl: './canvas-transform.component.html',
  styleUrls: ['./canvas-transform.component.scss'],
})
export class CanvasTransformComponent {
  @Input()
  frame: CanvasTransformFrameObject;

  constructor(private readonly cs: CanvasService) {}
  setCurrent() {
    this.frame.x = this.cs.previewShape.translateX;
    this.frame.y = this.cs.previewShape.translateX;
    this.frame.scale = this.cs.previewShape.scaleX;
    this.frame.save();
  }

  get x() {
    return this.frame.x;
  }

  get y() {
    return this.frame.y;
  }

  get scale() {
    return this.frame.scale;
  }
}
