import { Component, Input, OnInit } from '@angular/core';
import {
  AnimationValue,
  LineAnimation,
} from '../../../../elements/resource/types/shape.type';
import { TrajectoryShape } from '../../../../element-editor/shape/shapes/trajectory/trajectory-shape';

@Component({
  selector: 'nw-trajectory-animation-item',
  templateUrl: './trajectory-animation-item.component.html',
  styleUrls: ['./trajectory-animation-item.component.scss'],
})
export class TrajectoryAnimationItemComponent implements OnInit {
  @Input()
  trajectoryShape: TrajectoryShape;

  @Input()
  // The type is animation-value because otherwise the shape-item.component.html throws an error
  animationValue: AnimationValue;

  get animation() {
    return this.animationValue as LineAnimation;
  }

  // constructor(private readonly cs: CanvasService) {}

  ngOnInit(): void {}
}
