<div class="color-palette-edit-component editor-container">
  <div class="flex-column stretch">
    <div class="editor-container-header">
      <div class="header-inner flex-row gap-8">
        <nw-icon type="group" [size]="13" [outlined]="true"></nw-icon>
        <div>Users</div>
      </div>
    </div>
    <div class="body flex-column gap-8 center">
      <div
        *ngFor="let element of palette; let i = index"
        class="item flex-row space-between"
      >
        <!-- <div class="fs-13">{{ element.name }}</div> -->
        <nw-editable-text
          [text]="element.name"
          (textChanged)="nameChanged($event, i)"
        ></nw-editable-text>
        <div class="flex-row gap-8">
          <nw-color-select
            [colors]="[element.code]"
            [noOptionSelect]="true"
          ></nw-color-select>
          <div class="fs-12" style="width: 68px">{{ element.code }}</div>
        </div>
      </div>

      <div class="item selector flex-row start">
        <div class="add-container" (click)="addColor()">
          <nw-icon type="add" [size]="15"></nw-icon>
        </div>
      </div>
    </div>
    <div class="editor-footer flex-row space-around pointer">
      <div
        class="w-50 t-center fs-15"
        style="text-align: center; border-right: 1.5px solid black"
        (click)="save()"
      >
        Save
      </div>
      <div
        class="w-50 t-center fs-15"
        style="text-align: center"
        (click)="discard()"
      >
        Discard
      </div>
    </div>
  </div>
</div>
