import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  addAnimationItemAction,
  deleteAnimationItemAction,
  resetAnimationAction,
  setCurrentAnimationId,
  setMainAnimationFrame,
  setCurrentAnimationIdBase,
  setMainAnimationFrameBase,
  setAnimationChanged,
  setAnimationSubValue,
  removeAnimationItemAction,
  requestSpeechFileFromText,
  setAnimationControlPanelState,
  requestSpeechFileFromTextRequest,
  setAnimationEase,
  setAnimationEaseBase,
  setAnimationFrameForScene,
  addAnimationsOfShapesToStoreBase,
  removeAnimations,
  addAnimationsOfShapesToStore,
} from './animation.actions';
import {
  selectCurrentScenes,
  selectSelectedShapes,
  selectedShapes,
} from '../../store/selector/editor.selector';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AnimationService } from '../animation.service';
import { currentAnimationId } from './animation.selector';
import {
  bulkShapeUpdate,
  resetBaseState,
  saveChangedShapes,
  setFileChanged,
  setShapeToBeSaved,
} from '../../store/editor.actions';
import { ShapePosition } from '../../../elements/resource/types/shape.type';
import { HttpService } from '../../../store/http/http.service';
import { RequestType } from '../../../store/actions';

@Injectable()
export class AnimationEffects {
  requestSpeechFileFromText$: Observable<Action>;
  requestSpeechFileFromTextRequest$: Observable<Action>;

  addAnimationItem$: Observable<Action>;
  addAnimationItemForShape$: Observable<Action>;
  removeAnimationItem$: Observable<Action>;
  removeAnimationOfShapeItem$: Observable<Action>;

  deleteAnimation$: Observable<Action>;
  setCurrentAnimationId$: Observable<Action>;

  addAnimationOfShapeToStore$: Observable<Action>;
  setMainAnimationFrame$: Observable<Action>;

  setAnimationSubValue$: Observable<Action>;
  addAnimationsOfShapesToStore$: Observable<Action>;

  addTranslateAnimation$: Observable<Action>;
  addScaleAnimation$: Observable<Action>;
  addRotationAnimation$: Observable<Action>;

  setAnimationEase$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly animationService: AnimationService,
    private readonly http: HttpService,
  ) {
    this.setAnimationEase$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setAnimationEase),
        withLatestFrom(this.store.select(currentAnimationId)),
        switchMap(([{ shapeIRI, key, ease }, animationId]) => [
          setAnimationEaseBase({ shapeIRI, key, ease, animationId }),
          setShapeToBeSaved({ IRI: shapeIRI }),
        ]),
      ),
    );

    this.requestSpeechFileFromText$ = createEffect(() =>
      this.actions$.pipe(
        ofType(requestSpeechFileFromText),
        switchMap(({ text, id }) => [
          requestSpeechFileFromTextRequest({ text, id }),
          setAnimationControlPanelState({
            state: {
              mode: 'text-frame-form',
              isLoading: true,
            },
          }),
          // setAnimationControlPanelState({
          //   state: {
          //     mode: 'text-to-speech',
          //     isLoading: false,
          //     speechFile: {
          //       url: '123.mp3',
          //       sizeInKb: 20,
          //     },
          //   },
          // }),
        ]),
      ),
    );

    this.requestSpeechFileFromTextRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(requestSpeechFileFromTextRequest),
        switchMap(({ text, id }) => {
          console.log('request-speect-file', { text, id });
          return this.http.requestCall({
            path: '/tts/audio/text-to-speech',
            type: RequestType.POST,
            body: {
              text,
              id: Math.random().toString(),
            },
          });
        }),
        switchMap(({ filename, size }) => {
          return [
            setAnimationControlPanelState({
              state: {
                mode: 'text-frame-form',
                isLoading: false,
                speechFile: {
                  filename,
                  size,
                },
              },
            }),
          ];
        }),
      ),
    );

    this.addAnimationOfShapeToStore$ = createEffect(() =>
      this.actions$.pipe(
        // this action is coming from the attribute-panel //
        ofType(addAnimationsOfShapesToStore),
        switchMap(({ animations }) => {
          this.animationService.setAnimationStore(animations);
          return [addAnimationsOfShapesToStoreBase({ animations })];
        }),
      ),
    );

    this.addAnimationItem$ = createEffect(() =>
      this.actions$.pipe(
        // this action is coming from the attribute-panel //
        ofType(addAnimationItemAction),
        withLatestFrom(
          this.store.select(selectedShapes),
          this.store.select(currentAnimationId),
        ),
        switchMap(
          ([{ item, IRI, IRIs }, selectedShapes, currentAnimationId]) => {
            const shapeIRIs = IRI ? [IRI] : IRIs ? IRIs : selectedShapes;

            const bulkUpdate = this.animationService.setAnimationItem(
              shapeIRIs,
              item,
              currentAnimationId,
            );

            const animationId =
              this.animationService.getRootAnimationId(currentAnimationId);

            return [
              addAnimationsOfShapesToStoreBase({
                animations: shapeIRIs.map(IRI => ({
                  IRI,
                  animationsById: {
                    [animationId]: [item],
                  },
                })),
              }),
              bulkShapeUpdate({
                data: bulkUpdate,
              }),
              saveChangedShapes({ IRIs: shapeIRIs }),
            ];
          },
        ),
      ),
    );

    this.setAnimationSubValue$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setAnimationSubValue),
        withLatestFrom(
          this.store.select(currentAnimationId),
          this.store.select(selectSelectedShapes),
        ),
        switchMap(
          ([
            { IRIs, IRI, animationKey, innerKey, value },
            currentAnimationId,
            selectedShapes,
          ]) => {
            const shapeIRIs = IRI
              ? [IRI]
              : IRIs
                ? IRIs
                : Object.keys(selectedShapes);
            const { animations, bulkUpdates } =
              this.animationService.setAnimationItemSubvalue(
                shapeIRIs,
                animationKey,
                innerKey,
                value,
                currentAnimationId,
              );

            return [
              addAnimationsOfShapesToStoreBase({
                animations,
              }),
              bulkShapeUpdate({
                data: bulkUpdates,
              }),
              saveChangedShapes({ IRIs: shapeIRIs }),
            ];
          },
        ),
      ),
    );

    this.removeAnimationItem$ = createEffect(() =>
      this.actions$.pipe(
        // this action is coming from the attribute-panel
        ofType(removeAnimationItemAction),
        withLatestFrom(this.store.select(currentAnimationId)),
        switchMap(([{ key, IRI, IRIs }, currentAnimationId]) => {
          const shapeIRIs = IRI ? [IRI] : IRIs;
          const bulkUpdates = this.animationService.removeAnimationItem(
            key,
            shapeIRIs,
            currentAnimationId,
          );
          return [
            removeAnimations({
              key,
              IRIs: shapeIRIs,
              animationId: currentAnimationId,
            }),
            saveChangedShapes({ IRIs: shapeIRIs }),
            bulkShapeUpdate({
              data: bulkUpdates,
            }),
          ];
        }),
      ),
    );

    this.deleteAnimation$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteAnimationItemAction),
        withLatestFrom(this.store.select(selectedShapes)),
        map(([{ animationKey }, selectedShapes]) =>
          resetAnimationAction({
            IRIs: Object.keys(selectedShapes),
            key: animationKey,
          }),
        ),
      ),
    );

    this.setCurrentAnimationId$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setCurrentAnimationId),
        switchMap(({ id }) => {
          if (!id) {
            return [resetBaseState()];
          }

          return [
            setCurrentAnimationIdBase({ id }),
            // ...this.animationService.getShapeUpdates(id),
          ];
        }),
        catchError(error => {
          console.log({ error });
          return of(error.message);
        }),
      ),
    );

    this.setMainAnimationFrame$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setMainAnimationFrame),
        withLatestFrom(this.store.select(selectCurrentScenes)),
        switchMap(([{ frame }, scenes]) => {
          // const [currentScene] = scenes || [];
          // currentScene = 'main';

          // if (currentScene && currentScene !== 'main') {
          //   return [
          //     setAnimationFrameForScene({ frame, scene: currentScene }),
          //     setAnimationChanged({ value: true }),
          //   ];
          // } else {
          return [
            setMainAnimationFrameBase({ frame }),
            setAnimationChanged({ value: true }),
          ];
          // }
        }),
      ),
    );
  }
}
