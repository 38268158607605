<div class="shape-item-component">
  <div
    class="shape-item"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    (click)="select($event, true)"
    [style.padding-left.px]="yOffset * 10 + 4"
    [class.selected]="isSelected$ | async"
  >
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
      <div [ngSwitch]="type" [style.padding.px]="2">
        <nw-icon
          *ngSwitchCase="'imported-shape'"
          type="change_history"
          [size]="10"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'shadow'"
          type="nightlight"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'path-shape'"
          type="timeline"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'hand'"
          type="share"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'rectangle-shape'"
          type="rectangle"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'ts'"
          type="timeline"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'circle-shape'"
          type="circle"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'image-shape'"
          type="image"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'text-shape'"
          type="title"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'group-shape'"
          type="atr"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'hand-shape'"
          type="share"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
      </div>

      <nw-icon *ngIf="auxMode$ | async" type="settings" [size]="12"></nw-icon>

      <nw-editable-text
        [text]="shape.literals.label || 'no-label'"
        (textChanged)="nameChanged($event)"
        [editDisabled]="!(isSelected$ | async)"
        [id]="shape.IRI"
      ></nw-editable-text>
    </div>

    <div
      *ngIf="!noEdit"
      fxLayout="row"
      fxLayoutGap="4px"
      fxLayoutAlign=" center"
    >
      <nw-icon
        *ngIf="_shape.hidden"
        type="visibility"
        [size]="10"
        [stopPropagation]="true"
        (click)="show()"
      ></nw-icon>
      <nw-icon
        *ngIf="!_shape.hidden"
        type="visibility_off"
        [size]="10"
        [stopPropagation]="true"
        (click)="hide()"
      ></nw-icon>
      <nw-icon
        type="close"
        [size]="10"
        [outlined]="true"
        (click)="deleteShape(); $event?.stopPropagation()"
      ></nw-icon>
    </div>
  </div>

  <div>
    <!-- [ngClass]="{ 'animation-item-container-selected': !!shape.animationToCut }" -->
    <div
      class="animation-item"
      *ngFor="let animation of currentAnimations$ | async"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxFlex="grow" [ngSwitch]="animation.key">
        <nw-floating [left]="-6" [top]="-4">
          <div
            [ngSwitch]="getEase(animation)"
            (click)="flipEase(animation)"
            style="cursor: pointer"
          >
            <img
              *ngSwitchCase="'linear'"
              src="assets/icons/ease_linear.svg"
              style="width: 10px"
            />

            <img
              *ngSwitchCase="'start'"
              src="assets/icons/ease_start.svg"
              style="width: 10px"
            />

            <img
              *ngSwitchCase="'smooth'"
              src="assets/icons/ease_smooth.svg"
              style="width: 10px"
            />
            <img
              *ngSwitchCase="'overflow'"
              src="assets/icons/ease_overflow.svg"
              style="width: 10px"
            />
            <img
              *ngSwitchCase="'random'"
              src="assets/icons/ease_random.svg"
              style="width: 10px"
            />
          </div>
        </nw-floating>
        <!-- <nw-floating [left]="-7">
          <div
            [ngSwitch]="getTiming(animation)"
            (click)="flipTiming(animation)"
          >
            <nw-icon
              *ngSwitchCase="'start'"
              type="align_flex_start"
              [outlined]="true"
              [size]="12"
              [rotate]="-90"
            ></nw-icon>
            <nw-icon
              *ngSwitchCase="'end'"
              type="align_flex_end"
              [outlined]="true"
              [size]="12"
              [rotate]="-90"
            ></nw-icon>
            <nw-icon
              *ngSwitchDefault
              type="align_self_stretch"
              [outlined]="true"
              [size]="12"
              [rotate]="-90"
            ></nw-icon>
          </div>
        </nw-floating> -->

        <nw-trajectory-animation-item
          *ngSwitchCase="'trajectory'"
          [trajectoryShape]="trajectoryShape"
          [animationValue]="animation.value"
        ></nw-trajectory-animation-item>

        <nw-loop-translate-animation-item
          *ngSwitchCase="'loop-trajectory-transform'"
          [shape]="trajectoryShape"
          [animationValue]="animation.value"
        ></nw-loop-translate-animation-item>

        <nw-icon
          *ngSwitchCase="'trajectory-transform'"
          type="trending_up"
          [size]="12"
        ></nw-icon>

        <nw-icon *ngSwitchCase="'text'" type="title" [size]="12"></nw-icon>

        <nw-icon
          *ngSwitchCase="'trajectory'"
          type="timeline"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'trajectory-fill'"
          type="line_start"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'trajectory-transform'"
          type="timeline"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'rotation'"
          type="refresh"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'position.rotation'"
          type="refresh"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'trajectory-appear'"
          type="line_end"
          [outlined]="true"
          [size]="12"
        ></nw-icon>

        <nw-icon
          *ngSwitchCase="'transform'"
          type="auto_awesome_motion"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'sections'"
          type="share"
          [size]="12"
          (click)="setOriginalSectionsAnimation()"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'handSections'"
          type="share"
          [size]="12"
        ></nw-icon>
        <div
          *ngSwitchCase="'show-hide'"
          style="text-align: center; font-size: 11px"
        >
          {{ getShowAnimationValue(animation.value) ? 'show' : 'hide' }}
        </div>
        <div
          *ngSwitchCase="'function'"
          fxFLayout="row"
          fxLayoutGap="6px"
          fxLayoutAlign=" center"
        >
          <div class="function-name">f</div>
          <nw-icon type="trending_flat" [size]="12"></nw-icon>
          <div class="function-name">{{ animation.value }}</div>
        </div>

        <div *ngSwitchDefault style="text-align: center; font-size: 11px">
          {{ animation.key }}
        </div>
        <nw-icon
          *ngSwitchCase="'appear'"
          type="visibility"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'disappear'"
          type="visibility_off"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'svgAttributes.fill-opacity'"
          type="invert_colors"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'svgAttributes.opacity'"
          type="invert_colors"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'svgAttributes.stroke-width'"
          type="horizontal_rule"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'type-animation'"
          type="title"
          [size]="12"
        ></nw-icon>
        <nw-icon
          *ngSwitchCase="'r'"
          type="radio_button_unchecked"
          [size]="12"
        ></nw-icon>
      </div>

      <div class="right-section">
        <div class="close-icon-container">
          <div class="inner-container" fxLayout="row" fxLayoutGap="4px">
            <nw-icon
              *ngIf="
                animation.key !== 'appear' && animation.key !== 'disappear'
              "
              type="backup"
              [outlined]="true"
              [size]="12"
              (click)="saveAnimationAsFunction(animation.key, animation.value)"
            ></nw-icon>
            <nw-icon
              type="visibility_off"
              [size]="10"
              (click)="hideAnimation(animation.key)"
            ></nw-icon>
            <nw-icon
              type="remove"
              [size]="12"
              (click)="deleteAnimation(animation.key)"
            ></nw-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      (_shape?.selected || groupShape?.groupEdit) &&
      _shape?.getType() == 'group-shape'
    "
    class="child-container"
  >
    <nw-shape-item
      *ngFor="let child of _shape._shapes"
      [shape]="child"
      [noEdit]="false"
      [yOffset]="yOffset + 1"
    ></nw-shape-item>
  </div>
</div>
