<div class="shape-attribute-panel-component fill-available-height flex-column">
  <!-- <div class="inner-container flex-column gap-4"> -->
  <div class="flex-column flex-grow editor-container">
    <ng-container *ngIf="areThereSelectedShapes$ | async; else fileSettings">
      <!-- <iw-env-attribute></iw-env-attribute> -->
      <nw-if-attribute *ngIf="areThereMultiplScenes$ | async"></nw-if-attribute>
      <nw-translate-attribute></nw-translate-attribute>
      <nw-scale-attribute></nw-scale-attribute>
      <nw-rotation-attribute></nw-rotation-attribute>
      <nw-fill-attribute></nw-fill-attribute>
      <nw-stroke-attribute></nw-stroke-attribute>
      <!-- <nw-multiplication-x-attribute></nw-multiplication-x-attribute>
      <nw-multiplication-y-attribute></nw-multiplication-y-attribute> -->
      <iw-hand-shape-attribute
        *ngIf="onlyHandshapes$ | async"
      ></iw-hand-shape-attribute>
      <nw-drop-shadow-attribute></nw-drop-shadow-attribute>

      <nw-text-attributes *ngIf="onlyTextShapes$ | async"></nw-text-attributes>
      <iw-arc-interval-attribute
        *ngIf="onlyCircleShapes$ | async"
      ></iw-arc-interval-attribute>
    </ng-container>
    <ng-template #fileSettings>
      <!-- <div class="editor-container flex-grow iw-100 h-100">File settings</div> -->
      <nw-file-settings></nw-file-settings>
    </ng-template>
  </div>

  <nw-animation-action></nw-animation-action>
  <iw-animation-controller *ngIf="!isShapeLoading"></iw-animation-controller>
</div>
<!-- </div> -->
