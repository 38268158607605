import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { StoreService } from '../../../store/store.service';
import { PathShape } from '../../shape/shapes/path-shape/path-shape';
import { ShapeService } from '../../shape/shape.service';
import { Store } from '@ngrx/store';
import {
  file,
  isSaveActive,
  selectCurrentBackgroundColor,
  selectNoAnimationMode,
} from '../../store/selector/editor.selector';
import { Observable } from 'rxjs';
import { EditorService } from '../../editor.service';
import { map } from 'rxjs/operators';
import {
  setCanvasBackgroundColor,
  setNoAnimationMode,
} from '../../store/editor.actions';
import {
  getCurrentLanguage,
  getCurrentProjectLanguages,
  getProjectColorPalette,
} from '../../../projects/project.selector';
import { setCurrentLanguage } from '../../../projects/project.actions';

const initialMenu = {
  project: false,
  file: false,
  shape: false,
  animation: false,
};
@Component({
  selector: 'nw-control-panel',
  templateUrl: 'control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent implements OnInit {
  menuOpen = initialMenu;
  isSaveActive$: Observable<boolean>;

  isFile$: Observable<boolean>;

  noAnimationMode: boolean;

  canvasBgColor$: Observable<string>;

  colorPalette$: Observable<Array<{ label: string; color: string }>>;

  languages$: Observable<string[]>;
  currentLanguage$: Observable<string>;
  currentLanguage: string;

  langMap = {
    hu: 'hungarian',
    en: 'english',
    ger: 'german',
    esp: 'spanish',
    fr: 'french',
    it: 'italien',
    pl: 'polish',
  };

  constructor(
    readonly cs: CanvasService,
    private readonly db: StoreService,
    public readonly shapeService: ShapeService,
    public readonly editorService: EditorService,
    readonly store: Store,
  ) {
    // TODO - check cs clickEvent
    this.isSaveActive$ = this.store.select(isSaveActive);
    cs.clickEvent.subscribe(() => this.closeMenus());

    this.isFile$ = this.store.select(file).pipe(map(file => !!file));

    this.canvasBgColor$ = this.store.select(selectCurrentBackgroundColor);
    this.colorPalette$ = this.store.select(getProjectColorPalette);

    this.store
      .select(selectNoAnimationMode)
      .subscribe(noAnimationMode => (this.noAnimationMode = noAnimationMode));

    this.languages$ = this.store.select(getCurrentProjectLanguages);
    this.currentLanguage$ = this.store.select(getCurrentLanguage);
    this.currentLanguage$.subscribe(lang => {
      this.currentLanguage = lang;
    });
    this.languages$.subscribe(lang => {
      // console.log('------- languages ----', lang);
    });
  }

  ngOnInit() {}

  changeLang(event: Event) {
    this.store.dispatch(
      setCurrentLanguage({
        language: (event.target as HTMLSelectElement).value,
      }),
    );
  }

  flipMenu(event: Event, key: string) {
    event.stopPropagation();
    if (this.menuOpen[key]) {
      this.closeMenus();
    } else {
      this.closeMenus();
      this.menuOpen[key] = true;
    }
  }

  closeMenus() {
    this.menuOpen = {
      project: false,
      file: false,
      shape: false,
      animation: false,
    };
  }

  get attrPanel() {
    return this.db.get<boolean>('showAttrPanel');
  }

  flipAttrPanel() {
    this.db.flip('showAttrPanel');
  }

  async patchFiles() {
    await this.cs.patchFiles();
  }

  action(menu: string, submenu: string) {
    console.log('action', menu, submenu);
  }

  flipPathShapeDirection() {
    const pathShapes = this.cs.selectedShapes.filter(
      shape => shape.getType() == 'path-shape',
    );
    pathShapes.map((ps: PathShape) => ps.flipDirection());
  }

  flipNoAnimationMode() {
    this.store.dispatch(setNoAnimationMode({ value: !this.noAnimationMode }));
  }

  colorSelected(color: string) {
    this.store.dispatch(setCanvasBackgroundColor({ color }));
  }
}
