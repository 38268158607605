<div
  class="animation-frame-panel"
  [style.height.px]="height * 16"
  [class.root]="!parent"
>
  <div
    class="animation-row"
    [style.height.px]="height * 16"
    [style.width.px]="width * 32"
  >
    <nw-animation-frame-item
      *ngFor="let frame of frames"
      [frame]="frame"
      [id]="frame.id"
      [focusInput]="focusInput"
    >
    </nw-animation-frame-item>
  </div>
</div>
