import { createReducer, on } from '@ngrx/store';
import { User } from './user.interface';
import {
  deleteUserBase,
  loadUsers,
  setMyUserId,
  setNewlyCreatedUser,
  setNewUserLoading,
  setUser,
  setUserBeingDeleted,
  setUserBeingEdited,
  setUsers,
} from './user.actions';
import { createImmerReducer } from 'ngrx-immer/store';

export interface UserState {
  listLoading: boolean;
  myId: string;
  allUsers: Record<string, User>;
  newUserIsLoading: boolean;
  newlyCreatedUsers: Record<string, number>;
  userBeingEdited?: string;
  userBeingDeleted?: string;
}

export const initialState: UserState = {
  myId: undefined,
  listLoading: false,
  allUsers: {},
  newUserIsLoading: false,
  newlyCreatedUsers: {},
};

export const userReducer = createImmerReducer(
  initialState,
  on(loadUsers, state => ({ ...state, listLoading: true })),
  on(setUser, (state, { user }) => ({
    ...state,
    allUsers: {
      ...state.allUsers,
      [user.id]: user,
    },
    listLoading: false,
  })),
  on(setNewlyCreatedUser, (state, { id: IRI, time }) => {
    state.newlyCreatedUsers[IRI] = time;
    return state;
  }),
  on(setUserBeingEdited, (state, { user }) => ({
    ...state,
    userBeingEdited: user.id,
  })),
  on(setUserBeingDeleted, (state, { user }) => ({
    ...state,
    userBeingDeleted: user?.id,
  })),
  on(deleteUserBase, (state, { user }) => {
    delete state.allUsers[user.id];
    return state;
  }),
  on(setNewUserLoading, (state, { value }) => ({
    ...state,
    newUserIsLoading: value,
  })),
  on(setUsers, (state, { users }) => ({
    ...state,
    allUsers: users.reduce(
      (existing, user) => ({
        ...existing,
        [user.id]: user,
      }),
      { ...state.allUsers },
    ),
    listLoading: false,
  })),
  on(setMyUserId, (state, { id }) => ({ ...state, myId: id })),
);
