import {
  KeyHandlerService,
  KeyEvent,
} from './../../services/keyhandler/keyhandler.service';
import { StoreService } from './../../store/store.service';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentType } from '../general-component/general.component.type';
import { HttpService } from '../../store/http/http.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep as _cloneDeep } from 'lodash';

export interface RootComponentMessage {
  id?: string;
  component: string;
  input: any;
}

export interface ComponentMessage {
  id: string;
  component: string;
  body: any;
}

@UntilDestroy()
@Component({
  selector: 'nw-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent<Config = any> implements OnInit {
  type: ComponentType;

  @Input()
  id: string;

  @Input()
  config: Config;
  elementType: string;

  elementInstance: string;
  constructor(
    protected readonly db: StoreService,
    protected readonly http: HttpService,
    protected readonly khs: KeyHandlerService
  ) {
    this.khs.onClickEvent(() => this.globalClick());
    // this.db
    //   .get('ComponentMessage')
    //   .pipe(untilDestroyed(this))
    //   .subscribe((message: ComponentMessage) => {
    //     if (this.componentType && message && this.componentType === message?.component) {
    //       this.messageHandler(_cloneDeep(message.body));
    //     }
    //   });

    this.khs.onEvent(event => this.keyEvent(event));
  }

  // Will be overriden by the child components
  get componentType() {
    return '';
  }

  keyEvent(_event: KeyEvent) {
    // handled by the children
  }

  ngOnInit() {
    this.db
      .get('RootComponent:message')
      .subscribe((msg: RootComponentMessage) => {
        if (msg) {
          this.elementType = msg.component;
          this.config = msg.input;
        }
      });
  }

  messageHandler(message: any) {}

  globalClick() {}

  copy(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  getId(selector: string) {
    const id = `${this.elementType}-${this.elementInstance}-${selector}`;
    return id;
  }

  get random() {
    return Math.random().toString().slice(2);
  }
}
