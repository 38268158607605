<div
  fxLayout="column"
  fxLayoutAlign=" center"
  fxLayoutGap="8px"
  class="project-selector-component"
>
  <div class="inner-container" fxLayout="column" fxLayoutGap="12px">
    <!-- <div
      class="title-container"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="label">Projects</div>
      <div
        *ngIf="
          editable &&
          ['owner', 'admin', 'editor'].includes(myOrganisationRole$ | async)
        "
        class="add pointer"
        (click)="addProject()"
      >
        Add
      </div>
    </div> -->
    <ng-container *ngIf="(loading$ | async) === false; else loading">
      <div
        class="project-item blink"
        *ngIf="newProjectLoading$ | async"
        fxLayout="row"
      >
        New project is being created...
      </div>
      <div
        *ngFor="let project of projects$ | async"
        class="project-item flex-row"
        (mouseenter)="hoveredProject = project.id"
        (mouseleave)="hoveredProject = null"
        [routerLink]="[
          '/',
          'organisations',
          project.organisationId || organisationId,
          'projects',
          project.id,
        ]"
      >
        <div>
          {{ project.name }}
          <!-- [<span
            *ngFor="let role of project.roles | keyvalue"
            >{{ role.value.type }}</span
          >] -->
        </div>
        <div class="flex-row gap-6" *ngIf="isDeletePending != project.id">
          <!-- <nw-icon
            *ngIf="
              editable &&
              ['owner', 'admin', 'editor'].includes(
                project.roles[myUserId$ | async]?.type
              )
            "
            (click)="$event.stopPropagation(); openInEdit(project)"
            type="edit"
            [size]="16"
            [outlined]="true"
          ></nw-icon> -->
          <nw-icon
            *ngIf="
              hoveredProject == project.id &&
              editable &&
              ['owner'].includes(project.roles[myUserId$ | async]?.type)
            "
            (click)="$event.stopPropagation(); deleteProject(project)"
            type="delete"
            [size]="16"
            [outlined]="true"
          ></nw-icon>
        </div>
        <div *ngIf="isDeletePending == project.id">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
      <div *ngIf="(projects$ | async).length === 0">
        There are no projects yet..
      </div>

      <div
        *ngIf="
          editable &&
          ['owner', 'admin', 'editor'].includes(myOrganisationRole$ | async)
        "
        class="add pointer"
        (click)="addProject()"
      >
        +
      </div>
    </ng-container>
    <ng-template #loading>
      <div>Loading</div>
    </ng-template>
  </div>
  <div *ngIf="newProjectPanel" class="new-project-panel-container">
    <div class="new-project-panel">
      <div class="input-header">
        <div class="form-name">New Project</div>
        <nw-icon
          (click)="resetNewPanel()"
          type="close"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
      </div>
      <div>
        <label for="new-organisation-name">Name</label>
        <input [(ngModel)]="newProjectName" />
      </div>
      <button [disabled]="!newProjectName" (click)="submitNewProject()">
        Add
      </button>
    </div>
  </div>
  <div *ngIf="editProjectPanel" class="edit-project-panel-container">
    <div class="edit-project-panel">
      <div class="input-header">
        <div class="form-name">{{ editProject.name }}</div>
        <nw-icon
          (click)="resetEditPanel()"
          type="close"
          [size]="10"
          [outlined]="true"
        ></nw-icon>
      </div>
      <div>
        <label for="edit-organisation-name">Name</label>
        <input [(ngModel)]="editProject.name" />
      </div>
      <div>
        <h3>Users</h3>
        <p *ngFor="let role of editProject.roles | keyvalue">
          <ng-container *ngIf="getUserById(role.key) | async as user">
            {{ user.firstName }} {{ user.lastName }} - {{ user.email }} [{{
              role.value
            }}]
          </ng-container>
        </p>
        <ng-container *ngFor="let user of organisationUsers$ | async">
          <div *ngIf="!getKeys(editProject.roles).includes(user.id)">
            <span
              >{{ user.firstName }} {{ user.lastName }} - {{ user.email }}</span
            >
            <select [ngModel]="user.role">
              <option value="viewer">Viewer</option>
              <option value="editor">Editor</option>
              <option value="admin">Admin</option>
              <option value="owner">Owner</option>
            </select>
            <button>Add</button>
          </div>
        </ng-container>
      </div>
      <button [disabled]="!editProject.name" (click)="submitEditedProject()">
        Save
      </button>
    </div>
  </div>
</div>
