import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';

@Component({
  selector: 'nw-drop-shadow-attribute',
  templateUrl: './drop-shadow-attribute.component.html',
  styleUrls: ['./drop-shadow-attribute.component.scss'],
})
export class DropShadowYAttributeComponent extends BaseAttributeComponent {
  constructor(readonly store: Store) {
    super(store);
    this.key = 'dropShadow';
    this.attributeState$ = this.store.select(_attributeState('dropShadow'));
  }
}
