import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ifPanelState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { IfPanelState } from '../../../elements/resource/types/shape.type';
import {
  removeAllScenesRequest,
  removeSceneRequest,
  saveSceneRequest,
  setIfAttributes,
} from '../../store/editor.actions';

@Component({
  selector: 'nw-if-attribute',
  templateUrl: './if-attribute.component.html',
  styleUrls: ['./if-attribute.component.scss'],
})
export class IfAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  // attributeState$: Observable<AttributePanelState>; //

  panelState$: Observable<IfPanelState>;

  constructor(readonly store: Store) {
    super(store);
    this.key = 'if';
  }

  ngOnInit(): void {
    this.panelState$ = this.store.select(ifPanelState);
    this.panelState$.subscribe(state => {
      // console.log('-- if-panel-state --', state);
    });
  }

  sceneValueChange(key: string, value: boolean) {
    this.store.dispatch(setIfAttributes({ key, value }));
    console.log('scene-value-change', key, value);
    if (key == '_all_') {
      this.store.dispatch(removeAllScenesRequest());
      return;
    }
    console.log('scene-value-change', key, value);
    if (value) {
      this.store.dispatch(saveSceneRequest({ scene: key }));
    } else {
      this.store.dispatch(removeSceneRequest({ scene: key }));
    }
  }
}
