<div class="sign-in-component w-100 h-100">
  <div class="header flex-row space-around w-100 pv-15">
    <img src="assets/inwedio-logo.svg" alt="" style="width: 100px" />
  </div>

  <div class="flex-row space-around w-100 pv-20">
    <div class="flex-row gap-10">
      <div>Sign in with</div>
      <img
        class="third-party-icon"
        src="/assets/icons/google.png"
        alt="google"
        (click)="signIn('Google')"
      />
    </div>
  </div>

  <div class="flex-row space-around w-100 pv-12">
    <div class="fs-14" *ngIf="isUserRestricted">
      Unfortunately, the email address does not have access to the platform!
    </div>
  </div>
</div>

<!-- <div class="row justify-content-center my-5">
  <div class="col-4">
    <div class="error-message" *ngIf="isUserRestricted">
      This email address does not have access to the platform!
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col mb-2">
            <label for="email" class="form-label">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              #email="ngModel"
              [(ngModel)]="user.email"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="password" class="form-label">Password:</label>
            <div class="input-group input-group-sm">
              <input
                [type]="user.showPassword ? 'text' : 'password'"
                id="password"
                name="password"
                #password="ngModel"
                [(ngModel)]="user.password"
                class="form-control form-control-sm"
              />
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="user.showPassword = !user.showPassword"
              >
                <i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-fill': !user.showPassword,
                    'bi-eye-slash-fill': user.showPassword,
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-grid">
            <button
              type="button"
              (click)="signIn()"
              class="btn btn-sm btn-success"
              [disabled]="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loading"
              ></span>
              Sign in
            </button>
            <img
              class="third-party-icon"
              src="/assets/icons/google.png"
              alt="google"
              (click)="signIn('Google')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
