import {
  AnimationFrameObject,
  AnimationFrameRelationships,
} from './animation-frame-object';
import { BufferLoader } from './buffer-loader';
import { SoundAnimationFrame } from '../components/animation-frame/animation.types';
import { pick } from 'lodash';
import { Subscription } from 'rxjs';
import { AnimationEnvironment } from '../../../services/animation/animation.types';

export class SoundAnimationFrameObject<
  T extends SoundAnimationFrame = SoundAnimationFrame,
> extends AnimationFrameObject<T> {
  get audioService() {
    return this.shape.animationService.audioService;
  }
  get soundUrl() {
    return this.frame.soundFileUrl;
  }
  get type() {
    return 'sound';
  }

  get _duration() {
    return this.frame.duration;
  }

  resolve: () => void;
  timeout: NodeJS.Timeout;

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      ...pick(this.frame, ['soundFileUrl', 'audioDuration']),
    };
  }

  state: 'paused' | 'started' = 'started';
  soundStarted = 0;
  soundPaused = 0;
  maxIncrement = 0;
  pauseSub: Subscription;
  restartSub: Subscription;

  async _animate(env: AnimationEnvironment = {}) {
    this.maxIncrement = Math.ceil(this._duration * 60);
    this.currentIncrement = 0;

    this.pauseSub = this.cs.generalEventSubscribe(
      'animation-paused',
      async () => {
        this.soundPaused = (await this.context).currentTime;
        this.audioService.pause(this.id);
        // this.source1.stop();
      },
    );
    this.restartSub = this.cs.generalEventSubscribe(
      'animation-restarted',
      async () => {
        // await this.initBufferSource();
        const offset = this.soundPaused - this.soundStarted;
        this.audioService.restart(this.id, offset);
        this.soundStarted = this.soundPaused;
      },
    );

    return new Promise<void>(resolve => {
      this.animationId = Math.random().toString() + Math.random().toString();
      this.playSound();
      this.service.animationFrames[this.animationId] = increment => {
        this.currentIncrement += increment;
        if (this.currentIncrement > this.maxIncrement) {
          this.pauseSub.unsubscribe();
          this.restartSub.unsubscribe();
          resolve();
        }
      };
    });
  }

  bufferLoader: BufferLoader;
  source1: AudioBufferSourceNode;

  constructor(
    frame: T,
    relationships: AnimationFrameRelationships,
    private readonly context: Promise<AudioContext>,
    private readonly destination: Promise<MediaStreamAudioDestinationNode>,
  ) {
    super(frame, relationships);
  }
  init() {
    super.init();
    // -- //
    if (!this.soundUrl) {
      return;
    }

    this.audioService.initSoundBuffer(this.soundUrl, this.id);
  }

  async playSound() {
    if (!this.soundUrl) {
      return Promise.resolve();
    }
    this.soundStarted = (await this.context).currentTime;
    await this.audioService.playSound(this.id);
  }
}
