<div
  class="arc-interval-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Arc Interval"
    [noDelete]="true"
    [attributeState]="attributeState$ | async"
    key="arcInterval"
    [initialValue]="{ start: 0, end: 2 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="flex-row gap-4 attribute-value-section"
  >
    <nw-number-input
      [key]="'start'"
      [value]="attributeState.value?.start"
      (changed)="updateSubAttributeValue('start', $event)"
    ></nw-number-input>
    <nw-number-input
      [key]="'end'"
      [value]="attributeState.value?.start"
      (changed)="updateSubAttributeValue('end', $event)"
    ></nw-number-input>
  </div>
</div>
