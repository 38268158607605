import { NgModule } from '@angular/core';
import { GeneralAttributeHeaderComponent } from './general-attribute-header.component';
import { UtilComponentModule } from '../../../components/util/util-component.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [UtilComponentModule, FormsModule],
  declarations: [GeneralAttributeHeaderComponent],
  exports: [GeneralAttributeHeaderComponent],
})
export class GeneralAttributeHeaderModule {}
