import { Graphics } from 'pixi.js';
import { SVG } from './svg-element';

export class Circle extends SVG<{
  x: number;
  y: number;
  r: number;
}> {
  get r() {
    return this.config.r;
  }
  initElement() {
    this._element = new Graphics();
    // this._element.on('pointerover', () => {
    //   console.log('cursor --- yooo');
    //   this._element.cursor = 'crosshair'; // You can set different cursor styles
    // });
  }
  updateAttr() {
    super.updateAttr();
    this.element.arc(this.x, this.y, this.r, 0, 2 * Math.PI).endFill();
    // this.element.drawCircle(this.x, this.y, this.r).endFill(); //
  }
}
