import {
  OperationType,
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import {
  AnimationKeys,
  Coords,
  GeneralShapeDescriptor,
  LineAnimationInstance,
  Multiplication,
  PathShapeDescriptor,
  ShapePosition,
  AnimationValue,
  __Animation,
  TrajectoryDTransform,
  ImageShapeDescriptor,
  TypeDef,
  ScatterConfig,
  PositionAnimation,
  AnimationItem,
  ShapeConfig,
  BlinkAnimation,
  DropShadowConfig,
  FrameRectangle,
  ShapeSelectParams,
  ShapeDragParams,
  BBox,
  OrientationLineCandidate,
  eCoords,
  TrajectoryMoveAnimation,
  IncrementState,
  EaseInAnimation,
  ShapeTransform,
  Point,
  Scale,
  Scene,
  ShowHideAnimation,
  FloatEffect,
  Rotation,
  PathAnimation,
  ConsequtiveAnimationsByKey,
} from '../../../../elements/resource/types/shape.type';
import {
  cloneDeep,
  set,
  get,
  groupBy,
  isEqual as _isEqual,
  pick as _pick,
  isEqual,
} from 'lodash';
import * as uuidv1 from 'uuid/v1';

import { ObjectExpression } from '../../../../ms/ms.types';
import { MSExpression } from '../../../../ms/parser/ms-expression.stat';
import { PathShape } from '../path-shape/path-shape';
import { Resource } from '../../../../elements/resource/resource';
import { PointController } from '../../../../elements/util/point-controller/point-controller';
import {
  AnimationFrameObject,
  AnimationId,
} from '../../../animation/frame/animation-frame-object';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { Container } from '@pixi/display';
import { AnimationFrame } from '../../../animation/components/animation-frame/animation.types';
import { EventEmitter } from '@angular/core';
import { AnimationController } from '../controllers/animation/animation-controller';
import { BlinkAnimationController } from '../controllers/animation/blink/blink-animation-controller';
import { MIPMAP_MODES, Sprite, Texture } from 'pixi.js';
import { RootShape } from './root/root-shape';
import { Rectanglelement } from '../primitive/rectangle-element';
import { ImportedShape } from './imported/imported-shape';
import { GroupShape } from '../group/group-shape';
import { SVG } from '../primitive/svg-element';
import { ImageShapeConfig } from '../base/image-shape';
import { IncrementMapper } from '../../../animation/frame/increment/mapper/increment-mapper';
import { StartIncrementMapper } from '../../../animation/frame/increment/mapper/start-increment-mapper';
import { EndIncrementMapper } from '../../../animation/frame/increment/mapper/end-increment-mapper';
import { PathElement } from '../primitive/path-element';
import { FoundOrientation } from '../../../../services/orientation/orientation.service';
import {
  Easing,
  IncrementController,
} from '../../../animation/frame/increment/controller/increment.controller';
import {
  selectCurrentShapeTranslate,
  selectCurrentShapeScale,
  multiplicationByIRI,
  selectFloatEffectByIRI,
  selectDescriptorValueByIRI,
  baseDescriptorByIRI,
  selectCurrentShapeRotation,
  baseShapeTransformByIRI,
} from '../../../store/selector/editor.selector';
import {
  deleteShapeAction,
  setShapeAttributeBaseAction,
  addShapeToStore,
  setBBoxOfShape,
  setShapeTranslateBase,
  setNewShapeAction,
  setChildOf,
  setDescriptorValue,
  deleteShapeFromFileAction,
} from '../../../store/editor.actions';
import { ShapeService } from '../../shape.service';
import {
  MSAnimationFrame,
  MainAnimationFrameObject,
  RootAnimationFrame,
} from '../../../animation/frame/main-animation-frame-object';
import { filter, skip } from 'rxjs/operators';
import {
  Animations,
  AnimationsByFrame,
} from '../../../animation/store/animation.reducer';
import { selectAnimationsByShapeByIRI } from '../../../animation/store/animation.selector';
import { SineIncrementController } from '../../../animation/frame/increment/controller/sine-increment.controller';
import { AnimationByStart } from '../../../animation/animation.service';

export const errors = {
  // TODO - the descriptor for the shape should not be verified by the shape
  invalidInput: (variable: string, key: string) =>
    `'${variable}' is invalid input type for key: '${key}'`,
};

export interface PatchStep {
  prev?: number;
  action: {
    key: string;
    value: any;
  };
}

export class GeneralShape<
  DescriptorType extends GeneralShapeDescriptor = GeneralShapeDescriptor,
  C extends ShapeConfig = ShapeConfig,
> extends Resource<DescriptorType, C> {
  prev: GeneralShape;
  next: GeneralShape;

  animationsByFrame: AnimationsByFrame = {};

  blurContainer: Container;
  dropShadowElement: SVG;

  scenes: Scene[];
  contextChangedEvent: EventEmitter<Record<string, any>>;

  editMode = false;

  opened = false;

  constrainedBy: string;

  maskExpression: ObjectExpression;

  selected = false;

  initialDescriptor: DescriptorType;

  _shapes: GeneralShape<any>[] = [];

  currentlyDragged = false;

  // used in imported-shape
  outerContainer: Container;

  maskCopy: Container;

  container: Container;
  circleContainer: Container;
  sectionContainer: Container;
  auxCircleContainer: Container;

  rc: RectangleController;

  ifDefinition: Record<string, boolean>;

  scatter: ScatterConfig;

  dragProcesses: Record<
    string,
    | false
    | {
        x: number;
        y: number;
      }
  > = {};

  animationsFromCode?: Partial<Record<string, __Animation>>;
  msAnimations: Record<string, AnimationFrameObject> = {};

  locked = false;
  currentMultiplication: Multiplication;

  index: number;
  canvasIndex: number;
  baseShapeTransform: ShapeTransform;
  baseDescriptor: DescriptorType;

  animationsById: Record<AnimationId, Animations> = {};

  get animationService() {
    return this.service.animationService;
  }

  get rootParent() {
    const parent = this.parent;
    if (parent.getType() == 'root-shape' || parent.getType() == 'is') {
      return parent;
    }
    return parent.rootParent;
  }

  makeEditable() {}

  initRC() {}

  updateByMultiplication(
    width: number,
    height: number,
    xGap: number,
    yGap: number,
  ) {
    const { x, y } = this.config.multiplicationIndexes;
    // -- // -- // -- // -- // -- // -- //
    this.x = x * (width + xGap);
    this.y = y * (height + yGap);
    this.applyTranslate({ x: this.x, y: this.y });
  }

  getColorPaletteValue(fillValue: string) {
    const key = (fillValue as string).split('.')[1];
    return (
      this.cs.currentProject?.literals?.descriptor?.colorPalette?.[key] ||
      '#000000'
    );
  }

  joinIRIs(...IRIs: string[]) {
    return IRIs.filter(v => !!v).join('_');
  }

  getSVGAttribute(attrKey: string) {
    return this.descriptor.svgAttributes?.[attrKey];
  }

  setSVGAttribute(attrKey: string, value: string | number) {
    this.descriptor.svgAttributes ||= {};
    this.descriptor.svgAttributes[attrKey] = value;
  }

  constraints: Record<string, Record<string, string>> = {};
  reverseConstraints: Record<string, Record<string, string>> = {};

  addConstraint(key: string, targetShape: string, targetPoint: string) {
    set(this.constraints, [key, targetShape], targetPoint);
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'constraints',
        value: this.constraints,
      }),
    );
  }

  _startDrag(key: string, sourceShapeIRI?: string) {
    this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true });
  }

  constrainedShapes = [];
  startConstraintDrag(key: string, sourceShapeIRI: string) {
    Object.entries({
      ...(this.constraints[key] || {}),
      ...(this.reverseConstraints[key] || {}),
    })
      .filter(([shapeIRI]) => shapeIRI !== sourceShapeIRI)
      .map(([shapeIRI, targetPoint]) => {
        const shape = this.service.getShapeByIRI(shapeIRI);

        if (!shape) {
          console.warn(
            'constrained-shape was not be found',
            this.IRI,
            shapeIRI,
          );
          return;
        }
        // console.log('constraint added', targetPoint, shape);
        shape._startDrag(targetPoint, sourceShapeIRI);
        this.constrainedShapes.push(shape);
      });
  }

  _drag(x: number, y: number, dx: number, dy: number) {
    this.drag(dx, dy);
  }

  applyState(state: string) {
    // -- //

    const { position } = this.descriptor.updatesByState?.[state] || {};
    // console.log('applyState', position);
    if (position) {
      const { x, y, scale } = position;

      this.x = x !== undefined ? x : this.x;
      this.y = y !== undefined ? y : this.y;

      // if (x !== undefined || y !== undefined) {
      //   this._redraw({
      //     x: x || this.descriptor.position.x,
      //     y: y || this.descriptor.position.y,
      //   });
      // }

      if (scale !== undefined) {
        const { x: sx, y: sy } = scale;
        this.scale.x = sx !== undefined ? sx : this.scale.x || 1;
        this.scale.y = sy !== undefined ? sy : this.scale.y || 1;
        // this.applyShapeScale({
        //   x: sx || this.descriptor.position.scale.x || 1,
        //   y: sy || this.descriptor.position.scale.y || 1,
        // });
      }

      this.redraw();
    }
  }

  _endDrag() {
    this.endDrag();
  }
  getColorValue(val: any) {
    if (typeof val == 'string') {
      if (val.startsWith('$')) {
        const key = val.split('.')[1];
        if (this.parent?.descriptor.env?.[key]) {
          return this.parent?.descriptor.env[key];
        }
        return this.getColorPaletteValue(val);
      }
    }
    return val;
  }

  _setSVGAttribute(key: string, value: any, noRefresh = false) {
    this.patch(`svgAttributes.${key}`, value, noRefresh);
  }

  currentState: string;

  get IRIPrefix() {
    return '';
  }

  get animationFunctions(): string[] {
    return [];
  }

  get states() {
    return this.descriptor.states || [];
  }

  get fill() {
    return this.getSVGAttribute('fill');
  }

  // set fill(value: string) {
  //   // this.setSVGAttribute('fill', value);
  // }

  get stroke() {
    return this.getSVGAttribute('stroke');
  }

  // set stroke(value: string) {
  //   // this.setSVGAttribute('stroke', value);
  // }

  get opacity() {
    return this.getSVGAttribute('opacity') || 1;
  }

  set opacity(value: number) {
    this.descriptor.svgAttributes ||= {};
    this.descriptor.svgAttributes.opacity = value;
    this.refresh();
  }

  get strokeWidth() {
    return this.getSVGAttribute('stroke-width') || 1;
  }

  get store() {
    return this.cs.store;
  }

  get 'stroke-width'() {
    return this.getSVGAttribute('stroke-width') || 1;
  }

  get maskedBy() {
    return this.descriptor.maskedBy;
  }

  set maskedBy(val: string) {
    this.descriptor.maskedBy = val;
  }

  get containerForRc() {
    return this.container;
  }

  get mainContainer() {
    return this.container;
  }

  get dropShadow() {
    // if (this.cs.currentAnimation) {
    //   const animation = this.currentAnimations.find(
    //     ({ key }) => key == 'dropShadow'
    //   );
    //   if (animation) {
    //     return animation.value;
    //   }
    // }
    return this.descriptor.dropShadow;
  }

  set dropShadow(val: DropShadowConfig) {
    this.descriptor.dropShadow = val;
    this.initDropShadowElement(val);
  }

  get msService() {
    return this.cs.msService;
  }

  get childOf() {
    return this.descriptor.childOf;
  }

  set childOf(val: string) {
    this.descriptor.childOf = val;
  }

  // get currentDescriptor() {
  //   return {
  //     position: this.position,
  //   };
  // }

  get parent() {
    // if (this.childOf) {
    //   return this.service.getResource(this.childOf) as GeneralShape;
    // }

    return (super.parent as GeneralShape) || (this.maskParent as GeneralShape);
  }

  get animationCodesById() {
    return this.descriptor.animationCodesById || {};
  }

  set sanimationCodesById(val: Record<string, string[]>) {
    this.descriptor.animationCodesById = val;
  }

  // get maskParent() {
  //   return super.maskParent as GeneralShape;
  // }

  get orientationService() {
    return this.service.orientationService;
  }

  get auxMode() {
    return this.descriptor.auxMode;
  }

  set auxMode(val: boolean) {
    this.descriptor.auxMode = val;
  }

  set offset([x, y]: Coords) {
    this.descriptor.offset = { x, y };
  }

  // TODO - implement
  get width() {
    return 0;
  }

  // TODO - implement
  get height() {
    return 0;
  }

  get editable() {
    return (
      ((this.parent?.getType() === 'root-shape' ||
        this.parent?.getType() == 'group-shape') &&
        !this.config?.noEdit) ||
      !!this.descriptor.mask ||
      this.config?.forceEditable
    );
  }

  get currentAngle() {
    return this.descriptor.position?.rotation?.angle || 0;
  }

  get currentAngleInDeg() {
    return this.radToDeg(this.currentAngle);
  }

  get absScaleX() {
    return 1 * this.parent?.absScaleX || 1;
  }

  get absScaleY() {
    return 1 * this.parent?.absScaleY || 1;
  }

  set currentAngle(value: number) {
    set(this.descriptor, 'position.rotation', value);
  }

  get position() {
    return this.descriptor.position;
  }

  set position(value: ShapePosition) {
    this.descriptor.position = value;
  }

  get uuid() {
    return this.IRI.split('#')[1];
  }

  // NOTE - if is core variable - doesn't change with animation
  get if() {
    return this.descriptor.if;
  }

  set if(val: Record<string, boolean>) {
    // If there was some condition and it is cleared, then showing the shape
    if (!val && this.if) {
      this.show();
    }
    this.descriptor.if = val;
  }

  get showByIf() {
    return this._showByState(this.cs.currentState);
  }

  get colorInputs() {
    return (
      this.descriptor.inputs
        ?.filter(({ value }) => value == 'color')
        .map(({ key, value }) => ({
          key,
          value: this.descriptor.env?.[key] || '#000000',
        })) || []
    );
  }

  get offsetX() {
    return this.descriptor.position?.offsetX || 0;
  }

  set offsetX(val: number) {
    this.descriptor.position.offsetX = val;
  }

  get offsetY() {
    return this.descriptor.position?.offsetY || 0;
  }

  set offsetY(val: number) {
    this.descriptor.position.offsetY = val;
  }

  get w() {
    return this.descriptor.position?.w || 0;
  }

  set w(val: number) {
    this.descriptor.position.w = val;
  }

  get h() {
    return this.descriptor.position?.h || 0;
  }

  set h(val: number) {
    this.descriptor.position.h = val;
  }

  get shapes() {
    return this._shapes || [];
  }

  get currentShapes() {
    if (!this.cs.currentState) {
      return this.shapes;
    }

    return this.shapes.filter(shape => {
      if (!shape.if) {
        return true;
      }
      return shape.if?.[this.cs.currentState];
    });
  }

  get shapeIndexes() {
    return this.currentShapes
      .map(shape => shape.index)
      .sort((i1, i2) => i1 - i2);
  }

  get shapesByIndex() {
    return this.shapes.sort((s1, s2) => s1.index - s2.index);
  }

  get isPathShape() {
    return false;
  }

  get containerForChildren() {
    return this.container;
  }

  get descriptorObject() {
    return {
      literals: {
        label: this.literals.label,
        descriptor: this.descriptor,
      },
      relationships: {
        shapes: this.shapes.map(shape => shape.descriptorObject),
      },
    };
  }
  /**
   *  The underscore is because the TrajectoryShape has a currentAnimation field which has a different type yet
   */
  get currentAnimation() {
    return this.animationsById?.[this.cs.currentAnimation?.id];
  }

  get xIRIPrefix() {
    const prefixes = [];
    if (this.parent?.getType() == 'is') {
      prefixes.push(this.parent.IRI);
    }
    if (this.parent?.config?.IRIprefix) {
      prefixes.push(this.parent.config.IRIprefix);
    }

    if (prefixes.length) {
      return prefixes.join('_') + '_';
    }
    return '';
  }

  childOffsetX = 0;
  childOffsetY = 0;

  getType(): string {
    return 'gs';
  }

  get currentCode() {
    if (this.cs.currentAnimation) {
      return (
        this.descriptor.animationCodesById?.[this.cs.currentAnimation.id] || []
      );
    }
    return this.descriptor.code || [];
  }

  // invoke by the code-editor.component
  set currentCode(val: string[]) {
    if (this.cs.currentAnimation) {
      this.descriptor.animationCodesById ||= {};
      this.descriptor.animationCodesById[this.cs.currentAnimation.id] = val;
      return;
    }
    this.descriptor.code = val as string[];
  }

  get ms() {
    return this.descriptor.ms;
  }

  set ms(val: Record<string, any>) {
    this.descriptor.ms = val;
  }

  initialised = true;

  translate: Point;

  scale: Point;
  floatEffect: FloatEffect;

  get x() {
    return this.translate.x;
  }

  set x(val: number) {
    this.translate.x = val;
  }

  get y() {
    return this.translate.y;
  }

  set y(val: number) {
    this.translate.y = val;
  }

  get angle() {
    return this.rotation?.angle || 0;
  }

  rotation: Rotation;

  xSineIncement: SineIncrementController;
  ySineIncement: SineIncrementController;

  controlPoints?: Record<
    string,
    {
      coords?: Coords;
      p?: Coords;
      index?: number;
      constrainedTo?: Record<string, string>;
    }
  >;
  constructor(
    service: ShapeService,
    resourceData: ResourceData<DescriptorType>,
    config?: C,
  ) {
    super(service, resourceData, config);
    this.type = ResourceType.GeneralShape;
    this.translate = _pick(this.descriptor.position, ['x', 'y']);
    this.scale = this.descriptor.position?.scale || { x: 1, y: 1 };
    this.scenes = this.descriptor._scenes;
    this.rotation = this.descriptor.position?.rotation || { angle: 0 };

    this.constraints = cloneDeep(this.descriptor.constraints) || {};

    this.animationsById = Object.entries(
      this.descriptor._animationsByKey || {},
    ).reduce((object, [animationId, items]) => {
      object[`${animationId}`] = items.reduce((obj, item) => {
        obj[item.key] = item;
        return obj;
      }, {});
      return object;
    }, {});

    this.baseShapeTransform = {
      translate: this.translate,
      scale: this.scale,
      rotation: this.rotation,
    };

    this.baseDescriptor = this.descriptor;

    this.floatEffect = this.descriptor.floatEffect;
    this.ifDefinition = this.descriptor.if;

    if (this.floatEffect) {
      this.startFloat();
    }

    this.controlPoints = this.descriptor.controlPoints;

    this.index = this.descriptor.index;
    // dirty..
    this.canvasIndex = this.config?.index;

    // This is not that of a clean code //
    if (resourceData.IRI?.startsWith('tmp')) {
      this.IRI = resourceData.IRI;
    } else {
      // this. //
      this.IRI = this.joinIRIs(this.parent?.IRIPrefix, this.resourceData.IRI);
    }

    if (this.config?.isRoot) {
      // console.log('controlPoints', this.controlPoints);
    }

    this.service?.saveResource(this);

    if (this.descriptor?.mask) {
      this.maskExpression = MSExpression.getObjectPojo(this.descriptor.mask);
    }

    if (this.editable) {
      // TODO - migrate those subscriptions to cs
      // this.subscriptions.push(
      //   this.cs.keyEventSubscribe('Escape', () => {
      //     if (this.animationToCut) {
      //       this.animationToCut = null;
      //     }
      //   })
      // );
      this.subscriptions.push(
        this.cs.generalEventSubscribe('opacity-set', opacity => {
          if (this.groupShapeParent) {
            return;
          }
          if (this.getType() == 'root-shape') {
            return;
          }
          if (
            this.getType() == 'group-shape' &&
            //@ts-ignore
            (this as GroupShape).groupEdit
          ) {
            // console.log('ret2');
            return;
          }
          // console.log('no-ret');
          this.mainContainer.alpha = opacity == 0 ? this.opacity : opacity;
        }),
      );
    }

    // if (this.descriptor.hidden) {
    //   this._hide();
    // }

    if (config?.isRoot) {
      // console.log('if', this.if);
    }
  }

  rotationIncrement: IncrementController;

  localStartDrag() {
    if (this.groupShapeParent?.selected) {
      return this.service.startDrag(this);
    }

    // console.log('local-start-drag', this.groupShapeParent);
    this.service.startDrag(
      this.groupShapeParent || this._importedShapeParent
        ? this.groupShapeParent || this._importedShapeParent
        : this,
    );
  }

  selection({ xLimits, yLimits, strict }) {
    if (this.groupShapeParent && !this.groupShapeParent.editMode) {
      return;
    }

    if (this.hidden) {
      return;
    }

    if (!this.container) {
      return;
    }

    if (this.locked) {
      return;
    }

    const [xMinLim, xMaxLim] = xLimits;
    const [yMinLim, yMaxLim] = yLimits;

    let x, y, width, height;
    try {
      const { x: _x, y: _y, width: w, height: h } = this.container.getBounds();

      x = _x;
      y = _y;
      width = w;
      height = h;
    } catch (error) {
      return false;
      // console.log('--- error ----');
    }

    const [xMin, xMax] = [x, x + width];
    const [yMin, yMax] = [y, y + height];

    // console.log('------------------', 'lim-x', xMaxLim - xMinLim, 'lim-y', yMaxLim - yMaxLim);
    // console.log({ xMinLim, xMaxLim, yMinLim, yMaxLim});
    // console.log({ xMin, xMax, yMin, yMax});

    // checking inner rectangle
    if (xMin < xMinLim && xMaxLim < xMax && yMin < yMinLim && yMaxLim < yMax) {
      return false;
    }

    if (strict) {
      if (
        xMinLim <= xMin &&
        xMax <= xMaxLim &&
        yMinLim <= yMin &&
        yMax <= yMaxLim
      ) {
        return true;
      }
    } else {
      let xGood = 0;
      if (xMinLim <= xMin && xMin <= xMaxLim) {
        xGood++;
      }
      if (xMinLim <= xMax && xMax <= xMaxLim) {
        xGood++;
      }
      let yGood = 0;
      if (yMinLim <= yMin && yMin <= yMaxLim) {
        //
      }
      if (yMinLim <= yMax && yMax <= yMaxLim) {
        yGood++;
      }
      if (xGood > 0 && yGood > 0) {
        // console.log('selection:not-strict:select'); //
        return true;
      }
    }
  }

  get env() {
    return this.descriptor.env;
  }

  set env(val: Record<string, any>) {
    this.descriptor.env = val;
  }

  setState(state: string) {
    // -- // -- // -- //
  }

  addRemoveByState(state: string) {
    if (!state) {
      this.addContainersToParent();
      return;
    }
    this.removeByState(state);
    this.addByState(state);
  }

  removeByState(state: string) {
    // if (!this.if || this.fakeIf) {
    //   return;
    // }
    // if (!this.checkState(state)) {
    //   this.removeContainersFromParent();
    // }
  }

  addByState(state: string) {
    // if (!this.if || this.fakeIf) {
    //   this.addContainersToParent();
    //   return;
    // }
    // if (this.checkState(state)) {
    //   this.addContainersToParent();
    // }
  }

  showHideByState(state: string) {
    // console.log('show-hide-by-state', state);
    if (!state) {
      this._show();
      return;
    }
    this.hideByState(state);
    this.showByState(state);
  }

  get fakeIf() {
    // TODO - fix that [null] cannot appear as fake if
    return Array.isArray(this.if) && !this.if.find(e => !!e);
  }

  hideByState(state: string) {
    if (!this.if || this.fakeIf) {
      return;
    }
    if (!this.checkState(state)) {
      this._hide();
    }
  }

  _showByState(state: string) {
    if (!state) {
      return true;
    }
    if (!this.if || this.fakeIf) {
      return true;
    }
    return this.checkState(state);
  }

  showByState(state: string) {
    if (!this.if || this.fakeIf) {
      return;
    }
    // console.log('show-by-state', state, this.if, this.checkState(state));
    if (this.checkState(state)) {
      // && !this.descriptor._hidden) {
      this._show();
    }
  }

  checkState(state: string) {
    if (state == 'main' && (!this.if || Object.keys(this.if).length == 0)) {
      return true;
    }
    return this.if?.[state];
  }

  clone(object: any) {
    return JSON.parse(JSON.stringify(object)) as Record<string, ResourceData[]>;
  }

  paramsChanged(params: any) {
    // -- //
  }

  hideDragControllers() {
    this.rc?.hide();
  }

  clearMultipliedShapes() {
    // Object.values(this.multipliedShapes).map(shape => shape.remove());
    // this.multipliedShapes = {};
  }

  setScatter(config: ScatterConfig) {
    // this.scatter = config;
    // this.clearMultipliedShapes();
    // this.initScatter();
  }

  applyAnimationByAnimations(
    animations: Array<{ animation: AnimationByStart; ratio: number }>,
  ) {
    // -- // -- //
    const animationsByKey: Record<
      string,
      { animation: AnimationItem[]; start: number; end: number; ration: number }
    > = {};
    for (const { animation, ratio } of animations) {
      // -- // -- //
      // this.animationsById[animation]
      const { id, start, end } = animation;
      // Object.entries(this.animationCodesById[id] || {}).
    }
    // -- // -- //
  }

  // shape service logic
  initScatter() {
    if (!this.scatter) {
      return;
    }
    const { cnt, x: xRange, y: yRange, angle } = this.scatter;

    const [xStart, xEnd] = xRange;
    const [yStart, yEnd] = yRange;
    const [aStart, aEnd] = angle || [0, 0];

    // now comes the magical calculation // -- // -- //

    const xDiff = xEnd - xStart;
    const yDiff = yEnd - yStart;

    let xRel: number, yRel: number, multiplier: number;

    if (xDiff <= yDiff) {
      xRel = 1;
      yRel = yDiff / xDiff;
      multiplier = Math.sqrt(cnt / yRel);
    } else {
      xRel = xDiff / yDiff;
      yRel = 1;
      multiplier = Math.sqrt(cnt / xRel);
    }

    const xCount = xRel * multiplier;
    const yCount = yRel * multiplier;

    // console.log('xCount', xCount, 'yCount', yCount);

    const _xCount = Math.ceil(xCount);
    const _yCount = Math.ceil(yCount);
    // console.log('_xCount', _xCount, '_yCount', _yCount);

    let xOffset = 0;
    const xIncrement = xDiff / _xCount;
    let yOffset = 0;
    const yIncrement = yDiff / _yCount;

    const positions = [];
    for (let x = 0; x < _xCount; x++) {
      for (let y = 0; y < _yCount; y++) {
        positions.push({
          x: this.x + this._random(xOffset, xIncrement),
          y: this.y + this._random(yOffset, yIncrement),
          scale: this.scale,
          rotation: aStart + this.random() * (aEnd - aStart),
        });
        yOffset += yIncrement;
      }
      yOffset = 0;
      xOffset += xIncrement;
    }

    const countToRemove = _xCount * _yCount - cnt;
    const removeIncrement = Math.floor(cnt / countToRemove);
    // console.log('countToRemove', countToRemove, 'removeIncrement', removeIncrement);

    const indexesToRemove = [];
    for (let i = 0; i < countToRemove; i++) {
      indexesToRemove.push(removeIncrement * (i + 1));
    }

    indexesToRemove.reverse();
    // console.log('indexesToRemove', indexesToRemove);

    // console.log('positions-before', positions);
    indexesToRemove.map(index => positions.splice(index, 1));

    // console.log('positions-after', positions);
    // console.log('------ final-lenght', positions.length);

    for (const position of positions) {
      const { x, y } = position;
      this.multipliedShapes[`${x}-${y}`] = this.copy(
        `multiplied-${x}-${x}-${this.IRI}`,
        position,
      );
    }

    // for (let i = 0; i < cnt; i++) {
    //   this.multipliedShapes[`${i}`] = this.copy(`multiplied-${i}-${this.IRI}`, {
    //     x: this.x + xStart + this.random() * (xEnd - xStart),
    //     y: this.y + xStart + this.random() * (yEnd - yStart),
    //     scale: this.scale,
    //     rotation: aStart + this.random() * (aEnd - aStart),
    //   });
    // }
  }

  _applyAnimation(animations: AnimationByStart[]) {
    // -- // -- // -- // -- // -- // -- //
  }

  _random(start: number, amplitude: number) {
    return start + amplitude * Math.random();
  }

  random() {
    return Math.random();
  }

  contextChanged(param?: any) {
    this.contextChangedEvent?.next(param);
  }

  environmentChange(env: Record<string, any>) {
    this.env = env;
    this.contextChanged(this.env);
  }

  yamlDescriptorChanged(descriptor: Record<string, any>) {
    Object.entries(descriptor).map(([key, value]) => {
      switch (key) {
        case 'ms':
          this.msDescriptorChanged(value);
          break;
      }
    });
  }

  msDescriptorChanged(ms: Record<string, string>) {
    this.ms = ms;
    console.log('ms-descriptor-changed');
    this.contextIsUpdated();
  }

  resize(width: number, height: number) {
    // Implement
  }

  _resize(width: number, height: number) {
    // Implement
    this.resize(width, height);
  }

  finishInit() {
    this.select();
  }

  contextIsUpdated() {
    if (this.env?.if && this.parent?.env) {
      const env = this.parent.env;

      if (!this.env.if.includes('.')) {
        return console.log('env must be variable definition');
      }

      let [key, value] = this.env.if.split('.') as [string, string | boolean];
      value ||= true;
      if (env[key] === value) {
        this.show();
      } else {
        this.hide();
      }
    }

    this.contextChanged();
  }

  getEnvVar(key: string) {
    return this.env?.[key];
  }

  getVariable(variable: string) {
    return this.descriptor.env?.[variable];
  }

  keyEventSubscribe(key: string, callback: () => void, prio?: number) {
    this.subscriptions.push(this.cs.keyEventSubscribe(key, callback, prio));
  }

  selectedKeyEventSubscribe(
    key: string,
    callback: (consume?: () => void) => void,
    prio?: number,
  ) {
    this.subscriptions.push(
      this.cs.keyEventSubscribe(
        key,
        () => {
          if (this.selected) {
            callback();
          }
        },
        prio,
      ),
    );
  }

  envChanged(env: Record<string, any>) {
    if (!env) {
      return;
    }

    this.descriptor.env = env;
    this.save();
    this.contextIsUpdated();
  }

  selectedGeneralEventSubscribe(
    key: string,
    callback: (p?: any) => void,
    prio?: number,
  ) {
    this.subscriptions.push(
      this.cs.generalEventSubscribe(key, p => {
        if (this.selected) {
          callback(p);
        }
      }),
    );
  }

  saveState() {
    this.patch('transform', cloneDeep(this.position));
  }

  animationsByStart: AnimationByStart[];

  initAnimationFrame(scene?: string) {
    if (scene && scene !== 'main') {
      const frame = cloneDeep(this.descriptor.animiationFrameByScene?.[scene]);
      if (this.descriptor.animiationFrameByScene?.[scene]) {
        this.animationFrame = new RootAnimationFrame(this, frame);
      }
    } else if (this.descriptor.animationFrame) {
      this.animationFrame = new RootAnimationFrame(
        this,
        cloneDeep(this.descriptor.animationFrame),
      );
    }
    if (!this.animationFrame) {
      return;
    }
    this.animationsByStart = this.animationFrame.getAnimationsByStart();
    this.shapes.map(shape =>
      shape.setConsequtiveAnimationsByKey(this.animationsByStart),
    );
  }
  _consequtiveAnimationsByKey: ConsequtiveAnimationsByKey = {};

  get consequtiveAnimationsByKey() {
    if (this.config?.isRoot) {
      return this.animationService.consequtiveActionsByShape[this.IRI];
    }
    return this._consequtiveAnimationsByKey;
  }

  set consequtiveAnimationsByKey(val) {
    if (this.config?.isRoot) {
      this.animationService.consequtiveActionsByShape[this.IRI] = val;
    }
    this._consequtiveAnimationsByKey = val;
  }

  updateAnimationsByAnimationKey() {
    this.setConsequtiveAnimationsByKey(this.animationsByStart);
  }

  setConsequtiveAnimationsByKey(animationsByStart: AnimationByStart[]) {
    this.animationsByStart = animationsByStart || [];
    this.consequtiveAnimationsByKey = {};
    for (const animationByStart of this.animationsByStart) {
      this.getAnimationsById(animationByStart.id).map(animationItem => {
        this.consequtiveAnimationsByKey[animationItem.key] ||= [];
        this.consequtiveAnimationsByKey[animationItem.key].push({
          ...animationByStart,
          animation: animationItem,
        });
      });
    }

    if (this.config?.isRoot) {
      this.animationService.setConsequtiveActionsByShape(
        this.IRI,
        this.consequtiveAnimationsByKey,
      );
    }
  }

  updateAnimationState() {
    // -- //
    this.applyAnimationByTime(this.animationService.currentAnimationTime);
  }

  applyAnimationByTime(time: number, directApply = false) {
    if (time == undefined) {
      return;
    }

    const updates: Partial<Record<AnimationKeys, AnimationValue>> = {};
    Object.entries(this.consequtiveAnimationsByKey || {}).map(
      ([animationKey, value]) => {
        switch (animationKey) {
          case 'show-hide':
            const showHideValue = this.animationService.getShowHideByTillTime(
              time,
              value,
            );

            if (showHideValue == undefined) {
              return;
            }

            if (showHideValue == 0) {
              this.hide();
              return;
            }

            this.show();

            if (showHideValue !== 1) {
              this.opacity = showHideValue;
            }

            break;
          case 'translate':
            const { x, y } = this.animationService.getTransformTillTime(
              time,
              this.IRI,
              this.baseShapeTransform.translate,
              'translate',
            );
            if (this.x !== x || this.y !== y) {
              if (directApply) {
                this.applyTranslate({ x, y });
              } else {
                updates.translate = { x, y };
              }
            }
            break;
          case 'scale':
            const { x: xs, y: ys } = this.animationService.getTransformTillTime(
              time,
              this.IRI,
              this.baseShapeTransform.scale,
              'scale',
            );
            if (this.scale.x !== xs || this.scale.y !== ys) {
              if (directApply) {
                this.applyScale({ x: xs, y: ys });
              } else {
                updates.scale = { x: xs, y: ys };
              }
            }
            break;
        }
      },
    );
    return updates;
  }

  msFunctions: Record<string, MSAnimationFrame> = {};

  addMSFunction(key: string, frame: AnimationFrame) {
    this.msFunctions ||= {};
    this.msFunctions[key] = new MSAnimationFrame(this, frame);
  }

  addFunction(key: string, frame: AnimationFrame) {
    this.animationFrame ||= new RootAnimationFrame(this, {});
    this.animationFrame.addFunction(key, frame);
  }

  filterFcn(r: ResourceData) {
    return true;
  }

  log(...messages: any[]) {
    this.cs.logX(
      this,
      messages
        .map(msg => `${typeof msg === 'object' ? JSON.stringify(msg) : msg}`)
        .join(' '),
    );
  }

  isLog = false;

  get isRoot() {
    return false;
  }

  shapeStore: Record<string, boolean> = {};

  __setPreAnimationState() {
    // const animationsByStart = this.animationFrame.getAnimationsByStart();
    const hiddenShapes = {};

    this.shapes.map(shape => {
      if (shape.consequtiveAnimationsByKey['show-hide']?.length) {
        const [first] = shape.consequtiveAnimationsByKey['show-hide'];
        if ((first.animation.value as ShowHideAnimation).value) {
          shape._hide();
          return;
        }
      }

      if (shape.consequtiveAnimationsByKey.path?.length) {
        const [firstPath] = shape.consequtiveAnimationsByKey.path;
        if (firstPath.animation.value as PathAnimation) {
          shape._hide();
        }
      }
    });
    // for (const animation of this.animationsByStart || []) {
    //   // -- // -- // -- //
    //   const { id } = animation;
    //   Object.entries(this.service.animationService.animationsByFrame[id] || {})
    //     .filter(
    //       ([shapeIRI]) => !hiddenShapes[shapeIRI] && !notHiddenShapes[shapeIRI],
    //     )
    //     .map(([shapeIRI, animations]) => {
    //       if (animations.appear) {
    //         hiddenShapes[shapeIRI] = true;
    //       }

    //       const showHideValue = animations['show-hide']
    //         ?.value as ShowHideAnimation;

    //       if (showHideValue && !showHideValue.value) {
    //         notHiddenShapes[shapeIRI] = true;
    //       }

    //       if (showHideValue && showHideValue.value) {
    //         hiddenShapes[shapeIRI] = true;
    //       }

    //       if (animations['path']?.value as PathAnimation) {
    //         hiddenShapes[shapeIRI] = true;
    //       }
    //     });
    // }

    // console.log('hiddenShapes', hiddenShapes);

    // Object.keys(hiddenShapes)
    //   .map(IRI => this.getShapeByIRI(IRI))
    //   .map(shape => {
    //     console.log('shape', shape);
    //     shape?._hide();
    //     shape.hiddenByAnimation = true;
    //   });

    this.shapes.filter(shape => shape.auxMode).map(shape => shape._hide());

    this.shapes.map(shape => {
      if (shape.getType() == 'is') {
        shape.__setPreAnimationState();
      }
    });
  }

  initShapes(scene?: string) {
    if (this.scatter) {
      this.initScatter();
    }
    this.shapeData = (cloneDeep(this.resourceData.relationships?.shape) ||
      []) as ResourceData[];

    this._initShapes(this.shapeData);
  }

  shapeData: ResourceData[];
  _initShapes(shapeData: ResourceData[], merge = false) {
    const shapeStore = {};

    shapeData = shapeData.sort(
      (rd1, rd2) =>
        rd1.literals.descriptor.index - rd2.literals.descriptor.index,
    );

    const shapes = shapeData
      .filter(data => !merge || !this.shapeStore[data.IRI])
      .map((data, index) => {
        if (shapeStore[data.IRI]) {
          console.log('duplicate', this.label, '  ---- ', data.literals.label);
          return;
        }

        shapeStore[data.IRI] = true;
        if (this.config?.IRIprefix) {
          data.IRI = this.config.IRIprefix + '_' + data.IRI;
        }

        this.shapeStore[data.IRI] = true;

        return this.service.initShape(data, this, {
          index,
          isRoot: this.isRoot,
        });
      })
      .filter(shape => !!shape);

    shapes.map(shape => shape.afterInit());

    if (merge) {
      this._shapes.push(...shapes);
    } else {
      this._shapes = shapes;
    }
  }

  _clone(object: any) {
    return JSON.parse(
      JSON.stringify(object),
    ) as ResourceData<GeneralShapeDescriptor>[];
  }

  compileCode(code: string) {
    const expression = this.msService.compile(code);
    this.msService.getScatterParams(expression as ObjectExpression);
  }

  containerAdded = false;

  init() {
    this.initContainers();

    this.addContainersToParent();

    // if (this.parent?.contextChangedEvent) {
    //   // I think its easier to do directly with each iterating through the children
    //   this.subscriptions.push(
    //     this.parent.contextChangedEvent.subscribe(() => {
    //       this.contextIsUpdated();
    //     })
    //   );
    // }

    // TODO - it should come from data
    // if (this.maskExpression) {
    //   this.maskElement = this.cs.snap.rect(0, 0, 0, 0, 0, 0);
    //   this.maskElement.attr({
    //     ...MSEvaluationService.resolve(this, this.maskExpression),
    //     fill: 'white',
    //   });
    //   this.outerElementGroup.attr({ mask: this.maskElement });
    // }

    // this.redraw();
  }

  addContainersToParent() {
    // if (this.containerAdded) {
    //   if (this.parent?.getType() == 'root-shape') {
    //     console.log(
    //       'add-container-to-parent RETURN',
    //       this.getType(),
    //       this.label,
    //     );
    //   }
    //   return;
    // }

    if (this.parent?.getType() == 'root-shape') {
      // console.log('add-container-to-parent', this.getType(), this.label);
    }
    // this.containerAdded = true;

    if (this.descriptor.fixPosition) {
      this.cs.rootFixContainer.addChild(this.container);
    } else {
      // const ifDef = this.ifDefinition;
      this.parent?.containerForChildren?.addChild(this.container);
    }

    // console.log('add-containers-to-parent', !!this.parent);

    if (this.editable) {
      if (this.descriptor.fixPosition) {
        this.cs.rootFixContainer.addChild(this.circleContainer);
        this.cs.rootFixContainer.addChild(this.auxCircleContainer);
      } else {
        this.parent?.circleContainer?.addChild(this.circleContainer);
        this.parent?.auxCircleContainer?.addChild(this.auxCircleContainer);
      }
    }
    this.parent?.sectionContainer?.addChild(this.sectionContainer);
  }

  removeContainersFromParent() {
    return;
    if (!this.containerAdded) {
      // console.log('--------- remove-return --------');
      return;
    }
    if (this.parent?.getType() == 'root-shape') {
      // console.log('remove-container-from-parent', this.getType(), this.label);
    }

    this.containerAdded = false;

    if (this.editable) {
      this.parent?.circleContainer?.removeChild(this.circleContainer);
      this.parent?.auxCircleContainer?.removeChild(this.auxCircleContainer);
    }

    this.parent?.sectionContainer?.removeChild(this.sectionContainer);
    this.parent?.containerForChildren?.removeChild(this.container);
  }

  texture: Texture;
  sprite: Sprite;

  // imgPointerDown = false;

  scaleImage() {}

  initImageByBase64(base64: string, cb?: () => void, raw = false) {
    const now = performance.now();

    return new Promise<void>(res => {
      if (!base64) {
        console.log('----------- nooooo -----------');
      }

      if (raw) {
        this.texture = Texture.from(base64);
      } else {
        this.texture = Texture.from('data:image/png;base64,' + base64);
      }
      this.texture.baseTexture.mipmap = MIPMAP_MODES.ON;

      this.sprite = new Sprite(this.texture);

      this.container.visible = false;
      this.container.addChild(this.sprite);

      this.sprite.interactive = true;
      this.sprite.on('pointerdown', () => {
        this.cs.mouseDownAbsorbed = true;
      });
      this.sprite.on('pointerup', () => {
        // this.cs.clickAbsorbed = true;
        this.clicked();
      });
      // this.container.visible = false;

      // this.sprite.on('mouseout', () => this.deselect({ onHover: true }));
      this.sprite.on('mouseover', () => {
        if (!this.cs.shapeAddMode) {
          this.select({ onHover: true });
        }
      });

      if (!this.position) {
        this.position = {
          x: 0,
          y: 0,
        };
      }

      if (!this.position.scale) {
        this.position.scale = { x: 1, y: 1 };
      }

      const interval = setInterval(() => {
        try {
          const { width, height } = this.container.getBounds();

          if (width > 1 && height > 1) {
            this.setWidthHeight();
            console.log('-------- set-width/height', width, height);
            this.redraw();
            this.scaleImage();

            // if (this.containerAdded && !this.hidden) {
            // }
            this.container.visible = true;
            this.initialised = true;

            this.initRect();
            clearInterval(interval);
            cb?.();

            this.updateFrameRectangle();
            res();
            // this.sprite = new Sprite(this.texture);

            // var maskG = new Graphics()
            // maskG.beginFill('#ff0000')
            // maskG.drawRect(0, 0, 500, 500)    //<- COMMENT THIS
            // maskG.endFill();

            // this.container.alpha = 0.1;

            // // maskG.transform.position.x = this.x
            // // maskG.transform.position.y = this.x
            // this.container.addChild(maskG);
            // this.sprite.mask = maskG;
          }
        } catch (error) {
          clearInterval(interval);
        }
      }, 100);
    });
  }

  initRect() {}

  hideIfTheresAppearAnimation() {
    // TODOx - refactor
    // Object.entries(this.animationsById || {}).map(
    //   ([animationID, animations]) => {
    //     if (animationID == this.cs.currentAnimation?.id) {
    //       return;
    //     }
    //     if (animations.find(({ key }) => key == 'appear' || key == '_appear')) {
    //       this._hide();
    //     }
    //   },
    // );
  }

  get multiplication() {
    return {
      x: this.descriptor.multiplicationX,
      y: this.descriptor.multiplicationY,
    };
  }

  _scenes: string[];

  afterInit() {
    if (!this.resourceData.IRI.startsWith('multi')) {
      this.initMultiplications(this.multiplication);
    }

    // console.log('constraints', this.constraints);
    Object.entries(this.constraints).map(([point, pointsByShapeIRI]) => {
      Object.entries(pointsByShapeIRI || {}).map(([shapeIRI, targetPoint]) => {
        const shape = this.service.getShapeByIRI(shapeIRI);
        if (!shape) {
          return;
        }
        // console.log('add-reverse-constraint', [targetPoint, this.IRI, point]);
        set(shape.reverseConstraints, [targetPoint, this.IRI], point);
      });
    });

    this.updateFrameRectangle();

    // This is a tmp. solution, while it does not support disappear - appear loop
    if (this.descriptor.auxMode && this.parent.getType() === 'is') {
      this.hide();
      return;
    }

    if (this.parent?.states.length) {
      // this.hideByState(this.parent.states[0]);
    }

    // if (this.descriptor._hidden) {
    //   this._hide();
    // }

    if (this.position?.rotation) {
      // TODO
      // this.setRotation(this.position.rotation);
    }

    for (const shape of this.shapes.sort((s1, s2) => s1.index - s2.index)) {
      if (shape.maskedBy) {
        const baseShape = (this.service.getShapeByIRI(shape.maskedBy) ||
          this.service.getShapeByIRI(
            this.IRI + '_' + shape.maskedBy,
          )) as GeneralShape;

        if (!baseShape) {
          continue;
        }
        // shape.setMeAsMask(baseShape);
        baseShape.setMeAsMask(shape as GeneralShape, true);
      }
    }

    if (this.editable && this.container) {
      // this.registerOrientationPoints(); //
    }

    // TODO - make multiplied shape have some config flag
    if (this.config?.isRoot && !this.IRI.startsWith('multiplied')) {
      // -- subaru -- //

      this.subscriptions.push(
        ...[
          this.store
            .select(selectDescriptorValueByIRI(this.IRI, 'if'))
            .subscribe(ifDefinition => {
              this.ifDefinition = ifDefinition;
            }),
          this.store
            .select(selectFloatEffectByIRI(this.IRI))
            .subscribe(floatEffect => {
              this.floatEffect = floatEffect;
            }),
          this.store
            .select(baseShapeTransformByIRI(this.IRI))
            .pipe(filter(val => !!val))
            .subscribe((transform: ShapeTransform) => {
              this.baseShapeTransform = transform;
            }),
          this.store
            .select(baseDescriptorByIRI(this.IRI))
            .pipe(filter(val => !!val))
            .subscribe((descriptor: DescriptorType) => {
              this.baseDescriptor = descriptor;
            }),
          this.store
            .select(selectAnimationsByShapeByIRI(this.IRI))
            .pipe(filter(val => !!val))
            .subscribe((animations: Record<string, Animations>) => {
              this.animationsById = animations;
            }),
          this.store
            .select(selectCurrentShapeTranslate(this.IRI))
            .pipe(filter(val => !!Object.keys(val || {}).length))
            .subscribe((translate: Point) => {
              if (!isEqual(translate, this.translate)) {
                this.translate = translate;
                this.applyTranslate(cloneDeep(translate));
              }
            }),
          this.store
            .select(selectCurrentShapeScale(this.IRI))
            .pipe(skip(1))
            .pipe(filter(val => !!Object.keys(val || {}).length))
            .subscribe((scale: Scale) => {
              if (!isEqual(scale, this.scale)) {
                this.scale = scale;
                this.applyScale(cloneDeep(scale));
              }
            }),
          this.store
            .select(selectCurrentShapeRotation(this.IRI))
            .pipe(skip(1))
            .pipe(filter(val => !!Object.keys(val || {}).length))
            .subscribe((rotation: Rotation) => {
              if (!isEqual(rotation, this.rotation)) {
                this.rotation = cloneDeep(rotation);
                this.setShapeRotation(this.rotation);
              }
            }),
          this.store
            .select(multiplicationByIRI(this.IRI))
            .pipe(skip(2))
            .pipe(filter(({ x, y }) => !!x || !!y))
            .subscribe(multiplication => {
              if (_isEqual(this.currentMultiplication, multiplication)) {
                return;
              }
              this.currentMultiplication = multiplication;
              this.initMultiplications(multiplication);
            }),
        ],
      );
    }
  }

  getBounds(): { width: number; height: number } {
    return this.container.getBounds();
  }

  saveBBox() {
    if (this.descriptor.auxMode) {
      console.log('------saveBBox - auxMode > return ------');
      return;
    }

    if (this.IRI.startsWith('multi')) {
      return;
    }

    if (this.parent?.getType() == 'root-shape') {
      const { x, y, width, height } = this.container.getBounds();

      this.store.dispatch(
        setBBoxOfShape({
          IRI: this.IRI,
          bBox: {
            ...this.cs.getAbsoluteCoords(x, y),
            width: width / this.cs.canvasScale,
            height: height / this.cs.canvasScale,
          },
        }),
      );
    }
  }

  saveTransform() {
    this.saveTranslate();
    this.saveScale(this.scale);
  }

  saveTranslate(dx?: number, dy?: number) {
    const { x, y, relative } = (this.animationsById[
      this.cs.currentAnimation?.id
    ]?.translate?.value || {}) as Partial<Point>;
    if (relative) {
      this.cs.store.dispatch(
        setDescriptorValue({
          key: 'translate',
          IRI: this.IRI,
          value: {
            x: x + dx,
            y: y + dy,
            relative: true,
          },
        }),
      );
    } else {
      this.cs.store.dispatch(
        setDescriptorValue({
          key: 'translate',
          IRI: this.IRI,
          value: {
            x: this.x,
            y: this.y,
          },
        }),
      );
    }
  }

  saveScale(value: Scale) {
    this.cs.store.dispatch(
      setDescriptorValue({
        key: 'scale',
        IRI: this.IRI,
        value: cloneDeep(value),
      }),
    );
  }

  dispatchTranslate(value?: Point) {
    if (!value) {
      return;
    }
    // console.log('dispatch.translate', value);
    this.cs.store.dispatch(
      setDescriptorValue({
        key: 'translate',
        IRI: this.IRI,
        value,
      }),
    );
  }

  applyTranslate(translate: Point, context = '') {
    this._redraw(translate);
  }

  applyScale(scale: Scale) {}

  containerDims: Coords;

  applyTrajectoryTransform(value: TrajectoryMoveAnimation, t: number) {
    const { trajectoryShapeIRI, offset, end } = value;
    const targetPathShape = this.getShapeByIRI(trajectoryShapeIRI) as PathShape;

    let d: number;
    switch (t) {
      case 0:
        d = offset || 0;
        break;
      case 1:
        d = end || targetPathShape.__endLength;
        break;
      default:
        const start = offset || 0;
        const _end = end || targetPathShape.__endLength;
        d = start + (_end - start) * t;
    }

    const { x, y, angle } = targetPathShape.getPositionVector(d);
    const [width, height] = this.containerDims;

    this._redraw({
      x: +x.toFixed(4) - width / 2,
      y: +y.toFixed(4) - height / 2,
      rotation: {
        angle: +angle.toFixed(4),
      },
    });
  }

  // The unMoved elements for the group and multi-shape selection transformation

  _height: number;
  _width: number;

  saveUnMovedValues() {
    this._width = this.width;
    this._height = this.height;

    this._x = this.x;
    this._y = this.y;
  }

  setCenterTo(x: number, y: number) {
    this.setPosition({
      x: x - this._width / 2,
      y: y - this._height / 2,
    });
  }

  setWidthHeight() {
    if (!this.container) {
      // We should not be here
      return;
    }

    const { width, height } = this.container.getBounds();
    this._width = width;
    this._height = height;
  }

  initControlPoints() {
    if (this.editable) {
      Object.keys(this.controlPoints || {}).map(id =>
        this.initControlPointController(id),
      );
    }
  }

  postInit() {}

  get completeResourceData(): ResourceData {
    return {
      IRI: this.IRI,
      type: 'http://nowords.com#Shape',
      literals: {
        label: this.label,
        descriptor: this.descriptor,
      },
    };
  }

  _save(descriptor: Partial<DescriptorType>) {}

  save() {}

  transformationKeys: number[] = [];
  transformations: Record<string, Animation> = {};

  fromTo: Record<number, number> = {};
  toFrom: Record<number, number> = {};

  patches: Record<number, PatchStep | PatchStep[]> = {};
  lastPatchByKey: Record<string, number> = {};

  lastPatchIDByKey: Record<string, string> = {
    position: undefined,
    sections: undefined,
  };

  animationFrame: RootAnimationFrame;

  animationFrameByState: Record<string, RootAnimationFrame> = {};

  get allShapes(): GeneralShape[] {
    return [this];
  }

  /***************
   *  Animations
   **************/

  applyAnimationFrame(
    frame: AnimationFrameObject,
    params: {
      noCurrentSet?: boolean;
      indirect?: boolean;
    } = {},
  ) {
    const { noCurrentSet, indirect } = params;
    // console.log('apply-animation-frame', noCurrentSet);
    if (!noCurrentSet) {
      this.cs.currentAnimation = frame.frame;
    }
    const {
      id,
      duration,
      function: f,
      functionTarget,
      child,
      next,
      stateTransition,
    } = frame;

    if (stateTransition) {
      // const { from, to } = stateTransition;
      // for (const shape of this.shapes) {
      //   switch (shape.if) {
      //     case from:
      //       shape._hide();
      //       break;
      //     case to:
      //       // if (!shape.descriptor.hidden) {
      //       // }
      //       shape._show();
      //       break;
      //   }
      //   shape.applyAnimation(id, duration);
      // }
      return;
    }

    if (f) {
      let target: GeneralShape;
      if (functionTarget?.targetAlias) {
        target = this.shapes.find(
          shape => shape.label === functionTarget.targetAlias,
        );
      } else {
        target = (
          functionTarget ? this.service.getResource(functionTarget.IRI) : this
        ) as GeneralShape;
      }
      if (target) {
        // target.applyFunctionAnimation(f, frame);
        target.applyFunctionAnimation(f);
      } else {
        console.warn(`'${target}' could not be found for animation`);
      }
    } else {
      this.applyAnimation(id, duration);
      this.shapes.map((ps: GeneralShape) => ps.applyAnimation(id, duration));
    }

    if (child) {
      this.applyAnimationFrame(child, { indirect: true, noCurrentSet: true });
    }

    if (!indirect) {
      return;
    }

    // if (next) {
    //   this.applyAnimationFrame(next, { indirect, noCurrentSet });
    // }
  }

  applyFunctionAnimation(
    fcnName: string,
    // animationFrame: AnimationFrameObject
  ) {
    // if (!this.animationFrame) {
    //   // console.log('this', this);
    //   return;
    // }
    // let frame =
    //   fcnName === 'main'
    //     ? this.animationFrame
    //     : this.animationFrame.findFunction(fcnName);
    // // let frame = animationFrame.findFunction(fcnName);
    // if (!frame) {
    //   console.warn(`'${fcnName}' does not exist among the functions!`);
    //   return;
    // }
    // this.applyAnimationFrame(frame, { noCurrentSet: true, indirect: true });
  }

  patch(key: string, value: any, norefresh = false) {
    // TODO - we should never patch empty array of object
    console.log('patch', key, value);
    this.cs.store.dispatch(
      setShapeAttributeBaseAction({
        IRIs: [this.IRI],
        object: {
          [key]: value,
        },
      }),
    );
    return;
  }

  /***************************************************************************************/
  /**********************************  ANIMATION   ***************************************/
  /***************************************************************************************/

  setPreAnimationState() {
    return this._setPreAnimationState();
  }

  _setPreAnimationState() {
    // -- //
    // console.warn('set-pre-animation-state');
    if (!this.parent) {
      return;
    }

    const timeStore = this.parent.animationFrame?.timeStore;
    if (!timeStore) {
      return;
    }

    for (const { t, frames } of timeStore) {
      for (const frame of frames) {
        for (const { key, value } of this.getAnimationsById(frame)) {
          switch (key) {
            case 'trajectory-transform':
              this.applyTrajectoryTransform(
                value as TrajectoryMoveAnimation,
                0,
              );
              return true;
            case 'ease-in':
              const { dx, dy } = value as EaseInAnimation;
              this._redraw({
                x: this.x - dx || 0,
                y: this.y - dy || 0,
              });
              return false;
          }

          if (key == 'appear' || key == '_appear') {
            this._hide();
            return true;
          }
        }
      }
    }

    return false;
  }

  animationToCut: string;

  refreshSVG() {}

  patchOverride(key: string, value: any) {
    const patch = this.patches[this.cs.currentPatch];
    if (!patch) {
      return this.patch(key, value);
    }

    if (Array.isArray(patch)) {
      console.log('patchOverride - array', patch);
      const index = patch.findIndex(p => p.action.key === key);

      (this.patches[this.cs.currentPatch] as PatchStep[])[index].action.value =
        value;
    } else {
      (this.patches[this.cs.currentPatch] as PatchStep).action.value = value;
    }
  }

  _refresh({ key }: PatchStep['action']) {
    switch (key) {
      case 'transform':
        this.redraw();
        break;
      case 'sections':
        this.reInit();
        this.redraw();
        break;
      default:
        this.refresh();
    }
  }

  _apply(id: PatchStep) {}

  delete(fromFile = false) {
    this.remove();
    if (fromFile) {
      this.store.dispatch(deleteShapeFromFileAction({ IRI: this.IRI }));
    } else {
      this.store.dispatch(deleteShapeAction({ IRI: this.IRI }));
    }
  }

  centerScale(bBox: Coords, ratio: number) {
    const [width, height] = bBox;
    const [currentWidth, currentHeight] = [width, height].map(
      val => val * ratio,
    );
    this._redraw({
      x: this.x - (currentWidth - width) / 2,
      y: this.y - (currentHeight - height) / 2,
      scale: { x: this.scale.x * ratio, y: this.scale.y * ratio },
    });
  }

  /************************************** frame-rectangle *********************************************/

  frameRectangleElement: Rectanglelement;

  get hasFrameRectangle() {
    return false;
  }

  get frameRectangle() {
    return this.descriptor.frameRectangle;
  }

  get frameRectangleBounds() {
    return this.container.getBounds();
  }

  set frameRectangle(value: FrameRectangle) {
    this.descriptor.frameRectangle = value;
  }

  addFrameRectangle() {
    this.frameRectangle = {
      paddingHorizontal: 12,
      paddingVertical: 12,
      radius: 0,
      svgAttributes: {
        stroke: '#000000',
      },
    };
    this.updateFrameRectangle();
    this.save();
  }

  removeFrameRectangle() {
    this.frameRectangle = null;
    this.updateFrameRectangle();
    this.save();
  }

  editFrameRectangle(key: keyof FrameRectangle, value: any) {
    this.frameRectangle[key] = value;
    this.updateFrameRectangle();
  }

  updateFrameRectangle() {
    if (this.frameRectangle) {
      const { paddingHorizontal, paddingVertical, radius, svgAttributes } =
        this.frameRectangle;
      const { width, height } = this.frameRectangleBounds;
      const { fill, stroke, 'stroke-width': strokeWidth } = svgAttributes;

      const rectangleAttributes = {
        position: {
          x: -paddingHorizontal,
          y: -paddingVertical,
        },
        width: width + paddingHorizontal * 2,
        height: height + paddingVertical * 2,
        r: radius,
        fill,
        stroke,
        'stroke-width': strokeWidth,
        noFill: true,
      };

      if (!this.frameRectangleElement) {
        this.frameRectangleElement = new Rectanglelement(
          this,
          this.containerForRc,
          rectangleAttributes,
          0,
        ).click(() => this.clicked());
      } else {
        this.frameRectangleElement.patch(rectangleAttributes);
      }
    } else {
      this.frameRectangleElement?.remove();
      this.frameRectangleElement = null;
    }
  }

  toggleSelect() {
    this.selected ? this.deselect() : this.select();
    // this.cs.shapeSelected(this);
  }

  addMeToGroup(groupShape: GroupShape) {
    const { x, y } = groupShape;
    this.descriptor.childOf = groupShape.IRI;

    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'childOf',
        value: groupShape.IRI,
      }),
    );

    this.store.dispatch(
      setChildOf({
        child: this.IRI,
        parent: groupShape.IRI,
      }),
    );

    this.x -= x;
    this.y -= y;
    this.store.dispatch(
      setShapeTranslateBase({
        IRI: this.IRI,
        translate: {
          x: this.x,
          y: this.y,
        },
      }),
    );

    this.cs.previewShape.containerForChildren.removeChild(this.mainContainer);
    groupShape.containerForChildren.addChild(this.mainContainer);
    this.redraw();
    this.save();

    groupShape.addRelationship(
      { key: 'children', child: true, type: OperationType.Push },
      this,
    );
    this.lock();

    groupShape._shapes.push(this);
  }

  // saveByKey(key: AnimationKeys, value: any) {
  //   this.store.dispatch(
  //     setDescriptorValue({
  //       IRI: this.IRI,
  //       key,
  //       value,
  //     }),
  //   );
  // }

  lock() {
    this.locked = true;
    this.deselect();
  }

  unlock() {
    this.locked = false;
    this.select();
  }

  get groupShapeParent(): GroupShape {
    const parent = this.parent;
    if (parent?.type === ResourceType.GroupShape) {
      return parent as GroupShape;
    }
  }

  get rootShapeParent(): GroupShape {
    const parent = this.parent;
    if (parent?.getType() == 'root-shape') {
      return parent as GroupShape;
    }
  }

  get importedShapeParent(): GroupShape {
    const parent = this.parent;
    if (parent?.getType() == 'is') {
      return parent as GroupShape;
    }
  }

  get _importedShapeParent(): GroupShape {
    const isParent = this.importedShapeParent;
    if (isParent?.importedShapeParent) {
      return isParent._importedShapeParent;
    }
    return isParent;
  }

  select(params: ShapeSelectParams = {}) {
    if (this.cs.isPressed('l')) {
      this.isLog = true;
      console.log('set-is-log', this.label);
    }

    if (this.cs.mouseDownFree) {
      return;
    }

    if (
      (this.groupShapeParent && !this.groupShapeParent.groupEdit) ||
      this.importedShapeParent
    ) {
      this.deselectDisabled = true;
      setTimeout(() => (this.deselectDisabled = false), 5);
      return this.parent.select(params);
    }

    const { onHover, noDeselect } = params;
    if (onHover) {
      this.service.hoverSelect(this);
      this.hoverSelected = true;
      return this.rc?.show();
    }

    this.hoverSelected = true;

    // if (onHover) {
    //   if (!this.cs.isAltPressed) {
    //     this.rc?.show();
    //   }
    //   return;
    // }

    this.service.select(this);
  }

  deselectDisabled = false;
  hoverSelected = false;
  deselect(params: ShapeSelectParams = {}) {
    // if (this.cs.dragging) {
    //   return;
    // }

    if (this.deselectDisabled) {
      return;
    }

    if (
      (this.groupShapeParent && !this.groupShapeParent.groupEdit) ||
      this.importedShapeParent
    ) {
      this.parent.deselect(params);
    }

    const { onHover } = params;

    if (onHover) {
      if (this.selected) {
        return;
      }
      this.service.hoverDeselect(this);
      this.hoverSelected = false;
      return this.rc?.hide();
    }
    this.hoverSelected = false;
    this.service.deselect(this);
  }

  _select() {
    this.selected = true;
    this.showDragControllers();
  }

  showDragControllers() {
    this.rc?.show();
  }

  _deselect() {
    this.selected = false;
    this.hideDragControllers();
  }

  _dx: number;
  _dy: number;

  _x: number;
  _y: number;
  _scale: { x: number; y: number };
  _offsetX: number;
  _offsetY: number;

  _w: number;
  _h: number;

  startTransformation(dx = 0, dy = 0) {
    // This is the group transformation and here no rc is supposed to be shown
    // this.rc?.hide();

    this._dx = dx;
    this._dy = dy;

    this._x = this.x;
    this._y = this.y;

    this._offsetX = this.offsetX;
    this._offsetY = this.offsetY;

    this.circleContainer.visible = false;
  }

  translateX = 0;
  translateY = 0;

  transformation(scaleX = 1, scaleY = 1, dx?: number, dy?: number) {
    // console.log('this.x', this.x);
    // this.x = this._dx * (scaleX - 1) + this._x + this._offsetX * (scaleX - 1);
    // this.y = this._dy * (scaleY - 1) + this._y + this._offsetY * (scaleY - 1);

    // this.applyTranslate({
    //   x: this.x,
    //   y: this.y,
    // });
    // this.x += dx || 0;
    // this.y += dy || 0;

    // this.offsetX = this._offsetX * scaleX;
    // this.offsetY = this._offsetY * scaleY;

    this.translateX =
      this._dx * (scaleX - 1) + this._x + this._offsetX * (scaleX - 1);
    this.translateY =
      this._dy * (scaleY - 1) + this._y + this._offsetY * (scaleY - 1);

    this.applyTranslate({
      x: this.translateX,
      y: this.translateY,
    });

    this.translateX += dx || 0;
    this.translateY += dy || 0;

    this.offsetX = this._offsetX * scaleX;
    this.offsetY = this._offsetY * scaleY;

    this.rc?.patch(
      {
        width: this.width,
        height: this.height,
      },
      true,
    );
  }

  endTransformation() {
    this.cs.store.dispatch(
      setDescriptorValue({
        key: 'translate',
        IRI: this.IRI,
        value: {
          x: this.translateX,
          y: this.translateY,
        },
      }),
    );
    // this._x = this.x;
    // this._y = this.y;
    this.circleContainer.visible = true;
    // this.save();
    //this.saveTranslate();
    // this.saveScale();
  }

  removeRelationships(keys: Record<string, any>) {
    this._shapes = this._shapes.filter(({ IRI }) => !keys[IRI]);
  }

  _removeRelationship(key: string) {
    this._shapes = this._shapes.filter(({ IRI }) => IRI !== key);
  }

  get appearAnimation() {
    // TODO - check
    return null;
    // return Object.values(this.descriptor?.animationsByKey || {}).find(
    //   animation =>
    //     Array.isArray(animation)
    //       ? !!animation.find(({ type }) => type.includes('appear'))
    //       : animation.type.includes('appear')
    // );
  }

  reset() {}

  resetBaseState() {
    this.applyTranslate(this.baseShapeTransform.translate);
    this.applyScale(this.baseShapeTransform.scale);
    // this.baseShapeTransform = {
    //   translate: this.translate,
    //   scale: this.scale,
    //   rotation: this.rotation,
    // };
  }

  getNewShapeIndex() {
    const shapes = this.shapesByIndex;

    if (shapes.length == 0) {
      return 1;
    }

    // if (shapeIndexes.includes(NaN) || shapeIndexes(null)) {
    //   for (const shape of this.shapes) {
    //     if (isNaN(shape.index) || shape.index == 0)
    //   }
    // }

    const shapesToFix = [];

    let i = 1;
    let currentShape = shapes[shapes.length - i];
    let startIndex = 0;
    while (isNaN(currentShape.index) || currentShape.index == null) {
      shapesToFix.unshift(currentShape);

      i++;
      currentShape = shapes[shapes.length - i];
      startIndex = currentShape?.index;

      if (!currentShape) {
        startIndex = 0;
        break;
      }
    }

    if (shapesToFix.length) {
      console.warn(`Shape was found with NaN or null index`);
    }

    i = 1;
    for (const shape of shapesToFix) {
      shape.index = startIndex + i;
      shape.save();
      i++;
      currentShape = shape;
    }

    const index = currentShape.index;
    return index % 1 ? Math.ceil(index) : index + 1;
  }

  addCopiedShape(
    shape: GeneralShape<GeneralShapeDescriptor>,
    params: {
      noPositionAdjust?: boolean;
      noAnimation?: boolean;
    } = {},
  ) {
    const { noPositionAdjust, noAnimation } = params;

    const descriptor = cloneDeep(shape.descriptor);

    if (!noPositionAdjust) {
      descriptor.position.x = shape.x + 40 / this.cs.canvasScale;
      descriptor.position.y = shape.y + 40 / this.cs.canvasScale;
    }

    if (this.cs.currentState) {
      descriptor.if = { [this.cs.currentState]: true };
    }

    descriptor.index = shape.next
      ? (shape.index + shape.next.index) / 2
      : shape.index + 1;

    descriptor.auxMode = this.cs.auxMode;

    // TODO - fix
    if (this.cs.currentAnimation && !noPositionAdjust) {
      if (
        this.cs.currentAnimation.stateTransition &&
        this.cs.currentAnimation.stateTransition.to == this.cs.currentState
      ) {
        // to nothing
      } else {
        descriptor._animationsByKey = {
          [this.cs.currentAnimationFrame.id]: [{ key: 'appear' }],
        };
      }
    }

    if ((shape as PathShape).descriptor.sections) {
      (descriptor as PathShapeDescriptor).sections = (
        descriptor as PathShapeDescriptor
      ).sections.map(section => ({ ...section, id: Math.random().toString() }));
    }

    const rd: ResourceData = {
      IRI: `http://nowords.com#${uuidv1()}`,
      type: 'http://nowords.com#Shape',
      literals: {
        label: 'copied-shape',
        descriptor,
      },
      relationships: {
        ...shape.relationships,
        parent: this.IRI,
      },
    };

    // console.log('add-copied-shape', rd);

    // TODO - migrate

    this.store.dispatch(addShapeToStore({ data: cloneDeep(rd) }));
    this.store.dispatch(setNewShapeAction({ data: cloneDeep(rd) }));

    console.log('add-copied shape', rd);
    const newShape = this.service.initShape(rd, this, {
      copied: true,
    });

    this._shapes.push(newShape);
    newShape.select();

    if (shape.getType() == 'group-shape') {
      for (const child of shape.shapes) {
        child.descriptor.childOf = rd.IRI;
        newShape.addCopiedShape(child, {
          noAnimation: true,
          noPositionAdjust: true,
        });
      }
      (newShape as GroupShape).initRC(true);
    }

    return newShape;
  }

  selectedShapes() {
    if (!this.selected && !this.currentlyDragged) {
      return [];
    }
    return [this as GeneralShape];
  }

  // TODO - move this shapeService
  addImageShape(s3Id: string, [x, y]: Coords, base64: string) {
    console.log('base64', base64);
    this.service.addShape({
      label: 'image',
      descriptor: {
        type: 'image-shape',
        s3Id,
        position: { x, y },
      } as ImageShapeDescriptor,
      config: { base64 } as ImageShapeConfig,
    });
  }

  // This is call only by the root-shape //

  /**
   * This function is called only in the case preview-shape (root shape) when the canvas is zoomed
   * @param param0
   */

  setPosition({ x, y, offsetX, offsetY, scale }: ShapePosition): void {
    // TODO - revise that part
    this.x = x;
    this.y = y;
  }

  translateTo(x: number, y: number) {
    // console.log('translate-to', x, y);
    this.x = x;
    this.y = y;
    this.redraw();
  }

  redraw() {
    this.applyTranslate(this.translate);
    this.applyScale(this.scale);
    this.setShapeRotation(this.rotation);
    this.updateCircleContainers(this.translate.x, this.translate.y);
  }

  currentPosition: ShapePosition;

  incrementState(state: IncrementState, increment: number) {
    let { current, increment: ttIncrement } = state;
    current += +(increment * ttIncrement).toFixed(6);
    state.current = current;
    return current;
  }

  _redraw(position: ShapePosition) {
    if (!position) {
      console.warn('_redraw called with undefined position object!');
      return;
    }

    const { x, y } = position;
    this.currentPosition = position;
    if (this.parent?.getType() == 'is' && !this.config?.multiplicationIndexes) {
      if (this.getType() == 'is') {
        console.log('childOffset');
      }
      // console.log('------ inside is -------'); //
      this.container.setTransform(
        x - this.parent.childOffsetX || 0,
        y - this.parent.childOffsetY || 0,
      );

      this.updateCircleContainers(
        x - this.parent.childOffsetX || 0,
        y - this.parent.childOffsetY || 0,
      );
    } else {
      this.container?.setTransform(x, y);
      this.updateCircleContainers(x, y);
    }
    // const { width, height } = this.container.getBounds();
    // this.containerDims = [width, height];

    // by animation no circle container should updated
  }

  updateCircleContainers(x: number, y: number) {
    this.auxCircleContainer?.setTransform(x, y);
    this.sectionContainer?.setTransform(x, y);
    this.circleContainer?.setTransform(x, y);
  }

  get baseInputs(): TypeDef {
    return [
      {
        key: 'if',
        value: 'string',
      },
    ];
  }

  get inputs() {
    return [...this.baseInputs, ...(this.descriptor.inputs || [])];
  }

  get _inputs(): Record<string, any> {
    return {
      if: 'string',
      // y: 'number',
      // type: 'string',
      // fill: 'string',
      // stroke: 'string',
      // 'stroke-width': 'number',
      // opacity: 'number',
      // top: 'number',
      // left: 'number',
      // scale: 'number',
      // display: 'string',
      // h: 'number',
      // w: 'number',
      // r: 'number',
      // // TOOD - check if we need it
      // size: 'number',
      // // TODO - it is probably the input for path-shape
      // openPath: 'string',
      // align: 'string',
      // gap: 'number',
      // ...this.descriptor?.inputs,
    };
  }

  get align() {
    return this.ref('align');
  }

  get expressions() {
    return {
      top: 'y - h/2',
      bottom: 'y + h/2',
      left: 'x - w/2',
      right: 'x + w/2',
      ...this.descriptor?.exprs,
    };
  }

  removed = false;

  removeAll() {
    this.subscriptions.map(sub => sub.unsubscribe());
    this.shapes.map(shape => shape.removeAll());
  }

  remove() {
    this.removed = true;
    this.container.destroy();
    this.subscriptions.map(subscription => subscription.unsubscribe());
    this.rotationInstance = null;
    this.opacityIncrement = null;
    this.service.deleteResource(this);

    this.multipliedShapesArray.map(shape => shape.remove());

    this.rc?.remove();

    if (this.parent) {
      this.parent.containerForChildren.removeChild(this.container);
      this.parent.circleContainer.removeChild(this.circleContainer);
      this.parent.auxCircleContainer.removeChild(this.auxCircleContainer);
    } else {
      // - no-parent
    }

    // this.shapes.map(shape => shape.remove());
  }

  __refresh() {
    this.refresh();
  }

  refresh() {
    // this.rc?.refresh();
    this.children.map(({ resource }) => resource.refresh());
  }

  /**************************** Save / Descriptor ****************************/

  get resourceDescriptor(): ResourceData {
    return {
      IRI: this.IRI,
      literals: {
        descriptor: this.descriptor,
      },
    };
  }

  get _resourceDescriptor(): ResourceData {
    return {
      IRI: this.IRI,
      literals: {
        descriptor: this.descriptor,
      },
    };
  }

  /********************************** Drag ***********************************/

  dx = 0;
  dy = 0;
  diffs: Coords;

  get multipliedShapesArray() {
    return Object.values(this.multipliedShapes || {});
  }

  getConstrainedShapes(constraintSourceShape?: string) {
    return Object.values(this.controlPoints)
      .reduce((acc, { constrainedTo: constrainedToArray }) => {
        // TODO - remove that later
        this.asArray(constrainedToArray)
          .filter(constrainedTo => {
            const cond =
              !!constrainedTo &&
              (!constraintSourceShape ||
                !constraintSourceShape.endsWith(constrainedTo.shape));
            // console.log('filter-cond', cond); //
            return cond;
          })
          .map(constrainedTo => {
            acc.push(this.getResource(constrainedTo.shape) as GeneralShape);
          });
        return acc;
      }, [])
      .filter(shape => shape && !shape.selected);
  }

  getResource(IRI: string) {
    if (this.parent?.getType() === 'is' || this.getType() == 'is') {
      return (
        this.service.getResource(`${this.parent?.IRI}_${IRI}`) ||
        this.service.getResource(`${this.IRI}_${IRI}`) ||
        this.service.getResource(IRI)
      );
    }
    return this.service.getResource(IRI);
  }

  dragBase: [number, number];
  iamDragged = false;

  completeDrag(dx: number, dy: number) {
    this.startBaseDrag();
    this.drag(dx, dy);
    this.endDrag();
  }

  // startDrag(fromConstraint?: string, context?: string) {
  startBaseDrag(
    startPoint?: string,
    sourceShapeIRIs?: Record<string, boolean>,
    noConstraint = false,
  ) {
    // if (context) {
    //   this.dragProcesses[context] = false;
    // }

    this.iamDragged = true;

    this.dragBase = [this.x, this.y];

    // this.clearOrientationPoints();
    // this.getConstrainedShapes(fromConstraint).map(shape => {
    //   shape.startDrag(this.IRI, 'translate');
    // });

    if (noConstraint) {
      return;
    }

    // -- // -- // -- //

    Object.values(this.controlPoints || {}).map(({ constrainedTo }) => {
      Object.keys(constrainedTo || {})
        .filter(IRI => !sourceShapeIRIs?.[IRI])
        .map(IRI => {
          const shape = this.service.getShapeByIRI(IRI);
          if (!shape) {
            console.warn('Constained shape could not be found');
            return;
          }
          this.constrainedShapes.push(shape);
          shape.startBaseDrag('', {
            [this.IRI]: true,
            ...(sourceShapeIRIs || {}),
          });
        });
    });

    // Object.entries({
    //   ...(this.constraints || {}),
    //   ...(this.reverseConstraints || {}),
    // })
    //   .filter(([point]) => point !== startPoint)
    //   .map(([point, pointsByShape]) => {
    //     Object.entries(pointsByShape)
    //       .filter(([shapeIRI]) => shapeIRI !== sourceShapeIRI)
    //       .map(([shapeIRI, targetPoint]) => {
    //         const shape = this.service.getShapeByIRI(shapeIRI);
    //         if (!shape) {
    //           return;
    //         }
    //         this.constrainedShapes.push(shape);
    //         shape.startBaseDrag(targetPoint, sourceShapeIRI || this.IRI);
    //       });
    //   });
  }

  hOrientationLines: PathElement[] = [];

  showHorizontalOrientationLines(
    configs: Array<{ position: ShapePosition; x: number; y: number }> = [],
  ) {
    this.hideHOrientationLines();
    this.hOrientationLines = configs.map(({ position, x, y }) => {
      return new PathElement(this, this.cs.previewShape.circleContainer, {
        position,
        x,
        y,
        stroke: '#ff0000',
        'stroke-width': 1,
        'stroke-opacity': 0.5,
      });
    });
  }

  hideHOrientationLines() {
    this.hOrientationLines.map(line => line.remove());
  }

  hideVOrientationLines() {
    this.vOrientationLines.map(line => line.remove());
  }

  hideOrientationLines() {
    this.hideHOrientationLines();
    this.hideVOrientationLines();
  }

  vOrientationLines: PathElement[] = [];
  showVerticalOrientationLines(
    configs: Array<{ position: ShapePosition; x: number; y: number }> = [],
  ) {
    this.hideVOrientationLines();
    this.vOrientationLines = configs.map(({ position, x, y }) => {
      return new PathElement(this, this.cs.previewShape.circleContainer, {
        position,
        x,
        y,
        stroke: '#ff0000',
        'stroke-width': 1,
        'stroke-opacity': 0.5,
      });
    });
  }

  checkHorizontal(
    [upX, upY, upOffset]: eCoords,
    [downX, downY, downOffset]: eCoords,
    [dx, dy]: Coords,
  ) {
    if (this.cs.isSpacePressed) {
      return [dx, []] as [number, OrientationLineCandidate[]];
    }

    const resultUp = this.orientationService.checkHorizontalUp(
      this.IRI,
      [upX, upY, upOffset],
      [dx, dy],
    );
    const resultDown = this.orientationService.checkHorizontalDown(
      this.IRI,
      [downX, downY, downOffset],
      [dx, dy],
    );
    return this.filterHorizontalkOrientationResults(
      [resultUp, resultDown],
      dx,
      true,
    );
  }

  checkVertical(
    [leftX, leftY, leftOffset]: eCoords,
    [rightX, rightY, rightOffset]: eCoords,
    [dx, dy]: Coords,
  ) {
    if (this.cs.isSpacePressed) {
      return [dy, []] as [number, OrientationLineCandidate[]];
    }

    const resultUp = this.orientationService.checkVerticalLeft(
      this.IRI,
      [leftX, leftY, leftOffset],
      [dx, dy],
    );
    const resultDown = this.orientationService.checkVerticalRight(
      this.IRI,
      [rightX, rightY, rightOffset],
      [dx, dy],
    );
    return this.filterVerticalOrientationResults(
      [resultUp, resultDown],
      dy,
      true,
    );
  }

  checkHorizontalOrientation(
    dx: number,
    dy: number,
    { x, y, width, height }: BBox,
    skipCenter = false,
  ) {
    const resultLeft = this.orientationService.checkHorizontalUp(
      this.IRI,
      [x, y],
      [dx, dy],
    );

    const resultCenter = skipCenter
      ? null
      : this.orientationService.checkHorizontalUp(
          this.IRI,
          [x + width / 2, y],
          [dx, dy],
        );

    const resultRight = this.orientationService.checkHorizontalUp(
      this.IRI,
      [x + width, y],
      [dx, dy],
    );

    const resultLeftD = this.orientationService.checkHorizontalDown(
      this.IRI,
      [x, y + height],
      [dx, dy],
    );

    const resultCenterD = skipCenter
      ? null
      : this.orientationService.checkHorizontalDown(
          this.IRI,
          [x + width / 2, y + height],
          [dx, dy],
        );

    const resultRightD = this.orientationService.checkHorizontalDown(
      this.IRI,
      [x + width, y + height],
      [dx, dy],
    );

    return this.filterHorizontalkOrientationResults(
      [
        resultLeft,
        resultLeftD,
        resultCenter,
        resultCenterD,
        resultRight,
        resultRightD,
      ],
      dx,
    );
  }

  filterHorizontalkOrientationResults(
    _results: (FoundOrientation | void)[],
    dx: number,
    strict = false,
  ): [number, OrientationLineCandidate[]] {
    let minDx = Infinity;

    const results = _results
      .map((result, i) => {
        if (result) {
          let dx: number;
          const { internalPoint, externalPoint, offset } = result;
          const [ex, ey] = externalPoint;
          const [ix, iy] = internalPoint;
          dx = ex - ix;

          minDx = Math.min(minDx, dx);
          return { dx, internalPoint, externalPoint, offset };
        }
      })
      .filter(val => !!val)
      .filter(({ dx }) => dx == minDx)
      .map(({ internalPoint, externalPoint, offset }) => ({
        internalPoint,
        externalPoint,
        offset,
      }));

    if (minDx !== Infinity) {
      dx = minDx;
    }

    if (strict && results.length > 1) {
      return [dx, [results[0]]];
    }

    return [dx, results];
  }

  getCurrentBBox() {
    const { x, y, width, height } = this.container.getBounds();
    return {
      // x: this.x,
      // y: this.y,
      x,
      y,
      width: width / this.cs.canvasScale,
      height: height / this.cs.canvasScale,
    };
  }

  checkVerticalOrientation(
    dx: number,
    dy: number,
    { x, y, width, height }: BBox,
    skipCenter = false,
  ) {
    const leftTop = this.orientationService.checkVerticalLeft(
      this.IRI,
      [x, y],
      [dx, dy],
    );

    const leftCenter = skipCenter
      ? null
      : this.orientationService.checkVerticalLeft(
          this.IRI,
          [x, y + height / 2],
          [dx, dy],
        );

    const leftBottom = this.orientationService.checkVerticalLeft(
      this.IRI,
      [x, y + height],
      [dx, dy],
    );

    const rightTop = this.orientationService.checkVerticalRight(
      this.IRI,
      [x + width, y],
      [dx, dy],
    );

    const rightCenter = skipCenter
      ? null
      : this.orientationService.checkVerticalRight(
          this.IRI,
          [x + width, y + height / 2],
          [dx, dy],
        );

    const rightBottom = this.orientationService.checkVerticalRight(
      this.IRI,
      [x + width, y + height],
      [dx, dy],
    );

    return this.filterVerticalOrientationResults(
      [leftTop, rightTop, leftCenter, rightCenter, leftBottom, rightBottom],
      dy,
    );
  }

  filterVerticalOrientationResults(
    _results: (FoundOrientation | void)[],
    dy: number,
    strict = false,
  ): [number, OrientationLineCandidate[]] {
    let minDy = Infinity;

    const results = _results
      .map((result, i) => {
        if (result) {
          let dy: number;
          const { internalPoint, externalPoint, offset } = result;
          const [ex, ey] = externalPoint;
          const [ix, iy] = internalPoint;
          dy = ey - iy;

          minDy = Math.min(minDy, dy);
          return { dy, internalPoint, externalPoint, offset };
        }
      })
      .filter(val => !!val)
      .filter(({ dy }) => dy == minDy)
      .map(({ internalPoint, externalPoint, offset }) => ({
        internalPoint,
        externalPoint,
        offset,
      }));

    if (minDy !== Infinity) {
      dy = minDy;
    }

    if (strict && results.length > 1) {
      return [dy, [results[0]]];
    }

    return [dy, results];
  }

  prepareVerticalOrientationLines(results: any[], dx: number, width: number) {
    return;
  }

  drag(dx: number, dy: number, params: ShapeDragParams = {}) {
    // console.log('---------- drag ---------', dx, dy);
    // const { orientationCheck } = params;
    // if (orientationCheck) {
    //   const { height, width } = this.getCurrentBBox();

    //   const [newDy, hLines] = this.checkVerticalOrientation(dx, dy, {
    //     x: this._x,
    //     y: this._y,
    //     width,
    //     height,
    //   });

    //   const [newDx, vLines] = this.checkHorizontalOrientation(dx, dy, {
    //     x: this._x,
    //     y: this._y,
    //     width,
    //     height,
    //   });

    //   dx = newDx;
    //   dy = newDy;

    //   this.orientationService.showHorizontalOrientationLines(
    //     this,
    //     hLines,
    //     dx,
    //     dy
    //   );
    //   this.orientationService.showVerticalOrientationLines(
    //     this,
    //     vLines,
    //     dx,
    //     dy
    //   );
    // }

    // TODO - use only one //

    this.constrainedShapes.map(shape => shape.drag(dx, dy));

    this.dx = dx;
    this.dy = dy;
    this.diffs = [dx, dy];
    const [x, y] = this.dragBase;
    this._redraw({
      x: x + dx,
      y: y + dy,
    });

    // -- //
    // -- // this.multipliedShapesArray.map(shape => shape.drag(dx, dy));
    // -- //
    if (!this.selected) {
      this.rc?.hide();
    }
  }

  endDrag(
    dx?: number,
    dy?: number,
    { noShow, fromConstraint }: ShapeDragParams = {},
  ) {
    this.constrainedShapes.map(shape => shape?._endDrag());
    this.constrainedShapes = [];

    if (this.dx == 0 && this.dy == 0) {
      return;
    }

    this.iamDragged = false;
    // this.hideHOrientationLines();
    // this.hideVOrientationLines();
    // this.updateControlPoints();
    // this.getConstrainedShapes(fromConstraint).map(shape =>
    //   shape.endDrag(dx, dy, this.IRI)
    // );

    const [x, y] = this.dragBase;
    this.x = x + this.dx;
    this.y = y + this.dy;
    this._x = this.x;
    this._y = this.y;

    // this.registerOrientationPoints();

    this.saveTranslate(this.dx, this.dy);
    this.dx = 0;
    this.dy = 0;
  }

  get myChildOffsetX() {
    return this.parent?.childOffsetX || 0;
  }

  get myChildOffsetY() {
    return this.parent?.childOffsetY || 0;
  }

  get currentX() {
    return this._x + this.dx;
  }

  get currentY() {
    return this._y + this.dy;
  }

  get leftX() {
    return this.bBox?.x + this.dx;
  }

  get centerX() {
    return this.bBox?.x + this.bBox?.width / 2 + this.dx;
  }

  get rightX() {
    return this.bBox?.x + this.bBox?.width + this.dx;
  }

  get topY() {
    return this.bBox?.y + this.dy;
  }

  get centerY() {
    return this.bBox?.y + this.bBox?.height / 2 + this.dy;
  }

  get bottomY() {
    return this.bBox?.y + this.bBox?.height + this.dy;
  }

  get leftTop(): Coords {
    return [this.leftX, this.topY];
  }

  get centerTop(): Coords {
    return [this.centerX, this.topY];
  }

  get rightTop(): Coords {
    return [this.rightX, this.topY];
  }

  get leftBottom(): Coords {
    return [this.leftX, this.bottomY];
  }

  get centerBottom(): Coords {
    return [this.centerX, this.bottomY];
  }

  get rightBottom(): Coords {
    return [this.rightX, this.bottomY];
  }

  get leftCenter(): Coords {
    return [this.leftX, this.centerY];
  }

  get rightCenter(): Coords {
    return [this.rightX, this.centerY];
  }

  bBox: { x: number; y: number; width: number; height: number };

  get verticalOrientationPoints() {
    const { x, y, width, height } = this.bBox;
    // -- // -- //

    return [];
  }

  closeOrientationLines() {
    // this.orientationService.clearOrientationLines(); //
    this.registerOrientationPoints();
  }

  clearOrientationPoints() {
    return;
    this.horizontalUpKeys.map(key =>
      this.orientationService.clearHorizontalUp(key),
    );
    this.horizontalDownKeys.map(key =>
      this.orientationService.clearHorizontalDown(key),
    );

    this.verticalLeftKeys.map(key =>
      this.orientationService.clearVerticalLeft(key),
    );
    this.verticalRightKeys.map(key =>
      this.orientationService.clearVerticalRight(key),
    );
  }

  horizontalUpKeys = [];
  horizontalDownKeys = [];

  verticalLeftKeys = [];
  verticalRightKeys = [];

  registerOrientationPoints() {
    return;
    /*
    x -------------- x --------------- x
    |                                  |
    x                                  x
    |                                  |
    x -------------- x --------------- x
    */

    if (this.getType() == 'root-shape') {
      return;
    }

    this.bBox = this.getCurrentBBox();

    this.clearOrientationPoints();

    this.horizontalUpKeys = [
      this.leftBottom,
      this.centerBottom,
      this.rightBottom,
      // this.leftTop,
      // this.centerTop,
      // this.rightTop,
    ].map(([x, y]) =>
      this.orientationService.registerHorizontalUp(this.IRI, x, y),
    );

    this.horizontalDownKeys = [
      // this.leftBottom,
      // this.centerBottom,
      // this.rightBottom,
      this.leftTop,
      this.centerTop,
      this.rightTop,
    ].map(([x, y]) =>
      this.orientationService.registerHorizontalDown(this.IRI, x, y),
    );

    this.verticalLeftKeys.map(key =>
      this.orientationService.clearVerticalLeft(key),
    );
    this.verticalRightKeys.map(key =>
      this.orientationService.clearVerticalRight(key),
    );

    this.verticalRightKeys = [
      this.leftTop,
      this.leftCenter,
      this.leftBottom,
      // this.rightTop,
      // this.rightCenter,
      // this.rightBottom,
    ].map(([x, y]) =>
      this.orientationService.registerVerticalRight(this.IRI, x, y),
    );

    this.verticalLeftKeys = [
      // this.leftTop,
      // this.leftCenter,
      // this.leftBottom,
      this.rightTop,
      this.rightCenter,
      this.rightBottom,
    ].map(([x, y]) =>
      this.orientationService.registerVerticalLeft(this.IRI, x, y),
    );
  }

  updateRectangleController() {
    // to be implemented
  }

  checkOrientationPoints() {}

  updateOrientationPoints() {
    this.registerOrientationPoints();
  }

  incrementalDragShape(dx: number, dy: number, fromConstraint: string) {
    // this.dragBase ||= [this.currentX, this.currentY]; //
    // this.dragBase ||= [this.currentX, this.currentY]; //
    // const [x0, y0] = this.dragBase; //

    const [x0, y0] = [this.x, this.y];
    this.dx += dx;
    this.dy += dy;
    this.translateTo(x0 + this.dx, y0 + this.dy);
    this.getConstrainedShapes(fromConstraint).map(shape =>
      shape.incrementalDragShape(dx, dy, this.IRI),
    );
  }

  updateControlPoints() {
    Object.entries(this.controlPoints || {}).map(([id, value]) => {
      this.controlPointControllers?.[id]?.patch({
        // controlPoint: {
        //   shapeIRI: this.IRI,
        //   point: id,
        //   absCoords: this.getAbsCoords(value.coords),
        // },
      });
    });
  }

  childIndex: number;

  /********************************** Animations ***********************************/

  reInitById(id: string) {}

  reInit() {
    this.shapes.map(shape => shape.remove());
    this.initShapes();
  }

  animationIsInProgress = false;

  pendingAnimation: {
    timestamp: number;
    duration: number;
    animation: Animation;
  };

  _animationState: {
    inProgress?: string; // the id of the animation that is in progress,
    finishedCounter?: number;
  } = {};

  _pendingAnimation: {
    timestamp: number;
    duration: number;
    id: string;
  };

  getShapeByIRI(iri: string) {
    return this.getResource(iri) as GeneralShape;
  }

  // The id is gonna be the prefixed id
  hasAnimation(id: string) {
    return Object.entries(this.animationsById?.[id] || {}).length > 0;
  }

  show() {
    this.saveDescriptorKey('hidden', false);
    this._show();
  }

  hide() {
    // if (this.isAnimationInProgress) {
    //   this.animationsById ||= {};
    //   this.animationsById[this.cs.currentAnimation.id] ||= [];
    //   if (
    //     this.animationsById[this.cs.currentAnimation.id].find(
    //       ({ key }) => key === 'disappear',
    //     )
    //   ) {
    //     this.cs.notify('There is already disappear animation for that frame!');
    //   } else {
    //     this.animationsById[this.cs.currentAnimation.id].push({
    //       key: 'disappear',
    //     });
    //     this.save();
    //   }
    // } else {
    //   this.descriptor.hidden = true;
    //   this.save();
    // }
    this.saveDescriptorKey('hidden', true);
    this._hide();
  }

  rotationInstance: {
    cx?: number;
    cy?: number;
    increment: number;
    degSum?: number;
    degMax?: number;
    constraints?: Array<{
      p: Coords;
      shapeIRI: string;
    }>;
  };

  rotated = false;

  currentOpacity: number;
  opacityIncrement: number;

  dTranslate: ShapePosition & { localIncrement: number };

  trajectoryTransformIncrement: number;
  trajectoryDTransform: TrajectoryDTransform[];

  lastAnimation: any;

  get _scaleMatrix() {
    return this.currentMatrix;
  }

  animationsByCode: Record<string, __Animation>;
  currentAnimationId: string;

  setAnimationByCode(animation: AnimationItem) {
    if (!this.currentAnimationId) {
      throw new Error(
        'currentAnimationId must be defined for set animation by code!',
      );
    }
    this.animationsByCode ||= {};
    this.animationsByCode[this.currentAnimationId] ||= [];
    this.animationsByCode[this.currentAnimationId].push(animation);
  }

  incrementMappers: Record<string, IncrementMapper> = {};

  lastTranslateValue: { x: number; y: number };

  startAnimation(
    id: string,
    division: number,
    inverse = false,
    duration?: number,
  ) {
    if (this.removed) {
      return;
    }

    this.currentAnimationId = id;

    // this.multipliedShapesArray.map(shape => shape.currentAnimationId = id);
    // (this.animationCodesById[id] || []).map(code => {
    //   const expr = this.msService.compile(code);
    //   MSEvaluationService.evaluate(this, expr, 'animation');
    // })

    // console.log('prepare-key-value animation', this.getAnimationsById(id));

    this.getAnimationsById(id).map(animation => {
      this.prepareKeyValueAnimation(animation, division, duration, inverse);
    });
  }

  animationControllers: Record<string, AnimationController> = {};

  dropShadowIncrement: {
    increment: number;
    current: number;
  };

  getAnimationFunctionValue(animation: AnimationItem): Partial<AnimationItem> {
    const { key, fcn } = animation;
    if (fcn == 'original') {
      switch (key) {
        case 'rotation':
          return { value: { angle: 0 } };
        case 'scale':
        case 'translate':
          return { value: this.baseShapeTransform[key] };
      }
    }

    let descriptor: GeneralShapeDescriptor = this.cs.previewShape.descriptor;
    if (this.parent?.getType() == 'is') {
      descriptor = (this.parent as ImportedShape).descriptor
        .baseShapeDescriptor;
    }

    return descriptor.animationFunctions?.[key]?.[fcn];
  }

  trajectoryTransformAnimationState: IncrementState;

  lastAngle: number;

  _cx: number;
  _cy: number;

  prepareKeyValueAnimation(
    animation: AnimationItem,
    division: number,
    duration?: number,
    inverse = false,
  ) {
    if (!animation) {
      console.log('no-animation', this);
      return;
    }
    let { key, value, meta, fcn: f } = animation;
    // console.log(key, 'meta', meta);
    if (f) {
      const { timing } = meta || {};
      if (f) {
        const fcnAnimation = this.getAnimationFunctionValue(
          animation,
        ) as AnimationItem;
        value = fcnAnimation.value;
        console.log('fcnAnimation', fcnAnimation);
      }

      switch (timing) {
        case 'start':
          this.incrementMappers[key] = new StartIncrementMapper(division);
          break;
        case 'end':
          this.incrementMappers[key] = new EndIncrementMapper(division);
          break;
      }
    }

    switch (key) {
      case 'dropShadow-remove':
        this.dropShadowIncrement = { increment: -1 / division, current: 1 };
        break;
      case 'dropShadow':
        this.dropShadowIncrement = { increment: 1 / division, current: 0 };
        this.initDropShadowElement(value as DropShadowConfig);
        break;
      case 'translate':
        this.translateAnimationIsInProgress = true;
        this.stopFloat();
        this.prepareTranslateAnimation(
          value as Point,
          division,
          meta?.ease,
          inverse,
        );
        break;
      case 'scale':
        this.prepareScaleAnimation(
          value as Scale,
          division,
          meta?.ease,
          inverse,
        );
        break;
      case 'rotation':
        const { angle, controlPoint } = value as Rotation;
        this.constrainedBy =
          Object.keys(this.constraints)[0] ||
          Object.keys(this.reverseConstraints)[0];
        if (this.constrainedBy) {
          this.prepareRotation(this.constrainedBy);
        } else {
          // defaults to center
          this.prepareRotation(controlPoint);
        }

        if (inverse) {
          this.rotationIncrement = new IncrementController(
            this.lastAngle,
            angle - this.lastAngle,
            division,
            meta?.ease,
          );
        } else {
          this.lastAngle = angle;
          this.rotationIncrement = new IncrementController(
            this.angle,
            angle,
            division,
            meta?.ease,
          );
        }
        break;
      case 'ease-in':
        if (!this.translateXIncrementController) {
          return;
        }
        const { dx, dy } = value as EaseInAnimation;
        this.translateXIncrementController = new IncrementController(
          -dx || 0,
          0,
          division,
        );
        this.translateYIncrementController = new IncrementController(
          -dy || 0,
          0,
          division,
        );
        break;

      case 'blink':
        this.animationControllers['blink'] = new BlinkAnimationController(
          this,
          value as BlinkAnimation,
          division,
        );
        break;

      case 'trajectory-transform':
        // TODO - move-it-back
        const { trajectoryShapeIRI, mode } = value as TrajectoryMoveAnimation;
        // const trajectoryShape: PathShape = this.getShapeByIRI(
        //   trajectoryShapeIRI
        // ) as PathShape;

        this.trajectoryShape = this.service.getShapeByIRI(
          trajectoryShapeIRI,
        ) as PathShape;

        // this.parent.shapes.find(shape =>
        //   shape.IRI.startsWith(trajectoryShapeIRI),
        // ) as PathShape;

        if (this.trajectoryShape) {
          const { width, height } = this.container.getBounds();
          this.trajectoryTransformOffset = [
            width / 2 / this.cs.canvasScale,
            height / 2 / this.cs.canvasScale,
          ];

          this.trajectoryTranslateController = new IncrementController(
            mode == 'reverse' ? this.trajectoryShape.endLength : 0,
            mode == 'reverse' ? 0 : this.trajectoryShape.endLength,
            division,
            Easing.LINEAR,
          );
          // -- // -- // -- //
        } else {
          console.warn(
            `Trajectory shape with IRI - '${trajectoryShapeIRI}' could not be found!`,
          );
        }

        break;

      case 'floatEffect':
        const { x: xAmp, xInterval, y: yAmp, yInterval } = value as FloatEffect;
        if (xAmp && xInterval) {
          this.xSineIncement = new SineIncrementController(
            this.x,
            this.x + xAmp,
            xInterval * this.cs.batchPerSecond,
          );
        }
        if (yAmp && yInterval) {
          this.ySineIncement = new SineIncrementController(
            this.y,
            this.y + yAmp,
            yInterval * this.cs.batchPerSecond,
          );
        }
        break;
      case 'show-hide':
        const { value: show, x, y } = value as ShowHideAnimation;
        if (show) {
          if (meta?.ease == 'start') {
            return this._show();
          }

          if (meta?.ease == Easing.RANDOM) {
            const timeout = duration * Math.random();
            setTimeout(() => this._show(), timeout * 1_000);
            return;
          }
          this.updateOpacity(0);
          this.prepareAppearAnimation(division);

          if (x || y) {
            this._redraw({
              x: this.x - (x || 0),
              y: this.y - (y || 0),
            });

            if (x) {
              this.showHideXTranslateController = new IncrementController(
                this.x - x,
                this.x,
                division,
                meta?.ease || Easing.SMOOTH,
              );
            }
            if (y) {
              this.showHideYTranslateController = new IncrementController(
                this.y - y,
                this.y,
                division,
                meta?.ease || Easing.SMOOTH,
              );
            }
          }
        } else {
          if (meta?.ease == 'start') {
            return this._hide();
          }
          this.prepareDisappearAnimation(division);
        }
        break;
      case 'appear':
        this.prepareAppearAnimation(division);
        break;
      case 'disappear':
        this.prepareDisappearAnimation(division);
        break;

      case 'svgAttributes.fill-opacity':
      case 'svgAttributes.opacity':
        this.currentOpacity = get(this.descriptor, key) || 1;
        console.log(
          'opacity-increment',
          this.currentOpacity,
          value,
          'division',
          division,
        );
        this.opacityIncrement =
          ((value as number) - this.currentOpacity) / division;
        break;

      default:
        return;
        if (key.startsWith('svgAttr')) {
          const [, svgKey] = key.split('.');
          this.svgAttributeOverride[svgKey] = value;
          this.refresh();
        }
    }
  }

  trajectoryShape: PathShape;
  trajectoryTranslateController: IncrementController;
  trajectoryTransformOffset: Coords;
  showHideXTranslateController: IncrementController;
  showHideYTranslateController: IncrementController;

  get scaleX() {
    return this.scale?.x || 1;
  }

  get scaleY() {
    return this.scale?.y || 1;
  }

  set scaleX(val: number) {
    this.scale.x = val;
  }

  set scaleY(val: number) {
    this.scale.y = val;
  }

  // x = 0;
  // y = 0;

  zoomUpdate() {
    this.rc?.zoomUpdate();
  }

  initDropShadowElement(config: DropShadowConfig) {
    // -- //
  }

  translateXIncrementController: IncrementController;
  translateYIncrementController: IncrementController;

  incrementControllers: Record<string, IncrementController> = {};

  prepareTranslateAnimation(
    value: Point,
    division: number,
    ease = Easing.LINEAR,
    inverse = false,
  ) {
    let startX: number;
    let startY: number;

    let endX: number;
    let endY: number;
    const { x, y, relative } = value || {};

    if (ease == Easing.START) {
      this.x = x;
      this.y = y;
      this.applyTranslate({ x, y });
      return;
    }

    if (inverse) {
      endX = this.lastTranslateValue.x;
      endY = this.lastTranslateValue.y;

      startX = relative ? this.lastTranslateValue.x - value.x : value.x;
      startY = relative ? this.lastTranslateValue.y - value.y : value.y;
    } else {
      startX = this.x;
      startY = this.y;

      endX = relative ? this.x + value.x : value.x;
      endY = relative ? this.y + value.y : value.y;

      this.lastTranslateValue = {
        x: this.x,
        y: this.y,
      };
    }

    this.translateXIncrementController = new IncrementController(
      startX,
      endX,
      division,
      ease,
    );
    this.translateYIncrementController = new IncrementController(
      startY,
      endY,
      division,
      ease,
    );
  }

  lastScale: Scale;

  scaleXIncrementController: IncrementController;
  scaleYIncrementController: IncrementController;

  prepareScaleAnimation(
    value: Scale,
    division: number,
    ease: Easing,
    inverse = false,
  ) {
    if (!this.scale) {
      return;
    }

    let sx: number, sy: number, ex: number, ey: number;

    if (inverse) {
      sx = value.x;
      sy = value.y;
      ex = this.lastScale.x;
      ey = this.lastScale.y;
    } else {
      this.lastScale = this.scale;

      sx = this.scale.x;
      sy = this.scale.y;
      ex = value.x;
      ey = value.y;
    }

    this.scaleXIncrementController = new IncrementController(
      sx,
      ex,
      division,
      ease,
    );
    this.scaleYIncrementController = new IncrementController(
      sy,
      ey,
      division,
      ease,
    );
  }

  logPosition() {
    // console.log('tx', this.x, 'ty', this.y);
    // console.log('sx', this.scaleX, 'sy', this._scaleY);
    return;
  }

  prepareAppearAnimation(division: number) {
    // this.show(); //
    if (division !== 0) {
      this.currentOpacity = 0;
      this.opacityIncrement = 1 / division;
      this.currentOpacity = this.opacityIncrement;
      this.updateOpacity(this.currentOpacity);
    }
    this._show();
  }

  prepareDisappearAnimation(division: number) {
    // this.show();
    if (division === 0) {
      this.hide();
    } else {
      this.currentOpacity = 1;
      this.opacityIncrement = -1 / division;
      this.updateOpacity(this.currentOpacity - this.opacityIncrement);
    }
  }

  addTrajectoryAnimation() {
    const trajectoryShapes = this.cs.selectedTrajectoryShapes;
    if (trajectoryShapes.length === 1) {
      // const [shape] = trajectoryShapes;
      // this.addAnimation('trajectory-transform', {
      //   trajectoryShapeIRI: shape.IRI,
      // });
      this.save();
    } else {
      alert('Please select one, and only one trajectory shape!');
    }
  }

  addLoopTrajectoryTransform() {
    // this.addAnimation('loop-trajectory-transform', {
    //   speed: 0,
    //   cnt: 0,
    //   shapes: [],
    // });
  }

  incrementAnimation(increment: number, id?: string, maxIncrement?: number) {
    if (this.removed) {
      return;
    }

    if (this.dropShadowIncrement) {
      this.dropShadowIncrement.current +=
        increment * this.dropShadowIncrement.increment;
      this.dropShadowElement.patch({
        opacity: this.dropShadowIncrement.current,
      });
    }

    Object.values(this.animationControllers).map(controller =>
      controller.incrementAnimation(increment),
    );

    if (this.rotationInstance) {
      this.incrementRotation();
    }

    // console.log('rotation-controller', this.rotationController); //

    if (this.opacityIncrement) {
      this.currentOpacity += this.opacityIncrement * increment;
      this.updateOpacity(this.currentOpacity);
    }

    // TODO - this should something like currentTrajectorAnimationInsance(s) //

    if (this.dTranslate) {
      this.dTransformIncrement(increment);
    }

    if (this.trajectorAnimationInstances[id]) {
      this.incrementTrajectoryAnimation(increment, id);
    }

    if (this.trajectoryDTransform?.length) {
      for (let i = 0; i < increment; i++) {
        const currentTrajectoryDTransform = this.trajectoryDTransform[0];

        const { dx, dy, incrementMax } = currentTrajectoryDTransform;
        this.trajectoryTransformIncrement++;

        this.x += dx;
        this.y += dy;
        this.redraw();

        if (incrementMax === this.trajectoryTransformIncrement) {
          this.trajectoryDTransform.shift();
          this.trajectoryTransformIncrement = 0;
        }
      }
    }

    this.getAnimationsById(id).map(({ key }) =>
      this.incrementAnimationByKey(key, increment),
    );
  }

  incrementAnimationByKey(key: AnimationKeys, increment: number) {
    switch (key) {
      case 'floatEffect':
        this.applyTranslate({
          x: this.xSineIncement
            ? this.xSineIncement.increment(increment)
            : this.x,
          y: this.ySineIncement
            ? this.ySineIncement.increment(increment)
            : this.y,
        });
        break;

      case 'trajectory-transform':
        if (!this.trajectoryTranslateController) {
          return;
        }

        const length = this.trajectoryTranslateController.increment(increment);
        const [ox, oy] = this.trajectoryTransformOffset;
        const { x, y } = this.trajectoryShape.getPositionVector(length) || {};
        if (isNaN(x) || isNaN(y)) {
          return;
        }
        this.applyTranslate({
          x: x - ox,
          y: y - oy,
        });
        break;
      case 'translate':
      case 'ease-in':
        if (!this.translateXIncrementController) {
          return;
        }
        if (!this.translateYIncrementController) {
          return;
        }

        this._redraw({
          x: this.translateXIncrementController.increment(increment),
          y: this.translateYIncrementController.increment(increment),
        });
        break;
      case 'scale':
        if (!this.scaleXIncrementController) {
          return;
        }
        if (!this.scaleYIncrementController) {
          return;
        }
        this.applyScale({
          x: this.scaleXIncrementController.increment(increment),
          y: this.scaleYIncrementController.increment(increment),
        });
        break;
      case 'rotation':
        const currentAngle = this.rotationIncrement.increment(increment);
        this.applyRotation(currentAngle);
        break;

      case 'show-hide':
        if (
          this.showHideXTranslateController ||
          this.showHideYTranslateController
        ) {
          this.applyTranslate(
            {
              x:
                this.showHideXTranslateController?.increment(increment) ||
                this.x,
              y:
                this.showHideYTranslateController?.increment(increment) ||
                this.y,
            },
            'show-hide',
          );
        }
        break;
    }
  }

  getAnimationsById(id: string) {
    return Object.values(this.animationsById?.[id] || {});
  }

  endAnimation(id: string, inverse = false) {
    this.opacityIncrement = null;
    this.svgAttributeOverride = {};
    this.getAnimationsById(id).map(({ key, value }) => {
      delete this.incrementMappers[key];
      this.endAnimationByKeyValue(key, value, inverse);
    });
  }

  translateAnimationIsInProgress = false;

  endAnimationByKeyValue(key: string, value: any, inverse = false) {
    switch (key) {
      case 'floatEffect':
        this.xSineIncement = null;
        this.ySineIncement = null;
        break;
      case 'blink':
        delete this.animationControllers.blink;
        this.opacity = 1;
        break;
      case 'show-hide':
        const { value: show } = value as ShowHideAnimation;
        if (show) {
          this.updateOpacity(1);
        } else {
          this.updateOpacity(0);
          this._hide();
        }
        this.showHideXTranslateController = null;
        this.showHideYTranslateController = null;
        break;
      case 'appear':
        this.updateOpacity(1);
        break;
      case 'disappear':
        this.updateOpacity(0);
        this._hide();
        break;
      case 'rotation':
        this.rotation = value as Rotation;
        break;
      case 'translate':
        if (this.translateXIncrementController) {
          this.x = this.translateXIncrementController.increment(0);
        }
        if (this.translateYIncrementController) {
          this.y = this.translateYIncrementController.increment(0);
        }

        this.translateXIncrementController = null;
        this.translateYIncrementController = null;

        this.translateAnimationIsInProgress = false;
        this.startFloat();
        // this.store.dispatch(
        //   setCurrentShapeTranslate({
        //     IRI: this.IRI,
        //     translate: { x: this.x, y: this.y },
        //   }),
        // );
        break;
      case 'scale':
        // -- //

        if (this.scaleXIncrementController) {
          this.scale = {
            x: this.scaleXIncrementController.end,
            y: this.scaleYIncrementController.end,
          };
        }
        this.scaleXIncrementController = null;
        this.scaleYIncrementController = null;
        // this.store.dispatch(
        //   setCurrentShapeScale({ IRI: this.IRI, scale: this.scale }),
        // );

        break;
      case 'transform':
        // this.position = {
        //   ...(value as ShapePosition),
        //   rotation: this.currentAngle,
        // };

        break;
      case 'trajectory-transform':
        this.trajectoryTransformIncrement = null;
        break;
    }
  }

  /******************
   * Rotation
   *****************/

  rotationInstanceCopy: any;

  normDeg(deg: number) {
    return deg < 0 ? deg + 360 : deg;
  }

  trajectorAnimationInstances: Record<string, LineAnimationInstance> = {};

  incrementTrajectoryAnimation(_indexIncrement: number, _id: string) {
    // --> // --> // --> //
  }

  dTransformIncrement(increment: number) {
    const { x, y, scale } = this.dTranslate;

    if (scale) {
      this.scaleX += increment * scale.x;
      this.scaleY += increment * scale.y;
    }

    this.dTranslate.localIncrement += increment;

    const mappedIncrement = this.incrementMappers['translate']?.map(
      this.dTranslate.localIncrement,
    );
    const actualIncrement =
      mappedIncrement !== undefined
        ? mappedIncrement
        : this.dTranslate.localIncrement;

    this._redraw({
      x: this._x + actualIncrement * x,
      y: this._y + actualIncrement * y,
    });
  }

  rotateCircle() {}

  rotateByMatrix(matrix: any) {
    // this.outerElementGroup.attr({ transform: matrix });
  }

  rotateGroupMatrix: any;

  // prepareRotatation(cx: number, cy: number) {
  // this.container.x = this.x - this.myChildOffsetX + cx;
  // this.container.y = this.y - this.myChildOffsetY + cy;
  // this.container.pivot.set(cx, cy);
  // }

  prepareRotation(origin?: string) {}

  setShapeRotation(rotation: Rotation) {
    if (!rotation.angle) {
      return;
    }
    this.prepareRotation(rotation?.controlPoint);
    this.applyRotation(rotation?.angle);
  }

  applyRotation(rad = 0) {
    // TOODx - move it back
    if (!this.mainContainer) {
      return;
    }

    this.mainContainer.rotation = rad;
  }

  setRotationOrigin() {
    const { cx, cy } = this.getRotationOrigin();
    this.cx = cx;
    this.cy = cy;
  }

  getRotationOrigin() {
    const { width, height } = this.container.getBounds();
    // return { cx: width / 2, cy: height / 2 };
    return { cx: width / 2, cy: height / 2 };
  }

  saveRotation() {
    this.cx = 0;
    this.cy = 0;
    this.patch('position.rotation', this.currentAngle);
  }

  radToDeg(rad: number) {
    return (180 * rad) / Math.PI;
  }

  degToRad(deg: number) {
    return (Math.PI * deg) / 180;
  }

  // TODO - should be rad
  incrementRotation() {
    // this.rotation
    // const { degSum, degMax } = this.rotationInstance;
    // if (deg > 0) {
    //   deg = Math.min(deg, degMax - degSum);
    // } else {
    //   deg = Math.max(deg, degMax - degSum);
    // }
    // // if (deg === 0) {
    // //  return;
    // // }
    // this.rotationInstance.degSum += deg;
    // const { cx, cy, matrix } = this.rotationInstance;
    // // Old code
    // // matrix.rotate(deg, cx - this.x - this.offsetX, cy - this.y - this.offsetY);
    // this.setRotation(this.degToRad(this.rotationInstance.degSum), cx, cy);
    // // That part is rubbish
    // for (let i = 0; i < this.rotationInstance.constraints?.length || 0; i++) {
    //   const { p, shapeIRI } = this.rotationInstance.constraints[i];
    //   const [ccx, ccy] = this.getLocalCoords([cx, cy]);
    //   // const [dx, dy] = Vector.dRotate([p[0] - ccx, p[1] - ccy], -deg); //
    //   const [dx, dy] = Vector.dRotate([p[0] - ccx, p[1] - ccy], deg);
    //   this.rotationInstance.constraints[i].p = [p[0] + dx, p[1] + dy];
    //   this.getShapeByIRI(shapeIRI)?.incrementalDragShape(dx, dy, this.IRI);
    // }
  }

  refreshElement() {}

  updateOpacity(opacity: number): void {
    this.opacity = Math.min(1, opacity);
    this.refreshElement();
  }

  svgAttributeOverride = {};

  rotationCenterPoint: PointController;

  previousPatchesById: Record<string, Record<string, string>> = {};
  lastPatchId: string;

  /**********************************
   *
   * Applying/reverting animation
   *
   **********************************/

  applyAnimation(id: string, duration: number) {
    this.svgAttributeOverride = {};
    this.rotationCenterPoint?.remove();
    this.rotationCenterPoint = undefined;

    this.getAnimationsById(id).map(({ key, value }) => {
      if (this.lastPatchIDByKey[key]) {
        this.previousPatchesById[id] ||= {};
        this.previousPatchesById[id][key] = this.lastPatchIDByKey[key];
      }

      this.lastPatchIDByKey[key] = id;
      this.applyAnimationKeyValue(key, cloneDeep(value), duration);
    });

    this.lastPatchId = id;
    // this.rotationRefresh(); //
    this.logPosition();
  }

  revertAnimation(id: string) {
    if (this.previousPatchesById[id]) {
      Object.entries(this.previousPatchesById[id]).map(
        ([animationKey, animationId]) => {
          const animation = this.getAnimationsById(animationId).find(
            ({ key }) => animationKey === key,
          );
          if (animation && animation.key !== 'rotation') {
            this.applyAnimationKeyValue(
              animation.key,
              animation.value,
              1 / this.cs.batchPerSecond,
              true,
            );
          } else {
            console.warn(
              `Animation could not be found by: id: ${animationId}, key: ${animationKey}!`,
            );
          }
        },
      );
    } else {
      this.redraw();
    }
  }

  ttAnimationApplied = false;

  applyAnimationKeyValue(
    key: AnimationKeys,
    value: AnimationValue,
    duration: number,
    revert = false,
  ) {
    switch (key) {
      case 'dropShadow':
        this.descriptor.dropShadow = value as DropShadowConfig;
        this.initDropShadowElement(this.descriptor.dropShadow);
        break;
      case 'dropShadow-remove':
        this.descriptor.dropShadow = null;
        this.initDropShadowElement(null);
        break;
      //      case 'translate':
      // case 'transform':
      //   // The rotation is applied 10 lines underneath
      //   this.applyShapePosition({
      //     ...(value as ShapePosition),
      //     rotation: this.currentAngle,
      //   });
      //   break;

      case 'trajectory-transform':
        this.ttAnimationApplied = true;
        this.applyTrajectoryTransform(value as TrajectoryMoveAnimation, 1);
        break;
      case 'appear':
        this._show();
        break;
      case 'disappear':
        this._hide();
        break;
      case 'svgAttributes.opacity':
      case 'svgAttributes.fill-opacity':
        this.updateOpacity(value as number);
        break;

      case 'svgAttributes.stroke':
        console.log('todo-implement-stroke-apply');
        this.descriptor.svgAttributes ||= {};
        this.descriptor.svgAttributes.stroke = value as string;
        this.refresh();
        break;
      case 'svgAttributes.stroke-width':
        this.updateStrokeWidth(value as number);
        break;
      default:
        if (key.startsWith('svgAttr')) {
          const [, svgKey] = key.split('.');
          this.svgAttributeOverride[svgKey] = value;
          this.refresh();
        }
        break;
    }
  }

  saveDescriptorKey(key: AnimationKeys, value: unknown) {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key,
        value,
      }),
    );
  }

  initDrag(width: number, height: number) {}

  updateStrokeWidth(value: number) {
    // This is gonna be valid when the imported shape for instance is gonna get an outline
  }

  // Implemented in the imported-shape
  rotationRefresh() {}

  get currentAnimationFrame() {
    return this.parent.animationFrame;
  }

  getCurrentShowHideState() {
    // that is the first frame
    let current: AnimationFrameObject = this.parent.animationFrame;

    let i = 0;
    let { x, y, scale } = this.descriptor.position;
    // currentState =
    while (current) {
      i++;

      if (this.hasAnimation(current.id)) {
        const animation = this.getAnimationsById(current.id).find(
          ({ key }) => key == 'appear' || key == '_appear',
        );
        if (animation) {
          const translate = animation.value as PositionAnimation;
          if (translate.dx || translate.dy) {
            x += translate.dx || 0;
            y += translate.dy || 0;
          } else {
            x = translate.x;
            y = translate.y;
          }
        }
      }

      if (current.id == this.cs.currentAnimation.id) {
        break;
      }
      current =
        current.next || (current as MainAnimationFrameObject).fcnParent?.next;
    }

    return { x, y, scale };
  }

  reArrangeIndexes() {
    const indexes = this.shapes
      // .filter(s => s.index !== undefined)
      .sort((s1, s2) => (s1.index || 0) - (s2.index || 0))
      .map(s => s.index);

    const lastIndex = Math.ceil(indexes[indexes.length - 1]);
    let i = 1;
    this.shapes.map(shape => {
      if (shape.index === undefined) {
        shape.index = lastIndex + i++;
        shape.save();
      }
    });

    const shapesByIndex = groupBy(this.shapes, 'index');

    if (Object.values(shapesByIndex).find(array => array.length > 1)) {
      // There is something to rearrange
      const orderedKeys = Object.keys(shapesByIndex)
        .map(index => parseFloat(index) || 0)
        .sort((i1, i2) => i1 - i2);

      orderedKeys.map((key, index) => {
        if (shapesByIndex[key]?.length > 1) {
          const dIndex =
            index > orderedKeys.length - 1
              ? (orderedKeys[index + 1] - key) / (shapesByIndex[key].length + 1)
              : 1;

          shapesByIndex[key].map((shape, index) => {
            if (index > 0) {
              shape.index += index * dIndex;
              shape.save();
            }
          });
        }
      });
    }
  }

  scaleMatrix: any;

  incrementIndex() {
    (this.parent as RootShape).moveUp(this);
  }

  decrementIndex() {
    (this.parent as RootShape).moveDown(this);
  }

  isSelectable() {
    return true;
  }

  setMeAsMask(maskTarget: GeneralShape, noSave = false) {}

  clicked() {
    if (
      this.importedShapeParent ||
      (this.groupShapeParent && !this.groupShapeParent.selected)
    ) {
      this.parent.clicked();
      return;
    }

    if (this.groupShapeParent?.selected) {
      console.log('yoooooo > groupShapeParent -> selected');
      this.selected = true;
      this.rc?.show();
      this.service.select(this, true);
      return;
    }

    if (
      Math.abs(this.service.selectorHeight) > 30 &&
      Math.abs(this.service.selectorWidth) > 30
    ) {
      return;
    }

    if (this.cs.dragging) {
      return;
    }

    // This prevents the global click handler in the element editor to fire

    // if (this.cs.selectorWidth > 5 || this.cs.selectorHeight > 5) {
    //   console.log('------gs:clicked:return-----');
    //   return;
    // }

    // TODO - move this logic into a cleaner place //

    if (this.cs.shapesTobeMasked?.length) {
      this.cs.shapesTobeMasked.map(maskTarget => {
        if (maskTarget) {
          if (maskTarget.IRI != this.IRI) {
            this.setMeAsMask(maskTarget);
            maskTarget.saveMask(this.IRI);
          }
        }
      });

      this.cs.shapesTobeMasked = null;
      return;
    }

    this.selected ? this.deselect() : this.select();

    // selected ? this.deselect() : this.select();
    // if (this.cs.selectorHeight > 30 && this.cs.selectorWidth > 30) {
    //   return;
    // }
  }

  saveMask(IRI: string) {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'maskedBy',
        value: IRI,
      }),
    );
  }

  initContainers() {
    this.container = new Container();
    this.auxCircleContainer = new Container();
    this.circleContainer = new Container();
    this.sectionContainer = new Container();
  }

  /******************
   * Control points
   ******************/

  controlPointControllers: Record<string, PointController> = {};

  controlPointDragMode = false;

  saveControlPoints() {
    this.saveDescriptorKey('controlPoints', cloneDeep(this.controlPoints));
  }

  handleRightClickMenu(key: string) {}

  initControlPointController(pointId: string) {
    this.controlPointControllers[pointId] = new PointController(
      this,
      {
        start: () => {
          if (!this.cs.isPressed('Shift')) {
            this.startBaseDrag();
            return;
          }

          const constrainedTo = this.controlPoints[pointId].constrainedTo;
          if (constrainedTo?.length) {
            Object.entries(constrainedTo).map(([shape, point]) => {
              this.getShapeByIRI(shape)?.removeControlPointConstraint(
                point,
                this.IRI,
              );
              this.removeControlPointConstraint(pointId, shape);

              this.cs.notify('Control point constraints has been removed!');
              this.startBaseDrag();
            });
          } else {
            this.controlPointDragMode = true;
          }
        },
        drag: ({ x, y, dx, dy }) => {
          if (this.controlPointDragMode) {
            this.controlPoints[pointId].coords = [x, y];
            this.controlPointControllers[pointId].patch({ p: [x, y] });
          } else {
            this.controlPointControllers[pointId].patch({
              p: this.controlPoints[pointId].coords,
            });
            this.drag(dx, dy);
          }
        },
        end: () => {
          console.log('end');
          if (this.controlPointDragMode) {
            console.log('end-1');
            this.controlPointDragMode = false;

            const [ox, oy] = this.controlPoints[pointId].coords;
            this.controlPoints[pointId].coords = [ox + this.dx, oy + this.dy];
            this.saveControlPoints();
          } else {
            console.log(
              'end-2',
              this.cs.hoveredControlPoint,
              this.cs.hoveredControlPoint?.shapeIRI === this.IRI,
            );

            let [dx, dy] = this.diffs;
            if (
              this.cs.hoveredControlPoint &&
              this.cs.hoveredControlPoint.shapeIRI !== this.IRI
            ) {
              const { shapeIRI, point: hoveredPointId } =
                this.cs.hoveredControlPoint;

              const hoveredShape = this.getResource(
                shapeIRI,
              ) as GeneralShape<GeneralShapeDescriptor>;

              const [hoveredX, hoveredY] =
                hoveredShape.getAbsCoordsOfControlPoint(hoveredPointId);

              const [x, y] = this.dragBase;
              const [ox, oy] = this.controlPoints[pointId].coords;
              const [baseX, basY] = [x + ox, y + oy];

              console.log('before', dx, dy);
              dx = hoveredX - baseX;
              dy = hoveredY - basY;
              console.log('after', dx, dy);

              this.drag(dx, dy);
              // this.applyTranslate({
              //   x: hoveredX + ox,
              //   y: hoveredY + oy,
              // });
              // -- // -- //

              // console.log('dragged-onto', {
              //   hoveredX,
              //   hoveredY,
              //   newX,
              //   newY,
              // });

              hoveredShape.addControlPointConstraint(
                hoveredPointId,
                this,
                pointId,
              );
            }

            this.endDrag(dx, dy);
          }
          // this.updateControlPoints();
          // this.save();
        },
        pOffset: [0, 0],
        p: this.controlPoints[pointId].coords,
        controlPoint: {
          shapeIRI: this.IRI,
          point: pointId,
          absCoords: this.getAbsCoords(this.controlPoints[pointId].coords),
        },
        color: '#ff0000',
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
  }

  addControlPoint(id?: string) {
    const { width, height } = this.container.getBounds();
    const [cx, cy] = [width / 2 + 20, height / 2];

    id ||= Math.random().toString();
    this.controlPoints ||= {};
    this.controlPoints[id] = {
      coords: [cx, cy],
    };
    this.initControlPointController(id);
    this.select();
    this.saveControlPoints();
  }

  removeControlPointConstraint(id: string, targetShape: string) {
    delete this.controlPoints[id].constrainedTo[targetShape];
    this.save();
  }

  getAbsCoordsOfControlPoint(pointId: string) {
    const { x, y } = this.translate;
    const [cx, cy] = this.controlPoints[pointId].coords;
    return [x + cx, y + cy];
  }

  addControlPointConstraint(
    pointId: string,
    targetShape: GeneralShape,
    targetPointId: string,
  ) {
    set(
      this.controlPoints,
      [pointId, 'constrainedTo', targetShape.IRI],
      targetPointId,
    );

    this.saveControlPoints();

    set(
      targetShape.controlPoints,
      [targetPointId, 'constrainedTo', this.IRI],
      pointId,
    );
    targetShape.saveControlPoints();
  }

  getAbsCoords([x, y]: Coords): Coords {
    return [this.x + this.offsetX + x, this.y + this.offsetY + y];
  }

  getLocalCoords([x, y]: Coords): Coords {
    return [x - this.x - this.offsetX, y - this.y - this.offsetY];
  }

  /******************
   * Multiplication
   ******************/

  showMultiplication = false;

  multipliedShapesStore: Record<string, boolean> = {};
  multipliedShapes: Record<string, GeneralShape> = {};

  initMultiplications(multiplication: Multiplication) {
    // Object.values(this.multipliedShapes || {}).map(shape => shape.remove());

    const { x, y } = multiplication || {};
    if (!x && !y) {
      return;
    }
    // const notRemoved = this.multipliedShapes;
    const xGap = x?.gap || 0;
    const yGap = y?.gap || 0;

    let width, height;

    switch (this.getType()) {
      case 'rectangle-shape':
        width = this.scale.x;
        height = this.scale.x;
        break;
      default:
        const { width: w, height: h } = this.getBounds();
        width = w;
        height = h;
        break;
    }

    // console.log('multiplied-shapes', this.multipliedShapes);

    for (let i = 0; i < (x?.cnt || 0) + 1; i++) {
      for (let j = 0; j < (y?.cnt || 0) + 1; j++) {
        if (i > 0 || j > 0) {
          const position = {
            x: i * (width + xGap),
            y: j * (height + yGap),
          };

          const instanceIRI = `multiplied-${i}.${j}-${this.IRI}`;

          if (this.multipliedShapesStore[instanceIRI]) {
            this.multipliedShapes[instanceIRI].applyTranslate(position);
          } else {
            const shape = this.copy(instanceIRI, position, {
              noEdit: true,
              IRIprefix: instanceIRI,
              multiplicationIndexes: {
                x: i,
                y: j,
              },
            });

            // console.log('-- copy --', position);
            this.multipliedShapes[instanceIRI] = shape;
            this.multipliedShapesStore[instanceIRI] = true;
            this.container.addChild(shape.mainContainer);
          }
        }
      }
    }

    // Object.entries(notRemoved).map(([key, shape]) => {
    //   delete this.multipliedShapes[key];
    //   shape.remove();
    // });
  }

  // TO BE OVERRIDEN
  copy(_IRI: string, _position: ShapePosition, config?: ShapeConfig) {
    return new GeneralShape(this.service, {}, config);
  }

  hidden = false;
  hiddenByAnimation = false;
  _hide() {
    this.hidden = true;
    if (this.container) {
      this.container.visible = false;
    }
    if (this.circleContainer) {
      this.circleContainer.visible = false;
    }
    if (this.auxCircleContainer) {
      this.auxCircleContainer.visible = false;
    }
    // this.deselect();
  }

  initialShow = true;

  _show() {
    // console.log('---- _show -----', this.if);
    // if (this.descriptor.hidden && this.initialShow) {
    //   this.initialShow = false;
    //   return;
    // }

    this.initialShow = false;
    this.hidden = false;
    if (this.container) {
      this.container.visible = true;
    }
    if (this.circleContainer) {
      this.circleContainer.visible = true;
    }
    if (this.auxCircleContainer) {
      this.auxCircleContainer.visible = true;
    }
  }

  floatAnimationID: string;
  startFloat() {
    if (this.floatEffect) {
      const { x, xInterval, y, yInterval } = this.floatEffect;
      if (x && xInterval) {
        this.xSineIncement = new SineIncrementController(
          this.x,
          this.x + x,
          xInterval * this.cs.batchPerSecond,
        );
      }
      if (y && yInterval) {
        this.ySineIncement = new SineIncrementController(
          this.y,
          this.y + y,
          yInterval * this.cs.batchPerSecond,
        );
      }

      const rand = Math.random().toString();

      this.floatAnimationID = this.IRI + rand;

      this.service.animationService.animationFrames[this.floatAnimationID] =
        increment => {
          // this is double-check
          if (this.translateAnimationIsInProgress) {
            return;
          }
          this.applyTranslate({
            x: this.xSineIncement
              ? this.xSineIncement.increment(increment)
              : this.x,
            y: this.ySineIncement
              ? this.ySineIncement.increment(increment)
              : this.y,
          });
        };

      this.cs.generalEventSubscribe('end-animation', () => {
        this.xSineIncement = null;
        this.ySineIncement = null;
        delete this.service.animationService.animationFrames[this.IRI + rand];
      });
    }
  }

  stopFloat() {
    if (this.floatAnimationID) {
      delete this.service.animationService.animationFrames[
        this.floatAnimationID
      ];
      this.floatAnimationID = null;
    }
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }
}

function setShapeTranslateBas(): any {
  throw new Error('Function not implemented.');
}
// - - // - - // Animate dashed line // - - // - - //

// * {
//   font-family: sans-serif;
// }

// .paths {
//   fill: none;
//   stroke: grey;
//   stroke-dasharray: 5;
//   stroke-width: 5;
//   stroke-linejoin: round;
// }

// .mask {
//   fill: none;
//   stroke: white;
//   stroke-width: 10;
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 1000;
//   animation: dash 5s linear alternate infinite;
// }

// /* does not work in IE, need JS to animate there */
// @keyframes dash {
//   from {
//     stroke-dashoffset: 1000;
//   }
//   to {
//     stroke-dashoffset: 0;
//   }
// }

// <p>Taking the example from the seminal article <a href="https://css-tricks.com/svg-line-animation-works/">SVG Line Animation</a> ... but with a dotted line!</p>

// <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="1340" height="333" viewBox="0 0 1340 333">
//   <g transform="translate(500, 0)">
//   <defs>
//     <path id="path1" d="M66.039,133.545c0,0-21-57,18-67s49-4,65,8s30,41,53,27s66,4,58,32s-5,44,18,57s22,46,0,45s-54-40-68-16s-40,88-83,48s11-61-11-80s-79-7-70-41 C46.039,146.545,53.039,128.545,66.039,133.545z" />
//     <mask id="mask1"><use class="mask" xlink:href="#path1">
// </mask>
//       <path id="path2" d="M66.039,133.545c0,0-21-57,18-67s49-4,65,8s30,41,53,27s66,4,58,32s-5,44,18,57s22,46,0,45s-54-40-68-16s-40,88-83,48s11-61-11-80s-79-7-70-41 C46.039,146.545,53.039,128.545,66.039,133.545z" />
//     <mask id="mask2"><use class="mask" href="#path2"></mask>
//   </defs>

//   <rect x="100" y="100" width="100" height="100"></rect>
//   <use class="paths" xlink:href="#path1" mask="url(#mask1)" />
//  </g>
// <g transform="translate(0, -20) scale(1.4 1.4)">

//   <rect x="200" y="100" width="100" height="100" fill="green"> </rect>

//   <rect x="100" y="100" width="100" height="100"></rect>
//   <use style="fill: none;
//   stroke: grey;
//   stroke-dasharray: 5;
//   stroke-width: 5;
//   stroke-linejoin: round;" href="#path2" mask="url(#mask2)" />
//  </g>
// </svg>
// <!-- Can you do better? Great, fork this and/or leave a comment -->

// TS start

// const clip = this.cs.snap.path('M 100 100 C 20 20, 40 20, 50 10');

// const m = this.cs.snap.mask('path');

// // m.toDefs();

// const u1 = (m as any).use();

// console.log('m', (m as any).id);
// console.log('u1', u1.id);

// u1.attr({ id: u1.id });

// const e = this.cs.snap.path('M 100 100 C 20 20, 40 20, 50 10');
// e.toDefs();
// // e.attr({
// //   id: 'abc',
// //   mask: m,
// // });

// const e_m = document.getElementById((m as any).id);
// const e_u = document.getElementById(u1.id);

// e_m.appendChild(e_u);

// clip.attr({
//   fill: 'none',
//   stroke: 'black',
//   'stroke-dasharray': 115,
//   'stroke-dashoffset': 115,
//   mask: clip,
// });

// const g = this.cs.snap.g();

// const use = g.use('path');

// e.attr({
//   fill: 'none',
//   stroke: 'black',
//   'stroke-dasharray': 115,
//   'stroke-dashoffset': 115,
// });

// (use as any).attr({
//   href: '#path2',
//   id: 'xx',
// });
// this.cs.snap.g().mask;

// document.getElementById('xx').setAttribute('mask', 'url("#mask2")');

// // const length = e.getTotalLength();

// setInterval(() => {
//   e.attr({ 'stroke-dashoffset': length });
//   e.animate(
//     {
//       'stroke-dashoffset': 0,
//     },
//     1000
//   );
// }, 1000);

// The heavy solution
// https://codepen.io/elliz/pen/prYqwx

// More lightweight solution
// https://codepen.io/Evgeny/pen/IEGoq
