<div class="general-selector-component">
  <!-- *ngIf="this.config?.text as text; else options" -->
  <div
    *ngIf="this.textId as text; else options"
    fxLayout="column"
    fxLayoutGap="6px"
  >
    <input [(ngModel)]="textValue" (keydown)="keyDown($event)" #input />
  </div>

  <ng-template #options>
    <div fxLayout="column" fxLayoutGap="6px">
      <div
        *ngFor="let option of currentOptions; let i = index"
        (click)="select(option)"
        class="option-item"
        [ngClass]="{ 'option-item-selected': i === currentIndex }"
      >
        {{ option.label || option.id }}
      </div>
    </div>
  </ng-template>
</div>