<div
  class="trajectory-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <div class="container">
    <nw-general-attribute-header
      title="Line move"
      [attributeState]="attributeState$ | async"
      key="trajectory-transform"
      [initialValue]="{ mode: 'normal' }"
    ></nw-general-attribute-header>

    <div
      *ngIf="isDefined$ | async"
      class="attribute-value-section flex-column gap-4"
    >
      <div
        *ngIf="attributeState.value['trajectoryShapeIRI']?.[0] as IRI"
        (mouseover)="mouseOver(IRI)"
        (mouseout)="mouseOut(IRI)"
      >
        {{ getLabel(IRI) }}
      </div>

      <div *ngIf="!attributeState.value['trajectoryShapeIRI']?.[0]">
        <div
          (click)="startPathShapeSelectMode()"
          [class.blink]="pathShapeSelectMode"
        >
          Select shape
        </div>
      </div>
      <div class="flex-row">
        <div>mode</div>
        <select
          [value]="attributeState.value['mode'][0]"
          (change)="modeChange($event)"
        >
          <option value="normal">normal</option>
          <option value="reverse">reverse</option>
          <option value="back-and-forth">back-and-forth</option>
        </select>
      </div>
      <!-- <nw-number-input
          key="length"
          [value]="attributeState.value['width']"
          (changed)="updateSubAttributeValue('width', $event)"
        ></nw-number-input> -->
    </div>
  </div>
</div>
