import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nw-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class MatIconComponent implements OnInit {
  @Input()
  color = 'black';

  @Input()
  align = 'center';

  @Input()
  disabled: boolean;

  @Input()
  faded: boolean;

  @Input()
  offsetBottom: number;

  @Input()
  size: number;

  @Input()
  type: string;

  // Overriding click
  @Output()
  click = new EventEmitter();

  // Enables fading on hover
  @Input()
  hover = false;

  @Input()
  outlined = false;

  @Input()
  noPointer = false;

  @Input()
  height = 0;

  @Input()
  width = 0;

  @Input()
  opacity = 100;

  @Input()
  rotate: number;

  @Input()
  stopPropagation: boolean;

  constructor() {}

  ngOnInit() {
    this.disabled = this.disabled || false;
  }

  get _opacity() {
    return `${this.opacity}%`;
  }

  get fontSize() {
    return `${this.size}px`;
  }

  get heightValue() {
    return `${this.height || this.size}px`;
  }

  get widthValue() {
    return `${this.width || this.size}px`;
  }

  get fxLayoutAlign() {
    return `${this.align} center`;
  }

  get marginBotton() {
    return this.offsetBottom ? `${this.offsetBottom}px` : '0px';
  }

  public clickHandler(event: MouseEvent) {
    //if (this.stopPropagation) {
    // event.stopPropagation();
    // }
    // if (!this.disabled && this.click) {
    //   return this.click.emit(event);
    // }
  }
}
