import { abs } from 'mathjs';
import { Subscription } from 'rxjs';
import { Circle } from '../../element-editor/shape/shapes/primitive/circle-element';
import { Region } from './region';

export type Color = [number, number, number];

export class Pixel {
  get cs() {
    return this.region.cs;
  }

  element: Circle;

  get selected() {
    return this.service.isSelected(this);
  }

  get service() {
    return this.region.service;
  }

  get pixelStore() {
    return this.region.parentShape.pixelStore;
  }

  get regionByPixel() {
    return this.region.parentShape.regionByPixel;
  }

  get event() {
    return this.region.parentShape.pixelEvent;
  }

  get left() {
    return this.pixelStore[`${this.x - 1}.${this.y}`];
  }

  get leftUp() {
    return this.pixelStore[`${this.x - 1}.${this.y - 1}`];
  }

  get leftDown() {
    return this.pixelStore[`${this.x - 1}.${this.y + 1}`];
  }

  get right() {
    return this.pixelStore[`${this.x + 1}.${this.y}`];
  }

  get rightUp() {
    return this.pixelStore[`${this.x + 1}.${this.y - 1}`];
  }

  get rightDown() {
    return this.pixelStore[`${this.x + 1}.${this.y + 1}`];
  }

  get up() {
    return this.pixelStore[`${this.x}.${this.y - 1}`];
  }

  get down() {
    return this.pixelStore[`${this.x}.${this.y + 1}`];
  }

  get adjacentPixels() {
    return [this.left, this.right, this.up, this.down].filter(p => !!p);
  }

  get crossAdjacentPixels() {
    return [this.leftDown, this.leftUp, this.rightDown, this.rightUp].filter(
      p => !!p,
    );
  }

  get allAdjacentPixels() {
    return [...this.adjacentPixels, ...this.crossAdjacentPixels];
  }

  get adjacentRegions(): Region[] {
    const adjacentRegions = {};
    this.adjacentPixels
      .filter(p => p.region.id != this.region.id)
      .map(p => {
        adjacentRegions[p.region.id] = p.region;
      });
    return Object.values(adjacentRegions);
  }

  sub: Subscription;

  angle: number;

  get color() {
    if (this.key == this.region.startPoint) {
      return [255, 0, 0] as Color;
    }
    return this._color;
  }

  key: string;
  constructor(
    public region: Region,
    public x: number,
    public y: number,
    public _color: Color,
  ) {
    this.key = `${x}.${y}`;

    let fill = this.colorsToHex(this.color);
    let r = 2;
    if (this.region.descriptor.curveBoundaries?.[this.key]) {
      fill = this.colorsToHex([0, 255, 0]);
      r = 3;
    }
    this.element = new Circle(this, this.region.container, {
      r,
      x: x * 6,
      y: y * 6,
      // fill,
    })
      .mouseover(() => {
        console.log('key', this.key);
        this.select();
      })
      .mouseout(() => {
        this.deselect();
      })
      .click(() => {
        if (this.service.edgeSelectMode) {
          return this.region.setEdgePixel(this);
        }

        if (this.cs.isPressed('d')) {
          return this.region.deletePixel(this);
        }

        if (this.cs.isPressed('Shift')) {
          return this.region.clickHandler(this);
        }
        if (this.service.isSelected(this)) {
          this.service.deselectPixel(this);
          this.deselect();
        } else {
          this.service.selectPixel(this);
          this.select();
        }
      });

    if (this.pixelStore[this.key]) {
      console.warn('pixel is initialized twice', this.key);
    }

    if (
      this.regionByPixel[this.key] &&
      this.regionByPixel[this.key].id !== this.region.id
    ) {
      this.service.addOverlappingRegion(
        this.region,
        this.regionByPixel[this.key],
      );
    }

    this.pixelStore[this.key] = this;
    this.regionByPixel[this.key] = this.region;

    this.sub = this.event.subscribe(event => {
      switch (event) {
        case 'setBoundaryPixelFlag':
          this.setBoundaryPixelFlag();
          break;
      }
    });
  }

  colorsToHex(color: Color) {
    return parseInt(`0x${color.map(c => Math.floor(c).toString(16)).join('')}`);
  }

  select() {
    this.element.patch({ r: 3 });
  }

  deselect() {
    if (this.service.isSelected(this)) {
      return;
    }
    this.element.patch({ r: 2 });
  }

  remove() {
    this.sub?.unsubscribe();
    this.element.remove();
    delete this.pixelStore[this.key];
  }

  amIBoundary = true;

  setBoundaryPixelFlag() {
    if (this.region.length == 1) {
      return;
    }
    this.amIBoundary =
      !this.left ||
      !this.right ||
      !this.up ||
      !this.down ||
      this.adjacentRegions.length > 0;
  }

  isSameBoundary(pixel: Pixel) {
    // -- // -- // -- // -- // -- //
    if (!pixel?.amIBoundary) {
      return false;
    }
    if (!this.isSameRegionByPixel(pixel)) {
      return false;
    }

    return true;
  }

  isSameRegionByPixel(pixel: Pixel) {
    return this.isSameRegion(pixel?.region);
  }

  isSameRegion(region: Region) {
    return region && this.region.id == region.id;
  }

  foundInSplitDiscovery = false;

  discoverSplit(store: Record<string, Pixel>) {
    const checkSplit = (pixel: Pixel) => {
      if (!pixel) {
        return false;
      }

      if (pixel.region.id != this.region.id) {
        return false;
      }

      return (
        !store[pixel.key] && !pixel.selected && !this.foundInSplitDiscovery
      );
    };

    if (checkSplit(this.left)) {
      store[this.left.key] = this.left;
    }
    if (checkSplit(this.right)) {
      store[this.right.key] = this.right;
    }
    if (checkSplit(this.up)) {
      store[this.up.key] = this.up;
    }
    if (checkSplit(this.down)) {
      store[this.down.key] = this.down;
    }

    delete store[this.key];
    this.foundInSplitDiscovery = true;
    return store;
  }

  discoverInnerPixels() {
    const pixels = [];

    if (!this.left) {
      const pixel = this.region.addPixelByKey(`${this.x - 1}.${this.y}`);
      pixels.push(pixel);
    }
    if (!this.right) {
      const pixel = this.region.addPixelByKey(`${this.x + 1}.${this.y}`);
      pixels.push(pixel);
    }
    if (!this.up) {
      const pixel = this.region.addPixelByKey(`${this.x}.${this.y - 1}`);
      pixels.push(pixel);
    }
    if (!this.down) {
      const pixel = this.region.addPixelByKey(`${this.x}.${this.y + 1}`);
      pixels.push(pixel);
    }
    // if (!this.leftDown) {
    //   const pixel = this.region.addPixel(`${this.x - 1}.${this.y + 1}`);
    //   pixels.push(pixel);
    // }
    // if (!this.rightDown) {
    //   const pixel = this.region.addPixel(`${this.x + 1}.${this.y + 1}`);
    //   pixels.push(pixel);
    // }
    // if (!this.leftUp) {
    //   const pixel = this.region.addPixel(`${this.x - 1}.${this.y - 1}`);
    //   pixels.push(pixel);
    // }
    // if (!this.rightUp) {
    //   const pixel = this.region.addPixel(`${this.x + 1}.${this.y - 1}`);
    //   pixels.push(pixel);
    // }
    return pixels;
  }

  assignToRegion(region: Region) {
    this.deselect();
    this.region.removePixel(this);
    region.addPixel(this);
  }

  reassign(filterSelected = false) {
    let regions = this.adjacentRegions;

    if (filterSelected) {
      regions = regions.filter(r => r.selected);
    }

    switch (regions.length) {
      case 0:
        return false;
      case 1:
        this.assignToRegion(regions[0]);
        return true;
      default:
        const diffs = regions.map(r => this.colorDiff(r.color));
        // -- // -- //
        let min = Infinity;
        let minIndex = -1;
        diffs.map((diff, index) => {
          if (diff < min) {
            min = diff;
            minIndex = index;
          }
        });
        this.assignToRegion(regions[minIndex]);
        return true;
      // const currentDiff = this.currentDiff();
      // // if (min < currentDiff || (currentDiff > 100 && min < 150)) {
      // if (min < currentDiff || currentDiff > 100) {
      //   console.log('reassign');
      //   this.assignToRegion(regions[minIndex]);
      //   return true;
      // } else {
      //   console.log('min', min, 'currentDif', this.currentDiff());
      // }
    }
  }

  currentDiff() {
    return this.colorDiff(this.region.color);
  }

  colorDiff(color: Color) {
    const [r, g, b] = color;
    const [_r, _g, _b] = this.color;
    return abs(r - _r) + abs(g - _g) + abs(b - _b);
  }
}
