import { ResourceData } from './../resource/resource.types';
import { NormalVector } from './vector/vector';
import { Resource } from '../resource/resource';
import { PointData } from '../../services/util/vector-service';
import { ResourceType } from '../resource/resource.types';
import { Transformation } from '../../services/animation/animation.types';

export const PointScheme = {
  literals: {
    x: 'number',
    y: 'number',
  },
};

export class Point extends Resource {
  backup: Point;

  static abs(p1: PointData, p2: PointData): number {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
  }

  constructor(x = 0, y = 0, noBackUp = false) {
    super();
    this.type = ResourceType.Point;
    if (typeof y === 'object' && y) {
      this.literals = (y as any).literals || {};
    } else {
      this.literals.x = x;
      this.literals.y = y;
    }
    if (!noBackUp) {
      this.backup = new Point(0, 0, true);
    }
    this.cx = this.x;
    this.cy = this.y;
  }

  static diff(p1: PointData, p2: PointData): PointData {
    return {
      x: p2.x - p1.x,
      y: p2.y - p1.y,
    };
  }

  static compare(a: Point, b: Point): boolean {
    return a.x === b.x && a.y === b.y;
  }

  init() {
    // const abs = new Point(this.x, this.y, true);
    // this.service.saveResource(abs);
    // this.relationships.abs = abs.IRI;
    this.calculateAbs();
  }

  get abs(): Point {
    return this.ref('abs') as Point;
  }

  getLiterals() {
    // console.log("getLiterals", this.abs.coordString());
    return { x: this.x, y: this.y };
  }

  get current() {
    return { x: this.cx, y: this.cy } as Point | PointData;
  }

  getType(): string {
    return 'point';
  }

  set x(val: number) {
    this.literals.x = val;
  }

  set y(val: number) {
    this.literals.y = val;
  }

  get x(): number {
    return this.literals.x;
  }

  get y(): number {
    return this.literals.y;
  }

  calculateAbs() {
    // if (this.cs.dimension.scale) {
    //   const pointData = VectorService.scale(
    //     this.cs.dimension,
    //     { x: this.cx, y: this.cy },
    //     -this.cs.dimension.scale,
    //   );
    //   this.setData(pointData);
    // } else if (
    //   // TODO - remove isNaN
    //   (this.cs.canvasX || this.cs.dimension.y) &&
    //   ((!isNaN(this.cs.canvasX) && this.cs.canvasX !== 0) ||
    //     (!isNaN(this.cs.dimension.y) && this.cs.dimension.y !== 0))
    // ) {
    //   this.setData({
    //     x: this.cx - this.cs.canvasX,
    //     y: this.cy - this.cs.dimension.y,
    //   });
    // } else {
    //   this.setData(this.current);
    // }
  }

  setLiteralValue(key: string, value: any) {
    if (key === 'x') {
      this.cx = value;
    } else if (key === 'y') {
      this.cy = value;
    } else {
      super.setLiteralValue(key, value);
    }
    this.calculateAbs();
  }

  public eventHandler(event: any, from?: Resource, to?: string) {
    switch (event.type) {
      case 'drag':
        this.setLiteral('x', event.message.p.x);
        this.setLiteral('y', event.message.p.y);
        break;
      case 'endDrag':
        this.calculateAbs();
        break;
      case 'startTranslate':
        this.backup.setCoord(this.cx, this.cy);
        break;
      case 'translate':
        this.setLiteral('x', this.backup.x + event.message.vector.x);
        this.setLiteral('y', this.backup.y + event.message.vector.y);
        return;
      case 'reset':
        this.cx = this.x;
        this.cy = this.y;
        break;
      case 'endTranslate':
        this.calculateAbs();
        break;
      case 'transform':
        const t: Transformation = event.message.t;
        if (!t) {
          return;
        }
        const [dx, dy] = [this.cx - t.x, this.cy - t.y];
        if (t.scale) {
          this.cx = t.x + dx * t.scale;
          this.cy = t.y + dy * t.scale;
        } else {
          // converting to number
          this.cx += +t.x;
          this.cy += +t.y;
        }
        break;
      case 'set':
        this.setCurrent(event.message.p);
        break;
      default:
        // super.eventHandler(event, from, to);
        break;
    }
  }

  patch(data: ResourceData) {
    this.x = data.literals.x;
    this.y = data.literals.y;
    return {};
  }

  setCurrent(p: PointData): Point {
    this.cx = p.x;
    this.cy = p.y;
    return this;
  }

  set(p: PointData): Point {
    this.x = p.x;
    this.y = p.y;
    return this;
  }

  setData(p: PointData): Point {
    this.x = p.x;
    this.y = p.y;
    return this;
  }

  setCoord(x: number, y: number): Point {
    this.x = x;
    this.y = y;
    return this;
  }

  substract(p: Point) {
    return new Point(this.x - p.x, this.y - p.y, true);
  }

  substractEco(p: Point): PointData {
    return { x: this.x - p.x, y: this.y - p.y };
  }

  add(p: Point) {
    this.x += p.x;
    this.y += p.y;
  }

  coords(): string {
    return `${this.x} ${this.y}`;
  }

  coordString(): string {
    return `${this.x} ${this.y}`;
  }

  addN(p: Point) {
    return this.addNx(p.x, p.y);
  }

  addNx(x: number, y: number) {
    return new Point(this.x + x, this.y + y, true);
  }

  sub(p: Point) {
    this.x -= p.x;
    this.y -= p.y;
  }

  copy() {
    return new Point(this.x, this.y, true);
  }

  length(): number {
    return null;
  }

  endPoint(vector: NormalVector) {
    return this.backup.setCoord(this.x + vector.x, this.y + vector.y);
  }
}
