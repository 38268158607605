import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-option-select',
  templateUrl: './option-select.component.html',
  styleUrls: ['./option-select.component.scss'],
})
export class OptionSelectComponent implements OnInit {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  _closed = true;

  @Input()
  set close(val: number) {
    this._closed = true;
  }

  @Input()
  data: any[];

  @Input()
  selectedValue: string;

  @Output()
  itemSelected = new EventEmitter<any>();

  constructor(public readonly cs: CanvasService) {}

  ngOnInit(): void {}

  itemClicked(item: any) {
    this.consumed = true;
    this._closed = true;
    // console.log('item-clicked');
    this.itemSelected.emit(item);
  }

  consumed = false;

  containerClickHandler() {
    if (this.consumed) {
      this.consumed = false;
      return;
    }
    this._closed = !this._closed;
  }
}
