import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit, Input, Inject } from '@angular/core';
import * as yaml from 'js-yaml';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { KeyValueMenuComponent } from '../key-value-menu/key-value-menu.component';

const staticCodeMirrorOptions = {
  theme: 'material',
  mode: 'javascript',
  // mode: 'javascript',
  lineNumbers: false,
  // lineWrapping: true,
  // foldGutter: true,
  // gutters: [],
  // autoCloseBrackets: true,
  // matchBrackets: true,
  // lint: true,
  // singleCursorHeightPerLine: true,
  foldGutter: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
  // viewportMargin: Infinity,
};

export interface Tab {
  name: string;
  descripor: any;
}

@UntilDestroy()
@Component({
  selector: 'nw-yaml',
  templateUrl: './yaml.component.html',
  styleUrls: ['./yaml.component.scss'],
})
export class YamlComponent implements OnInit {
  @Input()
  color: string;

  @Input()
  data: any;

  @Input()
  height: number;

  @Input()
  storeKey: string;

  @Input()
  title: string;

  @Input()
  width: number;

  codeMirrorOptions = staticCodeMirrorOptions;

  valid = true;

  constructor(
    private readonly cs: CanvasService,
    public dialogRef: MatDialogRef<KeyValueMenuComponent>,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      data: Record<string, any>;
      dataScheme?: Record<string, any>;
    }
  ) {
    // --> // --> //
  }
  yaml: any;

  get style() {
    return {
      height: `${this.height || 300}px`,
      width: `${this.width || this.height || 300}px`,
    };
  }

  get componentType() {
    return 'yaml';
  }

  async ngOnInit() {
    try {
      // this.yaml = yaml.safeDump(this.dialogData?.data || this.data);
      this.yaml = this.dialogData?.data;
      console.log('yaml', this.yaml);
    } catch (error) {
      console.warn('Yaml could not be parsed', error);
    }
  }

  close() {
    this.dialogRef.close(yaml.safeLoad(this.yaml));
  }

  contentHasChanged() {
    try {
      this.valid = true;
    } catch (error) {
      this.valid = false;
    }
  }

  save() {}
}
