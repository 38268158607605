<div class="file-setting-component">
  <div class="editor-container-header">File</div>

  <div class="h-100">
    <div class="fs-13 p-8">Orientation</div>
    <div class="flex-row w-100 space-between ph-8">
      <div
        class="flex-row gap-6 pointer"
        [class.faded]="!(isLandscapeOrientation$ | async)"
        (click)="setOrientation('landscape')"
      >
        <div class="fs-10">Landscape</div>
        <div style="width: 16px; height: 9px" class="orientation-sample"></div>
      </div>
      <div
        class="flex-row gap-6 pointer"
        [class.faded]="isLandscapeOrientation$ | async"
        (click)="setOrientation('portrait')"
      >
        <div class="fs-10">Portrait</div>
        <div style="width: 9px; height: 16px" class="orientation-sample"></div>
      </div>
    </div>
  </div>

  <div class="h-100 blue-border-top" style="margin-top: 10px">
    <div class="fs-13 p-8">Background</div>
    <div class="flex-row w-100 space-between ph-8">
      <div class="fs-11">color</div>
      <nw-color-select
        key="color"
        [colors]="[bgColor$ | async]"
        (changed)="colorChanged($event)"
      ></nw-color-select>
    </div>
  </div>
</div>
