import { createAction, props } from '@ngrx/store';
import { ResourceData } from '../../elements/resource/resource.types';
import {
  AnimationInnerKey,
  AnimationKeys,
  BBox,
  GeneralShapeDescriptor,
  Point,
  LiteralValue,
  SVGAttributes,
  Scale,
  ShapeIRI,
  ShapeTransform,
  Rotation,
  TextShapeDescriptor,
  FontAlign,
  FontStyle,
  FontWeight,
  AnimationValue,
  CanvasOrientation,
  Scene,
} from '../../elements/resource/types/shape.type';
import { CanvasPosition } from './reducer/editor.reducer';

export const EditorFeature = 'editor';

function createSetterAction<T>(name: string) {
  return createAction(EditorFeature + ':' + name, props<{ value: T }>());
}

export const setFileChanged = createSetterAction<boolean>('set-file-changed');

export const saveSceneRequestComplete = createAction(
  'editor:save-scene-request-complete',
);

export const setComponents = createAction(
  'editor:save-components',
  props<{
    data: Array<{
      IRI: string;
      label: string;
    }>;
  }>(),
);

export const setFiles = createAction(
  'editor:set-files',
  props<{ files: Record<string, ResourceData> }>(),
);
export const saveSceneRequest = createAction(
  'editor:save-scene-request',
  props<{
    scene: string;
  }>(),
);

export const setCurrentMainScene = createAction(
  'editor:set-current-main-scene',
  props<{
    scene: string;
  }>(),
);

export const setLiteralValue = createAction(
  'editor:set-literal-value',
  props<{
    IRI: string;
    key: string;
    value: LiteralValue;
  }>(),
);

export const bulkShapeUpdate = createAction(
  'editor:bulk-shape-update',
  props<{
    data: Record<string, Partial<Record<AnimationKeys, AnimationValue>>>;
  }>(),
);

export const setLiteralValues = createAction(
  'editor:set-literal-values',
  props<{
    values: {
      IRI: string;
      key: string;
      value: LiteralValue;
    }[];
  }>(),
);

export const removeSceneRequest = createAction(
  'editor:remove-scene-request',
  props<{
    scene: string;
  }>(),
);

export const removeAllScenesRequest = createAction(
  'editor:remove-all-scene-request',
);

export const setCanvasBackgroundColor = createAction(
  'editor:set-canvas-background-color',
  props<{ color: string }>(),
);

export const setCanvasOrientation = createAction(
  'editor:set-canvas-orientation',
  props<{ value: CanvasOrientation }>(),
);

export const addFileActionRequest = createAction(
  'editor:add-file-request',
  props<{ name: string }>(),
);

export const addFileAction = createAction(
  'editor:add-file',
  props<{ name: string }>(),
);

export const setMultiplication = createAction(
  'editor:set-multiplication',
  props<{
    axis: 'x' | 'y';
    cnt?: number;
    gap: number;
  }>(),
);

export const setChildOf = createAction(
  'editor:set-child-of',
  props<{
    parent: string;
    child: string;
  }>(),
);

export const clearMultiplication = createAction(
  'editor:clear-multiplication',
  props<{
    axis: 'x' | 'y';
  }>(),
);

export const setIndex = createAction(
  'editor:set-index',
  props<{
    IRI: string;
    index: number;
  }>,
);

export const setIfAttributes = createAction(
  'editor:set-if-attribute',
  props<{
    key: string;
    value: boolean;
  }>(),
);

export const setLoadedScenes = createAction(
  'editor:set-loaded-scenes',
  props<{
    value: Record<string, boolean>;
  }>(),
);

export const addLoadedScene = createAction(
  'editor:add-loaded-scenes',
  props<{
    key: string;
  }>(),
);

export const setDescriptorValue = createAction(
  'editor:set-attribute-subvalue',
  props<{
    key: AnimationKeys;
    innerKey?: AnimationInnerKey;
    value: unknown;
    IRI?: string;
  }>(),
);

export const loadFileAction = createAction(
  'editor:load-file',
  props<{
    ID: string;
  }>(),
);

export const resetBaseState = createAction('editor:reset-base-state');

export const setAttributeByState = createAction(
  'editor:set-attribute-state',
  props<{
    IRIs: string[];
    state: string;
    key?: AnimationKeys;
    innerKey?: AnimationInnerKey;
    value: AnimationValue;
    keys?: string[];
  }>(),
);

export const setShowComponentSearch = createAction(
  'editor:set-show-component-search',
  props<{
    value: boolean;
  }>(),
);

export const loadFileRequestAction = createAction(
  'editor:load-file-request',
  props<{
    ID: string;
  }>(),
);

export const setFileLoading = createSetterAction<boolean>('set-file-loading');

export const saveChangedShapes = createAction(
  'editor:save-changed-shape',
  props<{
    IRI?: ShapeIRI;
    IRIs?: ShapeIRI[];
  }>(),
);

export const setBaseShapeTransform = createAction(
  'editor:set-base-shape-transform',
  props<{
    IRI: ShapeIRI;
    transform: Partial<ShapeTransform>;
  }>(),
);

export const saveShapeRotation = createAction(
  'editor:save-shape-rotation',
  props<{
    IRI: ShapeIRI;
    rotation: Rotation;
  }>(),
);

export const setShapeOriginalTransform = createAction(
  'editor:set-original-shape-transform',
  props<{
    IRI: ShapeIRI;
    transform: Partial<ShapeTransform>;
  }>(),
);

export const setShapeTranslateBase = createAction(
  'editor:set-shape-translate-base',
  props<{
    IRI: ShapeIRI;
    translate: Point;
  }>(),
);

export const setCurrentShapeTranslate = createAction(
  'editor:set-current-shape-translate',
  props<{
    IRI: ShapeIRI;
    translate: Point;
  }>(),
);

export const setShapeScaleBase = createAction(
  'editor:set-shape-scale-base',
  props<{
    IRI: ShapeIRI;
    scale: Scale;
  }>(),
);

export const setShapeRotationBase = createAction(
  'editor:set-shape-rotation-base',
  props<{
    IRI: ShapeIRI;
    rotation: Rotation;
  }>(),
);

export const setCurrentShapeScale = createAction(
  'editor:set-current-shape-scale',
  props<{
    IRI: ShapeIRI;
    scale: Point;
  }>(),
);
export const setCurrentShapeRotation = createAction(
  'editor:set-current-shape-rotation',
  props<{
    IRI: ShapeIRI;
    rotation: Rotation;
  }>(),
);

export const saveShapesAction = createAction('editor:save-shapes');
export const saveShapesAction2 = createAction('editor:save-shapes-2');
export const saveShapesRequestAction = createAction(
  'editor:save-shapes-request',
);

export const setFileAction = createAction(
  'editor:set-file',
  props<{
    file: ResourceData;
    merge?: boolean;
  }>(),
);

export const addShapesAction = createAction(
  'editor:add-shapes-action',
  props<{
    file: ResourceData;
    merge?: boolean;
  }>(),
);

export const resetPatchedShapes = createAction('editor:reset-patched-shapes');

export const cleanFile = createAction('editor:clean-file');

export const setSelectedShapes = createAction(
  'editor:set-selected-shapes',
  props<{ shapes: Record<string, boolean> }>(),
);

export const selectShape = createAction(
  'editor:select-shape',
  props<{ IRI: string; shift?: boolean }>(),
);

export const deselectShape = createAction(
  'editor:deselect-shape',
  props<{ IRI: string }>(),
);

export const selectAllShapes = createAction('editor:select-all-shapes');
export const deselectAllShapes = createAction('editor:deselect-all-shapes');

export const setShapeAttributeBaseAction = createAction(
  'editor:set-shape-attribute',
  props<{
    IRIs: string[];
    object?: Record<string, any>;
    original?: boolean;
  }>(),
);

export const addPatchedShape = createAction(
  'editor:add-patched-shape',
  props<{
    IRI: string;
  }>(),
);

export const updateSVGAttributeAction = createAction(
  'editor:update-svg-attribute',
  props<{
    key: 'fill' | 'stroke' | 'stroke-width' | 'opacity';
    value: number | string;
  }>(),
);

export const setOriginalSVGAttributes = createAction(
  'editor:set-original-svg-attributes',
  props<{
    IRI: string;
    svgAttributes: SVGAttributes;
  }>(),
);

export const removeAttribute = createAction(
  'editor:remove-attribute',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: AnimationKeys;
  }>(),
);

export const saveSubScenePosition = createAction(
  'editor:save-sub-scene-position',
  props<{
    scene: string;
    subScene: string;
    position: {
      x: number;
      y: number;
      scale: {
        x: number;
        y: number;
      };
    };
  }>(),
);
export const saveScenePosition = createAction(
  'editor:save-scene-position',
  props<{
    scene: string;
    position: {
      x: number;
      y: number;
      scale: {
        x: number;
        y: number;
      };
    };
  }>(),
);

export const updateDescriptorOfShapeBaseAction = createAction(
  'editor:update-descriptor-of-shape-base-action',
  props<{
    IRI: string;
    descriptor?: Partial<GeneralShapeDescriptor>;
    original?: boolean;
  }>(),
);

export const updateCurrentDescriptorBaseAction = createAction(
  'editor:update-current-descriptor-of-shape-base-action',
  props<{
    IRI: string;
    descriptor?: Partial<GeneralShapeDescriptor>;
  }>(),
);

export const setShapeAttributeAction = createAction(
  'editor:attribute-panel-action',
  props<{ object: Record<string, any> }>(),
);

export const setShapeToBeSaved = createAction(
  'editor:set-shape-to-be-saved',
  props<{ IRI: string }>(),
);

export const setCurrentSVGAttributesOfShape = createAction(
  'editor:set-current-svg-attribute-of-shape',
  props<{ shapeIRI: string; svgAttributes: SVGAttributes }>(),
);

export const setSVGAttributesOfShape = createAction(
  'editor:set-svg-attribute-of-shape',
  props<{ shapeIRI: string; svgAttributes: SVGAttributes }>(),
);

export const setSVGSubAttribute = createAction(
  'editor:set-svg-sub-attribute',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: keyof SVGAttributes;
    innerKey: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentSVGSubAttribute = createAction(
  'editor:set-svg-current-sub-attribute',
  props<{
    IRI: string;
    key: keyof SVGAttributes;
    innerKey: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentScenes = createAction(
  'editor:set-current-scenes',
  props<{
    scenes: string[];
  }>(),
);

export const selectSubScene = createAction(
  'editor:select-sub-scene',
  props<{
    name: string;
  }>(),
);

export const selectSubSceneBase = createAction(
  'editor:select-sub-scene-base',
  props<{
    name: string;
  }>(),
);

export const setCanvasPosition = createAction(
  'editor:set-canvas-position',
  props<{ position: CanvasPosition }>(),
);

export const setCurrentScene = createAction(
  'editor:set-current-scene',
  props<{
    scene: string;
  }>(),
);

export const setSceneName = createAction(
  'editor:set-scene-name',
  props<{
    index: number;
    name: string;
  }>(),
);

export const addNewScene = createAction(
  'editor:add-new-scene',
  props<{
    name: string;
  }>(),
);

export const addNewSceneBase = createAction(
  'editor:add-new-scene-base',
  props<{
    name: string;
  }>(),
);

export const addSubScene = createAction(
  'editor:add-sub-scene',
  props<{
    parentIndex: number;
    name: string;
    index: number;
  }>(),
);

export const openFile = createAction(
  'editor:open-file',
  props<{
    ID: string;
  }>(),
);

export const addFileToStore = createAction(
  'editor:add-file-to-store',
  props<{
    file: ResourceData;
  }>(),
);

export const setSubSceneName = createAction(
  'editor:set-sub-scene-name',
  props<{
    parentIndex: number;
    index: number;
    name: string;
  }>(),
);

export const setScenes = createAction(
  'editor:set-scenes',
  props<{
    scenes: Scene[];
  }>(),
);

export const setSubScenePosition = createAction(
  'editor:add-sub-scene',
  props<{
    parentIndex: number;
    name: string;
    index: number;
  }>(),
);

export const setTransformSubAttribute = createAction(
  'editor:set-transform-sub-attribute',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: keyof ShapeTransform;
    innerKey: 'x' | 'y' | 'relative';
    value: number | boolean;
  }>(),
);
export const setCurrentTransformSubAttribute = createAction(
  'editor:set-svg-current-transform-sub-attribute',
  props<{
    shapeIRI: string;
    key: keyof ShapeTransform;
    innerKey: 'x' | 'y' | 'relative';
    value: number | boolean;
  }>(),
);

export const setDescriptorSubAttribute = createAction(
  'editor:set-descriptor-sub-attribute',
  props<{
    shapeIRI: string;
    key: 'sections' | 'index' | 'label' | 'opacity';
    innerKey?: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentDescriptorSubAttribute = createAction(
  'editor:set-current-descriptor-sub-attribute',
  props<{
    shapeIRI: string;
    key: 'sections' | 'index' | 'label' | 'opacity';
    innerKey?: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const incrementStrokeWidthAction = createAction(
  'editor:increment-stroke-width',
  props<{ increment: number }>(),
);

export const setPatchLoading = createAction(
  'editor:set-patch-loading',
  props<{
    value: boolean;
  }>(),
);

export const deleteShapeAction = createAction(
  'editor:delete-shape',
  props<{
    IRI: string;
  }>(),
);

export const deleteShapeFromFileAction = createAction(
  'editor:delete-shape-from-file',
  props<{
    IRI: string;
  }>(),
);

export const setProjectLoading = createSetterAction<boolean>(
  'set-project-loading',
);

export const addNewState = createAction(
  'editor:add-new-state',
  props<{
    name: string;
    index?: number;
  }>(),
);

export const removeState = createAction(
  'editor:remove-state',
  props<{
    name: string;
  }>(),
);

export const setCurrentState = createAction(
  'editor:set-current-state',
  props<{
    name: string;
  }>(),
);

export const addNewShapeAction = createAction(
  'editor:add-new-shape',
  props<{
    data: ResourceData;
  }>(),
);

export const setNewShapeAction = createAction(
  'editor:set-new-shape',
  props<{
    data: ResourceData;
  }>(),
);

export const setShapeLabel = createAction(
  'editor:set-shape-label',
  props<{ IRI: string; label: string }>(),
);

export const toggleSelectAction = createAction(
  'editor:toggle-select-action',
  props<{ IRI: string }>(),
);

export const shiftSelectShapes = createAction(
  'editor:toggle-select-action',
  props<{ IRI: string }>(),
);

export const setShapeDescriptor = createAction(
  'editor:set-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const setDescriptorItem = createAction(
  'editor:set-descriptor-item',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: keyof GeneralShapeDescriptor | AnimationKeys;
    innerKey?: string;
    value: GeneralShapeDescriptor[keyof GeneralShapeDescriptor];
  }>(),
);

export const setCurrentDescriptor = createAction(
  'editor:set-current-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const setOriginalDescriptor = createAction(
  'editor:set-original-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const setShapeResourceData = createAction(
  'editor:set-resourceData',
  props<{ data: ResourceData<GeneralShapeDescriptor> }>(),
);

export const addShapeToStore = createAction(
  'editor:add-shape-to-store',
  props<{ data: ResourceData<GeneralShapeDescriptor> }>(),
);

export const saveFileRequest = createAction('editor:save-file-request');

export const saveFileRequestComplete = createAction(
  'editor:save-file-request-complete',
);

export const setBBoxOfShape = createAction(
  'editor:update-root-animation-frame',
  props<{ IRI: string; bBox: BBox }>(),
);

export const switchScene = createAction(
  'editor:switch-scene',
  props<{ scene: string }>(),
);

export const loadScene = createAction(
  'editor:load-scene',
  props<{ scene: string }>(),
);

export const loadSceneRequest = createAction(
  'editor:load-scene-request',
  props<{ scene: string }>(),
);

export const fetchShape = createAction(
  'editor:fetch-shape-action',
  props<{ IRI: string }>(),
);

export const addState = createAction(
  'editor:add-state',
  props<{ name: string }>(),
);

export const selectState = createAction(
  'editor:select-state',
  props<{ name: string }>(),
);

export const setFontSize = createAction(
  'editor:set-font-size',
  props<{
    fontSize: number;
  }>(),
);

export const setNoAnimationMode = createAction(
  'editor:set-no-animation-mode',
  props<{
    value: boolean;
  }>(),
);

export const setFontLoaded = createAction(
  'editor:set-font-loaded',
  props<{
    value: boolean;
  }>(),
);
export const saveNewComponentAction = createAction(
  'editor:save-new-comp',
  props<{
    data: ResourceData;
  }>(),
);
