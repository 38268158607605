<div class="key-value-menu" fxLayout="column" fxLayoutGap="4px">
  <div
    fxLayout="row"
    fxLayoutAlign="space-around center"
    class="title-container"
  >
    <div class="title">Inputs</div>
  </div>
  <div class="data-container" fxLayout="column" fxLayoutGap="6px">
    <div
      *ngFor="let data of localData; index as index"
      [class.selected]="isSelected(index)"
      fxLayout="row"
      fxLayoutAlign=" center"
      class="row-item"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div
          *ngIf="data.key === '__new__'; else dataRow"
          fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign=" center"
        >
          <div
            class="type type-string"
            [class.selected-type]="newTypeIndex === 0"
          >
            string
          </div>
          <div
            class="type type-number"
            [class.selected-type]="newTypeIndex === 1"
          >
            number
          </div>
          <div
            class="type type-boolean"
            [class.selected-type]="newTypeIndex === 2"
          >
            boolean
          </div>
          <div
            class="type type-color"
            [class.selected-type]="newTypeIndex === 3"
          >
            color
          </div>
          <div
            class="type type-option"
            [class.selected-type]="newTypeIndex === 4"
          >
            option
          </div>
        </div>
      </div>
      <ng-template #dataRow>
        <div class="key" [style.min-width.px]="200">
          <nw-editable-text
            [text]="data.key"
            (textChanged)="keyChanged(data.key, $event)"
            [activate]="isKeySelected(index)"
          ></nw-editable-text>
        </div>

        <div
          *ngIf="!data.options; else options"
          class="type"
          [ngClass]="'type-' + data.value"
        >
          {{ data.value }}
        </div>
        <ng-template #options>
          <!-- *ngIf="isSelected(index) && valueSelected; else optionField" -->
          <div [style.height.px]="20">
            <div [style.position]="'absolute'">
              <nw-floating *ngIf="isSelected(index) && valueSelected">
                <div
                  class="type type-option-container"
                  fxLayout="column"
                  fxLayoutAlign=" center"
                  fxLayoutGap="4px"
                >
                  <div
                    *ngFor="let option of data.options || []; index as i"
                    fxLayout="row"
                    fxLayoutAlign=" center"
                    [class.option-selected]="selectedOption === i"
                    class="option-container"
                  >
                    <nw-editable-text
                      [text]="option"
                      (textChanged)="optionChanged(data.key, i, $event)"
                      [activate]="selectedOption === i && optionEditMode"
                    ></nw-editable-text>
                  </div>
                </div>
              </nw-floating>
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign=" center"
            class="type type-option single-item"
            [class.hidden-by-opacity]="isSelected(index) && valueSelected"
          >
            <div>{{ data.options[0] }}</div>
            <div [style.padding.px]="2">
              <nw-icon type="expand_more" [size]="14"></nw-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
