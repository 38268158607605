<div class="fill-available root" #editorContainer fxLayout="row">
  <div class="flex-column stretch">
    <nw-project-header
      (setOverlay)="overlayComponent = $event"
    ></nw-project-header>
    <nw-side-panel></nw-side-panel>
  </div>

  <div
    class="fill-available-width overflow-hidden"
    fxLayout="row"
    *ngIf="
      !shapeService.previewShapeIsLoading && shapeService.previewShape;
      else noFileSelected
    "
  >
    <div class="overflow-hidden" fxLayout="column" style="margin-bottom: 2px">
      <nw-control-panel></nw-control-panel>
      <div
        *ngIf="shapeService.previewShapeIsLoading; else canvas"
        class="w-100 h-100 editor-container flex-row space-around"
      >
        <div
          class="flex-column gap-16"
          [class.blink]="shapeService.previewShapeIsLoading"
        >
          <img src="assets/please_select.svg" style="width: 156px" />
          <div *ngIf="!shapeService.previewShapeIsLoading">
            Please select a file
          </div>
          <div *ngIf="shapeService.previewShapeIsLoading">
            File is being loaded
          </div>
        </div>
      </div>
      <ng-template #canvas>
        <div
          fxFlex="1 1 auto"
          class="editor-container"
          style="overflow: hidden"
        >
          <div
            class="position-relative w-100 flex-row space-around"
            *ngIf="cs.shapeAddMode == 'path'"
          >
            <div class="path-shape-extend-mode-type">
              <div class="flex-row gap-10">
                <div
                  class="path-shape-extend-case"
                  [ngStyle]="{
                    opacity: cs.pathShapeExtendMode == 'line' ? 1 : 0.3,
                  }"
                >
                  <nw-icon
                    type="pen_size_2"
                    [size]="18"
                    [outlined]="true"
                  ></nw-icon>
                </div>
                <div
                  class="path-shape-extend-case"
                  [ngStyle]="{
                    opacity: cs.pathShapeExtendMode == 'curve' ? 1 : 0.3,
                  }"
                >
                  <nw-icon
                    type="line_curve"
                    [size]="18"
                    [outlined]="true"
                  ></nw-icon>
                </div>
                <div
                  class="path-shape-extend-case"
                  [ngStyle]="{
                    opacity: cs.pathShapeExtendMode == 'anchor-curve' ? 1 : 0.3,
                  }"
                >
                  <img
                    src="assets/curve.png"
                    style="height: 16px; width: 15px"
                  />
                </div>
              </div>
            </div>
          </div>
          <iw-canvas> </iw-canvas>
        </div>
      </ng-template>
      <nw-animation-panel
        *ngIf="!shapeService.previewShapeIsLoading"
      ></nw-animation-panel>
    </div>

    <div class="fill-available-height" fxLayout="column">
      <nw-zoom-panel></nw-zoom-panel>
      <nw-shape-attribute-panel fxFlex="1 1 auto"></nw-shape-attribute-panel>
    </div>
  </div>

  <ng-template #noFileSelected>
    <div
      fxFlex="1 1 auto"
      class="editor-container w-100 h-100 flex-row space-around"
    >
      <div
        class="flex-column gap-16"
        [class.blink]="shapeService.previewShapeIsLoading"
      >
        <img src="assets/please_select.svg" style="width: 156px" />
        <div *ngIf="!shapeService.previewShapeIsLoading">
          Please select a file
        </div>
        <div *ngIf="shapeService.previewShapeIsLoading">
          File is being loaded
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div
  *ngIf="overlayComponent"
  class="iw-overlay"
  (click)="overlayComponent = null"
>
  <div class="layer"></div>
  <div
    [ngSwitch]="overlayComponent"
    class="content w-100 h-100 flex-row space-around"
  >
    <nw-project-languages-edit
      *ngSwitchCase="'languages'"
      (click)="$event.stopPropagation()"
      (exit)="overlayComponent = null"
    ></nw-project-languages-edit>
    <nw-color-palette-edit
      *ngSwitchCase="'color-palette'"
      (click)="$event.stopPropagation()"
      (exit)="overlayComponent = null"
    ></nw-color-palette-edit>
    <nw-project-users
      *ngSwitchCase="'users'"
      (click)="$event.stopPropagation()"
      (exit)="overlayComponent = null"
    ></nw-project-users>
  </div>
</div>
