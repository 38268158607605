import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AnimationValue,
  LoopTrajectoryAnimation,
} from '../../../../elements/resource/types/shape.type';
import { TrajectoryShape } from '../../../../element-editor/shape/shapes/trajectory/trajectory-shape';
import { CanvasService } from '../../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-loop-translate-animation-item',
  templateUrl: './loop-translate-animation-item.component.html',
  styleUrls: ['./loop-translate-animation-item.component.scss'],
})
export class LoopTranslateAnimationItemComponent implements OnInit, OnDestroy {
  @Input()
  shape: TrajectoryShape;

  @Input()
  animationValue: AnimationValue;

  itemRecordInProgress = false;

  itemRecording: Subscription;

  get isBlink() {
    return this.cs.blinkIRI === this.shape.IRI;
  }

  get animation() {
    return this.animationValue as LoopTrajectoryAnimation;
  }

  constructor(private readonly cs: CanvasService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.itemRecording?.unsubscribe();
  }

  recordItems() {
    if (this.cs.shapeRecordMode) {
      this.itemRecording?.unsubscribe();
      this.cs.stopRecordMode();
    } else {
      this.cs.startRecordMode(this.shape.IRI, this.animation.shapes);
      this.itemRecording = this.cs.generalEventSubscribe(
        'selectedShapeIRIs',
        (shapes: string[]) => {
          this.animation.shapes = shapes;
          this.shape.save();
        },
      );
    }
  }

  change() {
    this.shape.save();
  }
}
