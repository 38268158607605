import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AttributePanelState } from '../../../services/animation/animation.types';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { DashConfig } from '../../../elements/resource/types/shape.type';

@Component({
  selector: 'nw-stroke-attribute',
  templateUrl: './stroke-attribute.component.html',
  styleUrls: ['./stroke-attribute.component.scss'],
})
export class StrokeAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'stroke';
  }

  currentDash: DashConfig;
  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('stroke'));

    this.attributeState$.subscribe(state => {
      // console.log('------ stroke attr-state ------', state);
      this.currentDash = state?.value?.dash?.[0];
    });
  }

  dashChanged(val: boolean) {
    console.log('dash-changed', val);

    this.updateSubAttributeValue(
      'dash',
      val
        ? {
            fill: 20,
            gap: 20,
          }
        : undefined,
    );
  }
  dashFillChange(fill: number) {
    this.updateSubAttributeValue('dash', {
      ...this.currentDash,
      fill,
    });
  }
  dashGapChange(gap: number) {
    this.updateSubAttributeValue('dash', {
      ...this.currentDash,
      gap,
    });
  }
}
