import { Subscription } from 'rxjs';
import { LimiterService } from '../../../../../services/limiter/limiter.service';
import { CurveSection } from '../path-sections/curve/curve-section';
import { PathSection } from '../path-sections/path-section';
import {
  Coords,
  HandSectionDescriptor,
} from '../../../../../elements/resource/types/shape.type';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import { Circle } from '../../primitive/circle-element';
import { HandShape } from './hand-shape';

const { PI } = Math;

export class HandSection {
  x: number;
  y: number;

  pc1: PointController;
  pc2: PointController;
  unMoved: Coords;

  firstSection: CurveSection;
  secondSection: CurveSection;

  closingLine: PathSection;

  get symmetric() {
    return this.descriptor.symmetric;
  }

  set symmetric(val: boolean) {
    this.descriptor.symmetric = val;
  }

  get d1() {
    return this.descriptor.d1;
  }

  set d1(val: number) {
    this.descriptor.d1 = val;
  }

  get d2() {
    return this.descriptor.d2;
  }

  set d2(val: number) {
    this.descriptor.d2 = val;
  }

  get l() {
    return this.descriptor.l;
  }

  set l(val: number) {
    this.descriptor.l = val;
  }

  get a() {
    return this.descriptor.a;
  }

  set a(val: number) {
    this.descriptor.a = val;
  }

  get cs() {
    return this.handShape.cs;
  }

  get lineMode() {
    return this.handShape.cs.isPressed('Shift');
  }

  get coords() {
    return [this.x, this.y];
  }

  abs: Coords;

  get start(): Coords {
    return this.index === 0 ? [0, 0] : this.prev.abs;
  }

  get prev() {
    return this.handShape.getPrev(this.index);
  }

  get next() {
    return this.handShape.getNext(this.index);
  }

  sub: Subscription;

  get container() {
    return this.handShape.container;
  }

  get circleContainer() {
    return this.handShape.circleContainer;
  }

  circle: Circle;

  constructor(
    public handShape: HandShape,
    public descriptor: HandSectionDescriptor,
    private index: number,
  ) {
    const { l, a } = descriptor;

    this.x = l * Math.cos(a);
    this.y = l * Math.sin(a);

    this.setAbs();

    this.firstSection = new CurveSection(this.handShape, {
      x: this.x,
      y: this.y,
      b1: PI / 4,
      h1: l / 2,
      b2: PI / 4,
      h2: l / 2,
      id: Math.random().toString(),
      index,
    });

    this.secondSection = new CurveSection(this.handShape, {
      x: this.x,
      y: this.y,
      b1: PI / 4,
      h1: l / 2,
      b2: PI / 4,
      h2: l / 2,
      id: Math.random().toString(),
      index: 6 - index,
    });

    if (this.handShape.editable) {
      this.sub = this.cs.keyEventSubscribe('Shift', () => {
        if (this.pc1?.inProgress) {
          this.symmetric = !this.symmetric;
          this.d2 = this.d1;
        }

        if (this.pc2?.inProgress) {
          this.symmetric = !this.symmetric;
          this.d1 = this.d2;
        }
      });

      this.circle = new Circle(this, this.circleContainer, {
        x: this.abs[0],
        y: this.abs[1],
        r: 5,
        fill: '#ffffff',
        stroke: '#ff0000',
      }).drag(
        (dx, dy) => this.drag(dx, dy),
        () => this.startDrag(),
        () => this.endDrag(),
      );
      this.initPCs();
    }

    if (this.handShape.selected || this.handShape.config?.initByDrag) {
      this.select();
    } else {
      this.deselect();
    }
  }

  startDrag() {
    this.saveUnMoved();
    this.next?.saveUnMoved();
  }

  drag(dx: number, dy: number) {
    dx /= this.cs.canvasScale;
    dy /= this.cs.canvasScale;

    this.adjust(dx, dy);
    if (!this.cs.isShiftPressed) {
      this.next?.adjust(-dx, -dy);
    }
    this.handShape.refresh();
  }

  endDrag() {
    this.save();
  }

  saveUnMoved() {
    this.unMoved = [this.x, this.y];
  }

  adjust(dx: number, dy: number) {
    const [uX, uY] = this.unMoved;
    this.x = uX + dx;
    this.y = uY + dy;
    this.a = this.getAFromXY(this.x, this.y);
    this.l = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  getAFromXY(x: number, y: number) {
    const angle = Math.atan(y / x);
    return this.norm(x >= 0 ? angle : angle - PI);
  }

  save() {
    this.handShape.saveSections();
  }

  remove() {
    // console.log('remove');
    this.pc1?.remove();
    this.pc2?.remove();
    this.sub?.unsubscribe();
  }

  initPCs() {
    this.pc1 = new PointController(
      this,
      {
        pOffset: this.start,
        p: [this.x, this.y],
        showCircle: true,
        updateCircle: true,
        d: this.d1,
        angle: this.pcA,
        hack: true,
        drag: ({ d }) => {
          this.d1 = d;
          if (this.symmetric) {
            this.d2 = this.d1;
          }
          this.handShape.refresh();
        },
        end: () => this.save(),
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );

    this.pc2 = new PointController(
      this,
      {
        pOffset: this.start,
        p: [this.x, this.y],
        showCircle: true,
        updateCircle: true,
        d: -this.d2,
        angle: this.pcA,
        hack: true,
        drag: ({ d }) => {
          this.d2 = -d;
          if (this.symmetric) {
            this.d1 = this.d2;
          }
          this.handShape.refresh();
        },
        end: () => this.save(),
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );
  }

  norm(angle: number) {
    if (angle > 2 * PI) {
      return angle - 2 * PI;
    }
    if (angle < 0) {
      return angle + 2 * PI;
    }
    return angle;
  }

  get pcA() {
    if (this.next) {
      const d1 = this.norm(this.a + PI);
      const d2 = d1 > this.next.a ? this.next.a + 2 * PI : this.next.a;
      return (this.next ? (d1 + d2) / 2 : this.a - PI / 2) || PI;
    } else {
      return this.a - PI / 2 || PI;
    }
  }

  select() {
    this.circle?.show();
    this.pc1?.show();
    this.pc2?.show();
  }

  deselect() {
    this.circle?.hide();
    this.pc1?.hide();
    this.pc2?.hide();
  }

  setAbs() {
    this.abs = [this.start[0] + this.x, this.start[1] + this.y];
  }

  x1: number;
  y1: number;

  x2: number;
  y2: number;

  refresh() {
    this.setAbs();
    const [cx, cy] = this.abs;

    this.circle?.patch({
      x: cx,
      y: cy,
      r: 5 / this.cs.canvasScale,
      'stroke-width': 1 / this.cs.canvasScale,
    });

    this.pc1?.patch({
      pOffset: this.start,
      p: [this.x, this.y],
      angle: this.pcA,
      d: this.d1,
    });
    this.pc2?.patch({
      pOffset: this.start,
      p: [this.x, this.y],
      angle: this.pcA,
      d: -this.d2,
    });

    const [x, y] = this.getVector(this.l, this.a);

    const [s1x, s1y] = this.startDVector1();
    const [e1x, e1y] = this.endDVector1();

    [this.x1, this.y1] = [-s1x + x + e1x, -s1y + y + e1y];
    this.a1 = this.getAFromXY(this.x1, this.y1);

    const [s2x, s2y] = this.startDVector2();
    const [e2x, e2y] = this.endDVector2();

    [this.x2, this.y2] = [e2x - x - s2x, e2y - y - s2y];
    this.a2 = this.getAFromXY(this.x2, this.y2);

    this.refreshSections();
  }

  limitCoords([x, y]: Coords, r: number, a: number): Coords {
    if (this.lineMode) {
      return [x, x * Math.tan(a)];
    } else {
      return LimiterService.circle(r, [x, y]);
    }
  }

  startDVector1() {
    return this.prev?.endDVector1() || [0, 0];
  }

  startDVector2() {
    return this.prev?.endDVector2() || [0, 0];
  }

  endDVector1() {
    return this.getVector(this.d1, this.pcA);
  }

  endDVector2() {
    return this.getVector(this.d2, this.pcA);
  }

  refreshSections() {
    this.refreshFirstSection();
    this.refreshClosingLine();
    this.refreshSecondSection();
  }

  a1: number;
  a2: number;

  refreshFirstSection() {
    this.firstSection.x = this.x1;
    this.firstSection.y = this.y1;
    this.firstSection._h1 = this.l / 6;
    this.firstSection._h2 = this.l / 6;

    let diff = this.getDiff(this.prev?.a1 || this.a2, this.a1);
    this.firstSection._b1 = PI / 2 + diff / 2;

    if (this.next) {
      diff = this.getDiff(this.a1, this.next.a1);
      this.firstSection._b2 = PI / 2 + diff / 2;
    } else {
      // This is the case of the last hand-section //
      this.firstSection._b2 = PI / 2;
    }
  }

  getFirstSection() {
    return this.firstSection._getSection();
  }

  refreshClosingLine() {
    if (!this.closingLine) {
      return;
    }

    const [xf, yf] = [
      (this.d1 + this.d2) * Math.cos(this.a + PI / 2),
      (this.d1 + this.d2) * Math.sin(this.a + PI / 2),
    ];
    this.closingLine.x = xf;
    this.closingLine.y = yf;
  }

  getClosingLineD() {
    return this.closingLine._getSection();
  }

  initClosingLine() {
    this.closingLine = new PathSection(this.handShape, {
      id: Math.random().toString(),
      x: this.x,
      y: this.y,
      index: 3,
    });
    this.refreshClosingLine();
  }

  refreshSecondSection() {
    this.secondSection.x = this.x2;
    this.secondSection.y = this.y2;
    this.secondSection._h1 = this.l / 6;
    this.secondSection._h2 = this.l / 6;

    if (this.next) {
      const diff = this.getDiff(this.a2, this.next.a2);
      this.secondSection._b1 = PI / 2 - diff / 2;
    } else {
      // This is the case of the last hand-section
      this.secondSection._b1 = PI / 2;
    }

    const diff = this.getDiff(this.prev?.a2 || this.a1, this.a2);
    this.secondSection._b2 = PI / 2 - diff / 2;
  }

  getSecondSection() {
    return this.secondSection._getSection();
  }

  getDiff(a1: number, a2: number) {
    let diff = a1 - a2;
    if (Math.abs(diff) > PI) {
      diff = 2 * PI - Math.abs(diff);
      if (a1 > a2) {
        diff = -diff;
      }
    }
    return diff;
  }

  getL(l: number, angle: number) {
    const [x, y] = [l * Math.cos(angle), l * Math.sin(angle)];
    return `l ${x} ${y}`;
  }

  getVector(l: number, angle: number) {
    return [l * Math.cos(angle), l * Math.sin(angle)];
  }
}
