import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  saveProjectLanguages,
  saveProjectLanguagesBase,
} from '../../../projects/project.actions';
import { getCurrentProjectLanguages } from '../../../projects/project.selector';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'nw-project-languages-edit',
  templateUrl: './project-languages-edit.component.html',
  styleUrls: ['./project-languages-edit.component.scss'],
})
export class ProjectLanguagesEditComponent {
  @Output()
  exit = new EventEmitter();

  selectedLang = 'en';

  languages$: Observable<string[]>;
  languages: string[];

  langMap = {
    hu: 'hungarian',
    en: 'english',
    ger: 'german',
    esp: 'spanish',
    fr: 'french',
    it: 'italien',
    pl: 'polish',
  };

  constructor(private readonly store: Store) {
    this.languages$ = this.store.select(getCurrentProjectLanguages);
    this.languages$.subscribe(langs => (this.languages = cloneDeep(langs)));
  }

  get langKeys() {
    return ['hu', 'en', 'ger', 'esp', 'fr', 'it', 'pl'].filter(
      key => !this.languages.includes(key),
    );
  }

  addLanguage() {
    console.log('------- add-language -----', this.selectedLang); //
    this.languages.push(this.selectedLang);
  }

  save() {
    // const languages = this.languages;
    const languages = ['hu'];
    this.store.dispatch(saveProjectLanguages({ languages }));
    this.store.dispatch(saveProjectLanguagesBase({ languages }));
    this.exit.emit();
  }

  discard() {
    this.exit.emit();
  }
}
