import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  onlyShapeType,
  selectedShapes,
} from '../../../store/selector/editor.selector';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nw-animation-action',
  templateUrl: './animation-action.component.html',
  styleUrls: ['./animation-action.component.scss'],
})
export class AnimationActionComponent {
  areThereSelectedShapes$: Observable<boolean>;

  onlyPathShapes$: Observable<boolean>;
  constructor(
    private readonly store: Store,
    // public readonly animationsService: AnimationService, //
    // private readonly shapeService: ShapeService, //
  ) {
    this.areThereSelectedShapes$ = this.store
      .select(selectedShapes)
      .pipe(map(selectedShapes => !!selectedShapes.length));
    this.onlyPathShapes$ = this.store.select(onlyShapeType('path-shape'));
  }
}
