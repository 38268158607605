import { CurveSection } from '../path-sections/curve/curve-section';
import { PathShape } from '../path-shape';

export class ShadowShape extends PathShape {
  getType() {
    return 'shadow';
  }

  get s1() {
    return this.pathSections[0] as CurveSection;
  }

  get s2() {
    return this.pathSections[1] as CurveSection;
  }

  init() {
    if (!this.descriptor.sections?.length) {
      this.descriptor.sections = [
        {
          id: Math.random().toString(),
          type: 'curve',
          x: 100,
          y: 100,
        },
        {
          id: Math.random().toString(),
          type: 'curve',
          x: -100,
          y: -100,
        },
      ];
    }
    this.descriptor.closed = true;
    super.init();

    this.removeSectionPaths();
  }

  // TODO - clean this
  removeSectionPaths() {
    // this.s2.hoverPath?.remove();
    // this.s2.sectionPath?.remove();
    this.refresh();

    delete this.s2.pc1;
    delete this.s2.pc2;

    this.s1.removeEditPaths();
    this.s2.removeEditPaths();
  }

  reInit() {
    super.reInit();
    this.removeSectionPaths();
  }

  refresh() {
    this.updateOneByTheOther();
    super.refresh();
  }

  updateOneByTheOther() {
    const { b1, h1, b2, h2 } = this.s1;

    this.s2._b1 = Math.PI - b2;

    this.s2._b2 = Math.PI - b1;

    const inRange = (angle: number) =>
      Math.PI / 2 < angle && angle <= (3 * Math.PI) / 2;

    const r1 = inRange(b1);
    const r2 = inRange(b2);

    if ((r1 && r2) || (!r1 && !r2)) {
      this.s2._h1 = h2 * 0.75;
      this.s2._h2 = h1 * 0.75;
    } else {
      this.s2._h1 = h2 * 0.75;
      this.s2._h2 = h1 * 1.25;
    }
  }

  select() {
    super.select();
    this.s1.showControlPoints();
  }

  deselect() {
    super.deselect();
    this.s1.hideControlPoints();
  }
}
