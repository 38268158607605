import { HttpService } from './store/http/http.service';
import { MSEditorService } from './components/ms-editor/ms-editor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServerService } from './server.service';

// Components
import { AppComponent } from './app.component';

// Services
import { KeyHandlerService } from './services/keyhandler/keyhandler.service';
import { CanvasService } from './services/canvas/canvas.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseReducer } from './store/reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreService } from './store/store.service';
import { StoreEffects } from './store/effect/effect';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { TypeService } from './components/data-editor/type.service';

import { ComponentsModule } from './components/components.module';
import { MetaScriptModule } from './ms/metascript.module';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';

import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ProfileComponent } from './profile/profile.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { EditorModule } from './element-editor/element-editor.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SidePanelModule } from './components/side-panel/side-panel.module';
import { OrganisationModule } from './organisation/organisation.module';
import { ProjectModule } from './projects/project.module';
import { UserModule } from './user/user.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    ProfileComponent,
  ],
  imports: [
    ComponentsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot(),
    MaterialModule,
    // ShapeAttributePanelModule,
    EffectsModule.forRoot([StoreEffects]),
    StoreModule.forRoot(BaseReducer),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 25 })
      : [],
    MetaScriptModule,
    AppRoutingModule,
    EditorModule,
    SidePanelModule,
    AuthenticationModule,
    OrganisationModule,
    ProjectModule,
    UserModule,
    HomeModule,
  ],
  providers: [
    // useHttpCacheLocalStorage,
    CanvasService,
    KeyHandlerService,
    MSEditorService,
    ServerService,
    StoreService,
    TypeService,
    StoreEffects,
    HttpService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.log(
      `App version: ${environment.version} (${
        environment.production ? 'production' : 'development'
      })`,
    );
    // if (!environment.production) {
    //   router.events
    //     .pipe(filter(event => event instanceof NavigationEnd))
    //     .subscribe(event => console.log(activatedRoute.snapshot));
    // }
  }
}
