import { cloneDeep } from 'lodash';
import {
  Scene,
  ShapePosition,
} from '../../../elements/resource/types/shape.type';
import { RootShape } from '../../shape/shapes/general/root/root-shape';
import { setCurrentScenes } from '../../store/editor.actions';
import { selectCurrentScenes } from '../../store/selector/editor.selector';
import { SubSceneTransitionFrame } from '../components/animation-frame/animation.types';
import { AnimationFrameObject } from './animation-frame-object';
import {
  Easing,
  IncrementController,
} from './increment/controller/increment.controller';

export class SubSceneTransitionFrameObject extends AnimationFrameObject<SubSceneTransitionFrame> {
  init() {
    super.init();
    // console.log('-- SubSceneTransitionFrameObject --');
    this.service.store.select(selectCurrentScenes).subscribe(scenes => {
      // -- // -- // -- // -- //
      const [main, sub] = scenes || [];
      if (this.mainScene == main && this.targetSubScene == sub) {
        super.select();
      }
    });
  }

  get rootShape() {
    return this.shape as RootShape;
  }

  get mainScene() {
    return this.frame.mainScene;
  }

  get targetSubScene() {
    return this.frame.targetSubScene;
  }

  set targetSubScene(val: string) {
    this.frame.targetSubScene = val;
  }

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      mainScene: this.frame.mainScene,
      targetSubScene: this.frame.targetSubScene,
      translate: this.frame.translate,
      scale: this.frame.scale,
    };
  }

  position: ShapePosition;

  get x() {
    return this.position?.x;
  }
  get y() {
    return this.position?.y;
  }

  get scale() {
    return this.position?.scale?.x;
  }

  select() {
    super.select();

    const targetScene = this.shape.scenes.find(
      ({ name }) => name == 'main', // this.cs.currentScene,
    );

    const targetSubScene = targetScene?.subscenes?.find(
      ({ name }) => name == this.targetSubScene,
    );

    console.log('targetScene-position', targetSubScene.position);

    if (targetSubScene?.position) {
      this.position = cloneDeep(targetSubScene.position);
      console.log('setting-position', this.position);
      (this.shape as RootShape).applyPosition(targetSubScene.position);
    }

    if (!this.cs.isShiftPressed) {
      this.service.store.dispatch(
        setCurrentScenes({
          scenes: [this.mainScene, this.targetSubScene],
        }),
      );
    }

    console.log('this.position', this.position);
  }

  xInc: IncrementController;
  yInc: IncrementController;

  sxInc: IncrementController;
  syInc: IncrementController;

  // async animate(inverse = false, timeScale = 1) {
  //   return Promise.resolve();
  // }

  executeAnimation(timeScale?: number): Promise<void> {
    // console.log('subscene-transition > animate'); //
    // const scenes =
    // this.rootMainFrame.shape.scen
    const scenes = this.rootMainFrame.shape.scenes;

    const currentPosition = this.rootShape.canvasPosition;
    const { scale: scale1 } = currentPosition;

    const targetPosition = this.getPosition(
      scenes,
      this.mainScene,
      this.targetSubScene,
    );
    const { scale: scale2 } = targetPosition;

    const numberOfBatches = Math.floor(
      this.duration * timeScale * this.cs.batchPerSecond,
    );

    this.xInc = new IncrementController(
      currentPosition.x,
      targetPosition.x * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );
    this.yInc = new IncrementController(
      currentPosition.y,
      targetPosition.y * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );

    this.sxInc = new IncrementController(
      scale1.x,
      scale2.x * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );
    this.syInc = new IncrementController(
      scale1.y,
      scale2.y * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );

    this.currentIncrement = 0;
    return new Promise<void>(resolve => {
      this.service.animationFrames[this.id] = increment => {
        this.currentIncrement += increment;
        let end = false;
        if (this.currentIncrement >= numberOfBatches) {
          end = true;
          // Occasional overflow is eliminated
          increment -= this.currentIncrement - numberOfBatches;
        }

        const _x = this.xInc.increment(increment);
        const _y = this.yInc.increment(increment);
        const _sx = this.sxInc.increment(increment);
        const _sy = this.syInc.increment(increment);

        // console.log({ _sx, _sy });
        this.rootShape.applyPosition({
          x: _x,
          y: _y,
          scale: {
            x: _sx,
            y: _sy,
          },
        });

        if (end) {
          // this.rootShape.setPosition(targetPosition);
          delete this.service.animationFrames[this.id];
          resolve();
        }
      };
    });
  }

  getPosition(scenes: Scene[], mainScene: string, subScene: string) {
    const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
    const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    return scenes[mainSceneIndex].subscenes[subSceneIndex].position;
  }
}
