import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnimationPanelConfig } from '../../../services/animation/animation.types';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';

@Component({
  selector: 'nw-scale-attribute',
  templateUrl: './scale-attribute.component.html',
  styleUrls: ['./scale-attribute.component.scss'],
})
export class ScaleAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  config: AnimationPanelConfig = {
    icon: 'fit_screen',
    animationKey: 'translate',
    fields: [],
  };

  constructor(readonly store: Store) {
    super(store);
    this.key = 'scale';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('scale'));
    this.attributeState$.subscribe(animationState => {
      // console.log('--------- scale --------', animationState);
    });
  }
}
