import { Injectable } from "@angular/core";
import { Type, Primitive } from "./data-scheme";

@Injectable()
export class TypeService {
  types: Record<string, Type> = {};

  constructor() {
    this.types.label = {
      key: "label",
      label: "Label",
      literals: {
        label: Primitive.String,
      },
    };

    this.types.animationOld = {
      key: "shapeAnimation",
      label: "Animations",
      relationships: [
        {
          key: "shapeAlias",
          existing: true,
          typeId: "shapeAlias",
        },
        {
          key: "shapeGroup",
          existing: true,
          typeId: "shapeGroup",
        },
        {
          key: "animation",
          typeId: "animationInstance",
        },
      ],
    };

    this.types.animation = {
      key: "animation",
      label: "Test",
      literals: {
        duration: Primitive.Number,
        scale: Primitive.Number,
        start: Primitive.Number,
        x: Primitive.Number,
        y: Primitive.Number,
      },
    };
  }

  getType(key: string): Type {
    return this.types[key];
  }
}
