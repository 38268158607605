import { Injectable } from '@angular/core';
import { HttpService } from '../store/http/http.service';
import { Organisation } from './organisation.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestType } from '../store/actions';
import { Project } from '../projects/project.interface';
import { User } from '../user/user.interface';
import { ProjectService } from '../projects/project.service';
import { UserService } from '../user/user.service';
import { IRIToId, IdToIRI } from '../util/iri';
import { Role } from './role.interface';
import { omit } from 'lodash';

@Injectable()
export class OrganisationService {
  createOrganisation(organisation: any): Observable<Organisation> {
    return this.http
      .requestCall({
        path: 'organisation',
        type: RequestType.POST,
        body: organisation,
      })
      .pipe(map(organisation => this.mapResourceToOrganisation(organisation)));
  }

  saveOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.http
      .requestCall({
        path: 'organisation',
        type: RequestType.PATCH,
        body: omit(
          this.mapOrganisationToResource(organisation),
          'relationships',
        ),
        headers: { 'organisation-id': organisation.id },
      })
      .pipe(map(organisation => this.mapResourceToOrganisation(organisation)));
  }

  listOrganisations(): Observable<Organisation[]> {
    return this.http
      .requestCall({ path: 'user-crud/organisation', type: RequestType.GET })
      .pipe(
        map(organisations =>
          organisations.map(organisation =>
            this.mapResourceToOrganisation(organisation),
          ),
        ),
      );
  }

  getOrganisationById(id: string): Observable<{
    projects: Project[];
    users: User[];
    organisation: Organisation;
  }> {
    return this.http
      .requestCall({
        path: `organisation/${id}`,
        type: RequestType.GET,
        headers: { 'organisation-id': id },
      })
      .pipe(
        map(({ IRI, literals, relationships }) => ({
          organisation: this.mapResourceToOrganisation({
            IRI,
            literals,
            relationships,
          }),
          projects: relationships.projects.map(project =>
            this.projectService.mapResourceToProject(project),
          ),
          users: Object.values(
            relationships.roles.reduce(
              (roles, role) => ({
                ...roles,
                [role.relationships.user.IRI]:
                  this.userService.mapResourceToUser(role.relationships.user),
              }),
              {},
            ),
          ),
        })),
      );
  }

  mapResourceToOrganisation({
    IRI,
    literals,
    relationships,
  }: any): Organisation {
    const a = {
      id: IRIToId(IRI),
      name: literals.label,
      colorPalette: literals.descriptor?.colorPalette,
      roles:
        (relationships?.roles as any[])?.reduce<Record<string, Role>>(
          (roles, role) => ({
            ...roles,
            [IRIToId(role.relationships.user.IRI)]: {
              id: IRIToId(role.IRI),
              type: role.literals.type,
            },
          }),
          {},
        ) || {},
    };
    return a;
  }

  mapOrganisationToResource({
    id,
    name,
    roles,
    colorPalette,
  }: Partial<Organisation>): any {
    return {
      IRI: IdToIRI(id),
      literals: {
        label: name,
        descriptor: { colorPalette },
      },
      relationships: {
        roles: Object.entries(roles || {}).map(([userId, role]) => ({
          IRI: IdToIRI(role.id),
          literals: {
            type: role.type,
          },
          relationships: {
            user: {
              IRI: IdToIRI(userId),
            },
          },
        })),
      },
    };
  }

  constructor(
    private readonly http: HttpService,
    private readonly projectService: ProjectService,
    private readonly userService: UserService,
  ) {}
}
