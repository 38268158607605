<div *ngIf="showHeader">
  <div class="flex-row gap-20" style="border-bottom: 1px solid grey">
    <a class="inwedio-icon" routerLink="/" routerLinkActive="active">Inwedio</a>

    <!-- <button class="iw-navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->

    <div>
      <div class="flex-row gap-12">
        <div class="iw-nav-item" *ngIf="!session">
          <a class="iw-nav-link" routerLink="/signUp" routerLinkActive="active"
            >Sign up</a
          >
        </div>
        <div class="iw-nav-item" *ngIf="!session">
          <a class="iw-nav-link" routerLink="/signIn" routerLinkActive="active"
            >Sign in</a
          >
        </div>
        <div class="iw-nav-item" *ngIf="session">
          <a class="iw-nav-link" routerLink="/profile" routerLinkActive="active"
            >Profile</a
          >
        </div>
        <div class="iw-nav-item" *ngIf="session">
          <a class="iw-nav-link" routerLink="/signIn" (click)="signOut()"
            >Sign out</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
