<div class="number-select-component flex-row gap-6">
  <div style="font-size: 11px; margin-right: 4px">{{ key }}</div>
  <input
    class="input-field"
    type="number"
    [value]="currentValue"
    (click)="cs.consumeClick()"
    [step]="step"
    (change)="change($event)"
  />

  <!-- TODO - this must be back (mouseleave)="dropdownOpen = false" -->
  <div
    *ngIf="enableDropdown"
    (mouseenter)="dropdownOpen = true"
    style="height: 24px; width: 24px"
  >
    <nw-icon type="arrow_drop_down"> </nw-icon>
  </div>
  <nw-dropdown-component
    *ngIf="dropdownOpen"
    [height]="20"
    [top]="8"
    [left]="-36"
    [gap]="4"
    [data]="differentValues"
    (hoverOut)="dropdownOpen = false"
    (clicked)="currentValue = $event; dropdownOpen = false"
  >
    <ng-template let-value>
      <div
        fxLayout="row"
        fxLayoutAlign=" center"
        fxLayoutGap="8px"
        class="color-option-container"
      >
        <div>{{ value }}</div>
      </div>
    </ng-template>
  </nw-dropdown-component>
</div>
