import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';

export interface GeneralSelectorConfig {
  text?:
    | string
    | {
        title: string;
        takenOptions?: string[];
      };
  options?: GeneralSelectorOptions;
}

export type GeneralSelectorOptions = GeneralSelectorOption[];

export interface GeneralSelectorOption {
  label?: string;
  id: string;
  children?: GeneralSelectorOption[];
  text?: boolean;
  optionFilter?: (currentState: string, lastResponse: string) => boolean;
}

@Component({
  selector: 'nw-general-selector',
  templateUrl: './general-selector.component.html',
  styleUrls: ['./general-selector.component.scss'],
})
export class GeneralSelectorComponent implements OnInit, OnDestroy {
  // @Input()
  // config: GeneralSelectorConfig;

  @ViewChild('input') inputElement: ElementRef;

  subOptions: GeneralSelectorOptions;

  currentIndex = 0;

  givenText: string;

  get textTitle() {
    const { text } = this.config;
    return typeof text === 'object' ? text.title : text;
  }

  get options() {
    return this.config?.options;
  }

  get currentOptions() {
    return this.subOptions || this.options;
  }

  get selectedOption() {
    return this.currentOptions[this.currentIndex];
  }

  get isActive() {
    return !!this.config;
  }

  get config() {
    return this.cs.generalSelectorConfig;
  }

  set config(value) {
    this.cs.generalSelectorConfig = value;
  }

  constructor(public readonly cs: CanvasService) {
    console.log('general-selector > constructor');
  }

  allResults = [];

  subscriptions = [];
  ngOnInit(): void {
    console.log('general-selector > ngOnInit');
    this.allResults = [];
    if (this.config.text) {
      this.textId = Math.random().toString();
      this.textValue = this.config.text as string;
      setTimeout(() => this.inputElement.nativeElement.focus(), 50);
    }

    this.subscriptions.push(
      this.cs.keyEventSubscribe('Escape', () => {
        if (this.isActive) {
          this.clear();
        }
      })
    );
    this.subscriptions.push(
      this.cs.keyEventSubscribe('Enter', () => {
        console.log('Enter', this.isActive);
        if (this.isActive) {
          if (this.config.text) {
            this.cs.generalSelectorFinished(this.givenText, this.allResults);
          } else {
            this.select(this.selectedOption);
          }
          this.cs.consumeKeyEvent('Enter');
        }
      })
    );
    this.subscriptions.push(
      this.cs.keyEventSubscribe(
        'ArrowDown',
        () => {
          // console.log('ArrowDown', this.isActive, this.currentIndex);
          if (this.isActive) {
            this.cs.consumeKeyEvent('ArrowDown');
            this.currentIndex = Math.min(
              ++this.currentIndex,
              this.currentOptions.length - 1
            );
            // console.log('ArrowDown-in', this.isActive, this.currentIndex);
          }
        },
        99
      )
    );
    this.subscriptions.push(
      this.cs.keyEventSubscribe(
        'ArrowUp',
        () => {
          if (this.isActive) {
            this.cs.consumeKeyEvent('ArrowUp');
            this.currentIndex = Math.max(--this.currentIndex, 0);
          }
        },
        99
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map(sub => sub.unsubscribe())
  }

  select({ id, children, optionFilter }: GeneralSelectorOption) {
    this.allResults.push(id);
    this.currentIndex = 0;
    if (children) {
      if (children.length === 1 && children[0].text) {
        // TODO - figure out whats that
        this.textId = children[0].id;
        setTimeout(() => this.inputElement.nativeElement.focus(), 50);
      } else {
        this.subOptions = children.filter(({ id: currentId }) =>
          optionFilter ? optionFilter(currentId, id) : true
        );
      }
    } else {
      this.clear();
      this.cs.generalSelectorFinished(id, this.allResults);
    }
  }

  textId: string;
  textValue: string;

  done() {
    console.log('done', this.textId);
    this.cs.generalSelectorFinished(this.textId, this.allResults);
  }

  clear() {
    this.config = null;
    this.subOptions = null;
    this.currentIndex = 0;
    this.givenText = null;
  }

  keyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.cs.generalSelectorFinished(this.textId, this.allResults);
      this.clear();
    }
    event.stopPropagation();
  }
}
