import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';
import { _attributeState } from '../../../../store/selector/editor.selector';

@Component({
  selector: 'nw-dash-move-animation',
  templateUrl: './dash-move-animation.component.html',
  styleUrls: ['./dash-move-animation.component.scss'],
})
export class DashMoveAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'dash-move';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('dash-move'));
    this.attributeState$.subscribe(attrState => {
      // console.log('path-attribute-start', attrState); //
    });
  }
}
