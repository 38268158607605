import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';

@Component({
  selector: 'iw-arc-interval-attribute',
  templateUrl: './arc-interval-attribute.component.html',
  styleUrls: ['./arc-interval-attribute.component.scss'],
})
export class ArcIntervalAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'arcInterval';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('arcInterval'));
  }
}
