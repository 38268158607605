import { Injectable } from '@angular/core';
import {
  AnimationBatch,
  AnimationInnerKey,
  AnimationItem,
  AnimationKeys,
  FillConfig,
  GeneralShapeDescriptor,
  PathAnimation,
  Point,
  PrimitiveValue,
  Rotation,
  SVGAttributes,
  Scale,
  ShapeIRI,
  ShapePosition,
  ShapeTransform,
  ShowHideAnimation,
  StrokeConfig,
  __Animation,
} from '../../elements/resource/types/shape.type';
import {
  MainAnimationFrameObject,
  RootAnimationFrame,
} from './frame/main-animation-frame-object';
import { get as _get, set as _set } from 'lodash';
import {
  AnimationFrame,
  CanvasTransformFrame,
  SoundAnimationFrame,
  SubSceneTransitionFrame,
  TextAnimationFrame,
} from './components/animation-frame/animation.types';
import { GeneralShape } from '../shape/shapes/general/general-shape';
import { RootShape } from '../shape/shapes/general/root/root-shape';
import { Store } from '@ngrx/store';
import { setAnimationsByStart } from './store/animation.actions';
import {
  AnimationAction,
  AnimationId,
  ConsequtiveActionByKey,
} from '../../services/animation/animation.types';

import {
  AnimationsByFrame,
  AnimationsByShape,
} from './store/animation.reducer';
import {
  currentShapeTransforms,
  originalDescriptors,
  baseShapeTransforms,
  baseSvgAttributes,
} from '../store/selector/editor.selector';
import {
  AnimationFrameObject,
  AnimationFrameRelationships,
} from './frame/animation-frame-object';
import { FunctionAnimationFrameObject } from './frame/function/function-animation-frame';
import { SoundAnimationFrameObject } from './frame/sound-animation-frame';
import {
  getCurrentColorPalette,
  getCurrentLanguage,
  getCurrentProject,
} from '../../projects/project.selector';
import { SubSceneTransitionFrameObject } from './frame/subscene-transition.frame';
import { CanvasService } from '../../services/canvas/canvas.service';
import { Ticker } from 'pixi.js';
import { InverseAnimationFrameObject } from './frame/inverse/inverse-animation-frame.object';
import { SceneTransitionFrameObject } from './frame/scene-transition.frame';
import { TextAnimationFrameObject } from './frame/text-animation-frame';
import { AudioService } from './audio.service';
import { LocalSoundAnimationFrameObject } from './frame/local-sound-animation-frame';
import { ColorIncrementController } from './frame/increment/controller/color-increment.controller';
import { Project } from '../../projects/project.interface';
import { Observable } from 'rxjs';
import { CanvasTransformFrameObject } from './frame/canvas-transform-frame';
export type ExactValue = {
  type: 'exact';
  value: any;
};

export type OriginalValue = {
  type: 'original';
};

export type TransitionValue = {
  type: 'transition';
  from: ExactValue | OriginalValue;
  to: any;
  ratio: number;
};

export type PreShapeAttributeValue =
  | ExactValue
  | OriginalValue
  | TransitionValue;

export type FrameCollection = Record<
  string,
  { id: string; start: number; end: number }
>;

export type ActualFrame = {
  id: string;
  globalId: string;
  start: number;
  end: number;
  ratio: number;
};

export interface AnimationByStart {
  id: string;
  globalId?: string;
  start: number;
  end: number;
}

export interface AnimationItemByStart extends AnimationByStart {
  animation: AnimationItem;
}

export interface AnimationShapeAction {
  IRI: string;
  descriptor: {
    [attributeKey: string]: PreShapeAttributeValue;
  };
}

@Injectable()
export class AnimationService {
  originalDescriptors: Record<ShapeIRI, GeneralShapeDescriptor>;
  baseShapeTransforms: Record<ShapeIRI, ShapeTransform> = {};
  currentShapeTransforms: Record<ShapeIRI, ShapeTransform>;
  animationsByFrame: AnimationsByFrame = {};
  animationsByShape: AnimationsByShape = {};

  currentAnimationTime: number;

  framesByImportedShapeIRI: Record<string, RootAnimationFrame> = {};
  functionFrames: Record<string, FunctionAnimationFrameObject[]> = {};

  // animation-panel related
  selectedFunctionId: string;

  svgAttributes: Record<string, SVGAttributes> = {};
  colorPalette: Record<string, string>;

  openedFrames: FunctionAnimationFrameObject[] = [];

  get audioContext() {
    return this.audioService.audioContext;
  }
  get audioDestination() {
    return this.audioService.audioDestination;
  }

  get mainFrame() {
    return this.cs.previewShape?.animationFrame;
  }

  get rootShape() {
    return this.cs.previewShape;
  }
  get currentScene() {
    return this.cs.currentScene;
  }
  consequtiveActionsByShape: Record<
    string,
    Partial<Record<AnimationKeys, AnimationItemByStart[]>>
  > = {};

  consequtiveActionMap: Record<
    ShapeIRI,
    Partial<Record<AnimationKeys, Record<AnimationId, number>>>
  > = {};

  currentProject: Project;

  currentLanguage$: Observable<string>;
  currentLanguage: string;
  constructor(
    readonly store: Store,
    private readonly cs: CanvasService,
    public readonly audioService: AudioService,
  ) {
    this.store.select(originalDescriptors).subscribe(originalDescriptors => {
      this.originalDescriptors = originalDescriptors;
    });

    this.store.select(baseShapeTransforms).subscribe(shapeTransforms => {
      this.baseShapeTransforms = shapeTransforms;
    });

    this.currentLanguage$ = this.store.select(getCurrentLanguage);
    this.currentLanguage$.subscribe(lang => {
      this.currentLanguage = lang;
    });

    this.store
      .select(currentShapeTransforms)
      .subscribe(currentShapeTransforms => {
        this.currentShapeTransforms = currentShapeTransforms;
      });

    this.store.select(baseSvgAttributes).subscribe(svgAttributes => {
      this.svgAttributes = svgAttributes || {};
    });

    this.store.select(getCurrentColorPalette).subscribe(colorPalette => {
      this.colorPalette = colorPalette || {};
    });

    this.store.select(getCurrentProject).subscribe(currentProject => {
      this.currentProject = currentProject;
    });

    // this.cs.keyEventSubscribe('Shift+x', async () => { //
    //   await this.playAnimations(); // .. // .. //
    // }); //

    this.cs.keyEventSubscribe('Space', async () => {
      switch (this.animationState) {
        case 'idle':
          await this.playAnimations();
          break;
        case 'paused':
          this.restartAnimation();
          break;
        case 'started':
          this.pauseAnimations();
          break;
      }
    });

    this.cs.keyEventSubscribe('b+m', async () => {
      // -- // -- //
      this.mainFrame.frame.backgrounMusic = 'test';
      this.mainFrame.save();
      // -- //
    });

    this.cs.keyEventSubscribe('g+r', async () => {
      await this.cs.goToRecord();
    });
    // this.currentShapeTransforms. //
    // this.startTicker(); // -- //

    this.cs.generalEventSubscribe('play-animations', () =>
      this.playAnimations(),
    );

    this.cs.keyEventSubscribe('s+f', () => {
      if (this.selectedFrame) {
        this.cs.consumeKeyEvent('f');
        this.selectedFrame.frame.soundAnimationFrame = {
          id: 'sound' + Math.random().toString(),
          duration: 1,
          paralell: {
            id: 'sound' + Math.random().toString(),
            duration: 2,
          },
        };
        this.selectedFrame.save();
      }
    });
  }

  animationFrameStore: Record<string, RootAnimationFrame> = {};
  frameByShape: Record<string, MainAnimationFrameObject>;
  animationsByStart: AnimationByStart[];

  lastAnimationFrame: AnimationFrameObject;
  currentAnimationFrame: AnimationFrameObject;

  selectedFrame: AnimationFrameObject;

  ticker: Ticker;
  sum = 0;
  cnt = 0;

  delta = 0;
  frameCounter = 0;

  time: number;
  timeCnt = 0;
  animationFrames: Record<string, (increment: number) => void> = {};

  resolveColor(key: string) {
    if (key?.startsWith?.('<')) {
      const formula = key.slice(1);

      if (formula.startsWith('random')) {
        const [c1, c2, c3] = formula
          .slice(7)
          .split(',')
          .map(v => v.trim());
        // console.log('random-color', c1, c2, c3.slice(0, 7));
        // const color = ColorIncrementController.getColor(c1, c2.trim(), Math.random())
        // console.log('color', color);
        const num = Math.random();

        if (num < 0.333) {
          return c1;
        }
        if (num < 0.666) {
          return c2;
        }
        return c3.slice(0, 7);
      }

      return key;
    }

    if (!key?.startsWith?.('$')) {
      return key;
    }
    const colorPalette = this.currentProject.colorPalette;
    if (!colorPalette) {
      console.warn('color-palette-could not be found');
    }
    return colorPalette?.[key.slice(2)];
  }

  getAnimationsTillTime(
    time: number,
    animationsByStart: AnimationItemByStart[],
  ) {
    let ratio = 1;
    const animations = (animationsByStart || [])
      .filter(({ start, end }) => {
        if (end <= time) {
          return true;
        }
        if (time <= start) {
          return false;
        }

        ratio = (time - start) / (end - start);
        return true;
      })
      .map(({ animation }) => animation);

    return { animations, ratio };
  }

  getShowHideByTillTime(
    time: number,
    animationsByStart: AnimationItemByStart[],
  ): number {
    // -- // -- // const { animations, ratio } = this.getAnimationsTillTime(time, animations); // -- // -- //
    const { animations, ratio } = this.getAnimationsTillTime(
      time,
      animationsByStart,
    );
    if (!animations.length) {
      return;
    }

    const last = animations[animations.length - 1];
    if ((last as ShowHideAnimation).value) {
      return ratio;
    } else {
      return 1 - ratio;
    }
  }

  getTransformTillTime(
    time: number,
    shapeIRI: string,
    originalValue: Point | Rotation,
    key: keyof ShapeTransform = 'translate',
  ) {
    const { animations, ratio } = this.getAnimationsTillTime(
      time,
      this.consequtiveActionsByShape[shapeIRI]?.[key],
    );

    switch (key) {
      case 'translate':
      case 'scale':
        let { x: cx, y: cy } = originalValue as Point;

        let currentRatio = 1;
        for (let i = 0; i < animations.length; i++) {
          const { value } = animations[i];
          const { x, y, relative } = value as Point;

          if (ratio !== 1 && i == animations.length - 1) {
            currentRatio = ratio;
          }
          if (relative) {
            if (key == 'translate') {
              cx += currentRatio * x;
              cy += currentRatio * y;
            } else {
              cx *= 1 + currentRatio * (x - 1);
              cy *= 1 + currentRatio * (y - 1);
            }
          } else {
            if (currentRatio !== 1) {
              cx = cx + (x - cx) * ratio;
              cy = cy + (y - cy) * ratio;
            } else {
              cx = x;
              cy = y;
            }
          }
        }
        // -- // -- //
        return { x: cx, y: cy };
    }
  }

  getSVGAttributeTillTime(
    time: number,
    shapeIRI: string,
    originalValue: FillConfig | StrokeConfig,
    key: 'fill' | 'stroke',
  ) {
    const { animations, ratio } = this.getAnimationsTillTime(
      time,
      this.consequtiveActionsByShape[shapeIRI]?.[key],
    );

    if (!animations.length) {
      return originalValue;
    }

    const lastAnimation = animations[animations.length - 1];

    if (ratio == 1) {
      return lastAnimation.value;
    }

    const lastButOneAnimation = animations[animations.length - 2];

    switch (key) {
      case 'fill':
        const { color: prevFillColor } = (lastButOneAnimation?.value ||
          originalValue) as FillConfig;
        const { color: targetFillColor } = lastAnimation.value as FillConfig;
        return {
          color: ColorIncrementController.getColor(
            this.resolveColor(prevFillColor),
            this.resolveColor(targetFillColor),
            ratio,
          ),
        };
      case 'stroke':
        // -- // -- //
        const { color: prevStrokeColor, width: prevStrokeWidth } =
          (lastButOneAnimation?.value || originalValue) as StrokeConfig;
        const { color: targetStrokeColor, width: targetStrokeWidth } =
          lastAnimation.value as StrokeConfig;
        return {
          color: ColorIncrementController.getColor(
            this.resolveColor(prevStrokeColor),
            this.resolveColor(targetStrokeColor),
            ratio,
          ),
          width:
            (prevStrokeWidth || 1) +
            ((targetStrokeWidth || 1) - (prevStrokeWidth || 1)) * ratio,
        };
    }
  }

  getStrokeTillTime(
    time: number,
    shapeIRI: string,
    originalValue: StrokeConfig,
  ): StrokeConfig {
    const { animations, ratio } = this.getAnimationsTillTime(
      time,
      this.consequtiveActionsByShape[shapeIRI]?.stroke,
    );
    // -- //

    const lastAnimation = animations[animations.length - 1];
    if (!lastAnimation) {
      return originalValue;
    }

    const { color, width, dash } = originalValue || {};
    const { color: c, width: w, dash: d } = lastAnimation.value as StrokeConfig;

    return {
      color: ColorIncrementController.getColor(color, c, ratio),
      width: width + (w - width) * ratio,
      dash,
    };
  }
  getAttributeTillTime() {
    // -- //
  }
  resetBaseState() {
    this.animationState = 'idle';
    this.timeCnt = 0;
    this.mainFrame.deselectAll();
    this.animationFrames = {};
    console.log('animation-service > resetBaseState');
  }

  getAbsolutePositionTillStartOfAnimation(
    animationId: string,
    shapeIRI: string,
    originalValue: Point,
  ) {
    const { _start } = this.getFrameById(animationId);
    const { x, y } = this.getTransformTillTime(_start, shapeIRI, originalValue);
    return { x, y };
  }

  getAbsolutePositionTillEndOfAnimation(
    animationId: string,
    shapeIRI: string,
    originalValue: Point,
  ) {
    const { _end } = this.getFrameById(animationId);
    const { x, y } = this.getTransformTillTime(_end, shapeIRI, originalValue);
    return { x, y };
  }
  getRelativeTranslateValue(
    animationId: string,
    shapeIRI: string,
    originalValue: Point,
  ) {
    // -- // -- //
    const { x: sx, y: sy } = this.getAbsolutePositionTillStartOfAnimation(
      animationId,
      shapeIRI,
      originalValue,
    );
    const { x: ex, y: ey } = this.getAbsolutePositionTillEndOfAnimation(
      animationId,
      shapeIRI,
      originalValue,
    );
    return { x: ex - sx, y: ey - sy };
  }
  animationState: 'idle' | 'started' | 'paused' = 'idle';
  get isAnimationStopped() {
    return this.animationState == 'paused';
  }

  start: number;
  startTicker() {
    if (!this.cs.app) {
      return;
    }
    this.start = performance.now();
    this.frameCounter = 0;
    this.ticker = this.cs.app.ticker.add(this.tickerHandler, this);
  }

  currentTime = 0;

  get currentTimeInSec() {
    return this.timeCnt / 60;
  }

  get currentTimeTexts() {
    const sec = Math.floor(this.currentTimeInSec);
    const min = Math.floor(sec / 60);
    const convert = (num: number) =>
      num < 10 ? `0${Math.floor(num)}` : Math.floor(num);
    const convert100 = (num: number) => {
      if (num > 999) {
        return num.toString().slice(0, 3);
      }
      if (num > 99) {
        return num;
      }
      if (num > 10) {
        return `0${num}`;
      }
      return `00${num}`;
    };
    const milisec = Math.floor((this.timeCnt - 60 * sec) * 16.666667);
    return {
      min: `${convert(min)}`,
      sec: `${convert(sec)}`,
      milisec: `${convert100(milisec)}`,
    };
  }

  tickerHandler(delta: number) {
    this.frameCounter += 1;
    this.timeCnt += delta;
    this.delta += delta;

    this.currentTime = this.delta / 60;

    // const timeCount = Math.floor(this.timeCnt);
    // if (timeCount % 60 == 0 || timeCount % 60 == 1) {
    //   console.log(
    //     'current-time',
    //     timeCount,
    //     (performance.now() - this.start) / 1_000,
    //   );
    // }

    const _delta = Math.round(this.delta);

    if (this.frameCounter == 2) {
      Object.values(this.animationFrames).map(fcn => fcn(_delta));
      this.delta = 0;
      this.frameCounter = 0;
    }
  }

  pauseAnimations() {
    this.animationState = 'paused';
    this.audioService.pauseBackgroundMusic();
    // console.log('remove'); //
    this.cs.generalEventEmit('animation-paused');
    this.removeTicker();
  }

  removeTicker() {
    this.ticker.remove(this.tickerHandler, this);
  }

  restartAnimation() {
    console.log('restart-animation');
    this.cs.generalEventEmit('animation-restarted');
    this.animationState = 'started';
    this.audioService.restartBackgroundMusic();
    this.addTicker();
  }

  addTicker() {
    this.ticker = this.cs.app.ticker.add(this.tickerHandler, this);
  }

  stopAnimation() {
    this.animationFrames = {};
  }

  async playAnimations() {
    this.animationState = 'started';
    await this.audioService.waitForAudioFiles();
    this.startTicker();
    // this.audioService.startBackgroundMusic();
    console.log('playAnimations', this.selectedFrame);
    this.cs.previewShape.__setPreAnimationState();

    if (this.selectedFrame) {
      if (this.selectedFrame.next) {
        await this.selectedFrame.next.animate();
      } else {
        console.warn('There is nothing to animate!');
      }
    } else {
      await this.mainFrame.animate();
    }

    console.log('lastAnimationFrame', this.lastAnimationFrame);
    this.lastAnimationFrame?.select();
    this.removeTicker();
    this.animationState = 'idle';
    // this.audioService.stopBackgroundMusic();
  }

  setPreAnimationState() {
    const hiddenShapes = {};

    for (const animation of this.animationsByStart || []) {
      // -- // -- //
      const { id } = animation;
      Object.entries(this.animationsByFrame[id] || {})
        .filter(([shapeIRI]) => !hiddenShapes[shapeIRI])
        .map(([shapeIRI, animations]) => {
          if (animations.appear) {
            hiddenShapes[shapeIRI] = true;
          }
          if ((animations['show-hide']?.value as ShowHideAnimation)?.value) {
            hiddenShapes[shapeIRI] = true;
          }
          if (animations['path']?.value as PathAnimation) {
            hiddenShapes[shapeIRI] = true;
          }
        });
    }

    return Object.keys(hiddenShapes);
  }

  init() {
    this.selectedFrame = null;
    this.selectedFunctionId = null;
    if (!this.mainFrame) {
      return;
    }
    this.store.dispatch(setAnimationsByStart({ value: [] }));
    this.animationsByStart = this.mainFrame.getAnimationsByStart() || [];
  }

  getRootAnimationId(id: string) {
    const ids = id.split('_');
    return ids[ids.length - 1];
  }

  setAnimationItem(
    shapeIRIs: string[],
    item: AnimationItem,
    animationId: string,
  ) {
    // -- // -- //
    const currentFrame = this.getFrameById(animationId);
    const { _end } = currentFrame;

    shapeIRIs.map(IRI => {
      _set(this.animationsByShape, [IRI, animationId, item.key], item);
      _set(this.animationsByFrame, [animationId, IRI, item.key], item);
      this.setConsequtiveAction(IRI, item.key, animationId, item);
    });
    const updates = {};
    shapeIRIs.map(IRI => {
      updates[IRI] = {
        [item.key]: this.getBulkUpdateValue(IRI, item.key, _end),
      };
    });

    return updates;
  }

  setAnimationItemSubvalue(
    shapeIRIs: string[],
    key: AnimationKeys,
    innerKey: AnimationInnerKey,
    value: PrimitiveValue,
    animationId: string,
  ): { animations: AnimationBatch; bulkUpdates: any } {
    // -- // -- // -- //
    let bulkUpdates = {};
    const animations: AnimationBatch = [];
    shapeIRIs.map(IRI => {
      const currentAnimationItem =
        this.animationsByShape[IRI][animationId][key] ||
        ({
          key,
          value: {
            [innerKey]: value,
          },
        } as AnimationItem);

      let newItem;

      if (key == 'translate' && innerKey == 'relative') {
        if (value == true) {
          // -- // -- // -- //
          const { x, y } = this.getRelativeTranslateValue(
            animationId,
            IRI,
            this.baseShapeTransforms[IRI].translate,
          );

          animations.push({
            IRI,
            animationsById: {
              [animationId]: [
                {
                  key,
                  value: {
                    x,
                    y,
                    relative: true,
                  },
                },
              ],
            },
          });

          this.setAnimationItem(
            [IRI],
            {
              ...currentAnimationItem,
              value: {
                x,
                y,
                relative: true,
              },
            },
            animationId,
          );
        } else {
          const item = {
            ...currentAnimationItem,
            value: {
              ...this.currentShapeTransforms[IRI].translate,
              relative: false,
            },
          };

          this.setAnimationItem([IRI], item, animationId);

          animations.push({
            IRI,
            animationsById: {
              [animationId]: [item],
            },
          });
        }
      } else {
        const item = {
          ...currentAnimationItem,
          value: {
            ...(currentAnimationItem.value as Record<string, PrimitiveValue>),
            [innerKey]: value,
          },
        };

        // -- // -- // -- //

        newItem = this.setAnimationItem([IRI], item, animationId);

        animations.push({
          IRI,
          animationsById: {
            [animationId]: [item],
          },
        });
      }

      bulkUpdates = {
        ...bulkUpdates,
        ...(newItem || {}),
      };
    });

    this.setAnimationStore(animations);

    return { animations, bulkUpdates };
  }

  setAnimationStore(
    animations: Array<{
      IRI: string;
      animationsById: Record<string, __Animation>;
    }>,
  ) {
    animations.map(({ IRI, animationsById }) => {
      Object.entries(animationsById || {}).map(
        ([animationId, animationArray]) => {
          animationArray.map(item => {
            _set(this.animationsByFrame, [animationId, IRI, item.key], item);
            _set(this.animationsByShape, [IRI, animationId, item.key], item);
          });
        },
      );
    });
  }

  removeAnimationItem(key: AnimationKeys, IRIs: string[], animationId: string) {
    IRIs.map(IRI => {
      this.deleteFromConsequtiveActions(IRI, key, animationId);
    });

    const updates = {};
    IRIs.map(IRI => {
      // this.deleteFromStore(IRI, animationId, key);
      switch (key) {
        case 'translate':
          updates[IRI] = {
            translate: this.getAbsolutePositionTillEndOfAnimation(
              animationId,
              IRI,
              this.baseShapeTransforms[IRI].translate,
            ),
          };
          break;
      }
    });

    return updates;
  }

  getBulkUpdateValue(shapeIRI: string, key: AnimationKeys, time: number) {
    // -- // -- // -- //
    switch (key) {
      case 'translate':
      case 'scale':
        return this.getTransformTillTime(
          time,
          shapeIRI,
          this.baseShapeTransforms[shapeIRI][key],
          key,
        );
    }
  }

  setConsequtiveActionsByShape(
    shapeIRI: string,
    consequtiveActions: ConsequtiveActionByKey,
  ) {
    this.consequtiveActionsByShape[shapeIRI] = consequtiveActions;
    this.consequtiveActionMap[shapeIRI] = {};
    Object.keys(consequtiveActions).map(animationKey =>
      this.updateConsequtiveActionMap(shapeIRI, animationKey as AnimationKeys),
    );
  }

  setConsequtiveAction(
    shapeIRI: string,
    animationKey: AnimationKeys,
    animationId: string,
    animation: AnimationItem,
    globalId?: string,
  ) {
    const currentFrame = this.getFrameById(animationId);

    if (!currentFrame) {
      throw new Error(
        `AnimationService.addAdnimation - no frame was found with id: ${animationId}`,
      );
    }

    if (animationId.includes('_')) {
      globalId = animationId;
      animationId = this.getRootAnimationId(animationId);
    }

    const { _start, _end } = currentFrame;
    this.consequtiveActionsByShape[shapeIRI] ||= {};
    this.consequtiveActionMap[shapeIRI] ||= {};
    this.consequtiveActionsByShape[shapeIRI][animationKey] ||= [];

    const index =
      this.consequtiveActionMap[shapeIRI][animationKey]?.[animationId];
    if (index !== undefined) {
      // This is the update case
      this.consequtiveActionsByShape[shapeIRI][animationKey][index].animation =
        animation;
      return;
    }

    const prevs = this.consequtiveActionsByShape[shapeIRI][animationKey].filter(
      ({ end }) => end <= _start,
    );
    const posts = this.consequtiveActionsByShape[shapeIRI][animationKey].filter(
      ({ start }) => _end <= start,
    );

    this.consequtiveActionsByShape[shapeIRI][animationKey] = [
      ...prevs,
      {
        id: animationId,
        globalId,
        start: _start,
        end: _end,
        animation,
      },
      ...posts,
    ];

    this.updateConsequtiveActionMap(shapeIRI, animationKey);
  }

  getFrameById(animationId: string) {
    // return this.mainFrame.getFrameById(animationId);
    return this.mainFrame.store[animationId];
  }

  deleteFromConsequtiveActions(
    shapeIRI: string,
    animationKey: AnimationKeys,
    animationId: string,
  ) {
    const index =
      this.consequtiveActionMap[shapeIRI][animationKey][animationId];
    if (index == undefined) {
      throw new Error(`Consequtive animation map has not been found!`);
    }

    this.consequtiveActionsByShape[shapeIRI][animationKey].splice(index, 1);
    this.updateConsequtiveActionMap(shapeIRI, animationKey);
  }

  updateConsequtiveActionMap(shapeIRI: string, animationKey: AnimationKeys) {
    this.consequtiveActionMap[shapeIRI][animationKey] = {};
    this.consequtiveActionsByShape[shapeIRI][animationKey].map(
      ({ id, globalId }, index) => {
        this.consequtiveActionMap[shapeIRI][animationKey][globalId || id] =
          index;
      },
    );
  }

  resetFrame(
    animationFrame: AnimationFrame,
    shape?: GeneralShape,
    main = false,
  ) {}

  getCurrentTranslateAnimation(shapeIRI: ShapeIRI, animationId: string) {
    return _get(this.animationsByShape, [shapeIRI, animationId, 'translate']);
  }

  getCurrentAnimation(
    animationsByShape: AnimationsByShape,
    shapeIRI: ShapeIRI,
    currentAnimationId: string,
    key: AnimationKeys,
  ) {
    return _get(animationsByShape, [shapeIRI, currentAnimationId, key]);
  }

  getShapeAttributeValue(
    key: string,
    actions: AnimationAction[],
    originalValue?: any,
  ) {
    if (!actions.length) {
      console.log('original-value', originalValue);
      return originalValue;
    }
    // console.log({ key, actions: JSON.stringify(actions, null, 2), originalValue }); //
    if (!originalValue) {
      return {};
    }

    let { x, y, relative: rel } = originalValue as Point;

    // if I get the initial value in relative -> I shall return in relative
    let [prevX, prevY] = [x, y];
    switch (key) {
      case 'rotation':
        return actions[actions.length - 1].value as Rotation;
      case 'scale':
        let [ox, oy] = [0, 0];
        for (const action of actions) {
          const { value, ratio } = action;
          const { x: _x, y: _y, relative, center } = value as Scale;
          if (relative) {
            x *= _x * ratio;
            y *= _y * ratio;
          } else {
            if (ratio !== 1) {
              // it would work for the other branch as well
              x = x + (_x - x) * ratio;
              y = y + (_y - y) * ratio;
            } else {
              x = _x;
              y = _y;
            }
          }

          if (center) {
            ox = (x - prevX) / 2;
            oy = (y - prevY) / 2;
          }

          prevX = x;
          prevY = x;
        }
        // console.log('get-shape-attr-value > scale', { x, y });
        return {
          x,
          y,
          offset: ox || oy ? [ox, oy] : undefined,
          relative: rel,
        };

      case 'translate':
        for (const action of actions) {
          const { value, ratio } = action;
          const {
            x: _x,
            y: _y,
            relative,
          } = value as Point & { relative: boolean };
          if (relative) {
            x += _x * ratio;
            y += _y * ratio;
          } else {
            if (ratio !== 1) {
              // it would work for the other branch as well
              x = x + (_x - x) * ratio;
              y = y + (_y - y) * ratio;
            } else {
              x = _x;
              y = _y;
            }
          }
        }
        return {
          x,
          y,
        };

      case 'show-hide':
        const { value: show } = actions[actions.length - 1]
          .value as ShowHideAnimation;
        return show;

      case 'fill':
        const fill = actions[actions.length - 1].value as string;
        return fill.startsWith('$')
          ? this.colorPalette[fill.substring(2)]
          : fill;
      case 'stroke':
        const stroke = actions[actions.length - 1].value as StrokeConfig;
        return {
          color: this.colorPalette[stroke.color.substring(2)] || stroke.color,
          width: stroke.width,
        };
      // case 'path':
      default:
        const pathAnimation = actions[actions.length - 1].value;
        return pathAnimation;
    }

    return;
    const last = actions.pop();
    if (!last) {
      return;
    }
    let { ratio, value } = last;
    const relative = true;
    if (key == 'position') {
      // -- //
      let dx = 0;
      let dy = 0;
      let X: number, Y: number;
      let RATIO = 0;
      let { x, y } = originalValue as ShapePosition;

      while (1) {
        if (relative) {
          dx += (value as ShapePosition).x * ratio;
          dy += (value as ShapePosition).y * ratio;
        } else {
          const position = value as ShapePosition;

          if (ratio == 1) {
            x = position.x;
            y = position.y;
            break;
          }

          X = position.x;
          Y = position.y;
          RATIO = ratio;
        }
        const next = actions.pop();
        if (!next) {
          break;
        }
        ratio = next.ratio;
        // relative = next.relative;
        value = next.value;
      }

      if (X !== undefined) {
        const [lastAbsX, lastAbsY] = [x + dx, y + dy];
        return {
          x: lastAbsX + (X - lastAbsX) * RATIO,
          y: lastAbsY + (Y - lastAbsY) * RATIO,
        };
      } else {
        return {
          x: x + dx,
          y: y + dy,
        };
      }
    } else {
      if (last.ratio == 1) {
        return value;
      } else {
        const numberKeys = {
          opacity: true,
          'fill-opacity': true,
          'stroke-opacity': true,
        };

        const lastButOne = actions.pop();

        if (lastButOne) {
        } else {
        }

        return {
          type: 'transition',
          ratio,
          from: lastButOne
            ? {
                type: 'exact',
                value: lastButOne.value,
              }
            : {
                type: 'original',
              },
          to: {
            type: 'exact',
            value,
          },
        };
      }
    }
  }

  getActualFramesInOrder(frames: FrameCollection, time: number): ActualFrame[] {
    return Object.entries(frames)
      .filter(([, { start }]) => start < time)
      .map(([globalId, { id, start, end }]) => ({
        globalId,
        id,
        start,
        end,
        ratio: end <= time ? 1 : (time - start) / (end - start),
      }))
      .sort(({ start: s1 }, { start: s2 }) => s1 - s2);
  }

  getAnimationsByTillTime(
    animationsByStart: AnimationByStart[],
    time: number,
  ): Array<{
    animation: AnimationByStart;
    ratio: number;
  }> {
    // -- //
    const ratio = undefined;
    const animations = [];
    for (const animation of animationsByStart || []) {
      const { id, start: startsAt, end: endsAt } = animation;
      if (time <= animation.start) {
        break;
      }
      let ratio = 1;
      if (time < animation.end) {
        ratio = (endsAt - time) / (endsAt - startsAt);
      }

      animations.push({ animation, ratio });
    }

    return animations;
  }

  newFrameObject<T = AnimationFrameObject>(
    frame: AnimationFrame,
    relationships: AnimationFrameRelationships,
  ): T {
    console.log('new-frame-object', frame);
    frame ||= {};
    frame.id ||= Math.random().toString();
    frame.duration = isNaN(frame.duration) ? 1 : frame.duration;

    if (this.cs.isSpacePressed && relationships.prev) {
      frame.duration = relationships.prev.duration;
    }

    const newFrame = this.initFrameObject(frame, relationships);
    newFrame.select();
    // this.mainFrame.save();
    return newFrame as T;
  }

  initFrameObject(
    frame: AnimationFrame,
    relationships: AnimationFrameRelationships = {},
  ) {
    let frameObject: AnimationFrameObject;

    switch (frame.type) {
      case 'text':
        frameObject = new TextAnimationFrameObject(
          frame as TextAnimationFrame,
          relationships,
          this.audioContext,
          this.audioDestination,
        );
        break;
      case 'local-sound':
        // console.log('init-local-sound');
        frameObject = new LocalSoundAnimationFrameObject(
          frame as TextAnimationFrame,
          relationships,
          this.audioContext,
          this.audioDestination,
        );
        break;
      case 'canvas-transform':
        frameObject = new CanvasTransformFrameObject(
          frame as CanvasTransformFrame,
          relationships,
        );
        break;
      default:
        if ((frame as SoundAnimationFrame).soundFileUrl) {
          frameObject = new SoundAnimationFrameObject(
            frame,
            relationships,
            this.audioContext,
            this.audioDestination,
          );
        } else if (frame.function) {
          frameObject = new FunctionAnimationFrameObject(frame, relationships);
        } else if ((frame as SubSceneTransitionFrame).targetSubScene) {
          console.log('---- subscene-transition -----', frame);
          frameObject = new SubSceneTransitionFrameObject(
            frame as SubSceneTransitionFrame,
            relationships,
          );
        } else if (frame.inverse) {
          frameObject = new InverseAnimationFrameObject(frame, relationships);
          // -- // -- //
        } else if (frame.stateTransition) {
          frameObject = new SceneTransitionFrameObject(frame, relationships);
        } else {
          frameObject = new AnimationFrameObject(frame, relationships);
        }
    }

    // if (parent) {
    //   parent.child = frameObject;
    // }

    // if (prev) {
    //   prev.next = frameObject;
    // }

    frameObject.init();
    return frameObject;
  }

  currentFrame: MainAnimationFrameObject;

  addFirstFrame() {
    // -- //
    this.currentFrame = new RootAnimationFrame(this.rootShape, {
      id: Math.random().toString(),
      duration: 1,
    });

    this.rootShape.animationFrame = this.currentFrame as RootAnimationFrame;
    this.currentFrame.save();
  }

  getFunctionAnimationFrame(
    frame: AnimationFrame,
    relationships: AnimationFrameRelationships,
  ) {
    const frameObject = new FunctionAnimationFrameObject(frame, relationships);
    frameObject.init();
    return frameObject;
  }
}
