export const angleLookup = {
  '-1.-1|-1.-1': 0.0,
  '-1.-1|-1.0': -0.7853981633974483,
  '-1.-1|-1.1': -1.5707963267948966,
  '-1.-1|0.-1': 0.7853981633974483,
  '-1.-1|0.1': -2.356194490192345,
  '-1.-1|1.-1': 1.5707963267948966,
  '-1.-1|1.0': 2.356194490192345,
  '-1.-1|1.1': 3.141592653589793,
  '-1.0|-1.-1': 0.7853981633974483,
  '-1.0|-1.0': 0.0,
  '-1.0|-1.1': -0.7853981633974483,
  '-1.0|0.-1': 1.5707963267948966,
  '-1.0|0.1': -1.5707963267948966,
  '-1.0|1.-1': 2.356194490192345,
  '-1.0|1.0': -3.141592653589793,
  '-1.0|1.1': -2.356194490192345,
  '-1.1|-1.-1': 1.5707963267948966,
  '-1.1|-1.0': 0.7853981633974483,
  '-1.1|-1.1': 0.0,
  '-1.1|0.-1': 2.356194490192345,
  '-1.1|0.1': -0.7853981633974483,
  '-1.1|1.-1': -3.141592653589793,
  '-1.1|1.0': -2.356194490192345,
  '-1.1|1.1': -1.5707963267948966,
  '0.-1|-1.-1': -0.7853981633974483,
  '0.-1|-1.0': -1.5707963267948966,
  '0.-1|-1.1': -2.356194490192345,
  '0.-1|0.-1': 0.0,
  '0.-1|0.1': 3.141592653589793,
  '0.-1|1.-1': 0.7853981633974483,
  '0.-1|1.0': 1.5707963267948966,
  '0.-1|1.1': 2.356194490192345,
  '0.1|-1.-1': 2.356194490192345,
  '0.1|-1.0': 1.5707963267948966,
  '0.1|-1.1': 0.7853981633974483,
  '0.1|0.-1': -3.141592653589793,
  '0.1|0.1': 0.0,
  '0.1|1.-1': -2.356194490192345,
  '0.1|1.0': -1.5707963267948966,
  '0.1|1.1': -0.7853981633974483,
  '1.-1|-1.-1': -1.5707963267948966,
  '1.-1|-1.0': -2.356194490192345,
  '1.-1|-1.1': 3.141592653589793,
  '1.-1|0.-1': -0.7853981633974483,
  '1.-1|0.1': 2.356194490192345,
  '1.-1|1.-1': 0.0,
  '1.-1|1.0': 0.7853981633974483,
  '1.-1|1.1': 1.5707963267948966,
  '1.0|-1.-1': -2.356194490192345,
  '1.0|-1.0': 3.141592653589793,
  '1.0|-1.1': 2.356194490192345,
  '1.0|0.-1': -1.5707963267948966,
  '1.0|0.1': 1.5707963267948966,
  '1.0|1.-1': -0.7853981633974483,
  '1.0|1.0': 0.0,
  '1.0|1.1': 0.7853981633974483,
  '1.1|-1.-1': -3.141592653589793,
  '1.1|-1.0': 2.356194490192345,
  '1.1|-1.1': 1.5707963267948966,
  '1.1|0.-1': -2.356194490192345,
  '1.1|0.1': 0.7853981633974483,
  '1.1|1.-1': -1.5707963267948966,
  '1.1|1.0': -0.7853981633974483,
  '1.1|1.1': 0.0,
};
