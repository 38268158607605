export class BufferLoader {
  constructor(
    private readonly context: AudioContext,
    private readonly url: string,
    private readonly callBack: (buffer: AudioBuffer) => void,
  ) {}

  load() {
    this.loadBuffer(this.url);
  }

  loadBuffer(url: string) {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'arraybuffer';
    request.onload = () => {
      this.context.decodeAudioData(request.response, buffer => {
        if (!buffer) {
          alert('error decoding file data: ' + url);
          return;
        }
        this.callBack(buffer);
      });
    };
    request.onerror = function () {
      alert('BufferLoader: XHR error');
    };
    request.send();
  }

  audioBuffer: ArrayBuffer;
}
