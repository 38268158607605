import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../../../services/canvas/canvas.service';
import { StoreService } from '../../../../store/store.service';
import {
  MainAnimationFrameObject,
  RootAnimationFrame,
} from '../../frame/main-animation-frame-object';
import { AnimationService } from '../../animation.service';
import { Observable } from 'rxjs';
import { currentAnimationId } from '../../store/animation.selector';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ShapeService } from '../../../shape/shape.service';

@Component({
  selector: 'nw-animation-panel',
  templateUrl: './animation-panel.component.html',
  styleUrls: ['./animation-panel.component.scss'],
})
export class AnimationPanelComponent implements OnInit {
  isFrameSelected$: Observable<boolean>;

  get currentTimePosition() {
    return this.animationService.currentTimeInSec * 32;
  }

  get currentTimeTexts() {
    return this.animationService.currentTimeTexts;
  }

  get mainFrame() {
    return this.animationService.mainFrame;
  }
  get height() {
    let height = this.mainFrame.height;
    if (this.mainFrame.soundRootFrame) {
      height += this.mainFrame.soundRootFrame.height;
    }
    return height * 16 + 8;
  }

  closed = false;

  constructor(
    public readonly cs: CanvasService,
    public readonly service: ShapeService,
    public readonly animationService: AnimationService,
    private readonly db: StoreService,
    private readonly store: Store,
  ) {
    this.cs.keyEventSubscribe('m', () => (this.closed = !this.closed));

    this.isFrameSelected$ = this.store
      .select(currentAnimationId)
      .pipe(map(currentAnimationId => !!currentAnimationId));
  }

  ngOnInit(): void {}

  _frame: MainAnimationFrameObject;
  get frame(): RootAnimationFrame {
    return this.cs.activeFrame;
  }
  _opened = false;
  get opened() {
    return this.db.get('animationOpened');
  }

  addFirstValue: number;

  addFirst() {
    this.cs.addFirstFrame();
  }

  open() {
    this.db.set('animationOpened', true);
  }

  close() {
    this.db.set('animationOpened', false);
  }
}
