import { createSelector } from '@ngrx/store';
import { DataBag } from '../store/reducer';
import { UserFeature } from './user.actions';
import { User } from './user.interface';
import { UserState } from './user.reducer';

export const selectUserFeature = (state: DataBag) => state[UserFeature];

export const userList = createSelector(
  selectUserFeature,
  ({ allUsers }): User[] => Object.values(allUsers),
);

export const userListLoading = createSelector(
  selectUserFeature,
  ({ listLoading }): boolean => listLoading,
);

export const newUserIsLoading = createSelector(
  selectUserFeature,
  ({ newUserIsLoading }): boolean => newUserIsLoading,
);

export const selectUserBeingEdited = createSelector(
  selectUserFeature,
  ({ userBeingEdited }) => userBeingEdited,
);

export const selectUserBeingDeleted = createSelector(
  selectUserFeature,
  ({ userBeingDeleted }) => userBeingDeleted,
);

export const userById = (id: string) =>
  createSelector(
    selectUserFeature,
    (state: UserState): User => state.allUsers[id],
  );

export const myUserId = createSelector(selectUserFeature, ({ myId }) => myId);
