<div
  class="scale-animation-component"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Scale"
    [noDelete]="true"
    [attributeState]="attributeState$ | async"
    key="scale"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4"
  >
    <div class="flex-row space-between gap-16">
      <nw-number-input
        [key]="'x'"
        [value]="attributeState.value['x']"
        (changed)="updateSubAttributeValue('x', $event)"
        [step]="0.1"
      ></nw-number-input>
      <nw-number-input
        [key]="'y'"
        [value]="attributeState.value['y']"
        (changed)="updateSubAttributeValue('y', $event)"
        [step]="0.1"
      ></nw-number-input>
    </div>
    <nw-boolean-input
      *ngIf="
        attributeState.value['relative']?.length ||
        attributeState.type == 'animation'
      "
      [key]="'relative'"
      [value]="attributeState.value['relative'] || [false]"
      (changed)="updateSubAttributeValue('relative', $event)"
    ></nw-boolean-input>
  </div>
  <!-- <div *ngSwitchCase="'function'">output</div>
    <div *ngSwitchCase="'mixed'">output</div>
    <div *ngSwitchCase="'undefined'">output</div> -->
</div>
