<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-icon class="hidden pointer i-18" matTooltip="Close"> close </mat-icon>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="12px">
    <div class="title">
      <b>Image Upload</b>
    </div>
    <mat-icon class="material-icons-outlined"> insert_photo </mat-icon>
  </div>
  <mat-icon class="pointer i-18" matTooltip="Close" (click)="close()">
    close
  </mat-icon>
</div>

<div
  *ngIf="!fileUrl"
  class="container"
  appDnd
  (fileDropped)="onFileDropped($event)"
  (hover)="
    $event ? (imageUploadState = 'dragging') : (imageUploadState = 'initial')
  "
>
  <input type="file" #fileDropRef id="fileDropRef" multiple />
  <div
    class="drop-image"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="14px"
  >
    <ng-container [ngSwitch]="imageUploadState">
      <ng-container *ngSwitchCase="'initial'">
        <div>Drop Image</div>
        <mat-icon>get_app</mat-icon>
      </ng-container>
      <mat-icon *ngSwitchCase="'dragging'" class="i-40"> get_app </mat-icon>
      <ng-container *ngSwitchCase="'in-progress'">
        <mat-spinner diameter="24"></mat-spinner>
      </ng-container>
      <ng-container *ngSwitchCase="'error-by-upload'">
        <mat-icon> sentiment_very_dissatisfied</mat-icon>
        <div>Unfortunately, something went wrong!</div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div *ngIf="fileUrl" fxLayout="column" fxLayoutGap="12px">
  <svg-file-preview
    [uuid]="fileUrl"
    (fileIsLoaded)="fileData = $event"
  ></svg-file-preview>
  <div
    fxLayout="column"
    fxLayoutGap="6px"
    *ngIf="fileData"
    class="form-container"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <b>Width</b>
      </div>
      <div class="px-container">
        <i>{{ data.width }}px</i>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <b>Height</b>
      </div>
      <div class="px-container">
        <i>{{ data.height }}px</i>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <b> Name </b>
      </div>
      <div class="input-container">
        <input type="text" [(ngModel)]="imageName" (input)="textHasChanged()" />
      </div>
    </div>
    <div class="hint-container" [ngSwitch]="nameCheckState">
      <div [style.color]="'red'" *ngSwitchCase="'at-least-three'">
        The name must be at least 3 character long!
      </div>
      <div [style.color]="'red'" *ngSwitchCase="'name-is-taken'">
        That name is already taken!
      </div>
      <div [style.color]="'blue'" *ngSwitchCase="'in-progress'">
        Name checking is in progress...
      </div>
      <div [style.color]="'green'" *ngSwitchCase="'valid-name'">
        This is a valid name!
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="8px"
      class="button-container"
    >
      <button mat-raised-button (click)="cancel()">Delete</button>
      <button mat-raised-button [disabled]="!saveIsEnabled" (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
