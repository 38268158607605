export const lookup_scale_in = {
  '1': 0.45454545454545364,
  '2': 0.2747252747252741,
  '3': 0.18628912071535036,
  '4': 0.13437970328961563,
  '5': 0.10070574586703614,
  '6': 0.07742392634692864,
  '7': 0.060609422408689564,
  '8': 0.04807946167250038,
  '9': 0.03852275688285927,
  '10': 0.031103794152168473,
  '11': 0.025264964903781287,
  '12': 0.020620001083411332,
  '13': 0.016893055222026665,
  '14': 0.013882119837510462,
  '15': 0.01143613503374462,
  '16': 0.009440146866203767,
  '17': 0.00780538573212082,
  '18': 0.006462453160357396,
  '19': 0.0053565306930427525,
  '20': 0.004443938831908269,
  '21': 0.0036896186602227527,
  '22': 0.0030652575240547653,
  '23': 0.0025478730308976906,
  '24': 0.002118728982074454,
  '25': 0.0017624956096043982,
  '26': 0.0014665922892821762,
  '27': 0.0012206683886922458,
  '28': 0.0010161899647711478,
  '29': 0.0008461084648667712,
  '30': 0.0007045935852252189,
  '31': 0.0005868167649151713,
  '32': 0.0004887749529818515,
  '33': 0.00040714662492384146,
  '34': 0.0003391737762211212,
  '35': 0.00028256494799895995,
  '36': 0.00023541535655826538,
  '37': 0.0001961409849674254,
  '38': 0.00016342410900100471,
  '39': 0.00013616821319385188,
  '40': 0.00011346063621804608,
  '41': 0.00009454159122332621,
  '42': 0.00007877845281825845,
  '43': 0.00006564440121288706,
  '44': 0.00005470067543039043,
  '45': 0.00004558181832821189,
  '46': 0.000037983405812523544,
  '47': 0.000031651836305825934,
  '48': 0.000026375834552992,
  '49': 0.000021979379025101456,
  '50': 0.000018315813710748996,
  '51': 0.000015262945131311912,
  '52': 0.000012718959169160563,
  '53': 0.000010599020300793869,
  '54': 0.000008832438904925838,
  '55': 0.000007360311579066407,
  '56': 0.000006133555361685088,
  '57': 0.0000051112700107140885,
  '58': 0.000004259373532408996,
  '59': 0.0000035494653452750863,
  '60': 0.0000029578790383091997,
  '61': 0.0000024648931227819886,
  '62': 0.000002054073383206201,
  '63': 0.000001711724889537516,
  '64': 0.0000014264353736602608,
  '65': 0.0000011886947310235043,
  '66': 9.905779611135408e-7,
  '67': 8.254809528681204e-7,
  '68': 6.879003210871061e-7,
  '69': 5.732499390659593e-7,
  '70': 4.777080541408607e-7,
  '71': 3.9808988681892495e-7,
  '72': 3.317414617867184e-7,
  '73': 2.7645114173310455e-7,
  '74': 2.303758983191162e-7,
  '75': 1.9197987836266172e-7,
  '76': 1.5998320307223013e-7,
  '77': 1.333193208920319e-7,
  '78': 1.1109942198708974e-7,
  '79': 9.258284285082787e-8,
  '80': 7.715236328073263e-8,
  '81': 6.429363182122582e-8,
  '82': 5.357802360166459e-8,
  '83': 4.4648348601872385e-8,
  '84': 3.72069592913249e-8,
  '85': 3.1005799024742896e-8,
  '86': 2.5838163810326862e-8,
  '87': 2.153180424038865e-8,
  '88': 1.7943168660022814e-8,
  '89': 1.495263511267638e-8,
  '90': 1.246053336107021e-8,
  '91': 1.0383777459736463e-8,
  '92': 8.653144174918902e-9,
  '93': 7.210956112236979e-9,
  '94': 6.009130625903674e-9,
  '95': 5.007610580391479e-9,
  '96': 4.173006997526614e-9,
  '97': 3.477505335422885e-9,
  '98': 2.897921535044167e-9,
  '99': 2.414934823456129e-9,
  '100': 2.012460610142149e-9,
  '101': 1.6770381341127275e-9,
  '102': 1.39753007016627e-9,
  '103': 1.1646093581015382e-9,
  '104': 9.705106048183105e-10,
  '105': 8.087562353959831e-10,
  '106': 6.739644715300714e-10,
  '107': 5.616344113955505e-10,
  '108': 4.680301732074252e-10,
  '109': 3.900253089742012e-10,
  '110': 3.2501847941172143e-10,
  '111': 2.7085138552448976e-10,
  '112': 2.2570904230433282e-10,
  '113': 1.8809095624733688e-10,
  '114': 1.5674343340094086e-10,
  '115': 1.3061894994585042e-10,
  '116': 1.0884977925836322e-10,
  '117': 9.070806013239101e-11,
  '118': 7.558878993614122e-11,
  '119': 6.299121346034147e-11,
  '-120': -0.20000000007558938,
  '-119': -0.20000000009070734,
  '-118': -0.20000000010884877,
  '-117': -0.2000000001306238,
  '-116': -0.2000000001567453,
  '-115': -0.20000000018809058,
  '-114': -0.20000000022570927,
  '-113': -0.2000000002708516,
  '-112': -0.20000000032502252,
  '-111': -0.20000000039002575,
  '-110': -0.20000000046804733,
  '-109': -0.20000000056163664,
  '-108': -0.2000000006739639,
  '-107': -0.20000000080875738,
  '-106': -0.20000000097050807,
  '-105': -0.2000000011646149,
  '-104': -0.20000000139753152,
  '-103': -0.20000000167704507,
  '-102': -0.2000000020124387,
  '-101': -0.20000000241493512,
  '-100': -0.20000000289792177,
  '-99': -0.2000000034775057,
  '-98': -0.20000000417300787,
  '-97': -0.20000000500760867,
  '-96': -0.2000000060091281,
  '-95': -0.2000000072109566,
  '-94': -0.20000000865314796,
  '-93': -0.20000001038377752,
  '-92': -0.20000001246054527,
  '-91': -0.20000001495264008,
  '-90': -0.20000001794316885,
  '-89': -0.2000000215318025,
  '-88': -0.2000000258381372,
  '-87': -0.20000003100579986,
  '-86': -0.2000000372069564,
  '-85': -0.20000004464835133,
  '-84': -0.20000005357812636,
  '-83': -0.20000006429363176,
  '-82': -0.2000000771523632,
  '-81': -0.2000000925828429,
  '-80': -0.20000011109942198,
  '-79': -0.20000013331932096,
  '-78': -0.20000015998320655,
  '-77': -0.20000019197987834,
  '-76': -0.20000023037589953,
  '-75': -0.20000027645114188,
  '-74': -0.2000003317414603,
  '-73': -0.2000003980898861,
  '-72': -0.20000047770804555,
  '-71': -0.20000057324994033,
  '-70': -0.20000068790032147,
  '-69': -0.2000008254809531,
  '-68': -0.2000009905779611,
  '-67': -0.20000118869473227,
  '-66': -0.20000142643537308,
  '-65': -0.2000017117249043,
  '-64': -0.20000205407338187,
  '-63': -0.2000024648931214,
  '-62': -0.20000295787903827,
  '-61': -0.2000035494653449,
  '-60': -0.20000425937353236,
  '-59': -0.20000511127000983,
  '-58': -0.20000613355535984,
  '-57': -0.2000073603115792,
  '-56': -0.2000088324389012,
  '-55': -0.20001059902029983,
  '-54': -0.20001271895916906,
  '-53': -0.2000152629451316,
  '-52': -0.20001831581370766,
  '-51': -0.20002197937902336,
  '-50': -0.20002637583455363,
  '-49': -0.20003165183630642,
  '-48': -0.2000379834058128,
  '-47': -0.20004558181832716,
  '-46': -0.20005470067535006,
  '-45': -0.20006564440121416,
  '-44': -0.2000787784528213,
  '-43': -0.2000945415912244,
  '-42': -0.20011346063621777,
  '-41': -0.2001361682130913,
  '-40': -0.20016342410900212,
  '-39': -0.20019614098496763,
  '-38': -0.20023541535656023,
  '-37': -0.200282564948001,
  '-36': -0.20033917377622107,
  '-35': -0.20040714662492587,
  '-34': -0.2004887749529812,
  '-33': -0.20058681676491427,
  '-32': -0.2007045935852248,
  '-31': -0.20084610846486686,
  '-30': -0.2010161899647716,
  '-29': -0.20122066838869357,
  '-28': -0.2014665922892768,
  '-27': -0.20176249560960377,
  '-26': -0.2021187289820531,
  '-25': -0.20254787303089747,
  '-24': -0.20306525752405516,
  '-23': -0.20368961866022556,
  '-22': -0.20444393883190964,
  '-21': -0.20535653069304285,
  '-20': -0.2064624531603571,
  '-19': -0.207805385732122,
  '-18': -0.20944014686620366,
  '-17': -0.2114361350337444,
  '-16': -0.21388211983751218,
  '-15': -0.21689305522202615,
  '-14': -0.22062000108341154,
  '-13': -0.22526496490378217,
  '-12': -0.23110379415216628,
  '-11': -0.23852275688285887,
  '-10': -0.2480794616724972,
  '-9': -0.2606094224086892,
  '-8': -0.27742392634692903,
  '-7': -0.3007057458670349,
  '-6': -0.33437970328961425,
  '-5': -0.3862891207153512,
  '-4': -0.4747252747252747,
  '-3': -0.6545454545454575,
  '-2': -1.2000000000000077,
  '-1': -2321473672304882.5,
};
export const lookup_scale_out = {
  '1': -0.16666666666666663,
  '2': -0.8333333333333323,
  '3': -0.3787878787878787,
  '4': -0.22893772893772857,
  '5': -0.1552409339294599,
  '6': -0.11198308607467841,
  '7': -0.08392145488919701,
  '8': -0.06451993862244036,
  '9': -0.05050785200724189,
  '10': -0.040066218060416416,
  '11': -0.032102297402384794,
  '12': -0.025919828460138968,
  '13': -0.0210541374198173,
  '14': -0.017183334236175865,
  '15': -0.014077546018356124,
  '16': -0.011568433197925089,
  '17': -0.009530112528117883,
  '18': -0.007866789055173075,
  '19': -0.006504488110101712,
  '20': -0.0053853776336312414,
  '21': -0.0044637755775509234,
  '22': -0.003703282359924958,
  '23': -0.00307468221685553,
  '24': -0.002554381270045693,
  '25': -0.002123227525747853,
  '26': -0.0017656074850451598,
  '27': -0.0014687463413364658,
  '28': -0.0012221602410587662,
  '29': -0.0010172236572437278,
  '30': -0.000846824970643911,
  '31': -0.0007050903873888178,
  '32': -0.000587161321020761,
  '33': -0.0004890139707618263,
  '34': -0.0004073124608178146,
  '35': -0.00033928885410362667,
  '36': -0.0002826448135179761,
  '37': -0.00023547079000074757,
  '38': -0.00019617946380021646,
  '39': -0.0001634508208060348,
  '40': -0.00013618675750127986,
  '41': -0.0001134735109964584,
  '42': -0.00009455053018170664,
  '43': -0.00007878465935319795,
  '44': -0.00006564871068409438,
  '45': -0.000054703667676936466,
  '46': -0.00004558389612456156,
  '47': -0.000037984848605655304,
  '48': -0.00003165283817760233,
  '49': -0.00002637653025692389,
  '50': -0.0000219798621272655,
  '51': -0.000018316149186160813,
  '52': -0.000015263178092249945,
  '53': -0.000012719120942802014,
  '54': -0.000010599132641079233,
  '55': -0.000008832516917335184,
  '56': -0.000007360365753294006,
  '57': -0.000006133592984934225,
  '58': -0.0000051112961345495146,
  '59': -0.000004259391674707051,
  '60': -0.000003549477943006591,
  '61': -0.000002957887787417571,
  '62': -0.000002464899198578117,
  '63': -0.0000020540776023639893,
  '64': -0.0000017117278195350225,
  '65': -0.0000014264374075508372,
  '66': -0.0000011886961289871454,
  '67': -9.905789428916844e-7,
  '68': -8.254816335274197e-7,
  '69': -6.879007941793953e-7,
  '70': -5.73250267671182e-7,
  '71': -4.777082824820881e-7,
  '72': -3.9809004544086285e-7,
  '73': -3.317415722134819e-7,
  '74': -2.7645121874110157e-7,
  '75': -2.3037595174847167e-7,
  '76': -1.9197991573213082e-7,
  '77': -1.5998316353733795e-7,
  '78': -1.3331933881460687e-7,
  '79': -1.110994300161259e-7,
  '80': -9.258285148344855e-8,
  '81': -7.715236918635783e-8,
  '82': -6.429363599975078e-8,
  '83': -5.357802664184373e-8,
  '84': -4.4648353170028246e-8,
  '85': -3.7206959240093706e-8,
  '86': -3.100579124264222e-8,
  '87': -2.583816420928949e-8,
  '88': -2.1531803104209266e-8,
  '89': -1.794316841008775e-8,
  '90': -1.4952640488876884e-8,
  '91': -1.2460533620114654e-8,
  '92': -1.038377795409148e-8,
  '93': -8.653148441705132e-9,
  '94': -7.210956771975901e-9,
  '95': -6.009130884588202e-9,
  '96': -5.007608627860752e-9,
  '97': -4.1730068611082565e-9,
  '98': -3.4775070046794135e-9,
  '99': -2.8979234596523376e-9,
  '100': -2.414934540616571e-9,
  '101': -2.0124456540759534e-9,
  '102': -1.6770377152249134e-9,
  '103': -1.3975322288541131e-9,
  '104': -1.164610468810685e-9,
  '105': -9.705086255581998e-10,
  '106': -8.087561016736407e-10,
  '107': -6.739635467452975e-10,
  '108': -5.616373069093258e-10,
  '109': -4.680303771577123e-10,
  '110': -3.900249492445406e-10,
  '111': -3.250215535585335e-10,
  '112': -2.7085192115268825e-10,
  '113': -2.257112077831025e-10,
  '114': -1.8809167356260547e-10,
  '115': -1.56742351491991e-10,
  '116': -1.3061954775720769e-10,
  '117': -1.0884905104985995e-10,
  '118': -9.070785103916779e-11,
  '119': -7.558960070929617e-11,
  '-120': 0.1666666667104114,
  '-119': 0.16666666671915936,
  '-118': 0.16666666672965785,
  '-117': 0.1666666667422566,
  '-116': 0.1666666667573745,
  '-115': 0.1666666667755161,
  '-114': 0.16666666679728298,
  '-113': 0.16666666682340897,
  '-112': 0.16666666685475734,
  '-111': 0.1666666668923759,
  '-110': 0.16666666693751758,
  '-109': 0.16666666699168697,
  '-108': 0.1666666670566958,
  '-107': 0.16666666713471243,
  '-106': 0.16666666722830328,
  '-105': 0.16666666734063076,
  '-104': 0.16666666747542336,
  '-103': 0.16666666763717583,
  '-102': 0.16666666783127598,
  '-101': 0.1666666680641984,
  '-100': 0.16666666834370458,
  '-99': 0.16666666867911317,
  '-98': 0.16666666908160133,
  '-97': 0.16666666956458745,
  '-96': 0.16666667014417233,
  '-95': 0.16666667083967254,
  '-94': 0.16666667167427549,
  '-93': 0.16666667267579666,
  '-92': 0.16666667387762343,
  '-91': 0.16666667531981474,
  '-90': 0.16666667705044466,
  '-89': 0.16666667912720032,
  '-88': 0.16666668161930742,
  '-87': 0.16666668460983544,
  '-86': 0.16666668819847008,
  '-85': 0.16666669250483024,
  '-84': 0.16666669767246536,
  '-83': 0.16666670387362592,
  '-82': 0.16666671131501784,
  '-81': 0.16666672024469248,
  '-80': 0.16666673096030132,
  '-79': 0.16666674381903335,
  '-78': 0.16666675924953184,
  '-77': 0.16666677776610062,
  '-76': 0.16666679998600625,
  '-75': 0.16666682664989899,
  '-74': 0.16666685864658418,
  '-73': 0.16666689704261822,
  '-72': 0.16666694311788602,
  '-71': 0.16666699840823396,
  '-70': 0.16666706475671178,
  '-69': 0.16666714437494798,
  '-68': 0.16666723991693208,
  '-67': 0.1666673545674614,
  '-66': 0.16666749214830162,
  '-65': 0.16666765724560914,
  '-64': 0.1666678553628107,
  '-63': 0.166668093104067,
  '-62': 0.16666837839448626,
  '-61': 0.16666872074425024,
  '-60': 0.1666691315658654,
  '-59': 0.16666962455445353,
  '-58': 0.16667021614461128,
  '-57': 0.16667092605833847,
  '-56': 0.16667177796280144,
  '-55': 0.16667280025964085,
  '-54': 0.16667402703242049,
  '-53': 0.16667549918358357,
  '-52': 0.16667726579930786,
  '-51': 0.16667938578760905,
  '-50': 0.16668192984475824,
  '-49': 0.16668498281585295,
  '-48': 0.1666886465287939,
  '-47': 0.16669304319692244,
  '-46': 0.1666983195048438,
  '-45': 0.16670465151527342,
  '-44': 0.16671225056279163,
  '-43': 0.16672137033434986,
  '-42': 0.16673231537734862,
  '-41': 0.1667454513260196,
  '-40': 0.1667612171968491,
  '-39': 0.16678014017766166,
  '-38': 0.16680285342416792,
  '-37': 0.1668301174874733,
  '-36': 0.16686284613046734,
  '-35': 0.16690213745666693,
  '-34': 0.16694931148018466,
  '-33': 0.1670059555207686,
  '-32': 0.16707397912748329,
  '-31': 0.1671556806374337,
  '-30': 0.16725382798766433,
  '-29': 0.16737175705405483,
  '-28': 0.16751349163730955,
  '-27': 0.16768389032391728,
  '-26': 0.16788882690773227,
  '-25': 0.1681354130080036,
  '-24': 0.16843227415171141,
  '-23': 0.16878989419241439,
  '-22': 0.1692210479367128,
  '-21': 0.16974134888352002,
  '-20': 0.17036994902659156,
  '-19': 0.17113044224420162,
  '-18': 0.1720520443003049,
  '-17': 0.17317115477676728,
  '-16': 0.1745334557218365,
  '-15': 0.17619677919478793,
  '-14': 0.17823509986459188,
  '-13': 0.1807442126850206,
  '-12': 0.18385000090284234,
  '-11': 0.18772080408648453,
  '-10': 0.1925864951268059,
  '-9': 0.19876896406904898,
  '-8': 0.20673288472708265,
  '-7': 0.21717451867390916,
  '-6': 0.23118660528910714,
  '-5': 0.25058812155586385,
  '-4': 0.2786497527413461,
  '-3': 0.32190760059612566,
  '-2': 0.3956043956043954,
  '-1': 0.5454545454545456,
};

export const lookup_scale_out_y = {
  1: -0.16666666666666663,
  2: -0.8333333333333346,
  3: -0.3787878787878784,
  4: -0.2289377289377288,
  5: -0.15524093392945887,
  6: -0.11198308607467908,
  7: -0.08392145488919661,
  8: -0.06451993862244154,
  9: -0.05050785200724154,
  10: -0.04006621806041598,
  11: -0.03210229740238237,
  12: -0.02591982846013953,
  13: -0.02105413741981677,
  14: -0.017183334236177714,
  15: -0.014077546018354974,
  16: -0.011568433197925422,
  17: -0.009530112528120685,
  18: -0.007866789055169783,
  19: -0.006504488110100774,
  20: -0.005385377633630779,
  21: -0.004463775577534958,
  22: -0.003703282359925098,
  23: -0.0030746822168548956,
  24: -0.002554381270046173,
  25: -0.002123227525748116,
  26: -0.0017656074850452646,
  27: -0.0014687463413366882,
  28: -0.0012221602410660102,
  29: -0.001017223657243206,
  30: -0.000846824970642732,
  31: -0.0007050903873889563,
  32: -0.0005871613210209021,
  33: -0.0004890139707619025,
  34: -0.00040731246081704593,
  35: -0.0003392888541007075,
  36: -0.0002826448135178257,
  37: -0.00023547079000008474,
  38: -0.0001961794638003731,
  39: -0.00016345100731178784,
  40: -0.00013618675750120808,
  41: -0.00011347351099466091,
  42: -0.00009455053018197377,
  43: -0.00007878465935746244,
  44: -0.00006564871068384949,
  45: -0.000054701856794370414,
  46: -0.00004558389612474008,
  47: -0.000037984848607131804,
  48: -0.000031652838178248685,
  49: -0.000026376530255561014,
  50: -0.000021979862126510726,
  51: -0.000018316149187419524,
  52: -0.00001526317809302267,
  53: -0.000012719120942868213,
  54: -0.000010599132641023222,
  55: -0.000008832516916802823,
  56: -0.0000073603657544605224,
  57: -0.000006133592981907631,
  58: -0.000005111296135752404,
  59: -0.000004259391959904137,
  60: -0.0000035494779292424873,
  61: -0.000002957887786428702,
  62: -0.0000024648991985944716,
  63: -0.0000020540776025678066,
  64: -0.0000017117278225051232,
  65: -0.0000014264374103272446,
  66: -0.0000011886961402751696,
  67: -9.905789428062328e-7,
  68: -8.254816352380215e-7,
  69: -6.879007941661578e-7,
  70: -5.732502674553597e-7,
  71: -4.777082828361516e-7,
  72: -3.9809004482208956e-7,
  73: -3.3174157192846226e-7,
  74: -2.7645121797537383e-7,
  75: -2.3037595073719078e-7,
  76: -1.9197991408219008e-7,
  77: -1.5998323173697256e-7,
  78: -1.333193402738562e-7,
  79: -1.1109943416428774e-7,
  80: -9.258285145899399e-8,
  81: -7.715236985642804e-8,
  82: -6.429363644896571e-8,
  83: -5.3578026710842537e-8,
  84: -4.464835263473614e-8,
  85: -3.720695924100527e-8,
  86: -3.100579841106975e-8,
  87: -2.58381645359275e-8,
  88: -2.153180358543076e-8,
  89: -1.794316883464967e-8,
  90: -1.495264027972764e-8,
  91: -1.246053335712181e-8,
  92: -1.0383777713015946e-8,
  93: -8.65314783804486e-9,
  94: -7.210958296057996e-9,
  95: -6.009132503966457e-9,
  96: -5.007608897080368e-9,
  97: -4.173011872947961e-9,
  98: -3.477505584227611e-9,
  99: -2.8979219119173342e-9,
  100: -2.4149361464764293e-9,
  101: -2.0124451908553737e-9,
  102: -1.6770379866117327e-9,
  103: -1.3975314581410428e-9,
  104: -1.164609059514846e-9,
  105: -9.705087899466063e-10,
  106: -8.087572694211009e-10,
  107: -6.739637485104956e-10,
  108: -5.616363640597994e-10,
  109: -4.680301665258793e-10,
  110: -3.9002516359710785e-10,
  111: -3.2502116323631166e-10,
  112: -2.7085156584516134e-10,
  113: -2.2570935656791629e-10,
  114: -1.880921014438955e-10,
  115: -5.857647776233657e-7,
  116: -1.3061823850817093e-10,
  117: -1.0884874963745932e-10,
  118: -9.070816619988112e-11,
  119: -7.558947725620862e-11,
  120: -6.299165087285802e-11,
};
