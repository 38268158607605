<div class="row justify-content-center my-5">
  <div class="col-4">
    <div class="row">
      <div class="col mb-2">
        <label for="email" class="form-label">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          #email="ngModel"
          [ngModel]="user.email"
          disabled
          class="form-control form-control-sm"
        />
      </div>
    </div>
    <div class="row">
      <div class="col mb-2">
        <label for="name" class="form-label">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          #name="ngModel"
          [(ngModel)]="user.name"
          class="form-control form-control-sm"
        />
      </div>
    </div>
    <div class="row">
      <div class="col d-grid">
        <button
          type="button"
          (click)="update()"
          class="btn btn-sm btn-dark"
          [disabled]="loading"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading"
          ></span>
          Update
        </button>
      </div>
    </div>
  </div>
</div>
