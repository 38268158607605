import { createSelector } from "@ngrx/store";
import * as _ from "lodash";

export type DataBag = Record<string, any>;

export const getDataBag = (store: { databag: DataBag }): DataBag =>
  store.databag;

const get = (dataBag: { data: Record<string, any> }, props): any => {
  return _.get(dataBag.data, props.path);
};

export const getDataBagValue = createSelector(getDataBag, get);

export const getDataBagValueAsArray = createSelector(
  getDataBag,
  (dataBag: { data: Record<string, any> }, props): any => {
    const object = _.get(dataBag.data, props.path);
    if (typeof object !== "object") {
      console.warn(`Property path: ${props.path} did not gave back an object`);
      return object;
    } else {
      return Object.keys(object).map((key) => object[key]);
    }
  }
);

export const getById = createSelector(
  getDataBag,
  (dataBag: { data: Record<string, any> }, props): any => {
    const array = _.get(dataBag.data, props.path);
    const index = (array || []).findIndex((element) => element.id === props.id);
    if (index > -1) {
      return array[index];
    }
  }
);
