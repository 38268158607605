import { cloneDeep } from 'lodash';
import { ImportedShape } from '../../../shape/shapes/general/imported/imported-shape';
import { AnimationFrame } from '../../components/animation-frame/animation.types';
import { MainAnimationFrameObject } from '../main-animation-frame-object';
import { AnimationFrameObject } from '../animation-frame-object';
import { AnimationEnvironment } from '../../../../services/animation/animation.types';

export class FunctionAnimationFrameObject extends AnimationFrameObject {
  functionInstance: MainAnimationFrameObject;
  targetShape?: ImportedShape;
  functionNotFound = false;
  targetNotFound = false;

  // get shape() {
  //   return this.rootMainFrame?.shape;
  // }

  // get rootMainFrame(): RootAnimationFrame {
  //   return this.mainFrame.rootMainFrame;
  // }

  // get mainFrame(): MainAnimationFrameObject {
  //   if (this.prev) {
  //     return this.prev.mainFrame;
  //   }
  //   if (this.parent) {
  //     return this.parent.mainFrame;
  //   }
  //   return this.fcnParent.mainFrame;
  // }

  get functionId() {
    if (this.targetShape) {
      return this.targetShape.shapeIRI + ':' + this.frame.function;
    } else {
      return this.frame.function;
    }
  }

  get functionName() {
    return this.frame.function;
  }

  get functionTarget() {
    return this.frame.functionTarget;
  }

  get _duration() {
    if (this.edit) {
      return 3;
    }

    const fcn = this.functionInstance;
    if (fcn?.id === this.id) {
      return this.frame.duration || 0;
    }
    return fcn?.fcnDuration;
  }

  // constructor(frame: AnimationFrame,
  //   parent?: AnimationFrameObject,
  //   prev?: AnimationFrameObject) {
  //   super(frame, parent, prev);
  // }

  findFrameById(id: string) {
    return this.functionInstance.findFrameById(id);
  }
  init() {
    super.init();

    this.mainFrame.functionInstances[this.frame.function] ||= {};
    this.mainFrame.functionInstances[this.frame.function][this.id] = this;

    if (this.functionTarget) {
      const targetIRI =
        this.shape.getType() == 'is'
          ? this.shape.IRI + '_' + this.functionTarget?.IRI
          : this.functionTarget?.IRI;

      this.targetShape = this.shape.service.getShapeByIRI(
        targetIRI,
      ) as ImportedShape;

      if (!this.targetShape) {
        // console.warn('target-shape-not-found', this.frame);
        this.targetNotFound = true;
        return;
      }

      this.functionInstance = this.targetShape.getFunctionInstance(
        this,
        this.frame.function,
      );
      if (!this.functionInstance) {
        console.warn('FunctionFrame could not be found', {
          fcn: this.function,
          target: this.functionTarget,
        });
        this.functionNotFound = true;
        return;
      }
    } else {
      // local function
      const functionFrame = this.rootMainFrame.findFunctionDescriptor(
        this.functionName,
        true,
      );
      if (!functionFrame) {
        console.warn('Local FunctionFrame could not be found', {
          fcn: this.function,
          target: this.functionTarget,
        });
        this.functionNotFound = true;
        return;
      }
      this.functionInstance = new MainAnimationFrameObject(
        cloneDeep(functionFrame),
        this,
      );
    }

    this.functionInstance.init();
    // todo - move it to the open
    // this.functionInstance.calcPositions(); // -- // -- //

    this.calcIntervals();
  }

  updated(): void {
    this.rootMainFrame.updateFunctions(
      this.id,
      this.frame.function,
      this.functionInstance.frameObject,
    );
    this.functionInstance.calcIntervals();
    this.functionInstance.calcRowIndexes();
  }

  save() {
    if (this.function == 'main') {
      return this.rootMainFrame.save();
    }

    // -- // -- // -- //
    // this.rootMainFrame.frame.functions = this.rootMainFrame.fu //
    const index =
      this.rootMainFrame.frame.functions?.findIndex(
        ({ name }) => name == this.frame.function,
      ) || -1;

    if (index == -1) {
      console.error('Function instance could not be saved');
      return;
    }
    this.rootMainFrame.frame.functions[index] = {
      name: this.function,
      frame: this.functionInstance.firstFrame.frameObject,
    };

    super.save();
  }

  updateFunction(functionFrame: AnimationFrame) {
    if (this.targetShape) {
      // -- //
    } else {
      console.log('function-instance-update', functionFrame);
      this.functionInstance = new MainAnimationFrameObject(
        cloneDeep(functionFrame),
        this,
      );
      this.functionInstance.init();
    }
  }

  calcIntervals(): void {
    const prev = this.prev;
    const parent = this.parent;
    if (!parent && !prev) {
      this._start = 0;
    } else if (parent) {
      this._start = parent._start;
    } else if (prev) {
      this._start = prev._end;
    }

    if (this.functionNotFound) {
      this._end = this._start + 1;
    } else {
      // happy path
      if (this.functionInstance) {
        this.functionInstance.calcFcnDuration();
        this._end = this._start + this.functionInstance.functionDuration;
      }
    }

    // this.mainFrame.functionDuration = Math.max(
    //   this.mainFrame.functionDuration,
    //   this._end,
    // );

    this.next?.calcIntervals();
    this.child?.calcIntervals();
  }

  // _findFunctioFrame() {
  //   const targetFrame: RootAnimationFrame = this.functionTarget?.IRI
  //     ? (this.getResourceByIRI(this.functionTarget?.IRI) as GeneralShape)?.animationFrame
  //     : this.rootMainFrame;
  //   return targetFrame?.findFunction(this.functionName, true);
  // }

  findFunctionDescriptor() {
    // -- // -- // -- //
    return this.functionTarget?.IRI
      ? (this.getResourceByIRI(this.functionTarget?.IRI) as ImportedShape)
          ?.baseShapeDescriptor.animationFrame
      : this.rootMainFrame.findFunctionDescriptor(this.functionName, true);
  }

  async _animate(env: AnimationEnvironment): Promise<void> {
    const { timeScale } = env;
    if (this.functionNotFound) {
      console.log('function-not-found');
      await new Promise(res => setTimeout(res, 1000));
    }

    await this.functionInstance?.animate({
      timeScale: timeScale * this.duration,
    });
  }

  openFrame(noClose = false) {
    if (this.open) {
      return;
    }

    // Close all other frames
    if (!noClose) {
      // by default we close the others
      this.animationService.openedFrames.map(frame => (frame.open = false));
      this.animationService.openedFrames = [];
    }

    this.open = true;
    this.animationService.selectedFunctionId = this.id;

    this.functionInstance.select({ open: true });
    this.animationService.openedFrames.push(this);

    // TODO - migrate //

    this.functionInstance.calcRowIndexes();
    this.height = this.functionInstance.frameOccupation.length;

    this.mainFrame.calcRowIndexes();
  }

  closeFrame() {
    this.open = false;
    // TODO - implement

    if (this.mainFrame.id == this.rootMainFrame.id) {
      this.animationService.selectedFunctionId = null;
    } else {
      this.animationService.selectedFunctionId = this.mainFrame.id;
      console.log('close-frame > selectedFunctioNId', this.mainFrame.id);
    }

    this.functionInstance.deselect();
    this.select();

    this.rootMainFrame.calcRowIndexes();
  }
}
