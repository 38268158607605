<div class="option-select-component" (click)="containerClickHandler()">
  <div class="base-row">
    <div *ngIf="selectedValue" class="selected-value">{{ selectedValue }}</div>
    <nw-icon type="arrow_drop_down" [size]="18"></nw-icon>
  </div>
  <nw-dropdown-component
    *ngIf="!_closed"
    [height]="20"
    [top]="8"
    [right]="1"
    [gap]="4"
    [data]="data"
    (clicked)="itemClicked($event)"
  >
    <ng-template let-value>
      <ng-template
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{ $implicit: value }"
      ></ng-template>
    </ng-template>
  </nw-dropdown-component>
</div>
