import { EventEmitter } from '@angular/core';
import { cloneDeep, omit } from 'lodash';
import {
  MainAnimationFrameObject,
  RootAnimationFrame,
} from '../../../../animation/frame/main-animation-frame-object';
import { ResourceData } from '../../../../../elements/resource/resource.types';
import {
  AnimationItem,
  AnimationKeys,
  CenterScaleAnimation,
  Coords,
  ImportedShapeDescriptor,
  IncrementState,
  Scale,
  ShapeConfig,
  ShapeControlPointPosition,
  ShapePosition,
  ShapeSelectParams,
} from '../../../../../elements/resource/types/shape.type';
import {
  RectangleController,
  RectangleDragResponse,
} from '../../../../../elements/util/rectangle-controller/rectangle-controller';
import { GeneralShape } from '../general-shape';
import { Container } from '@pixi/display';
import { Graphics } from 'pixi.js';
import { Rectanglelement } from '../../primitive/rectangle-element';
import { ContainerShape } from '../../container/container.shape';
import { ShapeService } from '../../../shape.service';
import {
  setDescriptorValue,
  setCurrentScene,
} from '../../../../store/editor.actions';
import { FunctionAnimationFrameObject } from '../../../../animation/frame/function/function-animation-frame';
import { BaseVector } from '../../../../../elements/base/vector/vector';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import { AnimationFrameObject } from '../../../../animation/frame/animation-frame-object';
import { AnimationByStart } from '../../../../animation/animation.service';
import { Point } from '../../../../../elements/base/point';

export class ImportedShape extends ContainerShape<ImportedShapeDescriptor> {
  shape: GeneralShape;
  shapeEvent = new EventEmitter();
  auxElementsAreShown = false;
  ox: number;
  oy: number;
  maskRectangle: Graphics;

  get animationFunctions() {
    if (this.animationFrame) {
      return [
        'main',
        ...(this.animationFrame.frame.functions?.map(({ name }) => name) || []),
      ];
    }
    return [];
  }

  get IRIPrefix() {
    return this.joinIRIs(this.rootParent.IRIPrefix, this.resourceData.IRI);
  }

  get framePanelCoords() {
    const { x, y } = this.cs.getAbsCoordsInverse(
      this.currentX + this.offsetX,
      this.currentY + this.offsetY,
    );
    const { width, height } = this.container.getBounds();
    return {
      x: x + (this.scale.x > 0 ? width * this.cs.canvasScale : 0),
      y: y + (this.scale.y > 0 ? (height * this.cs.canvasScale) / 2 : 0),
    };
  }

  get states() {
    return this.descriptor.baseShapeDescriptor.states || [];
  }

  get shapeIRI() {
    return this.descriptor.shapeIRI;
  }

  get animationConfig() {
    return this.descriptor.animationConfig;
  }

  get containerForRc() {
    return this.rectangleContainer;
  }

  get baseShapeDescriptor() {
    return this.descriptor.baseShapeDescriptor;
  }

  get completeResourceData() {
    const resourceData = super.completeResourceData;
    resourceData.relationships = {
      shape: this.shapes.map(shape => shape.completeResourceData),
    };
    return resourceData;
  }

  get _scaleMatrix() {
    return this.scaleMatrix;
  }

  get width() {
    return this.originalWidth * this.scaleX;
  }

  get height() {
    return this.originalHeight * this.scaleY;
  }

  get mainContainer() {
    return this.outerContainer;
  }

  // get originalWidth() {
  //   return this.baseShapeDescriptor.offset?.width;
  // }

  // get originalHeight() {
  //   return this.baseShapeDescriptor.offset?.height;
  // }

  originalWidth: number;

  originalHeight: number;

  getType() {
    return 'is';
  }

  get inputs() {
    return [...this.baseInputs, ...(this.baseShapeDescriptor.inputs || [])];
  }

  constructor(
    service: ShapeService,
    data: ResourceData<ImportedShapeDescriptor>,
    config?: ShapeConfig,
  ) {
    // TODO - revise
    super(service, data, config);
    this.cs.scaler.subscribe(() => this.shapeEvent.next());

    this.initialised = false;

    const { x, y } = this.baseShapeDescriptor.offset || { x: 0, y: 0 };

    this.childOffsetX = x;
    this.childOffsetY = y;

    this.customRotationCenter = this.descriptor.customRotationCenter;

    // (data.relationships.shape as ResourceData[]).map((shape, index) => {
    // console.log('baseShapeDescirptor.offset', x, y, '........', shape.literals.descriptor.position); //

    //   const { x: _x, y: _y} = shape.literals.descriptor.position;

    //   console.log('-- is:ps', x, y, '-----', _x, _y);

    //   shape.literals.descriptor.position.x -= x;
    //   shape.literals.descriptor.position.y -= y;
    //   (data.relationships.shape as ResourceData[])[index] = shape;
    // });

    // console.log('imported-shape-next-shapes', data.relationships.shape); //

    this.contextChangedEvent = new EventEmitter();

    this.init();

    this.animationsById ||= {};
    this.descriptor.animationConfig ||= {};

    this.currentScaleX = this.scaleX;
    this.currentScaleY = this.scaleY;

    this.current_X = this.x;
    this.current_Y = this.y;

    if (this.config?.isRoot) {
      this.selectedKeyEventSubscribe('a+c', () => {
        // -- // -- // -- // -- // -- //
        this.cs.consumeKeyEvent('a');
        this.cs.consumeKeyEvent('c');
        this.addControlPoint();
      });
      this.selectedKeyEventSubscribe('r+t', () => {
        // -- // -- // -- // -- // -- //
        const [w, h] = this._bBox;
        console.log('this.bBox', this._bBox);
        const origin = new BaseVector([
          (w / 2) * this.scale.x,
          (h / 2) * this.scale.y,
        ]);
        const leftTop = new BaseVector([
          (-w / 2) * this.scale.x,
          (-h / 2) * this.scale.y,
        ]);
        console.log('leftTop', leftTop.x, leftTop.y);
        console.log('leftTop.start', leftTop.start, 'end', leftTop.end);
        console.log('----------angle---------', this.angle);
        leftTop.rotate(this.angle);

        console.log('leftTop', leftTop.x, leftTop.y);
        console.log('leftTop.start', leftTop.start, 'end', leftTop.end);
      });
    }

    if (this.editable && this.customRotationCenter) {
      this.addRotationCenterPC(true);
    }
    if (this.editable) {
      this.cs.keyDownEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.showDragControllers();
      });

      this.cs.keyEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.hideDragControllers();
      });
    }
  }

  rotateContainer: Container;
  auxContainer: Container;
  rectangleContainer: Container;

  /** Container hierarchy:
   *
   *  outerContainer
   *    rotateContainer
   *       container
   *         auxContainer
   *    rectangleContainer
   *
   *  (hint: there is no section-group)
   */

  initShapes(): void {
    super.initShapes();
    if (this.descriptor.env?.state) {
      this.shapes.map(shape => shape.applyState(this.descriptor.env.state));
    }
  }

  handleRightClickMenu(key: string): void {
    switch (key) {
      case 'resolve':
        // -- //

        this._shapes.map(shape => {
          const descriptor = shape.descriptor;

          // const { x:} //
          descriptor.position;
        });

        // (this.parent as RootShape)

        break;
    }
  }

  centerScaleIncrementState: IncrementState<{ width: number; height: number }>;

  lastEnv: any;
  prepareKeyValueAnimation(
    animation: AnimationItem<AnimationKeys>,
    division: number,
    duration: number,
    inverse = false,
  ): void {
    super.prepareKeyValueAnimation(animation, division, duration, inverse);
    if (!animation) {
      return;
    }
    const { key, value } = animation;
    switch (key) {
      case 'env':
        // console.log('yooo.env animation', this.env.state);

        const { state } = value as any;
        this.lastEnv = value;
        if (!state) {
          return;
        }

        this.shapes.map(shape => {
          if (shape.descriptor.updatesByState?.[state]) {
            const { x, y, scale } = shape.descriptor.updatesByState?.[state]
              .position as ShapePosition;
            if (x !== undefined || y !== undefined) {
              // -- // -- //
              shape.prepareKeyValueAnimation(
                {
                  key: 'translate',
                  value: {
                    x: x || shape.translate.x,
                    y: y || shape.translate.y,
                  },
                },
                division,
              );
            }
            if (scale) {
              // -- //
              shape.prepareKeyValueAnimation(
                {
                  key: 'scale',
                  value: {
                    x: scale.x || shape.scale.x,
                    y: scale.y || shape.scale.y,
                  },
                },
                division,
              );
            }
          }
        });
        break;

      case 'center-scale':
        //

        this.setBBox();

        const { ratio } = value as CenterScaleAnimation;
        // const { width, height } = this.container.getBounds();
        const [width, height] = this._bBox.map(
          val => val / this.cs.previewShape._scaleX,
        );
        this.centerScaleIncrementState = {
          current: 1,
          increment: (ratio - 1) / division,
          data: { width, height },
        };
        // console.log('prep', this.IRI, { width, height }); //
        break;
    }
  }

  currentScaleX?: number;
  currentScaleY?: number;

  current_X?: number;
  current_Y?: number;

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);
    let current;
    Object.entries(this.animationsById?.[id] || {}).map(([key, value]) => {
      switch (key) {
        case 'center-scale':
          current = this.incrementState(
            this.centerScaleIncrementState,
            increment,
          );
          // console.log('current', current); //
          const { width: originalWidth, height: originalHeight } =
            this.centerScaleIncrementState.data;
          const [currentWidth, currentHeight] = [
            originalWidth,
            originalHeight,
          ].map(val => val * current);

          this._redraw({
            x: this.current_X - (currentWidth - originalWidth) / 2,
            y: this.current_Y - (currentHeight - originalHeight) / 2,
            scale: {
              x: this.currentScaleX * current,
              y: this.currentScaleY * current,
            },
          });

          // TODO - this won't work with margin in the drop-shadow
          if (this.dropShadowElement) {
            (this.dropShadowElement as Rectanglelement)?.patch({
              width: currentWidth,
              height: currentHeight,
            });
          }
          break;
      }
    });
  }

  incrementAnimationByKey(key: AnimationKeys, increment: number) {
    super.incrementAnimationByKey(key, increment);
    switch (key) {
      case 'env':
        console.log('increment-env');
        this.shapes.map(shape =>
          shape.incrementAnimationByKey('translate', increment),
        );
        this.shapes.map(shape =>
          shape.incrementAnimationByKey('scale', increment),
        );
        break;
    }
  }

  rotationCenterPC: PointController;
  customRotationCenter: Coords;
  addRotationCenterPC(hide = false) {
    const p: Coords = this.customRotationCenter
      ? [
          this.customRotationCenter[0] * this.scale.x,
          this.customRotationCenter[1] * this.scale.y,
        ]
      : [this.width / 2, this.height / 2];
    this.rotationCenterPC = new PointController(
      this,
      {
        p,
        color: '#ff0000',
        start: () => {
          // -- // -- // -- // -- //
        },
        drag: ({ x, y, dx, dy }) => {
          // console.log('-------- end-drag ----------', x, y, dx, dy);
          this.customRotationCenter = [x / this.scale.x, y / this.scale.y];
        },
        end: () => {
          // console.log('-------- end-drag ----------'); //
          this.store.dispatch(
            setDescriptorValue({
              IRI: this.IRI,
              key: 'customRotationCenter',
              value: this.customRotationCenter,
            }),
          );
        },
        controlPoint: {
          shapeIRI: this.IRI,
          point: 'custom',
        },
        dragOnto: params => {
          if (!params) {
            return;
          }
          const { point, shapeIRI } = params;
          this.addConstraint('custom', shapeIRI, point);
        },
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
    if (hide) this.rotationCenterPC.hide();
  }

  endAnimationByKeyValue(key: string, value: any) {
    super.endAnimationByKeyValue(key, value);

    switch (key) {
      case 'center-scale':
        const { ratio } = value as CenterScaleAnimation;
        this.currentScaleX *= ratio;
        this.currentScaleY *= ratio;

        const [width, height] = this._bBox.map(
          val => val / this.cs.previewShape._scaleX,
        );

        this.current_X -= (width * (ratio - 1)) / 2;
        this.current_Y -= (height * (ratio - 1)) / 2;
    }
  }

  hideDragControllers(): void {
    super.hideDragControllers();
    this.rotationCenterPC?.hide();
  }

  showDragControllers(): void {
    super.showDragControllers();
    this.rotationCenterPC?.show();
  }

  initContainers() {
    super.initContainers();
    this.outerContainer = new Container();
    // This is for the rectangle-controller
    this.rectangleContainer = new Container();

    this.outerContainer.addChild(this.container);
    this.outerContainer.addChild(this.rectangleContainer);
    // TODO - this will not be needed
    this.container.addChild(this.sectionContainer);
    this.outerContainer.visible = false;
  }

  addContainersToParent() {
    // if (this.containerAdded) {
    //   return;
    // }

    // this.containerAdded = true;

    if (this.descriptor.fixPosition) {
      this.cs.rootFixContainer.addChild(this.outerContainer);
      if (this.editable) {
        this.cs.rootFixContainer.addChild(this.circleContainer);
        this.cs.rootFixContainer.addChild(this.auxCircleContainer);
      }
    } else {
      this.parent?.containerForChildren.addChild(this.outerContainer);
    }

    if (this.editable && !this.descriptor.fixPosition) {
      this.parent?.circleContainer?.addChild(this.circleContainer);
      this.parent?.auxCircleContainer?.addChild(this.auxCircleContainer);
    }
  }

  removeContainersFromParent(): void {
    return;
    if (!this.containerAdded) {
      return;
    }

    this.containerAdded = false;
    this.parent?.containerForChildren.removeChild(this.outerContainer);

    if (this.editable) {
      this.parent.circleContainer?.removeChild(this.circleContainer);
      this.parent.auxCircleContainer?.removeChild(this.auxCircleContainer);
    }
  }

  interval: NodeJS.Timeout;

  isLoaded = false;
  init() {
    super.init();

    this.initShapes();
    this.initAnimationFrame();

    this.initControlPoints();
    if (this.parent?.contextChangedEvent) {
      this.subscriptions.push(
        this.parent.contextChangedEvent.subscribe(() => {
          this.contextIsUpdated();
        }),
      );
    }

    // let { x, y, width, height } = this.container.getBounds();

    // this.outerContainer.

    // this.ox = -x;
    // this.oy = -y;

    // TODO - move it back
    // This is bit dirty
    // if (this.shapeDescriptor._animationsById && this.shapeDescriptor.mask) {
    //   const { x: _x, y: _y, width: w, height: h } = this.shapeDescriptor.mask;

    //   this.ox = -_x;
    //   this.oy = -_y;

    //   width = w;
    //   height = h;

    //   this.originalWidth = w;
    //   this.originalHeight = h;

    //   this.maskRectangle = new Graphics().drawRect(0, 0, w, h);

    //   this.container.addChild(this.maskRectangle);
    //   this.auxContainer.mask = this.maskRectangle;
    // }

    // this.auxContainer.setTransform(this.ox, this.oy);

    this.setHeightWidth();

    if (this.descriptor.override) {
      this._shapes.map(shape => {
        // shape.data.IRI is the original irir
        // console.log('shape.data.iri', shape.data.IRI);
        if (shape.resourceData.IRI in this.descriptor.override) {
          /*
          Object.entries(this.descriptor.override[shape.data.IRI]).map(
            ([key, value]) => {
              console.log('setting-key-value', key, value);

              if (key.startsWith('svgAttributes')) {
                key = key.split('.')[1];
              }
              shape[key] = value;
            }
          ); 
          console.log('shape.get', shape.svgAttributes);
          shape.refresh(); */
        }
      });
    }

    this.drawLayerRectangle();

    this.outerContainer.visible = false;

    // if (this.editable) {
    // this.interval = setInterval(() => {
    //   const isThereUnInitialized = this._shapes.find(
    //     shape => !shape.initialised,
    //   );

    // if (!isThereUnInitialized) {
    this.initialised = true;

    clearInterval(this.interval);

    this.isLoaded = true;

    // This is going to be the // -- //
    this.setBBox();

    this.redraw();
    this.outerContainer.visible = true;
    if (this.editable) {
      this.initRC();
    }

    if (this.config?.copied) {
      this.select();
    }

    this._show();
    // this.saveBBox();
    // }
    // }, 50);
  }
  // }

  _bBox: [number, number];

  setBBox() {
    const { width, height } = this.container.getBounds();
    this._bBox = [width, height];
  }

  _hide() {
    super._hide();
    if (this.outerContainer) {
      this.outerContainer.visible = false;
    }
  }

  _show() {
    if (!this.isLoaded) {
      return;
    }
    super._show();
    if (this.outerContainer) {
      this.outerContainer.visible = true;
    }
  }

  selectedShapes() {
    if (!this.selected && !this.currentlyDragged) {
      return [];
    }

    return [
      ...super.selectedShapes(),
      ...this._shapes.filter(shape => shape.selected),
    ];
  }

  drawLayerRectangle() {
    return;
    const { x, y, width, height } = this.container.getBounds();
    const rect = new Rectanglelement(this, this.rectangleContainer, {
      width,
      height,
      fill: '#000',
      opacity: 0.001,
      // stroke: this.convertHexToNumber('#ff0000')
    })
      .rightClick(e => {
        this.cs.generalEventEmit('show-right-click-menu', {
          x: this.cs.canvasX,
          y: this.cs.canvasY,
          options: [{ label: 'Edit', id: 'edit' }],
          handler: (id: string) => {
            switch (id) {
              case 'edit':
                console.log('yooo edit');

                this.select();
                // rect.hide(); //
                rect.patch({
                  fill: undefined,
                  noFill: true,
                });
                this.rc.rect.patch({
                  fill: undefined,
                  noFill: true,
                });
                this._shapes.map(shape => shape.makeEditable());
                break;
            }
            this.cs.generalEventEmit('hide-right-click-menu');
          },
        });
      })
      .click(() => this.clicked());
  }

  unMoved: Coords;
  unMovedAngle: number;

  initRC() {
    if (this.rc) {
      return;
    }

    const [width, height] = [
      // this.originalWidth * this.scaleX,
      // this.originalHeight * this.scaleY,
      this._width * this.scaleX,
      this._height * this.scaleY,
    ];

    this.rc = new RectangleController(
      this,
      {
        // -- // -- // offset: [this.x, this.y], // -- // -- //
        width,
        height,
        showRectangle: true,
        rotation: this.position.rotation?.angle,
        startDrag: point => {
          try {
            console.log('start-drag', point);
            // -- // -- // -- // -- //
            // The IS has only one constraint //
            this.constrainedBy = Object.keys(this.controlPoints || {})[0];
            // console.log('-start-drag-', this.constrainedBy);
            // if (this.constrainedBy) {
            //   // goes to the default branch
            //   point = null;
            // }

            if (!this.constrainedBy && this.cs.isPressed('r')) {
              this.constrainedBy = 'center';
              this.cs.consumeKeyEvent('r');
            }

            if (this.constrainedBy) {
              let [w, h] = this._bBox;
              w *= this.scale.x;
              h *= this.scale.y;

              console.log('rc startDrag', this.constrainedBy, point);

              let end: Coords;
              switch (point) {
                case 'right-top':
                  end = [w / this.scale.x, 0];
                  break;
                case 'right-bottom':
                  end = [w / this.scale.x, h / this.scale.y];
                  break;
                case 'left-top':
                  end = [0, 0];
                  break;
                case 'left-bottom':
                  end = [0, h / this.scale.y];
                  break;
              }

              const [sx, sy] = this.prepareRotation(this.constrainedBy);
              const [ex, ey] = end;

              if (this.rotation?.angle) {
                const { angle } = this.rotation;
                const vector = new BaseVector([ex - sx, ey - sy]);

                vector.rotate(angle);
                this.unMovedAngle = angle;
                this.unMoved = [vector.end.x, vector.end.y];
                this.unMovedAngle = vector.getAngle();
                console.log('un-moved-angle', this.unMovedAngle, this.unMoved);
              } else {
                this.unMoved = [ex - sx, ey - sy];
                // no sure if this is needed
                this.unMovedAngle = this.getAngle(
                  this.unMoved[0],
                  this.unMoved[1],
                );
                console.log('un-moved-angle', this.unMovedAngle, this.unMoved);
              }
            }
          } catch (e) {
            console.log('error', e);
          }
        },
        drag: resp => this.rcDrag(resp),
        endDrag: () => this.endRCDrag(),
        mouseout: () => {
          if (!this.cs.shapeAddMode && !this.selected) {
            this.deselect({ onHover: true });
          }
        },
        mouseover: () => {
          // console.log('is.rc > mouseover');
        },
        clicked: () => {
          this.clicked();
        },
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
    this.rc.hide();
  }
  clicked(): void {
    if (this.cs.isPressed('f')) {
      console.log('is redirect', this.descriptor.shapeIRI);
      // const organisationId =
      //   this.cs.route.snapshot.paramMap.get('organisationID');
      // const projectId = this.cs.route.snapshot.paramMap.get('projectID');

      this.store.dispatch(setCurrentScene({ scene: null }));
      this.cs.router.navigate([
        'organisations',
        this.cs.currentOrganisationID,
        'projects',
        this.cs.currentProjectID,
        'file',
        this.descriptor.shapeIRI.split('#')[1],
      ]);
      return;
    }

    super.clicked();
  }

  prepareRotation(origin = 'center', wh?: Point) {
    let cx: number, cy: number;
    if (this.customRotationCenter) {
      const [x, y] = this.customRotationCenter;
      cx = x;
      cy = y;
    } else {
      let [w, h] = this._bBox;
      // console.log('w', w, 'h', h);

      if (wh) {
        // -- //
      } else {
        w *= this.scale.x;
        h *= this.scale.y;
      }

      switch (origin) {
        case 'center':
          cx = w / 2;
          cy = h / 2;
          break;
        case 'left-center':
          cx = 0;
          cy = h / 2;
          break;
        case 'right-center':
          cx = w;
          cy = h / 2;
          break;
        default:
          const controlPoint = this.controlPoints[this.constrainedBy];
          if (!controlPoint) {
            return;
          }
          cx = controlPoint.coords[0];
          cy = controlPoint.coords[1];
          break;
      }
    }

    this.container.pivot.x = cx / this.scale.x;
    this.container.pivot.y = cy / this.scale.y;

    this.container.position.x = cx;
    this.container.position.y = cy;

    if (this.circleContainer) {
      this.circleContainer.pivot.x = cx;
      this.circleContainer.pivot.y = cy;

      this.circleContainer.position.x = this.x + cx;
      this.circleContainer.position.y = this.y + cy;
    }

    if (this.auxCircleContainer) {
      this.auxCircleContainer.pivot.x = cx;
      this.auxCircleContainer.pivot.y = cy;

      this.auxCircleContainer.position.x = this.x + cx;
      this.auxCircleContainer.position.y = this.y + cy;
    }

    if (this.rectangleContainer) {
      this.rectangleContainer.pivot.x = cx;
      this.rectangleContainer.pivot.y = cy;

      this.rectangleContainer.position.x = cx;
      this.rectangleContainer.position.y = cy;
    }

    return [cx, cy];
  }

  applyRotation(angle: number) {
    this.container.rotation = angle;

    if (this.circleContainer) {
      this.circleContainer.rotation = angle;
    }

    if (this.auxCircleContainer) {
      this.auxCircleContainer.rotation = angle;
    }

    if (this.rectangleContainer) {
      this.rectangleContainer.rotation = angle;
    }
  }

  applyAnimationByTime(time: number, directApply = false) {
    super.applyAnimationByTime(time, directApply);
    this.shapes.map(shape => shape.applyAnimationByTime(time, true));
    return {};
  }

  _currentAngle: number;
  rcDrag({ x, y, width, height, dx, dy, _dx, _dy }: RectangleDragResponse) {
    if (this.constrainedBy) {
      const [x0, y0] = this.unMoved;
      const [nx, ny] = [x0 + _dx, y0 + _dy];
      // -- // -- //

      const newAngle = this.getAngle(nx, ny) + this.rotation.angle;

      const angle = newAngle - this.unMovedAngle;
      // console.log({
      //   unMoved: this.unMoved,
      //   unMovedAngle: this.unMovedAngle,
      //   angle,
      //   nx,
      //   ny,
      //   _dx,
      //   _dy,
      // });

      this.applyRotation(angle);

      this._currentAngle = angle;

      const [w, h] = this._bBox;
      this.rc.patch(
        {
          width: w * this.scale.x,
          height: h * this.scale.y,
        },
        true,
      );
      return;
    }

    // this.translate = { x, y };
    // console.log('drag', this.translate);
    this.scale = {
      x: width / this.originalWidth,
      y: height / this.originalHeight,
    };

    this.container.scale = {
      x: width / this.originalWidth,
      y: height / this.originalHeight,
    };
    this.outerContainer.position = { x, y };

    if (this.rotation?.angle) {
      this.prepareRotation('center');
    } else {
      this.updateCircleContainers(x, y);
    }

    // this.container.position = { x: width / 2, y: height / 2 };
    // this.setRotationOrigin();
    // this.setRotation(this.currentAngle);
    /// this.updateRotController();
    // this.updateFrameRectangle();
  }

  endRCDrag() {
    if (this.constrainedBy) {
      this.rotation.angle = this._currentAngle;
      this.rotation.controlPoint = this
        .constrainedBy as ShapeControlPointPosition;

      // const { width, height } = this.baseDescriptor.offset; //
      // this._bBox = [width * this.scale.x, height * this.scale.y]; //

      this.cs.store.dispatch(
        setDescriptorValue({
          IRI: this.IRI,
          key: 'rotation',
          value: cloneDeep(this.rotation),
        }),
      );
      return;
    }

    // this.clicked();
    this.updateRectController();

    this.saveTranslate();
    this.saveScale(this.scale);
  }

  // saveTranslate() {
  //   this.dispatchTranslate(this.translate);
  // }

  applyScale(scale: Scale): void {
    this.container.scale = scale;
    const { x, y } = scale;
    this.rc?.patch(
      {
        width: this.originalWidth * x,
        height: this.originalHeight * y,
      },
      true,
    );
  }

  _scaleX: number;
  _scaleY: number;

  startTransformation(dx?: number, dy?: number): void {
    super.startTransformation(dx, dy);
    this._scaleX = this.scaleX;
    this._scaleY = this.scaleY;
  }

  transformation(scaleX: number, scaleY: number, dx?: number, dy?: number) {
    super.transformation(scaleX, scaleY, dx, dy);
    this.scaleX = this._scaleX * scaleX;
    this.scaleY = this._scaleY * scaleY;
    this.applyScale({
      x: this.scaleX,
      y: this.scaleY,
    });
  }

  endTransformation(): void {
    super.endTransformation();
    this.scale = {
      x: this.scaleX,
      y: this.scaleY,
    };
    this.saveScale(this.scale);
  }

  setHeightWidth() {
    const { width, height } = this.outerContainer.getBounds();
    this._height = height;
    this._width = width;
    this.originalWidth = width;
    this.originalHeight = height;
  }

  resize(width: number, height: number): void {
    this.scaleX = width / this.originalWidth;
    this.scaleY = height / this.originalHeight;

    this.redraw();
    this.updateRectController();
    this.save();
  }

  updateRectController() {
    this.rc?.patch(
      {
        // offset: [0, 0],
        // width: this.originalWidth * (this.scale?.x || 0),
        // height: this.originalHeight * (this.scale?.y || 0),
        width: this.originalWidth * this.scaleX,
        height: this.originalHeight * this.scaleY,
      },
      true,
    );
  }

  hide() {
    super.hide();
    if (this.outerContainer) {
      this.outerContainer.visible = false;
    }
  }

  show() {
    super.show();
    if (this.outerContainer) {
      this.outerContainer.visible = true;
    }
  }

  functionInstances: Record<string, MainAnimationFrameObject> = {};

  getFunctionInstance(
    fcnParent: FunctionAnimationFrameObject,
    fcnName: string,
  ) {
    const frame =
      fcnName == 'main'
        ? this.descriptor.baseShapeDescriptor.animationFrame
        : this.descriptor.baseShapeDescriptor.animationFrame.functions?.find(
            ({ name }) => name == fcnName,
          )?.frame;

    // -- //
    if (!frame) {
      return;
    }

    const rootFrame = new RootAnimationFrame(this, frame, fcnParent);
    this.functionInstances[fcnParent.globalID || fcnParent.id] = rootFrame;
    return rootFrame;
  }

  setConsequtiveAnimationsByKey(animationsByStart: AnimationByStart[]) {
    // -- //
    super.setConsequtiveAnimationsByKey(animationsByStart);
    const store: Record<string, AnimationFrameObject> = Object.values(
      this.functionInstances,
    ).reduce((object, frame) => {
      object = {
        ...object,
        ...frame.getFrameCollection(),
      };
      return object;
    }, {});

    const innerAnimationsByStart = Object.entries(store)
      .sort(([, frame1], [, frame2]) => frame1.start - frame2.start)
      .map(([globalId, frame]) => ({
        globalId: globalId.includes('_') ? globalId : undefined,
        ...frame,
        id: frame.id,
      }));

    // -- // -- // -- //
    this.shapes.map(shape =>
      shape.setConsequtiveAnimationsByKey(innerAnimationsByStart),
    );
  }

  getRootAnimationFrame(
    fcnParent?: MainAnimationFrameObject | FunctionAnimationFrameObject,
  ) {
    return new RootAnimationFrame(
      this,
      this.baseShapeDescriptor.animationFrame,
      fcnParent,
    );
  }

  refresh() {
    // This eliminates the refresh of rectangle-controller. It should not be a problem //
    super.refresh();

    /*
    if (typeof this.opacity == 'number') {
      console.log('heyheyhey', this.opacity);
      this.container.filters = [new AlphaFilter(this.opacity)];
      // this.outerContainer.alpha = this.opacity
    } else {
      console.log('nofilters')
      this.container.filters = [];
    } */

    this.updateRotController();
    this._shapes.map(shape => shape.refresh());
  }

  updateRotController() {
    // this.rotateController?.patch({
    //   angle: this.currentAngle,
    //   width: this.originalWidth * (this.scale?.x || 0),
    // });
  }

  rotateByMatrix(matrix: any) {
    // TODO - migrate
    // this.rotateElementGroup.attr({ transform: matrix });
  }

  get noAnimationByParent() {
    const parents = [this];
    let parent = this.parent;
    while (parent) {
      // TODO - check this
      parents.push(parent as any);
      parent = parent.parent;
    }
    return parents.find(
      shape =>
        shape.getType() === 'is' &&
        !(shape as ImportedShape).descriptor?.animationConfig?.startAtInit,
    );
  }

  async startAnimations() {
    this.shapes
      .filter(shape => shape.getType() === 'is')
      .map((shape: ImportedShape) => shape.startAnimations());

    if (this.noAnimationByParent) {
      return;
    }

    if (this.animationConfig && this.animationFrame) {
      const { startAtInit, delay, loop } = this.animationConfig;
      if (!startAtInit) {
        return;
      }

      await new Promise(resolve => setTimeout(resolve, 1_000 * (delay || 0)));
      if (loop === 0) {
        while (1) {
          if (this.removed) {
            break;
          }
          const start = performance.now();
          await this.animationFrame.animate();
          if (performance.now() - start < 100) {
            break;
          }
          this.reInit();
        }
      } else {
        for (let i = 0; i < (loop || 1); i++) {
          if (this.removed) {
            break;
          }
          await this.animationFrame.animate();
          this.reInit();
        }
      }
    }
  }

  remove() {
    this.outerContainer.destroy();
    super.remove();
    clearInterval(this.interval);
  }

  redraw() {
    this._redraw({ x: this.x, y: this.y, scale: this.scale });
    this.updateCircleContainers(this.x, this.y);
    this.setShapeRotation(this.rotation);

    if (this.position?.skew) {
      // TODO - migrate
      // this.rotateElementGroup.attr({
      //   transform: `skewX(${this.position.skew.x})`,
      // });
    }
  }

  getBounds() {
    const { x, y } = this.scale;
    const { width, height } = this.descriptor.baseShapeDescriptor.offset;
    return {
      width: x * width,
      height: y * height,
    };
  }

  updateCircleContainers(x: number, y: number) {
    super.updateCircleContainers(x, y);
    this.sectionContainer?.setTransform(0, 0);
  }

  _redraw(position: ShapePosition): void {
    const { x, y, scale, rotation } = position;

    if (this.parent?.getType() == 'is' && !this.config?.multiplicationIndexes) {
      this.outerContainer.position = {
        x: x - (this.parent.childOffsetX || 0),
        y: y - (this.parent.childOffsetY || 0),
      };
    } else {
      this.outerContainer.position = { x, y };
    }

    if (scale) {
      this.container.scale = scale;
      // TODO - revise that
      const { width, height } = this.container.getBounds();
      this.containerDims = [width, height];
    }
    this.updateCircleContainers(x, y);

    if (rotation) {
      // this.setRotation(rotation * 180 / Math.PI)
      this.setShapeRotation(rotation);
    }
  }

  setRotationOrigin() {
    const { cx, cy } = this.getRotationOrigin();
    // this.cx = cx * Math.sign(this.scale.x);
    // this.cy = cy * Math.sign(this.scale.y);
    console.log('setRotationOrigin - cx', cx, 'cy', cy);
    this.cx = cx;
    this.cy = cy;
  }

  get absScaleX() {
    return this.scale.x * this.parent.absScaleX;
  }

  get absScaleY() {
    return this.scale.y * this.parent.absScaleY;
  }

  incrementRotation(deg?: number) {
    const { cx, cy, increment } = this.rotationInstance;

    // -- // -- // -- // -- //
    // if (degMax) {
    //   if (deg > 0) {
    //     deg = Math.min(deg, degMax - degSum);
    //   } else {
    //     deg = Math.max(deg, degMax - degSum);
    //   }
    // }

    this.rotationInstance.degSum += increment;
    // console.log('degSum', this.rotationInstance.degSum);

    // this.rotateGroupMatrix.rotate(deg, cx, cy);
    // console.log('increment-rotation', this.rotationInstance.degSum);

    this.applyRotation(this.rotationInstance.degSum);
  }

  /************************************** frame-rectangle *********************************************/

  get hasFrameRectangle() {
    return true;
  }

  patch(key: string, value: any) {
    if (key === 'svgAttributes.fill-opacity') {
      // TODO - migrate if necessary
      // this.rotateElementGroup.attr({ opacity: value });
      this.updateOpacity(value);
      super.patch('svgAttributes.opacity', value);
    } else {
      super.patch(key, value);
    }
  }

  updateOpacity(opacity: number) {
    this.container.alpha = opacity;
  }

  // TODOxx - check that
  // hasAnimation(id: string): boolean {
  //   if (id.startsWith(this.IRI)) {
  //     id = id.split('_').slice(1).join('_');
  //   }
  //   const result =
  //     !!this.baseShapeDescriptor._animationsById?.[id]?.length ||
  //     super.hasAnimation(id);
  //   // console.log('res', result); //
  //   return result;
  // }

  cnt = 0;

  select(params: ShapeSelectParams = {}) {
    super.select(params);

    this.rc?.show();
    // TODO - check why transform-controller is in the
    // this.cs.updateStore();
    // if (this.cs.isPressed('Shift')) {
    //   this.makeSymmetric(); //
    // }
  }

  makeSymmetric() {
    this.scale = {
      x: this.scale.x,
      y: this.scale.x,
    };
    this.refresh();
    this.redraw();
    const { width, height } = this.container.getBounds();
    this.rc?.patch(
      { width, height, rotation: this.radToDeg(this.currentAngle) },
      true,
    );
  }

  deselect(params: ShapeSelectParams = {}) {
    super.deselect(params);

    if (this.selected && params?.onHover) {
      return;
    }

    this.rc?.hide();
    this.selected = false;
  }

  // TODO - check what shapeEvent.next() had an effect
  // drag(x: number, y: number) {
  //   super.drag(x, y);
  //   // This is for the components attached to the shape (animation selector/etc)
  //   this.shapeEvent.next();
  // }

  copy(IRI: string, position: ShapePosition, config: ShapeConfig) {
    // TODO - revise
    return new ImportedShape(
      this.service,
      {
        IRI,
        type: 'nw:Shape',
        relationships: {
          ...this.clone(this.relationships),
          parent: this.parent.IRI,
        },
        literals: {
          descriptor: {
            ...omit(this.descriptor, [
              'multiplication',
              '_animationsById',
              'code',
            ]),
            shapeIRI: '',
            baseShapeDescriptor: {},
            position,
          },
        },
      },
      config,
    );
  }
}
