import { Coords } from '../../elements/resource/types/shape.type';
import { PathShape } from '../../element-editor/shape/shapes/path-shape/path-shape';
import { PathElement } from '../../element-editor/shape/shapes/primitive/path-element';
import { HttpService } from '../../store/http/http.service';
import { angles } from '../angles';
import { extendedMap } from '../util';
import { angleLookup } from './angleLookup';
import { BoundaryPoint } from './boundary-points';
import { Color, Pixel } from './pixel';
import { RawImageShape, RegionDescriptor } from './raw-image-shape';

export class Region {
  get container() {
    return this.imageShape.container;
  }

  get cs() {
    return this.imageShape.cs;
  }

  get service() {
    return this.imageShape.imageService;
  }

  get id() {
    return this.descriptor.id;
  }

  get color() {
    return this.descriptor.color;
  }

  selected = false;

  pathElement: PathElement;
  pathShape: PathShape;

  // get pathElement() {
  //   return this.pathShape?.element;
  // }

  get pixels() {
    return Object.values(this.pixelStore);
  }

  get boundaries() {
    return this.descriptor.boundaries;
  }

  get boundaryPixels() {
    return this.pixels.filter(p => p.amIBoundary);
  }

  length: number;

  public pixelStore: Record<string, Pixel> = {};

  get parentShape() {
    return this.imageShape;
  }

  innerPixels: Pixel[] = [];

  changed = false;

  startPoint: string;

  constructor(
    private imageShape: RawImageShape,
    public descriptor: RegionDescriptor,
  ) {
    const length = Object.entries(descriptor.pixels).length;

    if (this.boundaries.length) {
      const [x, y] = this.xyFromKey(this.boundaries[0]);
      this.startPoint = `${x}.${y}`;
    }

    Object.entries(descriptor.pixels).map(([key, color]) => {
      const [x, y] = this.xyFromKey(key);

      this.pixelStore[key] = new Pixel(
        this,
        x,
        y,
        // this.id == '0.12605639201454644' ? [255, 255, 255] : color
        color,
        // length > 1 ? color : [0, 0, 0]
      );
    });

    this.innerPixels =
      descriptor.innerPixels?.map(innerPixel => {
        const [x, y] = this.xyFromKey(innerPixel);
        const pixel = new Pixel(this, x, y, this.color);
        this.pixelStore[innerPixel] = pixel;
        return pixel;
      }) || [];

    this.length = length;
    this.discoverInnerPixels();
    this.initPathShape();

    this.calcAngles();
  }

  angles: number[];

  calcAngles() {
    // this.angles = [];
    this.angles = extendedMap<string, number>((prev, current, next) => {
      const [px, py] = this.xyFromKey(prev);
      const [cx, cy] = this.xyFromKey(current);
      const [nx, ny] = this.xyFromKey(next);
      // -- //
      const [dx1, dy1] = [cx - px, cy - py];
      const [dx2, dy2] = [nx - cx, ny - cy];

      return angles[`${dx1}.${dy1}|${dx2}.${dy2}`];
    }, this.boundaries);
  }

  resolveEdges() {
    extendedMap((prev, current, next, index) => {
      if (current == -90 && (prev != 0 || next != 0)) {
        const key = this.boundaries[index];

        if (this.pixelStore[key].reassign()) {
          this.pixelStore[key]?.element.patch({
            // fill: this.colorsToHex([0, 0, 255]),
          });
        }
      }
    }, this.angles);
  }

  discoverInnerPixels() {
    for (const pixel of this.innerPixels) {
      const pixelsToDiscover = [pixel];
      while (pixelsToDiscover.length) {
        const actualPixel = pixelsToDiscover.pop();
        pixelsToDiscover.push(...actualPixel.discoverInnerPixels());
      }
    }
  }

  initPathShape() {
    if (!this.descriptor.curveBoundaries) {
      return this.initPathElement(true);
    }

    this.pathElement?.remove();

    let position: { x: number; y: number };
    const elements = [];

    if (this.boundaries.length == 0) {
      return;
    }

    // console.log('curveBoundaries', this.descriptor.curveBoundaries);
    for (const boundary of this.descriptor.curveBoundaries) {
      const { start, end, type, a1, a2 } = boundary;

      const [sx, sy] = this.xyFromKey(start);
      const [ex, ey] = this.xyFromKey(end);

      if (!position) {
        position = { x: sx * 6, y: sy * 6 };
      }

      if (a1 || a2) {
        console.log('a1', a1, 'a2', a2);
      }

      elements.push({
        x: (sx - ex) * -6,
        y: (sy - ey) * -6,
        type: type == 'straight' ? 'line' : 'curve',
        a1: a1?.map(v => v * 6),
        a2: a2?.map(v => v * 6),
      });

      // lType = type == 'straight' ? 'line' : 'curve';

      // lx = sx;
      // ly = sy;
    }
    // console.log('position', position);
    // console.log('elements', elements);
    console.log('path-shape', this.pathShape);

    this.pathShape.remove();
    // this.pathShape = new PathShape(this.cs, {
    //   relationships: {
    //     parent: 'raw-image-shape',
    //   },
    //   literals: {
    //     descriptor: {
    //       position,
    //       closed: true,
    //       // sections: elements.map(([x, y]) => ({ type: 'line', x, y })),
    //       sections: elements,
    //       svgAttributes: {
    //         stroke: '#000000',
    //         'stroke-width': 1,
    //       },
    //     },
    //   },
    // });
    // this.pathShape.show();

    this.initPathElement();
  }

  initPathElement(show = false) {
    const elements = [];
    let position;
    // console.log('elements', elements);
    let lx: number, ly: number;
    for (const point of this.descriptor.boundaries) {
      const [x, y] = this.xyFromKey(point);
      if (!position) {
        this.startPoint = `${x}.${y}`;
        position = { x: x * 6, y: y * 6 };
      }
      if (lx || ly) {
        elements.push([(lx - x) * -6, (ly - y) * -6]);
      }
      lx = x;
      ly = y;
    }
    // const first = this.boundaries[0];
    // let [fx, fy] = this.xyFromKey(first);
    // elements.push([(fx - lx) * -6, (fy - ly) * -6]);

    this.pathElement?.remove();
    this.pathElement = new PathElement(this.imageShape, this.container, {
      position,
      elements: elements.map(([x, y]) => ({ type: 'line', x, y })),
      closed: true,
      // stroke: this.colorsToHex([0, 0, 0]),
      'stroke-width': 1,
    }).click(() => {
      console.log('id', this.id);
      this.clickHandler();
    });

    if (show) {
      this.pathElement.show();
    }
  }

  pathElementShowed = false;

  flipPathElements() {
    if (this.pathElementShowed) {
      this.pathElement?.hide();
      this.pathShape?.show();
    } else {
      this.pathElement?.show();
      this.pathShape?.hide();
    }
    this.pathElementShowed = !this.pathElementShowed;
  }

  addPixelByKey(key: string) {
    const [x, y] = this.xyFromKey(key);
    this.pixelStore[key] = new Pixel(this, x, y, this.color);
    return this.pixelStore[key];
  }

  hidden = false;

  select() {
    if (this.service.splitRegionMode) {
      console.log('hide-hide');
      this.pathElement.hide();
      return;
    }

    // if (this.cs.isPressed('t')) {
    //   this.hidden = true;
    //   this.pathElement.hide();
    //   return;
    // } else {
    //   this.hidden = false;
    // }

    this.selected = true;
    this.pathElement?.patch({ 'stroke-width': 2 });
    this.service.selectRegion(this);
  }

  deselect() {
    delete this.service.selectedRegions[this.id];
    this.selected = false;
    this.pathElement?.patch({ 'stroke-width': 1 });
  }

  clickHandler(pixel?: Pixel) {
    if (this.cs.isPressed('d')) {
      this.remove(true);
      return;
    }

    if (this.service.splitRegionMode && pixel) {
      this.service.splitRegionMode = false;
      this.discoverSplit(pixel);
    }

    this.selected ? this.deselect() : this.select();
  }

  mouseover() {
    if (this.selected) {
      return;
    }
    this.pathElement?.patch({ 'stroke-width': 2 });
  }

  mouseout() {
    if (this.selected) {
      return;
    }
    this.pathElement?.patch({ 'stroke-width': 1 });
  }

  colorsToHex(color: [number, number, number]) {
    return parseInt(
      `0x${color
        .map(c => Math.floor(c).toString(16))
        .map(v => (v.length == 1 ? '0' + v : v))
        .join('')}`,
    );
  }

  remove(hard = true) {
    this.deselect();
    console.log('region.remove', this.id);
    this.pathElement?.remove();
    this.pathShape?.remove();
    this.service.deleteRegion(this);
    this.changed = false;
    if (hard) {
      this.pixels.map(pixel => pixel.remove());
    }
  }

  boundaryPoints: Coords[] = [];
  boundaryPixelStore: Record<string, Pixel> = {};
  notBoundaryPixelStore: Record<string, Pixel> = {};

  xyFromKey(key: string): Coords {
    return key.split('.').map(k => +k) as Coords;
  }

  getAllAdjacentKeys(x: number, y: number) {
    return [
      [x + 1, y],
      [x - 1, y],
      [x, y + 1],
      [x, y - 1],
      [x + 1, y + 1],
      [x + 1, y - 1],
      [x - 1, y + 1],
      [x - 1, y - 1],
    ].map(([_x, _y]) => `${_x}.${_y}`);
  }

  mergePixelsIntoMe(pixels: Pixel[]) {
    for (const pixel of pixels) {
      pixel.assignToRegion(this);
    }
    this.updateColor(pixels);
  }

  mergeRegionIntoMe(region: Region) {
    this.mergePixelsIntoMe(region.pixels);
    this.descriptor.innerPixels.push(...region.descriptor.innerPixels);
    region.remove();
  }

  updateColor(pixels: Pixel[]) {
    const c1 = this.color.map(c => c * this.length);
    const count = pixels.length;

    const c2 = pixels
      .map(p => p.color)
      .reduce(([r, g, b], [rC, gC, bC]) => [r + rC, g + gC, b + bC], [0, 0, 0]);

    this.descriptor.color = [c1[0] + c2[0], c1[1] + c2[1], c1[2] + c2[2]].map(
      c => c / (this.length + count),
    ) as Color;
  }

  refresh() {
    if (!this.length) {
      this.pathElement?.remove();
      this.service.deleteRegion(this);
    }
    if (this.changed) {
      // this.setBoundary();
      this.initPathShape();
      this.service.updateRegion(this);
      this.changed = false;
    }
  }

  async save(http: HttpService) {
    const pixels = this.getBoundaryPixels();

    const response = await http.postPromise('new-boundaries', pixels);

    this.descriptor.boundaries = response.data as string[];
    this.descriptor.pixels = pixels;

    // console.log('boundaries', this.descriptor.boundaries);
    this.initPathShape();
    this.deselect();
    this.service.updateRegion(this);
  }

  getBoundaryPixels() {
    this.pixels.map(p => p.setBoundaryPixelFlag());
    const pixels = {};
    this.boundaryPixels.map(p => (pixels[p.key] = p.color));
    return pixels;
  }

  updateBoundariesAndPixels(
    pixels: Record<string, Color>,
    boundaries: string[],
  ) {
    // console.log('updateBoundariesAndpixels', this.id);
    this.descriptor.boundaries = boundaries;
    this.descriptor.pixels = pixels;
    this.initPathShape();
    this.deselect();
    this.service.updateRegion(this);
    // this.refresh();
  }

  addPixel(pixel: Pixel) {
    // -- // -- // -- // -- //
    this.length++;
    this.pixelStore[pixel.key] = pixel;
    pixel.region = this;
    this.changed = true;
  }

  removePixel(pixel: Pixel) {
    this.length--;
    // The descriptor is not changed because it will be change in the set-boundary function
    delete this.pixelStore[pixel.key];
    this.changed = true;
  }

  resolve(filterSelected = false) {
    while (true) {
      if (this.pixels.length == 0) {
        break;
      }

      for (const pixel of this.pixels) {
        pixel.reassign();
      }
    }

    this.remove();
  }

  // discoverSplit(startPixel: Pixel) {
  discoverSplit(startPixel: Pixel) {
    let store: Record<string, Pixel> = {};
    store[startPixel.key] = startPixel;

    while (Object.values(store).length) {
      const pixel = Object.values(store)[0];
      store = pixel.discoverSplit(store);
    }

    const pixelsToDetach = this.pixels.filter(
      pixel => pixel.foundInSplitDiscovery,
    );

    const newRegion = this.parentShape.addNewRegion({
      id: Math.random().toString(),
      pixels: {},
      length: pixelsToDetach.length,
      innerPixels: [startPixel.key],
      color: this.color,
    });

    pixelsToDetach.map(pixel => pixel.assignToRegion(newRegion));

    this.refresh();

    this.descriptor.innerPixels = this.descriptor.innerPixels.filter(
      key => this.pixelStore[key],
    );

    if (!this.descriptor.innerPixels.length) {
      const notBoundaries = Object.values(this.notBoundaryPixelStore);
      if (notBoundaries.length) {
        this.descriptor.innerPixels = [notBoundaries[0].key];
        this.service.updateRegion(this);
      }
    }

    newRegion.refresh();
  }

  boundaryAngles = [];

  bPoints: BoundaryPoint[] = [];

  elements = [];

  firstIsEdge: BoundaryPoint;

  calcBoundaryAngles() {
    // -- // -- //

    // if (this.id !== '0.6405267608177722') {
    //   return;
    // }

    this.boundaryAngles = [];

    let i = 0;

    for (const point of this.boundaryPoints) {
      const prev = this.getPrev(this.boundaryPoints, i);
      const next = this.getNext(this.boundaryPoints, i);

      const [dx1, dy1] = this.getPointDiff(prev, point);
      const [dx2, dy2] = this.getPointDiff(point, next);

      const key = `${dx1}.${dy1}|${dx2}.${dy2}`;
      if (angleLookup[key]) {
        this.boundaryAngles.push(angleLookup[key]);
      }
      i++;
    }

    this.bPoints = this.boundaryPoints.map(
      (point, index) => new BoundaryPoint(this, index, point),
    );

    this.bPoints.map(p => p.setRelationships());
    this.bPoints.map(bPoint => bPoint.setTangent());
    this.bPoints.map(bPoint => bPoint.setIsEdge());

    let current = this.bPoints[0];

    const startId = current.id;

    while (true) {
      if (current.isEdge) {
        if (!this.firstIsEdge) {
          this.firstIsEdge = current;
        }
        const descriptor = current.discover();
        this.elements.push(descriptor);
      }
      current = current.next;
      if (current.id == startId) {
        break;
      }
    }

    console.log('elements', JSON.stringify(this.elements));
  }

  setIsEdge(key: string) {
    this.pixelStore[key].element.patch({
      // fill: this.colorsToHex([0, 255, 0]),
      // r: 4,
    });
  }

  setPixelColor(key: string, color: Color) {
    this.pixelStore[key].element.patch({
      // fill: this.colorsToHex(color),
      // r: 4,
    });
  }

  getPointDiff(p1: Coords, p2: Coords) {
    return [p2[0] - p1[0], p2[1] - p1[1]];
  }

  getPrev(array: any[], index: number) {
    return array[index == 0 ? array.length - 1 : index - 1];
  }

  getNext(array: any[], index: number, cnt = 1) {
    let _cnt = 0;
    while (true) {
      index = this.getNextIndex(array, index);
      _cnt += 1;
      if (_cnt >= cnt) break;
    }
    return array[index];
  }

  getNextIndex(array: any[], index: number) {
    if (index < array.length - 1) {
      return index + 1;
    } else {
      return 0;
    }
  }

  deletePixel(pixel: Pixel) {
    pixel.remove();
    this.removePixel(pixel);
    this.refresh();
  }

  setEdgePixel(pixel: Pixel) {
    if (this.descriptor.edges?.[pixel.key]) {
      delete this.descriptor.edges[pixel.key];
      pixel.element.patch({
        // fill: this.colorsToHex(pixel.color || this.color),
      });
    } else {
      this.descriptor.edges ||= {};
      this.descriptor.edges[pixel.key] = true;
      // pixel.element.patch({ fill: this.colorsToHex([255, 0, 0]) });
    }
  }

  show() {
    console.log('region-show');
    this.pathElement.show();
    this.pixels.map(p => p.element.show());
  }

  hide() {
    console.log('region-hide');
    this.pathElement.hide();
    this.pixels.map(p => p.element.hide());
  }
}
