import { Component, Input } from '@angular/core';

@Component({
  selector: 'nw-time-display',
  templateUrl: './time-display.component.html',
  styleUrls: ['./time-display.component.scss'],
})
export class TimeDisplayComponent {
  @Input()
  time: number;

  @Input()
  ratio = 1;

  // get timeInSec() {
  //   return this.time / this.ratio;
  // }

  get currentTimeTexts() {
    const sec = Math.floor(this.time);
    const min = Math.floor(sec / 60);
    const convert = (num: number) =>
      num < 10 ? `0${Math.floor(num)}` : Math.floor(num);

    const convert100 = (num: number) => {
      if (num > 999) {
        return num.toString().slice(0, 3);
      }
      if (num > 99) {
        return num;
      }
      if (num > 10) {
        return `0${num}`;
      }
      return `00${num}`;
    };
    const milisec = Math.floor(1000 * (this.time % 1));
    // Math.floor((this.time - 60 * sec) * 16.666667);
    return {
      min: `${convert(min)}`,
      sec: `${convert(sec)}`,
      milisec: `${convert100(milisec)}`,
    };
  }
}
