import { createAction, props } from '@ngrx/store';
import { ResourceData } from '../elements/resource/resource.types';
import { Project } from './project.interface';

export const ProjectFeature = 'Project';

function getActionName(action: string) {
  return `[${ProjectFeature}] ${action}`;
}

export const loadProjects = createAction(getActionName('list'));

export const loadProjectById = createAction(
  getActionName('get-one'),
  props<{ id: string }>(),
);

export const updateFileName = createAction(
  getActionName('update-file-name'),
  props<{ IRI: string; newName: string }>(),
);

export const deleteProject = createAction(
  getActionName('delete-project'),
  props<{ id: string }>(),
);

export const setFiles = createAction(
  getActionName('set-files'),
  props<{ files: Record<string, ResourceData> }>(),
);

export const saveProjectLanguages = createAction(
  getActionName('save-languages'),
  props<{ languages: string[] }>(),
);

export const saveProjectLanguagesBase = createAction(
  getActionName('save-languages-base'),
  props<{ languages: string[] }>(),
);

export const setCurrentLanguage = createAction(
  getActionName('save-current-language'),
  props<{ language: string }>(),
);

export const stepLanguage = createAction(getActionName('step-language'));

export const saveProjectColorPalette = createAction(
  getActionName('save-color-palette'),
  props<{ colorPalette: Record<string, string> }>(),
);

export const saveProjectColorPaletteBase = createAction(
  getActionName('save-color-palette-base'),
  props<{ colorPalette: Record<string, string> }>(),
);

export const deleteProjectRequest = createAction(
  getActionName('delete-project-request'),
  props<{ id: string }>(),
);

export const deleteProjectRequestSuccess = createAction(
  getActionName('delete-project-request-success'),
);

export const deleteProjectRequestFailure = createAction(
  getActionName('delete-project-request-failure'),
  props<{ error: string }>(),
);

export const setDeleteIsPending = createAction(
  getActionName('set-delete-is-pending'),
  props<{ id: string }>(),
);

export const resetDeleteIsPending = createAction(
  getActionName('reset-delete-is-pending'),
);

export const loadProjectWithFilesById = createAction(
  getActionName('get-one-with-files'),
  props<{ id: string }>(),
);

export const loadProjectWithFilesByIdRequest = createAction(
  getActionName('get-one-with-files-request'),
  props<{ id: string }>(),
);

export const loadProjectSuccess = createAction(
  getActionName('get-one-success'),
  props<{ project: ResourceData }>(),
);

export const patchProjectDescriptorSuccess = createAction(
  getActionName('patch-project-descriptor-success'),
  props<{ project: ResourceData }>(),
);

export const loadProjectFailed = createAction(
  getActionName('get-one-failed'),
  props<{ message: string }>(),
);

export const createProject = createAction(
  getActionName('create'),
  props<{ name: string }>(),
);

export const setNewlyCreatedProject = createAction(
  getActionName('set-newly-created-project'),
  props<{ id: string; time: number }>(),
);

export const setProject = createAction(
  getActionName('set'),
  props<{ project: Project }>(),
);

export const setNewProjectLoading = createAction(
  getActionName('set-new-project-loading'),
  props<{ value: boolean }>(),
);

export const deleteProjectFromStore = createAction(
  getActionName('delete-project-from-store'),
  props<{ id: string }>(),
);

export const setProjects = createAction(
  getActionName('set list'),
  props<{ projects: Project[] }>(),
);

export const setCurrentProject = createAction(
  getActionName('set-current'),
  props<{ id: string }>(),
);

export const saveProjectDescriptor = createAction(
  getActionName('save-project-descriptor'),
);

export const setFile = createAction(
  getActionName('set-file'),
  props<{
    file: ResourceData;
  }>(),
);

export const addFileToProject = createAction(
  getActionName('add-file-to-project'),
  props<{
    label: string;
  }>(),
);

export const addFileToProjectRequest = createAction(
  getActionName('add-file-to-project-request'),
  props<{
    projectID: string;
    label: string;
  }>(),
);
export const setNewFileLoading = createAction(
  getActionName('set-new-file-loading'),
  props<{
    value: boolean;
  }>(),
);
