import { Observable } from 'rxjs';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { KeyHandlerService } from '../../services/keyhandler/keyhandler.service';
import { CanvasService } from '../../services/canvas/canvas.service';
import { Point } from '../../elements/base/point';
import { ComponentEvent } from '../../components/general-component/general.component.type';
import { ListConfig } from '../../components/util/list/list.types';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileUploaderComponent } from '../../components/file-uploader/file-uploader.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { HttpService } from '../../store/http/http.service';
import { Store } from '@ngrx/store';
import {
  loadProjectWithFilesById,
  setCurrentProject,
} from '../../projects/project.actions';
import {
  loadFileAction,
  openFile,
  setCurrentScene,
} from '../store/editor.actions';
import { ShapeService } from '../shape/shape.service';
import { EditorService } from '../editor.service';
import { isFileLoading } from '../store/selector/editor.selector';
import { ProjectSettings } from '../../projects/project.reducer';
import { Organisation } from '../../organisation/organisation.interface';
import {
  loadOrganisationById,
  setCurrentOrganisationID,
} from '../../organisation/organisation.actions';
import { organisationById } from '../../organisation/organisation.selector';

@Component({
  selector: 'iw-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  x: number;
  y: number;
  clicked: Point;
  menu = false;
  options: string[] = [];
  selected = 0;
  svgDisabled = false;
  listConfig: ListConfig = {
    title: 'Templates',
    endpoint: 'template/component',
    select: {
      path: 'RootComponent:message',
      value: {
        component: 'element-editor',
        input: '${data}',
      },
    },
    create: {
      path: 'RootComponent:message',
      value: {
        component: 'element-editor',
        input: '${data}',
      },
    },
  };

  readonly organisationId$: Observable<string>;
  readonly organisation$: Observable<Organisation>;
  elements$: Observable<string[]>;

  loadedScene: string;
  isFileLoading$: Observable<boolean>;
  newFileId: string;
  overlayComponent: ProjectSettings;

  @ViewChild('editorContainer') editorContainer: ElementRef;

  constructor(
    readonly cs: CanvasService,
    readonly shapeService: ShapeService,
    khs: KeyHandlerService,
    readonly http: HttpService,
    readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.isFileLoading$ = this.store.select(isFileLoading);

    // this.route.params
    //   .pipe(filter(({ organisationID }) => !!organisationID))
    //   .subscribe(
    //     ({ organisationID }) =>
    //       (this.cs.currentOrganisationID = organisationID),
    //   );

    this.organisationId$ = this.route.params.pipe(
      map(({ organisationID }) => organisationID),
      filter(id => !!id),
    );

    // this.organisationId$.subscribe(val => {
    //   console.log('-------log1--------', val);
    // });

    this.organisation$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      tap(id => {
        this.cs.currentOrganisationID = id;
        this.store.dispatch(setCurrentOrganisationID({ ID: id }));
        this.store.dispatch(loadOrganisationById({ id }));
      }),
      mergeMap(id => this.store.select(organisationById(id))),
    );

    // This is necessary to fire-load-org-by-id
    this.organisation$.subscribe(org => {
      // console.log('Organisation data:', org);
    });

    // this.route.queryParamMap.subscribe(params => {
    //   const scene = params.get('scene');
    //   this.store.dispatch(setCurrentScene({ scene }));

    //   if (this.cs.currentScene == scene || !scene) {
    //     this.cs.currentScene = null;
    //     return;
    //   }

    //   this.cs.currentScene = scene;

    this.route.params
      .pipe(filter(({ projectID }) => !!projectID))
      .subscribe(async ({ projectID, fileID }) => {
        if (projectID != this.cs.currentProjectID) {
          this.cs.store.dispatch(setCurrentProject({ id: projectID }));
          this.cs.store.dispatch(loadProjectWithFilesById({ id: projectID }));
        }
        this.cs.currentProjectID = projectID;

        if (fileID) {
          this.shapeService.previewShapeIsLoading = true;
          this.cs.store.dispatch(
            openFile({
              ID: fileID,
            }),
          );
          this.cs.previewShape?.remove();
        }
        this.cs.currentFileID = fileID;
      });

    this.cs.keyEventSubscribe('g+r', () => {
      const { organisationID, projectID } = this.route.snapshot.params;
      // console.log({
      //   organisationID,
      //   projectID,
      //   loadedItem: this.cs.loadedItem,
      // });
      this.router.navigate(
        [
          'organisations',
          organisationID,
          'projects',
          projectID,
          'file',
          this.cs.currentFileID,
          'record',
        ],
        this.cs.currentScene
          ? {
              queryParams: {
                scene: this.cs.currentScene,
              },
            }
          : undefined,
      );
    });
  }

  ngOnInit() {
    this.x = 100;
    this.clicked = new Point(0, 0);
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
      }
      this.cs.keyDown(event.code);
    });

    document.addEventListener('keyup', (event: KeyboardEvent) => {
      event.preventDefault();
      this.cs.keyUp(event.code);
    });
  }

  ngAfterViewInit() {}

  eventHandler(e: ComponentEvent) {
    switch (e.type) {
      case 'disableCanvas':
        this.svgDisabled = true;
        break;
      case 'enableCanvas':
        this.svgDisabled = false;
        break;
    }
  }

  openDialog() {
    this.dialog.open(FileUploaderComponent, {});
  }

  // @HostListener('window:keyup', ['$event'])
  // keyEventUp(event: KeyboardEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.cs.keyUp(event.code);
  // }

  // @HostListener('window:keydown', ['$event'])
  // keyEventDown(event: KeyboardEvent) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //   }
  //   this.cs.keyDown(event.code);
  // }
}
