<div
  class="stroke-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Stroke"
    [attributeState]="attributeState$ | async"
    key="stroke"
    [initialValue]="{ color: '#000000', width: 1 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-2"
  >
    <div class="row-space-between">
      <div class="label">color</div>
      <nw-color-select
        [colors]="attributeState.value['color']"
        (changed)="updateSubAttributeValue('color', $event)"
      >
      </nw-color-select>
    </div>
    <div class="row-space-between">
      <div class="label">width</div>
      <nw-number-input
        [value]="attributeState.value['width']"
        (changed)="updateSubAttributeValue('width', $event)"
      ></nw-number-input>
    </div>

    <div>
      <nw-boolean-input
        key="dash"
        [value]="attributeState.value.dash"
        (changed)="dashChanged($event)"
      ></nw-boolean-input>

      <div
        *ngIf="attributeState.value.dash?.length"
        class="flex-row space-between mv-6"
      >
        <nw-number-input
          key="fill"
          [value]="attributeState.value.dash[0].fill"
          (changed)="dashFillChange($event)"
        ></nw-number-input>
        <nw-number-input
          key="gap"
          [value]="attributeState.value.dash[0].gap"
          (changed)="dashGapChange($event)"
        ></nw-number-input>
      </div>
    </div>
    <!-- todo - move dash here -->
  </div>
</div>
