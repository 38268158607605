import { RootShape } from '../../shape/shapes/general/root/root-shape';
import { CanvasTransformFrame } from '../components/animation-frame/animation.types';
import { AnimationFrameObject } from './animation-frame-object';
import {
  Easing,
  IncrementController,
} from './increment/controller/increment.controller';

export class CanvasTransformFrameObject extends AnimationFrameObject<CanvasTransformFrame> {
  get rootShape() {
    return this.shape as RootShape;
  }
  get x() {
    return this.frame.translate[0];
  }
  set x(val: number) {
    this.frame.translate[0] = val;
  }
  get y() {
    return this.frame.translate[1];
  }
  set y(val: number) {
    this.frame.translate[1] = val;
  }
  get scale() {
    return this.frame.scale;
  }

  set scale(val: number) {
    this.frame.scale = val;
  }

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      translate: this.frame.translate,
      scale: this.frame.scale,
    };
  }

  select() {
    super.select();

    (this.shape as RootShape).applyPosition({
      x: this.x,
      y: this.y,
      scale: { x: this.scale, y: this.scale },
    });
  }

  xInc: IncrementController;
  yInc: IncrementController;

  sxInc: IncrementController;
  syInc: IncrementController;

  // async animate(inverse = false, timeScale = 1) {
  //   return Promise.resolve();
  // }

  executeAnimation(timeScale?: number): Promise<void> {
    const currentPosition = this.rootShape.canvasPosition;
    const { scale: scale1 } = currentPosition;

    const numberOfBatches = Math.floor(
      this.duration * timeScale * this.cs.batchPerSecond,
    );

    this.xInc = new IncrementController(
      currentPosition.x,
      this.x * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );
    this.yInc = new IncrementController(
      currentPosition.y,
      this.y * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );

    this.sxInc = new IncrementController(
      scale1.x,
      this.scale * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );
    this.syInc = new IncrementController(
      scale1.y,
      this.scale * this.cs.scaleOffset,
      numberOfBatches,
      Easing.SMOOTH,
    );

    this.currentIncrement = 0;
    return new Promise<void>(resolve => {
      this.service.animationFrames[this.id] = increment => {
        this.currentIncrement += increment;
        let end = false;
        if (this.currentIncrement >= numberOfBatches) {
          end = true;
          // Occasional overflow is eliminated
          increment -= this.currentIncrement - numberOfBatches;
        }

        const _x = this.xInc.increment(increment);
        const _y = this.yInc.increment(increment);
        const _sx = this.sxInc.increment(increment);
        const _sy = this.syInc.increment(increment);

        // console.log({ _sx, _sy });
        this.rootShape.applyPosition({
          x: _x,
          y: _y,
          scale: {
            x: _sx,
            y: _sy,
          },
        });

        if (end) {
          // this.rootShape.setPosition(targetPosition);
          delete this.service.animationFrames[this.id];
          resolve();
        }
      };
    });
  }
}
