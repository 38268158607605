import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import {
  PrimitiveTypes,
  TypeDef,
} from '../../../elements/resource/types/shape.type';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-key-value-menu',
  templateUrl: './key-value-menu.component.html',
  styleUrls: ['./key-value-menu.component.scss'],
})
export class KeyValueMenuComponent implements OnInit {
  localData: TypeDef;

  /**
   *  @description This getter returns true if no dataScheme is given as an input,
   *  which means that the data is being edited is a data-scheme.
   */
  get dataSchemeMode() {
    return !this.dialogData?.dataScheme;
  }

  arrayMode = false;

  selectedIndex = 0;
  keySelected = false;
  valueSelected = false;
  selectedOption = -1;

  get isRowSelected() {
    return true;
  }

  isSelected(index: number) {
    return this.selectedIndex === index;
  }

  isKeySelected(index: number) {
    return this.keySelected && this.selectedIndex === index;
  }

  isValueSelected(index: number) {
    return this.valueSelected && this.selectedIndex === index;
  }

  isEnumSelected(index: number) {
    return this.selectedOption !== -1 && this.selectedIndex === index;
  }

  get currentSelectedData() {
    return this.localData[this.selectedIndex];
  }

  get optionRow() {
    return this.localData[this.selectedIndex].value === 'option';
  }

  get isAddItemSelected() {
    return this.selectedIndex === this.localData.length;
  }

  get optionSelected() {
    return this.optionRow && this.valueSelected === true;
  }

  get types(): Array<PrimitiveTypes> {
    return ['string', 'number', 'boolean', 'color', 'option'];
  }

  get currentNewType() {
    return this.types[this.newTypeIndex];
  }

  getTypeClass(index: number) {}

  addNewState = false;
  optionEditMode = false;

  newTypeIndex = 0;

  constructor(
    private readonly cs: CanvasService,
    public dialogRef: MatDialogRef<KeyValueMenuComponent>,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      data: Record<string, any>;
      dataScheme?: Record<string, any>;
    },
  ) {
    // TODO - onDestroy -> unsubscribe

    this.cs.keyEventSubscribe('a', () => {
      if (this.addNewState) {
        return;
      }

      if (!this.valueSelected) {
        this.localData = cloneDeep([
          ...this.localData.slice(0, this.selectedIndex + 1),
          { key: '__new__', value: null },
          ...this.localData.slice(this.selectedIndex + 1),
        ]);
        this.selectedIndex++;
        this.addNewState = true;
        this.save();
        return;
      }

      if (this.selectedOption !== -1) {
        this.currentSelectedData.options = [
          ...this.currentSelectedData.options.slice(0, this.selectedOption + 1),
          'new-item',
          ...this.currentSelectedData.options.slice(this.selectedOption + 1),
        ];
        this.selectedOption++;
        this.save();
      }
    });

    this.cs.keyEventSubscribe('Tab', () => {
      if (this.addNewState) {
        return;
      }

      if (this.keySelected) {
        if (this.currentSelectedData.options?.length) {
          this.valueSelected = true;
          this.selectedOption = 0;
        }
        this.keySelected = false;
      } else {
        this.keySelected = true;
      }
    });

    this.cs.keyEventSubscribe(
      'Enter',
      () => {
        this.cs.consumeKeyEvent('Enter');

        console.log('Enter');
        if (this.addNewState) {
          const type = this.currentNewType;
          const newData = this.localData.find(({ key }) => key === '__new__');
          newData.key = 'key';
          newData.value = type;
          if (type === 'option') {
            newData.options = ['option-1'];
          }
          this.keySelected = true;
          this.addNewState = false;
          this.valueSelected = true;
          this.save();
          return;
        }

        if (this.valueSelected) {
          this.optionEditMode = !this.optionEditMode;
          return;
        }

        this.keySelected = !this.keySelected;
      },
      10,
    );

    // this.cs.keyEventSubscribe(
    //   'Backspace',
    //   () => {
    //     if (this.addNewState) {
    //       this.localData = this.localData.filter(
    //         ({ key }) => key !== '__new__'
    //       );
    //       this.selectedIndex--;
    //       return;
    //     }

    //     if (this.valueSelected && this.optionRow) {
    //       if (this.currentSelectedData.options.length === 1) {
    //         return alert('The only element cannot be deleted');
    //       }

    //       this.currentSelectedData.options.splice(this.selectedOption, 1);
    //       if (this.selectedOption > 0) {
    //         this.selectedOption--;
    //       }
    //       this.save();
    //       return;
    //     }

    //     if (this.isRowSelected) {
    //       this.localData.splice(this.selectedIndex, 1);
    //     }
    //   },
    //   10
    // );

    this.cs.keyEventSubscribe('ArrowRight', () => {
      this.cs.consumeKeyEvent('ArrowRight');
      if (this.addNewState) {
        this.newTypeIndex = Math.min(this.newTypeIndex + 1, 4);
        return;
      }

      if (this.optionRow && this.valueSelected) {
        return;
      }

      return this.optionRow ? this.selectEnum() : this.selectKey();
    });

    this.cs.keyEventSubscribe('ArrowLeft', () => {
      this.cs.consumeKeyEvent('ArrowLeft');
      if (this.addNewState) {
        this.newTypeIndex = Math.max(0, this.newTypeIndex - 1);
        return;
      }

      if (this.keySelected) {
        this.keySelected = false;
        return;
      }
      if (this.optionRow && this.valueSelected) {
        this.deselect();
        return;
      }
    });

    this.cs.keyEventSubscribe('ArrowDown', () => {
      this.cs.consumeKeyEvent('ArrowDown');
      if (this.keySelected) {
        this.keySelected = false;
      }

      if (this.valueSelected) {
        if (this.currentSelectedData.options?.length) {
          this.selectedOption = Math.min(
            this.selectedOption + 1,
            this.currentSelectedData.options.length - 1,
          );
        }
        return;
      }

      // if (this.optionIndex !== -1) {
      //   const options = this.currentSelectedData.options;
      //   this.optionIndex = Math.min(this.optionIndex + 1, options.length);
      //   return;
      // }

      if (this.isAddItemSelected) {
        return;
      }
      // We do not need to maximize becasue of the isAddItemSelected flag
      this.selectedIndex = Math.min(
        this.localData.length - 1,
        this.selectedIndex + 1,
      );
    });

    this.cs.keyEventSubscribe('ArrowUp', () => {
      this.cs.consumeKeyEvent('ArrowUp');
      if (this.keySelected) {
        this.keySelected = false;
      }
      if (this.valueSelected) {
        if (this.currentSelectedData.options?.length) {
          this.selectedOption = Math.max(this.selectedOption - 1, 0);
        }
        return;
      }
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
    });

    if (!this.dataSchemeMode) {
      // --> // --> //
      this.localData = [];
      Object.keys(this.dataScheme).map(key => {
        this.localData[key] = this.data[key] || '';
      });
    } else {
      if (Array.isArray(this.data)) {
        this.arrayMode = true;
        this.localData = cloneDeep(this.data);
      } else {
        this.localData = Object.entries(this.data || {}).map(
          ([key, value]) => ({
            key,
            value,
          }),
        );
      }
    }
  }

  deselect() {
    this.keySelected = false;
    this.valueSelected = false;
  }

  selectKey() {
    this.keySelected = true;
    this.valueSelected = false;
  }

  selectValue() {
    this.keySelected = false;
    this.valueSelected = true;
  }

  selectEnum() {
    this.selectValue();
    this.selectedOption = 0;
  }

  addOption(key: string) {
    this.localData.find(({ key: k }) => k === key).options ||= [];
    this.localData.find(({ key: k }) => k === key).options.push('option');
  }

  removeOption(key: string, index: number) {
    this.localData.find(({ key: k }) => k === key).options.splice(index, 1);
  }

  optionChanged(key: string, index: number, value: string) {
    this.localData.find(({ key: k }) => k === key).options[index] = value;
    this.save();
  }

  ngOnInit(): void {
    // --> //
  }

  get data() {
    return this.dialogData.data;
  }

  get dataScheme() {
    return this.dialogData.dataScheme;
  }

  close() {
    this.dialogRef.close();
  }

  removeKey(keyToDelete: string) {
    this.localData = this.localData.filter(({ key }) => key !== keyToDelete);
    this.save();
  }

  newKey = 'key';
  newType = 'string';

  addNewField() {
    this.localData.push({
      key: 'key',
      value: 'string',
    });
    this.save();
  }

  keyChanged(oldKey: string, newKey: string) {
    const index = this.localData.findIndex(({ key }) => key === oldKey);
    this.localData[index].key = newKey;
    this.save();
  }

  selectorChanged(key: string, value: string) {
    this.localData.find(({ key: k }) => k === key).options =
      value === 'option' ? [] : null;
    this.save();
  }

  save(key?: string) {
    // this.cs.dialogData = this.localData;
  }

  getCurrentDataObject() {
    return this.localData.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
  }

  isThereEnum(key: string) {
    return !!this.localData.find(({ key: k }) => k === key).options;
  }
}
