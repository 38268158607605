import { Geometry, Graphics, Mesh, Shader } from 'pixi.js';
import { SVG } from './svg-element';
import { GeneralShape } from '../general/general-shape';
import { dir } from 'console';

export enum GradientDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Diagonal = 'diagonal',
}

function hexToRgb(hex) {
  // Remove the hash (#) if present
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
}

export class Rectanglelement extends SVG<{
  width: number;
  height: number;
  r?: number;
}> {
  get vertexShader() {
    return `
      attribute vec2 aVertexPosition;
      attribute vec2 aTextureCoord;
      varying vec2 vTextureCoord;
      
      uniform mat3 translationMatrix;
      uniform mat3 projectionMatrix;
      
      void main() {
        // Apply translation and projection matrices
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
        vTextureCoord = aTextureCoord;
      }
    `;
  }

  getFragmentShader(
    direction = GradientDirection.Horizontal,
    start: string,
    end: string,
  ) {
    let mixValue: string;

    switch (direction) {
      case GradientDirection.Diagonal:
        mixValue = '(vTextureCoord.x + vTextureCoord.y) / 2.0;';
        break;
      case GradientDirection.Horizontal:
        mixValue = 'vTextureCoord.x;';
        break;
      case GradientDirection.Vertical:
        mixValue = 'vTextureCoord.y;';
        break;
    }

    // const [er, eg, eb] = hexToRgb('#F1A200');
    // const [sr, sg, sb] = hexToRgb('#9B51E0');
    // const [sr, sg, sb] = hexToRgb('#FCF2DA');
    // const [er, eg, eb] = hexToRgb('#F0E5FA');
    const [sr, sg, sb] = hexToRgb(start);
    const [er, eg, eb] = hexToRgb(end);

    // -- //

    const opacity = 1;
    return `
      varying vec2 vTextureCoord;

      void main() {
        // Define the gradient colors
        vec4 startColor = vec4(${sr}.0 / 255.0, ${sg}.0 / 255.0, ${sb}.0 / 255.0, 1); // #9B51E0
        vec4 endColor = vec4(${er}.0 / 255.0, ${eg}.0 / 255.0, ${eb}.0 / 255.0, 1); // #F1A200

        // Mix colors based on the normalized coordinate
        //float mixValue = (vTextureCoord.x + vTextureCoord.y) / 2.0;
        float mixValue = ${mixValue};
        gl_FragColor = mix(startColor, endColor, mixValue) * vec4(1.0, 1.0, 1.0, ${opacity});
      }
    `;
  }

  get width() {
    return this.config.width;
  }

  get height() {
    return this.config.height;
  }

  get r() {
    return this.config.r || 0;
  }

  initElement() {
    this._element = new Graphics();
    if (this.config.gradient) {
      const shader = Shader.from(
        this.vertexShader,
        this.getFragmentShader(
          this.config.gradientDirection,
          this.fill || '#ff0000',
          this.config.gradientColor || '#ffffff',
        ),
      );
      const geometry = new Geometry()
        .addAttribute(
          'aVertexPosition', // the attribute name
          [
            0,
            0, // top-left
            1,
            0, // top-right
            1,
            1, // bottom-right
            0,
            1,
          ], // bottom-left
          2,
        ) // the size of the attribute (2D coordinates)
        .addAttribute(
          'aTextureCoord', // the attribute name
          [
            0,
            0, // top-left
            1,
            0, // top-right
            1,
            1, // bottom-right
            0,
            1,
          ], // bottom-left
          2,
        ) // the size of the attribute (texture coordinates)
        .addIndex([0, 1, 2, 0, 2, 3]);

      this.quad = new Mesh(geometry, shader);
      this.quad.scale.set(this.width, this.height);
      (this.parent as GeneralShape).container.addChild(this.quad);
      this.quad.mask = this.element;
    }
  }
  updateAttr() {
    super.updateAttr();

    if (this.x || this.y) {
      console.log('---- rectangle-moveTo ------', this.x, this.y);
      this.element.moveTo(this.x, this.y);
    }

    if (this.config.gradient) {
      this.element.beginFill(
        0,
        this.opacity === undefined && this.fillOpacity === undefined
          ? 1
          : this.opacity || this.fillOpacity,
      );
      this.element
        .drawRoundedRect(this.px, this.py, this.width, this.height, this.r)
        .endFill();

      if (this.opacity) {
        this.quad.alpha = this.opacity;
      }

      this.quad?.scale.set(this.width, this.height);
    } else {
      this.element
        .drawRoundedRect(this.px, this.py, this.width, this.height, this.r)
        .endFill();

      if (this.config.hole) {
        this.element.beginHole();
        this.element.drawRoundedRect(90, 170, 1248, 512, 6); // x, y, width, height, radius
        this.element.endHole();
      }
    }
  }
}
