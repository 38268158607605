import { Injectable, OnDestroy } from '@angular/core';
import { CanvasService } from '../services/canvas/canvas.service';
import { HttpService } from '../store/http/http.service';
import { cloneDeep, omit } from 'lodash';
import { Subscription } from 'rxjs';
import { ResourceData } from '../elements/resource/resource.types';
import { ImageDescriptor } from '../elements/resource/types/shape.type';
import { first } from 'rxjs/operators';
import { ImageShape } from './shape/image-shape';
import { Intervals } from './image-region-rectangle';
import { StoreService } from '../store/store.service';
import {
  RawImageShape,
  RegionData,
  RegionDescriptor,
} from './image-processing/raw-image-shape';
import { Region } from './image-processing/region';
import { Regex } from '../services/util/regex';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Pixel } from './image-processing/pixel';

export const SUBREGIONS_STORE_PATH = 'subregions';
export const SUBREGIONS_URL_PATH = 'editor/subregion';

export interface SubregionDescriptor {
  intervals: number[][];
  regions: Record<string, RegionDescriptor>;
  xMax: number;
  yMax: number;
}

export interface UpdateSession {
  delete: Record<string, RegionDescriptor>;
  update: Record<string, RegionDescriptor>;
  add: Record<string, RegionDescriptor>;
}

@Injectable()
export class ImageService implements OnDestroy {
  openImageSub: Subscription;
  openImageNatvieSub: Subscription;
  subregionSub: Subscription;

  currentIntervals: Intervals;

  images = {};

  show = false;

  imageData: ResourceData<ImageDescriptor>;

  isSubregionOpened = false;

  mergeMode = false;

  imageShape: ImageShape;

  overlappingRegions = {};

  selectedMode: 'original' | 'svg' | 'shape';

  addOverlappingRegion(region1: Region, region2: Region) {
    const [id1, id2] = [region1, region2]
      .map(r => r.id)
      .sort((id1, id2) => {
        if (id1 < id2) return -1;
        else if (id1 > id2) return 1;
        return 0;
      });

    this.overlappingRegions[id1] ||= {};
    this.overlappingRegions[id1][id2] = true;
  }

  async smoothBoundaries() {
    // for (const id of Object.keys(this.overlappingRegions)) {
    //   this.regionStore[id].select();
    //   for (const idInner of Object.keys(this.overlappingRegions[id])) {
    //     this.regionStore[idInner].select();
    //   }
    //   await this.mergeRegions();
    //   return;
    // }

    const intervals = this.currentSubregionData.literals.intervals;
    const s3Id = this.imageData.literals.descriptor.s3Id;

    await this.http.postPromise('smooth-boundaries', {
      s3Id,
      intervals,
    });

    console.log('smooth', intervals, s3Id);
  }

  get subregions$() {
    return this.db.get<any[]>('subregions');
  }

  get imageRoute() {
    return ['img', Regex.after('#', this.imageData.IRI)];
  }

  get regions() {
    return this.currentRegionShape?.regions || [];
  }

  regionStore: Record<string, Region> = {};

  currentSubregionDescriptor: SubregionDescriptor;

  deleteMode = false;

  edgeSelectMode = false;

  currentShape: ImageShape;

  constructor(
    readonly cs: CanvasService,
    private readonly db: StoreService,
    private readonly http: HttpService,
    private location?: Location,
    private router?: Router,
  ) {
    const url = this.location.path();

    this.cs.generalEventSubscribe('image-selected', imageShape => {
      this.imageShape = imageShape;
      this.imageData = imageShape.data;
      this.selectedMode = 'original';
    });

    this.cs.keyEventSubscribe('m', async () => {
      if (this.mergeIsEnabled) {
        await this.mergeRegions();
      }
    });
    // this.cs.keyEventSubscribe('u', async () => {
    //   if (Object.keys(this.selectedRegions).length == 1) {
    //     await Object.values(this.selectedRegions)[0].save(this.http);
    //   }
    // });
    this.keyEventSubscribe('r', async () => await this.resolve());
    this.keyEventSubscribe('q', () => this.undo());
    this.keyEventSubscribe('v', () => this.resolveUnselected());
    this.keyEventSubscribe('e', () => this.resolveEdges());

    this.keyEventSubscribe('s', () => this.saveSubregionDescriptor());
    this.keyEventSubscribe('d', () => this.deleteRegions());
    // this.cs.keyEventSubscribe('e', () => {
    //   if (this.edgeSelectMode) {
    //     this.edgeSelectMode = false;
    //     this.regions.map(r => r.pathElement.show());
    //   } else {
    //     this.edgeSelectMode = true;
    //     this.regions.map(r => r.pathElement?.hide());
    //   }
    // });
    if (url.startsWith('/img')) {
      this.cs.mode = 'image';

      setTimeout(async () => {
        const uuidPath = Regex.after('img/', url);
        const uuid = uuidPath.includes('/') ? uuidPath.split('/')[0] : uuidPath;
        const img = await this.http.getResourceByUUID<ImageDescriptor>(
          '/editor/img',
          uuid,
        );
        if (!img) {
          // TODO - handle not found error
        }
        this.imageData = img;
        // -- // -- //

        if (url.includes('/subregion')) {
          const uuidPath = Regex.after('subregion/', url);
          const uuid = uuidPath.includes('/')
            ? uuidPath.split('/')[0]
            : uuidPath;
          this.currentSubregionData = await this.http.getResourceByUUID(
            '/' + SUBREGIONS_URL_PATH,
            uuid,
          );

          if (!this.currentSubregionData) {
            // TODO - handle
            return;
          }
          this.openSubregion(this.currentSubregionData, false);
        } else {
          await this.openImage(img);
        }

        //
      }, 1);
    }

    this.openImageSub = this.cs.generalEventSubscribe(
      'open-image',
      async (item: ResourceData<ImageDescriptor>) => this.openImage(item),
    );

    this.openImageSub = this.cs.generalEventSubscribe(
      'open-image-native',
      async ({
        uuid,
        remainingPath,
      }: {
        uuid: string;
        remainingPath: string;
      }) => {
        console.log('------------', uuid, remainingPath);
      },
    );

    this.subregionSub = this.cs.generalEventSubscribe(
      'set-interval',
      async (intervals: Intervals) => {
        console.log('intervals', intervals[0], intervals[1]);
        this.currentIntervals = intervals;
      },
    );
  }

  async keyEventSubscribe(key: string, handler: () => void) {
    this.cs.keyEventSubscribe(key, () => {
      if (this.cs.isImageProcessingMode) {
        handler();
      }
    });
  }

  async openImage(item: ResourceData<ImageDescriptor>) {
    this.imageData = item;
    this.show = true;

    this.http.get(SUBREGIONS_URL_PATH, SUBREGIONS_STORE_PATH, {
      resourceData: {
        relationships: {
          image: {
            IRI: item.IRI,
          },
        },
      },
    });

    this.startLoading();
    // this.imageShape = new ImageShape(this.cs, item);
    this.cs.mode = 'image';
  }

  ngOnDestroy(): void {
    this.openImageSub.unsubscribe();
    this.subregionSub.unsubscribe();
  }

  startProcessing() {
    this.http.post(`region-init`, {
      IRI: this.imageData.IRI,
    });
  }

  async deleteSubregion(subRegion: ResourceData) {
    console.log('delete-sub-region', subRegion);
    this.http.delete(SUBREGIONS_URL_PATH, subRegion, SUBREGIONS_STORE_PATH);
  }

  async addSubImage() {
    this.http.post(
      'add-subregion',
      {
        image: omit(this.imageData, 'relationships'),
        intervals: this.currentIntervals,
      },
      SUBREGIONS_STORE_PATH,
    );
  }

  addSubregionSelectorRectange() {
    this.imageShape.addRegionRectangle();
  }

  async fetchImage(key: string): Promise<ResourceData<ImageDescriptor>> {
    if (!this.images[key]) {
      const response = await this.http
        .get<ImageDescriptor>('editor/img', '_', {
          resourceData: {
            literals: { label: `^${key}$` },
          },
        })
        .pipe(first(p => !!p))
        .toPromise();

      this.images[key] = response.data[0];
    }
    return this.images[key];
  }

  currentSubregionData: ResourceData;
  currentRegionShape: RawImageShape;

  async closeSubregion() {
    this.isSubregionOpened = false;
    //
    this.router.navigate(this.imageRoute);
    if (!this.imageShape) {
      await this.openImage(this.imageData);
    } else {
      this.imageShape.removeRegionRectange();

      this.isSubregionOpened = false;
    }
  }

  rawImageStore: Record<string, RawImageShape> = {};

  startLoading() {
    this.db.set('image-is-loading', true);
  }

  endLoading() {
    this.db.reset('image-is-loading');
  }

  async openSubregion(resourceData: ResourceData, doRouting = true) {
    // this.rou; // -- //

    if (doRouting) {
      this.router.navigate([
        ...this.imageRoute,
        'subregion',
        Regex.after('#', resourceData.IRI),
      ]);
    }

    console.log('open-subregion', resourceData);

    this.startLoading();

    this.currentSubregionData = resourceData;
    this.isSubregionOpened = true;

    const intervals = resourceData.literals.intervals;
    const s3Id = this.imageData.literals.descriptor.s3Id;
    const response = (await this.http
      .get<ImageDescriptor>(`get-subimage`, '_', {
        s3Id,
        intervals,
      })
      .pipe(first(p => !!p))
      .toPromise()) as any;
    console.log('sub-image', response.data);
    const shapeData = cloneDeep(response.data);

    this.currentSubregionDescriptor = shapeData;

    const _region = shapeData.regions;

    let pixelCnt = 0;
    for (const region of Object.values(_region)) {
      pixelCnt += Object.keys((region as any).pixels).length;
    }

    const interval = shapeData.intervals;

    const [xx, yy] = interval;
    this.cs.xs = xx[0];
    this.cs.ye = yy[1];

    this.cs.xMax = shapeData.xMax;
    this.cs.yMax = shapeData.yMax;

    // TODO - migrate
    // this.currentRegionShape = new RawImageShape(
    //   this.sh,
    //   interval,
    //   Object.values(_region) as RegionData
    // );

    console.log(
      'current-region-shape-has-been-opened',
      Object.keys(this.currentRegionShape.pixelStore).length,
    );

    this.rawImageStore[resourceData.IRI] = this.currentRegionShape;

    // this.cs.openShape(this.currentRegionShape);

    this.endLoading();

    console.log('overlapping-regions', this.overlappingRegions);
  }

  selectedRegions: Record<string, Region> = {};
  selectedPixels: Record<string, Pixel> = {};

  get selectedRegionsLength() {
    return Object.keys(this.selectedRegions).length;
  }

  get selectedPixelsLength() {
    return Object.keys(this.selectedPixels).length;
  }

  get mergeIsEnabled() {
    if (this.selectedRegionsLength >= 2) {
      return true;
    }

    if (this.selectedRegionsLength == 1 && this.selectedPixelsLength > 0) {
      return true;
    }

    return false;
  }

  refreshRegions() {
    this.regions.map(r => r.refresh());
  }

  async resolveEdges() {
    this.regions.map(r => r.resolveEdges());
    await this.saveChangedRegions();
  }

  async resolveUnselected() {
    for (const region of this.regions) {
      if (!region.selected) {
        region.resolve(true);
      }
    }
    for (const region of this.regions) {
      if (!region.selected) {
        region.remove();
        region.changed = false;
      }
    }
    await this.saveChangedRegions();
  }

  async resolve() {
    if (this.selectedRegionsLength == 1) {
      const [r1] = Object.values(this.selectedRegions);
      r1.resolve();
      await this.saveChangedRegions();
      this.clearAllSelection();
      return;
    }

    if (this.selectedPixelsLength) {
      Object.values(this.selectedPixels).map(p => p.reassign());
      this.selectedPixels = {};
      await this.saveChangedRegions();
    }
  }

  async saveChangedRegions() {
    await this.saveRegions(this.regions.filter(r => r.changed));
  }

  get oneSelectedRegion() {
    return Object.values(this.selectedRegions)[0];
  }

  currentSession: UpdateSession;
  saveSessions: Array<UpdateSession> = [];

  startSession() {
    this.currentSession = {
      delete: {},
      add: {},
      update: {},
    };
  }

  endSession() {
    this.saveSessions.push(this.currentSession);
    this.currentSession = null;
  }

  copy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  get onlyOneSelectedRegion() {
    return Object.values(this.selectedRegions)[0];
  }

  async mergeRegions() {
    if (this.selectedRegionsLength == 1 && this.selectedPixelsLength > 0) {
      console.log('--------------- merge -------------------');
      const r1 = this.oneSelectedRegion;
      r1.mergePixelsIntoMe(Object.values(this.selectedPixels));
      this.selectedPixels = {};
      await this.saveChangedRegions();
      return;
    }

    if (this.selectedRegionsLength < 2) {
      console.warn(`At least two regions must be selected for merge!`);
      return;
    }

    const baseRegion = Object.values(this.selectedRegions)[0];
    delete this.selectedRegions[baseRegion.id];

    // this.startSession();
    // this.currentSession.update[baseRegion.id] = this.copy(
    //   baseRegion.descriptor
    // );

    for (const r of Object.values(this.selectedRegions)) {
      baseRegion.mergeRegionIntoMe(r);
    }
    await this.saveRegions([baseRegion]);

    // this.endSession();
  }

  async saveRegions(regions: Region[]) {
    console.log('save-regions', regions.length);

    const pixels = regions.map(r => r.getBoundaryPixels());
    const response = await this.http.postPromise('new-boundaries-n', pixels);

    regions.map((region, index) =>
      region.updateBoundariesAndPixels(pixels[index], response.data[index]),
    );
  }

  regionToBeSaved = false;

  regionsToDelete = {};
  regionsToSet: Record<string, RegionDescriptor> = {};

  selectRegion(region: Region) {
    if (!this.cs.isPressed('Shift')) {
      this.clearAllSelection();
    }
    this.selectedRegions[region.id] = region;
  }

  clearAllSelection() {
    for (const region of Object.values(this.selectedRegions)) {
      region.deselect();
    }
    this.selectedRegions = {};
    for (const pixel of Object.values(this.selectedPixels)) {
      pixel.deselect();
    }
    this.selectedPixels = {};
  }

  deleteRegion(region: Region) {
    // if (this.currentSession) {
    //   this.currentSession.delete[region.id] = region.descriptor;
    // }

    delete this.regionStore[region.id];
    delete this.selectedRegions[region.id];
    this.regionsToDelete[region.id] = true;
    this.regionToBeSaved = true;

    if (this.regionsToSet[region.id]) {
      delete this.regionsToSet[region.id];
    }

    console.log('delete-region', region.id);
    console.log('regionsToDelete', this.regionsToDelete);
  }

  updateRegion(region: Region) {
    // if (this.currentSession) {
    //   this.currentSession.update[region.id] = region.descriptor;
    // }
    this.regionsToSet[region.id] = region.descriptor;
    this.regionToBeSaved = true;

    console.log('updateRegion', region.id);
    console.log('regionsToSet', this.regionsToSet);
  }

  hidden = false;
  async hideShowSelectedRegions() {
    if (this.hidden) {
      Object.values(this.selectedRegions).map(r => r.show());
    } else {
      Object.values(this.selectedRegions).map(r => r.hide());
    }
    this.hidden = !this.hidden;
  }

  async saveSubregionDescriptor() {
    console.log('dave-sub', this.selectedRegions);
    await this.http.postPromise('post-subimage', {
      s3Id: this.imageData.literals.descriptor.s3Id,
      intervals: JSON.stringify(this.currentSubregionData.literals.intervals),
      regionsToSet: this.regionsToSet,
      regionsToDelete: this.regionsToDelete,
    });
    this.selectedRegions = {};
    this.regionToBeSaved = false;
    this.regionsToDelete = {};
  }

  setPixelMode() {
    //
  }

  isSelected(object: Pixel | Region) {
    if (object instanceof Pixel) {
      return !!this.selectedPixels[object.key];
    }
    if (object instanceof Region) {
      return !!this.selectedRegions[object.id];
    }
  }

  selectPixel(pixel: Pixel) {
    this.selectedPixels[pixel.key] = pixel;
  }

  deselectPixel(pixel: Pixel) {
    delete this.selectedPixels[pixel.key];
  }

  splitRegionMode = false;

  setSplitRegionMode() {
    if (!this.splitRegionMode) {
      this.splitRegionMode = true;
    }
  }

  deleteRegions() {
    Object.values(this.selectedRegions).map(r => r.remove(true));
    this.selectedRegions = {};
  }

  undo() {
    const latestSession = this.saveSessions.pop();

    // -- // -- // -- // -- //

    if (!latestSession) {
      return;
    }

    for (const descriptor of Object.values(latestSession.update)) {
      console.log('update-region', Object.values(descriptor.pixels).length);
      const region = this.currentRegionShape.updateRegion(descriptor);
      region.discoverInnerPixels();
      // region.setBoundary();
      region.initPathShape();
    }

    for (const descriptor of Object.values(latestSession.delete)) {
      console.log('addNewRegion', Object.values(descriptor.pixels).length);
      const region = this.currentRegionShape.addRegion(descriptor);
      region.discoverInnerPixels();
      // region.setBoundary();
      region.initPathShape();
    }
  }

  flipPathElement() {
    this.regions.map(region => region.flipPathElements());
  }

  async loadBoundaries() {
    const intervals = this.currentSubregionData.literals.intervals;
    const s3Id = this.imageData.literals.descriptor.s3Id;
    const response = await this.http.getPromise('/load-curve-boundaries', {
      s3Id,
      intervals,
    });
    console.log('response', response);
  }

  async startSVGProcessing() {
    // -- // -- //
    const response = await this.http.postPromise(
      'vectorize',
      this.cs.previewShape.resourceData,
    );
    console.log('yoo', response);
  }
}
