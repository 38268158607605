<div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign=" center">
  <input
    type="number"
    [(ngModel)]="animation.cnt"
    matTooltip="Gap"
    (keyup)="change()"
  />
  <input
    type="number"
    [(ngModel)]="animation.speed"
    matTooltip="Speed"
    (keyup)="change()"
  />

  <div
    matTooltip="Record items"
    [class.blink]="isBlink"
    class="test-x"
    (click)="recordItems()"
  >
    <nw-icon type="change_history" [size]="12"></nw-icon>
  </div>
</div>
