<div
  class="path-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Path fill"
    [attributeState]="attributeState$ | async"
    key="path-fill"
    [initialValue]="{ start: 0, end: -1, reverse: false }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-6"
  >
    <div class="flex-row space-between">
      <nw-number-input
        key="start"
        [value]="attributeState.value['start']"
        (changed)="updateSubAttributeValue('start', $event)"
      ></nw-number-input>
      <nw-number-input
        key="end"
        [value]="attributeState.value['end']"
        (changed)="updateSubAttributeValue('end', $event)"
      ></nw-number-input>
    </div>
    <div>
      <nw-boolean-input
        key="reverse"
        [value]="attributeState.value['reverse']"
        (changed)="updateSubAttributeValue('reverse', $event)"
      ></nw-boolean-input>
    </div>
  </div>
</div>
