import { NgModule } from '@angular/core';
import { ProjectLanguagesEditComponent } from './project-languages-edit/project-languages-edit.component';
import { UtilComponentModule } from '../../components/util/util-component.module';
import { MaterialModule } from '../../material.module';
import { FormsModule } from '@angular/forms';
import { ColorPaletteEditComponent } from './color-palette-edit/color-palette-edit.component';
import { ProjectUsersComponent } from './project-users/project-users.component';

@NgModule({
  imports: [FormsModule, MaterialModule, UtilComponentModule],
  declarations: [
    ProjectLanguagesEditComponent,
    ColorPaletteEditComponent,
    ProjectUsersComponent,
  ],
  exports: [
    ProjectLanguagesEditComponent,
    ColorPaletteEditComponent,
    ProjectUsersComponent,
  ],
})
export class ProjectEditModule {}
