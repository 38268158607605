<div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="8px" class="organisation-selector-component">
  <div class="inner-container" fxLayout="column" fxLayoutGap="12px">
    <div class="title-container" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="label">Organisations</div>
      <div class="add pointer" (click)="addOrganisation()">Add</div>
    </div>
    <ng-container *ngIf="(loading$ | async) === false; else loading">
      <div *ngFor="let organisation of organisations$ | async" class="organisation-item" fxLayout="row" fxFlex="50"
        [routerLink]="['/', 'organisations', organisation.id]">
        <div>
          {{ organisation.name }}
          <span *ngIf="organisation.roles[myUserId$ | async]?.type as role">[{{ role }}]</span>
        </div>
      </div>
      <div *ngIf="(organisations$ | async).length === 0">
        Empty
      </div>
    </ng-container>
    <ng-template #loading>
      <div>
        Loading
      </div>
    </ng-template>
  </div>
  <div *ngIf="newOrganisationPanel" class="new-organisation-panel-container" (click)="resetNewPanel($event)">
    <div class="new-organisation-panel">
      <div class="input-header">
        <div class="form-name">New Organisation</div>
        <nw-icon (click)="resetNewPanel()" type="close" [size]="10" [outlined]="true"></nw-icon>
      </div>
      <div>
        <label for="new-organisation-name">Name</label>
        <input id="new-organisation-name" [(ngModel)]="newOrganisationName" />
      </div>
      <button [disabled]="!newOrganisationName" (click)="submitNewOrganisation()">Add</button>
    </div>
  </div>
</div>