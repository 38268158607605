import { SVG } from './svg-element';
import { BaseVector } from '../../../../elements/base/vector/vector';
import { CurveHoverItem } from '../path-shape/path-shape.types';
import { cloneDeep } from 'lodash';
import { Graphics } from 'pixi.js';

export class CurveCoverElement extends SVG<{
  hoverItem: CurveHoverItem;
}> {
  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }

  initElement() {
    this._element = new Graphics();
  }
  updateAttr() {
    super.updateAttr();

    const { x: px, y: py } = cloneDeep(this.position);
    // this.element.moveTo(px, py);
    const [_x, _y] = [px, py];

    const { x, y, bx, by, cx, cy, height } = this.config
      .hoverItem as CurveHoverItem;

    // console.log('cc', { x, y, bx, by, cx, cy, height });

    // -- //
    let ab = this.getAngle(bx, by);
    const angle = this.getAngle(x, y);
    let cc: boolean;

    if (!bx && !by) {
      ab = this.getAngle(cx, cy);
    }

    if (angle < ab) {
      cc = ab - angle < Math.PI;
      // console.log('angle < ab', ab - angle);
    } else {
      // console.log('angle > ab', angle - ab);
      cc = angle - ab > Math.PI;
    }

    const vb = new BaseVector(!bx && !by ? [cx, cy] : [bx, by]);
    // -- // -- //
    const vc = new BaseVector(!cx && !cy ? [bx, by] : [cx, cy]);

    if (cc) {
      vb.rotate(Math.PI / 2);
      vc.rotate(-Math.PI / 2);
    } else {
      vb.rotate(-Math.PI / 2);
      vc.rotate(Math.PI / 2);
    }
    vb.reScale(height / 2);
    vc.reScale(height / 2);

    const { x: bDx, y: bDy } = vb.end;
    const { x: cDx, y: cDy } = vc.end;

    this.element.lineTo(px + bDx, py + bDy);

    // const vc = new BaseVector([cx, cy]);
    // vc.rotate(-Math.PI / 2);
    // vc.reScale(height / 2);

    // console.log('v.end', vc.end);

    // const { x: cDx, y: cDy } = vc.end;

    // console.log({ vbx: bDx, vby: bDy }); //

    const l = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    const ratio = (l + height) / l;
    const _vb = new BaseVector([bx, by]).scale(ratio);
    const _vc = new BaseVector([cx, cy]).scale(ratio);

    this.element.bezierCurveTo(
      px + bDx + _vb.x,
      py + bDy + _vb.y,
      px + cDx + x + _vc.x,
      py + cDy + y + _vc.y,
      px + x + cDx,
      py + y + cDy,
    );

    this.element.lineTo(px + x, py + y);
    this.element.bezierCurveTo(
      px + x + cx,
      py + y + cy,
      px + bx,
      py + by,
      px,
      py,
    );

    // [_x, _y] = [px + x + cDx, py + y + cDy]; //

    // this.element.lineTo(_x - 2 * cDx, _y - 2 * cDy);

    // _x = px + x;
    // _y = py + y;

    // this.element.lineTo(_x, _y);
    // // -- // -- //

    // this.element.bezierCurveTo(
    //   _x + cx,
    //   _y + cy,
    //   _x - x + bx,
    //   _y - y + by,
    //   _x - cDx,
    //   _y - cDy,
    // );

    this.element.closePath();
    this.element.endFill();
  }
}
