<div class="text-edit-component">
  <input
    type="text"
    [(ngModel)]="text"
    (keyup)="$event.stopPropagation()"
    (keydown)="$event.stopPropagation()"
  />
  <div class="state-item icon">
    <nw-icon
      type="check"
      [size]="12"
      (click)="saveHandler(); $event.stopPropagation()"
    ></nw-icon>
  </div>
  <div class="state-item icon">
    <nw-icon
      type="close"
      [size]="12"
      (click)="discardHandler(); $event.stopPropagation()"
    ></nw-icon>
  </div>
</div>
