import { PointData } from '../util/vector-service';

export const SCALE = 0.2;
export const SCALE_IN = 1 + SCALE;
export const SCALE_OUT = 1 / SCALE_IN;

export interface Dimension {
  scale: number;
  x: number;
  y: number;
}

export interface ClickEvent {
  event: any;
  p: PointData;
  cs?: {
    moving: boolean;
  };
}

export interface Video {
  id: string;
  scenes: Scene[];
}

export type ShapeAlias = string;
export type ActionID = string;
export interface Scene {
  shape: ShapeAlias;
  actions: ActionID[];
}
