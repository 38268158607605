import {
  DataBag,
  DataBagAction,
  ArrayOperationType,
  DatabagActionDescriptor,
} from './reducer';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralAction } from './actions';
import { Action, Store, select } from '@ngrx/store';
import { getDataBagValue, getById, getDataBagValueAsArray } from './selectors';
import { first } from 'rxjs/operators';
import { ResourceData } from '../elements/resource/resource.types';

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

@Injectable()
export class StoreService {
  isTherePendingRequest = false;
  pendingRequests: GeneralAction[] = [];

  constructor(private store: Store<{ databag: DataBag }>) {}

  setLoading(path: string) {
    this.set(path + '-loading', true);
  }

  resetLoading(path: string) {
    this.set(path + '-loading', false);
  }

  componentMessage(component: string, body: any) {
    let bodyToSend: any;
    if (typeof body == 'object') {
      bodyToSend = JSON.parse(JSON.stringify(body));
    } else {
      bodyToSend = body;
    }

    this.set('ComponentMessage', {
      id: this.random,
      component,
      body: bodyToSend,
    });
  }

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  openFileDialog(cb: (data: ResourceData) => void) {}

  pushElementToArray(path: string, item: any) {
    this.store.dispatch(
      new DataBagAction({
        path,
        arrayOperation: ArrayOperationType.Push,
        value: item,
      })
    );
  }

  setArrayElement(path: string, item: any) {
    if (item.IRI) {
      this.set(item.IRI, item);
    }

    this.store.dispatch(
      new DataBagAction({
        path,
        arrayOperation: ArrayOperationType.Set,
        value: item,
      })
    );
  }

  removeArrayElement(path: string, IRI: string) {
    this.store.dispatch(
      new DataBagAction({
        path,
        arrayOperation: ArrayOperationType.Set,
        deleteKey: IRI,
      })
    );
  }

  storeAction(action: DatabagActionDescriptor, data?: any) {
    this.set(action.path, this.substituteInObject(action.value, data));
  }

  set<T>(path: string, value: T) {
    if (typeof value == 'object') {
      // console.log('db-set', value);
    }
    this.store.dispatch(new DataBagAction({ path, value }));
  }

  reset<T>(path: string) {
    this.store.dispatch(new DataBagAction({ path, value: false }));
  }

  _reset<T>(path: string) {
    this.store.dispatch(new DataBagAction({ path, value: false }));
  }

  flip(path: string) {
    this.store.dispatch(new DataBagAction({ path, flip: true }));
  }

  get<T extends {}>(path: string): Observable<T> {
    return this.store.pipe(select(getDataBagValue, { path }));
  }

  getOnce<T extends {}>(path: string): Observable<T> {
    return this.get<T>(path).pipe(first(p => !!p));
  }

  async getSync<T extends any>(path: string) {
    return new Promise<T>(resolve =>
      this.get(path).subscribe((val: T) => {
        if (val) {
          resolve(val);
        }
      })
    );
  }

  getAsArray<T>(path: string): Observable<T> {
    return this.store.pipe(select(getDataBagValueAsArray, { path }));
  }

  push<T>(path: string, value: T) {
    this.store.dispatch(
      new DataBagAction({
        path,
        value,
        arrayOperation: ArrayOperationType.Push,
      })
    );
  }

  getByID(path: string, id: string) {
    return this.store.pipe(select(getById, { path, id }));
  }

  getImages() {}

  getParams(params: Record<string, any>): HttpParams {
    const httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams.set(key, params[key]);
    });
    return httpParams;
  }

  substituteInObject(object: Record<string, any>, data: any) {
    if (!data) {
      return object;
    }
    Object.entries(object || {}).map(([key, value]) => {
      if (typeof value === 'object') {
        this.substituteInObject(value, data);
      }
      if (value === '${data}') {
        object[key] = data;
      }
    });
    return object;
  }

  private get random() {
    return Math.random().toString().slice(2);
  }
}
