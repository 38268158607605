<div
  fxLayout="column"
  fxLayoutAlign=" center"
  fxLayoutGap="8px"
  class="user-selector-component"
>
  <div class="inner-container" fxLayout="column" fxLayoutGap="12px">
    <!-- <div
      class="title-container"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="label">Users</div>
      <div
        *ngIf="
          editable &&
          ['owner', 'admin', 'editor'].includes(myOrganisationRole$ | async)
        "
        class="add pointer"
        (click)="addUser()"
      >
        Add
      </div>
    </div> -->
    <ng-container *ngIf="(loading$ | async) === false; else loading">
      <div
        class="user-item blink"
        *ngIf="newUserIsLoading$ | async"
        fxLayout="row"
      >
        New user is being created...
      </div>
      <div
        *ngFor="let user of users$ | async"
        class="user-item flex-row space-between"
        (mouseenter)="selectUser(user)"
        (mouseleave)="deselectUser()"
      >
        <!-- [routerLink]="['/', 'users', user.id]" -->
        <ng-container [ngSwitch]="getModeByUser(user)">
          <div
            *ngSwitchCase="'delete-confirm'"
            class="flex-row w-100 space-between"
          >
            <div class="fs-14">Are you sure you want to delete this user?</div>
            <div class="flex-row gap-4">
              <nw-icon
                type="check"
                [size]="18"
                (click)="deleteUser(user)"
              ></nw-icon>
              <nw-icon
                type="close"
                [size]="18"
                (click)="deleteMode = null"
              ></nw-icon>
            </div>
          </div>
          <div *ngSwitchCase="'user-being-deleted'" class="blink">
            User is being deleted
          </div>

          <div *ngSwitchCase="'selected'" class="flex-row space-between w-100">
            <div class="flex-row gap-4">
              <div>
                {{ user.firstName + ' ' + user.lastName }}
              </div>
              <div class="fs-14 italic">- {{ user.email }}</div>
            </div>
            <div class="flex-row gap-2">
              <!-- <div
                [style.width.px]="22"
                (mouseenter)="currentEditIconSize = 19"
                (mouseleave)="currentEditIconSize = 17"
              >
                <nw-icon
                  type="edit"
                  [size]="currentEditIconSize"
                  [outlined]="true"
                ></nw-icon>
              </div> -->
              <div
                *ngIf="isDeleteEnabled(user)"
                [style.width.px]="22"
                (mouseenter)="currentDeleteIconSize = 19"
                (mouseleave)="currentDeleteIconSize = 17"
              >
                <nw-icon
                  type="delete"
                  [size]="currentDeleteIconSize"
                  [outlined]="true"
                  (click)="startDeleteMode(user)"
                ></nw-icon>
              </div>
            </div>
          </div>
          <div
            *ngSwitchDefault
            (mouseenter)="selectUser(user)"
            (mouseleave)="deselectUser()"
            class="flex-row space-between w-100"
          >
            <div>{{ user.firstName + ' ' + user.lastName }}</div>
            <div *ngIf="hasRole(user)" class="fs-14">{{ user.role.type }}</div>
          </div>
        </ng-container>
        <!-- <div>
          <div
            *ngIf="selectedUser == user; else notSelected"
            class="flex-row gap-8"
          >
            <div
              (mouseenter)="currentEditIconSize = 20"
              (mouseleave)="currentEditIconSize = 17"
            >
              <nw-icon
                type="edit"
                [size]="currentEditIconSize"
                [outlined]="true"
                (click)="startDeleteMode(user)"
              ></nw-icon>
            </div>
            <div
              (mouseenter)="currentDeleteIconSize = 20"
              (mouseleave)="currentDeleteIconSize = 17"
            >
              <nw-icon
                type="delete"
                [size]="currentDeleteIconSize"
                [outlined]="true"
                (click)="deleteMode = user"
              ></nw-icon>
            </div>
          </div>
          <ng-template #notSelected>
            <div *ngIf="hasRole(user)">{{ user.role.type }}</div>
          </ng-template>
        </div> -->
      </div>
      <ng-container *ngIf="!(newUserIsLoading$ | async)">
        <div
          *ngIf="
            editable &&
            ['owner', 'admin', 'editor'].includes(myOrganisationRole$ | async)
          "
          class="add pointer blue-border"
          (click)="addUser()"
        >
          +
        </div>
      </ng-container>
      <div *ngIf="(users$ | async).length === 0">Empty</div>
    </ng-container>
    <ng-template #loading>
      <div>Loading</div>
    </ng-template>
  </div>
  <div *ngIf="newUserPanel" class="new-user-panel-container">
    <div class="new-user-panel">
      <!-- <div *ngIf="users$ | async as organisationUsers">
        <h3>Select</h3>
        <select id="assign-existing-user">
          <ng-container *ngFor="let user of allUser$ | async">
            <option
              *ngIf="!includesById(organisationUsers, 'id', user.id)"
              [value]="user.id"
            >
              {{ user.firstName }} {{ user.lastName }} - {{ user.email }}
            </option>
          </ng-container>
        </select>
      </div> -->
      <div class="new-user-details">
        <div class="fs-18 pv-8">New User</div>
        <div class="flex-row gap-4">
          <input placeholder="firstname" [(ngModel)]="newUser.firstName" />
          <input placeholder="lastname" [(ngModel)]="newUser.lastName" />
        </div>
        <input placeholder="email" type="email" [(ngModel)]="newUser.email" />
      </div>
      <div>
        <label for="new-user-role">Role</label>
        <select [(ngModel)]="selectedRole" id="new-user-role">
          <option value="admin">Admin</option>
          <option value="editor">Editor</option>
          <option value="viewer">Viewer</option>
        </select>
      </div>
      <div class="flex-row gap-4">
        <button
          [disabled]="!isUserDataFilled(this.newUser)"
          (click)="submitNewUser()"
        >
          Add
        </button>
        <button placeholder="super admin" (click)="newUserPanel = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
