import { Observable, of } from 'rxjs';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { SVGAppActionTypes, GeneralAction } from '../actions';
import { DataBagActionType } from '../reducer';
import * as _ from 'lodash';

@Injectable()
export class StoreEffects {
  generalActionEffect: Observable<{
    type: string;
    descriptor: Record<string, any>;
  }>;

  private baseHandler(action: GeneralAction) {
    return this.http.requestCall(action.request).pipe(
      map((result: any) => {
        return this.requestHandler(action, result);
      }),
      catchError(e => {
        const error = e();
        return of(this.requestHandler(action, null, error.error.message));
      })
    );
  }

  constructor(private actions$: Actions, private http: HttpService) {
    this.generalActionEffect = createEffect(() =>
      this.actions$.pipe(
        ofType(SVGAppActionTypes.General),
        mergeMap((action: GeneralAction) => this.baseHandler(action))
      )
    );
  }

  requestHandler(
    action: GeneralAction,
    result: any,
    error?: any
  ): { type: string; descriptor: Record<string, any> } {
    const descriptor = this.copy(action.descriptor);
    descriptor.value = descriptor.value || '${data}';

    const object = {
      type: DataBagActionType,
      descriptor: {
        error,
        id: action.id,
        path: descriptor.path,
        ...this.substituteInObject(descriptor || {}, result),
      },
    };
    return object;
  }

  copy(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  substituteInObject(object: Record<string, any>, data: any) {
    Object.entries(object).map(([key, value]) => {
      if (typeof value === 'object') {
        this.substituteInObject(value, data);
      }
      if (value === '${data}') {
        object[key] = data;
      }
    });
    return object;
  }
}
