import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

const SHIFT = {
  p: 138,
  t: 139,
  e: 140,
  v: 141,
  c: 'constraint',
  arrowup: 1,
  arrowdown: 2,
  arrowleft: 3,
  arrowright: 4,
  z: 5,
  h: 6,
  j: 7,
  u: 8,
  i: 9,
  o: 10,
};

export interface KeyEvent {
  key: string;
  code: string;
}

@Injectable()
export class KeyHandlerService {
  keyEvents: Record<string, Subject<any>> = {};

  private ups: any;
  private downs: any;

  comboState: any = {};

  @Output() event: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  private keyState = {
    Control: false,
    h: false,
  };

  keyEventSubscribe(key: string, handler: () => void) {
    this.keyEvents[key] = this.keyEvents[key] || new Subject();
    return this.keyEvents[key].subscribe(() => handler());
  }

  get shift() {
    return this.comboState.shiftright || this.comboState.shiftleft;
  }

  get space() {
    return this.comboState.space;
  }

  onClickEvent(cb: (message?: Event) => any): Subscription {
    return this.clickEvent.subscribe(cb);
  }

  onEvent(cb: (message?: KeyEvent) => any): Subscription {
    return this.event.subscribe(cb);
  }

  click() {
    this.clickEvent.emit();
  }

  up(event: KeyboardEvent) {
    // if (!event || !event.key) {
    //   return;
    // }
    // const key = event.code.toLowerCase();
    // this.comboState[key] = false;
    // this.handleCombo(key);
    // if (event.key === 'Shift') {
    //   this.canvasService.resetShift();
    // }
    // if (event.key === 'Alt') {
    //   this.canvasService.resetAlt();
    // }
    // this.canvasService.emitKey(event.key);
  }

  down(event: KeyboardEvent) {
    this.keyEvents[event.key]?.next();

    // if (!event || !event.key) {
    //   return;
    // }
    // const key = event.code.toLowerCase();
    // this.comboState[key] = true;
    // if (event.key === 'Shift') {
    //   this.canvasService.setShift();
    // }
    // if (event.key === 'Alt') {
    //   this.canvasService.setAlt();
    // }
  }

  public emit(e: KeyboardEvent) {
    let key = e.key;
    if (this.comboState.shift) {
      key += ':Shift';
    }
    this.event.emit({
      key: key,
      code: e.code,
    });
  }

  handleCombo(key) {
    // if (this.comboState.shift) {
    //   this.canvasService.command(SHIFT[key]);
    // }
  }
}
