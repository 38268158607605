import { NgModule } from '@angular/core';
import { UtilComponentModule } from '../../components/util/util-component.module';
import { StateSelectorComponent } from './state-selector/state-selector.component';
import { GenericFormComponent } from './generic-form/generic-form.component';
import { FormsModule } from '@angular/forms';
import { InputEditorComponent } from './input-editor/input-editor.component';
import { ShapeAttributePanelComponent } from './shape-attribute-panel.component';
import { TranslateAttributeComponent } from './translate-attribute/translate-attribute.component';
import { ScaleAttributeComponent } from './scale-attribute/scale-attribute.component';
import { FillAttributeComponent } from './fill-attribute/fill-attribute.component';
import { StrokeAttributeComponent } from './stroke-attribute/stroke-attribute.component';
import { MultiplicationXAttributeComponent } from './multiplication-x-attribute/multiplication-x-attribute.component';
import { MultiplicationYAttributeComponent } from './multiplication-y-attribute/multiplication-y-attribute.component';
import { DropShadowYAttributeComponent } from './drop-shadow-attribute/drop-shadow-attribute.component';
import { AnimationModule } from '../animation/animation.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IfAttributeComponent } from './if-attribute/if-attribute.component';
import { TextAttributesComponent } from './translate-attributes/text-attributes.component';
import { ArcIntervalAttributeComponent } from './arc-interval-attribute/arc-interval-attribute.component';
import { RotationAttributeComponent } from './rotation-attribute/rotation-attribute.component';
import { HandShapeAttributeComponent } from './hand-shape-attribute/hand-shape-attribute.component';
import { HandShapeSectionComponent } from './hand-shape-attribute/hand-shape-section/hand-shape-section.component';
import { EnvAttributeComponent } from './env-attributes/env-attribute.component';
import { AnimationAttributeModule } from './animation/animation-attr-panel.module';
import { GeneralAttributeHeaderModule } from './general-attribute-header/general-attribute-header.module';
import { FileAttributeModule } from './file/file-attribute.module';

@NgModule({
  imports: [
    UtilComponentModule,
    FormsModule,
    AnimationAttributeModule,
    AnimationModule,
    MatTooltipModule,
    GeneralAttributeHeaderModule,
    FileAttributeModule,
  ],
  providers: [],
  declarations: [
    ShapeAttributePanelComponent,
    GenericFormComponent,
    StateSelectorComponent,
    InputEditorComponent,
    TranslateAttributeComponent,
    ScaleAttributeComponent,
    FillAttributeComponent,
    StrokeAttributeComponent,
    MultiplicationXAttributeComponent,
    MultiplicationYAttributeComponent,
    DropShadowYAttributeComponent,
    IfAttributeComponent,
    TextAttributesComponent,
    ArcIntervalAttributeComponent,
    RotationAttributeComponent,
    HandShapeAttributeComponent,
    EnvAttributeComponent,
    HandShapeSectionComponent,
  ],
  exports: [ShapeAttributePanelComponent],
})
export class ShapeAttributePanelModule {}
