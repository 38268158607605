import { Container } from 'pixi.js';
import { PathSection } from '../../../element-editor/shape/shapes/path-shape/path-sections/path-section';
import {
  ControlPointDefinition,
  Coords,
} from '../../resource/types/shape.type';
import { GeneralShape } from '../../../element-editor/shape/shapes/general/general-shape';
import { HandSection } from '../../../element-editor/shape/shapes/path-shape/hand/hand-section';
import { Circle } from '../../../element-editor/shape/shapes/primitive/circle-element';
import { RectangleController } from '../rectangle-controller/rectangle-controller';
import { isEqual } from 'lodash';

const { PI, sqrt, pow } = Math;
const between = (a: number, a1: number, a2: number) => a1 < a && a < a2;

export interface DragResponse {
  x?: number;
  y?: number;
  dx?: number;
  dy?: number;
  d?: number;
  pc?: PointController;
}

export interface PointControllerConfig {
  pOffset?: Coords;
  dOffset?: number;
  angle?: number;
  d?: number;
  ds?: number;
  de?: number;
  p?: Coords;
  ps?: Coords;
  pe?: Coords;
  noScale?: boolean;
  showCircle?: boolean;
  start?: () => void;
  drag?: (resp: DragResponse) => void;
  baseDrag?: (dx: number, dy: number) => void;
  end?: (e?: MouseEvent, dx?: number, dy?: number) => void;
  click?: () => void;
  dragOnto?: (params: ControlPointDefinition) => void;
  mouseover?: () => void;
  mouseout?: () => void;
  disableActions?: boolean;
  updateCircle?: boolean;
  controlPoint?: ControlPointDefinition;
  hack?: boolean;
  color?: string;
  'stroke-width'?: number;
  rotation?: number;
  rotateAround?: {
    l: number;
    angle: number;
  };
}

export class PointController {
  steep: boolean;
  m: number;
  quarter: number;

  // circle: Element;
  // hoverCircle: Element;
  unMovedP = [0, 0];
  unMovedD = 0;

  inProgress = false;

  IRI: string;

  get pathSectionParent() {
    return this.parent as PathSection;
  }

  get canvasScale() {
    return this.config.noScale ? 1 : this.cs.canvasScale;
  }

  get cs() {
    return this.parent.cs;
  }

  get r() {
    return 4 / this.canvasScale;
  }

  get rL() {
    return 5 / this.canvasScale;
  }

  get r2() {
    return 10 / this.canvasScale;
  }

  get strokeWidth() {
    return 0.75 / this.canvasScale;
  }

  get _strokeWidth() {
    return this.config['stroke-width'] / this.canvasScale;
  }

  get rotation() {
    return this.config.rotation;
  }

  get ps() {
    return this.config.ps;
  }

  get pe() {
    return this.config.pe;
  }

  get xy() {
    const [xOffset, yOffset] = this.pOffset;
    const [x, y] = this.p;

    let [cx, cy] = [xOffset + x, yOffset + y];

    if (this.angle != undefined) {
      this.quarter = this.getQuarter(this.angle);
      this.m = this.getLineM(this.angle);
      this.steep = this.m < -1 || 1 < this.m;

      const offset = this.d + this.dOffset;
      const [dcx, dcy] = [
        offset * Math.cos(this.angle),
        offset * Math.sin(this.angle) * (this.config.hack ? -1 : 1),
      ];

      cx += dcx;
      cy -= dcy;
      // this.m = undefined;
    }

    return [cx || 0, cy || 0];
  }

  get angle() {
    return this.norm(this.config.angle);
  }

  get dOffset() {
    return this.config.dOffset || 0;
  }

  get d() {
    return this.config.d || 0;
  }

  get p() {
    return this.config.p || [0, 0];
  }

  get pOffset() {
    return this.config.pOffset || [0, 0];
  }

  set d(val: number) {
    this.config.d = val;
  }

  get xs() {
    if (this.config.angle && this.config.ds) {
      return Math.cos(this.config.ds);
    } else {
      return this.config.ps[0]; //- this.config.p?.[1] || 0;
    }
  }

  get ys() {
    if (this.config.angle && this.config.ds) {
      return Math.sin(this.config.ds);
    } else {
      return this.config.ps[1]; //- this.config.p?.[1] || 0;
    }
  }

  get xe() {
    return this.config.pe[0];
  }

  get ye() {
    return this.config.pe[1];
  }

  get circleAttrs() {
    return {
      r: this.r,
      // stroke: this.config.color || '#',
      'stroke-width': this.strokeWidth,
    };
  }

  get hoverCircleAttrs() {
    return {
      r: this.r2,
      'stroke-width': this.strokeWidth,
    };
  }

  // TODO - remove
  get id() {
    return (this.parent as PathSection).id;
  }

  get shape(): GeneralShape {
    if (this.parent instanceof PathSection) {
      return this.parent.pathShape;
    }

    if (this.parent instanceof RectangleController) {
      return this.parent.parent;
    }

    if (this.parent instanceof HandSection) {
      return this.parent.handShape;
    }
    // -- // -- //
    return this.parent;
  }

  get shapeService() {
    return this.shape.service;
  }

  circle: Circle;
  hoverCircle: Circle;

  constructor(
    public readonly parent:
      | PathSection
      | RectangleController
      | GeneralShape
      | HandSection,
    // TODO - move to private  again
    public config: PointControllerConfig,
    private container?: Container,
    private auxContainer?: Container,
  ) {
    this.unMovedD = this.d;

    this.IRI = Math.random().toString();

    this.initCircles();
  }

  mouseIsOver = false;

  stopDrag() {
    this.hoverCircle.stopDrag();
  }

  initCircles() {
    const [cx, cy] = this.xy;
    this.circle = new Circle(this, this.container, {
      x: cx,
      y: cy,
      r: this.r,
      stroke: this.config.color || '#000',
      fill: '#ffffff',
    });

    // Hovercircle is invisible and slightly larger then the main circle.
    // It makes to more convenient to drag the point-controller.
    this.hoverCircle = new Circle(this, this.container, {
      x: cx,
      y: cy,
      r: this.r2,
      // fill: '#00ff00',
      // opacity: 0.001,
      // stroke: '#ff0000',
    })
      .drag(
        (dx, dy) => this.drag(dx, dy),
        () => this.startDrag(),
        (dx, dy) => this.endDrag(dx, dy),
      )

      .mouseover(() => {
        this.cs._hoveredControlPoint = this;
        this.mouseIsOver = true;
        if (this.inProgress) {
          return;
        }
        this.config.mouseover?.();

        if (this.parent instanceof PathSection) {
          this.cs.hoveredPoint = this.parent;
        }

        if (this.config.controlPoint) {
          this.cs.hoveredControlPoint = this.config.controlPoint;
          // console.log(
          //   'cs.hoveredControlPoint',
          //   this.cs.hoveredControlPoint.absCoords
          // );
        }
        this.circle.patch({ r: this.rL });
      })
      .mouseout(() => {
        this.cs._hoveredControlPoint = null;
        this.mouseIsOver = false;
        this.config.mouseout?.();

        if (this.inProgress) {
          return;
        }
        this.cs.hoveredPoint = null;
        this.cs.hoveredControlPoint = null;
        this.circle.patch({ r: this.r });
      })
      .click(() => this.config.click?.());
  }

  zoomUpdate() {
    this.circle.patch(this.circleAttrs);
    this.hoverCircle.patch(this.hoverCircleAttrs);
  }

  baseDragMode = false;

  draggedOnto(params: ControlPointDefinition) {
    if (isEqual(params, this.config.controlPoint)) {
      return;
    }

    this.config.dragOnto?.(params);
  }

  startDrag() {
    this.inProgress = true;
    this.cs.draggedPointController = this;

    this.container.removeChild(this.circle.element);
    this.container.removeChild(this.hoverCircle.element);

    this.auxContainer.addChild(this.circle.element);
    this.auxContainer.addChild(this.hoverCircle.element);

    // TOOD - check if it is not needed
    // this.disableHover();
    if (this.config.disableActions) {
      return;
    }
    if (this.config.angle) {
      this.unMovedD = this.d;
    } else {
      this.unMovedP = this.config.p;
    }
    if (this.config.p) {
      this.unMovedP = this.config.p;
    }
    if (this.shape.cs.isPressed('v')) {
      this.shape.localStartDrag();
      this.baseDragMode = true;
    } else {
      this.config.start?.();
    }
  }

  drag(dx: number, dy: number) {
    if (this.baseDragMode) {
      return this.shapeService.drag(dx, dy);
    }

    if (this.config.baseDrag) {
      return this.config.baseDrag(dx, dy);
    }

    if (this.cs.isPressed('c')) {
      // -- // -- // -- //
    }

    const resp = this.dragHandler([dx, dy]);
    const [x, y] = this.pOffset;
    this.circle.patch({
      x: x + resp.x,
      y: y + resp.y,
    });
    this.hoverCircle.patch({
      x: x + resp.x,
      y: y + resp.y,
    });
    this.config.drag(resp);
  }

  endDrag(dx: number, dy: number) {
    this.auxContainer.removeChild(this.circle.element);
    this.auxContainer.removeChild(this.hoverCircle.element);

    this.container.addChild(this.circle.element);
    this.container.addChild(this.hoverCircle.element);

    if (this.baseDragMode) {
      this.baseDragMode = false;
      return this.shapeService.endDrag();
    }

    this.inProgress = false;

    this.config.end?.(null, dx, dy);
    this.circle.patch({ r: this.r });
  }

  _redrawCircle() {
    const [x, y] = this.xy;
    this.hoverCircle.patch({ x, y, r: this.r2 });
    // this.hoverCircle.patch({ x, y });
  }

  norm(angle: number) {
    if (angle > 2 * PI) {
      return angle - 2 * PI;
    }
    if (angle < 0) {
      return angle + 2 * PI;
    }
    return angle;
  }

  remove() {
    // TODO - check if nullish coelescing is necessary here
    this.circle.remove();
    this.hoverCircle.remove();
  }

  getLineM(angle: number) {
    const m = Math.tan(this.flipAngle(angle));
    return isNaN(m) ? Infinity : m;
  }

  getQuarter(angle: number) {
    return Math.floor(this.flipAngle(angle) / (PI / 2));
  }

  flipAngle(angle: number) {
    if (between(angle, 0, PI / 2) || between(angle, (3 * PI) / 2, 2 * PI)) {
      return 2 * PI - angle;
    }
    if (between(angle, PI / 2, (3 * PI) / 2)) {
      return PI + (PI - angle);
    }
  }

  dragHandler(coords: Coords): DragResponse {
    let [dx, dy] = coords;
    if (this.rotation) {
      let angle = Math.atan(dy / dx);

      if (dx < 0) {
        if (dy >= 0) {
          angle += Math.PI;
        } else {
          angle -= Math.PI;
        }
      }

      const diffAngle = angle - this.rotation;
      const l = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
      return {
        dx: Math.cos(diffAngle) * l,
        dy: Math.sin(diffAngle) * l,
        pc: this,
      };
    }
    [dx, dy] = this.limitDiff(dx, dy);
    const [x0, y0] = this.unMovedP;
    // dx /= this.canvasScale;
    // dy /= this.canvasScale;
    const [x, y] = [x0 + dx, y0 + dy];
    if (this.config.updateCircle) {
      this.updateCircles(x, y);
    }
    if (this.quarter !== undefined) {
      const d = this.getD(dx, dy);
      if (this.ps || this.pe) {
        [dx, dy] = this.limitByPoint(dx, dy);
      }
      return {
        x,
        y,
        d: this.unMovedD + (this.config.hack ? -d : d),
        dx,
        dy,
        pc: this,
      };
    } else {
      return { x, y, d: 0, dx, dy, pc: this };
    }
  }

  updateCircles(cx: number, cy: number) {
    // this.circle.attr({ cx, cy });
    // this.hoverCircle.attr({ cx, cy });
  }

  getD(x: number, y: number) {
    const d = sqrt(pow(x, 2) + pow(y, 2));
    if (this.quarter === 0 || this.quarter === 1) {
      return Math.sign(y) * d;
    } else {
      return -Math.sign(y) * d;
    }
  }

  limitDiff(x: number, y: number) {
    [x, y] = this.limitByLength(x, y);
    [x, y] = this.limitByLine(x, y);
    return [x, y];
  }

  get x() {
    if (this.config.angle !== undefined && this.unMovedD !== 0) {
      return this.unMovedD * Math.cos(this.config.angle);
    } else {
      return this.config.p[0];
    }
  }

  get y() {
    if (this.config.angle !== undefined && this.unMovedD) {
      return this.unMovedD * Math.sin(this.config.angle);
    } else {
      return this.config.p[1];
    }
  }

  limitByLength(dx: number, dy: number) {
    if (this.config.ds !== undefined) {
      const d = this.unMovedD - this.config.ds;

      if (this.quarter === 0 || this.quarter === 3) {
        dx = Math.max(-d * Math.cos(this.config.angle), dx);
      } else {
        dx = Math.min(-d * Math.cos(this.config.angle), dx);
      }

      if (this.quarter === 0 || this.quarter === 1) {
        dy = Math.max(d * Math.sin(this.config.angle), dy);
      } else {
        dy = Math.min(d * Math.sin(this.config.angle), dy);
      }
    }

    if (this.config.de !== undefined) {
      const d = this.config.de - this.unMovedD;

      if (this.quarter === 0 || this.quarter === 3) {
        dx = Math.min(d * Math.cos(this.config.angle), dx);
      } else {
        dx = Math.max(d * Math.cos(this.config.angle), dx);
      }

      if (this.quarter === 0 || this.quarter === 1) {
        dy = Math.min(-d * Math.sin(this.config.angle), dy);
      } else {
        dy = Math.max(-d * Math.sin(this.config.angle), dy);
      }
    }

    return [dx, dy];
  }

  limitByLine(dx: number, dy: number) {
    if (this.m !== undefined) {
      //console.log('limit-by-line', this.m);
      if (this.m === Infinity) {
        dy = 0;
      } else if (this.steep) {
        dx = this.getX(dy);
      } else {
        dy = this.getY(dx);
      }
    }
    return [dx, dy];
  }

  limitByPoint(dx: number, dy: number) {
    const [x, y] = this.unMovedP;
    if (this.config.ps) {
      if (this.quarter === 0 || this.quarter === 3) {
        dx = Math.max(this.xs - x, dx);
      } else {
        dx = Math.min(this.xs - x, dx);
      }
      if (this.quarter === 0 || this.quarter === 1) {
        dy = Math.max(this.ys - y, dy);
      } else {
        dy = Math.min(this.ys - y, dy);
      }
    }

    if (this.config.pe) {
      if (this.quarter === 0 || this.quarter === 3) {
        dx = Math.min(this.xe - x, dx);
      } else {
        dx = Math.max(this.xe - x, dx);
      }
      if (this.quarter === 0 || this.quarter === 1) {
        dy = Math.min(this.ye - y, dy);
      } else {
        dy = Math.max(this.ye - y, dy);
      }
    }
    return [dx, dy];
  }

  clearAngle() {
    this.config.angle = 0;
  }

  getX(y: number) {
    return y / this.m;
  }

  getY(x: number) {
    return this.m * x;
  }

  delete(keys: string[]) {
    keys.map(key => delete this.config[key]);
  }

  patch(config: Partial<PointControllerConfig>, log = false) {
    this.config = {
      ...this.config,
      ...config,
    };
    this.refresh(log);

    if (log) {
      // console.log('p', config.p); //
    }
  }

  clear(config: Partial<PointControllerConfig>) {
    Object.keys(config).map(key => delete this.config[key]);
    console.log('clear', this.config);
    console.log('clear', this.angle);
    if (this.angle == undefined) {
      this.m = undefined;
    }
    this.refresh();
  }

  refresh(log = false) {
    const [cx, cy] = this.xy;
    if (log) {
      // console.log('-----pc', this.id, { cx, cy });
    }
    this.circle.patch({
      x: cx,
      y: cy,
      r: this.r,
      'stroke-width': this._strokeWidth || this.strokeWidth,
      stroke: this.config?.color || '#000',
    });
    this._redrawCircle();
    // TODD - check what that is
    if (!this.inProgress) {
      // this.circle.attr({ r: this.r }); //
      // this.hoverCircle.attr({ r: this.r2 }); //
    }
  }

  disableHover() {
    this.hoverCircle.disableHover();
  }

  enableHover() {
    this.hoverCircle.enableHover();
  }

  hide(force = false) {
    this.circle.hide();
    // if (force) {
    this.hoverCircle.hide();
    // }
  }

  show() {
    this.zoomUpdate();
    this.circle.show();
    this.hoverCircle.show();
  }

  selectMany() {
    // TODO - migrate to pixi
    // this.circle?.attr({ stroke: 'red' });
  }

  deselectMany() {
    // TODO - migrate to pixi
    // this.circle?.attr({ stroke: 'black' });
  }
}

// this.circle = this.cs.snap?.circle(cx, cy, this.r).attr({
//   fill: 'white',
//   stroke: 'black',
//   visibility: this.config.showCircle ? 'visible' : 'hidden',
//   'stroke-width': this.strokeWidth,
// });

// this.hoverCircle = this.cs.snap
//   ?.circle(cx, cy, this.r2)
//   .attr({
//     opacity: 0.001,
//     visibility: this.config.showCircle ? 'visible' : 'hidden',
//   })
//   .mouseover(() => {
//     if (this.inProgress) {
//       return;
//     }

//     if (this.parent instanceof PathSection) {
//       this.cs.hoveredPoint = this.parent;
//     }

//     if (this.config.controlPoint) {
//       this.cs.hoveredControlPoint = this.config.controlPoint;
//       // console.log(
//       //   'cs.hoveredControlPoint',
//       //   this.cs.hoveredControlPoint.absCoords
//       // );
//     }

//     this.circle.attr({ r: this.rL });
//   })
//   .mouseout(() => {
//     this.cs.hoveredPoint = null;
//     this.cs.hoveredControlPoint = null;
//     this.circle.attr({ r: this.r });
//   });

// if (!this.config.showCircle) {
//   this.hide();
// }

// .mouseover(() => {
//   this.circle.attr({ r: this.rL });
// })
// .mouseout(() => {
//   if (this.inProgress) {
//     return;
//   }
//   this.circle.attr({ r: this.r });
// });

// This does not fire
// this.hoverCircle.click(e => {
//   console.log('hoverCircle-click');
//   e.stopPropagation();
// });

// this.hoverCircle?.drag(
//   (dx: number, dy: number) => {
//     if (this.config.disableActions) {
//       return;
//     }
//     this.drag(dx, dy);
//   },
//   () => {
//     this.inProgress = true;
//     this.disableHover();
//     if (this.config.disableActions) {
//       return;
//     }
//     if (this.config.a) {
//       this.unMovedD = this.d;
//     } else {
//       this.unMovedP = this.config.p;
//     }
//     if (this.config.p) {
//       this.unMovedP = this.config.p;
//     }

//     this.config.start?.();
//   },
//   e => {
//     // this stands for e.stopPropagation(); //
//     this.cs.clickAbsorbed = true;
//     this.inProgress = false;
//     this.enableHover();
//     if (this.config.disableActions) {
//       return;
//     }
//     this.config.end?.(e);
//   }
// );

// group?.add(this.circle);
// group?.add(this.hoverCircle);
