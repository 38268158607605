import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import {
  AdjustConstraint,
  AnimationItem,
  AnimationKeys,
  AnimationValue,
  Coords,
  CurveSectionDescriptor,
  DashConfig,
  DashMoveAnimation,
  DropShadowConfig,
  IncrementState,
  LineAnimation,
  LoopTrajectoryAnimation,
  PathAnimation,
  PathFillAnimation,
  PathSectionDescriptor,
  PathShapeDescriptor,
  PositionVector,
  SectionAnimation,
  ShapeConfig,
  ShapePosition,
  ShapeSelectParams,
  TrajectoryDTransform,
  TrajectoryFillAnimation,
  Point as iPoint,
} from '../../../../elements/resource/types/shape.type';
import { cloneDeep, isEqual, omit } from 'lodash';
import { PathSection, PathSectionConfig } from './path-sections/path-section';
import { CurveSection } from './path-sections/curve/curve-section';
import { ArcSection } from './path-sections/arc/arc-section';
import { PathSectionStepper } from './path-sections/stepper/path-section.stepper';
import { PathAttrStepper } from './path-sections/stepper/path-attr.stepper';
import { Point } from '../../../../elements/base/point';
import { PrimitiveShape } from '../primitive/primitive-shape';
import { PathElement } from '../primitive/path-element';
import { TrajectoryStepper } from '../trajectory/trajectory-stepper';
import { TrajectoryAnimation } from './trajectory/trajectory-animation';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { PathItem } from './path-shape.types';
import { ShapeService } from '../../shape.service';
import { setDescriptorValue } from '../../../store/editor.actions';
import {
  Easing,
  IncrementController,
} from '../../../animation/frame/increment/controller/increment.controller';
import { selectPathSectionsByIRI } from '../../../store/selector/editor.selector';
import { BlurFilter, Container } from 'pixi.js';
import { DashController } from './dash-controller';

export interface PathShapeConfig extends ShapeConfig {
  initByDrag: boolean;
  // initial?: boolean;
  // imported?: boolean;
  // noEdit?: boolean;
}

export class PathShape<
  T extends PathShapeDescriptor = PathShapeDescriptor,
> extends PrimitiveShape<PathElement, T, PathShapeConfig> {
  initialXY: [number, number];
  unMovedOffset: [number, number];

  animationDragStart: [number, number];

  pathSections: PathSection[];

  // pathElement: GeneralPathNext;

  adjustContraints: Record<string, AdjustConstraint[]> = {};

  hasBeenChanged = false;

  startLength: number;
  // endLength: number;

  lengthInterval: Coords;

  mouseoutConsumed = false;

  get dashArray() {
    return this.descriptor.dashArray;
  }

  set dashArray(val: any) {
    this.descriptor.dashArray = val;
  }

  get dashFill() {
    return this.descriptor.dashArray?.fill || 0;
  }

  get dashGap() {
    return this.descriptor.dashArray?.gap || 0;
  }

  get _startLength(): number {
    return this.lengthInterval?.[0];
  }

  get _endLength(): number {
    return this.lengthInterval?.[1];
  }

  get endLength() {
    return this._lastSection.endLength;
  }

  get __endLength(): number {
    return this._lastSection.endLength;
  }

  get sectionsDescriptor() {
    return this.pathSections?.map(section => section.getDescriptor()) || [];
  }

  get canvasScale() {
    return this.cs.canvasScale;
  }

  get expressions() {
    return {
      startx: 'x',
      starty: 'y',
      ...super.expressions,
    };
  }

  get newCurveAngle() {
    return this.descriptor.newCurveAngle;
  }

  get _pathSections() {
    if (this.closed) {
      return this.pathSections;
    }
    return this.pathSections.slice(0, this.pathSections.length - 1);
  }

  get _inputs(): Record<string, any> {
    return {
      x: { default: 0 },
      y: { default: 0 },
      ...super._inputs,
    };
  }

  get pathSectionLength() {
    return this.pathSections?.length || 0;
  }

  get lastSection() {
    return this.pathSections?.[this.pathSectionLength - 1];
  }

  get _lastSection() {
    if (this.closed) {
      return this.lastSection;
    }
    return this.pathSections?.[this.pathSectionLength - 2];
  }

  // set svgAttributes(value: Record<string, any>) {
  //   this.descriptor.svgAttributes = value;
  // }

  get isPathShape() {
    return true;
  }

  // get SectionClassToInit(): typeof PathSection {
  //   if (this.cs.isPressed('a')) {
  //     return PathSection;
  //   } else if (this.cs.isPressed('s')) {
  //     return CurveSection;
  //   } else {
  //     return ArcSection;
  //   }
  // }

  getType(): string {
    return 'path-shape';
  }

  // this is a bug in ts
  _save(descriptor: Partial<PathShapeDescriptor>) {
    super._save(descriptor as Partial<T>);
  }

  closed: boolean;

  constructor(
    service: ShapeService,
    resourceData: ResourceData<T>,
    config?: PathShapeConfig,
  ) {
    super(service, resourceData, config);
    this.init();
    this.type = ResourceType.PathShape;

    this.sections = this.descriptor.sections;
    this.closed = this.descriptor.closed;
    // todo - check if it is editable

    // subaru
    this.subscriptions = [
      // this.store
      //   .select(pathAnimationValueByIRI(this.IRI))
      //   .subscribe((pathAnimation: PathAnimation) => {
      //     if (isEqual(this.currentPathAnimation, pathAnimation)) {
      //       return;
      //     }
      //     this.initPathAnimation(pathAnimation);
      //   }),
      this.store
        .select(selectPathSectionsByIRI(this.IRI))
        .subscribe((sections: PathSectionDescriptor[]) => {
          if (!sections?.length) {
            return;
          }
          // console.log('sections', sections);
          if (!isEqual(this.sections, sections)) {
            this.sections = sections;
            this.initPathSections();
            this.refresh();
            // if (this.selected) {
            //  this.select();
            // }
          }
        }),
      this.cs.keyEventSubscribe('Shift+m', () => {
        if (this.selected) {
          this._save({ closed: true });
          this.refresh();
        }
      }),

      this.cs.keyEventSubscribe('Shift+W', () => {
        if (this.selected) {
          this.rotateIncrement(15);
        }
      }),
      this.cs.keyEventSubscribe('Shift+E', () => {
        if (this.selected) {
          this.rotateIncrement(-15);
        }
      }),
      this.cs.keyEventSubscribe('Shift+F', () => {
        if (this.selected) {
          this.flip();
        }
      }),
    ];

    this.cs.keyEventSubscribe('b', () => {
      this.setDashed();
    });

    this.cs.generalEventSubscribe('shape-selection', ({ xLimits, yLimits }) => {
      if (!this.selected) {
        this.deselect();
        return;
      }

      console.log('shape-selection', xLimits, yLimits);

      const [xStart, xEnd] = xLimits;
      const [yStart, yEnd] = yLimits;

      const [x1, y1] = [
        xStart - this.x - this.offsetX,
        yStart - this.y - this.offsetY,
      ];
      const [x2, y2] = [
        xEnd - this.x - this.offsetX,
        yEnd - this.y - this.offsetY,
      ];
      this.pathSections.map(ps => ps._setAbsCoords());
      const indexesToSelect = cloneDeep(
        this.pathSections
          .filter(({ absCoords }) => {
            const [x, y] = absCoords;
            return x1 <= x && x <= x2 && y1 <= y && y <= y2;
          })
          .map(ps => ps.index),
      );

      if (indexesToSelect.length === this.pathSections.length) {
        return;
      }

      console.log('indexes', indexesToSelect);
      this.prepareForBatchDrag(indexesToSelect);

      indexesToSelect.map(i => this.pathSections[i]._select());
      // this.preSelectMany(indexesToSelect);
    });

    this.log('pos', this.position);
    // TODO - check if this is necessary
    this.initPathSections();
    this.refreshElement();
    this.applyTranslate({ x: this.x, y: this.y });

    // -- // -- //
    this.cs.keyDownEventSubscribe('c', () => {
      if (this.selected) {
        return;
      }
      this.pathSections?.map(hs => hs.pc.show());
    });

    this.cs.keyEventSubscribe('c', () => {
      if (this.selected) {
        return;
      }
      this.pathSections?.map(hs => hs.pc.hide());
    });

    if (this.descriptor.hidden) {
      this._hide();
    }
  }

  draggedSection: PathSection;
  _startDrag(key: string, sourceShapeIRI?: string): void {
    if (!key) {
      return this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true });
    }
    // -- //
    const [, id] = key.split('_');
    console.log('key', key, 'id', id, 'key.split', key.split('_'));
    this.draggedSection = this.pathSections.find(ps => ps.id == id);
    this.draggedSection.pcStartDrag();
    // -- //
  }
  _drag(_x: number, _y: number, dx: number, dy: number) {
    this.draggedSection.pcDrag(dx, dy);
  }

  _endDrag(): void {
    if (this.draggedSection) {
      this.draggedSection.pcEndDrag();
    } else {
      this.endDrag();
    }
  }

  initPathAnimation(pathAnimation: PathAnimation) {
    if (!pathAnimation) {
      return;
    }
    this.currentPathAnimation = pathAnimation;
    const { length, offset, mode, gap } = pathAnimation;

    switch (mode) {
      case 'fill':
        this.refreshElement(offset || 0, length);
        break;

      case 'move':
        this.prepareDashMoveAnimation(pathAnimation, 1);
        break;
    }
  }

  /***************************************************************************************/
  /**********************************  ANIMATION   ***************************************/
  /***************************************************************************************/

  setPreAnimationState(): boolean {
    if (!this.parent) {
      return;
    }

    // TODO - check why is this the case
    super.setPreAnimationState();
    // if (super.setPreAnimationState()) {
    //   return;
    // }

    const timeStore = this.parent.animationFrame?.timeStore;
    if (!timeStore) {
      return;
    }
    for (const { t, frames } of timeStore) {
      for (const frame of frames) {
        for (const animation of this.getAnimationsById(frame)) {
          if (animation.key == 'trajectory-fill') {
            this.applyTrajectoryFill(
              animation.value as TrajectoryFillAnimation,
              0,
            );
            return;
          }
        }
      }
    }

    return super.setPreAnimationState();
  }

  applyAnimationByTime(
    time: number,
    directApply?: boolean,
  ): Partial<Record<AnimationKeys, AnimationValue>> {
    if (time == undefined) {
      return;
    }

    // -- // -- // -- // -- //

    const changed = false;
    const updates = super.applyAnimationByTime(time, directApply);
    Object.entries(this.consequtiveAnimationsByKey).map(
      ([animationKey, values]) => {
        switch (animationKey) {
          case 'path':
            // -- //
            this.pathSections.map(section => section.setDashCache());
            this.pathSections.map(ps => ps.setLengths());

            const { animations, ratio } =
              this.animationService.getAnimationsTillTime(time, values);

            console.log('path-animation', { animations, ratio, values });
            if (!animations.length) {
              // -- //
              if (values[0].start == time) {
                // this.is the pre-state
                this._show();
                this.refreshElement(
                  0,
                  (values[0].animation.value as PathAnimation).length,
                );
                return;
              }

              this._hide();
              return;
            }

            this._show();
            const last = animations[animations.length - 1];
            // -- // -- // -- //
            if ((last.value as PathAnimation).mode == 'fill') {
              console.log(
                '------ apply path animation --------',
                ratio,
                this.endLength,
              );
              this.refreshElement(0, ratio * this.endLength);
            }

            break;
        }
      },
    );
    return updates;
  }

  applyTrajectoryFill(value: TrajectoryFillAnimation, t: number) {
    const { offset, end, inverse } = value;
    let _start = offset || 0;

    if (offset == -1) {
      _start = this.__endLength;
    }
    const _end = end || this.__endLength;

    if (inverse) {
      switch (t) {
        case 0:
          this.refreshElement(_start, this._endLength);
          break;
        case 1:
          this.refreshElement(_end, this._endLength);
          break;
        default:
          this.refreshElement((_end - _start) * t, this.__endLength);
      }
    } else {
      switch (t) {
        case 0:
          this.refreshElement(0, _start);
          break;
        case 1:
          this.refreshElement(0, _end);
          break;
        default:
          this.refreshElement(0, _start + (_end - _start) * t);
      }
    }
  }

  hideAuxLines() {
    this.pathSections.map(section => section.hideAuxLines());
  }

  getCurrentBBox() {
    const { width, height } = this.container.getBounds();
    return {
      x: this.x - this.offsetX,
      y: this.y - this.offsetY,
      width: width / this.cs.canvasScale,
      height: height / this.cs.canvasScale,
    };
  }

  resize(width: number, height: number): void {
    const firstSection = this.pathSections[0];
    // firstSection.x = width;
    // firstSection.y = height;
    firstSection.refreshXY(width, height);

    if (firstSection.type == 'cs') {
      (firstSection as CurveSection).setA1A2ByDxDy(width, height);
    }

    this.refresh();
  }

  changeDashArray() {
    const animation = this.animationsById[this.cs.currentAnimation.id][
      'trajectory'
    ].value as LineAnimation;

    if (animation.dashArray) {
      delete animation.line;
      animation.dashArray = {
        length: 100,
        speed: 1,
      };
    } else {
      delete animation.dashArray;
      animation.line = {
        length: 100,
      };
    }
    // this.updateAnimationInstance(); //
    this.save();
  }

  get parentContainer() {
    return this.parent?.containerForChildren;
  }

  init() {
    super.init();

    // this.rc = new RectangleController(
    //   this,
    //   {
    //     offset: [-this.offsetX, -this.offsetY],
    //     width: this.w,
    //     height: this.h,
    //     drag: (coords: Coords, lengths: Coords) => null,
    //     endDrag: () => null,
    //     clicked: () => null,
    //   },
    //   this.circleContainer,
    //   this.auxCircleContainer
    // );

    if (this.config?.initByDrag) {
      this.select();
    }
    this.initPathSections();
    this.saveCoordinates();

    this.subscriptions.push(
      this.cs.keyEventSubscribe('r', () => {
        if (this.iamDragged) {
          this.pathSections.map(ps => ps.removeConstraints());
        }
      }),
    );

    this.parent?.sectionContainer?.addChild(this.sectionContainer);
    this.parent?.containerForChildren?.addChild(this.container);

    this.redraw();
    this.offsetX = this.position.offsetX;
    this.offsetY = this.position.offsetY;

    // this.setOffset();
    // This should be the part of some post-init routine because it is dependent on all shapes
    // setTimeout(() => {
    //   Object.entries(this.animationsById || {}).map(([id, val]) => {
    //     val.map(({ key, value }) => {
    //       if (key === 'loop-trajectory-transform') {
    //         this.trajectoryStepperStore[id] =
    //           this.prepareLoopTrajectoryAnimation(
    //             value as LoopTrajectoryAnimation
    //           );
    //       }
    //     });
    //   });
    // }, 300);

    this.pathSections.map(ps => ps.refresh());
    this.unMoved = null;

    // console.log('new-path-element', {
    //   ...this.resolvedSVGAttributes,
    //   ...this.elementConfig,
    // });

    const config = {
      ...this.resolvedSVGAttributes,
      ...this.elementConfig,
      noFill: true,
    };

    this.element = new PathElement(this, this.container, config)
      .mouseover(() => {
        this.select({ onHover: true });
      })
      .mouseout(() => {
        if (!this.cs.shapeAddMode) {
          this.deselect({ onHover: true });
        }
        // this.deselect({ onHover: true });
        // if (this.hoverSelected) {
        //   this.deselect();
        // }
        // if (this.mouseoutConsumed) {
        //   this.mouseoutConsumed = false;
        //   console.log('mouse-out-consumed');
        //   return;
        // }
        // this.deselect();
        // this.pathSections.map(ps => ps.deselect());
      })
      .click(() => this.clicked());

    this.baseLine = new PathElement(this, this.container, {
      'stroke-width': 0.5 / this.cs.canvasScale,
      stroke: '#2bc7e6',
      ...this.elementConfig,
      noFill: true,
    });
    this.baseLine.hide();
    // -- // -- //
    if (this.editable) {
      this.element
        .drag(
          (dx, dy) => {
            this.drag(dx, dy);
          },
          () => this.startBaseDrag(),
          () => {
            this.endDrag();
          },
        )
        .click(() => {
          // console.log('ps.element > clicked');
          this.clicked();
        });
    }

    // this.refresh();
    this.pathSections.map(ps => ps._setAbsCoords());
  }

  // initDropShadowElement(config: DropShadowConfig, opacity: number) {

  //   this.blurContainer?.destroy();

  //   console.log('init-drop-shadow-element', config);

  //   const { strength, margin, color } = config;
  //   this.blurContainer = new Container();

  //   this.dropShadowElement = new PathElement(this, this.blurContainer, {
  //     ...this.elementAttributes,
  //     ...this._svgAttributes,
  //     'stroke-width': 0,
  //     fill: this.convertHexToNumber(this.getColorValue(color)),
  //   });

  //   this.container.addChildAt(this.blurContainer, 0);

  //   this.blurContainer.setTransform(-margin, -margin);
  //   const filter = new BlurFilter(strength);
  //   this.dropShadowElement.element.filters = [filter];
  // }

  zoomUpdate(): void {
    super.zoomUpdate();
    this.pathSections.map(ps => ps.zoomUpdate());
    this.pathShapeRC?.zoomUpdate();
  }

  addTrajectoryAnimation(): void {}

  registerOrientationPoints() {
    try {
      const [[xs, xe], [ys, ye]] = this.getBBox();

      // this.orientationService.registerHorizontal(this.IRI, [xs, ys]);
      // this.orientationService.registerHorizontal(this.IRI, [xs, ye]);

      // this.orientationService.registerHorizontal(this.IRI, [xe, ys]);
      // this.orientationService.registerHorizontal(this.IRI, [xe, ye]);

      // this.orientationService.registerVertical(this.IRI, [xs, ys]);
      // this.orientationService.registerVertical(this.IRI, [xs, ye]);

      // this.orientationService.registerVertical(this.IRI, [xe, ys]);
      // this.orientationService.registerVertical(this.IRI, [xe, ye]);
    } catch (error) {
      console.log('-------- error ----------', error.message);
    }
  }

  getBBox() {
    const { x, y, width, height } = this.container.getBounds();
    return [
      [x, x + width],
      [y, y + height],
    ];
  }

  get _elements() {
    if (!this.currentPathSections) {
      return [];
    }

    let elements = this.currentPathSections.map(ps => ps.getPathItem());
    if (!this.closed) {
      elements = elements.slice(0, elements.length - 1);
    }
    // TODO - fix config
    // @ts-ignore
    return elements.flat();
  }

  _getElements(start?: number, end?: number) {
    let sections = this.currentPathSections;
    if (!this.closed) {
      sections = sections.slice(0, sections.length - 1);
    }

    return sections
      .map(section => section.getPathItem(start, end))
      .flat()
      .filter(v => !!v);
  }

  getElements(start: number, end: number) {
    if (this.svgAttributes.stroke.dash) {
      // -- //
    } else {
      const _end = start < 0 ? end + start : end;

      const sectionsToConsider = this._pathSections.filter(
        ps => start < ps.endLength && ps.startLength < _end,
      );

      const elements = sectionsToConsider.map(ps =>
        ps.getPathItem(start, _end),
      );
      // @ts-ignore
      return elements.flat();
    }
  }

  rotateIncrement(deg: number) {
    // TODO - migrate to PIXI
    // const { cx, cy } = this.elementGroup.getBBox();

    // const diffs = [];

    // this.pathSections.map(section => {
    //   const [x, y] = section.absCoords;
    //   diffs.push(Vector.dRotate([cx - x, cy - y], deg));
    // });

    // const ratio = 0.9381107837387799;
    // diffs.map(([dx, dy], i) => {
    //   const [prevDx, prevDy] = diffs[i - 1] || diffs[diffs.length - 1];
    //   if (i === 0) {
    //     this.x += prevDx;
    //     this.y += prevDy;
    //   }
    //   const section = this.pathSections[i];

    //   section.x += dx - prevDx;
    //   section.y += dy - prevDy;

    //   section.x *= ratio;
    //   section.y *= ratio;
    // });

    // TODO - revise that part
    // this.pathSections.map(section => section.calcD());

    this.refresh();
    this.redraw();

    // --> //
    this.patch('sections', cloneDeep(this.sectionsDescriptor));
    this.save();
  }

  copy(IRI: string, position: ShapePosition, config: PathShapeConfig) {
    // console.log('---copy', position); // -- // -- // -- // -- //
    return new PathShape(
      this.service,
      {
        IRI,
        type: 'nw:Shape',
        literals: {
          descriptor: {
            ...omit(this.descriptor, [
              'multiplication',
              '_animationsByKey',
              'code',
              'floatEffect',
            ]),
            position,
          },
        },
        // relationships: omit(this.relationships, ['parent']), //
        relationships: {
          ...this.clone(this.relationships),
          parent: this.parent.IRI,
        },
      },
      config,
    );
  }

  flip() {
    this.pathSections.map(ps => ps.flip());
    this.pathSections.map(ps => ps.save());
    this.saveSections();
    this.save();
    this.refresh();
  }

  selectManyZero = false;

  getDragBatches(indexes: number[], legnth: number) {
    const batches = [];
    let currentBatch = [];

    // -- //
    for (const index of indexes) {
      if (!currentBatch.length) {
        currentBatch.push(index);
        continue;
      }

      const last = currentBatch[currentBatch.length - 1];
      if (last + 1 == index || (last == legnth - 1 && index == 0)) {
        currentBatch.push(index);
      } else {
        batches.push(currentBatch);
        currentBatch = [index];
      }
    }
    if (currentBatch.length) {
      batches.push(currentBatch);
    }

    const [first, last] = [indexes[0], indexes[indexes.length - 1]];
    // -- //
    // if ((last == length - 1 && first == 0) || (last == 0 && first == legnth - 1)) {
    if ((last == 0 && first == 1) || (first == 0 && last == length - 1)) {
      const firstSection = batches.shift();
      const lastSection = batches.pop();
      batches.push([...lastSection, ...firstSection]);
    }

    return batches;
  }

  _batchesToDrag: number[];

  startBaseDrag(
    point?: string,
    sourceShapeIRIs?: Record<string, boolean>,
    noConstraint = false,
  ): void {
    super.startBaseDrag(point, sourceShapeIRIs, noConstraint);
    this.unMoved = [this.x, this.y];
    // -- // -- // -- // -- //
  }

  lastToDrag: PathSection;

  dragSections(dx: number, dy: number) {
    // if (lastIndex == this.pathSectionLength - 1) {
    //   this.adjustPosition(dx, dy);
    // }
    // console.log('path-shape > dragSections', this._batchesToDrag);
    const first = this.pathSections[this._batchesToDrag[0]];
    // this.lastToDrag?.adjust(dx, dy);
    // this.cs.postAdjusts();

    // console.log(
    //   'first.index',
    //   first.index,
    //   'last.index',
    //   this.lastToDrag.index,
    // );

    // console.log('first', first.x, first.y); //
    // console.log('next', first.nextPS.x, first.nextPS.y); //
    // console.log('last', this.lastToDrag.x, this.lastToDrag.y); //

    first._drag(dx, dy);
    this.lastToDrag.applyAdjustment(-dx, -dy);

    // -- // -- //

    this.refreshElement();
  }

  getAnimationFunctionValue(animation: AnimationItem) {
    const { key, fcn } = animation;
    if (fcn == 'original') {
      switch (key) {
        case 'sections':
          return { value: this.baseDescriptor.sections };
      }
    }

    return super.getAnimationFunctionValue(animation);
  }

  endPathShapeDrag() {
    this.x += this.dx;
    this.y += this.dy;
    this.currentlyDragged = false;
    this.dx = 0;
    this.dy = 0;
    this.cs.previewShape.hideAuxLines();
    console.log('hide-orientation-points');
  }

  batchesToDrag: number[][];

  prepareForBatchDrag(indexes: number[]) {
    this._batchesToDrag = indexes;
    this.pathSections.map(ps => ps.saveAbsCoords());

    const lastIndex = this._batchesToDrag[this._batchesToDrag.length - 1];
    const adjustIndex =
      lastIndex == this.pathSectionLength - 1 ? 0 : lastIndex + 1;

    this.lastToDrag = this.pathSections[adjustIndex];
    this.lastToDrag.setUnMoved();

    this.select();
    // this._batchesToDrag = indexes.sort((i1, i2) => i1 - i2);
    // indexes = indexes.sort((i1, i2) => i1 - i2); //
    // this.batchesToDrag = this.getDragBatches(indexes, this.pathSectionLength); //
  }

  batchDrag(dx: number, dy: number) {
    // -- // -- //
    this.batchesToDrag.map(batch => {});
  }

  preSelectMany(indexesToSelect: number[]) {
    console.log({ indexesToSelect });
    const selectedIndexes = this.pathSections
      .filter(ps => ps.selected)
      .map(ps => ps.index);

    const otherIndexesToSelect = this.getIndexesToSelect(
      selectedIndexes,
      indexesToSelect,
      this.pathSections.length,
    );

    const _indexes = [...indexesToSelect, ...otherIndexesToSelect];
    if (!_indexes?.length) {
      return;
    }
    this.selectMany(_indexes);

    const selected2s = this.pathSections
      .filter(ps => ps.selected)
      .sort((ps1, ps2) => ps1.index - ps2.index);

    const indexes = selected2s.map(ps => ps.index);

    this.nullIncluded = indexes.includes(this.pathSections.length - 1);

    if (indexes.includes(0)) {
      for (let i = 0; i < indexes.length; i++) {
        if (indexes[i] !== i) {
          this.currentSelected2First = selected2s[i];
          this.currentSelected2Last = selected2s[i - 1];
          this.unMoved = [this.x, this.y];
          this.unMovedOffset = [this.offsetX || 0, this.offsetY || 0];
          break;
        }
      }
    }

    console.log({ selected2s: selected2s.map(ps => ps.index) });
    if (!this.currentSelected2First) {
      this.currentSelected2First = selected2s[0];
      this.currentSelected2Last = selected2s[selected2s.length - 1];
    }
  }

  selectMany(indexes: number[]) {
    this.selectManyZero = indexes.includes(0);

    if (indexes.length === this.pathSections.length) {
      this.deselectSections();
      this.select();
      return;
    }

    indexes
      .map(index => this.pathSections.find(ps => ps.index === index))
      .map(ps => ps.selectMany());
  }

  getPathSectionByIndex(i: number) {
    return this.pathSections.find(({ index }) => index === i);
  }

  deselectSections() {
    this.currentSelected2First = null;
    this.currentSelected2Last = null;
    this.selectManyZero = false;
    this.adjustContraints.multiPointDrag = undefined;
    this.pathSections.filter(ps => ps.selected).map(ps => ps.deselectSide());
  }

  maskCopyElement: PathElement;

  setMeAsMask(maskTarget: PrimitiveShape) {
    // maskTarget.maskedBy = this.IRI;
    if (!this.maskCopyElement) {
      this.maskCopyElement = new PathElement(
        this,
        this.container,
        {
          ...this.elementConfig,
          ...this.resolvedSVGAttributes,
          // noFill: true
          fill: '#ff0000',
        },
        0,
      );
      this.maskCopyElement.show();
    }

    maskTarget.container.mask = this.maskCopyElement.element;
    // maskTarget.container.mask = this.maskCopyElement.element;

    // Object.values(maskTarget.multipliedShapes || {}).map(shape => {
    //   shape.container.mask = this.maskCopyElement.element;
    // });
  }

  getIndexesToSelect(selected: number[], toSelect: number[], cnt: number) {
    if (!selected?.length) {
      return [];
    }

    const gaps: Array<{ start: number; length: number; prio: number }> = [];

    const [startGap, endGap] = [
      selected[0],
      cnt - toSelect[toSelect.length - 1] - 1,
    ];
    gaps.push({
      start: toSelect[toSelect.length - 1],
      length: startGap + endGap,
      prio: 3,
    });

    gaps.push({
      start: selected[selected.length - 1],
      length: toSelect[0] - selected[selected.length - 1] - 1,
      prio: 1,
    });

    for (let i = 0; i < toSelect.length - 1; i++) {
      const gap = toSelect[i + 1] - toSelect[i];
      if (gap > 1) {
        gaps.push({
          start: toSelect[i],
          length: gap - 1,
          prio: 2,
        });
      }
    }

    if (gaps.length === 1) {
      return [];
    }

    return gaps
      .sort((g1, g2) => {
        if (g1.length !== g2.length) {
          return g2.length - g1.length;
        }
        return g1.prio - g2.prio;
      })
      .slice(1)
      .sort((g1, g2) => g1.start - g2.start)
      .reduce((acc, curr) => {
        for (let i = 0; i < curr.length; i++) {
          const val = curr.start + i + 1;
          acc.push(val >= cnt ? val - cnt : val);
        }
        return acc;
      }, []);
  }

  saveState() {
    super.saveState();
    this.patch('sections', cloneDeep(this.sectionsDescriptor));
  }

  currentSelected2First: PathSection;
  currentSelected2Last: PathSection;

  unMoved: [number, number];
  nullIncluded = false;

  dragSide(dx: number, dy: number) {
    if (!this.currentSelected2First) {
      return;
    }

    // this.pathSections
    //   .filter(ps => ps.selected)
    //   .map(ps => ps.move(dx, dy));
    //   this.refresh();
    //   this.cs.postAdjusts();
    // return;
    this.currentSelected2First.move(dx, dy);
    this.currentSelected2Last.nextPS.adjust(dx, dy);

    this.pathSections
      .filter(ps => ps.selected)
      .filter(
        ps =>
          ps.id !== this.currentSelected2Last.nextPS.id &&
          ps.id !== this.currentSelected2First.id,
      )
      .map(ps => {
        ps.applyConstraints(dx, dy, { shapeDrag: true });
      });

    if (this.nullIncluded) {
      this.adjustPosition(dx, dy);
    }
    this.refresh();
    this.cs.postAdjusts();
  }

  positionChanged = false;

  start() {
    this.unMoved = [this.x, this.y];
    this.unMovedOffset = [this.offsetX, this.offsetY];
  }

  get actualX() {
    return this.x + this.dx;
  }

  get actualY() {
    return this.y + this.dy;
  }

  adjustPosition(dx: number, dy: number) {
    this.dx = dx;
    this.dy = dy;
    this.positionChanged = true;
    // TODO - fix that this.unMoved is never undefined //
    // this.translateTo(this._x + dx, this._y + dy); //
    this.container?.setTransform(this.x + dx, this.y + dy);
    this.circleContainer?.setTransform(this.x + dx, this.y + dy);
    this.auxCircleContainer?.setTransform(this.x + dx, this.y + dy);
    this.sectionContainer?.setTransform(this.x + dx, this.y + dy);
  }

  endDragMany() {
    // this.currentSelected2First = null;
    // this.currentSelected2Last = null;
    // this.nullIncluded = false;
    // this.pathSections.map(ps => ps.deselectMany());

    this.x += this.dx;
    this.y += this.dy;

    this.dx = 0;
    this.dy = 0;

    this.unMoved = null;
    this.save();
  }

  reInitPathSections(sections: PathSectionDescriptor[]) {
    this.descriptor.sections = sections;
    this.initPathSections();
    this.refresh();
  }

  sections: PathSectionDescriptor[];

  initPathSections() {
    this.pathSections?.map(ps => ps.remove());
    // console.log('init-path-sections', this.sections?.length);
    this.pathSections =
      this.sections?.map((section: any, index: number) => {
        const descriptor = { ...section, index };
        const psConfig: PathSectionConfig = {};
        if (this.config?.initByDrag && index == 0) {
          psConfig.initByDrag = true;
        }

        switch (descriptor.type) {
          case 'arc':
            return new ArcSection(this, descriptor, psConfig);
          case 'curve':
            return new CurveSection(this, descriptor, psConfig);
          default:
            return new PathSection(this, descriptor, psConfig);
        }
      }) || [];

    // TODO - check if it is necessary
    // this.pathSections.map(ps => {
    //   const [x, y] = ps.getCoords();
    //   ps.setAbsCoords(x, y);
    //   ps.refreshRData();
    // });

    this.pathSections.map(ps => ps.init());
    this.pathSections.map(ps => ps.postInit());
  }

  initPathSections2() {
    // this.pathSections?.map(ps => ps.updateConstraints());
  }

  amILast(index: number) {
    return index == this.pathSectionLength - 1;
  }

  amILastButOne(index: number) {
    return index == this.pathSectionLength - 2;
  }

  postInit() {
    this.initPathSections2();
  }

  bounds: Coords;

  setOffset() {
    if (!this.container) {
      return;
    }

    const { x, y, width, height } = this.container.getBounds();

    this.offsetX = this.x - x;
    this.offsetY = this.y - y;
    this.w = width;
    this.h = height;

    // -- dx += this.dx; -- //
    // -- dy += this.dy; -- //

    // if (dx || dy) {
    //   // this.offsetX += dx;
    //   // this.x -= dx;
    //   // this.offsetY += dy;
    //   // this.y -= dy;

    //   console.log('set-position-data', this.offsetX, this.offsetY);
    //   return true;
    // }

    return true;
  }

  // This function is related to the constraint
  // _drag(dx: number, dy: number) {
  //   const [x0, y0] = this.unMoved;
  //   const [ox0, oy0] = this.unMovedOffset;

  //   if (this.offsetX) {
  //     this.offsetX = Math.max(0, ox0 + dx);
  //   } else {
  //     this.x = x0 + ox0 + dx;
  //   }

  //   if (this.offsetY) {
  //     this.offsetY = Math.max(0, oy0 + dy);
  //   } else {
  //     this.y = y0 + oy0 + dy;
  //   }
  //   this.redraw();
  //   this.refresh();
  // }

  reset() {
    this.reInit();
    this.redraw();
  }

  _hide() {
    super._hide();
    if (this.sectionContainer) {
      this.sectionContainer.visible = false;
    }

    this.element?.hide();
  }

  _show() {
    super._show();
    if (this.sectionContainer) {
      this.sectionContainer.visible = true;
    }
    this.element.show();
  }

  /*************************** Whole component drag *****************************/

  drag(dx: number, dy: number) {
    // console.log('ps-drag', dx, dy);
    super.drag(dx, dy);
    this.pathSections.map(ps =>
      ps.applyConstraints(dx, dy, { shapeDrag: true }),
    );
  }

  updateConstraints(noSave = false) {
    // TODO - we need check if there we any kind change from the constraints
    // only then we need to save
    this.pathSections.map(ps => ps.updateConstraints());
    if (noSave) {
      return;
    }
    this.saveSections({ noPatchIncrement: true });
  }

  updateOpacity(opacity: number) {
    this.container.alpha = opacity;
    return;
    this.element.patch({
      'fill-opacity': opacity * this.opacity,
      'stroke-opacity': opacity * this.opacity,
    });
  }

  get constraintedShapes() {
    const arr = [];
    this.pathSections.map(ps =>
      arr.push(
        ...ps.constrainedSections
          .map(section => section.pathShape.IRI)
          .filter(IRI => IRI !== this.IRI),
      ),
    );
    return [...new Set(arr)]
      .map(IRI => this.cs.resourceStore[IRI])
      .filter(s => !!s);
  }

  finishInit(): void {
    super.finishInit();
    this.saveSections();
  }

  save() {
    super.save();
    this.saveSections();
  }

  // TODO - implement
  // initDropShadowElement(config: DropShadowConfig, opacity: number) {
  //   console.log('init-drop-shadow-element', config);
  //   const { x, y, width, height } = this.container.getBounds();

  //   const { ratio, color, dx: _dx, dy: _dy } = config;
  //   const [dx, dy] = [(ratio - 1) * width, (ratio - 1) * height];
  //   this.blurContainer = new Container();
  //   this.blurContainer.scale.x = ratio;
  //   this.blurContainer.scale.y = ratio;

  //   this.dropShadowElement = new PathElement(this, this.blurContainer, {
  //     ...this._svgAttributes,
  //     ...this.elementConfig,
  //     'stroke-width': 0,
  //     fill: this.convertHexToNumber(this.getColorValue(color)),
  //   });

  //   this.container.addChildAt(this.blurContainer, 0);

  //   this.blurContainer.setTransform(dx / 2 + _dx, -dy / 2 + _dy, ratio, ratio);
  //   // const filter = new BlurFilter(12);
  //   // this.dropShadowElement.element.filters = [filter];
  // }

  updateTranslate(x: number, y: number) {
    this.x += x;
    this.y += y;
    this.dx = 0;
    this.dy = 0;
    this._dx = 0;
    this._dy = 0;
    this.redraw();
    this.saveTranslate();
  }

  saveCoordinates() {
    this.unMoved = [this.x, this.y];
    this.unMovedOffset = [this.offsetX || 0, this.offsetY || 0];
  }

  flipClosed() {
    this.closed = !this.closed;
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'closed',
        value: this.closed,
      }),
    );
    this.refresh();
  }

  saveSections(_params?: {
    noPatchIncrement?: boolean;
    section?: PathSection;
  }) {
    // console.log('position-before', JSON.stringify(this.position));

    if (!this.newCurveAngle) {
      this.pathSections.map(ps => {
        if (ps instanceof CurveSection) {
          ps.migrateToNewAngleRep();
        }
      });
      this.descriptor.newCurveAngle = true;
      this.refresh();
    }

    this.sections = this.sectionsDescriptor;
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'sections',
        value: this.sectionsDescriptor,
      }),
    );

    // if (this.positionChanged) {
    //   this.x += this.dx; // -- //
    //   this.y += this.dy; // -- //
    // }

    const positionChanged = this.setOffset();
    // console.log('position-after', JSON.stringify(this.position));
    if (positionChanged || this.positionChanged) {
      // this.patch('transform', cloneDeep(this.position), true);
      this.positionChanged = false;
    }

    this.pathSections.map(ps => {
      ps.updateConstraints();
      ps.save();
    });

    this.updateControlPoints();
    // this.cs.indirectPatch(); // -- // -- //
  }

  patchByKey(key: string) {
    switch (key) {
      case 'sections':
        this.patch('sections', cloneDeep(this.sectionsDescriptor));
        break;
      case 'transform':
        this.patch('transform', cloneDeep(this.position));
        break;
    }
  }

  patchOverrideByKey(key: string) {
    switch (key) {
      case 'sections':
        this.patchOverride('sections', cloneDeep(this.sectionsDescriptor));
        break;
      case 'transform':
        this.patchOverride('transform', cloneDeep(this.position));
        break;
    }
  }

  addLineSection(index: number) {
    this.addSection(index, 'line');
  }

  addCurveSection(index: number) {
    const section = this.addSection(index, 'curve') as CurveSection;
    section.refresh();
  }

  addAnchorCurveSection(index: number) {
    const section = this.addSection(index, 'curve') as CurveSection;
    section.refresh();
  }

  addSection(index: number, type: 'line' | 'curve' | 'anchor-curve') {
    let newSection: PathSection;
    const descriptor: PathSectionDescriptor = {
      id: Math.random().toString(),
      index,
      x: 0,
      y: 0,
    };
    const config = { initByDrag: true };
    switch (type) {
      case 'line':
        newSection = new PathSection(this, descriptor, config);
        break;
      case 'curve':
      case 'anchor-curve':
        (descriptor as CurveSectionDescriptor).h1 = 100;
        (descriptor as CurveSectionDescriptor).h2 = 100;
        newSection = new CurveSection(this, descriptor, config);
    }

    if (type == 'anchor-curve') {
      console.log('setting-prev-curve-anchor', this.pathSections[index - 1]);
      this.pathSections[index - 1].anchor = true;
      descriptor.anchor = true;
    }

    if (type == 'curve' && this.pathSections[index - 1].type == 'cs') {
      this.pathSections[index - 1].anchor = false;
    }

    console.log('addSection', index);
    this.pathSections.splice(index, 0, newSection);
    this.updateIndexes();
    newSection.init();
    newSection.postInit();
    newSection.select();
    this.select();
    this.pathSections.map(ps => ps.refresh());
    return newSection;
  }

  updateIndexes() {
    this.pathSections.map((section, i) => (section.index = i));
  }

  deleteSection(section: PathSection) {
    const next = section.nextPS;
    next.x += section.x;
    next.y += section.y;

    if (section.amILast) {
      this.x -= section.x - this.offsetX;
      this.y -= section.y - this.offsetY;
      this.setPosition({ x: this.x, y: this.y });
    }

    this.pathSections.filter((_s, i) => i > section.index).map(s => s.index--);
    this.pathSections = this.pathSections.filter(({ id }) => id !== section.id);
    console.warn('delete-sections', this.pathSections);
    section.remove();
    // TODO - check if it is necessary
    this.refresh();
    this.refresh();
    this.save();
  }

  getRoundedBounds() {
    const { x, y, width, height } = this.container.getBounds();
    return {
      x: +x.toFixed(4),
      y: +y.toFixed(4),
      width: +width.toFixed(4),
      height: +height.toFixed(4),
    };
  }

  startTransformation(dx = 0, dy = 0) {
    this.pathSections.map(ps => ps.startDragByWeight());
    // console.log('path-shape > startTransformation');
    super.startTransformation(dx, dy);
  }

  transformation(scaleX = 1, scaleY = 1, dx?: number, dy?: number) {
    // console.log('path-shape > transformation', scaleX, scaleY);
    this.pathSections.forEach(ps => ps.updateByScale(scaleX, scaleY));
    super.transformation(scaleX, scaleY, dx, dy);
    this.refresh();
  }

  endTransformation(): void {
    // -- // -- //
    super.endTransformation();
    this.saveSections();
  }

  hideDragControllers(): void {
    super.hideDragControllers();
    this.pathSections.map(section => section.deselect());
  }

  select(params: ShapeSelectParams = {}) {
    if (!params?.onHover && this.cs.pathShapeSelectMode) {
      this.cs.generalEventEmit('path-shape-select', this.IRI);
      return;
    }

    super.select(params);
    // console.log('ps-select', params);
    if (
      (this.groupShapeParent && !this.groupShapeParent.selected) ||
      this.importedShapeParent
    ) {
      return;
    }

    const { onHover } = params;

    this.baseLine.show(0.5 / this.cs.canvasScale);
    if (!this.selected && onHover) {
      return;
    }

    this.selected = true;
    this.pathSections?.map(section => section.select());
    if (this.cs.isShiftPressed) {
      this.showRC();
    } else {
      this.rc?.remove();
    }
  }

  _select() {
    super._select();
  }

  showRC() {
    console.log('show-rc', this.rc);
    if (!this.pathShapeRC) {
      this.initRC();
    } else {
      this.pathShapeRC.show();
    }
  }

  pathShapeRC: RectangleController;

  initRC(): void {
    // this.pathShapeRC?.remove();
    const { x, y, width, height } = this.getRoundedBounds();
    console.log('path-shape > init-rc', { x, y, width, height });

    const { x: xa, y: ya } = this.cs.getAbsoluteCoords(x, y);

    this.offsetX = this.x - xa;
    this.offsetY = this.y - ya;

    console.log('offsetX', this.offsetX, 'offsetY', this.offsetY);

    this.pathShapeRC = new RectangleController(
      this,
      {
        offset: [-this.offsetX, -this.offsetY],
        // width,
        // height,
        width: width / this.cs.canvasScale,
        height: height / this.cs.canvasScale,
        startDrag: () => {
          this.startTransformation();

          this._w = width / this.cs.canvasScale;
          this._h = height / this.cs.canvasScale;
        },
        drag: ({ x, y, width, height }) => {
          const scaleX = width / this._w;
          const scaleY = height / this._h;

          const dx = x - this._x;
          const dy = y - this._y;

          this.transformation(scaleX, scaleY);

          this.pathShapeRC.patch({
            offset: [-this.offsetX, -this.offsetY],
          });

          this.refresh();
          this.redraw();
        },
        endDrag: () => {
          const { x, y, width: _w, height: _h } = this.getRoundedBounds();

          this.save();
          this.saveSections();
          this.pathShapeRC.patch({
            width: _w,
            height: _h,
            offset: [-this.offsetX, -this.offsetY],
          });
        },
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
  }

  deselect(params: ShapeSelectParams = {}) {
    // console.log('ps-deselect', { params, selected: this.selected }); //
    super.deselect(params);
    const { onHover } = params;

    this.cs.customShapeToDrag = null;

    if (this.selected && onHover) {
      // console.log('selected.onhover > return', this.selected); //
      return;
    }

    this.baseLine.hide();

    if (!this.selected && onHover) {
      this.pathSections.map(section => section.deselect());
      return;
    }

    this.iamDragged = false;

    this.pathSections.map(section => section.deselect());
    this.currentSelected2First = null;
    this.currentSelected2Last = null;
    this.nullIncluded = true;
    this.pathShapeRC?.hide();
  }

  getParamKeys(type: string) {
    switch (type) {
      case 'arc':
        return ['x', 'y', 'r', 'cc'];
      case 'arcd':
        return ['d', 'a', 'r', 'cc'];
      case 'line':
        return ['x', 'y', 'r'];
      case 'lined':
        return ['d', 'a', 'r'];
      case 'curve':
        return ['x', 'y', 'b', 'h', 'b2', 'bs', 'cc'];
      case 'curved':
        return ['d', 'a', 'b', 'h', 'b2', 'bs', 'cc'];
    }
  }

  // Maybe we need it somewhere
  get currentTrajectoryAnimation() {
    return this.animationsById[this.cs.currentAnimation.id][
      'trajectory-transform'
    ].value as LineAnimation;
  }

  patchLineAnimation(animation: LineAnimation) {
    this.trajectoryAnimations[this.cs.currentAnimation.id].patch(
      animation,
      this.cs.currentAnimation.duration,
    );
    this.save();
  }

  getConstructor(inputs: string, keys?: string[]): Record<string, any> {
    const o = {};
    inputs.split(',').map((keyValue, index) => {
      let [key, value] = keyValue.split(':');
      if (!value) {
        value = key;
        key = keys[index];
      }
      if (key === 'cc') {
        o[key] = value === 'true';
      } else {
        o[key] = !isNaN(+value) ? +value : value;
      }
    });
    return o;
  }

  get currentPathSections() {
    return this.pathSections;
  }

  _deselect() {
    this.pathShapeRC?.hide();
    return super._deselect();
  }

  _dx = 0;
  _dy = 0;

  movePath(dx: number, dy: number) {
    this.dx = dx;
    this.dy = dy;
    // this.element.patch({
    //   position: {
    //     x: dx,
    //     y: dy,
    //   },
    // });
  }

  saveScale() {}

  refresh(start?: number, end?: number) {
    // TODO fix - this.lastSection should not be undefined //

    // console.log('-------------  ps-refresh -----------');
    this.closed
      ? this.lastSection?.showHoverPaths()
      : this.lastSection?.hideHoverPaths();

    this.pathSections?.map(s => {
      s._setAbsCoords();
      s.refresh();
    });
    this.refreshElement(start, end);
  }

  intervalStart: number;
  intervalEnd: number;

  _refreshElement() {
    if (this.svgAttributes.stroke?.dash) {
      // -- // -- //
    } else {
      super.refreshElement();
    }
  }

  refreshElement(start?: number, end?: number) {
    this.intervalStart = start;
    this.intervalEnd = end;
    // console.log('----- ps > refresh-element ---------', this.svgAttributes);
    if (this.svgAttributes.stroke?.dash) {
      this.element?.hide();
      this.initDash(this.svgAttributes.stroke.dash);
    } else {
      this.element?.show();
      this.dashMoveElements?.map(e => e.remove());

      const [x, y] = this.rOffset;
      if (this.dx || this.dy) {
        const [cx, cy] = [this.x + this.dx, this.y + this.dy];
        this.mainContainer.setTransform(cx, cy);
        this.circleContainer.setTransform(cx, cy);
        this.auxCircleContainer.setTransform(cx, cy);
      }

      const elements = this._getElements(start, end);
      const elementConfig = {
        // position: { x: x + this.dx, y: y + this.dy },
        position: { x, y },
        elements,
      };

      this.element.patch({ ...elementConfig, ...this.resolvedSVGAttributes });
      this.baseLine.patch({
        ...elementConfig,
        'stroke-width': 0.5 / this.cs.canvasScale,
      });
      this.maskElement?.patch(elementConfig);
    }
  }

  getElementConfig(start?: number, end?: number) {
    // -- // -- //
  }

  refreshInterval(start: number, end: number) {
    const elements = this.pathSections.map(ps => ps.getPathItem(start, end));
    elements.flat();
    this.element.patch({
      elements: elements as PathItem[],
    });
  }

  elementDx = 0;
  elementDy = 0;

  get elementConfig() {
    const [x, y] = this.rOffset;
    // console.log('r-offset', x, y);
    return {
      // TODO - move it back
      position: { x: x + this.dx, y: y + this.dy },
      elements: this._elements,
      closed: this.closed,
    };
  }

  get lastXY() {
    return this.pathSections.slice(0, this.pathSectionLength - 1).reduce(
      (acc, curr) => ({
        x: acc.x - (curr.x || 0),
        y: acc.y - (curr.y || 0),
      }),
      { x: 0, y: 0 },
    );
  }

  endAnimationByKeyValue(key: string, value: any, inverse = false): void {
    super.endAnimationByKeyValue(key, value, inverse);
    this.sectionSteppers = null;
    switch (key) {
      case 'path-fill':
        this.pathFillAnimationIncrement = null;
        break;
      case 'trajectory-appear':
        this.endIncrement = null;
        this.currentEnd = 0;
        break;

      case 'sections':
        if (inverse) {
          this.sections = this.lastSections;
        } else {
          this.sections = value
            ? cloneDeep(value as PathSectionDescriptor[])
            : this.baseDescriptor.sections;
        }
        // this.reInit();
        break;
      case 'trajectory':
        Object.values(this.trajectoryAnimations).map(trajectoryAnimation =>
          trajectoryAnimation.delete(),
        );
        this.trajectoryAnimations = {};
        break;

      case 'trajectory-fill':
        this.applyTrajectoryFill(value as TrajectoryFillAnimation, 1);
        this.trajectoryFillAnimationState = null;
    }
  }

  // I'm not sure that we need this
  // setSVGAttribute(key: string, value: string | number): void {
  //   this.trajectoryAnimation?.patchSVGAttribute(key, value);
  //   super.setSVGAttribute(key, value);
  // }

  reInit() {
    this.initPathSections();
    this.pathSections.map(ps => ps.refresh());
    this.pathSections.map(ps => ps.updateConstraints());
    this.refresh();
    this.saveCoordinates();
    this.cs.reInits.push(this);
    this.redraw();
    this.deselect();
  }

  sectionSteppers: PathSectionStepper[];
  attrSteppers: Record<string, PathAttrStepper>;

  endIncrement: number;
  currentEnd: number;

  trajectoryFillAnimationState: IncrementState;

  applyAnimation(id: string, duration: number) {
    super.applyAnimation(id, duration);

    this.getAnimationsById(id).map(animation => {
      let { key, value, meta, fcn } = animation;
      if (fcn) {
        const fcnAnimation = this.getAnimationFunctionValue(animation);
        value = fcnAnimation.value;
      }

      switch (key) {
        case 'loop-trajectory-transform':
          this.prepareLoopTrajectoryAnimation(value as LoopTrajectoryAnimation);
          break;
        case 'trajectory':
          this.prepareTrajectoryAnimation(id, duration, value as LineAnimation);
          break;
        case 'trajectory-appear':
          this._show();
          break;
        case 'trajectory-fill':
          this.applyTrajectoryFill(value as TrajectoryFillAnimation, 1);
          break;
        case 'sections':
          this.descriptor.sections = cloneDeep(value as SectionAnimation);
          this.lastPatchIDByKey.sections = id;
          this.reInit();
          this.deselect();
          break;
      }
    });

    this.saveCoordinates();
  }

  lastSections: PathSectionDescriptor[];

  startAnimation(
    id: string,
    division: number,
    inverse = false,
    duration?: number,
  ) {
    super.startAnimation(id, division, inverse, duration);

    this.getAnimationsById(id).map(animation => {
      const { key, meta, fcn } = animation;
      let { value } = animation;
      if (fcn) {
        const fcnAnimation = this.getAnimationFunctionValue(animation);
        value = fcnAnimation.value;
      }

      switch (key) {
        case 'loop-trajectory-transform':
          this.trajectorySteppers = this.trajectoryStepperStore[id];
          break;

        case 'trajectory-appear':
          const totalLength = this._pathSections.reduce((prev, curr) => {
            prev += curr.d;
            return prev;
          }, 0);

          this.currentEnd = 0;
          this.endIncrement = totalLength / division;
          break;

        case 'trajectory':
          this.prepareTrajectoryAnimation(
            id,
            duration,
            value as LineAnimation,
            division,
          );
          break;
        case 'path-fill':
          this.preparePathFillAnimation(value as PathFillAnimation, division);
          break;
        case 'dash-move':
          this.dashController?.prepareMove((value as DashMoveAnimation).speed);
          break;
        case 'path':
          this.preparePathAnimation(value as PathAnimation, division);
          break;
        case 'trajectory-fill':
          this.applyTrajectoryFill(value as TrajectoryFillAnimation, 0);
          this.trajectoryFillAnimationState = {
            current: 0,
            increment: 1 / division,
          };
          break;
        case 'sections':
          this.initSectionSteppers(
            this.sections,
            value as PathSectionDescriptor[],
            division,
            meta?.ease,
            inverse,
          );
          break;
      }
    });
  }

  pathAnimationIncrement: IncrementController;
  currentPathAnimation: PathAnimation;
  currentPathFillAnimation: PathFillAnimation;
  pathFillAnimationIncrement: IncrementController;

  preparePathFillAnimation(animation: PathFillAnimation, division: number) {
    const { start, end, reverse } = animation;
    this.pathSections.map(section => section.setDashCache());
    this.currentPathFillAnimation = animation;
    this.pathSections.map(ps => ps.setLengths());

    // -- // -- // -- //
    this.pathFillAnimationIncrement = new IncrementController(
      start || 0,
      end == -1 ? this.endLength : end,
      division,
      Easing.LINEAR,
    );
    this.applyInterval(0, start || 0);
  }
  preparePathAnimation(animation: PathAnimation, division: number) {
    this.currentPathAnimation = animation;
    const { length, offset, mode, gap, speed, fillSpeed } = animation;
    switch (mode) {
      case 'fill':
        this.pathSections.map(section => section.setDashCache());
        this.currentPathAnimation = animation;
        this.pathSections.map(ps => ps.setLengths());

        this.pathAnimationIncrement = new IncrementController(
          offset || 0,
          this.endLength - length - 1,
          division,
          Easing.LINEAR,
        );
        this.refreshElement(0, (offset || 0) + length);
        this._show();
        break;
      case 'move':
        this.prepareDashMoveAnimation(animation, division);
        break;
    }
  }

  dashMovePositions: Array<{
    start: number;
    end: number;
  }> = [];
  dashMoveElements: PathElement[];

  dashMoveInterval: number;
  currentDashMove: { length: number; gap: number };

  dashMoveLimit: number;
  dashMoveLimitController: IncrementController;

  prepareDashMoveAnimation(animation: PathAnimation, division: number) {
    this.dashMoveElements?.map(e => e.remove());

    const { length, gap, speed, fillSpeed } = animation;
    const endLength = this.endLength;
    // || 300;

    if (fillSpeed) {
      this.dashMoveLimit = 0;
      this.dashMoveLimitController = new IncrementController(
        0,
        endLength,
        division / fillSpeed,
        Easing.LINEAR,
      );
    }

    this._show();
    this.element.hide();

    this.currentDashMove = { length, gap };
    let start = 0;
    let end = length;
    this.dashMovePositions = [
      {
        start: -(length + gap),
        end: -gap,
      },
    ];

    this.dashMoveInterval = length + gap;

    while (start < endLength) {
      this.dashMovePositions.push({
        start,
        end: Math.min(end, this.endLength),
      });

      start += length + gap;
      end = start + length;
    }

    this.dashMoveElements = this.dashMovePositions.map(({ start, end }) => {
      const elements = this.pathSections
        .map(ps => ps.getPathItem(start, end))
        .flat()
        .filter(val => !!val);

      // -- // -- // -- // -- //
      // .filter(e => !Array.isArray(e) || !!(e as PathItem[]).length);
      // console.log('elements', elements);

      return new PathElement(this, this.container, {
        position: {
          x: 0,
          y: 0,
        },
        elements,
        ...this.resolvedSVGAttributes,
        'fill-opacity': 0,
      });
    });
  }

  dashController: DashController;

  initDash(dash: DashConfig) {
    this.dashController?.remove();

    const { fill: length, gap } = dash;

    this.pathSections.map(section => section.setDashCache());
    this.pathSections.map(ps => ps.setLengths());

    this.dashController = new DashController(this, length, gap);
    return;
  }

  applyInterval(start: number, end: number) {
    if (this.svgAttributes.stroke?.dash) {
      this.dashController.applyInterval(start, end);
    } else {
      this.element.patch({
        elements: this.getElements(start, end),
      });
    }
  }

  // trajectoryAnimation: TrajectoryAnimation;
  trajectoryAnimations: Record<string, TrajectoryAnimation> = {};

  addLine() {
    const animation = {
      dashArray: { length: 100, speed: 1 },
    };
    // this.addAnimation('trajectory', animation);
    this.save();
    this.prepareTrajectoryAnimation(
      this.cs.currentAnimation.id,
      this.cs.currentAnimation.duration,
      animation,
    );
  }

  prepareTrajectoryAnimation(
    id: string,
    duration: number,
    animation: LineAnimation,
    division?: number,
  ) {
    // this.trajectoryAnimation = new TrajectoryAnimation(this, animation);
    this.trajectoryAnimations[id] = new TrajectoryAnimation(
      this,
      animation,
      duration,
      division,
    );
  }

  trajectoryStepperStore: Record<string, TrajectoryStepper[]> = {};
  trajectorySteppers: TrajectoryStepper[] = [];

  prepareLoopTrajectoryAnimation(value: LoopTrajectoryAnimation) {
    const { cnt, speed, shapes } = value;
    const sectionData = this.pathSections.map(({ x, y, d }) => ({
      x,
      y,
      d,
    }));

    if (!cnt) {
      console.warn(`'cnt' parameter should be larger than zero!`);
    }

    // It is anyway used by arcs and lines
    // TODO - migrate
    const totalLength = 0; // this.pathElement?.element.getTotalLength() || 0;

    const dTransform = this.getDTransform(totalLength / (50 * speed));
    const validShapes = shapes.filter(shape => this.getShapeByIRI(shape));

    const gap = totalLength / cnt;
    const steppers = [];
    for (let i = 0; i < cnt; i++) {
      const shapeIndex = i ? i % validShapes.length : 0;
      const data = this.getShapeByIRI(validShapes[shapeIndex]).resourceData;
      // TODO - revise that
      // const shape = this.service.addShapeByResourceData(
      //   {
      //     ...data,
      //     IRI: `${this.IRI}---${i}---${data.IRI}`,
      //   },
      //   true,
      // );
      // shape.prepareAppearAnimation(1.5);

      // const stepper = new TrajectoryStepper(shape, dTransform, sectionData);
      // steppers.push(stepper);
      // stepper.setD(gap * i);
    }
    return steppers;
  }

  getDTransform(duration: number): TrajectoryDTransform[] {
    // TODO - migrate
    // const totalLength = this.pathElement?.element.getTotalLength() || 0;
    return [];
    // this.pathSections
    //   .slice(
    //     0,
    //     this.isClosed ? this.pathSections.length : this.pathSections.length - 1,
    //   )
    //   .map(ps => ps.getDTransform(totalLength, duration));
  }

  finishSectionAnimations(id: string) {
    // TODO-u - move it back
    // this.reInitPathSections(animation.sections);
  }

  // TODO - migrate - //
  incrementAnimation(increment: number, id?: string, percent?: number) {
    super.incrementAnimation(increment, id, percent);
    // if (this.sectionSteppers) {
    //   // This is a poor solution --> the sectionStepper should be incremented
    //   // this.pathCounter += increment;
    //   this.sectionSteppers.map(ss => ss.increment(increment));
    //   // this.pathElement?.refresh(); //

    //   this.element.patch({
    //     // position: { x: this.rOffset[0], y: this.rOffset[1] },
    //     elements: this.sectionSteppers.map(stepper => stepper.getPathItem()),
    //     closed: this.closed,
    //   });
    // }

    // this.trajectorySteppers?.map(stepper => stepper.increment(increment));

    // Object.values(this.trajectoryAnimations).map(trajectoryAnimation => {
    //   trajectoryAnimation?.incrementAnimation(increment);
    // });

    // if (this.endIncrement) {
    //   this.currentEnd += this.endIncrement * increment;
    //   this.element.patch(
    //     {
    //       elements: this.getElements(0, this.currentEnd),
    //     },
    //     true,
    //   );
    //   this._show();
    // }

    Object.values(this.animationsById?.[id] || {}).map(({ key, value }) => {
      switch (key) {
        case 'trajectory-fill':
          let { current, increment: ttIncrement } =
            this.trajectoryFillAnimationState;
          current += +(increment * ttIncrement).toFixed(6);
          this.applyTrajectoryFill(value as TrajectoryFillAnimation, current);
          this.trajectoryFillAnimationState.current = current;
          break;

        case 'path-fill':
          // -- // -- //
          const currentPathOffset =
            this.pathFillAnimationIncrement.increment(increment);
          // console.log('currentPathOffset', currentPathOffset);

          this.applyInterval(0, currentPathOffset);
          break;
        case 'dash-move':
          this.dashController?.move(increment);
          break;
          // case 'path':
          switch (this.currentPathAnimation.mode) {
            case 'fill':
            case 'fill-reverse':
              // -- // -- // -- //
              const { speed, offset } = value as PathAnimation;
              const currentPathOffset = this.pathAnimationIncrement.increment(
                increment * (speed || 1),
              );
              const { length } = this.currentPathAnimation;
              this.refreshElement(0, currentPathOffset + length);
              break;

            case 'move':
              let incrementDashMove = true;
              if (this.dashMoveLimitController) {
                this.dashMoveLimit =
                  this.dashMoveLimitController.increment(increment);

                if (this.dashMoveLimit < this.endLength) {
                  incrementDashMove = false;
                  // console.log('increment-dash-move > false', this.dashMoveLimit, this.endLength)
                }
              }

              if (incrementDashMove) {
                // console.log('increment-dash-move', this.dashMoveLimit, this.endLength)
                const { speed, offset } = value as PathAnimation;

                increment *= speed || 1;

                this.dashMovePositions = this.dashMovePositions.map(
                  ({ start, end }) => ({
                    start: start + increment,
                    end: Math.min(end + increment, this.endLength),
                  }),
                );
                // -- // -- // -- // -- //
              }

              const { start } =
                this.dashMovePositions[this.dashMovePositions.length - 1];

              if (start >= this.endLength) {
                const { start: firstStart } = this.dashMovePositions[0];
                // -- // -- //

                this.dashMovePositions.pop();

                const { length, gap } = this.currentDashMove;
                this.dashMovePositions.unshift({
                  start: firstStart - (length + gap),
                  end: firstStart - gap,
                });
                // -- // -- //
              }

              const hideIndexes = [];
              this.dashMovePositions.map(({ start, end }, index) => {
                if (this.dashMoveLimit) {
                  if (this.dashMoveLimit <= start) {
                    this.dashMoveElements[index].hide();
                    hideIndexes.push(index);
                    return;
                  }

                  if (this.dashMoveLimit < end) {
                    end = this.dashMoveLimit;
                  }
                }

                const elements = this.pathSections
                  .map(ps => ps.getPathItem(Math.max(0, start), end))
                  .flat()
                  .filter(val => !!val);

                this.dashMoveElements[index].patch(
                  {
                    elements,
                  },
                  true,
                );
              });

              // console.log('hide-indexes', hideIndexes)
              break;
          }
      }
    });
  }

  getPositionByLength(length: number) {
    // -- // -- // -- // -- // -- // -- //

    const actualSection = this.pathSections.find(ps => {
      if (ps.startLength < length && ps.endLength < length) {
        return true;
      }
    });

    if (!actualSection) {
      console.warn('---- section could not be found for length:', length);
    }

    return actualSection.getPositionVector(length);
  }

  initDropShadowElement(config: DropShadowConfig) {
    this.blurContainer?.destroy();

    if (!this.container) {
      return;
    }

    if (!config) {
      return;
    }
    const { strength, margin, color, dx, dy } = config;
    this.blurContainer = new Container();

    this.dropShadowElement = new PathElement(this, this.blurContainer, {
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      ...this.elementConfig,
      'stroke-width': 0,
      stroke: this.getColorValue(color),
    });

    this.container.addChildAt(this.blurContainer, 0);

    this.blurContainer.setTransform(-margin + (dx || 0), -margin + (dy || 0));
    if (strength !== 0) {
      const filter = new BlurFilter(strength);
      this.dropShadowElement.element.filters = [filter];
    }
  }

  get currentSectionDescriptors() {
    return this.descriptor.sections;
  }

  getPositionVector(t: number): PositionVector {
    let result: PositionVector;

    if (t == undefined) {
      t = this.endLength;
    }

    for (const section of this.pathSections) {
      result = section.getPositionVector(t);
      if (result) {
        // abs coords are returned
        result.x += this.x;
        result.y += this.y;
        return result;
      }
    }
    return;
  }

  dashElements: PathElement[] = [];

  setDashed(_start?: number, _end?: number) {
    // const [fill, gap] = [this.dashFill, this.dashGap];
    // const [fill, gap] = [100, 16];
    const { fill, gap } = this.svgAttributes.stroke.dash;

    const endLength = this.lastSection.endLength;

    this.dashElements.map(e => e.remove());
    this.dashElements = [];

    let [start, end] = [0, fill];

    this.element.patch({
      'stroke-width': 0,
    });

    this.pathSections.map(section => section.setDashCache());
    this.pathSections.map(ps => ps.setLengths());

    const between = (start: number, end: number, val: number) =>
      start <= val && val <= end;

    while (start < endLength) {
      const sectionsInScope = this._pathSections.filter(
        ps =>
          between(start, end, ps.startLength) ||
          between(start, end, ps.endLength) ||
          (between(ps.startLength, ps.endLength, start) &&
            between(ps.startLength, ps.endLength, end)),
      );

      if (!sectionsInScope.length) {
        break;
      }

      const elements = sectionsInScope
        .map(ps => ps.getPathItem(start, end))
        .flat();

      // console.log('elements', elements);

      this.dashElements.push(
        new PathElement(this, this.container, {
          position: {
            x: 0,
            y: 0,
          },
          elements,
          ...this.resolvedSVGAttributes,
          'fill-opacity': 0,
        }),
      );

      start = end + gap;
      end = start + fill;

      if (_end && end > _end) {
        // console.log('')
        break;
      }
    }

    console.log('dashElements', this.dashElements); //
  }

  initSectionSteppers(
    base: PathSectionDescriptor[],
    sections: PathSectionDescriptor[],
    division: number,
    ease: Easing,
    inverse?: boolean,
  ) {
    if (inverse) {
      base = sections;
      sections = this.lastSections;
    } else {
      this.lastSections = this.sections;
    }

    const sectionsById = {};
    // const baseLength = this.descriptor.sections.length; //
    const baseLength = base.length;
    const targetLength = sections.length;

    if (!this.closed) {
      sections = sections.slice(0, sections.length - 1);
    }

    switch (true) {
      case baseLength === targetLength:
        this.sectionSteppers = sections.map(
          (section: PathSectionDescriptor, i: number) =>
            new PathSectionStepper(
              cloneDeep(base[i]),
              cloneDeep(section),
              division,
              ease,
            ),
        );
        break;

      case baseLength < targetLength:
        base.map(section => (sectionsById[section.id] = section));
        this.sectionSteppers = sections.map(
          section =>
            new PathSectionStepper(
              cloneDeep(
                sectionsById[section.id] || {
                  id: Math.random().toString(),
                  type: 'line',
                  x: 0,
                  y: 0,
                },
              ),
              cloneDeep(section),
              division,
              ease,
            ),
        );
        break;
      case baseLength > targetLength:
        sections.map(section => (sectionsById[section.id] = section));
        this.sectionSteppers = base.map(
          section =>
            new PathSectionStepper(
              cloneDeep(section),
              cloneDeep(
                sectionsById[section.id] || {
                  id: Math.random().toString(),
                  type: 'line',
                  x: 0,
                  y: 0,
                },
              ),
              division,
              ease,
            ),
        );
        break;
    }
  }

  _rOffset: Coords;

  set rOffset(val: Coords) {
    this._rOffset = val;
  }

  get rOffset(): Coords {
    return this._rOffset || [0, 0];
  }

  getD() {
    let paths: string[];

    if (!this.closed) {
      paths.pop();
    }

    const pathString = paths.join(' ').replace('undefined', '0');

    if (pathString.startsWith('M')) {
      return pathString;
    }

    return `M ${this.rOffset.join(' ')} ${pathString}`;
  }

  previousSection(ps: PathSection): PathSection {
    return this.previousPathSection(ps.index);
  }

  previousPathSection(index: number): PathSection {
    return index ? this.pathSections[index - 1] : this.lastSection;
  }

  nextSection(ps: PathSection): PathSection {
    return this.nextPathSection(ps.index);
  }

  nextPathSection(index: number, strict = false): PathSection {
    if (strict && !this.closed) {
      return this.pathSections[index + 1];
    }
    return this.pathSections[index + 1] || this.pathSections[0];
  }

  removeAll() {
    super.removeAll();
    this.pathSections?.map(ps => ps.remove());
    // (this.parent as GeneralShape)?._removeRelationship(this.IRI);
    // (this.parent as GeneralShape)?.reArrangeIndexes();
  }

  disableHover() {
    this.pathSections.map(ps => ps.disableHover());
  }

  enableHover() {
    this.pathSections.map(ps => ps.enableHover());
  }

  localCoords([x, y]: [number, number]): [number, number] {
    return [x - this.currentX, y - this.currentY];
  }

  _localCoords(point: [number, number]) {
    const [x, y] = this.localCoords(point);
    return { x, y } as Point;
  }

  _localCoordsO(
    point: [number, number],
    xo: number,
    yo: number,
  ): [number, number] {
    const { x, y } = this._localCoords(point);
    return [x - xo, y - yo];
  }

  localize(p: { x: number; y: number }) {
    return {
      x: p.x - this.currentX,
      y: p.y - this.currentY,
    };
  }

  _localize(
    p: { x: number; y: number },
    offsetX?: number,
    offsetY?: number,
  ): [number, number] {
    const { x, y } = this.localize(p);
    return [x + (offsetX || 0), y + (offsetY || 0)];
  }

  __localize([x, y]: Coords): Coords {
    return [x - this.currentX, y - this.currentY];
  }

  flipDirection() {
    // this.pathSections.map(ps => ps.ab);

    if (this.closed) {
      return true;
    }

    const [x, y] = this._lastSection.absCoords;
    console.log({ x, y });
    this.x += x;
    this.y += y;

    const newCoords = this._pathSections.map(ps => [-ps.x, -ps.y]);
    newCoords.reverse();

    console.log('newCoords', newCoords);

    this._pathSections.map((ps, index) => {
      const [x, y] = newCoords[index];
      ps.x = x;
      ps.y = y;
    });

    this.refresh();
    this.redraw();
    this.save();
  }

  _redraw(position: ShapePosition): void {
    super._redraw(position);
    return;
    if (this.parent.getType() == 'is') {
      const { x, y } = position;
      console.log('parent.position', this.parent.position.scale);
      console.log(
        'parent.position',
        this.parent.childOffsetX,
        this.childOffsetY,
      );
      const [_x, _y] = [
        x - (this.parent.childOffsetX || 0) / this.parent.position.scale.x,
        y - (this.parent.childOffsetY || 0) / this.parent.position.scale.y,
      ];
      console.log({ x, y, _x, _y });
      this.sectionContainer.position.x = _x;
      this.sectionContainer.position.y = _y;
    }
  }
}
