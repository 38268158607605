import { Coords } from '../../../../../elements/resource/types/shape.type';

export class TrajectoryFunction {
  getLinearTrajectory([xs, xe]: Coords, [ys, ye]: Coords) {
    // -- //
  }
}

export class LinearTrajectoryFunction {
  b: number;
  m: number;

  get xStart() {
    return this.p1[0];
  }

  get yStart() {
    return this.p1[1];
  }

  get xEnd() {
    return this.p2[0];
  }

  get yEnd() {
    return this.p2[1];
  }

  constructor(
    private p1: Coords,
    private p2: Coords,
  ) {
    this.m = (this.yEnd - this.yStart) / (this.xEnd - this.xStart);
    this.b = this.yStart - this.m * this.xStart;
  }

  getValue(x: number) {
    return this.b + this.m * x;
  }
}
