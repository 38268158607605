<div
  class="dash-move-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Dash move"
    [attributeState]="attributeState$ | async"
    key="dash-move"
    [initialValue]="{ speed: 1 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-6"
  >
    <div class="flex-row space-between">
      <nw-number-input
        key="speed"
        [value]="attributeState.value['speed']"
        (changed)="updateSubAttributeValue('speed', $event)"
      ></nw-number-input>
    </div>
  </div>
</div>
