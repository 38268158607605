import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { sceneState } from '../../store/selector/editor.selector';
import { SceneState } from '../../store/reducer/editor.reducer';
import {
  addNewScene,
  addSubScene,
  saveScenePosition,
  saveSubScenePosition,
  selectSubScene,
  setSceneName,
  setSubSceneName,
} from '../../store/editor.actions';
import { Scene } from '../../../elements/resource/types/shape.type';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { AnimationService } from '../../animation/animation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShapeService } from '../../shape/shape.service';

@Component({
  selector: 'iw-scene-controller',
  templateUrl: './scene-controller.component.html',
  styleUrls: ['./scene-controller.component.scss'],
})
export class SceneControllerComponent {
  sceneState$: Observable<SceneState>;

  addNewMode = false;

  localScene: SceneState;

  isHoverMode: string;
  mouseOutConsume = false;

  get isEditInProgress() {
    return this.isSceneIsBeingEdited || this.isSubSceneBeingEdited;
  }

  get currentMainScene() {
    return this.localScene.current?.[0];
  }

  constructor(
    private readonly cs: CanvasService,
    private readonly shapeService: ShapeService,
    private readonly animationService: AnimationService,
    private readonly store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.sceneState$ = this.store.select(sceneState);
    this.sceneState$.subscribe(scene => {
      this.localScene = scene;
    });

    this.cs.generalEventSubscribe('globalClick', () => {
      // console.log('global-click'); //
    });

    this.cs.keyEventSubscribe('Space+p', () => {
      if (this.selectedSubScene) {
        this.store.dispatch(
          saveSubScenePosition({
            scene: this.selectedScene,
            subScene: this.selectedSubScene,
            position: {
              x: this.cs.previewShape.translateX / this.cs.scaleOffset,
              y: this.cs.previewShape.translateY / this.cs.scaleOffset,
              scale: {
                x: this.cs.previewShape._scaleX / this.cs.scaleOffset,
                y: this.cs.previewShape._scaleY / this.cs.scaleOffset,
              },
            },
          }),
        );
      } else {
        this.store.dispatch(
          saveScenePosition({
            scene: this.selectedScene,
            position: {
              x: this.cs.previewShape.translateX / this.cs.scaleOffset,
              y: this.cs.previewShape.translateY / this.cs.scaleOffset,
              scale: {
                x: this.cs.previewShape._scaleX / this.cs.scaleOffset,
                y: this.cs.previewShape._scaleY / this.cs.scaleOffset,
              },
            },
          }),
        );
      }
    });
  }

  mouseOver(scene: string) {
    // if (this.currentMainScene !== scene ) {
    if (!this.cs.isShiftPressed) {
      return;
    }

    this.isHoverMode = scene;
  }

  _mouseOutConsume(scene: string) {
    // if (this.currentMainScene !== scene ) {
    if (!this.cs.isShiftPressed) {
      return;
    }

    this.mouseOutConsume = true;
    setTimeout(() => {
      this.mouseOutConsume = false;
    }, 5);
  }

  mouseOut(scene: string) {
    // if (this.currentMainScene !== scene ) {
    if (!this.cs.isShiftPressed) {
      return;
    }

    setTimeout(() => {
      if (this.mouseOutConsume) {
        return;
      }
      this.isHoverMode = '';
    }, 2);
  }

  editedSceneName: string;
  editedSceneIndex: number;

  isSceneEdited(scene: Scene) {
    return this.editedSceneName == scene.name;
  }

  discardEdit() {
    this.editedSceneName = null;
  }

  saveSceneName(name: string) {
    this.store.dispatch(
      setSceneName({
        name,
        index: this.editedSceneIndex,
      }),
    );
    this.editedSceneName = null;
  }

  get selectedScene() {
    return this.localScene.current?.[0];
  }

  removeScene(scene: Scene) {
    console.log('remove-scene');
  }

  addNewScene() {
    this.addNewMode = true;
  }

  isSceneSelected(scene: Scene) {
    return scene.name == this.selectedScene;
  }

  selectScene(scene: Scene) {
    if (this.isEditInProgress) {
      return;
    }

    if (this.isSceneSelected(scene)) {
      this.editScene(scene);
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        scene: scene.name,
      },
    });
  }

  get isSceneIsBeingEdited() {
    return !!this.editedSceneName;
  }

  editScene(scene: Scene) {
    this.editedSceneIndex = this.localScene.scenes.findIndex(
      s => s.name == scene.name,
    );
    this.editedSceneName = scene.name;
  }

  newSceneName: string;
  newSubSceneName: string;

  saveNewScene(name: string) {
    console.log('save-new-scene', name);
    this.store.dispatch(addNewScene({ name }));
    this.closeNewSceneEdit();
  }

  closeNewSceneEdit() {
    this.addNewMode = false;
    this.newSceneName = '';
  }

  /************************* SUB-SCENES  ******************************************/

  get selectedSubScene() {
    return this.localScene.current?.[1];
  }

  get isSubSceneBeingEdited() {
    return !!this.editedSubSceneName;
  }

  isSubSceneSelected(scene: Scene) {
    return scene.name == this.selectedSubScene;
  }

  editedSubSceneIndex: number;
  editedParentName: string;
  editedSubSceneName: string;

  isSubSceneEdited(parent: Scene, scene: Scene) {
    return (
      this.editedParentName == parent.name &&
      this.editedSubSceneName == scene.name
    );
  }

  saveSubScene(parent: Scene, subScene: Scene, name: string) {
    const parentIndex = this.localScene.scenes.findIndex(
      s => s.name == parent.name,
    );
    const index = parent.subscenes.findIndex(s => s.name == subScene.name);
    this.store.dispatch(setSubSceneName({ parentIndex, index, name }));

    this.animationService.mainFrame.updateSubSceneTransitionFrames(
      parent.name,
      subScene.name,
      name,
    );
  }

  selectSubScene(parent: Scene, scene: Scene) {
    if (this.isEditInProgress) {
      return;
    }
    if (this.isSubSceneSelected(scene)) {
      return this.editSubScene(parent, scene);
    }
    this.store.dispatch(selectSubScene({ name: scene.name }));
    this.editedParentName = null;
    this.editedSubSceneName = null;
  }

  editSubScene(parent: Scene, scene: Scene) {
    this.editedSubSceneIndex = this.localScene.scenes.findIndex(
      s => s.name == scene.name,
    );
    this.editedParentName = parent.name;
    this.editedSubSceneName = scene.name;
  }

  saveNewSubScene(scene: Scene, name: string) {
    this.store.dispatch(
      addSubScene({
        parentIndex: this.localScene.scenes.findIndex(
          s => s.name == scene.name,
        ),
        index: scene.subscenes?.length || 0,
        name,
      }),
    );
    this.closeNewSubSceneEdit();
  }

  addNewSubSceneMode = false;

  addNewSubScene(scene: Scene) {
    this.addNewSubSceneMode = true;
  }

  closeNewSubSceneEdit() {
    this.addNewSubSceneMode = false;
    this.newSubSceneName = '';
  }
}
