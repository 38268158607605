import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';
import { KeyHandlerService } from '../../services/keyhandler/keyhandler.service';
import { Subject } from 'rxjs';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GeneralShape } from '../../element-editor/shape/shapes/general/general-shape';
import { ResourceData } from '../../elements/resource/resource.types';
import { first, debounceTime } from 'rxjs/operators';
import { HttpService } from '../../store/http/http.service';
import { environment } from '../../../environments/environment';

export interface ImageData {
  label: string;
  s3Id: string;
  width: number;
  height: number;
}

export interface LoadedImageData {
  width: number;
  height: number;
  initialScale: number;
}

const MAX_WIDTH = 320;
const MAX_HEIGHT = 320;

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
// TODO - rename this component to ImageUploaderComponent
export class FileUploaderComponent implements OnInit {
  data: Partial<ImageData> = {
    label: '',
  };

  image: GeneralShape;
  fileToUpload: File = null;
  imageNameUpdate = new Subject<string>();

  imageUploadState = 'initial';

  nameCheckState = 'initial';

  imageSaveState = 'initial';
  disabledSave = false;

  imageName: string;

  get containerWidth() {
    return Math.min(this.width, MAX_WIDTH);
  }

  get containerHeight() {
    return Math.min(this.width, MAX_HEIGHT);
  }

  get width() {
    return this.data.width || 0;
  }

  get height() {
    return this.data.height || 0;
  }

  get saveIsEnabled() {
    return this.nameCheckState === 'valid-name';
  }

  get relationships() {
    return this.dialogData.relationships;
  }

  constructor(
    readonly cs: CanvasService,
    readonly khs: KeyHandlerService,
    readonly http: HttpClient,
    readonly _http: HttpService,
    public dialogRef: MatDialogRef<FileUploaderComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { relationships: Record<string, any> },
  ) {}

  ngOnInit(): void {
    this.imageNameUpdate
      .pipe(debounceTime(1_000))
      .subscribe(async () => await this.checkName());
  }

  fileUrl: string;

  async onFileDropped(event: any) {
    this.imageUploadState = 'in-progress';
    const fileToUpload = event[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    let s3Id: string;

    console.log('event', event.offsetX, event.offsetY);
    return;
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    // Example call:
    const base64data = await toBase64(fileToUpload);

    try {
      const { uuid } = await this.http
        .post<{ uuid: string }>(
          `${environment.backend.url}/file-binary`,
          { base64data },
          {
            // headers: {
            //   'Content-Type': 'image/jpeg',
            // },
          },
        )
        .toPromise();

      console.log('on-file-dropped', uuid);

      s3Id = uuid;
      this.fileUrl = uuid;
    } catch (error) {
      this.imageUploadState = 'error-by-upload';
      setTimeout(() => (this.imageUploadState = 'initial'), 1_500);
      return;
    }
  }

  textHasChanged() {
    if (this.imageName?.length <= 3) {
      this.nameCheckState = 'at-least-three';
    } else {
      this.nameCheckState = 'in-progress';
      this.imageNameUpdate.next();
    }
  }

  async checkName(): Promise<void> {
    const result = await this.http
      .get<any[]>(
        // `${environment.backend.url}/image/img?resourceData={"literals":{"label":"^${this.imageName}$"}}`,
        `${environment.backend.url}/image/img`,
        {
          params: {
            resourceData: `{"literals":{"label":"^${this.imageName}$"}}`,
          },
        },
      )
      .toPromise();

    if (result.length) {
      this.nameCheckState = 'name-is-taken';
    } else {
      this.nameCheckState = 'valid-name';
    }
  }

  fileData: { width: number; height: number; initialScale: number };

  async save() {
    if (!this.saveIsEnabled) {
      return;
    }

    if (this.disabledSave) {
      return;
    }

    const { width, height, initialScale } = this.fileData;

    this.disabledSave = true;
    this.imageSaveState = 'in-progress';
    this._http
      .post<ResourceData>('editor/img', {
        type: 'nw:Image',
        relationships: this.relationships,
        literals: {
          label: this.imageName,
          descriptor: {
            s3Id: this.fileUrl,
            width,
            height,
            initialScale,
          },
        },
      })
      .pipe(first(p => !!p))
      .subscribe(({ data }) => this.dialogRef.close(data));
  }

  reset() {
    this.imageUploadState = 'initial';
    this.nameCheckState = 'initial';
    this.imageSaveState = 'initial';
    this.data = { label: '' };
  }

  close() {
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
