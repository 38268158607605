import { NgModule } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';
import { ShapeService } from './shape.service';
import { AnimationModule } from '../animation/animation.module';
import { OrientationService } from '../../services/orientation/orientation.service';

@NgModule({
  imports: [AnimationModule],
  providers: [CanvasService, OrientationService, ShapeService],
})
export class ShapeModule {}
