import { Component } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nw-zoom-panel',
  templateUrl: './zoom-panel.component.html',
  styleUrls: ['./zoom-panel.component.scss'],
})
export class ZoomPanelComponent {
  constructor(
    readonly cs: CanvasService,
    readonly store: Store,
  ) {}
}
