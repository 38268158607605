import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class ServerService {

  constructor(private http: HttpClient) { }

  callExpress() {
    console.log("callExpress");
    console.log(this.http);
    this.http.get("http://localhost:3000/",  {responseType: "text"}).subscribe(
      resp => {
          console.log(resp);
      });
    console.log("What happens");
  }
}
