import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  loadFileAction,
  loadFileRequestAction,
  resetPatchedShapes,
  saveShapesAction,
  saveShapesRequestAction,
  setFileAction,
  setFileLoading,
  setPatchLoading,
  saveFileRequest,
  saveFileRequestComplete,
  setFileChanged,
  saveSceneRequest,
  saveSceneRequestComplete,
  removeSceneRequest,
  removeAllScenesRequest,
  saveNewComponentAction,
  setComponents,
  loadScene,
  loadSceneRequest,
  setLoadedScenes,
  addFileToStore,
} from '../editor.actions';
import {
  bBoxes,
  deletedShapes,
  newShapes,
  selectDescriptors,
  selectFile,
  selectPatchedShapes,
  selectShapeTransforms,
  selectShapes,
  baseSvgAttributes,
  selectScenes,
  selectedShapes,
  selectCurrentScenes,
  selectCurrentFileID,
  selectStates,
  selectUpdatesByState,
} from '../selector/editor.selector';
import { HttpService } from '../../../store/http/http.service';
import { RequestType } from '../../../store/actions';
import { ResourceData } from '../../../elements/resource/resource.types';

import { omit as _omit, cloneDeep } from 'lodash';
import {
  animationFunctions,
  animations,
  framesByScene,
  mainFrame,
} from '../../animation/store/animation.selector';
import {
  addAnimationsOfShapesToStore,
  addAnimationsOfShapesToStoreBase,
  setAnimationChanged,
  setAnimationFramesBySceneBase,
  setAnimationFunctions,
  setCurrentAnimationId,
  setMainAnimationFrameBase,
} from '../../animation/store/animation.actions';
import {
  GeneralShapeDescriptor,
  ImportedShapeDescriptor,
  __Animation,
} from '../../../elements/resource/types/shape.type';

let start: number;

function getFile(
  data: ResourceData,
  prefix = '',
  root = false,
  animations: Array<{
    IRI: string;
    animationsById: Record<string, __Animation>;
  }>,
) {
  const descriptor = data.literals.descriptor;
  if (Object.keys(descriptor._animationsByKey || {}).length) {
    // -- // -- //
    animations.push({
      IRI: prefix ? prefix + '_' + data.IRI : data.IRI,
      animationsById: descriptor._animationsByKey,
    });
  }

  let nextPrefix: string;
  if (root) {
    // 0-th level
    nextPrefix = '';
  } else if (prefix) {
    // n-th level
    nextPrefix = prefix + '_' + data.IRI;
  } else {
    // first level
    nextPrefix = data.IRI;
  }

  const shapes = (data.relationships?.shape as ResourceData[]) || [];

  if (shapes.length) {
    const rootShapes = [];
    const shapesByParent: Record<string, ResourceData[]> = {};
    for (const shape of shapes) {
      const parent = shape.literals.descriptor.childOf;
      if (parent) {
        shapesByParent[parent] ||= [];
        shapesByParent[parent].push(shape);
      } else {
        rootShapes.push(shape);
      }
    }

    data.relationships.shape = rootShapes.map(rootShape => {
      const _rootShape = getFile(rootShape, nextPrefix, false, animations);
      if (shapesByParent[rootShape.IRI]) {
        _rootShape.relationships ||= {};
        _rootShape.relationships.shape = shapesByParent[rootShape.IRI].map(
          childShape => getFile(childShape, prefix, false, animations),
        );
      }
      return _rootShape;
    });
  }
  return data;
}

@Injectable()
export class EditorRequestEffects {
  addShapeToStore$: Observable<Action>;

  newShape$: Observable<Action>;

  loadFile$: Observable<Action>;
  loadFileRequest$: Observable<Action>;

  loadScene$: Observable<Action>;
  loadSceneRequest$: Observable<Action>;

  saveShapes$: Observable<Action>;
  saveShapesRequest$: Observable<Action>;

  setShapeAttributeAction$: Observable<Action>;
  updateShapeDescriptorAction$: Observable<Action>;

  saveFileRequest$: Observable<Action>;

  addFileRequest$: Observable<Action>;

  saveSceneRequest$: Observable<Action>;
  removeSceneRequest$: Observable<Action>;
  removeAllScenesRequest$: Observable<Action>;
  saveNewComponent$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly http: HttpService,
  ) {
    this.loadFile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadFileAction),
        switchMap(({ ID }) => {
          return [
            loadFileRequestAction({ ID }),
            setFileLoading({ value: true }),
          ];
        }),
      ),
    );

    this.loadFileRequest$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loadFileRequestAction),
        switchMap(({ ID }) => {
          start = performance.now();
          // scene = 'main';
          return this.http.requestCall({
            type: RequestType.GET,
            path: `shape-v2/file/${ID}`,
          });
        }),
        switchMap(file => {
          return [addFileToStore({ file })];
          console.log('file-loaded', (performance.now() - start) / 1_000);

          const actions = [];
          const animations = [];

          file = getFile(file, '', true, animations);

          // console.log('descriptor', JSON.stringify(file.literals.descriptor));
          // -- // -- // -- //
          if (animations.length) {
            actions.push(addAnimationsOfShapesToStore({ animations }));
          }

          actions.push(setCurrentAnimationId({ id: null }));

          actions.push(setFileAction({ file }));

          const { animationFunctions, animationFrame } =
            file.literals.descriptor || [];

          actions.push(setMainAnimationFrameBase({ frame: animationFrame }));

          if (animationFunctions) {
            actions.push(
              setAnimationFunctions({
                functions: animationFunctions,
              }),
            );
          }

          return [
            ...actions,
            setComponents({ data: file.relationships.components }),
            setFileLoading({ value: false }),
          ];
        }),
        catchError(error => {
          console.error('error', error.message);
          return of(setFileLoading({ value: false }));
        }),
      );
    });

    this.loadScene$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadScene),
        withLatestFrom(this.store.select(selectCurrentFileID)),
        switchMap(([{ scene }, fileID]) =>
          fileID
            ? [setFileLoading({ value: true }), loadSceneRequest({ scene })]
            : [],
        ),
      ),
    );

    this.loadSceneRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadSceneRequest),
        withLatestFrom(this.store.select(selectCurrentFileID)),
        switchMap(([{ scene }, fileID]) => {
          console.log('loadSceneRequest', { scene, fileID });
          // -- // -- //
          return this.http.requestCall({
            type: RequestType.GET,
            path: `shape-v2/file/${fileID}/${scene}`,
          });
        }),
        switchMap(({ shapeData: file }) => {
          // -- // -- //
          const animations = [];
          // -- // -- // -- //

          console.log(
            '---- loaded root-shape > open ----',
            (file.relationships.shape as ResourceData[]).length,
          );

          file = getFile(file, '', true, animations);

          return [
            setFileLoading({ value: false }),
            addAnimationsOfShapesToStoreBase({ animations }),
            setFileAction({ file, merge: true }),
          ];
        }),
      ),
    );

    this.saveNewComponent$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveNewComponentAction),
        switchMap(({ data: body }) =>
          this.http.requestCall({
            type: RequestType.POST,
            path: 'shape-v2/new-component',
            body,
          }),
        ),
        switchMap(() => []),
      ),
    );

    this.saveSceneRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveSceneRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([{ scene }, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.PATCH,
            path: 'shape-v2/scene',
            body: selectedShapes.map(shapeIRI => ({ shapeIRI, scene })),
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.removeSceneRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(removeSceneRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([{ scene }, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.DELETE,
            path: 'shape-v2/scene',
            body: selectedShapes.map(shapeIRI => ({ shapeIRI, scene })),
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.removeAllScenesRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(removeAllScenesRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.DELETE,
            path: 'shape-v2/scene-all',
            body: selectedShapes,
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    // this.addFileRequest$ = createEffect() => {
    //   ofType(addFileActionRequest)
    //   withLatestFrom(this.store.select(getCurrentProjectID))
    //   exhaustMap(([{ name }, currentProjectID]) => {
    //     return this.http.requestCall({
    //       type: RequestType.POST,
    //       path: 'editor/file',
    //       body: {
    //         type: 'nw:File,',
    //         literals: {
    //            label: name,
    //         },
    //         relationships: {
    //           project: { IRI: `nw:${currentProjectID}` },
    //         },
    //       }
    //     })
    //   }),
    //   switchMap(( { }))
    // }

    this.saveFileRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveFileRequest),
        withLatestFrom(
          this.store.select(selectFile),
          this.store.select(mainFrame),
          this.store.select(framesByScene),
          this.store.select(bBoxes),
          this.store.select(animationFunctions),
          this.store.select(selectScenes),
          this.store.select(selectStates),
        ),
        switchMap(
          // @ts-ignore
          ([
            ,
            file,
            mainFrame,
            framesByScene,
            bBoxes,
            functions,
            scenes,
            states,
          ]) => {
            let xMin = Infinity,
              yMin = Infinity,
              xMax = -Infinity,
              yMax = -Infinity;

            if (Object.keys(bBoxes).length) {
              Object.values(bBoxes).map(({ x, y, width, height }) => {
                xMin = x < xMin ? x : xMin;
                yMin = y < yMin ? y : yMin;
                xMax = x + width > xMax ? x + width : xMax;
                yMax = y + height > yMax ? y + height : yMax;
              });
            }

            return this.http.requestCall({
              type: RequestType.PATCH,
              body: {
                ...file,
                literals: {
                  ...file.literals,
                  descriptor: {
                    ...file.literals.descriptor,
                    offset:
                      xMin !== Infinity
                        ? {
                            x: xMin,
                            y: yMin,
                            width: xMax - xMin,
                            height: yMax - yMin,
                          }
                        : null,
                    animationFrame: mainFrame,
                    animationFunctions: functions,
                    animiationFrameByScene: {
                      ...framesByScene,
                    },
                    _scenes: scenes,
                    states,
                  } as GeneralShapeDescriptor,
                  time: undefined,
                },
                relationships: {},
              },
              path: 'editor/file',
            });
          },
        ),
        switchMap(res => [
          saveFileRequestComplete(),
          setAnimationChanged({ value: false }),
          setFileChanged({ value: false }),
        ]),
        catchError(error => {
          console.error('saveFileRequest', error, error.message);
          return of(error.message);
        }),
      ),
    );

    this.saveShapes$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(saveShapesAction),
        switchMap(() => [
          setPatchLoading({ value: true }),
          saveShapesRequestAction(),
        ]),
      );
    });

    this.saveShapesRequest$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(saveShapesRequestAction),
        withLatestFrom(
          this.store.select(selectFile),
          this.store.select(selectDescriptors),
          this.store.select(selectShapeTransforms),
          this.store.select(baseSvgAttributes),
          this.store.select(selectShapes),
          this.store.select(newShapes),
          this.store.select(selectPatchedShapes),
          this.store.select(deletedShapes),
          this.store.select(animations),
          this.store.select(selectUpdatesByState),
        ),
        switchMap(
          // @ts-ignore
          ([
            ,
            file,
            descriptors,
            transforms,
            svgAttributes,
            shapes,
            newShapes,
            patchedShapes,
            deletedShapes,
            animations,
            updatesByState,
          ]) => {
            // -- //
            function getResourceData(IRI: string): ResourceData {
              let override = {};
              const relationships: Record<string, any> = {};
              const { x, y } = transforms[IRI].scale || { x: 0, y: 0 };
              switch (descriptors[IRI].type) {
                case 'rectangle-shape':
                  override = {
                    width: x,
                    height: y,
                  };
                  break;
                case 'circle-shape':
                  override = {
                    rx: x / 2,
                    ry: y / 2,
                  };
                  break;
                case 'imported-shape':
                  relationships.instanceOf = (
                    descriptors[IRI] as ImportedShapeDescriptor
                  ).shapeIRI;
                  break;
              }

              const stroke = svgAttributes[IRI]?.stroke;
              const fill = svgAttributes[IRI]?.fill;

              return {
                ...shapes[IRI],
                literals: {
                  ...shapes[IRI].literals,
                  descriptor: {
                    ...descriptors[IRI],
                    position: {
                      ...transforms[IRI].translate,
                      scale: transforms[IRI]?.scale,
                      rotation: transforms[IRI]?.rotation,
                    },
                    svgAttributes: {
                      ...svgAttributes[IRI],
                      stroke: stroke?.color,
                      'stroke-width': stroke?.width,
                      dash: stroke?.dash,
                      fill: fill?.color,
                      gradient: fill?.gradient,
                      gradientColor: fill?.gradientColor,
                      gradientDirection: fill?.gradientDirection,
                    },
                    _animationsByKey: Object.entries(
                      animations[IRI] || {},
                    ).reduce((object, [animationId, animations]) => {
                      object[animationId] = Object.entries(
                        animations || {},
                      ).map(([key, value]) => ({ ...value, key }));
                      return object;
                    }, {}),
                    updatesByState: updatesByState?.[IRI],
                    ...override,
                    // fixPosition: false,
                  },
                },
                relationships,
              };
            }

            const posts = Object.keys(newShapes || {}).map(IRI => ({
              type: 'post',
              resourceData: getResourceData(IRI),
            }));

            const patches = Object.keys(patchedShapes || {})
              .filter(IRI => !IRI.includes('_'))
              .map(IRI => ({
                type: 'patch',
                resourceData: getResourceData(IRI),
              }));

            const deletes = Object.keys(deletedShapes || {}).map(IRI => ({
              type: 'delete',
              resourceData: {
                IRI,
              },
            }));

            // TODO! - previewShapeOffset //
            const body = {
              IRI: file.IRI,
              // literals: {
              //   label: file.literals.label || 'label',
              //   descriptor: file.literals.descriptor || {},
              // },
              shapes: [...posts, ...patches, ...deletes],
            };

            // console.log('body-1', body);

            return this.http.requestCall({
              type: RequestType.PATCH,
              path: 'shape',
              body,
            });
          },
        ),
        switchMap(() => [
          resetPatchedShapes(),
          setPatchLoading({ value: false }),
        ]),
        catchError(error => {
          console.error('------ error -----', error.message);
          return of(error.message);
        }),
      );
    });
  }
}
