<div class="list-editor-component">
  <div
    *ngFor="let item of items; index as i"
    class="item row-space-between"
  >
    <nw-editable-text [text]="item" (textChanged)="saveItem(i, $event)">
    </nw-editable-text>
    <nw-icon type="close" (click)="removeItem(i)" [size]="14"></nw-icon>
  </div>
  <div
    *ngIf="!newItemState"
    style="display: table; margin-top: 6px;"
    (click)="addNew()"
  >
    <nw-icon type="add" [size]="14"></nw-icon>
  </div>

  <div *ngIf="newItemState" fxLayout="row">
    <input class="new-item-input" [(ngModel)]="newItem" (keyup)="keyup($event)" (keydown)="keydown($event)" />
    <nw-floating [left]="-20" [top]="4">
      <nw-icon type="done" (click)="saveNew()" [size]="15"></nw-icon>
    </nw-floating>
  </div>
</div>
