import {
  AnimationFrameObject,
  AnimationFrameRelationships,
} from './animation-frame-object';
import { BufferLoader } from './buffer-loader';
import { SoundAnimationFrame } from '../components/animation-frame/animation.types';
import { pick } from 'lodash';
import { AnimationEnvironment } from '../../../services/animation/animation.types';

export class LocalSoundAnimationFrameObject<
  T extends SoundAnimationFrame = SoundAnimationFrame,
> extends AnimationFrameObject<T> {
  get soundUrl() {
    return this.frame.soundFileUrl;
  }
  get type() {
    return 'local-sound';
  }

  get soundDuration() {
    return this.source1.buffer.duration;
  }

  async playSound() {
    // if (!this.soundUrl) {
    //   return Promise.resolve();
    // }

    return new Promise(async res => {
      const context = await this.context;
      const destination = await this.destination;

      const gainNode = context.createGain();

      // Set the gain value (volume level)
      // 1.0 is the original volume, lower it to decrease the volume (e.g., 0.5 for 50% volume)
      gainNode.gain.value = 0.01; // Set this value to control the volume level
      console.log('play-sound');
      this.source1 = context.createBufferSource();
      this.source1.buffer = this.buffer;

      this.source1.connect(gainNode);
      gainNode.connect(destination); // recorder
      gainNode.connect(context.destination); // speaker

      // this.source1.connect(destination); // recorder
      // this.source1.connect(context.destination); // speaker
      this.source1.start(0);

      setTimeout(res, this.source1.buffer.duration * 1_000);
    });
  }

  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      ...pick(this.frame, ['soundFileUrl', 'audioDuration']),
    };
  }

  async _animate(_env: AnimationEnvironment = {}) {
    await this.playSound();
  }

  bufferLoader: BufferLoader;
  source1: AudioBufferSourceNode;

  constructor(
    frame: T,
    relationships: AnimationFrameRelationships,
    private readonly context: Promise<AudioContext>,
    private readonly destination: Promise<MediaStreamAudioDestinationNode>,
  ) {
    super(frame, relationships);
  }
  init() {
    super.init();
    // -- //
    // if (!this.soundUrl) {
    //   return;
    // }

    (async () => {
      const context = await this.context;
      const destination = await this.destination;

      // switch (this.soundUrl) {
      //   case 'beep':
      //     fileName = 'beep.wav';
      //     break;
      //   case 'noise':
      //     fileName = 'noise.wav';
      //     break;
      //   case 'inwedio':
      //     fileName = 'test.mp3';
      //     break;
      // }
      this.bufferLoader = new BufferLoader(
        context,
        `http://localhost:4200/assets/sounds/ES_Humanoid-AvaLow-0000-13343.wav`,
        // `http://localhost:4200/assets/sounds/${this.soundUrl}`,
        audioBuffer => {
          /* console.log(
            'buffer-loaded',
            this.cs.generalEventEmit('sound-loaded', this.frame.id),
          ); */

          // console.log('buffer-length', audioBuffer.length);
          // console.log('buffer-length', audioBuffer.sampleRate);
          this.buffer = audioBuffer;
        },
      );
      this.bufferLoader.load();
    })();
  }

  buffer: AudioBuffer;
}
