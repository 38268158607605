import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from '../../../../store/http/http.service';
import { TextAnimationFrameObject } from '../../../animation/frame/text-animation-frame';
import { BufferLoader } from '../../../animation/frame/buffer-loader';
import { AudioService } from '../../../animation/audio.service';
import { SoundRootAnimationFrame } from '../../../animation/frame/main-animation-frame-object';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getCurrentLanguage,
  getCurrentProjectLanguages,
} from '../../../../projects/project.selector';
import { set } from 'lodash';

@Component({
  selector: 'nw-text-animation',
  templateUrl: './text-animation.component.html',
  styleUrls: ['./text-animation.component.scss'],
})
export class TextAnimationComponent implements OnInit, OnDestroy {
  @Input()
  set frameObject(val: TextAnimationFrameObject) {
    console.log('---- setty baby ---', val);
    this._frameObject = val;
  }

  _frameObject: TextAnimationFrameObject;

  get frameObject() {
    return this._frameObject;
  }

  get frame() {
    return this.frameObject.frame;
  }

  get text() {
    if (this.currentLanguage) {
      return this.frame.langs?.[this.currentLanguage]?.text || '';
    }
    return this.frame.text;
  }

  set text(val: string) {
    if (this.currentLanguage) {
      set(
        this.frame,
        ['langs', this.currentLanguage, 'text'],
        val?.trim() || '',
      );
    } else {
      this.frame.text = val?.trim() || '';
    }
  }

  get soundFileUrl() {
    if (this.currentLanguage) {
      return this.frame.langs?.[this.currentLanguage]?.soundFileUrl;
    }
    return this.frameObject.frame.soundFileUrl;
  }

  set soundFileUrl(val: string) {
    if (this.currentLanguage) {
      set(
        this.frameObject.frame,
        ['langs', this.currentLanguage, 'soundFileUrl'],
        val,
      );
    } else {
      this.frameObject.frame.soundFileUrl = val;
    }
  }

  get recordFileUrl() {
    if (this.currentLanguage) {
      return this.frame.langs?.[this.currentLanguage]?.recordFileUrl;
    }
    return this.frameObject.frame.recordFileUrl;
  }

  set recordFileUrl(val: string) {
    if (this.currentLanguage) {
      set(
        this.frameObject.frame,
        ['langs', this.currentLanguage, 'recordFileUrl'],
        val,
      );
    } else {
      this.frameObject.frame.recordFileUrl = val;
    }
  }

  set audioDuration(val: number) {
    if (this.currentLanguage) {
      set(
        this.frameObject.frame,
        ['langs', this.currentLanguage, 'audioDuration'],
        val,
      );
    } else {
      this.frameObject.frame.audioDuration = val;
    }
  }

  get audioDuration() {
    if (this.currentLanguage) {
      return this.frame.langs?.[this.currentLanguage]?.audioDuration;
    }
    return this.frameObject.frame.audioDuration;
  }

  get voiceType() {
    if (this.currentLanguage) {
      return this.frame.langs?.[this.currentLanguage]?.voiceType;
    }
    return this.frameObject.frame.voiceType;
  }

  set voiceType(val: 'generated' | 'recorded') {
    if (this.currentLanguage) {
      set(
        this.frameObject.frame,
        ['langs', this.currentLanguage, 'voiceType'],
        val,
      );
      console.log('set-voice-type', this.frameObject.frame);
    } else {
      this.frameObject.frame.voiceType = val;
    }
  }

  get isGenerated() {
    return this.voiceType == 'generated';
  }
  get isRecorded() {
    return this.voiceType == 'recorded';
  }
  setGenerated() {
    this.voiceType = 'generated';
    console.log('save-setGenerated', this.frameObject.minimalFrameObject);
    this.save();
  }
  setRecorded() {
    this.voiceType = 'recorded';
    this.save();
  }

  languages$: Observable<string[]>;

  currentLanguage$: Observable<string>;
  currentLanguage: string;

  languages: string[];
  isLoading = false;

  bufferLoader: BufferLoader;
  buffer: any;
  source1: AudioBufferSourceNode;
  currentDuration: number;

  langMap = {
    hu: 'hungarian',
    en: 'english',
    ger: 'german',
    esp: 'spanish',
    fr: 'french',
    it: 'italien',
    pl: 'polish',
  };

  constructor(
    private readonly http: HttpService,
    private readonly audioService: AudioService,
    private readonly store: Store,
  ) {
    this.languages$ = this.store.select(getCurrentProjectLanguages);
    this.languages$.subscribe(languages => {
      this.languages = languages;
    });
    this.currentLanguage$ = this.store.select(getCurrentLanguage);
    this.currentLanguage$.subscribe(lang => {
      console.log('-------- text-animation-frame -------', lang);
      this.currentLanguage = lang;
    });
  }

  sub: Subscription;
  ngOnInit() {
    this.recordTime = this.audioDuration;
  }

  ngOnDestroy() {}

  subtitleChanged(val: boolean) {
    // -- // -- // -- // -- //
  }

  speechChanged(val: boolean) {
    if (val) {
      this.requestSpeechFile();
    }
  }

  textChanged(event: Event) {
    const text = (event.target as HTMLInputElement).value;

    this.text = text;
    console.log('text-changed', this.frameObject.frame);
    this.frameObject.save();
  }

  save() {
    this.frameObject.save();
  }

  newRecording = false;
  refresh() {
    if (this.isGenerated) {
      this.requestSpeechFile();
    } else {
      // -- //
      this.newRecording = true;
      this.recordTime = 0;
    }
  }

  recordTime = 0;
  recordIsInProgress = false;

  recordInterval: NodeJS.Timeout;

  private mediaRecorder: MediaRecorder;
  private audioChunks: Blob[] = [];
  public audioUrl: string | null = null;
  private audioBlob: Blob | null = null;

  playInProgress = false;

  async startRecording() {
    this.recordIsInProgress = true;
    // -- // -- // -- //

    this.startTimeCounter();

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.mediaRecorder = new MediaRecorder(stream);

    this.mediaRecorder.ondataavailable = event => {
      if (event.data.size > 0) {
        this.audioChunks.push(event.data);
      }
    };

    this.mediaRecorder.start();
  }

  stopRecording() {
    console.log('stop-recording');
    this.recordTime = 0;
    this.newRecording = false;

    this.endTimeCounter();
    this.recordIsInProgress = false;
    this.mediaRecorder.onstop = async () => {
      this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
      this.audioUrl = URL.createObjectURL(this.audioBlob);
      this.recordFileUrl = URL.createObjectURL(this.audioBlob);
      this.audioChunks = []; // Reset for next recording

      this.audioDuration = await this.getAudioDuration(this.audioBlob);
      this.recordTime = this.audioDuration;

      this.recordFileUrl = `${this.frame.id}_${this.currentLanguage || 'default'}_audio.wav`;
      this.http.postRecording(this.audioBlob, this.recordFileUrl);

      this.audioService.initSoundBufferByBlob(this.audioBlob, this.frame.id);

      this.recalcPositions();
      this.save();
    };
    this.mediaRecorder.stop();
  }

  async getAudioDuration(blob: Blob): Promise<number> {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const audioData = await blob.arrayBuffer();

    return new Promise(resolve => {
      audioContext.decodeAudioData(audioData, buffer => {
        resolve(buffer.duration); // Return the duration in seconds
      });
    });
  }

  startTimeCounter(endAt?: number, cb?: () => void) {
    const start = performance.now();
    this.recordInterval = setInterval(() => {
      this.recordTime = (performance.now() - start) / 1000;
    }, 100);
    if (endAt) {
      setTimeout(() => {
        this.endTimeCounter();
        cb?.();
      }, endAt * 1_000);
    }
  }

  endTimeCounter() {
    clearInterval(this.recordInterval);
  }

  async playAudio() {
    console.log('audio-duration', this.audioDuration);
    this.startTimeCounter(this.audioDuration, () => {
      this.recordTime = this.audioDuration;
      this.playInProgress = false;
    });
    this.playInProgress = true;
    // this.playAudio();
    this.audioService.playSound(this.frame.id);
    return;
    if (this.audioBlob) {
      const audioContext = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      const audioData = await this.audioBlob.arrayBuffer();

      audioContext.decodeAudioData(audioData, buffer => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0); // Start playing
      });
    } else {
      this.audioService.playSound(this.frame.id);
    }
  }

  async pauseAudio() {
    this.playInProgress = false;
  }

  async requestSpeechFile() {
    // this.store.dispatch(
    //   requestSpeechFileFromText({
    //     text: this.selectedSoundFrameText,
    //     id: this.selectedObject.id,
    //   }),
    // );
    this.isLoading = true;

    const url =
      this.currentLanguage == 'hu'
        ? '/tts/audio/text-to-speech/hu'
        : '/tts/audio/text-to-speech';

    const { error, data } = await this.http.postPromise(url, {
      text: this.text.trim(),
      id: this.frame.id,
    });

    if (error) {
      console.error('error at requesting speech file', error);
      return;
    }

    const { filename } = data as any;

    const duration = await this.audioService.initSoundBuffer(filename);
    console.log('duration', duration);
    this.frameObject.duration = duration;
    this.soundFileUrl = filename;
    this.isLoading = false;
    this.recalcPositions();
    this.save();
  }

  recalcPositions() {
    console.log('root-main-frame', this.frameObject.rootMainFrame);
    (
      this.frameObject.rootMainFrame as SoundRootAnimationFrame
    ).functionParent.calcPositions();
  }

  playSample() {
    this.audioService.playSound(this.soundFileUrl);
  }
}
