<div
  [style.position]="'relative'"
  [style.top.px]="top"
  [style.left.px]="left"
  [style.bottom.px]="bottom"
  [style.right.px]="right"
  class="nw-floating-component"
>
  <div [style.position]="'absolute'">
    <ng-content></ng-content>
  </div>
</div>
