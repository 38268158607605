import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'nw-state-editor',
  templateUrl: './state-editor.component.html',
  styleUrls: ['./state-editor.component.scss']
})
export class StateEditorComponent implements OnInit {

  @Input()
  states: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData:string[], private readonly dialogRef: MatDialogRef<string[]>) {
    this.states = dialogData;
  }

  ngOnInit(): void {
  }

  addFirst() {
    this.states.push('state-1');
  }

  save() {
    this.dialogRef.close(this.states);
  }

  discard() {
    this.dialogRef.close();
  }
}
