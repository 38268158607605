import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';
import { _attributeState } from '../../../../store/selector/editor.selector';

@Component({
  selector: 'iw-show-hide-animation',
  templateUrl: './show-hide-animation.component.html',
  styleUrls: ['./show-hide-animation.component.scss'],
})
export class ShowHideAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'show-hide';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('show-hide'));

    this.attributeState$.subscribe(attributeState => {
      // console.log('show-hide-attr', attributeState);
    });
  }
}
