import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  addNewShapeAction,
  setNewShapeAction,
  setShapeResourceData,
  addShapeToStore,
  setOriginalDescriptor,
  saveChangedShapes,
  setShapeOriginalTransform,
  setOriginalSVGAttributes,
  setDescriptorValue,
  setSVGSubAttribute,
  setDescriptorItem,
  setTransformSubAttribute,
  selectSubScene,
  setCanvasPosition,
  selectSubSceneBase,
  setChildOf,
  addNewScene,
  setFileChanged,
  addNewSceneBase,
  switchScene,
  setCurrentScene,
  loadScene,
  setAttributeByState,
  removeAttribute,
  openFile,
  addFileToStore,
  setComponents,
  setFileLoading,
  setFileAction,
  loadFileAction,
} from '../editor.actions';
import {
  selectedShapes,
  _selectedShapes,
  selectScenes,
  selectCurrentScenes,
  selectNoAnimationMode,
  loadedScenes,
  selectedSelectedState,
  selectFiles,
} from '../selector/editor.selector';
import {
  __Animation,
  AnimationValue,
  CircleShapeDescriptor,
  GeneralShapeDescriptor,
  RectangleShapeDescriptor,
} from '../../../elements/resource/types/shape.type';
import { omit as _omit, pick as _pick, cloneDeep } from 'lodash';
import {
  setAnimationSubValue,
  addAnimationsOfShapesToStoreBase,
  addAnimationItemAction,
  removeAnimationItemAction,
  setAnimationFunctions,
  setMainAnimationFrameBase,
  setCurrentAnimationId,
  addAnimationsOfShapesToStore,
} from '../../animation/store/animation.actions';
import { currentAnimationId } from '../../animation/store/animation.selector';
import { ResourceData } from '../../../elements/resource/resource.types';

@Injectable()
export class EditorEffects {
  setAttributeSubvalue$: Observable<Action>;

  saveTranslate$: Observable<Action>;
  saveRotation$: Observable<Action>;

  addShapeToStore$: Observable<Action>;
  newShape$: Observable<Action>;

  setShapeAttributeAction$: Observable<Action>;
  updateShapeDescriptorAction$: Observable<Action>;

  removeAttribute$: Observable<Action>;

  resetBaseState$: Observable<Action>;

  selectSubScene$: Observable<Action>;
  addNewScene$: Observable<Action>;
  switchScene$: Observable<Action>;

  openFile$: Observable<Action>;

  addFileToStore$: Observable<Action>;
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {
    this.openFile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(openFile),
        withLatestFrom(this.store.select(selectFiles)),
        switchMap(([{ ID }, files]) =>
          // files[ID]
          // ? [addFileToStore({ file: cloneDeep(files[ID]) })] :
          [loadFileAction({ ID })],
        ),
      ),
    );

    this.addFileToStore$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addFileToStore),
        switchMap(({ file }) => {
          const actions = [];
          const animations = [];

          ((file.relationships?.shape as ResourceData[]) || []).map(shape => {
            const descriptor = shape.literals.descriptor;
            if (Object.keys(descriptor._animationsByKey || {}).length) {
              // -- // -- //
              animations.push({
                IRI: shape.IRI,
                animationsById: descriptor._animationsByKey,
              });
            }
          });

          if (animations.length) {
            actions.push(addAnimationsOfShapesToStore({ animations }));
          }

          actions.push(setCurrentAnimationId({ id: null }));

          actions.push(setFileAction({ file }));

          const { animationFunctions, animationFrame } =
            file.literals.descriptor;

          actions.push(setMainAnimationFrameBase({ frame: animationFrame }));

          if (animationFunctions) {
            actions.push(
              setAnimationFunctions({
                functions: animationFunctions,
              }),
            );
          }

          return [
            ...actions,
            // TODO - migrate
            setComponents({ data: file.relationships.components as any[] }),
            setFileLoading({ value: false }),
          ];
        }),
      ),
    );

    this.selectSubScene$ = createEffect(() =>
      this.actions$.pipe(
        ofType(selectSubScene),
        withLatestFrom(
          this.store.select(selectScenes),
          this.store.select(selectCurrentScenes),
        ),
        switchMap(([{ name }, scenes, currentScenes]) => {
          const mainScene = currentScenes[0];
          const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
          const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
            s => s.name == name,
          );
          // -- // -- //
          if (scenes[mainSceneIndex].subscenes[subSceneIndex].position) {
            return [
              setCanvasPosition({
                position:
                  scenes[mainSceneIndex].subscenes[subSceneIndex].position,
              }),
              selectSubSceneBase({ name }),
            ];
          }

          return [selectSubSceneBase({ name })];
        }),
      ),
    );

    this.addNewScene$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addNewScene),
        switchMap(({ name }) => [
          setFileChanged({ value: true }),
          addNewSceneBase({ name }),
        ]),
      ),
    );

    this.switchScene$ = createEffect(() =>
      this.actions$.pipe(
        ofType(switchScene),
        withLatestFrom(this.store.select(loadedScenes)),
        switchMap(([{ scene }, loadedScenes]) => {
          if (Object.keys(loadedScenes).length > 0 && !loadedScenes[scene]) {
            return [setCurrentScene({ scene }), loadScene({ scene })];
          }

          return [
            setCurrentScene({ scene }),
            // setFileChanged({ value: true }),
            // addNewSceneBase({ name }),
          ];
        }),
      ),
    );

    this.setAttributeSubvalue$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setDescriptorValue),
        withLatestFrom(
          this.store.select(currentAnimationId),
          this.store.select(selectedShapes),
          this.store.select(selectNoAnimationMode),
          this.store.select(selectedSelectedState),
        ),
        switchMap(
          // @ts-ignore
          ([
            action,
            currentAnimationId,
            _selectedShapes,
            noAnimationMode,
            selectedState,
          ]): Action[] => {
            const { key, innerKey, value, IRI } = action;
            const selectedShapes = IRI ? [IRI] : _selectedShapes;
            if (selectedState) {
              switch (key) {
                case 'translate':
                  return [
                    setAttributeByState({
                      IRIs: selectedShapes,
                      state: selectedState,
                      key: 'position',
                      innerKey,
                      value,
                    }),
                  ];
                case 'scale':
                  return [
                    setAttributeByState({
                      IRIs: selectedShapes,
                      state: selectedState,
                      keys: ['position', 'scale', innerKey as string],
                      value,
                    }),
                  ];
              }
            } else if (currentAnimationId && !noAnimationMode) {
              return [
                innerKey
                  ? setAnimationSubValue({
                      IRIs: selectedShapes,
                      animationKey: key,
                      innerKey,
                      value,
                    })
                  : addAnimationItemAction({
                      IRIs: selectedShapes,
                      item: {
                        key,
                        value: value as AnimationValue,
                      },
                    }),
              ];
            } else {
              switch (key) {
                case 'translate':
                case 'scale':
                case 'rotation':
                  return [
                    setTransformSubAttribute({
                      IRIs: selectedShapes,
                      key,
                      innerKey: innerKey as 'x' | 'y' | 'relative',
                      value: value as number | boolean,
                    }),
                    saveChangedShapes({ IRIs: selectedShapes }),
                  ];
                case 'fill':
                case 'stroke':
                  return [
                    setSVGSubAttribute({
                      IRIs: selectedShapes,
                      key,
                      innerKey,
                      value: value as string,
                    }),
                    saveChangedShapes({ IRIs: selectedShapes }),
                  ];

                default:
                  return [
                    setDescriptorItem({
                      IRIs: selectedShapes,
                      key,
                      innerKey: innerKey as string,
                      value,
                    }),
                    saveChangedShapes({ IRIs: selectedShapes }),
                  ];
              }
            }
          },
        ),
        catchError(e => {
          console.log('..........error', e);
          return of(e.message);
        }),
      ),
    );

    this.removeAttribute$ = createEffect(() =>
      this.actions$.pipe(
        ofType(removeAttribute),
        withLatestFrom(
          this.store.select(currentAnimationId),
          this.store.select(selectNoAnimationMode),
          this.store.select(selectedShapes),
        ),
        switchMap(
          ([
            { IRI, IRIs, key },
            currentAnimationId,
            noAnimationMode,
            selectedShapes,
          ]) => {
            const shapeIRIs = IRI ? [IRI] : IRIs ? IRIs : selectedShapes;

            if (currentAnimationId && !noAnimationMode) {
              return [
                removeAnimationItemAction({ IRIs: shapeIRIs, key }),
                saveChangedShapes({ IRIs: shapeIRIs }),
              ];
            } else {
              switch (key) {
                case 'fill':
                  // -- // -- //
                  break;
                case 'stroke':
                  break;
              }
            }
          },
        ),
      ),
    );

    this.newShape$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addNewShapeAction),
        switchMap(({ data }) => [
          addShapeToStore({ data }),
          setNewShapeAction({ data }),
        ]),
      ),
    );

    this.addShapeToStore$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addShapeToStore),
        switchMap(({ data }) => {
          const { IRI, literals } = data;

          const { descriptor } = literals;

          // const subShapes: ResourceData[] = (relationships?.shape as ResourceData[])?.map(shape => {
          //   shape.IRI = `${IRI}_${shape.IRI}`;
          //   return shape;
          // }) || [];

          function getScale(descriptor: GeneralShapeDescriptor) {
            switch (descriptor.type) {
              case 'rectangle-shape':
                const { width, height } =
                  descriptor as RectangleShapeDescriptor;
                console.log('get-scale');
                return { x: width, y: height };
              case 'circle-shape':
                const { rx, ry } = descriptor as CircleShapeDescriptor;
                return { x: 2 * rx, y: 2 * ry };
              case 'imported-shape':
                return descriptor.position.scale;
            }
            return;
          }

          return [
            setShapeResourceData({ data }),
            setChildOf({
              child: data.IRI,
              parent: data.relationships.parent as string,
            }),
            setOriginalDescriptor({
              IRI,
              descriptor,
            }),
            setOriginalSVGAttributes({
              IRI: IRI,
              svgAttributes: {
                ...descriptor.svgAttributes,
                stroke: descriptor.svgAttributes?.stroke
                  ? {
                      color: descriptor.svgAttributes.stroke,
                      width: descriptor.svgAttributes['stroke-width'],
                      dash: descriptor.svgAttributes.dash,
                    }
                  : undefined,
                fill: descriptor.svgAttributes?.fill
                  ? {
                      color: descriptor.svgAttributes.fill,
                      gradient: descriptor.svgAttributes.gradient,
                      gradientColor: descriptor.svgAttributes.gradientColor,
                    }
                  : undefined,
              },
            }),
            setShapeOriginalTransform({
              IRI,
              transform: {
                translate: _pick(descriptor.position, 'x', 'y'),
                // ...scale, rotation //
                scale: getScale(descriptor),
              },
            }),
            descriptor._animationsByKey &&
              addAnimationsOfShapesToStoreBase({
                animations: [
                  {
                    IRI,
                    animationsById: descriptor._animationsByKey,
                  },
                ],
              }),
            // ...subShapes.map(shape => addShapeToStore({ data: shape }))
          ].filter(val => !!val);
        }),
      ),
    );

    // this.resetBaseState$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(resetBaseState),
    //     withLatestFrom(
    //       this.store.select(baseShapeTransforms),
    //       this.store.select(baseSvgAttributes),
    //     ),
    //     switchMap(([, shapeTransforms]) => {
    //       // TODO - implement for other attributes like fill, stroke
    //       // console.log('resetBaseState', { shapeTransforms });
    //       const transforms = Object.entries(shapeTransforms)
    //         .filter(([shapeIRI]) => !shapeIRI.includes('_'))
    //         .map(([shapeIRI, transform]) =>
    //           setBaseShapeTransform({ IRI: shapeIRI, transform }),
    //         );
    //       return [...transforms, setCurrentAnimationIdBase({ id: undefined })];
    //     }),
    //   ),
    // );
  }
}
