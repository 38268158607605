import { ResourceData } from './../../elements/resource/resource.types';
import { SCALE } from './canvas.types';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Point } from '../../elements/base/point';
import 'rxjs/add/operator/toPromise';
import { Resource } from '../../elements/resource/resource';
import { ResourceIRI } from '../../elements/resource/resource.types';
import { Time, Animation } from '../animation/animation.types';
import { SCALE_IN, ShapeAlias } from './canvas.types';
import {
  ComponentType,
  ComponentEvent,
} from '../../components/general-component/general.component.type';
import {
  ConstraintEntity,
  ControlPointDefinition,
  Coords,
  GeneralShapeDescriptor,
  PathShapeDescriptor,
  ShapeSelectParams,
} from '../../elements/resource/types/shape.type';
import {
  lookup_scale_in,
  lookup_scale_out,
} from './../util/scale/scale_lookup';
import { ImageDescriptor } from '../../elements/resource/types/shape.type';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { GeneralShape } from '../../element-editor/shape/shapes/general/general-shape';

import { PathShape } from '../../element-editor/shape/shapes/path-shape/path-shape';
import {
  AnimationFrame,
  SoundAnimationFrame,
} from '../../element-editor/animation/components/animation-frame/animation.types';
import { AnimationFrameObject } from '../../element-editor/animation/frame/animation-frame-object';
import { RootAnimationFrame } from '../../element-editor/animation/frame/main-animation-frame-object';
import { PathSection } from '../../element-editor/shape/shapes/path-shape/path-sections/path-section';
import { ImportedShape } from '../../element-editor/shape/shapes/general/imported/imported-shape';
import { GeneralSelectorConfig } from '../../components/util/general-selector/general-selector.component';
import { RootShape } from '../../element-editor/shape/shapes/general/root/root-shape';
import { Application, Container, Text } from 'pixi.js';
import { MetascriptService } from '../../ms/metascript.service';
import { SVG } from '../../element-editor/shape/shapes/primitive/svg-element';
import { environment } from '../../../environments/environment';
import { RectangleController } from '../../elements/util/rectangle-controller/rectangle-controller';
import { PointController } from '../../elements/util/point-controller/point-controller';
import { Store } from '@ngrx/store';
import {
  incrementStrokeWidthAction,
  saveShapesAction,
  saveFileRequest,
  setFontLoaded,
} from '../../element-editor/store/editor.actions';
import { setMainAnimationFrame } from '../../element-editor/animation/store/animation.actions';
import { HttpService } from '../../store/http/http.service';
import { Rectanglelement } from '../../element-editor/shape/shapes/primitive/rectangle-element';
import { ActivatedRoute, Router } from '@angular/router';

export const [screenX, screenY] = [1420, 810];
export const [_screenX, _screenY] = [1216, 684];
export const [screenXOffset, screenYOffset] = [0, 0];
// export const [screenXOffset, screenYOffset] = [323, 23];
export enum DataEditorState {
  Animation = 'animation',
  Label = 'label',
}

export interface Action {
  id: string;
  prev: Action;
  next?: Action;
}

export interface APIResponse<T = any> {
  data?: T;
  error?: any;
}

export type ConnectionTypes = 'parent' | 'child' | 'prev' | 'next';

export interface AnimationPatch {
  type: 'post' | 'patch' | 'delete';
  id: string;
  frame?: AnimationFrame;
  connection?: ConnectionTypes;
}

export interface SingleAnimation {
  shapeAlias: ShapeAlias;
  shapeGroup: string;
  animation: Animation;
}

// const UNSAVED_IMAGE_KEY = 'UNSAVED_IMAGE_KEY'; //

export const INITIAL_PATCH_KEY = '0';
const INITIAL_DIMENSION = {
  scale: 1,
  x: 0,
  y: 0,
};
const INITIAL_SCALE_VALUE = 1;

declare let WebFont;

@Injectable()
export class CanvasService {
  app: Application = new Application({
    autoDensity: true,
    resolution: window.devicePixelRatio,
    antialias: true,
    backgroundColor: '#ffffff',
    width: 1440,
    height: 810,
  });

  canvasNativeElement: HTMLDivElement;
  canvas: any;

  screenWidth = 1420;
  screenHeight = 810;

  previewApp: Application = new Application({
    autoDensity: true,
    resolution: 2,
    antialias: true,
    backgroundColor: '#ffffff',
  });
  /********** ACTION ***********/

  urlQueryParams: {
    state?: string;
  } = {};

  debug: Record<string, any> = {};

  hoveredPC: PointController;

  logs: Record<string, EventEmitter<string>> = {};

  orientationLimit = 6;

  pendingPatches: Record<string, PathShapeDescriptor> = {};
  snapShots: Record<string, PathShapeDescriptor>[] = [];

  elements: Record<string, GeneralShapeDescriptor> = {};

  images: Record<string, ImageDescriptor> = {};

  IRIs: Record<string, string> = {};

  animationAssignments: {
    shape: ShapeAlias;
    animation: string;
  }[] = [];

  shapeGroups: Record<string, ResourceIRI[]> = {};

  started: Time;
  shiftTime: Time;

  currentIRI: string;

  /************ CURSOR *************/
  currentCursorPoint: Point;

  // originOffset: PointData = { x: 0, y: 0 };

  pathShapeExtendMode: 'line' | 'curve' | 'anchor-curve' = 'line';

  /********** STATES ***********/

  tmpPosition: {
    x: number;
    y: number;
    scale: {
      x: number;
      y: number;
    };
  };

  alt = false;

  frameToBeMoved?: AnimationFrameObject;
  animationFrameMoveMode: 'set-frame-to-be-moved' | 'set-prev' | 'move-up';

  setAnimationFrameMoveMode(
    mode: 'set-frame-to-be-moved' | 'set-prev' | 'move-up',
  ) {
    // -- // -- //
    this.animationFrameMoveMode = mode;
  }

  maskSelection = false;
  shapesTobeMasked: GeneralShape[];

  dataEditorState: DataEditorState;
  dataEditorType: string;
  disabled = false;
  dragState = false;
  loading = false;
  shift = false;
  unsavedImage = false;
  toSave = false;

  showAnimationPanel = true;

  shapeStore: Record<string, Record<string, ResourceIRI>> = {};
  shapeAlias: Record<ShapeAlias, ResourceIRI> = {};

  /********** STORE ***********/
  animations: Record<string, Animation> = {};

  animationSuscribers = {};

  // shapeRegistry: Record<string, > = {};
  resourceStore: Record<string, Resource> = {};

  loadedShapes: Record<string, boolean> = {};

  currentImageKey: string;
  newImageName: string;

  @Output() componentCall: EventEmitter<ComponentEvent> =
    new EventEmitter<ComponentEvent>();

  animationInterval: number;
  animationTick = new Subject<number>();
  lastAnimationStarted: number;
  componentMessager = new Subject<{ target: string; message: any }>();
  scaler = new Subject<{ x: number; y: number; scale: number }>();
  mouseMove = new Subject<{ x: number; y: number }>();
  clickEvent = new Subject<string>();
  // generalEvent = new Subject<any>();
  generalEvents: Record<string, Subject<unknown>> = {};

  pressedKeys: Record<string, number> = {};

  // scaleValue = INITIAL_SCALE_VALUE;

  circle: any;

  canvasOffset: { x: number; y: number } = { x: 0, y: 0 };

  clickDisabled = false;

  customShapeToDrag: GeneralShape;

  _previewShape: RootShape;

  hoveredSection: PathSection;
  hoveredPoint: PathSection;

  hoveredControlPoint: ControlPointDefinition;
  draggedPointController: PointController;
  _hoveredControlPoint: PointController;

  pathSections: Record<string, PathSection> = {};

  copiedShapes: GeneralShape[];
  copiedAnimation: AnimationFrame;

  copiedFunction: {
    name: string;
    frame: AnimationFrame;
  };

  blinkIRI: string;

  logShapes: string[] = [];

  _canvasScale = 1;

  get canvasScale() {
    return this._canvasScale;
  }

  set canvasScale(val: number) {
    this._canvasScale = val;
  }

  previewShapesByScene: Record<string, RootShape> = {};
  currentShape: RootShape;

  set previewShape(rootShape: RootShape) {
    // this.previewShapesByScene[this.currentScene || 'main'] = rootShape;
    this.currentShape = rootShape;
  }

  get previewShape() {
    return this.currentShape;
  }

  get currentStates() {
    return this.previewShape?.descriptor.states || [];
  }

  get currentState() {
    return this.previewShape?.currentState;
  }

  set currentState(val: string) {
    console.log('set-current-state', val);
    this.previewShape.currentState = val;
  }

  get debugEntries() {
    return Object.entries(this.debug);
  }

  get currentResources() {
    return Object.values(this.resourceStore || {}) as GeneralShape[];
  }

  get onlyOneSelected() {
    return this.selectedShapes?.length === 1;
  }

  get selectedTrajectoryShapes() {
    return this.selectedShapes.filter(shape => shape.getType() === 'ts');
  }

  get isShiftPressed() {
    return this.isPressed('Shift');
  }

  get isAlPressed() {
    return this.isPressed('Alt');
  }

  get isMetaPressed() {
    return this.isPressed('Meta');
  }

  get currentFunctions() {
    return (
      this.previewShape?.animationFrame?.frame.functions?.map(
        ({ name }) => name,
      ) || []
    );
  }

  xs: number;
  ye: number;

  xMax: number;
  yMax: number;

  isImageProcessingMode = false;

  startAction() {
    const current = {
      id: Math.random().toString(),
      prev: this.current,
    };

    if (this.current) {
      this.current.next = current;
    }
    this.current = current;
  }

  current: Action;

  endAction() {
    // -- //
  }

  getXCoord(x: number) {
    return x - this.xs;
  }

  getYCoord(y: number) {
    return this.ye - y;
  }

  rotateMode = false;
  start: number;
  sum = 0;

  deltas: number[] = [];

  mode: 'none' | 'edit' | 'image' = 'none';

  projectIRI: string;

  loadedItem?: string;
  loadedState?: string;
  currentStateName: string;

  pathShapeSelectMode = false;

  playOnlyTillNextStage = true;
  currentScene: string;

  currentProjectID: string;

  currentFileID: string;
  loadedFileID: string;
  currentOrganisationID: string;

  constructor(
    // TODO - move it to http
    readonly msService: MetascriptService,
    public route: ActivatedRoute,
    public router: Router,
    readonly store?: Store,
    // This is used by the image shape
    readonly httpService?: HttpService,
  ) {
    if (WebFont) {
      WebFont?.load({
        google: {
          // families: ['Work Sans:300,400,700'],
          families: ['Lalezar'], // Only one weight is available for Lalezar
        },
        active: e => {
          this.store.dispatch(setFontLoaded({ value: true }));
          console.log('font loaded!');
          // now start setting up your PixiJS (or canvas) stuff!
        },
      });
    }

    this.rootFixContainer = new Container();
    this.canvasContainer = new Container();

    this.app.stage.addChild(this.canvasContainer);
    // WebFont.load({
    //   google: {
    //     families: ['Inter:300,400,500, 600,700'],
    //   },
    //   active: e => {
    //     this.store.dispatch(setFontLoaded({ value: true }));
    //     // now start setting up your PixiJS (or canvas) stuff!
    //   },
    // });

    if (!environment.production) {
      globalThis.__PIXI_APP__ = this.app;
    }

    this.keyDownEventSubscribe('g', () => {
      if (this.tmpPosition) {
        const { x, y, scale } = this.tmpPosition;

        this.previewShape.rootContainer.setTransform(x, y, scale.x, scale.y);
        return;
      }

      this.tmpPosition = {
        x: this.previewShape.translateX,
        y: this.previewShape.translateY,
        scale: {
          x: this.previewShape._scaleX,
          y: this.previewShape._scaleY,
        },
      };

      // console.log('tmp-position', this.tmpPosition); //
    });

    this.keyEventSubscribe(
      'r',
      () => {
        console.log('cs:r:10');
        this.shapeAddMode = this.shapeAddMode ? null : 'rectangle';
      },
      10,
    );

    this.keyDownEventSubscribe('AltLeft', () => {
      console.log('keyevent-altleft');
      this.previewShape?.shapes.map(shape => shape.deselect({ onHover: true }));
    });

    this.keyDownEventSubscribe('Alt', () => {
      this.previewShape?.shapes.map(shape => shape.deselect({ onHover: true }));
    });

    this.keyEventSubscribe(
      't',
      () => (this.shapeAddMode = this.shapeAddMode ? null : 'text'),
      10,
    );
    this.keyEventSubscribe(
      'e',
      () => (this.shapeAddMode = this.shapeAddMode ? null : 'circle'),
      10,
    );
    this.keyEventSubscribe(
      'w',
      () => (this.shapeAddMode = this.shapeAddMode ? null : 'path'),
      10,
    );

    this.keyEventSubscribe('Escape', () => (this.shapeAddMode = null));
    this.keyEventSubscribe(
      'a',
      () => {
        if (!this.isShiftPressed) {
          this.previewShape?.setPreAnimationState();
        }
      },
      100,
    );

    this.keyEventSubscribe('--Shift+s', () => {
      // todo implmeent
    });

    this.keyEventSubscribe('Shift+f', () => {
      // todo - implement
    });
    this.keyEventSubscribe('s+ArrowUp', () => {
      this.store.dispatch(incrementStrokeWidthAction({ increment: 1 }));
    });
    this.keyEventSubscribe('s+ArrowDown', () => {
      this.store.dispatch(incrementStrokeWidthAction({ increment: -1 }));
    });
    this.keyEventSubscribe('s+ArrowRight', () => {
      this.store.dispatch(incrementStrokeWidthAction({ increment: 5 }));
      this.consumeKeyEvent('ArrowRight');
    });
    this.keyEventSubscribe('s+ArrowLeft', () => {
      this.store.dispatch(incrementStrokeWidthAction({ increment: -5 }));
      this.consumeKeyEvent('ArrowLeft');
    });

    // Do not remove
    this.currentCursorPoint = new Point(0, 0);

    // this.keyEventSubscribe('v', () => {
    //   if (this.copiedFunction) {
    //     const { name, frame } = this.copiedFunction;
    //     this.mainFrame.addFunction(name, frame);
    //     this.mainFrame.save();
    //     this.notify('Function has been pasted!');
    //   }
    // });

    this.keyEventSubscribe('Escape', () => {
      this.shapeAddMode = null;
      if (this.generalSelectorConfigEnabled) {
        this.generalSelectorConfigEnabled = false;
        this.generalSelectorCallback = () => null;
      }
    });

    this.keyEventSubscribe('s+a', () => {
      console.log('s+a');
      this.previewShape?.shapes.map(shape => shape.show());
    });

    this.keyEventSubscribe('v+ArrowLeft', () => {
      this.selectedShapes.map(shape => {
        shape.completeDrag(-5 / this.canvasScale, 0);
      });
    });

    this.keyEventSubscribe('v+ArrowRight', () => {
      this.selectedShapes.map(shape => {
        shape.completeDrag(5 / this.canvasScale, 0);
      });
    });

    this.keyEventSubscribe('v+ArrowUp', () => {
      this.selectedShapes.map(shape => {
        shape.completeDrag(0, -5 / this.canvasScale);
      });
    });

    this.keyEventSubscribe('v+ArrowDown', () => {
      this.selectedShapes.map(shape => {
        shape.completeDrag(0, 5 / this.canvasScale);
      });
    });

    this.keyEventSubscribe('e+f', () => this.flipAnimationEditMode());
    this.keyEventSubscribe('x', () => this.flipAuxMode());

    // this.keyEventSubscribe('Shift+O', () => this.defineInputs());
    // this.keyEventSubscribe('l', () => {
    //   console.log('------ h ------', this.orientationService.horizontal);
    //   console.log('------ v ------', this.orientationService.vertical);
    // });

    this.keyEventSubscribe('Shift+Y', () => {
      if (this.mainFrame) {
        this.copiedAnimation = cloneDeep(this.mainFrame.frameObject);
        this.notify('Animation has been copied!');
      }
    });

    // this.keyEventSubscribe('Shift+M', () => {
    //   if (this.copiedAnimation) {
    //     // this.saveAnimation(this.copiedAnimation);
    //     this.notify('Animation has been copied!');
    //   }
    // });

    this.keyEventSubscribe('Alt+ArrowUp', () => {
      this.consumeKeyEvent('ArrowUp');
      this.previewShape?.translateCanvas(0, 100 / this.canvasScale);
    });
    this.keyEventSubscribe('Alt+ArrowDown', () => {
      this.consumeKeyEvent('ArrowDown');
      this.previewShape?.translateCanvas(0, -100 / this.canvasScale);
    });
    this.keyEventSubscribe('Alt+ArrowLeft', () => {
      this.consumeKeyEvent('ArrowLeft');
      this.previewShape?.translateCanvas(100 / this.canvasScale, 0);
    });
    this.keyEventSubscribe('Alt+ArrowRight', () => {
      this.consumeKeyEvent('ArrowRight');
      this.previewShape?.translateCanvas(-100 / this.canvasScale, 0);
    });

    this.keyEventSubscribe('Space+ArrowLeft', () => {
      this.previewShape.shiftStateToLeft();
    });
    this.keyEventSubscribe('Space+ArrowRight', () => {
      this.previewShape.shiftStateToRight();
    });

    this.keyEventSubscribe('Shift+Z', () => this.undo());
    this.keyEventSubscribe('Shift+z', () => this.undo());

    // this.keyEventSubscribe('i', () => {
    //   if (!this.previewShape) {
    //     return;
    //   }

    //   let shape: GeneralShape;
    //   switch (this.selectedShapes.length) {
    //     case 0:
    //       shape = this.previewShape;
    //       break;
    //     case 1:
    //       shape = this.selectedShapes[0];
    //       break;
    //     default:
    //       return alert('Please select only one or no shape for that menu!');
    //   }

    //   let scheme: TypeDef;
    //   let data: Record<string, any>;

    //   if (shape.getType() === 'is') {
    //     scheme = (shape as ImportedShape).inputs;
    //   } else {
    //     scheme = shape.inputs;
    //   }

    //   data = cloneDeep(shape.env);

    //   // TODO - clean this with migration
    //   if (Array.isArray(data)) {
    //     data = {};
    //   }

    this.keyEventSubscribe(
      'Shift+s',
      () => {
        this.patchFiles();
        this.consumeKeyEvent('s');
      },
      -10,
    );

    this.keyDownEventSubscribe('a', () => {
      if (this.shapeAddMode == 'path') {
        this.pathShapeExtendMode = 'line';
        this.consumeKeyEvent('a');
        return;
      }
    });

    this.keyDownEventSubscribe('s', () => {
      if (this.shapeAddMode == 'path') {
        this.pathShapeExtendMode = 'curve';
        this.consumeKeyEvent('s');
        return;
      }
    });

    this.keyDownEventSubscribe('d', () => {
      if (this.shapeAddMode == 'path') {
        this.pathShapeExtendMode = 'anchor-curve';
        this.consumeKeyEvent('d');
        return;
      }
    });

    this.keyEventSubscribe('Shift+a', async () => {
      this.shapes?.map(ps => ps.select());
      this.consumeKeyEvent('a');
    });

    this.keyEventSubscribe('z', () => this.flipZoomMode());
    this.keyEventSubscribe('m', () => this.flipPlayTillNextStage());

    this.keyEventSubscribe('e', () => {});

    this.keyEventSubscribe('y+m', () => {
      if (!this.previewShape) {
        return;
      }

      //const selectedShapes = this._selectedShapes;
      // switch (selectedShapes.length) {
      //   case 0:
      //     const keys = ['inputs', 'ms', 'env'];
      //     this.initYamlEditor(
      //       pick(this.previewShape.descriptor, keys),
      //       data => {
      //         this.previewShape.updateObject(
      //           this.previewShape.descriptor,
      //           data,
      //           keys
      //         );
      //         this.previewShape.contextChangedEvent.next();
      //         this.previewShape.save();
      //       }
      //     );
      //     break;
      //   case 1:
      //     const [shape] = selectedShapes;
      //     this.initYamlEditor(shape.descriptor.ms, data => {
      //       shape.descriptor.ms = data;
      //       shape.save();
      //       shape.contextIsUpdated();
      //     });
      //     break;
      //   default:
      //     console.warn(`For this operation only one shape can be selected!`);
      // }
    });
  }

  undo() {}
  redo() {}

  isPreAnimationState = false;

  setPreAnimationState() {
    if (this.isPreAnimationState) {
      return;
    }
    this.shapes.map(shape => shape.setPreAnimationState());
    this.isPreAnimationState = true;
  }

  resetCanvasPosition() {
    this.previewShape.resetPosition();
  }

  flipPlayTillNextStage() {
    return;
    if (this.playOnlyTillNextStage) {
      this.playOnlyTillNextStage = false;

      // we need remove all so that after that we can add all shapes in the right order
      this.previewShape.shapesByIndex.map(shape =>
        shape.removeContainersFromParent(),
      );
      console.log('current-state', this.currentState);
      this.previewShape.shapesByIndex.map(shape =>
        shape.showHideByState(this.currentState),
      );
      this.previewShape.shapesByIndex.map(shape =>
        shape.addContainersToParent(),
      );
    } else {
      this.playOnlyTillNextStage = true;
      this.previewShape.shapesByIndex.map(shape =>
        shape.addRemoveByState(this.currentState),
      );
    }
  }

  // -- // -- // -- // -- //
  canvasWidth: number;
  canvasHeight: number;

  /***************************************************************************************/
  /**********************************     UTIL     ***************************************/
  /***************************************************************************************/

  private keyEvents: Record<string, Record<number, Subject<void>>> = {};
  private longKeyEvents: Record<string, Record<number, Subject<void>>> = {};
  private keyUpEvents: Record<string, Record<number, Subject<void>>> = {};
  private keyDownEvents: Record<string, Record<number, Subject<void>>> = {};
  private keyEventConsumed: Record<string, boolean> = {};
  private _keyEventConsumed = false;

  keyEventSubscribe(
    key: string,
    handler: (consume?: () => void) => void,
    prio = 0,
  ): Subscription {
    this.keyEvents[key] ||= {};
    this.keyEvents[key][prio] ||= new Subject();
    return this.keyEvents[key][prio].subscribe(() => {
      if (!this.keyEventConsumed[key] && !this._keyEventConsumed) {
        handler(() => this.consumeKeyEvent(key));
      }
    });
  }

  longKeyEventSubscribe(
    key: string,
    handler: (consume?: () => void) => void,
    prio = 0,
  ): Subscription {
    this.longKeyEvents[key] ||= {};
    this.longKeyEvents[key][prio] ||= new Subject();
    return this.longKeyEvents[key][prio].subscribe(() => {
      if (!this.keyEventConsumed[key] && !this._keyEventConsumed) {
        handler(() => this.consumeKeyEvent(key));
      }
    });
  }

  keyDownEventSubscribe(
    key: string,
    handler: (consume?: () => void) => void,
    prio = 0,
  ): Subscription {
    this.keyDownEvents[key] ||= {};
    this.keyDownEvents[key][prio] ||= new Subject();
    return this.keyDownEvents[key][prio].subscribe(() => {
      // TODO - keydownevents consumed
      if (!this.keyEventConsumed[key] && !this._keyEventConsumed) {
        handler(() => this.consumeKeyEvent(key));
      }
    });
  }

  keyUp(key: string) {
    key = this.mapKey(key);

    const diff = performance.now() - this.pressedKeys[key];

    delete this.pressedKeys[key];
    delete this.pressedKeys[key.toLowerCase()];
    // if (!combos?.length) {
    // console.log('combos.length', combos.length);

    // if (combos?.length) {
    //   return;
    // }

    // TODO - move here key base keyEventConsumend check
    if (this._keyEventConsumed) {
      // this._keyEventConsumed = false;
      return;
    }
    if (this.keyEventConsumed[key]) {
      // console.log('keyUp > consumed > return', key);
      return;
    }

    if (diff > 800 && this.longKeyEvents[key]) {
      Object.keys(this.longKeyEvents[key] || {})
        .sort((k1, k2) => +k1 - +k2)
        .map(prio => {
          this.longKeyEvents[key][prio].next();
        });
    }

    this.emitKeyEvent(key);
  }

  mapKey(key: string) {
    if (key.startsWith('Shift')) {
      return 'Shift';
    }
    if (key.startsWith('Alt')) {
      return 'Alt';
    }

    if (key.startsWith('Meta')) {
      return 'Meta';
    }

    if (key.startsWith('Key')) {
      return key.slice(3).toLowerCase();
    }

    if (key.startsWith('Arrow')) {
      return key;
    }

    if (key.endsWith('Left')) {
      return key.slice(0, key.length - 4);
    }
    if (key.endsWith('Right')) {
      return key.slice(0, key.length - 5);
    }

    return key;
  }

  keyDown(key: string) {
    if (this.pressedKeys[key]) {
      return;
    }

    key = this.mapKey(key);
    this.pressedKeys[key] = performance.now();

    const combos = Object.keys(this.pressedKeys).filter(
      pressedKey => !!this.keyEvents[`${pressedKey}+${key}`],
    );
    combos.map(combo => {
      this.emitKeyEvent(`${combo}+${key}`);
    });
    this.emitKeyDownEvent(key);
  }

  emitKeyDownEvent(key: string) {
    Object.keys(this.keyDownEvents[key] || {})
      .sort((k1, k2) => +k1 - +k2)
      .map(prio => {
        // console.log('emitKeyEvent', key, prio); //
        this.keyDownEvents[key][prio].next();
      });
  }

  preKeyEventConsumed: Record<string, boolean> = {};

  addSubtitle(): string {
    // -- //
    return '';
  }

  emitKeyEvent(key: string) {
    if (this.preKeyEventConsumed[key]) {
      delete this.preKeyEventConsumed[key];
      return;
    }

    // this._keyEventConsumed = false;
    // this.keyEventConsumed[key] = false;
    Object.keys(this.keyEvents[key] || {})
      .sort((k1, k2) => +k1 - +k2)
      .map(prio => {
        // console.log('emitKeyEvent', key, prio);
        this.keyEvents[key][prio].next();
      });
  }

  emitKeyUpEvent(key: string) {
    Object.keys(this.keyUpEvents[key] || {})
      .sort((k1, k2) => +k1 - +k2)
      .map(prio => {
        if (!this.keyEventConsumed[key]) {
          this.keyUpEvents[key][prio].next();
        }
      });
  }

  preConsumeKeyEvent(key: string) {
    this.preKeyEventConsumed[key] = true;
  }

  consumeKeyEvent(key?: string, code?: string) {
    if (key.includes('+')) {
      return key.split('+').map(k => this.consumeKeyEvent(k));
    }

    if (key?.length == 1) {
      key = key?.toLowerCase();
    }
    // console.log('consumeKeyEvent', key);
    // if (!key) {
    //   this._keyEventConsumed = true;
    // }
    this.keyEventConsumed[key] = true;
    this.keyEventConsumed[code] = true;
    setTimeout(() => {
      this.keyEventConsumed[key] = false;
      this.keyEventConsumed[code] = false;
    }, 100);
  }

  generalEventSubscribe(key: string, handler: (e: any) => void) {
    this.generalEvents[key] ||= new Subject();
    return this.generalEvents[key].subscribe(e => handler(e));
  }

  generalEventEmit(key: string, event?: any) {
    if (!this.generalEvents[key]) {
      return;
      // throw new Error(`Invalid key (${key}) for general event emit!`);
    }
    this.generalEvents[key].next(event == undefined ? Math.random() : event);
  }

  generalSelectorConfig: GeneralSelectorConfig;
  generalSelectorConfigEnabled = false;
  generalSelectorCallback: (result: string, allResults?: string[]) => void;

  generalSelectorFinished(result: string, allResults: string[]) {
    this.generalSelectorCallback(result, allResults);
    this.generalSelectorCallback = () => null;
    this.generalSelectorConfigEnabled = false;
  }

  openGeneralSelector(
    options: GeneralSelectorConfig,
    callBack: (result: string, allResults: string[]) => void,
  ) {
    this.generalSelectorConfig = options;
    // TODO - The callback should be called from the component
    this.generalSelectorCallback = callBack;
  }

  consumeClick() {
    this.clickAbsorbed = true;
    // this.disableClick();
  }

  clearKeys() {
    this.pressedKeys = {};
  }

  isPressed(key: string | string[]) {
    return Array.isArray(key)
      ? !!key.find(k => this.pressedKeys[k])
      : !!this.pressedKeys[key];
  }

  resizeCanvas(w: number, h: number) {
    this.canvasWidth = w - 1;
    this.canvasHeight = h - 1;
    console.log('resize-canvas', w, h);
    this.app.renderer.resize(w - 1, h - 1);
  }

  logX(shape: GeneralShape, message: string) {
    if (this.logShapes.includes(shape.IRI)) {
      console.log(message);
    }
  }

  mouseMoveEvent(xa: number, ya: number) {
    // this.canvasX = xa; // - this.canvasOffset.x; // -- //
    // this.canvasY = ya; // - this.canvasOffset.y; // -- //
    // this.mouseMove.next(this.getAbsoluteCoords(xa, ya, true)); // .. // .. //
    this.mouseMove.next({ x: xa, y: ya });
  }

  /***************************************************************************************/
  /**********************************  TRANSFORM   ***************************************/
  /***************************************************************************************/

  transform(x: number, y: number, scaleIn?: boolean) {
    this.previewShape.transformAction({
      x,
      y,
      scale: scaleIn ? SCALE_IN : 1 / SCALE_IN,
    });
  }

  _scaleValue = 1;

  updateDimension(x: number, y: number, scaleIn: boolean) {
    // RIP - rip indeed
    if (this._scaleValue === 0 && this.canvasX === 0 && this.canvasY === 0) {
      this.canvasX = x;
      this.canvasY = y;
      return;
    }

    if (
      (this._scaleValue === 1 && !scaleIn) ||
      (this._scaleValue === -1 && scaleIn)
    ) {
      const [xd, yd] = [x - this.canvasX, y - this.canvasY];
      const angle =
        xd !== 0 || yd !== 0 ? Math.atan(Math.abs(yd) / Math.abs(xd)) : 0;

      const d = Math.sqrt(Math.pow(xd, 2) + Math.pow(yd, 2));
      const dNew = d * (scaleIn ? SCALE : SCALE / SCALE_IN);

      const [dx, dy] = [
        dNew * Math.cos(angle) * Math.sign(xd),
        dNew * Math.sin(angle) * Math.sign(yd),
      ];

      if (!scaleIn) {
        this.canvasX = dx;
        this.canvasY = dy;
      } else {
        this.canvasX = -dx;
        this.canvasY = -dy;
      }
      return;
    }

    if (this._scaleValue === 0 && (this.canvasX !== 0 || this.canvasY !== 0)) {
      const { x: xa, y: ya } = this.getAbsoluteCoords(x, y, true);
      const ratio = scaleIn ? 1 / SCALE : -1 / SCALE - 1;
      this.canvasX = xa - this.canvasX * ratio;
      this.canvasY = ya - this.canvasY * ratio;
      return;
    }

    const ratio = scaleIn
      ? lookup_scale_in[this._scaleValue]
      : lookup_scale_out[this._scaleValue];
    this.canvasX += (x - this.canvasX) * ratio;
    this.canvasY += (y - this.canvasY) * ratio;
  }

  getAbsoluteCoords(x: number, y: number, noOffset = false) {
    x /= this.canvasScale;
    y /= this.canvasScale;

    return {
      x: -this.canvasX / this.canvasScale + x,
      y: -this.canvasY / this.canvasScale + y,
    };
  }

  getRelativeCoords(x: number, y: number) {
    x *= this.canvasScale;
    y *= this.canvasScale;

    return [-this.canvasX + x, -this.canvasY + y];
  }

  get selectedImportedShapes() {
    return this.shapes.filter(
      shape =>
        shape instanceof ImportedShape && shape.selected && !shape.hidden,
    ) as ImportedShape[];
  }

  get canvasX() {
    return this.previewShape.translateX;
  }

  set canvasX(val: number) {
    this.previewShape.translateX = val;
  }

  get canvasY() {
    return this.previewShape.translateY;
  }

  set canvasY(val: number) {
    this.previewShape.translateY = val;
  }

  getAbsCoordsInverse(x: number, y: number) {
    if (this.canvasScale === 1) {
      return {
        x: x + this.canvasX,
        y: y + this.canvasY,
      };
    } else {
      const origin = [
        (this.canvasX * (this.canvasScale - 1)) / this.canvasScale,
        (this.canvasY * (this.canvasScale - 1)) / this.canvasScale,
      ];

      return {
        x: (x - origin[0]) * this.canvasScale,
        y: (y - origin[1]) * this.canvasScale,
      };
    }
  }

  /***************************************************************************************/
  /**********************************    PROJECT   ***************************************/
  /***************************************************************************************/

  currentProject: ResourceData;
  colorOptions: any;

  setCurrentProject(project: ResourceData) {
    this.currentProject = project;
    // console.log('currentProject', this.currentProject.literals.descriptor);

    this.generalEventEmit('color-options-loaded');
  }

  async setColorOptions() {
    this.colorOptions = [];
    if (this.previewShape) {
      this.colorOptions.push(...this.previewShape.colorInputs);
    }
    this.colorOptions.push(
      ...Object.entries(
        this.currentProject?.literals.descriptor?.colorPalette || {},
      ).map(([key, value]) => ({
        key,
        value,
      })),
    );
  }

  /***************************************************************************************/
  /**********************************     FILE     ***************************************/
  /***************************************************************************************/

  cutFileItem: ResourceData<{}, {}>;

  cutFile(data: ResourceData) {
    this.cutFileItem = data;
  }

  async pasteFile(folderIRI: string) {}

  // linear-gradient(to right, #ccc, #999999);

  /***************************************************************************************/
  /**********************************     SHAPE    ***************************************/
  /***************************************************************************************/

  adjusts: Record<string, { dx: number; dy: number }> = {};

  addAdjust(id: string, dx: number, dy: number) {
    this.adjusts[id] ||= { dx: 0, dy: 0 };
    this.adjusts[id].dx = dx;
    this.adjusts[id].dy = dy;
  }

  patchAdjust(id: string, key: 'dx' | 'dy', value: number) {
    if (!this.adjusts[id]) {
      return;
    }
    this.adjusts[id][key] = value;
  }

  removeAdjust(id: string) {
    delete this.adjusts[id];
  }

  postAdjusts() {
    Object.entries(this.adjusts).map(([key, { dx, dy }]) => {
      // console.log('applyAdjustMent', dx, dy);
      this.pathSections[key]?.applyAdjustment(dx, dy);
    });
    this.adjusts = {};
    // TODO - check if it necessary
    // this.shapes
    //  .filter((ps: PathShape) => ps.hasBeenChanged)
    //  .map(ps => ps.refresh());
  }

  get _selectedShapes() {
    return (
      this.previewShape?.shapes.filter(
        shape => shape.selected && shape.getType() !== 'root-shape',
      ) || []
    );
  }

  get selectedShapes(): GeneralShape[] {
    return (
      this.previewShape?.shapes.reduce((acc, shape) => {
        acc.push(...shape.selectedShapes());
        return acc;
        // }, (this.previewShape.selected ? [this.previewShape] : []) as GeneralShape[]) ||
      }, [] as GeneralShape[]) || []
    );
  }

  get shapesToDrag() {
    // MS - this.previewShapes.shapes[selected || currentlyDraged]
    if (this.customShapeToDrag) {
      return [this.customShapeToDrag];
    }

    return this.previewShape?.shapesToDrag;
  }

  goToRecord() {}

  _shapesToDrag: GeneralShape[];
  _shapesToDragLength: number;

  get isAltPressed() {
    return this.isPressed('Alt');
  }

  get isSpacePressed() {
    return this.isPressed('Space');
  }

  startDrag(shape?: GeneralShape) {
    if (this.isAltPressed) {
      return;
    }

    // if (shape) {
    //   this.shapeService.hideGroupRC();
    // }

    this._shapesToDrag = this.shapesToDrag;
    if (shape && !shape.selected) {
      if (this.isShiftPressed) {
        this._shapesToDrag.push(shape);
      } else {
        this.selectedShapes.map(shape => shape.deselect());
        this._shapesToDrag = [shape];
      }
    }
    this._shapesToDragLength = this._shapesToDrag.length;
    this._shapesToDrag.map(shape => shape.startBaseDrag());
  }

  dragDiff: Coords;

  dragging = false;

  selectorDimensions = { x: 0, y: 0, width: 0, height: 0 };

  copyDescriptor() {
    navigator.clipboard.writeText(this.previewShape?.descriptorObject);
    this.notify('Descriptor has been copied!');
  }

  descriptorImport = false;

  importedDescripor: any;

  importDescriptor() {
    this.descriptorImport = true;
  }

  addMask() {
    this.previewShape?.addMaskShape();
  }

  setShapeToBeMasked() {
    this.shapesTobeMasked = this.selectedShapes;
    console.log('set-shape-to-be-masked', this.shapesTobeMasked);
  }

  addScreenRectangle() {
    // -- // -- //
  }

  addToCanvas(shape: GeneralShape) {
    this.app.stage.addChild(shape.container);
  }

  addToPreview(shape: GeneralShape) {
    this.previewApp.stage.addChild(shape.container);
  }

  deselectAll(params: ShapeSelectParams = {}) {
    this.shapes.map(shape => shape.deselect(params));
  }

  /***************************************************************************************/
  /**********************************   ANIMATION  ***************************************/
  /***************************************************************************************/

  functionIds: Record<string, boolean> = {};
  animationIsBeingPlayed = false;
  batchPerSecond = 60;

  noAnimationMode = false;
  currentFunctionName = 'main';

  get mainFrame() {
    return this.previewShape?.animationFrame;
  }

  set mainFrame(frame: RootAnimationFrame) {
    this.previewShape.animationFrame = frame;
  }

  addFirstFrame() {
    // -- //
    console.log('-------yooooo xyz /----------');

    this.currentAnimation = { id: Math.random().toString(), duration: 1 };
    this.mainFrame = new RootAnimationFrame(
      this.previewShape,
      this.currentAnimation,
    );
    console.log('---------- add-first-frame ----------');
    this.store.dispatch(
      setMainAnimationFrame({
        frame: cloneDeep(this.currentAnimation),
      }),
    );
  }

  get animationFrame() {
    return this.previewShape?.descriptor?.animationFrame;
  }

  currentAnimation: AnimationFrame;
  currentAnimationFrame: AnimationFrameObject;

  async waitForsoundFilesAndPlay() {
    const sounds = {};
    const framesById = this.mainFrame.framesById;
    Object.entries(framesById).map(([id, frame]) => {
      if ((frame as SoundAnimationFrame).soundFileUrl) {
        sounds[id] = true;
      }
    });

    console.log({ sounds });

    // await new Promise(res => {
    //   const sub = this.generalEventSubscribe('sound-loaded', (id) => {
    //     delete sounds[id];
    //     if (Object.keys(sounds).length == 0) {
    //       sub.unsubscribe();
    //       res(null);
    //     }
    //   })
    // })
  }

  animationBlocked = false;

  async animate(
    frame: Partial<AnimationFrameObject>,
    params?: {
      inverse?: boolean;
      asFcnCallOf?: string;
      timeScale?: number;
    },
  ) {
    if (this.animationBlocked) {
      return;
    }

    // --> // --> //
    const { id, duration } = frame;
    const { inverse, timeScale } = params || {};
    await this.sectionAnimations(id, duration * timeScale, inverse);
  }

  async sectionAnimations(id: string, duration: number, inverse = false) {
    const shapesToAnimate = this.currentResources.filter(
      shape => !!shape.hasAnimation?.(id) && !shape.removed,
    ) as GeneralShape[];

    if (!shapesToAnimate.length) {
      return this.waitMs(duration * 1_000);
    }

    const numberOfBatches = Math.floor(duration * this.batchPerSecond);
    shapesToAnimate.map(shape =>
      shape.startAnimation(id, numberOfBatches || 1, inverse, duration),
    );

    const timeOfOneBatchInMs = 1000 / this.batchPerSecond;

    let increment = 0;
    let executionTime = 0;
    let referenceTime = 0;
    for (let i = 0; i < numberOfBatches; i++) {
      const start = performance.now();
      if (executionTime < referenceTime) {
        await this.waitMs(referenceTime - executionTime);
        increment = 1;
      } else {
        // speed up
        increment++;
        increment = Math.min(4, increment);
      }

      shapesToAnimate
        .sort((s1, s2) => s1.index - s2.index)
        .map((shape: GeneralShape) => {
          shape.incrementAnimation(increment, id);
        });

      executionTime += performance.now() - start;
      referenceTime += increment * timeOfOneBatchInMs;
      i += increment - 1;
    }
    shapesToAnimate.map(s => s.endAnimation(id));
  }

  zoomMode = false;

  flipZoomMode() {
    this.zoomMode = !this.zoomMode;
    console.log('flip-zoom-mode', this.zoomMode);
  }

  flipAnimationEditMode() {
    this.animationEditMode = !this.animationEditMode;
  }

  saveCurrentStateAsAnimation() {
    if (this.currentAnimation) {
      this.generalEventEmit('save-state', Math.random());
    }
  }

  getAnimationId() {
    return Math.random().toString();
  }

  updateRootAnimationFrame(states: string[], idOverride?: string) {
    if (states.length < 2) {
      return {};
    }

    const getFrame = index => ({
      id: this.getAnimationId(),
      stateTransition: {
        from: states[index - 1],
        to: states[index],
      },
      duration: 1,
      next: {
        id: this.getAnimationId(),
        function: `state:${states[index]}`,
        duration: 1,
      },
    });

    let frame: AnimationFrame;
    for (let i = states.length - 1; 0 < i; i--) {
      if (!frame) {
        frame = getFrame(i);
      } else {
        const prevFrame = frame;
        frame = getFrame(i);
        frame.next.next = prevFrame;
      }
    }

    return {
      id: this.getAnimationId(),
      function: `state:${states[0]}`,
      duration: 1,
      next: frame,
    };
  }

  switchPreviewShapes() {
    this._previewShape.rootContainer.visible = false;
    this.previewShape.rootContainer.visible = true;
  }

  animationEditMode = false;

  defineInputs() {
    this.previewShape?.editInputs();
  }

  openFunctionEditor() {
    // this.dialog.open(CodeEditorComponent, {
    //   data: { content: 'elemnt.stroke({ a: "1"}, 10)' },
    // });
    // console.log('open-function-editor');
  }

  auxMode = false;

  flipAuxMode() {
    this.auxMode = !this.auxMode;
  }

  shapeRecordMode = false;
  selectedShapesObject: Record<string, boolean> = {};

  startRecordMode(IRI: string, selectedIRIs: string[]) {
    // TODO - migrate
    // this.shapeRecordMode = true;
    // this.blinkIRI = IRI;
    // this.selectedShapes.map(shape => shape.deselect());
    // selectedIRIs.map(iri => {
    //   const shape = this.getResource(iri);
    //   if (!shape) {
    //     return;
    //   }
    //   shape.select();
    //   this.selectedShapesObject[iri] = true;
    // });
  }

  stopRecordMode() {
    this.shapeRecordMode = false;
    this.blinkIRI = null;
    this.selectedShapesObject = {};
    this.selectedShapes.map(shape => shape.deselect());
  }

  selectedShapesStore: GeneralShape[] = [];

  select(shape?: GeneralShape) {
    // if (this.shapeRecordMode) {
    //   this.emitSelectedShapes();
    // }
    // if (shape) {
    //   this.selectedShapesStore.push(shape);
    // }
    // if (this.shapeRecordMode) {
    //   return;
    // }
    // if (this.isPressed('Shift') && this.selectedShapesStore.length > 1) {
    //   this.shapeService.initGroupRC();
    // } else {
    //   this.shapeService.hideGroupRC();
    // }
  }

  deselect(shape?: GeneralShape) {
    // if (this.shapeRecordMode) {
    //   this.emitSelectedShapes();
    // }
    // if (shape) {
    //   this.selectedShapesStore = this.selectedShapesStore.filter(
    //     _shape => _shape.IRI != shape.IRI
    //   );
    // }
    //  this.groupContainer?.hide();
  }

  emitSelectedShapes() {
    this.generalEvents?.selectedShapeIRIs?.next(
      this.selectedShapes.map(shape => shape.IRI),
    );
  }

  _debug(key?: string, ...values: any[]) {
    this.debug[key] = `${values
      .map(v => (typeof v === 'number' ? v.toFixed() : v))
      .join(' ')}`;
  }

  log(topic: string, message: string) {
    if (!this.logs[topic]) {
      this.logs[topic] = new EventEmitter();
      this.logs[topic]
        .pipe(debounceTime(100))
        .subscribe(msg => console.log(`${topic}: ${msg}`));
    }
    this.logs[topic].emit(message);
  }

  async addComponent(data: ResourceData, image = false) {
    // TODO - reimplement
    // if (image) {
    //   const descriptor = data.literals.descriptor;
    //   this.previewShape?.addImageShape(
    //     (descriptor as ImageDescriptor).s3Id,
    //     [100, 100],
    //     null
    //   );
    // } else {
    //   const resourceData = await this.fetchShape(data.IRI);
    //   this.previewShape?.addImportedShape(resourceData);
    // }
    // const resourceData = await this.fetchShape(data.IRI);
    // this.previewShape?.addImportedShape(resourceData);
  }

  draggingPathSection: PathSection | undefined;
  draggedSVGElement: SVG;
  hoveredPointController: PointController;

  _startDrag() {
    return [this.canvasX, this.canvasY] as Coords;
  }

  click(id: string) {
    if (this.clickDisabled) {
      return;
    }
    this.clickEvent.next(id);
  }

  disableClick(time = 10) {
    this.clickDisabled = true;
    setTimeout(() => (this.clickDisabled = false), time);
  }

  async waitMs(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  startAnimation() {
    this.started = this.now;
    this.animationInterval = setInterval(() =>
      this.animationTick.next(this.timeSinceStart),
    );
  }

  stopAnimation() {
    clearInterval(this.animationInterval);
    this.animationInterval = null;
  }

  unsubscribeFromAnimation(iri: string) {
    delete this.animationSuscribers[iri];
    if (Object.keys(this.animationSuscribers).length === 0) {
      this.stopAnimation();
    }
  }

  getData(key: string) {
    switch (key) {
      case 'shapeAlias':
        return Object.keys(this.shapeAlias);
      case 'shapeGroup':
        return Object.keys(this.shapeGroups);
      default:
        return [];
    }
  }

  /******** Getting shape descriptor *************/

  // Old image service code

  // async getSubImage(): Promise<ResourceData<any>> {
  //   const response = await this.httpService
  //     .get<ImageDescriptor>(`get-subimage`, '_')
  //     .pipe(first(p => !!p))
  //     .toPromise();
  //   return response.data;
  // }

  // async fetchRegions(iri: string): Promise<ResourceData<any>> {
  //   const response = await this.httpService
  //     .get<ImageDescriptor>(`regions/${encodeURIComponent(iri)}`, '_')
  //     .pipe(first(p => !!p))
  //     .toPromise();
  //   return response.data;
  // }

  // async fetchShapesOfImage(iri: string) {
  //   const response = await this.httpService
  //     .get<ResourceData[]>(`shapes/${encodeURIComponent(iri)}`, '_')
  //     .pipe(first(p => !!p))
  //     .toPromise();
  //   return response.data;
  // }

  // @deprecated
  // processPicture(picture: ImageDescriptor) {
  //   return {
  //     ...picture,
  //     height: +picture.height['_'],
  //     width: +picture.width['_'],
  //   };
  // }

  /****************** GETTERS ***********************/

  get now() {
    return performance.now();
  }

  get timeSinceStart() {
    return this.now - this.started;
  }

  get selectedIRIs() {
    return Object.keys(this.selectedShapes);
  }

  get isThereSelectedShape() {
    return this.selectedIRIs.length > 0;
  }

  get isMultipleShapeSelected() {
    return this.selectedIRIs.length > 1;
  }

  get isSingleShapeSelected() {
    return this.selectedIRIs.length === 1;
  }

  _startAnimation(shift?: number) {
    this.started = this.now - (shift || 0) * 1_000;
  }

  diff(time: Time) {
    return time * 1000 - this.timeSinceStart;
  }

  selected(shape: GeneralShape) {
    this.selectedShapes[shape.IRI] = true;
  }

  deselected(shape: GeneralShape) {
    delete this.selectedShapes[shape.IRI];
  }

  /****************** COMPONENT CALL ***********************/

  callComponent(
    target: ComponentType | ComponentType[],
    type: string,
    message?: any,
  ) {
    this.componentCall.emit({ target, type, message });
  }

  addLabel() {
    const labelName = prompt('Name of the label:');
    if (Object.keys(this.shapeAlias).includes(labelName)) {
      alert('The labelName already exists. Try to use prefixes!');
      return;
    }
    this.shapeAlias[labelName] = Object.keys(this.selectedShapes)[0];
  }

  getShape(alias: string): GeneralShape {
    return this.resourceStore[this.shapeAlias[alias]] as GeneralShape;
  }

  _getShape(IRI: string): GeneralShape {
    return this.resourceStore[IRI] as GeneralShape;
  }

  /****************** IMAGE ***********************/

  get isImageShape() {
    return this.previewShape?.getType() == 'image-shape';
  }

  /****************** RESOURCE ***********************/

  setPicture(picture: ImageDescriptor) {
    // this.images[picture.label] = picture;
  }

  /*********************** STATE SETTING ************************/

  disable() {
    this.disabled = true;
  }

  setShift() {
    this.shift = true;
  }

  setAlt() {
    this.alt = true;
  }

  resetShift() {
    this.shift = false;
  }

  resetAlt() {
    this.alt = false;
  }

  /******************** STORE / CANVAS *********************/

  async command(key: number, x?: number, y?: number) {
    switch (key) {
      case 1:
        console.log('Up');
        this.translate(0, -100);
        break;
      case 2:
        console.log('Down');
        this.translate(0, 100);
        break;
      case 3:
        console.log('Left');
        this.translate(-100, 0);
        break;
      case 4:
        console.log('Right');
        this.translate(100, 0);
        break;

      default:
        break;
    }
  }

  /******************************* SelectorRectangle *************************/

  notification: string;

  notify(notification: string, time = 2_000) {
    this.notification = notification;
    setTimeout(() => (this.notification = null), time);
  }

  /********************************** Other ***********************************/

  resetImage() {
    // this.resourceStore = {};

    this.currentImageKey = INITIAL_PATCH_KEY;
    this.currentAnimation = null;

    this.functionStepper = null;
    this.isPreAnimationState = false;

    this.zoomMode = false;
    this.auxMode = false;
    this.animationEditMode = false;

    this.animationIsBeingPlayed = false;
    this.animationBlocked = false;

    this.stopRecordMode();

    this.previewShape?.removeAll();
  }

  shapeSelected(shape: GeneralShape) {
    if (this.shapeRecordMode) {
      return;
    }

    if (this.selectedShapes.length < 2) {
      return;
    }

    this.selectedShapesStore = [shape];
  }

  /*********************** TRANSFORM ************************/

  translateCanvas(x: number, y: number) {
    this.previewShape?.translateCanvas(x, y);
  }

  xscale(x: number, y: number, delta: number) {
    this.transform(x, y, delta > 0);
  }

  mouseDownFromElement = false;

  translate(x: number, y: number) {
    // --> //;
  }

  // async zoomAnimate() {
  //   // --> //
  //   const dScale = 0.728 / 100;
  //   const dx = -165.984 / 100;
  //   const dy = -641.368 / 100;
  //   for (let i = 0; i < 100; i++) {
  //     this.previewShape.currentMatrix.translate(dx, dy);
  //     this.previewShape.currentMatrix.scale(1 + dScale);
  //     this.previewShape.outerElementGroup.attr({
  //       transform: this.previewShape.currentMatrix,
  //     });
  //     // this.previewShape.zoom({
  //     //   x: 100,
  //     //   y: 100,
  //     //   scale: 1 + i * 0.01,
  //     // });
  //     await this.waitMs(20);
  //   }
  // }

  showBoundingRectangle() {
    // -- //
    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;
    for (const shape of this.previewShape.shapes) {
      if (shape.auxMode || shape.maskedBy) {
        continue;
      }

      if (shape.IRI == this.previewShape.IRI) {
        continue;
      }

      const { x, y, width, height } = shape.container.getBounds();

      if (height == 0 && height == 0) {
        continue;
      }

      if (x < xMin) xMin = x;
      if (y < yMin) yMin = y;
      if (xMax < x + width) xMax = x + width;
      if (yMax < y + height) yMax = y + height;
    }

    console.log('boundRectangle', { xMin, yMin, xMax, yMax });
    return;
    const rc = new RectangleController(
      this.previewShape,
      {
        offset: [xMin, yMin],
        width: xMax - xMin,
        height: yMax - yMin,
        startDrag: () => null,
        drag: params => null,
        endDrag: () => null,
        noDrag: true,
      },
      this.previewShape.auxCircleContainer,
      this.previewShape.auxCircleContainer,
    );
  }

  getSelectedBoundingRectangle() {
    // -- // -- //
    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;
    for (const shape of this._shapesToDrag) {
      const { x, y, width, height } = shape.container.getBounds();

      if (x < xMin) xMin = x;
      if (y < yMin) yMin = y;
      if (xMax < x + width) xMax = x + width;
      if (yMax < y + height) yMax = y + height;
    }

    return { xMin, yMin, xMax, yMax };
  }

  // ************** base-shape-control ******************** //

  shapeAddMode: 'rectangle' | 'circle' | 'path' | 'text';
  pathShapeAddMode: 'line' | 'curve' | 'anchor-curve';
  // Maybe later can be used for debug purposes
  // refreshCircle() {
  //   if (!this.circle && !!this.snap) {
  //     this.circle = this.snap.circle(this.canvasX, this.canvasY, 4).attr({
  //       fill: 'white',
  //       stroke: 'black',
  //     });
  //   } else {
  //     this.circle.attr({ cx: this.canvasX, cy: this.canvasY });
  //   }
  // }

  /******************************* SAVE / UNDO / REDO ******************************/

  get auxLineConfig() {
    return {
      stroke: '#ff0000',
      'stroke-width': 1,
      'stroke-opacity': 0.5,
    };
  }

  get undoDisabled() {
    return this.currentPatch === 0;
  }

  get redoDisabled() {
    return this.currentPatch >= this.patchCount;
  }

  tmpIRIs: string[] = [];

  async patchFiles() {
    this.store.dispatch(saveFileRequest());
    this.store.dispatch(saveShapesAction());
  }

  tmpFunctionFrames: Record<string, AnimationFrameObject> = {};

  clone(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  resetStore() {
    this.generalEventEmit('shapes', []);
    this.updateStore();
  }

  updateStore() {
    this.generalEventEmit('shapes', this.shapes);
  }

  get shapes(): GeneralShape[] {
    return this.previewShape?.shapes || [];
  }

  get allShapes() {
    return this.previewShape?.shapes.reduce((prev, curr) => {
      prev.push(...curr.allShapes);
      return prev;
    }, [] as GeneralShape[]);
  }

  currentPatch = parseInt(INITIAL_PATCH_KEY);
  patchCount = 0;

  patchIncrement() {
    this.currentPatch++;
    this.patchCount++;
  }

  patch(key: string, value: any) {
    this.patchIncrement();
    this.selectedShapes.map(shape => shape.patch(key, value));
  }

  reInits: PathShape[] = [];

  /******************************** Animation *********************************/

  getTarget({ type, id }: { type: ConstraintEntity; id: string }) {
    switch (type) {
      case 'section':
      case 'point':
      case 'point:a1':
      case 'point:a2':
        return this.pathSections[id];
    }
  }

  indirectPatches: Record<string, Record<string, boolean>> = {};

  addIndirectPatch(IRI: string, key: string) {
    this.indirectPatches[IRI] ||= {};
    this.indirectPatches[IRI][key] = true;
  }

  indirectPatch() {
    Object.entries(this.indirectPatches).map(([IRI, value]) => {
      Object.keys(value).map(key => {
        if (this.resourceStore[IRI]) {
          this.resourceStore[IRI]?.patchByKey(key);
        } else {
          console.warn(`Patch target (${IRI}) doesn't exists.`);
        }
      });
    });
    this.indirectPatches = {};
  }

  refresh() {
    this.generalEventEmit('refresh');
  }

  clickAbsorbed = false;
  mouseDownAbsorbed = false;
  // It is true when a selector rectangle is dragged
  mouseDownFree = false;
  b1: number;
  b2: number;

  functionStepper: string;

  animationFrameCut: {
    frame: AnimationFrameObject;
    fcnName: string;
  };

  currentResourceData: ResourceData;

  get activeFrame(): RootAnimationFrame {
    return this.mainFrame;
  }

  reloadPreviewShape() {
    this.generalEventEmit('route-update', {});

    if (!this.previewShape) {
      return;
    }
    this.previewShape.remove();
  }

  scaleOffset = 1;
  translateOffsetX = 0;
  translateOffsetY = 0;

  rootFixContainer: Container;

  canvasContainer: Container;

  addSceneToCanvas(shape: RootShape) {
    this.canvasContainer.addChild(shape.outerContainer);
  }

  async addShapeToCanvas(shape: RootShape) {
    // this.app.stage.removeChildren();
    this.canvasContainer.removeChildren();
    this.canvasContainer.addChild(shape.outerContainer);
    this.previewShape = shape;
    // this.app.stage.addChild(shape.outerContainer); //

    // const xRatio = this.canvasWidth / screenX;

    // let scale = 1;
    // const scaleInCnt = 0;

    // for (let i = 0; i < scaleInCnt; i++) {
    //   scale /= SCALE_IN;
    // }

    // scale = SCALE_IN;
    // // if (xRatio < 1) { //
    // //   for (let i = 0; i < 100; i++) { //

    // console.log('----- cs:scale ----', scale);

    // this.rootShapeContainer.position.x = (screenX - screenX * scale) / 2;
    // this.rootShapeContainer.position.y = (screenY - screenY * scale) / 2;

    const config = {
      browser: {
        x: -165,
        y: 33,
        scale: 1.05,
      },
      main: {
        x: 76,
        y: 58,
        scale: 0.9,
      },
      chart: {
        x: -52,
        y: 12,
        scale: 1.2,
      },
      base: {
        x: 0,
        y: 0,
        scale: 1,
      },
    };

    // const { x, y, scale } = config.base;

    this.initScreen(shape);
  }

  // get isImageOpened() {
  //   if (!this.previewShape) {
  //     return false;
  //   }
  //   return this.previewShape instanceof ImageShape2;
  // }

  screenRectangle: Rectanglelement;
  initScreen(previewShape: RootShape) {
    return;
    const canvasWidth = window.innerWidth - 408;
    const canvasHeight = window.innerHeight - 68;
    // -- //

    this.scaleOffset = 1;
    let width: number, height: number;
    if (previewShape.orientation == 'landscape') {
      width = _screenX;
      height = _screenY;
      if (canvasWidth < _screenX) {
        for (let i = 0; i < 10; i++) {
          this.scaleOffset /= SCALE_IN;
          if (this.scaleOffset * _screenX < canvasWidth) {
            // console.log('yooo', this.scaleOffset);
            //  console.log('diff', canvasWidth - this.scaleOffset * _screenX);
            this.translateOffsetX =
              (canvasWidth - this.scaleOffset * _screenX) / 2;
            // previewShape._scaleX = this.scaleOffset;
            // previewShape._scaleY = this.scaleOffset;
            // previewShape.translateX = this.translateOffsetX;
            this.canvasContainer.scale.x = this.scaleOffset;
            this.canvasContainer.scale.y = this.scaleOffset;
            this.canvasContainer.position.x = this.translateOffsetX;
            previewShape.redraw();
            break;
          }
        }
      } else {
        this.translateOffsetX = (canvasWidth - _screenX) / 2;
        this.canvasContainer.position.x = this.translateOffsetX;
        previewShape.redraw();
      }
    } else {
      width = _screenY;
      height = _screenX;
      if (canvasHeight < height) {
        for (let i = 0; i < 10; i++) {
          this.scaleOffset /= SCALE_IN;
          if (this.scaleOffset * height < canvasHeight) {
            // console.log('yooo', this.scaleOffset);
            //  console.log('diff', canvasWidth - this.scaleOffset * _screenX);
            this.translateOffsetX =
              (canvasWidth - this.scaleOffset * _screenY) / 2;
            this.canvasContainer.scale.x = this.scaleOffset;
            this.canvasContainer.scale.y = this.scaleOffset;
            this.canvasContainer.position.x = this.translateOffsetX;
            previewShape.redraw();
            break;
          }
        }
      } else {
        this.translateOffsetX = (canvasWidth - _screenY) / 2;
        this.canvasContainer.position.x = this.translateOffsetX;
      }
    }

    this.screenRectangle?.remove();

    this.screenRectangle = new Rectanglelement(
      previewShape,
      this.canvasContainer,
      // this.app.stage,
      {
        position: {
          x: 1,
          y: 1,
        },
        width,
        height,
        stroke: '#afafaf',
        'stroke-width': 0.5,
      },
    );
  }

  adjustSelectedShapes() {
    if (this.selectedShapesStore.length < 2) {
      return;
    }
    // -- // -- // -- //
    const first = this.selectedShapesStore.shift();
    const [w, h] = [first.width, first.height];
    for (const shape of this.selectedShapesStore) {
      shape.resize(w, h);
    }
  }

  toBase64(file: File) {
    return new Promise<APIResponse<string>>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ data: reader.result as string });
      reader.onerror = () => resolve({ error: reader.error });
    });
  }

  async saveImageShape() {}
}
