<div
  class="scale-animation-component"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Rotation"
    [noDelete]="true"
    [attributeState]="attributeState"
    key="rotation"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section"
    [ngClass]="{
      'value-section-disabled': !!attributeState.selectedFunctions?.length,
    }"
  >
    <div class="attr-container flex-column gap-4">
      <nw-number-input
        [key]="'angle'"
        [value]="attributeState.value['angle']"
        (changed)="updateSubAttributeValue('angle', $event)"
      ></nw-number-input>
    </div>
    <!-- <div *ngSwitchCase="'function'">output</div>
    <div *ngSwitchCase="'mixed'">output</div>
    <div *ngSwitchCase="'undefined'">output</div> -->
  </div>
</div>
