import { Pipe, PipeTransform } from '@angular/core';

import { cloneDeep as _cloneDeep } from 'lodash';

@Pipe({ name: 'step' })
export class StepPipe implements PipeTransform {
  transform<T>(value: number): number {
    if (typeof value !== 'number') {
      return 0;
    }
    return parseFloat(value.toFixed(2));
  }
}
