import { SCALE_IN } from "./../canvas/canvas.types";
export interface PointData {
  x: number;
  y: number;
}

export const SCALER_RATIO = 0.4545454545454547;

export class VectorService {
  public static scale(p1: PointData, p2: PointData, scale: number): PointData {
    return {
      x: p1.x + this.absX(p1, p2) * Math.pow(SCALE_IN, scale),
      y: p1.y + this.absY(p1, p2) * Math.pow(SCALE_IN, scale),
    };
  }

  public static scale1(p1: PointData, p2: PointData, scale: number): PointData {
    return {
      x: p1.x + this.absX(p1, p2) * scale,
      y: p1.y + this.absY(p1, p2) * scale,
    };
  }

  public static absX(start: PointData, end: PointData): number {
    return end.x - start.x;
  }

  public static absY(start: PointData, end: PointData): number {
    return end.y - start.y;
  }
}
