import { createAction, props } from '@ngrx/store';
import { Organisation } from './organisation.interface';
import { User } from '../user/user.interface';
import { RoleTypes } from './role.interface';

export const OrganisationFeature = 'Organisation';

function getActionName(action: string) {
  return `[${OrganisationFeature}] ${action}`;
}

export const loadOrganisations = createAction(getActionName('list'));

export const loadOrganisationById = createAction(
  getActionName('get by ID'),
  props<{ id: string }>(),
);

export const createOrganisation = createAction(
  getActionName('create'),
  props<{ organisation: Partial<Organisation> }>(),
);

export const setOrganisation = createAction(
  getActionName('set'),
  props<{ organisation: Organisation }>(),
);

export const setUserRoleInOrganisation = createAction(
  getActionName('set-new-role-in-org'),
  props<{ organisationId: string; userId: string; role: RoleTypes }>(),
);

export const setOrganisations = createAction(
  getActionName('set batch'),
  props<{ organisations: Organisation[] }>(),
);

export const setCurrentOrganisationID = createAction(
  getActionName('organisation:set-current-org-id'),
  props<{ ID: string }>(),
);

export const assignUsersToOrganisation = createAction(
  getActionName('assign users'),
  props<{ users: User[]; organisation: Organisation }>(),
);

export const saveOrganisationColorPalette = createAction(
  getActionName('save-color-palette'),
  props<{ colorPalette: Record<string, string> }>(),
);

export const setOrganisationColorPalette = createAction(
  getActionName('save-color-palette-base'),
  props<{ colorPalette: Record<string, string> }>(),
);

export const saveOrganisation = createAction(
  getActionName('save-save-organisation'),
  props<{ id: string }>(),
);
