<div class="project-header-component">
  <div class="main-header editor-container flex-row space-around w-100 fs-14">
    <div>{{ projectLabel$ | async }}</div>
    <div style="position: absolute; top: 9px; left: 9px">
      <nw-icon type="menu" [size]="15" (click)="editOpen = !editOpen"></nw-icon>
    </div>
  </div>

  <div *ngIf="editOpen" class="project-settings editor-container">
    <div class="inner-container flex-column start gap-10">
      <div class="flex-row gap-8 pointer" (click)="selectOverlay('languages')">
        <nw-icon type="language" [size]="13"></nw-icon>
        <div class="fs-13">Languages</div>
      </div>
      <div
        class="flex-row gap-8 pointer"
        (click)="selectOverlay('color-palette')"
      >
        <nw-icon type="palette" [size]="13" [outlined]="true"></nw-icon>
        <div class="fs-13">Color Palette</div>
      </div>
      <div class="flex-row gap-8 pointer">
        <nw-icon type="group" [size]="13" [outlined]="true"></nw-icon>
        <div class="fs-13">Users</div>
      </div>
      <!-- <div class="flex-row gap-8 pointer">
        <nw-icon type="border_color" [size]="13" [outlined]="true"></nw-icon>
        <div class="fs-13">Styles</div>
      </div> -->
      <div class="flex-row gap-8 pointer" (click)="exit()">
        <nw-icon type="arrow_back" [size]="14" [outlined]="true"></nw-icon>
        <div class="fs-13">Exit</div>
      </div>
    </div>
    <div class="close-container" (click)="editOpen = false">
      <nw-icon type="keyboard_arrow_up" [size]="20" [outlined]="true"></nw-icon>
    </div>
  </div>
</div>
