<div class="side-panel-component editor-container">
  <div
    *ngIf="!!shapeService.previewShape"
    class="w-100"
    fxLayout="row"
    fxLayoutGap=" center"
  >
    <div
      class="icon-container --right fs-12"
      fxFlex="50"
      fxLayout="column"
      fxLayoutAlign="space-around center"
      (click)="listView = false"
      [class.not-selected]="listView"
    >
      <div>files</div>
    </div>
    <div
      class="icon-container fs-12"
      fxFlex="50"
      fxLayout="column"
      fxLayoutAlign="space-around center"
      (click)="selectListView()"
      [class.not-selected]="!listView"
      [style.cursor]="isShapeSelected ? 'pointer' : 'default'"
    >
      <div>shapes</div>
    </div>
  </div>

  <div *ngIf="listView" class="shape-list-container">
    <nw-shape-item *ngIf="cs.previewShape" [shape]="cs.previewShape">
    </nw-shape-item>
    <div>
      <nw-shape-item-list [scene]="'main'"></nw-shape-item-list>
    </div>

    <div class="title">Components</div>

    <div
      *ngFor="let comp of components$ | async"
      (click)="addComponent(comp.IRI)"
      class="component-item"
    >
      <div class="flex-row gap-4">
        <nw-icon type="grid_view" [size]="11"></nw-icon>
        <div>
          {{ comp.label }}
        </div>
      </div>
    </div>
  </div>

  <div class="item-container" *ngIf="!listView">
    <div
      *ngIf="isProjectLoading$ | async; else loaded"
      class="fs-12 w-100 text-center pv-10 blink"
    >
      Files are being loaded...
    </div>

    <ng-template #loaded>
      <div *ngFor="let item of items$ | async">
        <iw-file-item [item]="item"></iw-file-item>
      </div>
      <div style="display: table; padding: 4px; margin-left: 2px">
        <div class="w-100 flex-row gap-4" *ngIf="newItemState">
          <input
            type="text"
            [(ngModel)]="newItemName"
            (click)="$event.stopPropagation()"
            style="border-radius: 3px"
            (keyup)="keyUp($event)"
            (keydown)="$event.stopPropagation()"
          />
          <div class="flex-row gap-2">
            <nw-icon
              type="done"
              [disabled]="newItemName.length < 2"
              [size]="14"
              (click)="save()"
            ></nw-icon>
            <nw-icon type="close" [size]="14" (click)="discardNew()"></nw-icon>
          </div>
        </div>

        <div fxLayout="row" *ngIf="!newItemState">
          <div
            class="flex-row gap-8"
            *ngIf="isNewFileLoading$ | async; else defaultState"
          >
            <div class="fs-12">File is being created...</div>
            <mat-spinner diameter="17" style="stroke: #0191f3"></mat-spinner>
          </div>
          <ng-template #defaultState>
            <div
              class="flex-row gap-4 pointer"
              (click)="addFile($event)"
              *ngIf="(items$ | async).length == 0; else addIcon"
            >
              <div class="fs-12">Add first file</div>
              <nw-icon type="add" [size]="16"></nw-icon>
            </div>
            <ng-template #addIcon>
              <div
                style="position: relative; left: -3px"
                (click)="addFile($event)"
              >
                <nw-icon type="add" [size]="16"></nw-icon>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- <div>
  <div
    *ngFor="let entry of cs.debugEntries"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [style.width.px]="180"
  >
    <div>{{ entry[0] }}</div>
    <div>{{ entry[1] }}</div>
  </div>
</div> -->
