import { Injectable } from '@angular/core';
import { YamlComponent } from '../components/util/yaml/yaml.component';
import { StateEditorComponent } from '../components/state-editor/state-editor.component';
import { ShapeService } from './shape/shape.service';
import { ResourceData } from '../elements/resource/resource.types';
import { CanvasService } from '../services/canvas/canvas.service';
import { KeyValueMenuComponent } from '../components/util/key-value-menu/key-value-menu.component';
import { TypeDef } from '../elements/resource/types/shape.type';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { setShowComponentSearch } from './store/editor.actions';

@Injectable()
export class EditorService {
  get previewShape() {
    return this.shapeService.previewShape;
  }

  currentProject: ResourceData;

  constructor(
    public readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly shapeService: ShapeService,
    private readonly cs: CanvasService,
    readonly dialog: MatDialog,
  ) {
    this.shapeService.cs.keyEventSubscribe('Shift+d', () => {
      this.showComponentSearch();
    });

    this.cs.generalEventSubscribe('route-update', queryParams => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: '',
      });
    });
  }

  showComponentSearch() {
    this.cs.store.dispatch(setShowComponentSearch({ value: true }));
  }

  hideComponentSearch() {
    this.cs.store.dispatch(setShowComponentSearch({ value: false }));
  }

  async pasteFile(folderIRI: string) {
    // TODO - implement in a store base fashion
    // this.db.removeArrayElement('root-items', this.cutFileItem.IRI);
    // this.db.pushElementToArray(`${folderIRI}.children`, this.cutFileItem);
    // await this.httpService.postPromise('/addtoparent', {
    //   subject: this.cutFileItem.IRI.split('#')[1],
    //   parent: folderIRI.split('#')[1],
    // });
    // this.cutFileItem = null;
    return;
  }

  async savePreviewShape() {
    // await this.httpService.patchPromise(
    //   'editor/file',
    //   this.clone(this.previewShape.resourceObject)
    // );
  }

  initYamlEditor(
    data: Record<string, any>,
    callback: (data: Record<string, any>) => void,
  ) {
    const sub = this.dialog
      .open(YamlComponent, {
        disableClose: true,
        data: { data },
      })
      .afterClosed()
      .subscribe(data => {
        callback(data);
        sub.unsubscribe();
      });
  }

  async openStateEditor() {
    console.log('open-state-editor');

    this.dialog
      .open(StateEditorComponent, {
        // data: ['planes', 'passengers', 'add-ons', 'payment'],
        data: this.previewShape.descriptor.states || [],
        hasBackdrop: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (result?: string[]) => {
        if (result) {
          // const animationFrame =  this.updateRootAnimationFrame(result);
          // this.previewShape.descriptor.animationFrame = animationFrame;
          // this.previewShape.animationFrame = new MainAnimationFrameObject(this.previewShape, animationFrame);
          // console.log('--------- save:state ---------', result);
          this.previewShape.descriptor.states = result;
          this.previewShape.save();
        }
      });
  }

  dialogData: any;

  openKeyValueEditor(
    data: Record<string, any>,
    callBack: (data: TypeDef) => void,
    dataScheme?: Record<string, any>,
  ) {
    data ||= {};
    this.dialogData = data;
    console.log('open-key-value-menu-component');
    this.dialog
      .open(KeyValueMenuComponent, { data: { data: data || {}, dataScheme } })
      .afterClosed()
      .subscribe(() => {
        if (!isEqual(data, this.dialogData)) {
          callBack(this.dialogData);
        }
      });
  }

  async saveColorPalette(palette: Record<string, string>) {
    // TDOO - implement in ngrx
    // this.currentProject.literals.descriptor ||= {};
    // this.currentProject.literals.descriptor.colorPalette = palette;
    // await this.httpService.patchPromise('editor/project', this.currentProject);
  }
}
