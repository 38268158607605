<div class="shape-item-container gap-2" fxLayout="column">
  <nw-shape-item
    *ngFor="let shape of shapes$ | async"
    [shape]="shape"
  ></nw-shape-item>
  <!-- <div> delimiter </div>
  <div *ngFor="let shape of shapes">
    <nw-shape-item
      *ngIf="!shape.showByIf"
      [shape]="shape"
      [force]="true"
    ></nw-shape-item> 
  </div> -->
</div>
