import { NgModule, Provider } from '@angular/core';
import { Auth, Amplify, Hub } from 'aws-amplify';
import { CognitoService } from './cognito.service';
import { AuthenticationService } from './authentication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationHttpInterceptor } from './authentication.interceptor';
import { UnAuthenticationGuard } from './unauthentication.guard';
import {
  AmplifyClassToken,
  AuthClassToken,
  HubClassToken,
} from './authentication.constans';

const providers: Provider[] = [
  {
    provide: AuthClassToken,
    useValue: Auth,
  },
  {
    provide: AmplifyClassToken,
    useValue: Amplify,
  },
  {
    provide: HubClassToken,
    useValue: Hub,
  },
  CognitoService,
  AuthenticationService,
  AuthenticationGuard,
  UnAuthenticationGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationHttpInterceptor,
    multi: true,
  },
];

@NgModule({
  providers,
})
export class AuthenticationModule {}
