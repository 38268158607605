import { ResourceData } from '../../../elements/resource/resource.types';
import { Component, OnInit, Input } from '@angular/core';
import { RootComponent } from '../../root/root.component';
import { first, filter, map } from 'rxjs/operators';
import { HttpService, Response } from '../../../store/http/http.service';
import { cloneDeep as _cloneDeep, omit } from 'lodash';
import { StoreService } from '../../../store/store.service';
import { KeyHandlerService } from '../../../services/keyhandler/keyhandler.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { ImageDescriptor } from '../../../elements/resource/types/shape.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { selectMyScenes } from '../../../projects/project.selector';
import { updateFileName } from '../../../projects/project.actions';

@UntilDestroy()
@Component({
  selector: 'iw-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.scss'],
})
export class FileItemComponent extends RootComponent implements OnInit {
  @Input()
  parent: ResourceData;

  @Input()
  item: ResourceData;
  localItem: ResourceData;

  @Input()
  index = 0;

  // State
  isActive = false;
  loading = false;
  opened = false;
  hidden = false;
  editState: string;

  scenes$: Observable<ResourceData[]>;

  // New form
  newItemName: string;
  iconState = false;
  selected = false;

  get localId() {
    return this.localItem.IRI.split('#')[1];
  }

  constructor(
    readonly dialog: MatDialog,
    db: StoreService,
    http: HttpService,
    khs: KeyHandlerService,
    readonly cs: CanvasService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super(db, http, khs);
  }

  mouseEnter() {
    if (this.editState == 'name') {
      this.isActive = false;
      console.log('mouse-enter > return');
      return;
    }
    this.isActive = true;
  }

  clickEdit() {}

  saveNewName(event: Event) {
    event.stopPropagation();
    this.cs.store.dispatch(
      updateFileName({
        IRI: this.localItem.IRI,
        newName: this.localItem.literals.label,
      }),
    );
    this.editState = null;
    this.isActive = true;
  }
  discardEdit(event: Event) {
    this.localItem = this.item;
    event.stopPropagation();
    this.editState = null;
    this.isActive = true;
  }
  ngOnInit(): void {
    this.scenes$ = this.cs.store
      .select(selectMyScenes(this.item.IRI))
      .pipe(
        map(scenes =>
          scenes.sort(
            (s1, s2) => s1.literals.sceneIndex - s2.literals.sceneIndex,
          ),
        ),
      );

    this.scenes$.subscribe(scenes => {
      // console.log('----- scenes ------', scenes);
    });

    this.localItem = _cloneDeep(this.item);
    this.db
      .get('fileIRI')
      .pipe(untilDestroyed(this))
      .subscribe((fileIRI: string) => {
        if (fileIRI) {
          if (fileIRI == this.cs.loadedItem) {
            return;
          }
          if (this.item.IRI.endsWith(fileIRI)) {
            this.click(false);
            this.cs.loadedItem = fileIRI;
          }
        }
      });

    // this.db
    //   .get('sidePanel.selected')
    //   .pipe(filter(IRI => this.IRI !== IRI))
    //   .subscribe(() => {
    //     this.isActive = false;
    //     this.editState = '';
    //     this.selected = false;
    //   });

    // this.db
    //   .get('sidePanel.selected')
    //   .pipe(filter(IRI => this.IRI === IRI))
    //   .subscribe(() => {
    //     this.selected = true;
    //   });

    // this.cs.keyEventSubscribe('Enter', () => this.globalClick());
    // Keyboard based control will be implemented next time
    // this.cs.keyEventSubscribe(
    //   'ArrowDown',
    //   () => {
    //     if (this.selected) {
    //       // console.log('arrowDown');
    //     }
    //   },
    //   1
    // );
  }

  keydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      return this.globalClick();
    }
    event.stopPropagation();
  }

  get IRI() {
    return this.item.IRI;
  }

  get relationships() {
    return this.isProject
      ? {
          project: {
            IRI: this.IRI,
          },
        }
      : {
          folder: {
            IRI: this.IRI,
          },
        };
  }

  get childrenRef() {
    return `${this.IRI}.children`;
  }

  get children$() {
    return this.db.get<ResourceData[]>(this.childrenRef);
  }

  get isContainer() {
    return this.isProject || this.isFolder;
  }

  get isProject() {
    return (
      this.item?.type === 'nw:Project' ||
      this.item?.type === 'http://nowords.com#Project'
    );
  }

  get isFolder() {
    return (
      this.item?.type === 'nw:Folder' ||
      this.item?.type === 'http://nowords.com#Folder'
    );
  }

  get isFile() {
    return (
      this.item?.type === 'nw:File' ||
      this.item?.type === 'http://nowords.com#File'
    );
  }

  get isImage() {
    return (
      this.item?.type === 'nw:Image' ||
      this.item?.type === 'http://nowords.com#Image'
    );
  }

  get showMenu() {
    return this.isActive && this.khs.shift;
  }

  moveItem() {
    this.cs.cutFile(this.localItem);
  }

  async click(resetQueryParams = true, scene?: string) {
    if (this.isImage) {
      // await this.openImage(this.item as ResourceData<ImageDescriptor>);
      // const shapeData = await this.cs.fetchShapesOfImage(this.item.IRI);
      // const resourceData: ResourceData = {
      //   IRI: 'root-shape',
      //   literals: this.item.literals,
      //   relationships: {
      //     shape: Object.values(shapeData),
      //   },
      // };

      // this.cs.previewShape = new RootShape(this.cs, resourceData);

      // this.dialog.open(FilePreviewComponent, {
      //   data: { s3Id: JSON.parse(imageData.literals.descriptor).s3Id },
      // });
      return;
    }

    if (this.isFile) {
      console.log('click', this.item.IRI);
      this.cs.loadedItem =
        this.item.IRI.split('http://nowords.com#')?.[1] ||
        this.item.IRI.split(':')?.[1];
      if (resetQueryParams) {
        this.cs.urlQueryParams = {};
      }

      const { organisationID, projectID } = this.route.snapshot.params;
      this.router.navigate(
        [
          'organisations',
          organisationID,
          'projects',
          projectID,
          'file',
          this.cs.loadedItem,
        ],
        scene
          ? {
              queryParams: {
                scene,
              },
            }
          : undefined,
      );

      // this.router.navigate(
      //   ['projects', this.cs.projectID, this.cs.loadedItem],
      //   { queryParams: this.cs.urlQueryParams }
      // );
      this.db.componentMessage('element-editor', {
        resource: this.item,
        reference: this.childrenRef,
      });
      return;
    }

    if (this.hidden) {
      this.hidden = false;
    } else if (this.opened) {
      this.hidden = true;
    } else {
      this.loading = true;
      this.loadData(({ error }: Response<ResourceData[]>) => {
        if (error) {
          console.error(error.message);
        }
        this.loading = false;
        this.opened = true;
        this.hidden = false;
      });
    }
  }

  goToFile(IRI: string) {
    this.cs.loadedItem =
      IRI.split('http://nowords.com#')?.[1] || IRI.split(':')?.[1];

    const { organisationID, projectID } = this.route.snapshot.params;
    this.router.navigate([
      'organisations',
      organisationID,
      'projects',
      projectID,
      'file',
      this.cs.loadedItem,
    ]);
  }

  addImage(event: Event) {
    event.stopPropagation();
    this.dialog
      .open(FileUploaderComponent, {
        data: { relationships: this.relationships },
      })
      .afterClosed()
      .subscribe(data => {
        if (!data) {
          // This case no new image has been uploaded
          return;
        }
        this.cs.setPicture(data.literals as ImageDescriptor);
        this.loadData(() => {});
      });
  }

  addFolder(event: Event) {
    event?.stopPropagation();
    this.hidden = false;
    this.editState = 'newFolder';
  }

  addFile(event: Event) {
    event?.stopPropagation();
    this.hidden = false;
    this.editState = 'newFile';
  }

  deleteItem(event: Event) {
    event?.stopPropagation();
    if (confirm('Are you sure you want to delete this item?')) {
      this.http.delete(
        'editor/file',
        { IRI: this.item.IRI },
        {
          path: this.parent ? `${this.parent.IRI}.children` : 'root-items',
          deleteKey: this.IRI,
        },
      );
    }
  }

  clearEditState() {
    this.editState = '';
    this.newItemName = '';
  }

  pasteFile() {
    // this.localItem.relationships.this.http.patch('editor/file', this.localItem); //
  }

  globalClick() {
    this.khs.comboState = {};
    switch (this.editState) {
      case 'name':
        const resource = this.cs.resourceStore[this.localItem.IRI];
        if (resource) {
          resource.label = this.localItem.literals.label;
        }
        this.http.patch(
          this.isProject ? 'editor/project' : 'editor/file',
          omit(this.localItem, 'relationships'),
        );
        this.clearEditState();
        break;
      case 'newFolder':
      case 'newFile':
        if (!this.newItemName) {
          // No name has been added
          this.clearEditState();
          return;
        }
        // Saving folder
        this.http
          .post<ResourceData>('editor/file', {
            type: this.editState === 'newFolder' ? 'nw:Folder' : 'nw:File',
            literals: { label: this.newItemName },
            relationships: this.relationships,
          })
          .pipe(first(p => !!p))
          .subscribe(({ error, data }) => {
            if (error) {
              return;
            }
            // refreshing the list
            this.loadData(({ error }) => {
              if (error) {
                // handle it later
              }
              this.clearEditState();
            });
          });
        break;
    }
  }

  loadData<T extends {}>(cb?: (response: Response<T>) => void) {
    return this.http
      .get<T>('editor/file', this.childrenRef, {
        resourceData: {
          sort: [
            {
              type: 'DESC',
              index: 1,
              varName: 'type',
            },
            {
              type: 'ASC',
              index: 2,
              varName: 'label',
            },
          ],
          relationships: this.relationships,
        },
      })
      .pipe(first(p => !!p))
      .subscribe(r => cb && cb(r));
  }

  paste($event) {
    $event?.stopPropagation();
    this.cs.pasteFile(this.localItem.IRI);
  }
}
