import {
  ExpressionType,
  MSIfElseExpr,
  MSRoutine,
  MSRoutineExpr,
  MSSwitchExpr,
  StepType,
} from './../../ms/ms.types';
import {
  Predicate,
  ResourceIRI,
  ResourceData,
  ResourceOperation,
  OperationType,
  ResourceDataObject,
  ResourceType,
  Relationships,
} from './resource.types';
import { LiteralPath, ConstraintDefinition } from './resource.old.types';
import { Expression } from '../../ms/ms.types';
import { Point } from '../base/point';
import { Subscription } from 'rxjs';
import { ArrayService } from '../../services/util/array';
import { Regex } from '../../services/util/regex';
import { MSEvaluationService } from '../../ms/evaluation/evaluation.service';

import * as uuidv1 from 'uuid/v1';
import { PointData } from '../../services/util/vector-service';

import { ClickEvent } from '../../services/canvas/canvas.types';
import { MSExpression } from '../../ms/parser/ms-expression.stat';
import { Animation } from '../../services/animation/animation.types';
import { GeneralShapeDescriptor } from './types/shape.type';

export type Literals = Record<string, any>;
import { clone as _clone, get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { ShapeService } from '../../element-editor/shape/shape.service';

export const INITIAL_KEY = '$initial';

export class Resource<
  T extends GeneralShapeDescriptor = {},
  C extends Record<string, any> = {},
> {
  /******************** ALIAS *********************/
  alias: Record<string, string[]> = {};
  aliasGroup: Record<string, string[]> = {};
  requestAliases: Record<string, string> = {};

  relationshipsToSave: string[] = [];

  matrix: any;

  /******************** SCHEME/STORE *********************/

  literals: Record<string, any>;
  relationships?: Relationships = {};
  backRelationshipMap: Record<string, string> = {};
  backReference?: Record<Predicate, Predicate> = {};
  oneToManyMap: Record<string, boolean> = {};
  resourceIRIPredicateMap: Record<ResourceIRI, Predicate> = {};
  relatedResourceDataMap: Record<string, ResourceData>;
  exprs: Record<string, Expression> = {};

  // index = 0; //

  scheme: Record<string, string> = {
    parent: 'relationship',
    maskParent: 'relationship',
    children: 'relationship',
  };
  defaultValues: Record<string, any> = {};

  /******************** CONSTRAINTS *********************/

  constraintDefinitions: ConstraintDefinition[] = [];
  constraintNotification: Record<string, string> = {};
  constraintActions: Record<string, LiteralPath[]> = {};
  externalConstraintActions: Record<string, Record<string, LiteralPath>> = {};

  /***************** LAST BUT NOT LEAST **************/

  subscription: Subscription;
  dOverride: boolean;
  type: ResourceType;

  animationIndexes: Record<string, number> = {};
  animationOffsets: Record<string, number> = {};

  compiledRoutines: Record<string, MSRoutineExpr> = {};

  /********************  SHAPE  ******************/

  cx: number;
  cy: number;
  currentMatrix: any;

  // offsetX = 0;
  // offsetY = 0;

  /********************  TRANSITION  ******************/

  descriptor: T;

  config: C;

  animations: Animation[] = [];
  animationSubscription: Subscription;

  subscriptions: Subscription[] = [];

  get cs() {
    return this.service.cs;
  }

  get IRI() {
    return this._IRI;
  }

  get label() {
    return this.resourceData.literals?.label;
  }

  set label(val: string) {
    this.resourceData.literals.label = val;
  }

  set IRI(value: string) {
    this._IRI = value;
  }

  get IRIPrefix() {
    return '';
  }

  _IRI: string;

  constructor(
    public service?: ShapeService,
    public resourceData: ResourceData<T> = {},
    config?: C,
  ) {
    // This will be ovewritten probably
    this.resourceData.IRI ||= uuidv1();
    this.IRI = resourceData.IRI;

    this.relationships = { ...resourceData.relationships };
    this.literals = { ...resourceData.literals };

    this.config = config;

    this.descriptor = { ...this.literals.descriptor };
    // This should no be here, rather in the GeneralShape
    Object.entries(this.descriptor?.routines || {}).map(([key, value]) => {
      this.compiledRoutines[key] = this.processRoutines(value);
    });
  }

  // get currentScaleX() {
  //   return this.currentMatrix?.a || 1;
  // }

  // get currentScaleY() {
  //   return this.currentMatrix?.d || 1;
  // }

  get currentTranslate() {
    return [this.currentX, this.currentY];
  }

  get currentX() {
    return this.currentMatrix ? this.currentMatrix.e || 0 : this.x;
  }

  get currentY() {
    return this.currentMatrix ? this.currentMatrix.f || 0 : this.y;
  }

  get x() {
    return 0;
  }

  get y() {
    return 0;
  }

  processRoutines(routine: MSRoutine): MSRoutineExpr {
    let ifState = false;
    return routine.reduce((acc, curr) => {
      if (typeof curr === 'string') {
        acc.push(MSExpression.getPojo(curr));
      } else {
        const [key] = Object.keys(curr);
        const [value] = Object.values(curr);
        if (/^if/.test(key) || /^else/.test(key)) {
          if (ifState && key === 'else') {
            (acc[acc.length - 1] as MSIfElseExpr).false =
              this.processRoutines(value);
            ifState = false;
          } else {
            acc.push({
              stepType: StepType.IfElse,
              condition: MSExpression.getPojo(key.slice(2)),
              true: this.processRoutines(value),
            });
            ifState = true;
          }
        } else if (/^switch/.test(key)) {
          const cases = (value as any[]).reduce((_acc, _curr) => {
            if (typeof _curr === 'string') {
              _curr = _curr.replace(/\s/g, '');
              _curr = _curr.slice(4);
              const [v, r] = _curr.split(':');
              _acc.push({
                value: MSExpression.getPojo(v),
                routine: MSExpression.getPojo(r),
              });
            } else {
              // --> TODO
            }
            return _acc;
          }, []);
          acc.push({
            stepType: StepType.Switch,
            expr: MSExpression.getPojo(key.slice(6)),
            cases,
          } as MSSwitchExpr);
        }
      }
      return acc;
    }, [] as MSRoutineExpr);
  }

  transformAnimation(a: Animation | string): Animation {
    const animation: Animation = {};
    if (typeof a === 'string') {
      const elements = a.split(':');
      let start: string, target: string, action: string;

      switch (elements.length) {
        case 2:
          [start, target] = elements;
          break;
        case 3:
          [start, target, action] = elements;
          break;
        default:
          throw new Error(`Invalid animation text: '${a}'`);
      }
      animation.start = +start;
      animation.target = target;
      if (action?.includes('(') && action?.includes(')')) {
        const [x, y, scale] = Regex.between('(', ')', action)?.split(',') || [];
        animation.xe = MSExpression.getPojo(x);
        animation.ye = MSExpression.getPojo(y);
        animation.scale = +scale;
        animation.action = 'transform';
      } else {
        animation.action = action;
      }
    }

    Object.entries(animation).map(([key, value]) => {
      if (value === undefined || (typeof value === 'number' && isNaN(value))) {
        delete animation[key];
      } else {
        switch (key) {
          case 'start':
          case 'end':
          case 'duration':
            animation[key] = this.toMs(value);
        }
      }
    });
    return animation;
  }

  toMs(sec: number) {
    return sec ? sec * 1000 : sec;
  }

  select() {}

  deselect() {}

  get parent(): Resource {
    return this.ref('parent');
  }

  get maskParent(): Resource {
    return this.ref('maskParent');
  }

  get _inputs(): Record<string, string> {
    return {};
  }

  get childTypes() {
    return [
      ResourceType.GeneralPath,
      ResourceType.TextElement,
      ResourceType.ContainerShape,
      ResourceType.PathShape,
      ResourceType.ImageElement,
      ResourceType.ImportedImageElement,
      ResourceType.RectangleShape,
      ResourceType.GeneralShape,
      ResourceType.PathSection,
    ];
  }

  get children() {
    return this.relationshipsByType(this.childTypes, true);
  }

  // get _children() {
  //   return (
  //     (this.relationships['children'] as string[])?.map(iri =>
  //       this.cs._getShape(iri),
  //     ) || []
  //   );
  // }

  private resourceIRIs(key: string): string[] {
    return Array.isArray(this.relationships[key])
      ? (this.relationships[key] as string[])
      : this.relationships[key]
        ? [this.relationships[key] as string]
        : [];
  }

  save() {
    // do nothing
  }

  relationshipsByType<T extends Resource>(
    type: ResourceType | ResourceType[],
    noParent = false,
  ): Array<{ key: string; resource: T }> {
    const types = Array.isArray(type) ? type : [type];
    return Object.keys(this.relationships)
      .filter(key => key !== 'children')
      .map(key => ({ key, resource: this.ref(key) }))
      .filter(({ resource }) => types.includes(resource?.type))
      .filter(({ key }) => !noParent || key !== 'parent')
      .sort((v1, v2) => v2.resource?.index - v1.resource?.index);
  }

  relationshipsByTypeObject<T extends Resource>(
    type: ResourceType,
  ): Record<string, T> {
    const object = {};
    this.relationshipsByType(type).map(
      ({ key, resource }) => (object[key] = resource),
    );
    return object;
  }

  // patch(_data?: ResourceData): PatchResponse {
  //   return {};
  // }

  initLiterals(literals: Record<string, any> = {}) {
    Object.entries(literals).map(([key, value]) => {
      if (this.scheme[key]) {
        // TODO check this
      }
      this.initLiteral(key, value);
    });
  }

  initLiteral(key: string, value: any) {
    this.scheme[key] = typeof value;
    this.literals[key] = value;
  }

  setExpressions(exprs: Record<string, string> = {}) {
    this.resetExpressions(exprs, 'expression');
  }

  setConstructorExpressions(exprs: Record<string, string> = {}) {
    this.resetExpressions(exprs, 'constructorExpression');
  }

  setConstructorExpressions_(constructor: Record<string, any> = {}) {
    Object.entries(constructor).map(([key, value]) => {
      if (typeof value === 'string') {
        if (this._inputs[key] !== 'string' || value.startsWith('$')) {
          this.initConstructorExpression(key, value);
        } else {
          this.initLiteral(key, value);
        }
      } else {
        this.initLiteral(key, value);
      }
    });
  }

  resetExpressions(exprs: Record<string, string>, type: string) {
    Object.entries({ ...exprs })
      .filter(([, value]) => value === type)
      .map(([key]) => {
        delete this.scheme[key];
        delete this.exprs[key];
      });

    Object.entries({ ...exprs }).map(([key, value]) => {
      this.scheme[key] = type;
      this.exprs[key] = MSExpression.getPojo(value);
    });
  }

  initConstructorExpression(key: string, value?: string, noOverride = false) {
    if (noOverride && this.scheme[key]) {
      return;
    }

    if (!value) {
      return;
    }

    this.scheme[key] = 'constructorExpression';
    this.exprs[key] = MSExpression.getPojo(value);
  }

  /*************************** MS *****************************/

  getPojo(expr: string | object) {
    if (typeof expr === 'string') {
      return MSExpression.getPojo(expr);
    } else {
      return {
        type: ExpressionType.ConstantObject,
        value: expr,
      };
    }
  }

  getPathNodeExprPojo(expr: string) {
    return MSExpression.pathNodePojo(expr);
  }

  getResources(key: string) {
    return this.resourceIRIs(key).map(IRI => this.getResource(IRI));
  }

  public addRelationshipTest(key: string, resource: Resource) {
    resource.IRI = resource.IRI || uuidv1();
    this.service.saveResource(resource);
    this.relationships[key] = resource.IRI;
  }

  public addRelationshipByKey(key: string, resource: Resource) {
    this.addRelationship({ key }, resource);
  }

  public addRelationship(target: ResourceOperation, resource: Resource) {
    // assert(resource, 'Base resource must be defined');
    // assert(target.key, 'Target must containt a key');

    this.scheme[target.key] = 'relationship';

    if (target.type === OperationType.Push) {
      this.scheme[target.key] = 'relationships';
      this.relationships[target.key] ||= [];
      (this.relationships[target.key] as string[]).push(resource.IRI);
    } else {
      this.relationships[target.key] = resource.IRI;
    }
    if (target.child) {
      this.relationships.children ||= [];
      if (!(this.relationships.children as string[]).includes(resource.IRI)) {
        (this.relationships.children as string[]).push(resource.IRI);
      }
      resource.relationships.parent = this.IRI;
      resource.scheme.parent = 'relationship';
    }
  }

  public initInput() {}

  public getResourceDataObject(
    resource: Resource,
    included: Record<string, ResourceData> = {},
    first = true,
    mainResource = resource,
  ): ResourceDataObject {
    const resourceData = {
      IRI: resource.IRI,
      literals: resource.getLiterals(),
      type: resource.getType(),
      relationships: {},
    } as ResourceData;
    resource.relationshipsToSave.forEach(key => {
      resourceData.relationships[key] = resource.relationships[key];
      if (Array.isArray(resource.relationships[key])) {
        (resource.relationships[key] as string[]).forEach(iri => {
          if (!included[iri] && mainResource.IRI !== iri) {
            included[iri] = this.getResourceDataObject(
              resource.service.getResource(iri),
              included,
              false,
              mainResource,
            ).resourceData;
          }
        });
      } else {
        if (resource.relationships[key]) {
          const iri = resource.relationships[key] as string;
          if (!included[iri] && mainResource.IRI !== iri) {
            included[iri] = this.getResourceDataObject(
              resource.service.getResource(iri),
              included,
              false,
              mainResource,
            ).resourceData;
          }
        }
      }
    });
    return { resourceData, included: first ? included : undefined };
  }

  initBaseAnimation(offset = 0) {
    if (this.animations.length) {
      this.animationIndexes[INITIAL_KEY] = 0;
      this.animationOffsets[INITIAL_KEY] = offset;
    }
    // this.subscribeToAnimationTicks();
  }

  // subscribeToAnimationTicks() {
  //   this.animationSubscription = this.cs.animationTick.subscribe(
  //     (time: number) => this.animationIntervalHandler(time)
  //   );
  //   this.cs.animationSuscribers[this.IRI] = true;
  // }

  unsubscribeFromAnimationTicks() {
    this.cs.unsubscribeFromAnimation(this.IRI);
    this.animationSubscription.unsubscribe();
  }

  getAnimationByKeys(key: string) {
    // if (key === INITIAL_KEY) {
    //   return this.animations;
    // } else {
    //   return this.animationsById[key];
    // }
  }

  callRoutine(key: string, params?: Record<string, any>) {
    MSEvaluationService.executeRoutine(
      this,
      this.compiledRoutines[key],
      params,
    );
    // those are like expressions
    // if (this.compiledRoutines[key]) {
    //   this.compiledRoutines[key].map(expr => MSEvaluationService.resolve(this, expr));
    // } else {
    //   throw new Error(`There is no routine with the key: '${key}'!`);
    // }
  }

  // NOTE - this part may will be important

  getDuration({ duration, start, end }: Animation) {
    if (duration) {
      return duration * 1000;
    }
    return end - start;
  }

  translateMatrix(matrix: any, x: number, y: number) {
    matrix.e += matrix.a * x;
    matrix.f += matrix.d * y;
    return matrix;
  }

  setMatrixTranslation(matrix: any, x: number, y: number) {
    matrix.e = x;
    matrix.f = y;
    return matrix;
  }

  // todo - move
  get currentScale() {
    return 1;
  }

  getSvgElement(): Element {
    return;
  }

  destroy() {
    this.subscription?.unsubscribe();
  }

  mouseover = () => {};

  mouseout = () => {};

  startMove(event?: ClickEvent) {}

  public getResource(IRI: string): Resource {
    return this.service.getResource(IRI);
  }

  public getPoint(key: string): Point | PointData {
    try {
      return (this.ref(key) as Point).current;
    } catch (error) {
      return { x: 0, y: 0 };
    }
  }

  // public eventHandler(event: any, from?: Resource, to?: string) {
  // }

  public show() {}
  public hide() {}
  public hover() {}
  public unhover() {}
  public refresh(fromStoreUpdate = false) {}

  // public request(
  //   to: string,
  //   type?: string,
  //   message?: any,
  //   from?: Resource
  // ): any {
  //   if (to === 'this') {
  //     return this.eventHandler({ type, message });
  //   }

  //   type = this.requestAliases[type] || type;
  //   if (this.aliasGroup[to]) {
  //     return this.aliasGroup[to].forEach((aliasedTo: string) =>
  //       this.request(aliasedTo, type, message, from)
  //     );
  //   } else {
  //     // Hey
  //     if (this.alias[to]) {
  //       const copy = _cloneDeep(this.alias[to]);
  //       const resource = this.request(copy.shift());
  //       resource.send('doesNotMatter', type, message, from, copy);
  //     } else {
  //       const rel = this.relationships[to];
  //       if (Array.isArray(rel)) {
  //         rel.forEach(IRI => this.send(IRI, type, message, from, to));
  //       } else if (rel) {
  //         return this.send(rel as string, type, message, from, to);
  //       }
  //     }
  //   }
  // }

  public getRefData(): ResourceData {
    return {
      IRI: this.IRI,
    };
  }

  getType(): string {
    return 'resource';
  }

  public getData(): ResourceData {
    return {
      IRI: this.IRI,
      literals: this.getLiterals(),
      relationships: this.relationships,
    };
  }

  public emitToParent(type: string, message?: any): any {
    return (
      this.relationships.parent &&
      this.ref('parent').eventHandler({ type, message }, this, 'parent')
    );
  }

  public takeRef(resource: Resource, keys?: string[]) {
    const foundKeys = [];
    for (const key of Object.keys(resource.relationships || {})) {
      if (key === 'parent') {
        continue;
      }
      if (keys && !keys.includes(key)) {
        continue;
      }
      foundKeys.push(key);
      this.relationships[key] = resource.relationships[key] as string;
    }
    foundKeys.forEach(key => delete resource.relationships[key]);
  }

  draw() {
    // overriden
  }

  getD(): string {
    return '';
  }

  getDParam(first?: boolean, x?: boolean): string {
    return '';
  }

  remove() {}

  removeRelationship(resource: Resource, key: string) {
    if (this.relationships[key]) {
      if (Array.isArray(this.relationships[key])) {
        ArrayService.remove(this.relationships[key] as string[], resource.IRI);
      } else {
        delete this.relationships[key];
      }
    } else {
      console.warn('Resource to delete does not exists');
    }
  }

  public setLiterals(literals: Record<string, any>) {
    this.literals = literals;
  }

  public setLiteral(key: string, value: any, fromConstraint = false) {
    this.setLiteralValue(key, value);
    if (fromConstraint) {
      return;
    }
    const resourceToNotify = this.constraintNotification[key];
    if (resourceToNotify) {
      this.ref(resourceToNotify).notify(this.IRI, key, value);
    }
    (this.constraintActions[key] || []).map(address => {
      try {
        this.getReferences(address.path).map(res =>
          res.setLiteral(address.literal, value),
        );
      } catch (error) {
        console.warn('Could not set literal', address.path, value);
      }
    });
  }

  getReferences(key: string) {
    return this.oneToMany(key) ? this.refN(key) : [this.ref(key)];
  }

  set_(key: string, value: any) {
    this.setLiteralValue(key, value);
  }

  clear_(key: string) {
    this.setLiteralValue(key, undefined);
  }

  flip(key: string) {
    this.ref(key) ? this.clear_(key) : this.set_(key, true);
  }

  setLiteralValue(key: string, value: any) {
    this.scheme[key] = typeof value;
    this.literals[key] = value;
  }

  private oneToMany(key: string): boolean {
    return this.oneToManyMap[key];
  }

  removeRef(key: string) {
    if (this.exprs[key]) {
      delete this.exprs[key];
    }

    if (this.literals[key]) {
      delete this.literals[key];
    }

    if (key.includes('.')) {
      const keys = key.split('.');
      const nextKey = keys.shift();
      const res = this.ref(nextKey);
      if (res) {
        res.removeRef(keys.join('.'));
        this.removeResource(res, nextKey);
      } else {
        console.warn(`[DELETE] Key cannot be found: ${nextKey} in ${key}!`);
      }
    } else {
      const res = this.ref(key);
      if (res) {
        this.removeResource(res, key);
      }
    }
  }

  removeResource(resource: Resource, key: string) {
    resource.remove();
    delete this.relationships[key];
  }

  addChild(resource: Resource) {
    this.addRelationship(
      { key: 'children', type: OperationType.Push },
      resource,
    );
  }

  public ref(ref: string) {
    const { key, path } = this.getKeyAndPath(ref);

    if (ref == 'this') {
      return this;
    }

    if (ref == 'angle') {
      return this.descriptor.position.rotation;
    }

    let res: any;
    switch (this.scheme[key]) {
      case 'expression':
        res = MSEvaluationService.resolve(this, this.exprs[key]);
        break;
      case 'constructorExpression':
        res = MSEvaluationService.resolve(this.parent, this.exprs[key]);
        break;
      case 'relationship':
        res = this.service?.getResource(this.relationships[key] as string);
        break;
      case 'relationships':
        if (!this.relationships[key]) {
          return [];
        }
        // assert(
        //   Array.isArray(this.relationships[key]),
        //   `Relationships under key '${key}' are not in array!`
        // );
        return this.asArray(this.relationships[key])
          .map((k: string) => this.service.getResource(k))
          .filter(r => !!r);
      case 'number':
      case 'string':
      case 'boolean':
      case 'object':
        return this.literals[key] !== undefined
          ? this.literals[key]
          : this.defaultValues[key];
    }
    if (!path) {
      return res;
    }

    if (res instanceof Resource) {
      return res.ref(path);
    }

    return _get(res, path);
  }

  getKeyAndPath(key: string) {
    const [k, ...path] = key.split('.');
    return { key: k, path: path.join('.') };
  }

  public refN(key: string): Resource[] {
    try {
      return ((this.relationships[key] as string[]) || []).map(IRI =>
        this.service.getResource(IRI),
      );
    } catch (error) {
      console.log(error);
    }
  }

  public refData(key: string): ResourceData {
    return this.ref(key).getData();
  }

  public refDataN(key: string): ResourceData[] {
    return this.refN(key).map(res => res.getData());
  }

  public getDataN(key: string): ResourceData[] {
    return this.refN(key).map(shape => shape.getData());
  }

  public refRefData(key: string): ResourceData {
    return this.ref(key).getRefData();
  }

  public refRefDataN(key: string): ResourceData[] {
    return this.ref(key).getRefDataN(key);
  }

  public getRefDataN(key: string): ResourceData[] {
    return this.refN(key).map(shape => shape.getRefData());
  }

  public getLiterals() {
    return this.literals;
  }

  public getRelationships() {
    return undefined;
  }

  public sameAs(shape: Resource): boolean {
    return this.IRI === shape.IRI;
  }

  asArray<T = any>(value: any | any[]): T[] {
    if (!value) {
      return [];
    }
    return Array.isArray(value) ? value : [value];
  }

  currentPatch: string;
  // patches: Record<number, T> = {};

  patchByKey(_key: string) {}

  patchOverrideByKey(_key: string) {}
}
