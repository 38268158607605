<div
  (mouseenter)="mouseEnter()"
  (mouseleave)="isActive = false; iconState = false"
  (click)="click()"
  [ngClass]="{ selected: isActive || this.cs.currentFileID == localId }"
  class="host__container flex-row gap-2 space-between"
>
  <!-- [ngClass]="{ selected: (selected$ | async) === localItem.IRI }" -->
  <div class="flex-row gap-2" style="padding-left: 3px">
    <!-- [style.padding-left.px]="index * 22 + 4" -->
    <div [style.width.px]="18">
      <!-- class="display-none"
      [class.display-block]="isContainer && (!opened || hidden)" -->
      <nw-icon
        *ngIf="isContainer && (!opened || hidden)"
        type="folder"
        [size]="13"
      ></nw-icon>
      <!-- class="display-none"
      [class.display-block]="isContainer && opened && !hidden" -->
      <nw-icon
        *ngIf="isContainer && opened && !hidden"
        type="folder_open"
        [size]="13"
      ></nw-icon>
      <!-- class="display-none"
      [class.display-block]="isFile" -->
      <nw-icon
        *ngIf="isFile"
        type="description"
        [outlined]="true"
        [size]="13"
      ></nw-icon>
      <!-- class="display-none"
      [class.display-block]="isImage" -->
      <nw-icon *ngIf="isImage" type="insert_photo" [size]="13"></nw-icon>
    </div>

    <!-- <div *ngIf="editState === 'name'"> -->
    <div *ngIf="editState === 'name'" class="flex-row gap-2">
      <input
        type="text"
        [style.width.px]="126"
        [(ngModel)]="localItem.literals.label"
        (click)="$event.stopPropagation()"
        (keydown)="keydown($event)"
      />
      <nw-icon type="check" (click)="saveNewName($event)" [size]="13"></nw-icon>
      <nw-icon type="close" (click)="discardEdit($event)" [size]="13"></nw-icon>
    </div>
    <div
      *ngIf="editState !== 'name'"
      class="label"
      [style.opacity]="opened && (children$ | async)?.length === 0 ? 0.5 : 1"
      [class.main-item]="localItem.literals.label == 'main'"
    >
      {{ localItem.literals.label }}
    </div>

    <!-- <nw-floating [top]="-12" [left]="2" *ngIf ngIf="showMenu">
      <div
        class="browser-actions__container"
        fxLayout="row"
        fxLayougAlign="center"
        fxLayoutGap="4px"
      >
        <ng-container *ngIf="isContainer">
          <nw-icon
            type="folder_open"
            [size]="18"
            matTooltip="Add folder"
            (click)="addFolder($event)"
          ></nw-icon>
          <nw-icon
            type="description"
            [size]="18"
            matTooltip="Add file"
            (click)="addFile($event)"
          ></nw-icon>
          <nw-icon
            type="insert_photo"
            (click)="addImage($event)"
            [size]="18"
            matTooltip="Add image"
          ></nw-icon>
        </ng-container>
        <nw-icon
          type="cached"
          [size]="18"
          matTooltip="Rename"
          (click)="editState = 'name'; isActive = false; $event.stopPropagation()"
        ></nw-icon>
        <nw-icon
          type="delete"
          [size]="18"
          matTooltip="Delete"
          (click)="deleteItem($event)"
        ></nw-icon>
      </div>
    </nw-floating> -->
    <nw-floating [top]="-6" [left]="-2">
      <mat-spinner
        *ngIf="loading"
        [diameter]="15"
        [style.margin-left.px]="10"
      ></mat-spinner>
    </nw-floating>
  </div>
  <div class="icon-container" *ngIf="isActive">
    <nw-icon
      *ngIf="!iconState"
      type="more_horiz"
      [size]="15"
      (mouseenter)="iconState = true"
    ></nw-icon>
    <div
      *ngIf="iconState"
      fxLayout="row"
      fxLayoutAlign=" center"
      fxLayoutGap="4px"
    >
      <nw-icon
        type="login"
        [size]="14"
        *ngIf="cs.cutFileItem"
        matTooltip="Paste file"
        (click)="paste($event)"
      >
      </nw-icon>
      <ng-container *ngIf="isContainer">
        <nw-icon
          type="folder_open"
          [size]="16"
          matTooltip="Add folder"
          (click)="addFolder($event)"
        ></nw-icon>
        <nw-icon
          type="description"
          [size]="16"
          matTooltip="Add file"
          (click)="addFile($event)"
        ></nw-icon>
        <nw-icon
          type="insert_photo"
          (click)="addImage($event)"
          [size]="16"
          matTooltip="Add image"
        ></nw-icon>
        <div class="delimiter"></div>
      </ng-container>
      <!-- <nw-icon
        *ngIf="!isContainer"
        type="arrow_right_alt"
        [size]="14"
        (click)="moveItem()"
        matTooltip="Move"
      >
      </nw-icon> -->
      <nw-icon
        type="edit"
        [size]="13"
        [outlined]="true"
        (click)="editState = 'name'; isActive = false; $event.stopPropagation()"
      >
      </nw-icon>
      <nw-icon
        type="delete"
        [size]="13"
        [outlined]="true"
        (click)="deleteItem($event)"
      >
      </nw-icon>
    </div>
  </div>
</div>

<!-- <div class="child-container" [class.display-none]="hidden">
  <div
    *ngIf="editState === 'newFolder' || editState === 'newFile'"
    fxLayout="row"
    fxLayoutAlign=" center"
    fxLayoutGap="4px"
    [style.padding-left.px]="(index + 1) * 22 + 4"
  >
    <nw-icon
      [type]="editState === 'newFolder' ? 'folder' : 'description'"
      [size]="18"
    ></nw-icon>
    <input
      type="text"
      class="new-file"
      (click)="$event.stopPropagation()"
      (keydown)="keydown($event)"
      [(ngModel)]="newItemName"
    />
  </div>
  <iw-file-item
    *ngFor="let child of children$ | async | sortBy"
    [parent]="item"
    [item]="child"
    [index]="index + 1"
  >
  </iw-file-item>
</div> -->

<div class="scene-list" *ngIf="(scenes$ | async).length">
  <div
    *ngFor="let scene of scenes$ | async"
    class="flex-row gap-4"
    (click)="goToFile(scene.IRI)"
  >
    <nw-icon type="crop_7_5" [size]="13"></nw-icon>
    <div>
      {{ scene.literals.label }}
    </div>
  </div>
</div>
