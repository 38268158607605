<div
  class="spacing-controller-component"
  [style.left.px]="x"
  [style.top.px]="y"
>
  <div class="flex-row gap-4">
    <nw-icon
      [type]="isRow ? 'align_justify_space_even' : 'align_space_even'"
      [outlined]="true"
      (click)="clickHandler()"
    ></nw-icon>
    <input
      *ngIf="showGapInput"
      type="number"
      [(ngModel)]="gap"
      (change)="gapChange()"
    />
  </div>
</div>
