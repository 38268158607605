// tslint:disable: no-use-before-declare
import { Regex as regex } from '../../services/util/regex';
import {
  ExpressionType,
  Expression,
  MethodExpression,
  PathNodeExpression,
  ObjectExpression,
  FieldExpression,
} from '../ms.types';

export const Regex = {
  boolean: /true|false/,
  chain: /\${\d+}(\.\${\d+})+(?!(\.\${\d+})*\()/,
  comparison: /\${\d+}(==|!=|<|>|<=|>=)\${\d+}/,
  constantString: /["'][\w\.]*["']/,
  equation: /\${\d+}(=|\+=)\${\d+}$/,
  filter: /\${\d+}\[\${\d+}\]/,
  method: /\${\d+}\(((\${\d+})*(,\${\d+})*)\)/,
  methodName: /\w+(?=\()/,
  number: /^[0-9]+(\.[0-9]+)*$/,
  multiplyDivide: /\${\d+}([/*]\${\d+})+/,
  multiplyDivideValue: /[*/]/g,
  object:
    /{((...\${\d+})|((\${\d+}:){0,1}\${\d+}))(,((...\${\d+})|((\${\d+}:){0,1}\${\d+})))*}/,
  parentheses: /(?<!\})\(\${\d+}\)/,
  plusMinus: /\${\d+}([+-]\${\d+})+/,
  plusMinusValue: /[+-]/g,
  primitive: /(?<!\${\d*)((\d+(\.\d)*)|\w+)/,
  whiteSpace: /\s/g,
  // TODO - implement string
  // string: /["']\${\d*\}["']/,
  // stringContent: /(?<=['"])\${\d*\}(?=['"])/,
};

// (((?<=[\(*/])-)*(?!((?<=\${)\d)(?=}))\w+)

export class MSExpression {
  static getObjectPojo(object: Record<string, any>): ObjectExpression {
    const o = {};
    Object.entries(object).map(
      ([key, value]) => (o[key] = this.getPojo(value))
    );
    return {
      type: ExpressionType.Object,
      object: o,
    };
  }

  static pathNodePojo(expr: string): PathNodeExpression {
    return {
      type: ExpressionType.PathNode,
      value: expr[0],
      chain: expr
        .slice(1)
        .trim()
        .split(';')
        .map(item => this.getPojo(item.trim())),
    };
  }

  static getPojo<T = Expression>(expr: string): Expression {
    if (typeof expr === 'string' && expr?.startsWith(`_`)) {
      return {
        type: ExpressionType.ConstantString,
        value: expr.slice(1),
      };
    }
    expr = `${expr}`.replace(/\s/g, '');

    let o: Expression = { expr };
    let currentExpr: string;

    let cnt = 0;

    while (currentExpr != o.expr) {
      currentExpr = o.expr;
      o = this.resolvePrimitive(o);
      o = this.resolveParenthesis(o);
      o = this.resolveMultiplyDivide(o);
      o = this.resolvePlusMinus(o);
      o = this.resolveFilter(o);
      o = this.resolveChain(o);
      o = this.resolveMethod(o);
      o = this.resolveEquation(o);
      o = this.resolveComparison(o);
      o = this.resolveObject(o);

      if (cnt++ > 50) {
        throw new Error(`Something is wrong with the ms expression!`)
      }
    }
    // TODO - rename this function
    return this.sanitize(o) as T;
  }

  static resolve<T = Expression>(
    param: Expression,
    regexp: RegExp,
    fcn: (e: string, params?: Expression[]) => Expression
  ): T {
    param.params = param.params || [];

    let i = 0;
    while (1) {
      const res = regex._get(param.expr, regexp);

      if (!res) {
        break;
      }

      const [expr, index] = res;

      if (!expr || expr === 'undefined') {
        break;
      }

      let original = `${param.expr}`;

      const paramIndexes = regex
        .matchAll(original, /\${\d+}/)
        .map(p => original.indexOf(p));

      let newIndex: number;
      if (!isNaN(+expr)) {
        newIndex = new RegExp(`(?<!\\\${)${expr}`).exec(original).index;
      } else {
        newIndex = original.indexOf(expr);
      }

      const subParams = this.indexesOfExpr(expr);

      let nextParamIndex = paramIndexes.findIndex(
        paramIndex => paramIndex >= newIndex
      );
      if (nextParamIndex === -1) {
        nextParamIndex = paramIndexes.length;
      }

      const nextParam = fcn(
        this.normalize(expr),
        subParams.map(i => param.params[i])
      );

      param.params.splice(nextParamIndex, subParams.length, nextParam);

      if (subParams.length) {
        original =
          original.slice(0, index) +
          `$\{${nextParamIndex}\}` +
          original.slice(index + expr.length);

        this.indexesOfExpr(original)
          .filter(val => val >= nextParamIndex + subParams.length)
          .map(val => {
            original = original.replace(
              `$\{${val}\}`,
              `$\{${val - (subParams.length - 1)}\}`
            );
          });

        param.expr = original;
      } else {
        this.indexesOfExpr(original)
          .reverse()
          .filter(val => val >= nextParamIndex + subParams.length)
          .map(
            val =>
              (original = original.replace(`$\{${val}\}`, `$\{${val + 1}\}`))
          );

        param.expr =
          original.slice(0, index) +
          `$\{${nextParamIndex}\}` +
          original.slice(index + expr.length);
      }
    }
    return this.sanitize(param) as T;
  }

  static resolvePrimitive(param: Expression) {
    return this.resolve(param, Regex.primitive, (expr: string) => {
      let type: ExpressionType, minus: boolean, value: any;
      if (expr.startsWith('-')) {
        minus = true;
        expr = expr.slice(1);
      }
      switch (true) {
        case Regex.boolean.test(expr):
          type = ExpressionType.ConstantBoolean;
          value = expr === 'true';
          break;
        case Regex.number.test(expr):
          type = ExpressionType.ConstantNumber;
          value = +expr;
          break;
        default:
          type = ExpressionType.Field;
          value = expr;
      }
      return { type, minus, value };
    });
  }

  static resolveMultiplyDivide(param: Expression): Expression {
    return this.resolve(param, Regex.multiplyDivide, (expr, params) => ({
      type: ExpressionType.Arithmetic,
      operations: regex.matchAll(expr, Regex.multiplyDivideValue),
      params,
    }));
  }

  static resolvePlusMinus(param: Expression): Expression {
    return this.resolve(param, Regex.plusMinus, (expr, params) => ({
      type: ExpressionType.Arithmetic,
      operations: regex.matchAll(expr, Regex.plusMinusValue),
      params,
    }));
  }

  static resolveParenthesis(param: Expression) {
    return this.resolve(
      param,
      Regex.parentheses,
      (_expr: string, params: Expression[]) => params[0]
    );
  }

  static resolveChain(param: Expression) {
    return this.resolve(
      param,
      Regex.chain,
      (_expr: string, params: Expression[]) => ({
        type: ExpressionType.Chain,
        chain: params,
      })
    );
  }

  static resolveComparison(param: Expression) {
    return this.resolve(param, Regex.comparison, (expr, [left, right]) => ({
      type: ExpressionType.Comparison,
      operator: regex.get(expr, /(==|!=|<=|>=|<|>)/),
      left,
      right,
    }));
  }

  static resolveEquation(param: Expression) {
    return this.resolve(param, Regex.equation, (expr, [target, value]) => ({
      type: ExpressionType.Equation,
      target,
      value,
      push: /\+=/.test(expr) ? true : undefined,
    }));
  }

  static resolveObject(param: Expression) {
    return this.resolve<ObjectExpression>(
      param,
      Regex.object,
      (expr, params) => {
        if (expr.includes('...')) {
          const objects = [];
          expr.split(',').map(subExpr => {
            if (subExpr.includes('...')) {
              objects.push(params.shift());
            } else if (!subExpr.includes(':')) {
              const p = params.shift();
              objects.push({
                [p.value]: p,
              });
            } else {
              objects.push({
                [params.shift().value]: params.shift(),
              });
            }
          });
          return {
            type: ExpressionType.Object,
            spread: true,
            objects,
          };
        } else {
          let object = {};
          expr.split(',').map(subExpr => {
            const p = params.shift();
            if (!subExpr.includes(':')) {
              object[p.value] = p;
            } else {
              if (p.type === ExpressionType.Object) {
                object = {
                  ...object,
                  ...(p as ObjectExpression).object,
                };
              } else {
                object[p.value] = params.shift();
              }
            }
          });
          return {
            type: ExpressionType.Object,
            object,
          };
        }
      }
    );
  }

  static resolveMethod(param: Expression): Expression {
    return this.resolve(param, Regex.method, (_expr, params) => {
      const me: MethodExpression = {
        type: ExpressionType.Method,
        value: params[0].value,
        params: params.length > 1 ? params.slice(1) : undefined,
      };
      if (/^new/.test(me.value)) {
        me.new = true;
        me.value = me.value.slice(3);
      }
      return me;
    });
  }

  static resolveFilter(param: Expression) {
    return this.resolve<FieldExpression>(
      param,
      Regex.filter,
      (_expr, params) => ({
        ...params[0],
        filter: params[1],
      })
    );
  }

  static sanitize(param: Expression) {
    if (param.params?.length === 1 && !!param.expr) {
      param = param.params[0];
    }
    // param.params?.length === 1 && !!param.expr ? param.params[0] : param; //

    if (!param.params?.length) {
      delete param.params;
    }
    return param;
  }

  static normalize(expr: string) {
    if (!/\${\d+}/.test(expr)) {
      return expr;
    }
    const indexes = this.indexesOfExpr(`${expr}`);
    if (indexes[0] === 0) {
      return expr;
    }
    indexes.map(
      num => (expr = expr.replace(`\${${num}}`, `\${${num - indexes[0]}}`))
    );
    return expr;
  }

  static indexesOfExpr(expr: string) {
    return regex
      .matchAll(expr, /\${\d+}/)
      .map(param => +regex.get(param, /\d+/));
  }
}
