<div
  class="trajectory-animation-item"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngIf="animation"
>
  <div
    class="trajectory-animation"
    fxLayout="row"
    fxLayoutAlign=" center"
    fxLayoutGap="6px"
  >
    <ng-container *ngIf="animation?.line as line">
      <input
        type="number"
        [(ngModel)]="line.offset"
        (change)="trajectoryShape.patchLineAnimation(animation)"
        (keydown)="$event.stopPropagation()"
      />
      <nw-icon type="remove" [size]="12" [noPointer]="true"></nw-icon>
      <input
        type="number"
        [(ngModel)]="line.length"
        (change)="trajectoryShape.patchLineAnimation(animation)"
        (keydown)="$event.stopPropagation()"
      />
    </ng-container>
    <ng-container *ngIf="animation?.dashArray as dashArray">
      <input
        type="number"
        [(ngModel)]="dashArray.length"
        (change)="trajectoryShape.patchLineAnimation(animation)"
        (keydown)="$event.stopPropagation()"
      />
      <nw-icon type="remove" [size]="12" [noPointer]="true"></nw-icon>
      <input
        type="number"
        [(ngModel)]="dashArray.speed"
        (change)="trajectoryShape.patchLineAnimation(animation)"
        (keydown)="$event.stopPropagation()"
      />
    </ng-container>
  </div>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="3px">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="6px">
      <input
        type="checkbox"
        [(ngModel)]="!!animation.dashArray"
        (change)="trajectoryShape.changeDashArray()"
      />
    </div>
    <!-- <nw-icon
      type="remove"
      [size]="12"
      [hover]="true"
      matTooltip="Remove"
      (click)="trajectoryShape.removeLineAnimation()"
    ></nw-icon>
  </div> -->
  </div>
</div>
