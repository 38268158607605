import { AfterViewInit, Component } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';

@Component({
  selector: 'nw-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
})
export class PlayComponent implements AfterViewInit {
  constructor(private readonly cs: CanvasService) { }

  ngAfterViewInit(): void {
    this.cs.generalEventSubscribe('fileLoaded', loaded => {
      if (loaded) {
        console.log('fileLoaded');
        this.cs.waitForsoundFilesAndPlay();
      }
    });
  }
}
