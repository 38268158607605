<div
  class="w-1001"
  fxLayout="row"
  class="no-select"
  [fxLayoutAlign]="fxLayoutAlign"
  [ngStyle]="{
    'margin-bottom': marginBotton,
    opacity: disabled ? '30%' : _opacity,
    cursor: disabled || noPointer ? 'default' : 'pointer',
    rotate: rotate ? (rotate + 'deg') : 0
  }"
  [ngClass]="{ faded: faded }"
  (click)="clickHandler($event)"
  (mouseenter)="$event.stopPropagation()"
>
  <i
    class="material-icons"
    [class.material-icons-outlined]="outlined"
    [class.material-symbols-outlined]="outlined"
    [ngStyle]="{
      'font-size': fontSize,
      color: color,
      width: widthValue,
      height: heightValue
    }"
  >
    {{ type }}
  </i>
</div>
