import produce from 'immer';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';
import { ArrayService } from '../services/util/array';
import { OrganisationState } from '../organisation/organisation.reducer';
import { ProjectState } from '../projects/project.reducer';
import { OrganisationFeature } from '../organisation/organisation.actions';
import { ProjectFeature } from '../projects/project.actions';
import { UserFeature } from '../user/user.actions';
import { UserState } from '../user/user.reducer';

export const DataBagActionType = 'dataBagActionType';

export enum ArrayOperationType {
  Push = 'push',
  Remove = 'remove',
  Set = 'set',
}

export interface DatabagActionDescriptor {
  id?: string;
  path: string;
  value?: any;
  forceValue?: any;
  arrayOperation?: ArrayOperationType;
  asArray?: boolean;
  deleteKey?: string;
  error?: any;
  flip?: boolean;
}

export class DataBagAction implements Action {
  readonly type = DataBagActionType;
  constructor(public descriptor: DatabagActionDescriptor) {}
}

export type DataBag = {
  databag: Record<string, any>;
  [OrganisationFeature]: OrganisationState;
  [ProjectFeature]: ProjectState;
  [UserFeature]: UserState;
};

export const dataBagReducer = (
  databag: Record<string, any> = { data: {} },
  databagAction: DataBagAction
): Record<string, any> => {
  return produce(databag, draft => {
    if (databagAction.type !== DataBagActionType) {
      return;
    }

    const {
      error,
      id,
      path,
      value,
      arrayOperation,
      forceValue,
      asArray,
      deleteKey,
      flip,
    } = databagAction.descriptor;

    // this is required for the subscription in the http.service
    if (id) {
      _.set(draft.data, id, { error, path, data: value });
    }

    if (error) {
      _.set(draft.data, `${path}-error`, error);
      return;
    }

    if (flip) {
      const val = _.get(draft.data, path);
      _.set(draft.data, path, !val);
      return;
    }

    if (arrayOperation) {
      let array = _.get(draft.data, path);
      if (!array) {
        _.set(draft.data, path, []);
        array = _.get(draft.data, path);
      }
      switch (arrayOperation) {
        case ArrayOperationType.Push:
          array.push(forceValue || value);
          break;
        case ArrayOperationType.Remove:
          ArrayService.remove(array, forceValue || value);
          _.set(draft.data, path, array);
          break;
        case ArrayOperationType.Set:
          if (deleteKey) {
            _.set(
              draft.data,
              path,
              array.filter(dataItem => dataItem.IRI !== deleteKey)
            );
          } else {
            _.set(draft.data, path, array);
          }

          break;
      }
    } else {
      if (deleteKey) {
        const data = _.get(draft.data, path);

        const index = (data as any[])?.findIndex(
          dataItem => dataItem.IRI === deleteKey
        );
        _.set(draft.data, path, [
          ...data.slice(0, index),
          ...data.slice(index + 1),
        ]);
      } else if (asArray) {
        const object = {};
        (value as any[]).map(val => (object[val.IRI] = val));
        _.set(draft.data, path, object);
      } else {
        _.set(draft.data, path, value === false ? 0 : value);
      }
    }
  });
};

export const BaseReducer = {
  databag: dataBagReducer,
};
