<div class="hand-shape-section-component flex-column gap-2">
  <nw-number-select
    key="r"
    [values]="[section.r]"
    (valueChange)="setR($event)"
  ></nw-number-select>

  <nw-number-select
    key="curve"
    [values]="[section.curve]"
    [step]="0.1"
    (valueChange)="setCurve($event)"
  ></nw-number-select>

  <nw-boolean-input
    key="flat"
    [value]="[section.flat]"
    (changed)="setFlat($event)"
  ></nw-boolean-input>

  <div class="row-space-between">
    <div style="font-size: 12px">stroke</div>
    <nw-icon
      *ngIf="!section.stroke"
      (click)="addStroke()"
      type="add"
      [size]="12"
    >
    </nw-icon>
    <nw-icon
      *ngIf="section.stroke"
      (click)="removeStroke()"
      type="remove"
      [size]="12"
    >
    </nw-icon>
  </div>
  <div
    *ngIf="section.stroke as stroke"
    class="flex-column flex-align-end gap-2"
  >
    <nw-color-select
      [colors]="[stroke.color]"
      (changed)="strokeColorChange($event)"
    ></nw-color-select>

    <nw-number-select
      [values]="[stroke.width]"
      (valueChange)="strokeWidthChange($event)"
    ></nw-number-select>

    <div class="flex-column gap-6">
      <div class="row-space-between" *ngIf="stroke.top as top">
        <input
          type="checkbox"
          [checked]="!!stroke.top"
          (change)="changeStrokeSection('top', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">top</div>
          <div class="flex-row">
            <input
              [value]="stroke.top?.start"
              (change)="changeSectionInterval('top', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.top?.end"
              (change)="changeSectionInterval('top', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row-space-between" *ngIf="!amIFirst"> -->
      <div class="row-space-between">
        <input
          type="checkbox"
          [checked]="!!stroke.side"
          (change)="changeStrokeSection('side', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">side</div>
          <div class="flex-row">
            <input
              [value]="stroke.side?.start"
              (change)="changeSectionInterval('side', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.side?.end"
              (change)="changeSectionInterval('side', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row-space-between" *ngIf="!closed && index == 1"> -->
      <div class="row-space-between">
        <input
          type="checkbox"
          [checked]="!!stroke.bottom"
          (change)="changeStrokeSection('bottom', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">bottom</div>
          <div class="flex-row">
            <input
              [value]="stroke.bottom?.start"
              (change)="changeSectionInterval('bottom', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.bottom?.end"
              (change)="changeSectionInterval('bottom', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
