import { createImmerReducer } from 'ngrx-immer/store';
import {
  AnimationItem,
  AnimationKeys,
  ShapeIRI,
} from '../../../elements/resource/types/shape.type';
import {
  resetAnimationAction,
  setAnimationChanged,
  setAnimationsOfFileAction,
  setCurrentAnimationIdBase,
  setMainAnimationFrameBase,
  addAnimationItemBaseAction,
  setAnimationsByFrame,
  setAnimationsByShape,
  removeAnimationItemBaseAction,
  addAnimationFunction,
  setAnimationFunctions,
  addAnimationsOfShapesToStoreBase,
  setAnimationControlPanelState,
  setAnimationEaseBase,
  setAnimationFrameForScene,
  setAnimationFramesBySceneBase,
  removeAnimations,
} from './animation.actions';
import { on } from '@ngrx/store';
import { set as _set } from 'lodash';
import { AnimationFrame } from '../components/animation-frame/animation.types';
import { AnimationByStart, FrameCollection } from '../animation.service';
import { DataBag } from '../../../store/selectors';
import { AnimationId } from '../frame/animation-frame-object';
export const AnimationFeatureKey = 'animations';

export const getRootAnimationId = (id: string) => {
  if (id?.includes('_')) {
    const ids = id.split('_');
    id = ids[ids.length - 1];
  }
  return id;
};

export type Animations = Partial<Record<AnimationKeys, AnimationItem>>;

export interface AnimationByTime {
  id: string;
  start: number;
  end: number;
  overlapsWith?: string[];
}

export type FunctionName = string;

export type AnimationsByShape = Record<
  ShapeIRI,
  Record<AnimationId, Animations>
>;
export type AnimationsByFrame = Partial<
  Record<AnimationId, Record<ShapeIRI, Animations>>
>;
export type AnimationFunctions = Partial<
  Record<AnimationKeys, Record<FunctionName, AnimationItem>>
>;

export interface AnimationControlPanelState {
  mode: 'idle' | 'text-frame-form';
  isLoading?: boolean;
  speechFile?: {
    // url: string;
    // lengthInSec?: number;
    // sizeInKb: number;
    filename: string;
    size: number;
  };
}

export interface AnimationState {
  animationChanged?: boolean;
  animationsByShape?: AnimationsByShape;
  animationsByFrame?: AnimationsByFrame;
  currentAnimationId?: string;
  animationsByTime?: AnimationByTime[];
  mainFrame?: AnimationFrame;
  framesByScene?: Record<string, AnimationFrame>;
  animationByStart?: AnimationByStart[];
  functions?: AnimationFunctions;
  controlPanelState?: AnimationControlPanelState;
}

export const initialAnimationState: AnimationState = {
  animationsByShape: {},
  animationsByFrame: {},
  functions: {},
  animationChanged: false,
  controlPanelState: {
    mode: 'idle',
  },
};

export const selectAnimationFeature = (state: DataBag) =>
  state[AnimationFeatureKey] as AnimationState;

export const animationReducer = createImmerReducer(
  initialAnimationState,
  on(setAnimationsOfFileAction, (state, { animations }) => {
    Object.entries(animations || {}).map(([IRI, animationsById]) => {
      state.animationsByShape[IRI] ||= {};
      Object.entries(animationsById || {}).map(([animationId, value]) => {
        state.animationsByShape[IRI][animationId] = value.reduce(
          (object, animation) => {
            object[animation.key] = animation;
            return object;
          },
          {} as Animations,
        );
      });
    });
    return state;
  }),
  on(setCurrentAnimationIdBase, (state, { id }) => {
    state.currentAnimationId = id;
    return state;
  }),
  on(resetAnimationAction, (state, { IRIs, key }) => {
    IRIs.map(IRI => {
      _set(
        state.animationsByShape,
        `${IRI}.${state.currentAnimationId}.${key}`,
        null,
      );
    });
    return state;
  }),
  on(setMainAnimationFrameBase, (state, { frame }) => {
    state.mainFrame = frame;
    return state;
  }),
  on(setAnimationFramesBySceneBase, (state, { frames }) => {
    state.framesByScene = frames;
    return state;
  }),
  on(setAnimationFrameForScene, (state, { scene, frame }) => {
    state.framesByScene ||= {};
    state.framesByScene[scene] = frame;
    return state;
  }),
  on(setAnimationControlPanelState, (state, { state: panelState }) => {
    state.controlPanelState = panelState;
    return state;
  }),
  on(setAnimationChanged, (state, { value }) => {
    state.animationChanged = value;
    return state;
  }),
  on(setAnimationEaseBase, (state, { shapeIRI, key, ease, animationId }) => {
    animationId = getRootAnimationId(animationId);
    state.animationsByFrame[animationId][shapeIRI][key].meta = { ease };
    state.animationsByShape[shapeIRI][animationId][key].meta = { ease };
    return state;
  }),
  on(addAnimationFunction, (state, { name, item }) => {
    _set(state.functions, [item.key, name], item);
    return state;
  }),
  on(setAnimationFunctions, (state, { functions }) => {
    state.functions = functions;
    return state;
  }),
  on(addAnimationItemBaseAction, (state, { shapeIRI, animationId, item }) => {
    animationId = getRootAnimationId(animationId);
    _set(state.animationsByFrame, [animationId, shapeIRI, item.key], item);
    _set(state.animationsByShape, [shapeIRI, animationId, item.key], item);
    return state;
  }),
  on(addAnimationsOfShapesToStoreBase, (state, { animations }) => {
    for (const animation of animations) {
      const { IRI, animationsById } = animation;
      Object.entries(animationsById).map(([animationId, animationArray]) => {
        animationArray.map(item => {
          _set(state.animationsByFrame, [animationId, IRI, item.key], item);
          _set(state.animationsByShape, [IRI, animationId, item.key], item);
        });
      });
    }
    return state;
  }),
  on(removeAnimations, (state, { key, IRIs, animationId }) => {
    for (const IRI of IRIs) {
      delete state.animationsByFrame[animationId]?.[IRI]?.[key];
      delete state.animationsByShape[IRI]?.[animationId]?.[key];

      // if (
      //   Object.entries(state.animationsByFrame[animationId]?.[IRI] || {})
      //     .length == 0
      // ) {
      //   delete state.animationsByFrame[animationId]?.[IRI];
      // }
      // if (
      //   Object.entries(state.animationsByFrame[IRI]?.[animationId] || {})
      //     .length == 0
      // ) {
      //   delete state.animationsByShape[IRI]?.[animationId];
      // }
    }
    return state;
  }),
  on(removeAnimationItemBaseAction, (state, { shapeIRI, animationId, key }) => {
    animationId = getRootAnimationId(animationId);
    delete state.animationsByFrame[animationId][shapeIRI][key];
    delete state.animationsByShape[shapeIRI][animationId][key];
    return state;
  }),
  on(setAnimationsByFrame, (state, { animationsByFrame }) => {
    state.animationsByFrame = animationsByFrame;
    return state;
  }),
  on(setAnimationsByShape, (state, { animationsByShape }) => {
    state.animationsByShape = animationsByShape;
    return state;
  }),
);
