import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
// import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MaterialModule } from '../../material.module';
import { CanvasService } from '../../services/canvas/canvas.service';
import { CenterComponent } from './center/center.component';
import { CodeComponent } from './code/code.component';
import { ContainerComponent } from './container/container.component';
import { ControlElementComponent } from './control-element/control-element.component';
import { EditableTextComponent } from './editable-text/editable-text.component';
import { FloatingComponent } from './floating/floating.component';
import { GeneralSelectorComponent } from './general-selector/general-selector.component';
import { MatIconComponent } from './icon/icon.component';
import { KeyValueMenuComponent } from './key-value-menu/key-value-menu.component';
import { LinkComponent } from './link/link.component';
import { ListComponent } from './list/list.component';
import { LoaderComponent } from './loader/loader.component';
import { TextInputComponent } from './text-input/text-input.component';
import { YamlComponent } from './yaml/yaml.component';
import { ColorSelectComponent } from './color-select/color-select.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropdownComponentComponent } from './dropdown-component/dropdown-component.component';
import { NumberSelectComponent } from './number-select/number-select.component';
import { OptionSelectComponent } from './option-select/option-select.component';
import { BooleanInputComponent } from './boolean-input/boolean-input.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { ShapeSelectorComponent } from './shape-selector/shape-selector.component';
import { StepPipe } from '../side-panel/step.pipe';
import { TimeDisplayComponent } from './time-display/time-display.component';

const Components = [
  CenterComponent,
  ContainerComponent,
  LinkComponent,
  MatIconComponent,
  YamlComponent,
  CodeComponent,
  ListComponent,
  LoaderComponent,
  TextInputComponent,
  FloatingComponent,
  EditableTextComponent,
  GeneralSelectorComponent,
  KeyValueMenuComponent,
  ControlElementComponent,
  ColorSelectComponent,
  NumberSelectComponent,
  DropdownComponentComponent,
  OptionSelectComponent,
  BooleanInputComponent,
  NumberInputComponent,
  ShapeSelectorComponent,
  TimeDisplayComponent,
];

@NgModule({
  declarations: [...Components, StepPipe],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ColorPickerModule,
    MaterialModule,
  ],
  providers: [CanvasService],
  exports: [...Components, CommonModule, FlexLayoutModule],
})
export class UtilComponentModule {}
