import { createAction, props } from '@ngrx/store';
import { User } from './user.interface';
import { RoleTypes } from '../organisation/role.interface';

export const UserFeature = 'User';

function getActionName(action: string) {
  return `[${UserFeature}] ${action}`;
}

export const loadUsers = createAction(getActionName('list'));

export const loadUserById = createAction(
  getActionName('get-one'),
  props<{ id: string }>(),
);

export const deleteUser = createAction(
  getActionName('delete-user'),
  props<{ user: User }>(),
);

export const deleteUserBase = createAction(
  getActionName('delete-user-base'),
  props<{ user: User }>(),
);

export const setUserBeingEdited = createAction(
  getActionName('set-user-being-edited'),
  props<{ user: User }>(),
);

export const setUserBeingDeleted = createAction(
  getActionName('set-user-being-deleted'),
  props<{ user: User }>(),
);

export const setNewlyCreatedUser = createAction(
  getActionName('set-newly-created-user'),
  props<{ id: string; time: number }>(),
);

export const createUser = createAction(
  getActionName('create'),
  props<{ user: Partial<User>; role: RoleTypes }>(),
);

export const setUser = createAction(
  getActionName('set'),
  props<{ user: User }>(),
);

export const setNewUserLoading = createAction(
  getActionName('set-new-user-loading'),
  props<{ value: boolean }>(),
);

export const setUsers = createAction(
  getActionName('set list'),
  props<{ users: User[] }>(),
);

export const setMyUserId = createAction(
  getActionName('set my user id'),
  props<{ id: string }>(),
);
