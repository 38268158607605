import { PathShape } from '../../path-shape';
import {
  ArcSectionDescriptor,
  Coords,
} from '../../../../../../elements/resource/types/shape.type';
import { PointController } from '../../../../../../elements/util/point-controller/point-controller';
import { PathSection, PathSectionConfig } from '../path-section';
import { BaseVector } from '../../../../../../elements/base/vector/vector';
import { ArcItem, PathItem } from '../../path-shape.types';
const { abs, atan, cos, pow, sign, sqrt, PI } = Math;

export class ArcSection extends PathSection {
  baseVector: BaseVector;
  largeArc = 1;
  cutArc: number;

  // get r() {
  //   return !!super.r ? super.r : this.d / 2;
  // }

  set r(val: number) {
    super.r = val;
  }

  get l() {
    return this.d / 2;
  }

  get r() {
    return this.descriptor.r;
  }

  get a1() {
    return this.descriptor.a1;
  }

  get a2() {
    return this.descriptor.a2;
  }

  get antiClockwise() {
    return this.descriptor.antiClockwise;
  }

  get cx() {
    return this.descriptor.cx;
  }

  get cy() {
    return this.descriptor.cy;
  }

  get type() {
    return 'as';
  }

  get rpcP(): Coords {
    return [this.absCoords[0] - this.x / 2, this.absCoords[1] - this.y / 2];
  }

  get rpcA() {
    return this.m + PI / 2;
  }

  get rpcR() {
    return this.getArcDistance({
      r: this.r,
      l: this.l,
      largeArc: this.largeArc,
    });
  }

  get origin(): Coords {
    return this.getArcOrigin({
      x: this.x,
      y: this.y,
      r: this.r,
      largeArc: this.largeArc,
    });
  }

  get absOrigin() {
    const [x, y] = this.origin;
    return this.convertToAbs(x, y);
  }

  get arcArc() {
    return abs((this.endLength - this.startLength) / this.r);
  }

  constructor(
    pathShape: PathShape,
    protected descriptor: ArcSectionDescriptor,
    config?: PathSectionConfig,
  ) {
    super(pathShape, descriptor, config);
    this.r = this.r;
    this.largeArc = descriptor.sign || -1;
    this.baseVector = new BaseVector([this.x, this.y]);
  }

  getPathItem(start?: number, end?: number): PathItem {
    return {
      type: 'arc',
      x: this.x,
      y: this.y,
      cx: this.cx,
      cy: this.cy,
      a1: this.a1,
      a2: this.a2,
      r: this.r,
      antiClockwise: this.antiClockwise,
    } as ArcItem;
  }

  getDescriptor(): ArcSectionDescriptor {
    return {
      ...super.getDescriptor(),
      type: 'arc',
      sign: this.largeArc,
      r: this.r,
      cx: this.cx,
      cy: this.cy,
      a1: this.a1,
      a2: this.a2,
      antiClockwise: this.antiClockwise,
    };
  }

  refreshRPC() {
    this.rPc?.patch({
      pOffset: this.rpcP,
      angle: this.rpcA,
      d: this.getArcDistance({
        r: this.r,
        l: this.l,
        largeArc: this.largeArc,
      }),
    });
  }

  initRPc() {
    this.rPc = new PointController(
      this,
      {
        pOffset: this.rpcP,
        angle: this.rpcA,
        d: this.rpcR,
        start: () => {
          // this.pathShape.sectionGroup.attr({ visibility: 'hidden' });
        },
        drag: ({ d }) => {
          const l = this.l;
          if (d < -l || l < d) {
            const _d = sqrt(pow(d, 2) + pow(l, 2)) / 2;
            const angle = atan(l / d);
            this.largeArc = 1;
            this.r = _d / Math.cos(angle);
          } else {
            this.largeArc = -1;
            this.r = l / cos(2 * atan(l / abs(d)) - PI / 2);
          }

          this.r *= sign(d);

          this.pathShape.refresh();
        },
        end: () => {
          // this.pathShape.sectionGroup.attr({ visibility: 'visible' });
          this.pathShape.saveSections({ section: this });
        },
      },
      this.pathShape.circleContainer,
      this.pathShape.auxCircleContainer,
    );
  }

  _getSection() {
    return this.getSection();
  }

  getSection(start?: number, end?: number) {
    let [x, y] = this.getCoords();
    this.setAbsCoords(x, y);

    if (start || end) {
      let absXY = false;
      let m = '';
      let largeArc = this.largeArc;
      this.cutArc = 0;
      if (end < this.startLength || this.endLength < start) {
        return;
      }
      if (this.startLength < start) {
        // In case the starting point of the arc is shifted the end will definitely be an absolute coordinate
        [x, y] = this.absCoords;
        absXY = true;
        const { coords, cutArc } = this.getArcStartPoint({
          dA: start - this.startLength,
          x: this.x,
          y: this.y,
          r: this.r,
          largeArc: this.largeArc,
        });

        const [xs, ys] = [
          this.prevPS.absCoords[0] + coords[0],
          this.prevPS.absCoords[1] + coords[1],
        ];
        this.cutArc += cutArc;
        m = `M ${xs} ${ys} `;
      }

      if (end < this.endLength) {
        absXY = true;

        // Coords are gonna be local variables
        const { coords, cutArc } = this.getArcEndPoint({
          dA: this.endLength - end,
          x: this.x,
          y: this.y,
          r: this.r,
          largeArc: this.largeArc,
        });

        this.cutArc += cutArc;
        // If the end is shifted as well, the x,y will be overriden
        [x, y] = this.convertToAbs(coords[0], coords[1]);
      }

      if (this.cutArc && this.arcArc - this.cutArc < PI) {
        largeArc = -1;
      }
      return this.getArcSectionString({
        m,
        r: this.r,
        largeArc,
        absXY,
        x,
        y,
      });
    } else {
      return this.getArcSectionString({
        r: this.r,
        largeArc: this.largeArc,
        x,
        y,
      });
    }
  }
}
