import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { getCurrentColorPalette } from '../organisation.selector';
import { saveOrganisationColorPalette } from '../organisation.actions';

export type Colors = Array<{ name: string; code: string }>;

@Component({
  selector: 'nw-org-color-palette-edit',
  templateUrl: './org-color-palette-edit.component.html',
  styleUrls: ['./org-color-palette-edit.component.scss'],
})
export class OrganisationColorPaletteEditComponent {
  @Output()
  exit = new EventEmitter();

  selectedLang = 'en';

  languages$: Observable<string[]>;
  languages: string[];

  langMap = {
    hu: 'hungarian',
    en: 'english',
    ger: 'german',
    esp: 'spanish',
    fr: 'french',
    it: 'italien',
    pl: 'polish',
  };

  palette$: Observable<Colors>;
  palette: Colors;
  colorPalette: Record<string, string> = {};

  constructor(private readonly store: Store) {
    this.store
      .select(getCurrentColorPalette)
      .subscribe(colorPalette => (this.colorPalette = cloneDeep(colorPalette)));

    this.palette$ = this.store.select(getCurrentColorPalette).pipe(
      map(object => {
        return Object.entries(object || {})
          .filter(([, value]) => !!value)
          .map(([name, code]) => ({
            name,
            code,
          }));
      }),
    );

    this.palette$.subscribe(palette => {
      this.palette = palette;
    });
  }

  addNewItem() {
    // this.store.dispatch(
    //   setColorPaletteItem({ name: 'newColor', color: '#333333' }),
    // );
  }

  nameChanged(value: string, index: number) {
    this.palette[index].name = value;
  }

  colorChanged(value: string, index: number) {
    this.palette[index].code = value;
    // this.colorPalette[this.palette[index].name] = value;
  }

  get langKeys() {
    return ['hu', 'en', 'ger', 'esp', 'fr', 'it', 'pl'].filter(
      key => !this.languages.includes(key),
    );
  }

  addColor() {
    this.palette.push({ name: 'newColor', code: '#333333' });
    this.colorPalette['newColor'] = '#333333';
  }

  save() {
    const colorPalette = this.palette.reduce((obj, { name, code }) => {
      obj[name] = code;
      return obj;
    }, {});
    console.log('dispatch', colorPalette);
    this.store.dispatch(saveOrganisationColorPalette({ colorPalette }));
    // this.store.dispatch(saveOrganisationColorPaletteBase({ colorPalette }));
  }

  discard() {}
}
