<div fxLayout="row" fxLayoutAlign="start" style="height: 100%">
  <nw-control-element>
    <nw-icon
      type="check"
      [size]="18"
      [disabled]="!(isSaveActive$ | async)"
      (click)="cs.patchFiles()"
    ></nw-icon>
  </nw-control-element>

  <!-- <nw-control-element>
    <div fxLayout="row" fxLayoutGap="8px">
      <nw-icon
        type="undo"
        [size]="18"
        [disabled]="cs.undoDisabled"
        (click)="cs.undo()"
      ></nw-icon>
      <nw-icon
        type="redo"
        [size]="18"
        [disabled]="cs.redoDisabled"
        (click)="cs.redo()"
      ></nw-icon>
    </div>
  </nw-control-element> -->
</div>
