<div class="boolean-input-comp row-space-between">
  <div class="row-space-between" [class.reverse]="reverse">
    <div class="attr-key">{{ key }}</div>
    <ng-container *ngIf="value?.length > 1; else input">
      <div (click)="selectorOpen = !selectorOpen" class="indefinite-wave">
        ~
      </div>
    </ng-container>
    <ng-template #input>
      <input
        [checked]="array[0]"
        type="checkbox"
        name="value"
        (change)="inputChanged($event)"
      />
    </ng-template>
  </div>
  <nw-dropdown-component
    [data]="[true, false]"
    *ngIf="selectorOpen"
    [right]="0"
    [top]="12"
    (clicked)="selectorClicked($event)"
  >
    <div>
      <ng-template let-value>
        <div>{{ value }}</div>
      </ng-template>
    </div>
  </nw-dropdown-component>
</div>
